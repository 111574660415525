import { addDays } from 'date-fns';
import React, { Component } from 'react';
import { Bar, CartesianGrid, Cell, ComposedChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis, Line, LabelList } from 'recharts';
import { formatDateMonthDayOfWeek, getServerDate } from '../../../../utils';
import DateFilterOneRange from './DateFilterOneRange';
import DownloadButton from '../../../../components/DownloadButton';

class VisitorsAndPassersBy extends Component {

  constructor(props) {
    super(props);
    const CURRDATE = addDays(getServerDate(), -1);
    const {
      filterRanges = {
        startDate: addDays(CURRDATE, -13),
        endDate: CURRDATE,
        key: 'selectionRange1'
      },
      dash = {
        header: {},
        data: []
      }, 
      dashVideo = false
    } = props;

    this.state = {
      isLoading: false,
      typeChart: 'DAILY',
      filterRanges: filterRanges,
      dash,
      dashVideo
    }
  }

  componentDidMount() {
    const { dash} = this.state;
    if(dash.data.length === 0){
      this.loadData()
    }
   
  }

  _changeDateFilter = () => (ranges) => {
    this.setState({ filterRanges: ranges }, () => {
      this.loadData();
    });
  }

  loadData = () => {
    this.setState({ isLoading: true }, () => {
      const { typeChart, filterRanges } = this.state;
      this.props.loadPeopleCount(typeChart, filterRanges.startDate, filterRanges.endDate)
        .then(result => {
          this.setState({ isLoading: false, dash: result });
        });
    });
  }

  render() {
    const { dash, filterRanges, isLoading, dashVideo } = this.state;
    return (<div className="card " style={{ width: '100%' }}>
      <header className="card-header" style={{ padding: 10}}>
        <div className="title is-5 is-marginless">
          Visitantes vs Vendas
        </div>
        {!dashVideo && <a href className="card-header-icon" aria-label="Download PDF">
          <DownloadButton site={this.props.site} name='peoplecount' begin={filterRanges.startDate} end={filterRanges.endDate}/>
        </a>}
      </header>
      <div className="card-content" style={{
        justifyContent: 'center',
        flexDirection: 'column',
        display: 'flex',
        height: '400px'
      }}>
        {isLoading ? <div className="has-text-centered" style={{ height:400, paddingTop: '100px' }}>
            <span>
              <span className="button is-loading is-large is-bordless" />
              <p className="subtitle is-5">Aguarde, carregando...</p>
            </span>
          </div>
        : (dash.header.hasSales ? <ResponsiveContainer width="100%" height={300}>
            <ComposedChart data={dash.data}
                margin={{top: 20, right: 50, left: 10, bottom: 20}}>
              <CartesianGrid strokeDasharray="3 10" />
              <XAxis dataKey="info_date" tickFormatter={(val) => `${formatDateMonthDayOfWeek(val)}`}/>
              <YAxis yAxisId="left" orientation="left" stroke="#343434"/>
              <YAxis yAxisId="right" orientation="right" stroke="#343434"/>
              <Legend />
              <Tooltip
                content={<CustomToolTip hasSales={dash.header.hasSales} />}
                isAnimationActive={false}/>
              <Bar
                key="visitorsCount"
                yAxisId="left"
                name="Visitantes Total"
                dataKey="visitorsCount"
                maxBarSize={15}
                fill="#0043ff"
                stroke="#0043ff"
                isAnimationActive={false}
                minPointSize={3}
                connectNulls={true}
                color="#222"
              >
                {
                  dash.data.map((entry, index) => (
                    <Cell color="#222" fill={'#0043ff'} fillOpacity={1} key={`cell-visitor-${index}`}/>
                  ))
                }
              </Bar>
              <Bar
                key="totalSales"
                yAxisId="left"
                name="Vendas"
                dataKey="totalSales"
                maxBarSize={15}
                fill="#00d1b2"
                stroke="#00d1b2"
                isAnimationActive={false}
                minPointSize={3}
                connectNulls={true}
                color="#222"
              >
                {
                  dash.data.map((entry, index) => (
                    <Cell color="#222" fill={'#00d1b2'} fillOpacity={1} key={`cell-visitor-${index}`}/>
                  ))
                }
              </Bar>
              <Line key="salesRate"
                yAxisId="right"
                name="Taxa de vendas"
                dataKey="salesRate"
                strokeDasharray="3 5"
                fill="#000"
                stroke="#000"
                isAnimationActive={false}
                connectNulls={true}
                position="top"
              >
                <LabelList
                  dataKey="salesRate"
                  position="top" 
                  formatter={(val) => `${val}%`}
                  fontSize={22}
                />
              </Line>
            </ComposedChart>
          </ResponsiveContainer>
        : <ResponsiveContainer width="100%" height={300}>
          <ComposedChart data={dash.data}
              margin={{top: 20, right: 50, left: 10, bottom: 20}}>
            <CartesianGrid strokeDasharray="3 10" />
            <XAxis dataKey="info_date" tickFormatter={(val) => `${formatDateMonthDayOfWeek(val)}`}/>
            <YAxis yAxisId="left" orientation="left" stroke="#343434"/>
            <Legend />
            <Tooltip
              content={<CustomToolTip hasSales={dash.header.hasSales} />}
              isAnimationActive={false}/>
            <Bar
              key="visitors"
              yAxisId="left"
              name="Visitantes"
              dataKey="visitorsCount"
              maxBarSize={15}
              fill="#0043ff"
              stroke="#0043ff"
              isAnimationActive={false}
              minPointSize={3}
              connectNulls={true}
              color="#222222"
              label={{position: 'top'}}
            >
            </Bar>
          </ComposedChart>
        </ResponsiveContainer>)}
      </div>
      <footer className="card-footer" >
        <span className="button has-text-left is-white card-footer-item is-radiusless is-shadowless is-bordless" style={{ borderRight: 'none' }}>
          <DateFilterOneRange changeDates={this._changeDateFilter()} actualRanges={{ ranges: filterRanges }}/>
        </span>
        <span className="card-footer-item" style={{ borderRight: 'none' }}>
        </span>
        <span className="card-footer-item" style={{ borderRight: 'none' }}>
        </span>
        <span className="card-footer-item" style={{ borderRight: 'none' }}>
        </span>
      </footer>
    </div>)
  }
}

class CustomToolTip extends Component {
  render() {
    const {
      active,
      hasSales
    } = this.props;

    if(active) {
      const { payload=[] } = this.props;
      const data = payload[0] || { payload: {} };

      return (<div className="card">
        {hasSales ? <div  style={{ padding: '0px 15px 0px 15px' }}>
            <p className="heading has-text-centered">
              Em {formatDateMonthDayOfWeek(data.payload.info_date)}
            </p>

            <span className="heading has-text-centered" style={{ fontWeight: 'bold', marginBottom: '-5px' }}>
              <span>Visitantes</span>
            </span>
            <span className="heading has-text-centered" style={{ fontWeight: 'bold', letterSpacing: 0.5, fontSize: '1.5em', color: '#0043ff' }}>
              <span>{data.payload.visitorsCount}</span>
            </span>

            <span className="heading has-text-centered" style={{ fontWeight: 'bold', marginBottom: '-5px' }}>
              <span>Vendas</span>
            </span>
            <span className="heading has-text-centered" style={{ fontWeight: 'bold', letterSpacing: 0.5, fontSize: '1.5em', color: '#00d1b2' }}>
              <span>{data.payload.totalSales}</span>
            </span>

            <span className="heading has-text-centered" style={{ fontWeight: 'bold', marginBottom: '-5px' }}>
              <span>Taxa vendas </span>
            </span>
            <span className="heading has-text-centered" style={{ fontWeight: 'bold', letterSpacing: 0.5, fontSize: '1.5em', color: '#222222' }}>
              <span>{data.payload.salesRate}%</span>
            </span>

          </div> : <div  style={{ padding: '0px 5px 0px 5px' }}>
            <p className="heading has-text-centered">
              Em {formatDateMonthDayOfWeek(data.payload.info_date)}
            </p>

            <span className="heading has-text-centered" style={{ fontWeight: 'bold', marginBottom: '-5px' }}>
              <span>Visitantes</span>
            </span>
            <span className="heading has-text-centered" style={{ fontWeight: 'bold', letterSpacing: 0.5, fontSize: '1.5em', color: '#0043ff' }}>
              <span>{data.payload.visitorsCount}</span>
            </span>

          </div>}
      </div>)
    }
    return null;
  }
}

export default (VisitorsAndPassersBy);