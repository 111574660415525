import React, { Component } from 'react';

import {
  addDays,
  subDays
} from 'date-fns';

import { dateToDDMMYYYY, getServerDate } from '../../../../../utils';

import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer
} from 'recharts';

import {
  QuestionCircleIcon,
  BranchHistoryIcon,
  CalendarAltIcon,
  ExclamationCircleIcon
} from '../../../../../components/icons';

import PieLabel from '../../../../../components/dashboard/PieLabel';

import VenueVisitors from './VenueVisitors';
import DatePickerFilter from './DatePickerFilter';

const colors = ['#0043ff', '#00d1b2', '#ff424b', '#23527c', '#7571ff', '#4267b2', '#d6249f', '#E8770C', '#D1C000', '#8D00DE' ];

class CustomerBehaviorChart extends Component {

  constructor(props) {
    super(props);
    const {
      dashVideo = false
    } = props;

    const CURRDATE = addDays(getServerDate(), -1);

    this.state = {
      isLoading: false,
      typeChart: 'DAILY',
      filterRanges: {
        startDate: addDays(CURRDATE, -13),
        endDate: CURRDATE,
        key: 'selectionRange1'
      },
      dash: {
        header: { venues: [] },
        data: []
      },
      dashVideo
    }
  }

  _changeDateFilter = () => (date) => {
    this.setState({
      filterRanges: {
        startDate: subDays(date, 7),
        endDate: date,
        key: 'selectionRange1'
      }
    }, () => {
      this.loaddata();
    });
  }

  loaddata = () => {
    this.setState({ isLoading: true }, () => {
      const { filterRanges } = this.state;
      this.props.loadVenuePeopleCount(filterRanges.startDate, filterRanges.endDate)
        .then(result => {
          this.setState({ isLoading: false, dash: result });
        });
    });
  }

  _showMore = () => {
    this.props.showMore(<div className="notification has-text-justified" style={{ padding: '100px' }}>
      <p className="title is-3">Atratividade!</p>
      <p className="subtitle is-5">
        Além da análise geral da loja (fluxo e conversão), também é possível 
        melhorar o aproveitamento da loja, analisando o 
        comportamento do consumidor dentro da loja.
      </p>
      <p className="subtitle is-5">
        A análise das áreas permite saber onde os clientes 
        vão na minha loja, e onde eles não vão.
      </p>
      <p className="subtitle is-5">
        A atratividade de área é determinada pelo número de visitantes 
        na área dividido pelo número total de visitantes.
      </p>
      <p className="subtitle is-4 has-text-centered">
        TAXA DE ATRATIVIDADE DA ÁREA = VISITANTES DA ÁREA / VISITANTES DA LOJA
      </p>
      <p className="subtitle is-5">
        A analise da atratividade, engajamento ou conversão de áreas como provadores, showroom, ou filas de caixa permitem 
        a avaliação do layout, áreas quentes, corredores ou gôndolas da loja, 
        descobrir qual o tipo de displays ou equipamento mais engaja meus clientes, quanto tempo 
        os clientes passam na minha loja, ou em determinada área ou fila, e ajudar otimizações de áreas específicas.
      </p>
      <p className="subtitle is-5">
        OBS: Somente é considerada na area um visitante que permaneceu ao menos 1 minuto na área.
      </p>
    </div>);
  }


  _showHistory = () => {
    const { viewOptions, shopId } = this.props;
    const { isLoading, filterRanges, dash, dashVideo } = this.state;
    if(isLoading)
      return;

    this.props.showMore(<VenueVisitors
      viewOptions={viewOptions}
      filterRanges={filterRanges}
      dash={dash}
      shopId={shopId}
      site={this.props.site}
      dashVideo={dashVideo}
      loadData={this.props.loadVenuePeopleCount}
    />);
  }

  _renderDatePickerLabel = () => {
    const { endDate } = this.state.filterRanges;
    return <div style={{ display: 'flex', justifyContent: 'center' }}>
      <span
        className="button is-large has-text-left is-white card-footer-item is-radiusless is-shadowless is-bordless"
        style={{
          borderRight: 'none',
          maxWidth: '100%',
          justifyContent: 'center'
        }}
      >
        <CalendarAltIcon />
        <span>Top 5 em <b>{dateToDDMMYYYY(endDate)}</b></span>
      </span>
    </div>
  }

  componentDidMount() {
    this.loaddata();
  }

  render() {

    const {
      isLoading,
      dash
    } = this.state;

    const {
      venues=[],
      header={ info_date: null, venues: [], venuesInfo: [] }
    } = dash;

    const venuesToIgore = venues
      .filter(venue => venue.fittingroom || venue.passersBy || venue.cashdesk || venue.cashdeskQueue)
      .map(venue => venue.id);

    const topFive = header.venues
      .filter(venue => !venuesToIgore.includes(venue.venueid))
      .sort((a, b) => b.peopleCount - a.peopleCount)
      .slice(0, 5)
      .map((venue, index) =>({ id: venue.venueid, auditingOk: header.auditingOk, col: index, name: venue.name, rate: venue.conversionRate, value: venue.peopleCount }));


    const auditingOk = topFive.length ? topFive[0].auditingOk : true;
    
    return <div style={{ width: '100%' }} className="card">
      <header className="card-header" style={{ borderBottom: '1px solid #eee', padding: '10px', justifyContent: 'space-between' }}> 
        <div className="title is-5 is-marginless">
          Atratividade
        </div> 
        <QuestionCircleIcon className="cursor-pointer" onClick={this._showMore}/>
      </header>
      
      <div className="card-content">
        <div className="tile is-ancestor">
          <div className="tile is-12 is-vertical is-parent">
            <div className="tile is-vertical is-parent has-text-centered">
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                {header.info_date
                  && <DatePickerFilter 
                    renderLabel={this._renderDatePickerLabel}
                    className={'top-to-down-right'}
                    changeDates={this._changeDateFilter()}
                    currDate={header.info_date}
                  />}
              </div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                {!auditingOk && <span
                  style={{ width: 150 }}
                  className="tag is-danger">
                  <ExclamationCircleIcon />
                  <span>
                    Dados não confiáveis.
                  </span>
                </span>}
              </div>


              {isLoading
                ? <div className="has-text-centered" style={{ height:300, paddingTop: '70px' }}>
                    <span>
                      <span className="button is-loading is-large is-bordless" />
                      <p className="subtitle is-5">Aguarde, carregando...</p>
                    </span>
                  </div>
                : topFive.filter(top => top.value !== 0).length === 0
                  ? <div className="has-text-centered" style={{ height:300, paddingTop: '70px' }}>
                    <span>
                      <p className="subtitle is-5 has-text-grey-light">Sem dados para a data escolhida.</p>
                    </span>
                  </div>
                  : <ResponsiveContainer className="chart_justifycenter" width="100%" height={400}>
                      <TwoLevelPieChart data={topFive} />
                    </ResponsiveContainer>}

              <p className="help">Este é o gráfico pizza que indica o top cinco de áreas da sua loja.</p>
              <p className="help">Isso significa que essas são as cinco lojas que mais tiveram visitantes <b>ENGAJADOS</b></p>
            </div>
          </div> 
        </div>
      </div>
      <footer className="card-footer">
        <span
          className="button has-text-left is-white card-footer-item is-radiusless is-shadowless is-bordless"
          style={{ borderRight: 'none' }}
          onClick={this._showHistory} >
          <BranchHistoryIcon />
          <span>Ver histórico</span>
        </span>
        <span className="card-footer-item" style={{ borderRight: 'none' }}>
        </span>
        <span className="card-footer-item" style={{ borderRight: 'none' }}>
        </span>
        <span className="card-footer-item" style={{ borderRight: 'none' }}>
        </span>
      </footer>
    </div>
  }
}


class TwoLevelPieChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0
    };
  }

  renderActiveShape = (props) => {
    const { col, name, rate } = props;
    const primaryLabel = `${col+1}º - ${name}`,
      secondaryLabel = `${rate}% dos visitantes engajaram`,
      primaryMiddleText = `TOP 5`,
      secondaryMiddleText = `ENGAJAMENTOS`,
      opacity =  1;

    return (<PieLabel {...props}
      col={col}
      primaryLabel={primaryLabel}
      secondaryLabel={secondaryLabel}
      primaryMiddleText={primaryMiddleText}
      secondaryMiddleText={secondaryMiddleText}
      opacity={opacity}
    />);
  };

  onPieEnter = (data, index) => {
    this.setState({
      activeIndex: index,
    });
  }

	render () {
    const { data } = this.props;
  	return (
    	<PieChart width={950} height={400}>
        <Pie 
          activeIndex={this.state.activeIndex}
          activeShape={this.renderActiveShape}
          data={data} 
          cx={460} 
          cy={200}
          innerRadius={80}
          outerRadius={100}
          dataKey="rate"
          onMouseEnter={this.onPieEnter}
        >
          {data.map((entry, index) => <Cell key={`celpie-${index}`} fill={colors[entry.col]}/>)}
        </Pie>
       </PieChart>
    );
  }
}

export default (CustomerBehaviorChart);