import React, { Component } from 'react';

import { connect } from 'react-redux';
import {
  Switch,
  Route,
  Redirect,
  withRouter
} from "react-router-dom";

import LoginForm from './login/LoginForm';
import RecoveryPassForm from './login/RecoveryPassForm';

import logoActive from '../img/tooq-logo-new-green.png';

class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {
      redirectToReferrer: false
    }
  }

  _loggedin() {
    this.setState({ redirectToReferrer: true });
  }

  render() {
    const { match } = this.props;
  
    const { from } = this.props.location.state || { from: { pathname: "/board" } };
    const { redirectToReferrer } = this.state;

    if (redirectToReferrer || this.props.app.user) {
      return <Redirect to={from} />;
    }

    return (
      <div style={{ display: 'flex', justifyContent: 'center', padding: '5%', height: '100vh', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: '#222222' }}>
        <div style={{ top: 30, width: 500 }}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img
              src={logoActive}
              alt="Logo"
              style={{ height: 48 }}
            />
          </div>
          <h1 className="title has-text-centered" style={{ color: '#fff', verticalAlign: 'centered' }}>
            Jenny
          </h1>
          <div style={{ margin: 30 }}>
            <Switch>
              <Route exact path={`${match.url}/`} render={(props) => <LoginForm {...props} loggedinHandler={this._loggedin.bind(this)} /> }/>
              <Route path={`${match.url}/pass-recovery/:email`} component={RecoveryPassForm} />
              <Route path={`${match.url}/pass-recovery`} component={RecoveryPassForm} />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
      app: state.app
  }
}

export default withRouter(connect(mapStateToProps)(Login));