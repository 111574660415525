import axios from 'axios';
import format from 'date-fns/format';
import addDays from 'date-fns/addDays';
import {pt} from 'date-fns/locale'
import notification from './notification'
import { subDays } from 'date-fns';
import { useState } from 'react';

const BASE_URL = process.env.REACT_APP_BASE_API_URL;

const defaultParameters = { format: 'json' };

export function mascara(val='', mask='000000000') {
  return `${mask}${val}`.substr(val.length);
}
export function getServerDate(decreaseValue=0) {
  const offset = localStorage.getItem('offset');
  if(!offset){
    return subDays(new Date(), decreaseValue);
  }else{
    return subDays(new Date(Date.now() + parseInt(offset)), decreaseValue);
  }
}
export function get(resource, params={}, parameters = {}) {
  const token = localStorage.getItem('token');
  return axios.get(`${BASE_URL}/${resource}`, {
    params,
    ...parameters,
    ...defaultParameters,
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  .catch((error) => {
    if(error.response && error.response.status === 500){
      notification.error('Erro ao se comunicar com o servidor.')
    }
    throw error;
  });
}

export function post(resource, parameters = {}) {
  const token = localStorage.getItem('token');
  return axios.post(`${BASE_URL}/${resource}`, {
    ...parameters,
    ...defaultParameters
  },
  {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  .catch((error) => {
    if(error.response && error.response.status === 500){
      notification.error('Erro ao se comunicar com o servidor.')
    }
    throw error;
  });
}

export function postImage(resource, parameters = {}) {
  const token = localStorage.getItem('token');
  return axios.post(`${BASE_URL}/${resource}`, parameters, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  .catch((error) => {
    if(error.response && error.response.status === 500){
      notification.error('Erro ao se comunicar com o servidor.')
    }
    throw error;
  });
}

export function del(resource, parameters = {}) {
  const token = localStorage.getItem('token');
  return axios.delete(`${BASE_URL}/${resource}`, {
    ...parameters,
    ...defaultParameters,
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  .catch((error) => {
    if(error.response && error.response.status === 500){
      notification.error('Erro ao se comunicar com o servidor.')
    }

    if (error.response && error.response.data){
      throw error.response.data.error;
    }

    throw error;
  });
}

export function put(resource, parameters = {}) {
  const token = localStorage.getItem('token');
  return axios.put(`${BASE_URL}/${resource}`, {
    ...parameters,
    ...defaultParameters
  },
  {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  .catch((error) => {
    if(error.response && error.response.status === 500){
      notification.error('Erro ao se comunicar com o servidor.')
    }
    throw error;
  });
}

export function getPageToDisplay(location) {
  const searchQuery = "page=";
  const searchLocation = location.search;
  const indexToParse = searchLocation.indexOf("page=");

  let page = 1;

  if(indexToParse !== -1)
    page = (searchLocation.substring(indexToParse + searchQuery.length, searchLocation.length));

  return parseInt(page, 10);
}

export function handleForInputChangeOnForm(event, callBackHandler) {
  const formData = { ...this.state.formData };
  const value = event.target.value;

  const nodes = `${event.target.id}`.split('.');

  if(nodes.length === 1)
    formData[nodes[0]] = value;
  else
    formData[nodes[0]][nodes[1]] = value;

  this.setState({ formData: formData }, callBackHandler);
}

export function calculateConversionRate(v1, v2) {
  if(!v2)
    return 0;

  return parseInt((v1 / v2) * 100, 10);
}
export function dateToDDMMYYYY(val) {
  if(val === null)
    return '';
  return format(val, 'DD/MM/YYYY');
}

export function formatDateDayOfWeek(val) {
  if(val === null)
    return '';
  return format(val, 'DD/MM/YYYY - ddd', {locale: pt}).toUpperCase();
}

export function formatAsPtBr(day, mask) {
  return format(day, mask, {
    locale: pt
  })
}

export function formatDateMonthDayOfWeek(val) {
  if(val === null)
    return '';
  return format(val, 'DD/MM - ddd', {locale: pt}).toUpperCase();
}

export function getNewDay(daysToAdd=0) {
  return format(addDays(new Date(), daysToAdd), 'YYYY-MM-DD')
}

export function useInput(defaultValue='') {
  const [ value, setInput ] = useState(defaultValue)
  
  const onChange = e => {
    const { value } = e.target;
    setInput(value);
  }

  return { value, onChange }
}
