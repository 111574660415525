import React, { Component } from 'react';

import { get } from '../../../../../utils';
import * as Icons from '../../../../../components/icons';
import Columns, { Column } from '../../../../../components/Columns';


class DvrsImages extends Component {

  constructor(props) {
    super(props);

    const { description='', url='' } = props.camera || {};

    this.state = {
      fields: {
        description,
        url
      },
      isSaving: false,
      dvrsImages: [],
      isLoading: false
    }
  }

  componentDidMount() {
    this._loadImage()
  }

  _loadImage = () => {
    const { site } = this.props;

    this.setState({isLoading: true}, () => {
      get(`admin/videos/sites/${site.id}/dvrs`)
        .then(resp => {
          this.setState({
            dvrsImages: resp.data.data,
            isLoading: false
          });
        })
    })
  }

  _selectCameraFromDVR = (image) => () => {
    this.props.changeCameraImage(image);
  }

  render() {
    const { dvrsImages, isLoading } = this.state;
    return (<div className="card notification">
      <p className="title is-5 has-text-centered">Selecione uma origem para a sua camera</p>
      {isLoading && <p className="subtitle is-5 has-text-centered" style={{ width: '100%' }}>
        Aguarde carregando...
      </p>}
      {!isLoading && <Columns className="has-text-centered" isMultiline>
        {dvrsImages.map(image => <Column isSize="is-narrow ">
          <p className="title is-5 has-text-left">IP:{image.dvr_ip}</p>
          <p className="subtitle is-5 has-text-left">Canal:{image.dvr_channel} ({image.width}x{image.height})</p>
          <img
            className="dvr-find-is-image-src"
            width="320"
            height="180"
            src={image.src}
            onClick={this._selectCameraFromDVR(image)}
            alt="Não encontrada"
          />
          <div className="control is-expanded" style={{marginTop: 0 }}>
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }} className="button is-small is-primary is-outlined is-fullwidth has-text-centered" onClick={this._selectCameraFromDVR(image)}>
              <Icons.PlusCircleIcon /> <span>Usar nesta camera</span>
            </div>
          </div>
        </Column>)}
      </Columns>}
    </div>);
  }
}

export default (DvrsImages);



        
