
import React, { Component } from 'react';

import { get } from '../../../../../utils';
import Columns, { Column } from '../../../../../components/Columns';
import Modal from '../../../../../components/Modal';
import * as Icons from '../../../../../components/icons';
import CameraForm from './CameraForm';
import CameraArea from './CameraArea';
import CameraAreaV2 from './CameraAreaV2';

class CameraContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      cameras: [],
      toogleModal: false,
      toogleModalSettings: false,
      toogleModalImageSrc: false,
      selectedCamera: {},
      imageSrc: null
    }
  }

  componentDidMount() {
    this._loadCameras();
  }

  _loadCameras = () => {
    const { id } = this.props.site;
    this.setState({ isLoading: true }, () => {
      get(`admin/videos/sites/${id}/cameras`)
        .then(resp => {
          const cameras = resp.data.data;
          this.setState({ cameras, isLoading: false });
        });
    });
  }

  _handleNewCamera = (camera) => {
    const cameras = [ ...this.state.cameras, camera ];
    if(cameras.length === 1)  {
      this.setState({ cameras });
      return;
    }
    this.setState({ cameras });
  }

  _changePictureSrc = (cameraId, pictureSrc) => {
    const { selectedCamera } = this.state;
    const cameras = this.state.cameras.map(d => {
      if(d.id === cameraId) {
        return {
          ...d,
          imageSrc: pictureSrc
        };
      }
      return d;
    });

    this.setState({ cameras, selectedCamera: { ...selectedCamera, imageSrc: pictureSrc } });
  }

  _handleEditCamera = (camera) => {
    const cameras = this.state.cameras.map(d => {
      if(d.id === camera.id) {
        return camera;
      }
      return d;
    });
    this.setState({ cameras  });
  }

  _changeDeviceCameras = (cameras) => {
    this.setState({ camerasFromDevice: cameras });
  }

  _toogleModal = () => {
    this.setState({ toogleModal: !this.state.toogleModal });
  }

  _toogleModalSettings = () => {
    this.setState({ toogleModalSettings: !this.state.toogleModalSettings });
  }

  _setCamera = (camera, settings=false) => () => {
    this.setState({ selectedCamera: camera }, settings ? this._toogleModalSettings : this._toogleModal);
  }

  _toogleModalImageSrc = (camera) => {
    this.setState({
      toogleModalImageSrc: !this.state.toogleModalImageSrc,
      modalImageSrcCamera: camera
    });
  }

  _choseCameraSrc = (imageSrc) => {
    this.setState({
      imageSrc
    });
  }

  render() {
    const { site } = this.props;
    const {
      isLoading,
      cameras,
      toogleModal,
      toogleModalSettings,
      selectedCamera
    } = this.state;

    console.log(selectedCamera)

    return (<div className="notification card">
      {toogleModal && <Modal handleToogleModal={this._toogleModal} component={<CameraArea site={site} camera={selectedCamera} />} />}
      {toogleModalSettings && <Modal
        handleToogleModal={this._toogleModalSettings}
        component={<CameraAreaV2
          site={site}
          camera={selectedCamera}
          changePictureSrc={this._changePictureSrc}
        />}
        style={{ minWidth: "95%" }}
      />}
      <p className="subtitle is-4"><Icons.VideoIcon /> Cameras</p>
      <CameraForm siteId={site.id} handleSubmit={this._handleNewCamera} toogleModalDvr={this._toogleModalImageSrc} />
      {isLoading && <p className="subtitle is-5" style={{ marginTop: 15, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <span className="button is-loading is-white is-bordless is-large" ></span>
        <span>Aguarde carregando...</span>
      </p>}
      <Columns isMultiline style={{ marginTop: 10 }}>
        {cameras.map(camera => <Column
          key={`admin-cameras-${camera.id}`}
          isSize={12}
        >
          <div
            className={`notification card`}
          >
            <CameraForm
              siteId={site.id}
              handleSubmit={this._handleEditCamera}
              camera={camera}
              toogleModalDvr={this._toogleModalImageSrc}
            />
            <div className="control is-expanded" style={{marginTop: 10 }}>
              <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}
                className="button is-outlined is-fullwidth has-text-centered"
                onClick={this._setCamera(camera)}>
                <Icons.MapMarkedIcon /> <span>Incluir areas</span>
              </div>
            </div>
            <div className="control is-expanded" style={{marginTop: 10 }}>
              <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}
                className="button is-fullwidth has-text-centered"
                onClick={this._setCamera(camera, true)}>
                <Icons.MapMarkedIcon /> <span>Configuração<span className="tag is-primary" style={{ borderRadius: 0, fontWeight: 'bold'}}>NOVO!</span></span>
              </div>
            </div>
          </div>
        </Column>)}
      </Columns>
    </div>);
  }
}

export default (CameraContainer);