import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  withRouter,
  Link
} from "react-router-dom";

import Page from '../../../components/Page';
import Columns, { Column } from '../../../components/Columns';

import {
  CogIcon,
  ArrowLeftIcon,
  UsersIcon,
  TemperatureHighIcon,
  SalesIcon,
  WalkingIcon,
  VideoIcon,
  MapMarkedIcon,
  BoxesIcon,
  FileUploadIcon,
  LayerIcon,
  ClipboardListIcon,
  RobotIcon
} from '../../../components/icons';

import SalesIntegrationAPI from './foottraffic/SalesIntegrationAPI';
import JohannListForm from './johann/JohannListForm';
import UserPage from './users/UserPage';
import VideoSettingForms from './videos/VideoSettingForms';
import VideoVenueSettings from './videos/VideoVenueSettings';
import ShelfSettings from './shelf/ShelfSettings';
import ShareGondola from './ambev/ShareGondola';
import ListGondolas from './ambev/ListGondolas';
import Auditoria from './videos/Auditoria';
import JohannAreasListForm from './johann/areas/JohannAreasListForm';
import IaTraining from './mlops/IaTraining';


class SitePage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      currentMenu: 'users'
    }
  }

  _changeMenu = (newMenu) => () => this.setState({ currentMenu: newMenu });

  _isActive = (current, menu) => current === menu;

  _toggleUserPermission() {
    this.setState({ openUsersTooltip: !this.state.openUsersTooltip });
  }


  render() {
    const { site, tooqAdmin } = this.props;

    if (!site.name) {
      return (<Page>
        <Columns isMultiline>
          <Column isSize={12}>
            <p className="title">{site.name ? site.name : <span>Aguarde <span className="button is-loading is-static is-bordless" /></span>}</p>
            <p className="subtitle"><CogIcon />Configurações e usuários</p>
          </Column>
          <Column isSize={1}>
            <span>
              <Link to="/board" className="heading is-size-5"><ArrowLeftIcon /> Voltar</Link>
            </span>
          </Column>
          <Column isSize={10} className="has-text-centered">
            <p className="subtitle is-4">Aguarde...</p>
          </Column>
        </Columns>
      </Page>)
    }

    if (site.userPermissions.permission !== 0) {
      return (<Page>
        <Columns isMultiline>
          <Column isSize={12}>
            <p className="title">{site.name ? site.name : <span>Aguarde <span className="button is-loading is-static is-bordless" /></span>}</p>
            <p className="subtitle"><CogIcon />Configurações e usuários</p>
          </Column>
          <Column isSize={1}>
            <span>
              <Link to="/board" className="heading is-size-5"><ArrowLeftIcon /> Voltar</Link>
            </span>
          </Column>
          <Column isSize={10} className="has-text-centered">
            Apenas o usuário administrador tem acesso a essa página.
          </Column>
        </Columns>
      </Page>)
    }

    return (<Page>
      <Columns isMultiline>
        <Column isSize={12}>
          <p className="title is-4">{site.name ? site.name : <span>Aguarde <span className="button is-loading is-static is-bordless" /></span>}</p>
          <p className="subtitle is-4"><CogIcon /> Configurações do site</p>
        </Column>
        <Column isSize={12}>
          <Columns className="card notification" isMultiline>
            <Column isSize={12}>
              <div className="tabs is-centered">
                <ul>
                  <li className={this._isActive(this.state.currentMenu, 'users') ? 'is-active' : ''} >
                    <a href onClick={this._changeMenu('users')}>
                      <UsersIcon />
                      <span>Usuários</span>
                    </a>
                  </li>
                  {false && site.modules.foottraffic && <li className={this._isActive(this.state.currentMenu, 'foottraffic') ? 'is-active' : ''}>
                    <a href onClick={this._changeMenu('foottraffic')}>
                      <WalkingIcon />
                      <span>Foot Traffic</span>
                    </a>
                  </li>}
                  {site.modules.sales && <li className={this._isActive(this.state.currentMenu, 'sales') ? 'is-active' : ''}>
                    <a href onClick={this._changeMenu('sales')}>
                      <SalesIcon />
                      <span>Integração vendas</span>
                    </a>
                  </li>}
                  {site.modules.johann && <li className={this._isActive(this.state.currentMenu, 'johann-areas') ? 'is-active' : ''}>
                    <a href onClick={this._changeMenu('johann-areas')}>
                      <LayerIcon />
                      <span>Áreas</span>
                    </a>
                  </li>}
                  {site.modules.johann && <li className={this._isActive(this.state.currentMenu, 'johann') ? 'is-active' : ''}>
                    <a href onClick={this._changeMenu('johann')}>
                      <TemperatureHighIcon />
                      <span>Temperatura</span>
                    </a>
                  </li>}
                  {site.modules.video && tooqAdmin && <li className={this._isActive(this.state.currentMenu, 'video_heatmap') ? 'is-active' : ''}>
                    <a href onClick={this._changeMenu('video_heatmap')}>
                      <MapMarkedIcon />
                      <span>Áreas heatmap</span>
                    </a>
                  </li>}
                  {site.modules.video && tooqAdmin && <li className={this._isActive(this.state.currentMenu, 'cameras') ? 'is-active' : ''}>
                    <a href onClick={this._changeMenu('cameras')}>
                      <VideoIcon />
                      <span>Monitoramento por vídeo</span>
                    </a>
                  </li>}

                  {(site.modules.isAmbev || site.modules.video) && tooqAdmin && <li className={this._isActive(this.state.currentMenu, 'auditoria') ? 'is-active' : ''}>
                    <a href onClick={this._changeMenu('auditoria')}>
                      <ClipboardListIcon />
                      <span>Auditoria</span>
                    </a>
                  </li>}

                  {site.modules.shelf && tooqAdmin && <li className={this._isActive(this.state.currentMenu, 'prateleira') ? 'is-active' : ''}>
                    <a href onClick={this._changeMenu('prateleira')}>
                      <BoxesIcon />
                      <span>Prateleiras</span>
                    </a>
                  </li>}
                  {tooqAdmin && <li className={this._isActive(this.state.currentMenu, 'mlops') ? 'is-active' : ''}>
                    <a href onClick={this._changeMenu('mlops')}>
                      <RobotIcon />
                      <span>ML Ops</span>
                    </a>
                  </li>}
                  {site.modules.isAmbev && tooqAdmin &&
                    <>
                      <li className={this._isActive(this.state.currentMenu, 'uploadXml') ? 'is-active' : ''}>
                        <a href onClick={this._changeMenu('uploadXml')}>
                          <FileUploadIcon />
                          <span>Upload Xmls</span>
                        </a>
                      </li>
                      <li className={this._isActive(this.state.currentMenu, 
                        'listXmlsSave') ? 'is-active' : ''}>
                          <a href onClick={this._changeMenu('listXmlsSave')}>
                            <ClipboardListIcon />
                            <span>Lista Xmls</span>
                          </a>
                      </li>
                    </>
                  }
                </ul>
              </div>
            </Column>

            {this._isActive(this.state.currentMenu, 'users') && <Column isSize={12}>
              <UserPage siteId={site.id} isFullPage={false} />
            </Column>}
            {this._isActive(this.state.currentMenu, 'johann-areas') && <Column isSize={12}>
              <JohannAreasListForm siteId={site.id} />
            </Column>}
            {this._isActive(this.state.currentMenu, 'johann') && <Column isSize={12}>
              <JohannListForm tooqadmin={tooqAdmin} siteId={site.id} />
            </Column>}
            {this._isActive(this.state.currentMenu, 'sales') && <Column isSize={12}>
              <SalesIntegrationAPI siteId={site.id} />
            </Column>}
            {tooqAdmin && this._isActive(this.state.currentMenu, 'cameras') && <Column isSize={12}>
              <VideoSettingForms site={site} />
            </Column>}
            {tooqAdmin && this._isActive(this.state.currentMenu, 'video_heatmap') && <Column isSize={12}>
              <VideoVenueSettings site={site} />
            </Column>}
            {tooqAdmin && this._isActive(this.state.currentMenu, 'prateleira') && <Column isSize={12}>
              <ShelfSettings site={site} />
            </Column>}
            {tooqAdmin && this._isActive(this.state.currentMenu, 'uploadXml') && <Column isSize={12}>
              <ShareGondola site={site} />
            </Column>}
            {tooqAdmin && this._isActive(this.state.currentMenu, 'listXmlsSave') && <Column isSize={12}>
              <ListGondolas site={site} />
            </Column>}
            {(site.modules.isAmbev || site.modules.video) && tooqAdmin && this._isActive(this.state.currentMenu, 'auditoria') && <Column isSize={12}>
              <Auditoria site={site}/>
            </Column>}
            {tooqAdmin && this._isActive(this.state.currentMenu, 'mlops') && <Column isSize={12}>
              <IaTraining site={site} />
            </Column>}
          </Columns>
        </Column>
      </Columns>
    </Page>);
  }
}

function mapStateToProps(state, props) {
  return { site: props.site, tooqAdmin: state.app.user.tooqAdmin };
}

export default withRouter(connect(mapStateToProps)(SitePage));