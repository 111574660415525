import React, {Component} from 'react';

import Avatar from 'react-avatar';
import { del } from '../../../utils';
import notification from '../../../utils/notification';

export default class UserCard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isRevoking: false
    };
  }

  _revokePermission() {
    const { userInfo, site } = this.props;
    this.setState({ isRevoking: true }, () => {
      del(`admin/tooq/users/${userInfo.id}/permission?siteId=${site.id}`)
        .then(resp => {
          notification.success('Removido com sucesso!')
          this.setState({ isRevoking: false }, () => this.props.revokedCB(userInfo));
          return;
        })
    })
  }
  
  render() {
    const { userInfo, canRevoke = true, className, currentUser } = this.props;
    const showRemoveButton = currentUser ? false : canRevoke;
    const { isRevoking } = this.state;

    return (<div className={`card notification ${className ? className : 'userCard' }`}>
      <div className="media">
        <div className="media-left">
          <Avatar style={{ fontFamily: 'Titillium Web' }} maxInitials={3} name={userInfo.displayName} size="48" round/>
        </div>
        <div className="media-content is-small">
          <div>
            <div className="level">
              <div className={`level-left ${showRemoveButton && 'userName'}`}>
                <div className="level-item" style={{ justifyContent: 'left' }}>
                  <p className="title is-5">{userInfo.displayName} </p>
                </div>
              </div>
              {showRemoveButton && <div className="level-right">
                 <div className="level-item">
                 {isRevoking
                    ? <span className="button is-bordless is-loading is-small"></span>
                    : <button type="button" className="delete is-small deleteButton" onClick={this._revokePermission.bind(this)}></button>}
                </div>
              </div>}
            </div>
          </div>
          <div>
            <p className="subtitle is-6">{userInfo.email}</p>
          </div>
          <div className="content is-small">
            <small>{userInfo.permission === 0 ? 'ADMINISTRADOR' : userInfo.permission === 3 ? 'AUDITOR' : 'ANALISTA'}</small>
          </div>
        </div>
      </div>
    </div>);
  }
}