import React, {Component} from 'react';

import _ from 'lodash';

import {
  withRouter,
  Redirect
} from "react-router-dom";

import {format, subDays} from 'date-fns';

import Page from '../../components/Page';

import * as Icons from '../../components/icons';
import {connect} from 'react-redux';

import Columns, { Column } from '../../components/Columns';
import SiteIndice from '../../components/dashboard/SiteIndice';

import { actions as siteActions } from '../../state/sites'; 
import { get, getServerDate } from '../../utils';

class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      reloadingShops: false,
      isLoading: true,
      videoData: []
    }
  }

  _handleSearchTerm = (e) => {
    this.setState({ searchTerm: e.target.value });
  }

  componentDidMount(){
    const { sites } = this.props;
    this._loadData(sites);
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (nextProps.currentGroup !== this.props.currentGroup) {
      this._loadData(nextProps.sites);
    }
  }

  _loadData = (sites) => {
    this.setState({ isLoading: true }, () => {
      const filterSites = sites.filter(s => !s.modules.foottraffic && s.modules.video).map(s => s.id)
      const date = subDays(getServerDate(), 1)
      if(filterSites.length > 0){
        get('dashboard/video/sites/position', {date: format(date, 'YYYY-MM-DD'), sites: filterSites.join(',')})
        .then(res => {
          this.setState({videoData: res.data.data, isLoading: false})
        });
      } else {
        this.setState({isLoading: false})
      }
    });
  }

  render() {
    const { sites, justOneAndJohann, justOneAndVideo, group, justOneAndVideoAndJohannn } = this.props;
    const { searchTerm, isLoading, videoData=[] } = this.state;
    const sitesFiltered = sites.filter(site => site.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1);

    if(justOneAndJohann || justOneAndVideoAndJohannn) {
      return <Redirect to={`/board/sites/${sites[0].id}/dashboard/johann/home`}/>
    }
    
    if(justOneAndVideo) {
      return <Redirect to={`/board/sites/${sites[0].id}/dashboard/video/home`}/>
    }

    
    return (<Page>
        <Columns isMultiline>
          <Column isSize={12}>
            <p className="title">{group.name} {this.state.reloadingShops && <span className="button is-loading is-static is-bordless"/>}</p>
            <p className="subtitle"><Icons.HomeIcon/> Todos os sites</p>
          </Column>
          <Column isSize={12}>
            <div className="field is-grouped">
              <div className="control has-icons-left is-large is-expanded">
                <input
                  className="input is-large"
                  type="text"
                  onChange={this._handleSearchTerm}
                  value={searchTerm}
                  placeholder="Pesquise pelo nome do site" />
                <Icons.SearchIcon className="is-left" />
              </div>
            </div>
          </Column>
          <Column isSize={12}>
            <Columns isMultiline>
              {!sitesFiltered.length && <Column isSize={12}>
                <div className="subtitle is-3 has-text-centered">
                  <div className="button is-static is-bordless is-large" style={{ fontSize: 60 }}></div>
                  <div>Nenhum site encontrado.</div>
                </div>
              </Column>}
              {isLoading && <Column >
                <div className=""  
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      alignItems: 'center',
                      height: 200,
                      width: '100%'
                    }}
                  >
                    <p className="button is-loading is-static is-bordless" style={{ fontSize: 60 }}></p>
                    <p className="subtitle is-4">Aguarde carregando...</p>
                </div>
              </Column>}
              {!isLoading && sitesFiltered
                .map(site => <Column key={site.id} isSize={3}>
                <SiteIndice site={site} videosData={videoData} />
              </Column>)}
            </Columns>
          </Column>
        </Columns>
      </Page>
    );
  }
}

function mapStateToProps(state) {
  const currentGroup = state.sites.currentGroup;
  const sites = _.toArray({ ...state.sites.all })
    .filter(site => site.group.id === currentGroup)
    .filter(site => site.isActive)
    .sort((a, b) => a.name.localeCompare(b.name));

  const justOneAndJohann = (sites.length === 1 && sites[0].modules.johann && !sites[0].modules.foottraffic && !sites[0].modules.video);
  const justOneAndVideo = (sites.length === 1 && sites[0].modules.video && !sites[0].modules.foottraffic);
  const justOneAndVideoAndJohannn = (sites.length === 1 && sites[0].modules.video &&  sites[0].modules.johann && !sites[0].modules.foottraffic);
  const group = state.sites.groups[currentGroup];

  const groupsSelect = _.toArray({ ...state.sites.groups })
    .map(group => ({ value: group.id, label: group.name }));

  return {
    user: state.app.user,
    isLoadingShops: state.app.isLoadingShops,
    justOneAndVideoAndJohannn,
    justOneAndJohann,
    justOneAndVideo,
    sites: sites,
    group,
    currentGroup,
    groupsSelect
  }
}

export default withRouter(connect(mapStateToProps, {
  addSites: siteActions.addMany
})(Home));