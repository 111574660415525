import React, { Component } from 'react';
import Columns, { Column } from '../../../../components/Columns';
import { subDays, format } from 'date-fns';
import { get, getServerDate } from '../../../../utils';
import DateFilterOneRange from '../../../../containers/board/dashboard/video/DateFilterOneRange';
import { addDays } from 'date-fns';

export default class SkusNielsen extends Component {
  constructor(props) {
    super(props);

    const CURRDATE = getServerDate();

    const filterRanges = {
      startDate: format(subDays(CURRDATE, 14), 'YYYY-MM-DD'),
      endDate: format(subDays(CURRDATE, 1), 'YYYY-MM-DD'),
    };

    this.state = {
      siteId               : props.siteId,
      skus                 : [],
      manufacturerOptions  : [],
      categoryOptions      : [],
      manufacturerSelected : null,
      categorySelected     : null,
      isLoading            : false,
      filterRanges
    };
  };


  componentDidMount() {
    this.setState({isLoading: true});

    get(`admin/share-gondola/category`)
      .then(resp => {
        this.setState({categoryOptions: resp.data.data});
      });

    get(`admin/share-gondola/manufacturer`)
    .then(resp => {
      this.setState({manufacturerOptions: resp.data.data})
      this.setState({isLoading: false});
    });
  };

  _changeDate = () => (ranges) => {
    let { endDate, startDate } = ranges;

    startDate = format(startDate, 'YYYY-MM-DD');
    endDate = format(endDate, 'YYYY-MM-DD');

    this.setState({ filterRanges: { ...ranges, startDate, endDate } }, async () => {
      await this._getSkus();
    });
  }

  _getSkus = async () => {
    const { filterRanges, siteId } = this.state;
    this.setState({isLoading: true})
    const skusData = await get(`admin/share-gondola/skus`, {
      beginDate: filterRanges.startDate,
      endDate: filterRanges.endDate,
      siteId: siteId,
      manufacturerId: this.state.manufacturerSelected,
      categoryId: this.state.categorySelected
    })
    this.setState({ skus: skusData.data.data, isLoading: false })
  };
  
  render() {
    const {
      skus,
      filterRanges,
      manufacturerOptions,
      categoryOptions,
      isLoading,
      manufacturerSelected,
      categorySelected
    } = this.state;

    return (
      <Column>
        <div className="card">
          <header className="card-header" style={{ borderBottom: '1px solid #eee', padding: '10px', justifyContent: 'space-between' }}>
            <div className="title is-5 is-marginless">
              Tabela de SKUs
            </div>
            <Columns isMultiline>
              <Column isSize={5}>
                <div className="field">
                  <label className="label">Categoria</label>
                  <div className="control">
                    <div className="select">
                      <select 
                        value={categorySelected} 
                        onChange={(e) => this.setState({categorySelected: e.target.value === 'Selecione uma opção' ? null : e.target.value})}
                      >
                        <option value={null}>{isLoading ? 'Aguarde carregando...' : 'Selecione uma opção'}</option>
                        {categoryOptions.map(category => <option key={`category_option_${category.id}`} value={category.id}>
                          {category.category}
                        </option>)}
                      </select>
                    </div>
                  </div>
                </div>
              </Column>
              <Column isSize={5}>
                <div className="field">
                  <label className="label">Fabricante</label>
                  <div className="control">
                    <div className="select">
                      <select 
                        value={manufacturerSelected} 
                        onChange={(e) => this.setState({manufacturerSelected: e.target.value === 'Selecione uma opção' ? null : e.target.value})}
                      >
                        <option value={null}>{isLoading ? 'Aguarde carregando...' : 'Selecione uma opção'}</option>
                        {manufacturerOptions.map(manufacturer => <option key={`manufacturer_option_${manufacturer.id}`} value={manufacturer.id}>
                          {manufacturer.name}
                        </option>)}
                      </select>
                    </div>
                  </div>
                </div>
              </Column>
            </Columns>
          </header>
          <div className="is-scroller is-justify-center" style={{ maxHeight: 500 }}>
            <table className="table is-fullwidth is-hoverable">
              <thead style={{position: "relative", top: 0}}>
                <tr>
                  <th>ID</th>
                  <th>Data</th>
                  <th>Sku</th>
                  <th>Categoria</th>
                  <th>Fabricante</th>
                  <th>Qnt</th>
                  <th>Preço</th>
                  <th>Largura</th>
                  <th>Facing Quantity</th>
                  <th>Facing Linear</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? 
                  <tr>Aguarde, carregando...</tr>
                : 
                skus.map(sku => {
                  return <tr /*key={`sku_key_${sku.snapTime}`}*/>
                    <td>{sku.id}</td>
                    <td>{sku.snapDate}</td>
                    <td>{sku.skuDescription}</td>
                    <td>{sku.categoryName}</td>
                    <td>{sku.manufacturerName}</td>
                    <td>{sku.qnt}</td>
                    <td>{sku.price}</td>
                    <td>{sku.widthCm}</td>
                    <td>{sku.facingQuantity}</td>
                    <td>{sku.facingLinear}</td>
                  </tr>
                })}
              </tbody>
            </table>          
          </div>
          <footer className="card-footer">
            <span className="button has-text-left is-white card-footer-item is-radiusless is-shadowless is-bordless" style={{ borderRight: 'none' }}>
              <DateFilterOneRange maxDate={addDays(getServerDate(), -1)} changeDates={this._changeDate()} actualRanges={{ ranges: filterRanges }} />
            </span>
            <span className="card-footer-item" style={{ borderRight: 'none' }}>
            </span>
            <span className="card-footer-item" style={{ borderRight: 'none' }}>
            </span>
            <span className="card-footer-item" style={{ borderRight: 'none' }}>
            </span>
          </footer>
        </div>
      </Column> 
    );  
  }
}