import React, { Component } from 'react';

import Page, { PageTitle } from '../../../../components/Page';
import {format} from 'date-fns'
import {pt} from 'react-date-range/src/locale';

import { DateRange } from 'react-date-range';

import Columns, { Column } from '../../../../components/Columns';
import * as Icons from '../../../../components/icons';

import { defaultStaticRanges } from '../filter/defaults';

import Select from 'react-select'
import { get, getServerDate } from '../../../../utils';
import notification from '../../../../utils/notification';
import ModalGroupSensor from '../../../../components/ModalGroupSensor';

class ScheduleList extends Component {
  constructor(props) {
    super(props);

    const startDate = getServerDate();
    const endDate = getServerDate();

    this.state = {
      isLoading:false,
      users: [],
      ranges: {
        startDate,
        endDate,
        key: 'ranges'
      },
      selectUsers: [],
      data: [],
      reportModal: false
    }
  }

  componentDidMount(){
    get(`admin/tooq/users?siteId=${this.props.site.id}`)
        .then(resp => resp.data.data)
        .then(userArray => {
          const selectUsers = userArray.map(({id, displayName}) =>{
            return { value: id, label: displayName}
          });
          this.setState({selectUsers}, () =>{
            this.onSearchClick()
          });
        })
        .catch(err => {
          console.log(err);
        });
  };

  reprocess = (url, id) => {
    console.log(url)
    get(`${url.substring(1)}&reportId=${id}`)
    .then(() => { 
      notification.success('Relatório reprocessado com sucesso.')
      this.onSearchClick()
    });
  };

  onSearchClick = ()=>{
    const { ranges, users,isLoading } = this.state;
    const {group} = this.props
    if(isLoading){
      return;
    };
    const dateIni = ranges.startDate;
    const dateFim = ranges.endDate;
    this.setState({isLoading: true}, () =>{
      get(`dashboard/reports/groups/${group.id}`, { users, dateIni, dateFim})
      .then(resp => {
        const data = resp.data.data
        this.setState({data, isLoading:false})
      })
    })
  };

  handleChange = (newValue) => {
    if(newValue){
      const userIds = newValue.map(val => val.value)
      this.setState({users: userIds})
    }else{
      this.setState({users: []})
    }
  };

  _selectDateRange = params => {
    const { ranges } = this.state;
    ranges['startDate'] = format(params.ranges.startDate, 'YYYY-MM-DD');
    ranges['endDate'] = format(params.ranges.endDate, 'YYYY-MM-DD');
    this.setState({ ranges });
  };

  _handleModal() {
    this.setState({reportModal: !this.state.reportModal})
  };

  render(){
    const { site, group, user } = this.props
    const { ranges, isLoading, data, selectUsers, reportModal } = this.state;
    return (
      <Page>
        {reportModal && <ModalGroupSensor 
          setReportModal={(val) => this.setState({reportModal: val})}
          site={site} 
          groupId={group.id}
          user={user}
        />}
        <PageTitle
          title={<span>
            <p className="title">{group.name} - {site.name}</p>
            <p className="subtitle">Relatórios <Icons.FilePDFIcon /></p>
            <div>
              <button onClick={() => this._handleModal()} className="button is-medium is-outlined is-primary" >
                Gerar Relatório em Grupo
              </button>
            </div>
          </span>}
        />
        <Columns isMultiline>
          <Column isSize={4}>
            <div className="card notification is-white ">
              <div className="field">
                <p className="title is-5" style={{marginBottom: 10}}>Usuários</p>
                <div className="control is-expanded" style={{width: '90%'}}>
                  <Select options={selectUsers} 
                  onChange={this.handleChange} 
                  isMulti 
                  name="colors"
                  placeholder="Usuários"
                  className="basic-multi-select"
                  classNamePrefix="select"/>
                </div>
              </div>
              
              <p className="title is-5" style={{marginBottom: 10}}>Defina o período</p>
              <div className="has-text-centered">
                <DateRange
                  months={1}
                  direction='horizontal'
                  maxDate={getServerDate()}
                  scroll={{ enabled: false }}
                  rangeColors={['#363740']}
                  dragSelectionEnabled={true}
                  locale={pt}
                  ranges={[ranges]} 
                  inputRanges={[]}
                  showPreview={false}
                  staticRanges={defaultStaticRanges}
                  onChange={this._selectDateRange}
                />
              </div>
              <p><button type="button " onClick={this.onSearchClick} className={`button is-dark ${isLoading ? 'is-loading': ''}`} >Pesquisar</button></p>
            </div>
          </Column>
          <Column isSize={8}>
            <div className="card notification is-white ">
            {isLoading ?
              <div className="has-text-centered" style={{ height: 275, paddingTop: '80px' }}>
                <span>
                  <span className="button is-loading is-large is-bordless" />
                  <p className="subtitle is-5">Aguarde, carregando...</p>
                </span>
              </div>:
              <div>
                {data.length === 0 ? 
                <div className="has-text-centered" style={{ height: 110, paddingTop: '45px' }}>
                  <span>
                    <p className="subtitle is-5">Não há informações com os parametros especificados. Realize a consulta novamente.</p>
                  </span>
                </div> : 
                <table className="table is-striped is-hoverable is-fullwidth" style={{ width: '100'}} >
                  <thead>
                    <tr>
                      <th>Data</th>
                      <th>Usuário</th>
                      <th style={{maxWidth:300}}>Descrição</th>
                      <th>Relatório</th>
                      <th>Relatório Detalhes</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody style={{overflowY: 'scroll'}}>
                    {data.map(({userName, description, statusReport, statusDetail, reportDetailLink, reportDetailLinkCsv, reportLink, url, id, snapDate}, index) =>{
                      return (
                        <tr key={`dedo-duro-item-${index}-${id}`}>
                          <td>{snapDate}</td>
                          <td>{userName}</td>
                          <td style={{maxWidth: 300}}>{description}</td>
                          <td>{statusReport === "FINISHED" ? 
                            <a className ="has-text-link" href={reportLink} rel="noopener noreferrer"  target="_blank">Graficos</a>
                          : statusReport === "NOT_EXISTS" ? '-' : 'Processando'}</td>
                          <td>{statusDetail === "FINISHED" ?
                            <div> 
                              <a className ="has-text-link" href={reportDetailLink} rel="noopener noreferrer"  target="_blank"><Icons.FilePDFIcon  className="is-left has-text-danger"/></a>
                              {reportDetailLinkCsv && <a className ="has-text-link" href={reportDetailLinkCsv} rel="noopener noreferrer"  target="_blank"><Icons.ExcelFileIcon className="is-left has-text-success"/></a>}
                            </div>
                          : 'Processando'}</td>
                          <td><button className="button" onClick={() => this.reprocess(url, id)}> Reprocessar </button> </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
                }
              </div>
              }
            </div>
          </Column>
        </Columns>      
      </Page>
    )}
}


export default ScheduleList;