import React, { useEffect, useState } from 'react';
import { IdBadgeIcon } from '../../../../components/icons';


import { get, post } from '../../../../utils';
import IsLoading from '../../../../components/IsLoading';
import Columns, { Column } from '../../../../components/Columns';


// List all days with images to audit
const useImagesDay = (siteId) => {
  const [ daysWithImages, setDaysWithImages ] = useState([]);
  const [ isLoading, setIsLoading ] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    get(`dashboard/video/sites/${siteId}/imagesday`)
      .then(resp => {
        setIsLoading(false);
        setDaysWithImages(resp.data.data);
      });
  }, [ siteId ]);

  return [ isLoading, daysWithImages ]
}

// List all hours from day with images to audit
const useHoursFromImagesDay = (siteId, snapDate, selectedCameras=[]) => {
  const [ hoursDaysWithImages, setHoursDaysWithImages ] = useState([]);
  const [ isLoading, setIsLoading ] = useState(false);
  const selectedCamerasString = selectedCameras.join(',')

  useEffect(() => {
    setIsLoading(true);


    if (!snapDate) {
      setIsLoading(false);
      setHoursDaysWithImages([]);
      return;
    }

    get(`dashboard/video/sites/${siteId}/imagesday/${snapDate}`, {
      cameras: selectedCamerasString
    })
      .then(resp => {
        setIsLoading(false);
        setHoursDaysWithImages(resp.data.data);
      });
  }, [ siteId, snapDate, selectedCamerasString ]);

  return [ isLoading, hoursDaysWithImages ]
}

const useCameras = (siteId, selectedCameras, auditType) => {
  const [ cameras, setCameras ] = useState([]);
  const [ isLoading, setIsLoading ] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    get(`admin/videos/sites/${siteId}/cameras`)
      .then(resp =>  {
        setIsLoading(false);
        setCameras(resp.data.data);
      });
  }, [ siteId ])

  const venues = cameras
    .map(camera => camera.venues)
    .flat()
    .filter(venue => {
      if(auditType === "none") {
        return false;
      }
      
      if(auditType === "visitors") {
        return venue.isVisitor;
      }
      
      if(auditType === "passersby") {
        return venue.isPassersBy;
      }

      if(auditType === "venue") {
        return venue.isVenue || venue.isCheckout || venue.isQueueCheckout;
      }
      
      if(auditType === "eventos") {
        return !venue.isShelf
      }
      
      if(auditType === "shelf") {
        return venue.isShelf;
      }

      if(auditType === "shelf_setup") {
        return venue.isShelf;
      }

      return false;
    })
    .reduce((prev, curr) => {
      prev[curr.venueId] = curr;
      return prev
    }, {});

  const venuesArray = Object.values(venues);
  const venuesAuditType = venuesArray.map(venue => venue.venueId);

  return [ isLoading, cameras.filter(camera => {
    const myVenues = camera.venues.map(venue => venuesAuditType.includes(venue.venueId));
    return myVenues.indexOf(true) !== -1
  }), venuesArray ];
}

const ModalNewAudit = (props) => {
  const NENHUM = "nenhum";
  const NONE = "none"
  const [ auditType, setAuditTypeState ] = useState(NONE);
  const [ selectedVenue, setSelectedVenue ] = useState(NENHUM);

  const [ flowAnalysis, setFlowAnalysis ] = useState(false);
  const [ description, setDescription ] = useState("");
  const [ selectedDay, setSelectedDayState ] = useState(NENHUM);
  const [ selectedHours, setSelectedHours ] = useState([]); 
  const [ selectedCameras, setSelectedCameras ] = useState([]);

  const setAuditType = (auditType) => {
    setAuditTypeState(auditType);
    setSelectedCameras([]);
    setSelectedDay(NENHUM);
    setSelectedVenue(NENHUM);
  }

  const setSelectedDay = (day) => {
    setSelectedHours([]);
    setSelectedDayState(day);
  }

  const blockCameras = auditType === "none" || (auditType === 'venue' && selectedVenue === 'nenhum');
  const blockDays = blockCameras || selectedCameras.length === 0;
  const blockHours = blockDays || selectedDay === "nenhum";
  const blockSave = (blockHours
      || selectedHours.length === 0
      || (auditType === 'shelf_setup' && selectedCameras.length > 1));

  const [ isLoadingCameras, cameras, venues ] = useCameras(props.site.id, selectedCameras, auditType);

  const [ isLoadingDays, daysToAudit ] = useImagesDay(props.site.id, selectedCameras);
  const [ isLoadingHours, hoursToAudit ] = useHoursFromImagesDay(props.site.id, selectedDay, selectedCameras);

  const [ isSaving, setIsSaving ] = useState(false);


  const _setCamera = (cameraId) => {
    setSelectedHours([]);

    if (selectedCameras.includes(cameraId)) {
      setSelectedCameras(selectedCameras.filter(c => c !== cameraId));
      return;
    }

    setSelectedCameras([
      ...selectedCameras,
      cameraId
    ]);
  }

  const _setHour = (hour) => {
    if (selectedHours.includes(hour)) {
      setSelectedHours(selectedHours.filter(h => h !== hour));
      return;
    }

    setSelectedHours([
      ...selectedHours,
      hour
    ]);
  }


  const _submitForm = (e) => {
    e.preventDefault();

    if(blockSave) {
      return;
    }

    if(isSaving) {
      return;
    }

    const venueId = selectedVenue !== 'nenhum' ? selectedVenue : undefined;

    setIsSaving(true);

    // [ "09:00:00", "08:00:00" ]
    const responsesPromises = selectedHours.map(hour => post(`dashboard/video/sites/${props.site.id}/audit`, {
      description,
      cameras: selectedCameras,
      day: selectedDay,
      hour: hour,
      auditType: auditType === 'eventos' ? 'visitors' : auditType,
      flowAnalysis: flowAnalysis,
      venueId
    }));
    
    Promise.all(responsesPromises)
      .then(responses => {
        props.toogleModal(true);
        setIsSaving(false);
      })
  }


  return <div className="modal is-active">
    <div className="modal-background" onClick={() => props.toogleModal()}></div>
    <div className="modal-content is-paddingless " style={{ width: 400 }}>
      <div className="card notification calendarNotDisplay-left-bar">
        <p className="subtitle is-4 has-text-centered">
          <IdBadgeIcon />
          <span>
            Iniciar nova auditoria
          </span>
        </p>
        <form onSubmit={_submitForm}>

          <Columns isGapless={true}>
            <Column>
              <label className="label">Tipo auditoria:</label>
              <div className="field">
                <div className="select" >
                  <select value={auditType} onChange={e => {
                    if(e.target.value !== 'venue') {
                      setSelectedVenue("nenhum")
                    }

                    setAuditType(e.target.value)
                    setFlowAnalysis(e.target.value === 'eventos')
                  }
                }>
                    <option value="none">Selecione o tipo</option>
                    <option value="visitors">Visitantes</option>
                    <option value="passersby">Passantes</option>
                    <option value="venue">Area</option>
                    <option value="eventos">Eventos</option>
                    <option value="shelf">Prateleiras</option>
                    <option value="shelf_setup">Setup de Prateleiras</option>
                  </select>
                </div>
              </div>
            </Column>

            {auditType === "venue" && <Column style={{ marginLeft: 10 }}>
              <label className="label">Áreas:</label>
              <div className="field">
                <div className={`select`}>
                  <select value={selectedVenue} onChange={e => setSelectedVenue(e.target.value)}> 
                    <option value="nenhum">{isLoadingCameras ? "Carregando..." : "Selecione uma área"}</option>
                    {venues.map(v => <option value={v.venueId}>{v.venueDescription}</option>)}
                  </select>
                </div>
              </div>
            </Column>}
          </Columns>
          <label className="label" htmlFor="input-cameras-list">Cameras:</label>
          {isLoadingCameras && <div>
            <IsLoading isBorderless={true} />
          </div>}
          {!isLoadingCameras && blockCameras && <div>
            {auditType === "venue" ? "Selecione uma area" : "Selecione o tipo de auditoria"}
          </div>}

          {!isLoadingCameras && !blockCameras && cameras.length === 0 && <div>
            Não há cameras
          </div>}
          {!isLoadingCameras && !blockCameras && cameras.length !== 0 && <div>
            {cameras.map(camera => <span
              className={`tag is-default ${isLoadingDays ? 'cursor-loading' : "cursor-pointer"} is-selectable ${selectedCameras.includes(camera.id) ? 'is-primary': ''}`}
              key={`camera_id_option_tag_${camera.id}`}
              value={camera.id}
              onClick={() => !isLoadingDays && _setCamera(camera.id)}  
            >
              {camera.description}
            </span>)}
          </div>}
          <br/>
          <label className="label" htmlFor="select-audit-days">Dias Disponíveis:</label>
          <div className="field has-addons">
            <div className="control is-expanded">
              <div className={`select is-fullwidth`}>
                <select disabled={blockDays} value={selectedDay} onChange={(e) => setSelectedDay(e.target.value)} id="select-audit-days" name="camera">
                  {isLoadingDays
                    ? <option value="nenhum">Carregando...</option>
                    : <option value="nenhum">{blockDays ? "Informe uma camera" : "Selecione um dia"}</option>}
                  {daysToAudit.map(day => <option key={`day_to_audit_option_${day}`} value={day}>
                    {day}
                  </option>)}
                </select>
              </div>
            </div>
            <div className="control">
              {/* <button type="button" className="button is-primary" onClick={this._includeCamera}>Adicionar</button> */}
            </div>
          </div>
          <br/>
          <label className="label" htmlFor="input-cameras-list">Horas:</label>
          <div>
            {isLoadingHours && <IsLoading isBorderless={true}/>}
            {!isLoadingHours && !blockHours && hoursToAudit.length === 0 && <div>
              Não há horários
            </div>}
            {!isLoadingHours && blockHours && <div>
              Selecione um dia.
            </div>}
            {!isLoadingHours && !blockHours && hoursToAudit.length !== 0 && <div>
              {hoursToAudit.map(hour => <span
                style={{
                  marginRight: 4,
                  marginBottom: 3
                }}
                onClick={() => _setHour(hour)}
                className={`tag is-default cursor-pointer is-selectable ${selectedHours.includes(hour) ? 'is-primary': ''}`}>
                  {hour}
              </span>)}
            </div>}
          </div>
          <br/>
          <label className="label" htmlFor="input-audit-observation">Descrição</label>
          <div className="field">
            <div className="control">
              <input
                id="input-audit-observation"
                className="input"
                type="text"
                placeholder="Ex: contarei só blusas branca"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
          </div>
          
          {auditType === 'shelf_setup' && selectedCameras.length > 1 && <p className="help is-danger">
            Para setup de modelo você só pode selecionar 1 camera por vez.
          </p>}

          <div className="buttons">
            {!blockSave ? <button
              type="submit"
              className={`button is-primary ${isSaving ? 'is-loading': ''}`}
            >
              Salvar
            </button> : <span
              className={`button is-static`}
            >
              Salvar
            </span>}
            <span
              className={`button`}
              onClick={() => props.toogleModal()}
            >
              Cancelar
            </span>
            
          </div>
        </form>
      </div>
    </div>
    <button className="modal-close is-large" onClick={() => props.toogleModal()} aria-label="close"></button>
  </div>
}

export default ModalNewAudit