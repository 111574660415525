import React, { Component } from 'react';

import {
  format, differenceInDays, addDays
} from 'date-fns';

import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  LabelList,
  Tooltip,
  Brush
} from 'recharts';

import DateFilterOneRange from './DateFilterOneRange';
import LabelCustomizedAuditing from './LabelCustomizedAuditing';
import DownloadButton from '../../../../../components/DownloadButton';
import * as Icons from '../../../../../components/icons';
import { getServerDate } from '../../../../../utils';

class VisitorsAndPassersByHourly extends Component {

  constructor(props) {
    super(props);
    const CURRDATE = getServerDate();

    const chartDate = addDays(CURRDATE, -1);

    
    const {
      filterRanges = {
        startDate: format(addDays(chartDate, -6), 'YYYY-MM-DD') ,
        endDate: format(chartDate, 'YYYY-MM-DD'),
        key: 'selectionRange1'
      },
      dash = {
        header: {},
        data: []
      }, 
      dashVideo = false
    } = props;

    this.state = {
      isLoading: false,
      typeChart: 'HOURLY',
      filterRanges: filterRanges,
      dash: dash,
      dashVideo
    }
  }
  
  componentDidMount() {
    this.loadData();
  }

  renderChartLegend = (props) => {
    const { payload } = props;
    const { filterRanges } = this.state;

    if(!payload) {
      return <div></div>;
    }

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}> 
        <div className="help has-text-centered">
          Indicador da taxa de visitantes da loja, 
          uma linha do tempo de <b>{format(filterRanges.startDate, 'DD/MM/YY')} </b> até <b>{format(filterRanges.endDate, 'DD/MM/YY')} </b> 
          comparando o número de passantes com o número de visitantes.
        </div>
        <ul style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
          {payload.map((entry, index)=> {
            return <li key={`legenditem-${index}`} style={{ marginRight: '10px', color: entry.color }}>
              <b>
                {entry.dataKey === "passersByCount" ? 'Passantes' 
                  : entry.dataKey === "visitorsCount" ? 'Visitantes'
                  : entry.dataKey === "visitorsBeforeCount" ? 'Visitantes permanecentes hora anterior'
                  : 'Taxa de visitantes'}
              </b>
            </li>
          })}
        </ul>
      </div>
    );
  }


  _changeDateFilter = () => (ranges) => {
    let { endDate, startDate } = ranges;

    startDate = format(startDate, 'YYYY-MM-DD');
    endDate = format(endDate, 'YYYY-MM-DD');

    this.setState({ filterRanges: { ...ranges, startDate, endDate } }, () => {
      this.loadData();
    });
  }

  loadData = () => {
    this.setState({ isLoading: true }, () => {
      const { typeChart, filterRanges } = this.state;
      this.props.loadPeopleCount(typeChart, filterRanges.startDate, filterRanges.endDate)
        .then(result => {
          this.setState({ isLoading: false, dash: result });
        });
    });
  }

  render() {
    const { dash, filterRanges, isLoading, dashVideo } = this.state;
    const diffBetweenDates = differenceInDays(filterRanges.endDate, filterRanges.startDate);
    const brushSize = 24 * (diffBetweenDates);
    return (<div className="card" style={{
      width: '100%'
    }}>
      <header className="card-header" style={{ padding: '20'}}>
        <p className="card-header-title is-5 is-marginless"style={{ fontSize: '1.25rem'}}>
          Taxa de visitantes - Hora
        </p>
        <span className="card-header-icon" aria-label="Download PDF">
          <DownloadButton site={this.props.site} name='peoplecount' begin={filterRanges.startDate} end={filterRanges.endDate} params={{type: 'HOURLY'}} video={dashVideo}/>
        </span>
      </header>
      <div className="card-content">
        <p className="subtitle is-4 has-text-centered">
          Dados de 
          {!isLoading 
            ? diffBetweenDates === 0 
              ? `${format(filterRanges.endDate, ' DD/MM/YY')}` : `${format(filterRanges.startDate, ' DD/MM/YY')} até ${format(filterRanges.endDate, 'DD/MM/YY')}`
            : ' ...'
          }
        </p>
        {isLoading 
        ? <div className="has-text-centered" style={{ height:400, paddingTop: '100px' }}>
            <span>
              <span className="button is-loading is-large is-bordless" />
              <p className="subtitle is-5">Aguarde, carregando...</p>
            </span>
          </div>
        : <ResponsiveContainer width="100%" height={300}>
          <ComposedChart data={dash.data}
              margin={{top: 20, right: 50, left: 50, bottom: 20}}>
            <CartesianGrid strokeDasharray="3 10" />
            <XAxis interval={0} dataKey="info_date" tickFormatter={(val) => `${format(val, 'HH:mm')}`}/>
            <YAxis yAxisId="left" orientation="left" stroke="#343434"/>
            <YAxis yAxisId="right" orientation="right" stroke="#343434" tickFormatter={(val) => `${val}%`}/>
            <Legend content={this.renderChartLegend} />
            <Brush
              startIndex={diffBetweenDates >= 1 ? brushSize + 7 : 7}
              endIndex={diffBetweenDates >= 1 ? brushSize + 22 : 22}
              dataKey='info_date'
              tickFormatter={(val) => `${format(val, 'DD/MM - HH:mm')}`}
              height={20}
              stroke="#000000"/>
            <Tooltip
              content={<CustomToolTip dashVideo={dashVideo}/>}
              isAnimationActive={false}/>
            <Bar
              key="visitors"
              yAxisId="left"
              name="Visitantes"
              dataKey={dashVideo ? "newVisitorsCount" : "visitorsCount"}
              maxBarSize={15}
              fill="#00d1b2"
              stroke="#00d1b2"
              stackId="peoplecount"
              isAnimationActive={false}
              minPointSize={5}
              connectNulls={true}
            />
            <Bar
              key="passersBy"
              yAxisId="left"
              name="Passantes"
              dataKey="passersByCount"
              maxBarSize={15} 
              fill="#0043ff"
              stroke="#0043ff"
              isAnimationActive={false}
              minPointSize={3}
              stackId="peoplecount"
              connectNulls={true}
            >
              <LabelList
                dataKey="auditingOk"
                position="top"
                content={<LabelCustomizedAuditing />}
              />
            </Bar>
            <Line key="taxaConversao"
              yAxisId="right"
              name="Taxa de visitantes"
              dataKey={dashVideo ?"visitorsRate": "conversionRate"}
              strokeDasharray="3 5"
              fill="#000"
              stroke="#000"
              isAnimationActive={false}
              connectNulls={true}
            >
              <LabelList dataKey={dashVideo ?"visitorsRate": "conversionRate"} position="top" formatter={(val) => `${val}%`} fontSize={22} />
            </Line>
          </ComposedChart>
        </ResponsiveContainer>} 
      </div>
      <footer className="card-footer">
        <span className="button has-text-left is-white card-footer-item is-radiusless is-shadowless is-bordless" style={{ borderRight: 'none' }}>
          <DateFilterOneRange maxDate={addDays(getServerDate(), -1)} changeDates={this._changeDateFilter()} actualRanges={{ ranges: filterRanges }}/>
        </span>
        <span className="card-footer-item" style={{ borderRight: 'none' }}>
        </span>
        <span className="card-footer-item" style={{ borderRight: 'none' }}>
        </span>
        <span className="card-footer-item" style={{ borderRight: 'none' }}>
        </span>
      </footer>
    </div>)
  }
}

class CustomToolTip extends Component {
  render() {
    const { active, payload=[], dashVideo } = this.props;
    if(active) {

      let data = { payload: {} };
      if(payload && payload.length > 0){
        data = payload[0]
      }

      return (<div className="card notification" style={{ padding: '0px 5px 0px 5px' }}>
        <p className="heading has-text-centered">
          Em {format(data.payload.info_date, 'DD/MM HH:mm')}
        </p>

        <span className="heading has-text-centered" style={{ fontWeight: 'bold', marginBottom: '-5px', color: '#000000' }}>
          <span>Taxa visitantes</span>
        </span>
        <span className="heading has-text-centered" style={{ color: '#000000', marginBottom: 10 }}>
          <span>{dashVideo ? data.payload.visitorsRate : data.payload.conversionRate}%</span>
        </span>

        <span className="heading has-text-centered" style={{ fontWeight: 'bold', marginBottom: '-2px', color: '#222222' }}>
          <span>Visitantes</span>
        </span>
        <span className="heading has-text-left" style={{ marginLeft: 15, marginBottom: '-5px', fontWeight: 'bold', color: '#00d1b2' }}>
          <span>Novos: { dashVideo ? data.payload.newVisitorsCount : data.payload.visitorsCount}</span>
        </span>
        <span className="heading has-text-left" style={{ marginLeft: 15, marginBottom: '-5px', color: '#222222' }}>
          <span>Hora Anterior: {dashVideo ? data.payload.visitorsCount - data.payload.newVisitorsCount  : data.payload.visitorsBeforeCount}</span>
        </span>
        <span className="heading has-text-left" style={{ marginLeft: 15, color: '#222222', marginBottom: 10 }}>
          <span>Total: {dashVideo ? data.payload.visitorsCount:  data.payload.visitorsCountTotal} </span>
        </span>

        <span className="heading has-text-centered" style={{ fontWeight: 'bold', marginBottom: '-5px', color: '#0043ff' }}>
          <span>Passantes</span>
        </span>
        <span className="heading has-text-centered" style={{ color: '#0043ff', marginBottom: 10 }}>
          <span>{data.payload.passersByCount}</span>
        </span>

        {!data.payload.auditingOk && <span
          className="help is-danger">
          <Icons.ExclamationCircleIcon />
          <span>
            Dados não confiáveis.
          </span>
        </span>}
      </div>)
    }
    return null;
  }
}

export default (VisitorsAndPassersByHourly);