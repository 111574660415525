import React, { Component } from 'react';
import _ from 'lodash';
import { IdBadgeIcon } from '../../../../components/icons';
import { format } from 'date-fns';
import {pt} from 'date-fns/locale';
import Columns, { Column } from '../../../../components/Columns';
import { get,post, put } from '../../../../utils';
import IsLoading from '../../../../components/IsLoading';


class ModalShelfSetup extends Component {

  constructor(props) {
    super(props);

    this.state = {
      cameras: [],
      images: [],
      currentPosition: 0,
      isloading: false,
      invalidShelfs: []
    }
  }

  componentDidMount() {
    const { site, audit } = this.props;
    this.setState({ isloading: true, isLoadingCameras: true }, () => {
      get(`dashboard/shelf/sites/${site.id}/audits/${audit.id}/shelf-setup`)
      .then(resp => {
        const { data, shelfs } = resp.data;
        const currentPosition = 0;

        this.setState({
          isloading: false,
          images: data,
          shelfs,
          currentPosition
        }, () => {
          this._next(currentPosition);
          this._loadCameras()
        });
      });
    });
  }

  _markShelfAsInvalid = (shelf) => () => {
    const { invalidShelfs=[] } = this.state;

    if(invalidShelfs.includes(shelf.venueId)) {
      this.setState({
        invalidShelfs: invalidShelfs.filter(s => s !== shelf.venueId)
      });
      return;
    }

    this.setState({
      invalidShelfs: [ ...invalidShelfs, shelf.venueId ]
    })
  }
  
  _handleConcluir = () => {
    const { isConcluindo=false } = this.state;
    const { site, audit } = this.props;

    if(isConcluindo) {
      return;
    }

    this.setState({ isConcluindo: true }, () => {
      const chunks = _.chunk(this.state.images, 10)
        .map(images => post(`dashboard/shelf/sites/${site.id}/audits/${audit.id}/shelf-setup`, {
          images: images
        }));
      Promise.all(chunks)
      .then(() => {
        return put(`dashboard/video/sites/${site.id}/audit/${audit.id}/status`, {
          status: "1"
        });
      })
      .then(resp => {
        this.setState({ isConcluindo: false }, () => {
          this.props.toogleModal(true);
        });
      });
    })
  }

  _makeShelfsIntoDataset = (imageId, shelfs, cb) => () => {
    const { invalidShelfs, images } = this.state;

    const shelfsInDataset = shelfs.map(shelf => {
      return {
        venueId: shelf.venueId,
        polygon_points: shelf.polygon_points,
        description: shelf.description,
        anomaly: invalidShelfs.includes(shelf.venueId) ? 1 : 0
      }
    });

    this.setState({
      images: images.map(image => {
        if(image.id === imageId) {
          const needToBeSend = JSON.stringify(shelfsInDataset) !== JSON.stringify(image.shelfsInDataset);
          return { ...image, needToBeSend, shelfsInDataset }
        }
        return { ...image };
      })
    }, cb);
  }

  _loadCameras = () => {
    const { site } = this.props;
    this.setState({ isLoadingCameras: true }, () => {
      get(`admin/videos/sites/${site.id}/cameras`)
        .then(resp => {
          const cameras = resp.data.data;
          this.setState({ cameras, isLoadingCameras: false });
        });
    })
  }

  _handleCancelButton = () => {
    this.props.toogleModal();
  }

  _loadPrevious = (i) => () => {
    let nextPosition = this.state.currentPosition - i;
    if(nextPosition < 0){
      nextPosition = 0;
    }

    this._next(nextPosition);
  }

  _loadNext = (i) => () => {
    let nextPosition = this.state.currentPosition + i;
    if(nextPosition >= this.state.images.length){
      nextPosition = this.state.images.length - 1;
    }
    
    this._next(nextPosition);
  }

  _next(nextPosition) {
    const alreadyChecked = this.state.images[nextPosition].shelfsInDataset.length !== 0;
    const invalids = alreadyChecked
      ? this.state.images[nextPosition]
          .shelfsInDataset
          .filter(shelf => shelf.anomaly)
          .map(shelf => shelf.venueId)
      : this.state.invalidShelfs;

    const invalidsBefore = [ ...invalids ];

    this.setState({
      invalidShelfs: invalidsBefore,
      currentPosition: nextPosition
    });
  }

  render() {
    const {
      audit
    } = this.props;

    const {
      currentPosition,
      isloading,
      isLoadingCameras,
      cameras,
      images,
      shelfs
    } = this.state;

    const actualToShow = images[currentPosition] || {
      id: 0,
      imageUrl: "",
      cameraId: 0,
      snapTime: "25:25:25",
      shelfsInDataset: []
    }
    

    const next20 = images.slice(currentPosition, currentPosition + 20);
    const camsAudit = audit.cameras.map(camera => camera.id);
    const isLoading = isloading || isLoadingCameras;


    return <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card" style={{ backgroundColor: 'white', width: '95%', height: '90%', borderRadius: 5  }}>
        <div className="modal-card-body">
          <p className="title is-4 has-text-left">
            <IdBadgeIcon />
            <span>
              Setup de Planograma
            </span>
          </p>
          <p className="subtitle is-5 has-text-left">
            <b>#{audit.id}</b> "{audit.description}" {format(audit.snapDate, 'DD MMMM', { locale: pt })} {audit.snapTime} até {audit.snapTime.replace('00:00', '59:59')}
          </p>
          <Columns>
            {isLoading && <Column className="chart_justifycenter" isSize={12}>
              <IsLoading fontSize={60} isBorderless={true} />
            </Column>}
            {next20.map((image, i) => {
              const camera = cameras.find(cam => parseInt(cam.id) === parseInt(image.cameraId)) || {
                width: 1200,
                height: 720
              };

              return <svg
                key={`shelf_audit_next_image_${image.id}_${camera.width}x${camera.height}_${i}`}
                width={camera.width}
                height={camera.height}
                style={{ display: 'none' }}>
                <image
                  xlinkHref={image.imageUrl}
                  x="0"
                  y="0"
                  width={camera.width}
                  height={camera.height}
                />
              </svg>
            })}

            {!isLoading && cameras
            .filter(camera => camsAudit.includes(camera.id))
            .map(camera => <Column key={`shelf_audit_camera_${camera.id}__${currentPosition}`}>
              <p className="help heading"><b>#{camera.id}</b>{camera.description}</p>
              <svg width={camera.width} height={camera.height} style={{ marginTop: 10, borderRadius: 4 }}>
                <image
                  xlinkHref={actualToShow.imageUrl}
                  x="0"
                  y="0"
                  width="100%"
                  height="100%"
                />
                {shelfs
                  .map(shelf => {
                    const isMarkedAsInvalid = this.state.invalidShelfs.includes(shelf.venueId);
                    return <polyline
                      key={`shelf_audit_camera_${camera.id}__${currentPosition}_${shelf.venueId}_${isMarkedAsInvalid}`}
                      onClick={this._markShelfAsInvalid(shelf)}
                      cursor="pointer"
                      points={shelf.polygon_points}
                      fillOpacity={0}
                      stroke={isMarkedAsInvalid ? "red" : "green"}
                      strokeWidth={3}
                    />
                  })}
              </svg>
            </Column>)}
          </Columns>
        </div>
        
        <footer className="modal-card-foot">
          <Columns style={{ width: '100%', justifyContent: 'center' }}>
            <Column className="is-narrow">
              <p className="subtitle is-5 has-text-centered" style={{marginBottom: 5}}>Imagem {currentPosition + 1} de {images.length} </p>
              <div className="buttons is-centered" style={{marginBottom: 5}}>
                <span className={`button`} onClick={this._makeShelfsIntoDataset(actualToShow.id, shelfs, this._loadPrevious(1))}>Voltar</span>
                {currentPosition === images.length - 1 && <span
                  className={`button is-primary ${this.state.isConcluindo ? 'is-loading': ''}`}
                  onClick={() => this._handleConcluir()}>
                  Concluir
                </span>}
                <span className={`button`} onClick={this._makeShelfsIntoDataset(actualToShow.id, shelfs, this._loadNext(1))}>Avançar</span>
              </div>
              <progress
                className={`progress is-primary `}
                value={currentPosition}
                max={images.length}>
              </progress>
            </Column>
          </Columns>
        </footer>
      </div>
      <button className="modal-close is-large" onClick={this._handleCancelButton} aria-label="close"></button>
    </div>
  }
}

export default ModalShelfSetup;