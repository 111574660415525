import React, { Component } from 'react';
import withRouter from "react-router-dom/withRouter";
import { connect } from 'react-redux';
import { PlayCircle } from '../../../../../../components/icons';
import Page, { PageTitle } from '../../../../../../components/Page';
import VisitorsAndPassersByHourly from './VisitorsAndPassersByHourly';
import SumaryDash from './SumaryDash';
import { get } from '../../../../../../utils';

class DashRealtime extends Component {

  constructor(props){
    super(props)

    this.state ={
      isLoading: true,
      visitorsHour: { header: {}, data: [] },
      salesHour: { header: {}, data: [] },
      visitors: { header: {}, data: [] },
      sales: { header: {}, data: [] }
    }
  }
  
  componentDidMount(){
    this.loadData();
  }

  loadData = () => {
    const {site} = this.props;
    this.setState({ isLoading: true }, () => {
      const promVisitorsLast = get(`dashboard/foottraffic/sites/${site.id}/peoplecount/realtime`, {type: 'HOURLY'})
      .catch(err => {
        return { data: { header: {}, data: [] } };
      }).then(resp => resp.data);

      const promSalesLast = get(`dashboard/foottraffic/sites/${site.id}/salesrate/realtime`, {type: 'HOURLY'})
      .catch(err => {
        return { data: { header: {}, data: [] } };
      }).then(resp => resp.data);
      
      const promVisitors = get(`dashboard/foottraffic/sites/${site.id}/peoplecount/realtime`)
      .catch(err => {
        return { data: { header: {}, data: [] } };
      }).then(resp => resp.data);

      const promSales = get(`dashboard/foottraffic/sites/${site.id}/salesrate/realtime`)
      .catch(err => {
        return { data: { header: {}, data: [] } };
      }).then(resp => resp.data);

      Promise.all([promVisitorsLast, promSalesLast, promVisitors, promSales])
        .then(pro => {
          this.setState({isLoading:false, visitorsHour: pro[0], salesHour: pro[1], visitors: pro[2], sales: pro[3]})
        })
    });
  }

  render () {
    const { site } = this.props;
    const {isLoading, visitorsHour, salesHour, visitors, sales} = this.state;
    return (
    <Page>
      <PageTitle
        title={<span>
          <span style={{ marginRight: '10px' }}>
            {`${(site && site.name) || ''} `}
          </span>
          <p className="subtitle">Tempo Real <PlayCircle /></p>
        </span>}
      />
      <div className="tile is-vertical is-ancestor margin-top-10">
        <div className="tile is-12 is-primary">
          <div className="tile is-parent">
            <SumaryDash site={site} isLoading={isLoading} 
                        visitorsHour={visitorsHour} salesHour={salesHour} 
                        visitors={visitors} sales={sales}/>
          </div>
        </div>
      </div>
      <div className="tile is-vertical is-ancestor margin-top-10">
        <div className="tile is-12 is-primary">
          <div className="tile is-parent">
            <VisitorsAndPassersByHourly site={site} isLoading={isLoading} visitorsHour={visitorsHour} salesHour={salesHour}/>
          </div>
        </div>
      </div>
    </Page>)
  }
}

function mapStateToProps(state, props) {
  return { site: props.site }
}

export default withRouter(connect(mapStateToProps)(DashRealtime));