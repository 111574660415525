import React, { Component } from 'react';

import {
  format, subDays, toDate
} from 'date-fns';

import CalendarDatePicker from '../../calendar/CalendarDatePicker';
import { getServerDate } from '../../../../../utils';

class DatePickerFilter extends Component {

  constructor(props) {
    super(props);

    this.state = {
      toogleFilter: false
    }
  }

  _toogleFilter = () => {
    this.setState({ toogleFilter: !this.state.toogleFilter });
  }

  _confirmSelectedDates = (date) => {
    this.setState({
      currDate: date
    }, () => {
      const { currDate=toDate(this.props.currDate) } = this.state;
      this.props.changeDates(currDate);
      this._toogleFilter();
    });
  }

  _renderLabel = (date) => {
    if(this.props.renderLabel) {
      return this.props.renderLabel(date);
    }
    return <span style={{ textDecoration: 'underline' }}>
      Data selecionada <b>{format(date, 'DD/MM/YYYY')}</b>
    </span>
  }

  render() {
    const { toogleFilter } = this.state;
    const { currDate=toDate(this.props.currDate) } = this.state;
    const { className = 'bottom-left-to-right' } = this.props;

    return <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
      <div className="tooltip">
        <div
          className="cursor-pointer help"
          onClick={this._toogleFilter}
        >
          {this._renderLabel(currDate)}
        </div>
        {toogleFilter && <span className={`tooltiptext ${className}`}>
          <CalendarDatePicker
            maxDate={subDays(getServerDate(), 1)}
            date={currDate}
            close={this._toogleFilter}
            onChange={this._confirmSelectedDates}
          />
        </span>}
      </div>
    </div>
  }
}

export default (DatePickerFilter);
