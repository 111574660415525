import React, { Component } from 'react';
import {
  ResponsiveContainer,
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  Line,
  Tooltip,
  Legend,
} from 'recharts';
class ParedaoChart extends Component {
  _secondsToLabel = (val) => {
    if(val === 0)
      return `0 min 0 seg`;

    const [ minutes, seconds='0' ] = `${val/60}`.split('.');
    const secondsLabel = `${60 * parseInt(seconds.slice(0,2)) / 10}`.slice(0, 2);

    if (seconds === '0' || parseInt(secondsLabel) >= 60)
      return `${minutes} min`;
      

    return `${minutes} min ${secondsLabel} seg`;
  }
  render(){
    const {data} = this.props;
    return (<div>
      <div style={{marginTop: 0}}>
        <p style={{margin:0}} className="heading has-text-centered is-size-6">Paredão </p>
        <ResponsiveContainer width="100%" height={250}>
          <ComposedChart data={data}
            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
            <XAxis dataKey="row" minTickGap={1} />
            <YAxis yAxisId="left" />
            <YAxis yAxisId="right" orientation="right" tickFormatter={val => this._secondsToLabel(val)}  />
            <Tooltip content={<CustomToolTip />}/>
            <Legend />
            <Bar name="Interações com o Paredão" yAxisId="left" dataKey="visitantesInteracao" stackId="a" barSize={20} fill="#2C45B4" />
            <Bar name="Pessoas no Paredão" yAxisId="left" dataKey="visitantesChart" stackId="a"barSize={20} fill="#00d1b2" />
            <Line name="Tempo Médio de Interação" type="monotone" yAxisId="right" dataKey="tempoMedioInteracao"  tickFormatter={val => this._secondsToLabel(val)} fill="#363636" stroke="#363636" strokeWidth={2} />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>)
  }
}


class CustomToolTip extends Component {
  _secondsToLabel = (val) => {
    if(val === 0)
      return `0 min 0 seg`;

    const [ minutes, seconds='0' ] = `${val/60}`.split('.');
    const secondsLabel = `${60 * parseInt(seconds.slice(0,2)) / 10}`.slice(0, 2);

    if (seconds === '0' || parseInt(secondsLabel) >= 60)
      return `${minutes} min`;
      

    return `${minutes} min ${secondsLabel} seg`;
  }
  render() {
    const { active } = this.props;
    if(active) {
      const { payload=[] } = this.props;
      const data = payload[0] || { payload: {} };
      return (<div className="card" style={{ padding: 10 }}>
          <p style={{ marginBottom: 10}}>
            {data.payload.row}
          </p>
          <p style={{color: '#00d1b2', marginBottom: 10}}>
            Pessoas no Paredão : {data.payload.visitantes}
          </p>
          <p style={{color: '#2C45B4', marginBottom: 10}}>
            Interações com o Paredão : {data.payload.visitantesInteracao}
          </p>
          <p style={{color: '#363636', marginBottom: 5}}>
            Tempo Médio de Interação : {this._secondsToLabel(data.payload.tempoMedioInteracao)}
          </p>

          
      </div>)
    }
    return null;
  }
}

export default ParedaoChart;