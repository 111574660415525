
import React, { Component } from 'react';

import { get } from '../../../../../utils';
import Columns, { Column } from '../../../../../components/Columns';
import * as Icons from '../../../../../components/icons';
import DeviceForm from './DeviceForm';
import Modal from '../../../../../components/Modal';
import DeviceCamera from './DeviceCamera';

class DeciveContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      devices: [],
      toogleModal: false,
      selectedDevice: {},
      toogleAdd: false
    }
  }

  componentDidMount() {
    this._loadDevices();
  }

  _loadDevices = () => {
    const { id } = this.props.site;
    this.setState({ isLoading: true }, () => {
      get(`admin/videos/sites/${id}/devices`)
        .then(resp => {
          const devices = resp.data.data;
          this.setState({ devices, isLoading: false });
        });
    });
  }

  _handleNewDevice = (device) => {
    const devices = [ ...this.state.devices, device ];
    if(devices.length === 1)  {
      this.setState({ devices });
      return;
    }
    this.setState({ devices });
  }

  _handleEditDevice = (device) => {
    const devices = this.state.devices.map(d => {
      if(d.id === device.id) {
        return device;
      }
      return d;
    });

    this.setState({ devices });
  }

  _changeDeviceCameras = (cameras) => {
    this.setState({ camerasFromDevice: cameras });
  }

  _toogleAdd = () => {
    this.setState({ toogleAdd: !this.state.toogleAdd });
  }

  _toogleModal = () => {
    this.setState({ toogleModal: !this.state.toogleModal });
  }

  _setDevice = (device) => () => {
    this.setState({ selectedDevice: device }, this._toogleModal);
  }

  render() {
    const { site } = this.props;
    const {
      toogleModal,
      selectedDevice,
      isLoading,
      devices
    } = this.state;

    return (<div className="notification card">
      {toogleModal && <Modal style={{ width: '50%' }} handleToogleModal={this._toogleModal} component={<DeviceCamera site={site} device={selectedDevice} />} />}
      <p className="subtitle is-4"><Icons.HddIcon /> Video devices</p>    
      <DeviceForm siteId={site.id} handleSubmit={this._handleNewDevice} />
      {isLoading && <p className="subtitle is-5" style={{ marginTop: 15, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <span className="button is-loading is-white is-bordless is-large" ></span>
        <span>Aguarde carregando...</span>
      </p>}
      <Columns isMultiline style={{ marginTop: 10 }}>
        {devices.map(device => <Column
          key={`admin-devices-${device.id}`}
          isSize={12}
        >
          <div
            className={`notification card`}
          >
            <DeviceForm siteId={site.id} handleSubmit={this._handleEditDevice} device={device} />
            <div className="control is-expanded" style={{marginTop: 10 }}>
              <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }} className="button is-small is-primary is-outlined is-fullwidth has-text-centered" onClick={this._setDevice(device)}>
                <Icons.VideoIcon /> <span>Incluir Cameras</span>
              </div>
            </div>
          </div>
        </Column>)}
      </Columns>
    </div>);
  }
}

export default (DeciveContainer);