import React, { useEffect, useState } from 'react';

import Columns, { Column } from '../../../../components/Columns';
import notification from '../../../../utils/notification';
import { TrashIcon } from '../../../../components/icons/index'
import { get, del, getServerDate } from '../../../../utils';
import { format } from 'date-fns';

const ListGondolas = (props) => {

    const { site } = props;
    const [listXmls, setListXmls] = useState([]);
    const [period, setPeriod] = useState('morning');
    const [snapDate, setSnapDate] = useState(format(getServerDate(1), 'YYYY-MM-DD'));

    const changeSnapDate = (e) => {
        setSnapDate(e.target.value);
    };

    const changePeriod = (e) => {
        setPeriod(e.target.value);
    };

    useEffect(() => {
      get(`admin/ambev/sites/${site.id}/manualxml`, {
        period: period,
        snapDate: snapDate
      })
      .then(resp => {
        setListXmls(resp.data)
      })
    }, [site.id, period, snapDate]);

    const deleteXml = (xmlId) => {
        del(`admin/ambev/sites/${site.id}/manualxml/${xmlId}`)
            .then((resp) => {
                const newXmlList = listXmls.filter((xml) => xml.id !== xmlId);
                setListXmls(newXmlList);
                notification.success("XML excluido com sucesso")
            })
            .catch((err) => {
                notification.error("Erro ao Excluir o XML")
            })
    };

    const xmlsMapped = listXmls.map(xml => {
      return (<tr key={xml.id}>
        <td>{xml.id}</td>
        <td>{xml.snapDate}</td>
        <td>{xml.period}</td>
        <td>{xml.annotations.xmlName}</td>
        <td>
          <abbr title='Deletar XML'>
            <button
              className='button is-danger is-normal'
              type='button'
              onClick={() => deleteXml(xml.id)}
            >
              <TrashIcon />
            </button>
          </abbr>
        </td>
      </tr>)
    })

    return (
        <div>
            <Columns isMultiline>
                <Column isSize={2}>
                    <div style={{ maxWidth: '100%' }}>
                        <input className="input is-fullwidth" type="date" value={snapDate} onChange={changeSnapDate} />
                    </div>
                </Column>
                <Column isSize={2}>
                    <div className="select is-fullwidth" >
                        <select value={period} onChange={changePeriod}>
                            {!period ? <option>Selecione um Período</option> : ""}
                            <option value="morning">Manhã</option>
                            <option value="afternoon">Tarde</option>
                        </select>
                    </div>
                </Column>
            </Columns>
            <table className='table is-striped is-narrow is-hoverable is-fullwidth'>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Data</th>
                        <th>Período</th>
                        <th>Xml</th>
                        <th>Ações</th>
                    </tr>
                </thead>
                <tbody>
                    {xmlsMapped}
                </tbody>
            </table>
            {xmlsMapped.length === 0 && <p className='subtitle is-4 has-text-centered'>Não há dados para este dia</p>}
        </div>
    )

};

export default ListGondolas;