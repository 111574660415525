import { format, subHours, isBefore } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Bar, CartesianGrid, ComposedChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import Columns, { Column } from '../../../../components/Columns';
import { IdBadgeIcon, CalendarAltIcon, ClockIcon, InfoCircleIcon, WalkingIcon, UserIcon } from '../../../../components/icons';
import IsLoading from '../../../../components/IsLoading';
import { get, post, put } from '../../../../utils';
import { STATUS_AUDITORIA, TIPOS_AUDITORIA } from './enums';

const getStatusDescription = (status) => {
  if(status === STATUS_AUDITORIA.FINALIZADO) {
    return "Finalizado";
  }

  if(status === STATUS_AUDITORIA.DESCONSIDERAR) {
    return "Desconsiderado";
  }

  if(status === STATUS_AUDITORIA.NAO_INICIADO) {
    return "Não iniciado";
  }

  if(status === STATUS_AUDITORIA.EM_ANDAMENTO) {
    return "Em Andamento";
  }
}

const getTipoDescription = (auditType) => {
  if(auditType === TIPOS_AUDITORIA.VISITORS) {
    return "Visitante"; 
  }
  if(auditType === TIPOS_AUDITORIA.PASSERSBY) {
    return "Passante"; 
  }
  if(auditType === TIPOS_AUDITORIA.VENUE) {
    return "Area"; 
  }
}

const useUsersSite = (siteId) => {
  const [ users, setUsers ] = useState([]);
  const [ isLoading, setIsLoading ] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    get(`admin/tooq/users?siteId=${siteId}`)
      .then(response => {
        setUsers(response.data.data);
        setIsLoading(false);
      });
  }, [ siteId ]);

  return [isLoading, users];
}


const useAuditStatus = (siteId, auditId) => {
  const [ auditStatus, setAuditStatus ] = useState([]);
  const [ isLoading, setIsLoading ] = useState(false);

  useEffect(() => {

    setIsLoading(true);
    get(`dashboard/video/sites/${siteId}/audit/${auditId}/status`)
      .then(response => {
        setAuditStatus(response.data.data);
        setIsLoading(false);
      });

  }, [ siteId, auditId ]);

  return [ isLoading, auditStatus ]
}



const CustomToolTip = (props) => {
  const { active, dia } = props;

  //dia em que atualizamos os dados de UTC para o timezone da origem
  const isBeforeUpgrade = (isBefore(dia, "2021-10-18"))

  if(active) {
    const { payload=[] } = props;
    const data = payload[0] || { payload: {} };

    return (<div className="card notification is-white">
      <p className="subtitle is-5 has-text-centered" style={{ marginBottom: 5, fontWeight: 'bold' }}>
        {format(isBeforeUpgrade ? subHours(data.payload.snapTime, 3) : data.payload.snapTime, 'DD/MM/YY HH:mm:ss')}
      </p>
      <span className="subtitle is-5 heading has-text-left">
        <span>Pessoas contadas: {data.payload.peopleCounted}</span>
      </span>
    </div>)
  }
  return null;
}

const ModalShowMoreAudit = (props) => {
  const { audit, site } = props;
  const [ isLoadingUsers, users ] = useUsersSite(site.id);
  const [ isSavingUser, setIsSavingUser ] = useState(false);
  const [ selectedAuditor, setSelectedAuditor ] = useState(audit.user.id);
  const [ isLoadingStatus, auditStatus ] = useAuditStatus(site.id, audit.id);

  const saveUser = () => {
    if(isLoadingUsers || isSavingUser) 
      return;

      setIsSavingUser(true);

    post(`dashboard/video/sites/${site.id}/audit/${audit.id}/setuser/${selectedAuditor}`)
      .then(resp => {
        setIsSavingUser(false);

        const newAudit = {
          ...audit,
          user: users.find(u => u.id === selectedAuditor)
        }
        props.handleSave(newAudit);
      });
  }

  const [desconsiderando, setDesconsiderando] = useState(false);

  const desconsiderarAuditoria = (auditId) => {
    if(desconsiderando)
      return;

    setDesconsiderando(true)
    put(`dashboard/video/sites/${site.id}/audit/${auditId}/status`, {
      status: "2"
    }).then(() => props.desconsiderada(auditId))
  }

  //dia em que atualizamos os dados de UTC para o timezone da origem
  const isBeforeUpgrade = (isBefore(audit.snapDate, "2021-10-18"))

  return <div className="modal is-active" style={{ paddingTop: 90, justifyContent: 'start' }}>
    <div className="modal-background" onClick={() => props.toogleModal()}></div>
    <div className="modal-content is-paddingless " style={{ width: '80%' }}>
      <div className="card notification calendarNotDisplay-left-bar">
        <p className="subtitle is-4 has-text-left">
          <IdBadgeIcon />
          <span>
            <b>#{audit.id}</b> {audit.description}
          </span>
        </p>
        <Columns isMultiline>
          <Column isSize={2}>
            <label className="label"> <CalendarAltIcon /> Dia:</label>
            <div>
              {audit.snapDate}
            </div>
          </Column>
          <Column isSize={2}>
            <label className="label"> <ClockIcon /> Hora:</label>
            <div>
              {audit.snapTime}
            </div>
          </Column>
          <Column isSize={2}>
            <label className="label"> <InfoCircleIcon /> Status:</label>
            <div>
              {audit.status === STATUS_AUDITORIA.DESCONSIDERAR ? <span className="tag is-warning is-light">
                {getStatusDescription(audit.status)}
              </span>
              : audit.status === STATUS_AUDITORIA.NAO_INICIADO ? <span className="tag is-default">
              {getStatusDescription(audit.status)}
              </span> : audit.status === STATUS_AUDITORIA.EM_ANDAMENTO ? <span className="tag is-link">
              {getStatusDescription(audit.status)}
              </span> : audit.status === STATUS_AUDITORIA.FINALIZADO ? <span className="tag is-success is-light">
                {getStatusDescription(audit.status)}
              </span> : <span></span>}
            </div>
          </Column>

          <Column isSize={2}>
            <label className="label"> <WalkingIcon /> Tipo:</label>
            <div>
              {audit.flowAnalysis ? 'Eventos' : getTipoDescription(audit.auditType)}
            </div>
          </Column>
          <Column isSize={3}>
            <label className="label"> <UserIcon /> Auditor:</label>
            <div className="field has-addons">
              <div className="control is-expanded">
                <div className={`select is-fullwidth`}>
                  <select
                    value={selectedAuditor}
                    onChange={(e) => setSelectedAuditor(parseInt(e.target.value))}
                    id="select-audit-days"
                    name="camera">
                    <option value="0">Selecione um auditor</option>
                    {users.map(user => <option key={`day_to_audit_option_${user.id}`} value={user.id}>
                      {user.email}
                    </option>)}
                  </select>
                </div>
              </div>

              <div className="control">
                {selectedAuditor !== audit.user.id
                  ? <button
                    type="button"
                    className={`button is-primary ${isSavingUser ? 'is-loading' : ''}`}
                    onClick={saveUser}>Salvar</button> 
                  : <button type="button" className="button is-static">Salvar</button>}
              </div>
            </div>
          </Column>
          {audit.status !== STATUS_AUDITORIA.DESCONSIDERAR && <Column isSize={1}>
            <span className={`button is-warning is-outlined ${desconsiderando ? "is-loading" : ""}`} onClick={() => desconsiderarAuditoria(audit.id)}>
              Desconsiderar
            </span>
          </Column>}
          <Column isSize={12}>
            <p className="subtitle is-4 has-text-centered">Timeline da auditoria</p>
            {isLoadingStatus && <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <IsLoading isBorderless={true} fontSize={60} />
            </div>}
            {!isLoadingStatus && auditStatus.length !== 0 && <ResponsiveContainer width="100%" height={300}>
              <ComposedChart data={auditStatus}
                  margin={{top: 20, right: 50, left: 10, bottom: 20}}>
                <CartesianGrid strokeDasharray="3 10" />
                <XAxis dataKey="snapTime" tickFormatter={(val) => `${format(isBeforeUpgrade ? subHours(val, 3) : val, 'DD/MM HH:mm')}`}/>
                <YAxis yAxisId="left" domain={[0, 5]} orientation="left" stroke="#343434"/>
                <Tooltip
                  content={<CustomToolTip dia={audit.snapDate} />}
                  isAnimationActive={false}/>
                <Bar
                  key="peopleCounted"
                  yAxisId="left"
                  name="Pessoas Contadas"
                  dataKey="peopleCounted"
                  maxBarSize={15}
                  fill="#0043ff"
                  isAnimationActive={false}
                  minPointSize={3}
                  connectNulls={true}
                />
              </ComposedChart>
            </ResponsiveContainer>}
          </Column>
        </Columns>
      </div>
    </div>
    <button className="modal-close is-large" onClick={() => props.toogleModal()} aria-label="close"></button>
  </div>
}

export default ModalShowMoreAudit