import React, { Component } from 'react';
import Columns, { Column } from '../../../../components/Columns';
import Select from 'react-select';
import { post, put } from '../../../../utils';
import notification from '../../../../utils/notification';

class JohannForm extends Component {
  constructor(props) {
    super(props);

    const {
      id,
      intervalTimeNotify='',
      maxOutLimitNotify='',
      maxHumi=100,
      maxTemp=-15,
      minHumi=0,
      placa = '',
      minTemp=-25,
      name='',
      sensorid='',
      gps = true,
      multi_thermo_part = true,
      ldrval = 0,
      hasHumi = false
    } = props.venue;

    this.state = {
      forms: {
        id,
        intervalTimeNotify,
        maxOutLimitNotify,
        maxHumi,
        maxTemp,
        minHumi,
        placa,
        minTemp,
        name,
        sensorid,
        gps,
        multi_thermo_part,
        with_gps: gps,
        with_humidity: hasHumi,
        ldrval
      },
      isSaving: false
    } 
  }
  _onChangeChecked = (e) => {
    const { forms } = this.state;
    const { checked, name } = e.target;
    forms[name] = checked;
    this.setState({ forms });
  }
  _changeTempValue = (val) => {
    const {forms} = this.state;
    const {minTemp, maxTemp} = val.value;

    if(forms.minTemp !== minTemp){
      const {forms} = this.state;
      forms.minTemp = minTemp;
      forms.maxTemp = maxTemp;
      this.setState({forms})
    }
  }

  _changeType = (e) =>{
    // 1 - com Gps
    // 2 - com Umidade
    this.setState({type: e.target.value})
  }

  _onChangeValue = (e) => {
    const { forms } = this.state;
    const { value, name, type } = e.target;


    const parsedValue = parseFloat(value);
    if(parsedValue.isNaN)
      forms[name] = parsedValue;
    else
      forms[name] = value;

    if(type === 'number')
      forms[name] = parseInt(forms[name], 10);

    if(name === "sensorid")
      forms[name] = value.trim();

    this.setState({ forms });
  }


  _onSave = (e) => {
    e.preventDefault();

    const { isSaving, forms } = this.state;
    if(isSaving)
      return;

    this.setState({ isSaving: true }, () => {
      const { siteId, venue } = this.props;
      const {
        id,
        maxHumi = 100,
        maxTemp,
        minHumi = 0,
        placa,
        minTemp,
        name,
        sensorid,
        multi_thermo_part, 
        ldrval,
        with_gps,
        with_humidity
       } = forms;

      const gps = with_gps
      const hasHumi = with_humidity
      
      const body = { name, sensorid, maxTemp, maxHumi, minTemp, minHumi, placa, temperatureChanged: venue.minTemp !== minTemp, gps, multi_thermo_part, ldrval, hasHumi };
      const addUpdate = id ? put(`admin/johann/sites/${siteId}/venues/${id}`, body) : post(`admin/johann/sites/${siteId}/venues`, body);
      addUpdate.then(resp => {
        notification.success('Dados salvos com sucesso!')
        this.setState({ isSaving: false }, () => this.props.toggle());
      }).catch(err => this.setState({ isSaving: false }));
    });
  }


  render(){
    const {venue} = this.props;
    const {forms,  isSaving} = this.state;    
    const {minHumi, maxHumi, with_humidity} = forms;


    const tempValues = [{ value: {minTemp: -25, maxTemp: -15}, label: `-25°C à -15°C `},
                       { value: {minTemp: 2, maxTemp: 8}, label: `2°C à 8°C`},
                      { value: {minTemp: 15, maxTemp: 25}, label: `15°C à 25°C`}];
    const defaultValue ={ value: {minTemp: forms.minTemp, maxTemp: forms.maxTemp}, label: `${forms.minTemp}°C à ${forms.maxTemp}°C `}

    return (
      <div className="card">
        <header className="card-header">
          <p className="card-header-title">
            { !venue.id ? "Novo Sensor": `${venue.name} - ${venue.sensorid}`}
          </p>
        </header>
        <div className="card-content">
          <Columns isMultiline >
            <Column isSize={4}>
              <div className="field is-small">
                <div
                  title={venue.id && "Uma vez cadastrado o sensor id não pode ser alterado. Se esta incorreto, delete e cadastre novamente."}
                  className="control has-icons-right is-expanded"
                  >
                  <label className="label">ID Sensor</label>
                  <input disabled={venue.id} name="sensorid" onChange={this._onChangeValue} className="input" value={this.state.forms.sensorid} type="text" placeholder="Ex: Jenny_Tooq-000000" />
                  <span className="help">O sensor ID é fornecido pela Tooq</span>
                </div>
              </div>
            </Column>
            <Column isSize={4}>
              <div className="field is-small">
                <div className="control has-icons-right is-expanded">
                  <label className="label">Descrição</label>
                  <input name="name" onChange={this._onChangeValue} className="input" value={this.state.forms.name} type="text" />
                  <span className="help">Descrição do Sensor</span>
                </div>
              </div>
            </Column>
            <Column isSize={4}>
              <div className="field is-small">
                <div className="control has-icons-right is-expanded">
                  <label className="label">Extensão de Temperatura</label>
                  <Select
                      className="width-100"
                      name="minTemp"
                      defaultValue={defaultValue}
                      options={tempValues}
                      placeholder="Ex: 0"
                      onChange={this._changeTempValue}
                      />
                  <span className="help">Temperatura mínima e máxima</span>
                </div>
              </div>
            </Column>
            <Column isSize={4}>
              <div className="field is-small">
                <div className="control is-expanded">
                  <label className="label">Placa ou Setor</label>
                  <input name="placa" onChange={this._onChangeValue} className="input" value={this.state.forms.placa} type="text" placeholder="Ex: AAA-9999" />
                  <span className="help">Placa ou Setor</span>
                </div>
              </div>
            </Column>
            <Column isSize={2}>
              <div className="field is-small">
                <div className="control is-expanded" >
                  <label className="label">Tipo de Sensor</label>
                  <label className="radio">
                    <input 
                      type="checkbox" 
                      name="with_gps" 
                      value={this.state.forms.with_gps} 
                      defaultChecked={this.state.forms.with_gps} 
                      style={{marginRight: 5}} 
                      onChange={this._onChangeChecked} />
                    Com GPS
                  </label>
                  <label className="radio" style={{margin: 0}}>
                    <input 
                      type="checkbox" 
                      name="with_humidity" 
                      value={this.state.forms.with_humidity} 
                      defaultChecked={this.state.forms.with_humidity} 
                      style={{marginRight: 5}} 
                      onChange={this._onChangeChecked} />
                    Com Umidade
                  </label>
                </div>
              </div>
            </Column>
            <Column isSize={2}>
              <div className="field is-small">
                <div className="control is-expanded">
                  <label className="label">Duas Temperaturas</label>
                  <input name="multi_thermo_part" defaultChecked={this.state.forms.multi_thermo_part} onChange={this._onChangeChecked}  type="checkbox" value={this.state.forms.multi_thermo_part}/>
                </div>
              </div>
            </Column>
            {with_humidity &&
              <Column isSize={4}>
                <Columns>
                  <Column isSize={6}>
                    <div className="field is-small">
                      <div className="control is-expanded">
                        <label className="label">Umidade Mínima</label>
                        <input name="minHumi" onChange={this._onChangeValue} className="input" value={minHumi}  type="text" placeholder="Ex: 1000" />
                        <span className="help">Extensão de umidade</span>
                      </div>
                    </div>
                  </Column>
                  <Column isSize={6}>
                    <div className="field is-small">
                      <div className="control is-expanded">
                        <label className="label">Umidade Máxima</label>
                        <input name="maxHumi" onChange={this._onChangeValue} className="input" value={maxHumi} type="text" placeholder="Ex: 1000" />
                      </div>
                    </div>
                  </Column>
                </Columns> 
              </Column> 
            }
            <Column isSize={2}>
              <div className="field is-small">
                <div className="control is-expanded">
                  <label className="label">Sensor de Luminosidade</label>
                  <input name="ldrval" onChange={this._onChangeValue} className="input" value={this.state.forms.ldrval} type="number" placeholder="Ex: 1000" />
                </div>
              </div>
            </Column>
          </Columns>
        </div>
        <footer className="card-footer">
          <p className="card-footer-item" style={{justifyContent: "flex-start"}}>
            <button className={`button is-primary ${isSaving ? 'is-loading': ''}`} onClick={this._onSave}>Salvar</button>
          </p>
        </footer>
      </div>
    )
  }
}
export default JohannForm;