import React, { Component } from 'react';

import _ from 'lodash';

import {
  PieChart,
  Pie,
  Line,
  LabelList,
  Bar,
  ComposedChart,
  Cell,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';

import PieLabel from '../../../../../components/dashboard/PieLabel';

import { addDays } from 'date-fns';

import { formatDateDayOfWeek, getServerDate } from '../../../../../utils';

import DateFilterOneRange from './DateFilterOneRange';

import {
  QuestionCircleIcon,
  ExclamationCircleIcon
} from '../../../../../components/icons';

import Columns, { Column } from '../../../../../components/Columns';
import DownloadButton from '../../../../../components/DownloadButton';
import LabelCustomizedAuditing from './LabelCustomizedAuditing';

const colors = ['#0043ff', '#00d1b2', '#ff424b', '#23527c', '#7571ff', '#4267b2', '#d6249f', '#E8770C', '#D1C000', '#8D00DE' ];

const renderLabel = (props) => {
  const { col, description, returnRate, auditingOk } = props;

  const primaryLabel = returnRate !== 0 ? description : `${description} - ${returnRate}%`,
        secondaryLabel = returnRate !== 0 ? `${returnRate}% dos retornos` : "",
        primaryMiddleText = ``,
        secondaryMiddleText = ``,
        opacity =  auditingOk ? 1 : 0.60;

  return <PieLabel
    {...props}
    col={col}
    primaryLabel={primaryLabel}
    secondaryLabel={secondaryLabel}
    primaryMiddleText={primaryMiddleText}
    secondaryMiddleText={secondaryMiddleText}
    opacity={opacity}
  />
}

const DASH_INITIAL = {
  header: {},
  data: []
};

class ReturnRateChart extends Component {

  constructor(props) {
    super(props);
    
    const CURRDATE = addDays(getServerDate(), -1);

    this.state = {
      isLoading: false,
      typeChart: 'DAILY',
      filterRanges: {
        startDate: addDays(CURRDATE, -6),
        endDate: CURRDATE,
        key: 'selectionRange1'
      },
      dash: DASH_INITIAL
    }
  }

  _changeDateFilter = () => (ranges) => {
    this.setState({ filterRanges: ranges }, () => {
      this.loadData();
    });
  }

  loadData = () => {
    this.setState({ isLoading: true, selectDate: null, dash: DASH_INITIAL }, () => {
      const { filterRanges } = this.state;
      this.props.loadVisitorReturnsRate(filterRanges.startDate, filterRanges.endDate)
        .then(result => {
          this.setState({ isLoading: false, dash: result, selectDate: null });
        });
    });
  }

  _showMore = () => {
    this.props.showMore(<div className="notification has-text-justified" style={{ padding: '100px' }}>
      <p className="title is-3">Taxa do retorno</p>
      <p className="subtitle is-5">
        Desde que foram instalados os sensores na loja, 
        estaremos considerando um visitante que esteve 
        ao menos uma vez na loja como um retorno.
      </p>
      <p className="subtitle is-5">
        A satisfação dos seus clientes é uma das principais métricas para o sucesso, 
        e indicação de possível tráfego futuro e evolução das suas vendas. 
        A taxa de retorno é determinada pelo número de retorno de visitantes 
        (visitantes totais menos visitantes únicos) dividido pelo 
        número de visitantes totais.
      </p>
      <br />
      <p className="title is-3">Comportamento do retorno</p>
      <p className="subtitle is-5">
        O comportamento dos clientes que retornam à sua loja te 
        permite analisar como está a fidelidade dos seus clientes.
      </p>
      <p className="subtitle is-5">
        A análise da fidelidade dos clientes permite analisar e comparar 
        a fidelidade e possível satisfação dos clientes de diferentes lojas,
        e pode ajudar na avaliação de ações de marketing, treinamentos de vendas, 
        e estruturação de campanhas de fidelidade.
      </p>
    </div>);
  }

  componentDidMount() {
    this.loadData();
  }

  onClickChart = () => (data) => {
    if(!data)
      return;
    this.setState({ selectDate: data.activeLabel });
  }
  
  renderChartLegend = (props) => {
    const { payload } = props;
    const { filterRanges } = this.state;

    if(!payload) {
      return <div></div>;
    }

    return (
      <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: '35px', paddingRight: '35px' }}> 
        <div className="help has-text-centered">
          <b>Clique no dia para detalhar ao lado.</b>
        </div>
        <div className="help has-text-centered">
          Indicador da taxa de retorno da loja, 
          uma linha do tempo de <b>{formatDateDayOfWeek(filterRanges.startDate)} </b> até <b>{formatDateDayOfWeek(filterRanges.endDate)} </b> 
          comparando o número de visitantes com o número de visitantes que retornaram algum dia na loja.
        </div>
        <ul style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
          {payload.map((entry, index)=> {
            return <li key={`legenditem-${index}`} style={{ marginRight: '10px', color: entry.color }}>
              <b>
                {entry.dataKey === "totalVisitors" ? 'Visitantes' 
                  : entry.dataKey === "totalReturns" ? 'Retornos'
                  : 'Taxa de Retornos'}
              </b>
            </li>
          })}
        </ul>
      </div>
    );
  }

  render() {
    const { isLoading, dash, selectDate, filterRanges } = this.state;
    const { header, data } = dash;

    const dateToDetail = selectDate ? data.find(d => d.date === selectDate) : header;
    const { behaviour={} } = dateToDetail

    const pieReturns = _.map(behaviour, (value, metric) => {
      let description = '';
      if('last7Days' === metric) {
        description = 'Últ. 7 dias';
      } else if('last15Days' === metric) {
        description = 'Últ. 15 dias';
      } else if('last30Days' === metric) {
        description = 'Últ. 30 dias';
      } else if('last60Days' === metric) {
        description = 'Últ. 60 dias';
      } else if('last90Days' === metric) {
        description = 'Últ. 90 dias';
      }
      return { metric, ...value, description };
    }).map((obj, index) => {
      return { ...obj, col: index };
    });

    return <div className="card is-paddingless " style={{ height: '100%' }}>
      <header className="card-header" style={{ borderBottom: '1px solid #eee', padding: '10px', justifyContent: 'space-between' }}> 
        <div className="title is-5 is-marginless">
          Retornos
        </div> 
        <QuestionCircleIcon className="cursor-pointer" onClick={this._showMore}/>
      </header>
      <div className="card-content ">
        <div>
          <Columns isGapless isMultiline>
            <Column isSize={8} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
              <div className="has-text-centered" style={{ flexDirection:'row', width: '100%', paddingBottom: '30px' }}>
                <span style={{marginRight: 10}}className="subtitle is-5"><b>Taxa de retorno</b></span>
                <DownloadButton site={this.props.site} name="returnsrate" begin={filterRanges.startDate} end={filterRanges.endDate}/>
              </div>
            {isLoading 
                ? <div className="has-text-centered" style={{ width: '100%', height:300, paddingTop: '70px' }}>
                    <span>
                      <span className="button is-loading is-large is-bordless" />
                      <p className="subtitle is-5">Aguarde, carregando...</p>
                    </span>
                  </div>
                : <ResponsiveContainer width="100%" height={300}>
                    <ComposedChart data={data}
                      cursor="pointer"
                      onClick={this.onClickChart()}
                      margin={{top: 20, right: 30, left: 20, bottom: 5}}>
                      <XAxis dataKey="date" tickFormatter={(val) => formatDateDayOfWeek(val)}/>
                      <YAxis yAxisId="left" orientation="left" stroke="#343434"/>
                      <YAxis yAxisId="right" orientation="right" stroke="#343434"/>

                      <Tooltip
                        content={<CustomToolTip />}
                        isAnimationActive={false}/>
                      <Bar
                        dataKey="totalVisitors"
                        yAxisId="left"
                        fill="#0043ff" 
                        maxBarSize={15}
                        minPointSize={3}
                        isAnimationActive={false}
                        name='Novos'
                      >
                        <LabelList
                          dataKey="auditingOk"
                          position="top"
                          content={<LabelCustomizedAuditing />}
                        />
                        {
                          data.map((entry, index) => (
                            <Cell strokeWidth={1} stroke={dateToDetail.date === entry.date ? "#343434" : ""} key={`visitorsNew-${index}`} fill="#0043ff" />
                          ))
                        }
                      </Bar>

                      <Bar
                        dataKey="totalReturns"
                        fill="#00d1b2"
                        name='Retornos'
                        yAxisId="left"
                        maxBarSize={15}
                        isAnimationActive={false}
                        minPointSize={3}
                      >
                        {
                          data.map((entry, index) => (
                            <Cell strokeWidth={1} stroke={dateToDetail.date === entry.date ? "#343434" : ""} colorInterpolation={"#000"} key={`visitorsReturned-${index}`} fill="#00d1b2"/>
                          ))
                        }
                      </Bar>
                      <Line
                        yAxisId="right"
                        name="Taxa de retornos"
                        dataKey="returnRate"
                        key="returnRate"
                        strokeDasharray="3 5"
                        fill="#000"
                        stroke="#000"
                        isAnimationActive={false}
                        connectNulls={true}
                      >
                        <LabelList dataKey="returnRate" position="top" formatter={(val) => `${val}%`} fontSize={22} />
                      </Line>
                      <Legend content={this.renderChartLegend} />
                    </ComposedChart>  
                  </ResponsiveContainer>}
            </Column>
            <Column isSize={4}>
              <p className="subtitle is-5 has-text-centered" style={{ paddingBottom: '30px' }}>
                <b>
                  Comportamento {dateToDetail.date && formatDateDayOfWeek(dateToDetail.date)}
                </b>
              </p>
              <p className="subtitle is-5 has-text-centered">{!dateToDetail.date && '(Selecione uma data ao lado)'}</p>
              <Columns>
                <Column>
                  <ResponsiveContainer width="100%" height={300}>
                    <PieChart>
                      <Pie
                        data={pieReturns}
                        innerRadius={30}
                        cy={100}
                        outerRadius={45}
                        isAnimationActive={false}
                        label={renderLabel}
                        fill="#8884d8"
                        dataKey="returnRate"
                        minAngle={25}
                        paddingAngle={1}
                      >
                        {
                          pieReturns.map((entry) => <Cell
                            key={`pie-return-${entry.description}`}
                            fill={colors[entry.col]}/>)
                        }
                      </Pie>
                    </PieChart>
                  </ResponsiveContainer>
                </Column>
              </Columns>
            </Column>
          </Columns>
        </div>
      </div>
      
      <footer className="card-footer">
        <span className="button has-text-left is-white card-footer-item is-radiusless is-shadowless is-bordless" style={{ borderRight: 'none' }}>
          <DateFilterOneRange changeDates={this._changeDateFilter()} actualRanges={{ ranges: filterRanges }}/>
        </span>
        <span className="card-footer-item" style={{ borderRight: 'none' }}>
        </span>
        <span className="card-footer-item" style={{ borderRight: 'none' }}>
        </span>
      </footer>
    </div>
  }
}

class CustomToolTip extends Component {

  render() {
    const { active } = this.props;
    if(active) {
      const { payload=[] } = this.props;
      const data = payload[0] || { payload: {} };

      return (<div className="card">
        <div  style={{ padding: '0px 5px 0px 5px' }}>
          <p className="heading has-text-centered">
            {formatDateDayOfWeek(data.payload.date, 'DD/MM/YYYY')}
          </p>
          <p className="heading has-text-centered has-text-weight-bold" style={{ color: '#0043ff', fontWeight: 'bold', marginBottom: '-8px' }}>
            <span>Visitantes: {data.payload.totalVisitors ? data.payload.totalVisitors : 0}</span>
          </p> 
          <p className="heading has-text-centered has-text-weight-bold margin-top-10" style={{ color: '#00d1b2', fontWeight: 'bold', marginBottom: '-8px' }}>
            <span>Retornos: {data.payload.totalReturns ? data.payload.totalReturns : 0}</span>
          </p> 
          <p className="heading has-text-centered has-text-weight-bold margin-top-10" style={{ color: '#000', fontWeight: 'bold', marginBottom: '-8px' }}>
            <span>Taxa retorno: {data.payload.returnRate ? data.payload.returnRate : 0}</span>
          </p>
          {!data.payload.auditingOk && <p
            className="help is-danger">
            <ExclamationCircleIcon />
            <span>
              Dados não confiáveis.
            </span>
          </p>}
        </div>
      </div>)
    }
    return null;
  }
}

export default (ReturnRateChart); 