import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { format, subDays, toDate } from 'date-fns';
import Page, { PageTitle } from '../../../../components/Page';
import Columns, { Column } from '../../../../components/Columns';
import { formatAsPtBr, get, getServerDate } from '../../../../utils';
import { CalendarAltIcon, MaleIcon } from '../../../../components/icons';
import IsLoading from '../../../../components/IsLoading';
import CalendarRangePicker from '../calendar/CalendarRangePicker';
import {
  AreaChart,
  ResponsiveContainer,
  Bar,
  Tooltip,
  XAxis,
  YAxis,
  ComposedChart,
  Area,
  Legend,
  Brush
} from 'recharts';

const secondsToLabel = (seconds) => {
  const minutos = parseInt(seconds / 60);
  const segundos = parseInt(seconds % 60);
  if (!seconds) {
    return `0 seg`;
  }

  if (minutos !== 0 && segundos !== 0) {
    return `${minutos} min e ${segundos} seg`;
  }
  
  if (minutos !== 0 && segundos === 0) {
    return `${minutos} min`;
  }

  if (minutos === 0 && segundos !== 0) {
    return `${segundos} seg`;
  }
}

const CalendarFilter = (props) => {
  const [ toogleFilter, setToogleFilter ] = useState(false);
  const _toogleFilter = () => {
    setToogleFilter(!toogleFilter);
  }

  const {
    top,
    left,
    right,
    bottom,
    isDark,
    begin,
    end,
    setBegin,
    setEnd,
    maxDate,
    className='bottom-left-to-right'
  } = props;

  const setConfirmDates = data => {
    const { startDate, endDate } = data.range;
    setBegin(format(startDate, 'YYYY-MM-DD'));
    setEnd(format(endDate, 'YYYY-MM-DD'));
    _toogleFilter();
  };

  return <div className="is-fullwidth-and-center">
    <div className="tooltip">
      <span className={`tag heading isLinkToClick cursor-pointer ${isDark ? 'is-dark is-medium': ''}`} onClick={_toogleFilter}>
        <CalendarAltIcon style={{ marginRight: 5 }} />
        <b>{formatAsPtBr(begin, 'DD MMMM')} até {formatAsPtBr(end, 'DD MMMM')}</b>
      </span>
      {toogleFilter && <span className={`tooltiptext-queues ${className}`} style={{ right, left, top, bottom }}>
        <CalendarRangePicker
          maxDate={maxDate}
          range={{
            startDate: toDate(begin),
            endDate: toDate(end)
          }}
          close={_toogleFilter}
          onChange={setConfirmDates}
          />
      </span>}
    </div>
  </div>
}

const TempoToolTip = ({ active, payload=[] }) => {
  if(active) {
    const data = payload[0] || { payload: {} };
    return (<div className="card notification" style={{ padding: 10 }}>
      <span className="help has-text-centered" style={{  color: '#363636', fontSize: '1rem'  }}>
        Em até <b style={{color:'#87BFD1'}}>{data.payload.labelFriendly}</b>
      </span>
      <span className="help has-text-centered" style={{ color: '#363636', fontSize: '1rem' }}>
          <b style={{color:'#87BFD1'}}>{data.payload.peopleInThisTime}</b> pessoas são atendidas
      </span>
    </div>)
  }
  return null;
}

const VisitorsAndQueuesTooltip = ({ active, payload=[] }) => {
  if(active) {
    const data = payload[0] || { payload: {} };

    return (<div className="card notification" style={{ padding: 10 }}>
      <p className="heading has-text-centered" style={{ fontWeight: 'bold' }}>
        {data.payload.infoDate 
          ? formatAsPtBr(data.payload.infoDate, 'HH:mm DD/MM - ddd')
          : data.payload.snapTime}
      </p>

      <span className="heading has-text-centered" style={{  color: '#00d1b2'  }}>
        Visitantes Loja
      </span>
      <span className="heading has-text-centered" style={{ fontWeight: 'bold', color: '#00d1b2', fontSize: '1.2rem' }}>
        <b style={{color:'#00d1b2'}}>{data.payload.totalSiteVisitors || data.payload.siteVisitors}</b>
      </span>

      <span className="heading has-text-centered" style={{  color: '#87BFD1'  }}>
        Pessoas na fila
      </span>
      <span className="help has-text-centered" style={{ fontWeight: 'bold', color: '#87BFD1', fontSize: '1.2rem' }}>
        <b style={{color:'#87BFD1'}}>{data.payload.totalVenueVisitors || data.payload.venueVisitors}</b>
      </span>
    </div>)
  }
  return null;
}

const QueuesMeanTimeTooltip = ({ active, payload=[] }) => {
  if(active) {
    const data = payload[0] || { payload: {} };
    return (<div className="card notification" style={{ padding: 10 }}>
      <p className="heading has-text-centered" style={{ fontWeight: 'bold' }}>
        {data.payload.infoDate 
          ? formatAsPtBr(data.payload.infoDate, 'HH:mm DD/MM - ddd')
          : data.payload.snapTime}
      </p>

      <span className="heading has-text-centered" style={{  color: '#87BFD1'  }}>
        Tempo médio de fila
      </span>
      <span className="heading has-text-centered" style={{ fontWeight: 'bold', color: '#87BFD1', fontSize: '1.2rem' }}>
        <b style={{color:'#87BFD1'}}>{secondsToLabel(data.payload.meanTime)}</b>
      </span>
    </div>)
  }
  return null;
}

const QueuesServicedTooltip = ({ active, payload=[] }) => {
  if(active) {
    const data = payload[0] || { payload: {} };
    return (<div className="card notification" style={{ padding: 10 }}>
      <p className="heading has-text-centered" style={{ fontWeight: 'bold' }}>
        {formatAsPtBr(data.payload.infoDate, 'HH:mm DD/MM - ddd')}
      </p>

      <span className="heading has-text-centered" style={{  color: '#6DD184'  }}>
        Atendidos em até 3 minutos
      </span>
      <span className="heading has-text-centered" style={{ fontWeight: 'bold', color: '#87BFD1', fontSize: '1.2rem' }}>
        <b style={{color:'#6DD184'}}>{data.payload.lessThan3MinutesRate}%</b>
      </span>

      <span className="heading has-text-centered" style={{  color: '#43A1D1'  }}>
      Atendidos entre 3 e 7 minutos
      </span>
      <span className="heading has-text-centered" style={{ fontWeight: 'bold', color: '#43A1D1', fontSize: '1.2rem' }}>
        <b style={{color:'#43A1D1'}}>{data.payload.lessThan7MinutesRate}%</b>
      </span>

      <span className="heading has-text-centered" style={{  color: '#D14D59'  }}>
        Depois de 7 minutos
      </span>
      <span className="heading has-text-centered" style={{ fontWeight: 'bold', color: '#D14D59', fontSize: '1.2rem' }}>
        <b style={{color:'#D14D59'}}>{data.payload.higherThan7MinutesRate}%</b>
      </span>
    </div>)
  }
  return null;
}

const DashQueues = (props) => {
  const { site } = props;

  const [ isLoadingCumulative, setIsLoadingCumulative ] = useState(false);
  const [ isLoadingSummary, setIsLoadingSummary ] = useState(false);
  const [ servicedUntil, setServicedUntil ] = useState([]);
  const [ servicedHeader, setServicedHeader ] = useState({
    threeMinutes: 0,
    sevenMinutes: 0,
    higherSevenMinutes: 0,
  })

  const [ hourSummary, setHourSummary ] = useState([]);

  const [ beginCumulative, setBeginCumulative ] = useState(format(subDays(new Date(), 7), 'YYYY-MM-DD'));
  const [ endCumulative, setEndCumulative ] = useState(format(subDays(new Date(), 1), 'YYYY-MM-DD'));
  
  const [ beginSummary, setBeginSummary ] = useState(format(subDays(new Date(), 7), 'YYYY-MM-DD'));
  const [ endSummary, setEndSummary ] = useState(format(subDays(new Date(), 1), 'YYYY-MM-DD'));

  const [ beginQueue, setBeginQueue ] = useState(format(subDays(new Date(), 7), 'YYYY-MM-DD'));
  const [ endQueue, setEndQueue ] = useState(format(subDays(new Date(), 1), 'YYYY-MM-DD'));

  const [ queuesData, setQueuesData ] = useState([]);
  const [ isLoadingQueuesData, setIsLoadingQueuesData ] = useState(false);

  useEffect(() => {
    setIsLoadingQueuesData(true);
    get(`dashboard/queues/sites/${site.id}`, {
      begin: beginQueue,
      end: endQueue
    })
    .then(resp => resp.data.data)
    .then(setQueuesData)
    .then(() => setIsLoadingQueuesData(false));
  }, [ site.id, beginQueue, endQueue ]);


  useEffect(() => {
    setIsLoadingSummary(true);
    get(`dashboard/queues/sites/${site.id}`, {
      begin: beginSummary,
      end: endSummary
    })
    .then(resp => resp.data.data)
    .then((data) => {
      const dashDataFlated = data
        .map(d => d.dashData)
        .flat();

      const hourSummaryReduced = dashDataFlated.reduce((prev, curr) => {
        const snapTimeData = prev[curr.snapTime] || [];
        snapTimeData.push(curr);
        prev[curr.snapTime] = snapTimeData;
        return prev;
      }, {});

      const hourSummary = Object.keys(hourSummaryReduced).map(snapTime => {
        const data = hourSummaryReduced[snapTime];

        const totalSiteVisitors = _.sum(data.map(d => d.siteVisitors));
        const totalVenueVisitors = _.sum(data.map(d => d.venueVisitors));
        const meanTime = parseInt(_.sum(data.map(d => d.visitorsTimes).flat()) / totalVenueVisitors);
        const meanTimeLabel = secondsToLabel(meanTime);
        return {
          snapTime,
          totalSiteVisitors,
          totalVenueVisitors,
          meanTime,
          meanTimeLabel
        }
      });

      setHourSummary(hourSummary.sort((a, b) => `${a.snapTime}`.localeCompare(b.snapTime)));
    })
    .then(() => setIsLoadingSummary(false));
  }, [ site.id, beginSummary, endSummary ]);

  useEffect(() => {
    setIsLoadingCumulative(true);
    get(`dashboard/queues/sites/${site.id}`, {
      begin: beginCumulative,
      end: endCumulative
    })
    .then(resp => resp.data.data)
    .then((data) => {
      const allVisitorsTimes = data.map(d => d.dashData.map(d => d.visitorsTimes).flat()).flat();

      const TRES_MINUTOS = 180;
      const SETE_MINUTOS = 420;

      const visitosLessThan3Minutes = allVisitorsTimes.filter(v => v <= TRES_MINUTOS).length;
      const visitosBtw3and7Minutes = allVisitorsTimes.filter(v => v > TRES_MINUTOS && v < SETE_MINUTOS).length;
      const visitosHigher7Minutes = allVisitorsTimes.filter(v => v >= SETE_MINUTOS).length;
      
      const total = allVisitorsTimes.length;
      const lessThan3MinutesRate = ((visitosLessThan3Minutes / total) * 100).toFixed(1);
      const btw3and7MinutesRate = ((visitosBtw3and7Minutes / total) * 100).toFixed(1);
      const higher7MinutesRate = ((visitosHigher7Minutes / total) * 100).toFixed(1);

      setServicedHeader({
        threeMinutes: lessThan3MinutesRate,
        sevenMinutes: btw3and7MinutesRate,
        higherSevenMinutes: higher7MinutesRate
      })

      const bubbles = [
        [0, 30],
        [30, 60],
        [60, 90],
        [90, 120],
        [120, 150],
        [150, 180],
        [180, 210],
        [210, 240],
        [240, 270],
        [270, 300],
        [300, 330],
        [330, 360],
        [360, 390],
        [390, 420],
        [420, 450],
        [450, 480],
        [480, 510],
        [510, 540],
        [540, 570],
        [570, 600]
      ]

      const bubbleChartQueueTimes = bubbles
        .map(buble => {
          const [begin, end] = buble;

          const visitors = allVisitorsTimes
            .filter(time => time >= begin && time < end);

          const minutos = parseInt(end / 60);
          const segundos = parseInt(end % 60);

          const label = `${minutos !== 0 ? `${minutos} min`:''}${minutos!== 0 && segundos !== 0 ? ' e ' : ''}${segundos !== 0 ? `${segundos} seg` : ''}`;
          
          return {
            index: 1,
            buble,
            labelFriendly: label,
            label: end,
            peopleInThisTime: visitors.length,
            peopleInThisTimeBarSize: visitors.length === 0 
              ? 0 
              : visitors.length < 5 ? 5 : visitors.length
          }
        });

      setServicedUntil(bubbleChartQueueTimes);
    })
    .then(() => setIsLoadingCumulative(false));
  }, [ site.id, beginCumulative, endCumulative ]);
  
  return <Page>
    <PageTitle
      title={<span>
        <span style={{ marginRight: '10px' }}>
          {`${(site && site.name) || ''} `}
        </span>
        <p className="subtitle">Gestão de filas <MaleIcon /></p>
      </span>}
    />
    <Columns isMultiline>
      <Column isSize={12}>
        <div className="tile is-ancestor">
          <div className="tile is-vertical is-12">
            <div className="tile">
              <div className="tile is-parent is-vertical">
                {/* <article className="tile is-child"> */}
                <Columns className="has-text-centered">
                  <Column isSize={4}>
                    <div className="card notification is-queue-color">
                      {isLoadingCumulative && <p className="subtitle is-2">... %</p>}
                      {!isLoadingCumulative && <p className="subtitle is-2">{servicedHeader.threeMinutes}%</p>}
                      <p className="heading">São atendidos em até <br/> <b>3 minutos</b></p>
                    </div>
                  </Column>
                  <Column isSize={4}>
                    <div className="card notification is-queue-color2">
                      {isLoadingCumulative && <p className="subtitle is-2">... %</p>}
                      {!isLoadingCumulative && <p className="subtitle is-2">{servicedHeader.sevenMinutes}%</p>}
                      <p className="heading">São atendidos entre <br/> <b>3 minutos e 7 minutos</b></p>
                    </div>
                  </Column>
                  <Column isSize={4}>
                    <div className="card notification is-queue-color3">
                      {isLoadingCumulative && <p className="subtitle is-2">... %</p>}
                      {!isLoadingCumulative && <p className="subtitle is-2">{servicedHeader.higherSevenMinutes}%</p>}
                      <p className="heading">São atendidos após <br/> <b>7 minutos</b></p>
                    </div>
                  </Column>
                </Columns>
                {/* </article> */}
                <article className="tile is-child card notification">
                  <p className="subtitle has-text-centered" style={{ margin: 0 }}>Espera até ser atendido</p>
                  <CalendarFilter
                    isDark={true}
                    left="-80%"
                    top="120%"
                    begin={beginCumulative}
                    end={endCumulative}
                    setBegin={setBeginCumulative}
                    setEnd={setEndCumulative}
                    maxDate={subDays(getServerDate(), 1)}
                  />
                  {isLoadingCumulative && <div className="has-text-centered">
                    <IsLoading isBorderless={true} fontSize={50} />
                  </div>}
                  {servicedUntil.length !== 0 && <ResponsiveContainer width="100%" height={200}>
                    <AreaChart data={servicedUntil}>
                      <Tooltip
                        content={<TempoToolTip />}
                      />
                      <XAxis
                        dataKey="labelFriendly"
                      />
                      <Area
                        dataKey="peopleInThisTimeBarSize"
                        fill="#87BFD1"
                        stroke="#87BFD1"
                        type="monotone"
                      />
                    </AreaChart>
                  </ResponsiveContainer>}
                </article>
              </div>
              <div className="tile is-parent">
                <article className="tile is-child box">
                  <p className="subtitle has-text-centered" style={{ marginBottom: 0 }}>Resumo Horários</p>
                  <CalendarFilter
                    isDark={true}
                    left="-80%"
                    top="120%"
                    begin={beginSummary}
                    end={endSummary}
                    setBegin={setBeginSummary}
                    setEnd={setEndSummary}
                    maxDate={subDays(getServerDate(), 1)}
                  />
                  {isLoadingSummary && <div className="has-text-centered">
                    <IsLoading isBorderless={true} fontSize={50} />
                  </div>}
                  {!isLoadingSummary && hourSummary.length !== 0 && <ResponsiveContainer width="100%" height={200}>
                    <ComposedChart data={hourSummary} syncId="queueSnapTime" >
                      <Tooltip
                        content={<VisitorsAndQueuesTooltip />}
                      />
                      <Legend />
                      <XAxis
                        dataKey="snapTime"
                      />
                      <YAxis
                        orientation="left"
                        yAxisId="left"
                        interval="preserveEnd"
                        domain={[0, 'dataMax+10']}
                      />
                      <YAxis
                        tick={false}
                        tickLine={false}
                        label={false}
                        axisLine={false}
                        orientation="right"
                        yAxisId="right"
                        interval="preserveEnd"
                        domain={[0, 'dataMax+10']}
                      />
                      <Bar
                        yAxisId="left"
                        name="Visitantes Loja"
                        maxBarSize={10}
                        dataKey="totalSiteVisitors"
                        fill="#00d1b2"
                      />
                      <Bar
                        yAxisId="left"
                        name="Pessoas na Fila"
                        maxBarSize={10}
                        dataKey="totalVenueVisitors"
                        fill="#87BFD1"
                      />
                    </ComposedChart>
                  </ResponsiveContainer>}
                  {hourSummary.length !== 0 && <ResponsiveContainer width="100%" height={200}>
                    <AreaChart data={hourSummary} syncId="queueSnapTime">
                      <Tooltip
                        content={<QueuesMeanTimeTooltip />}
                      />
                      <Legend />
                      <XAxis
                        dataKey="snapTime"
                      />
                      <YAxis
                        tick={false}
                        tickLine={false}
                        label={false}
                        axisLine={false}
                        orientation="right"
                        yAxisId="right"
                        unit=" pessoas"
                        interval="preserveEnd"
                        domain={[0, 'dataMax+10']}
                      />
                      <YAxis
                        orientation="left"
                        yAxisId="left"
                        interval="preserveEnd"
                        tickFormatter={(value=0) => {
                          return secondsToLabel(value);
                        }}
                        domain={[0, 600]}
                      />
                      <Area
                        name="Tempo médio de fila"
                        yAxisId="left"
                        dataKey="meanTime"
                        type={"monotone"}
                        stroke="#87BFD1"
                        fill="#87BFD1"
                      />
                    </AreaChart>
                  </ResponsiveContainer>}
                </article>
              </div>
            </div>
          </div>
        </div>
      </Column>
      {!isLoadingQueuesData && <Column isSize={12}>
        <div className="is-fullwidth-and-center">
          <CalendarFilter
            isDark={true}
            left="-80%"
            top="120%"
            begin={beginQueue}
            end={endQueue}
            setBegin={setBeginQueue}
            setEnd={setEndQueue}
            maxDate={subDays(getServerDate(), 1)}
          />
        </div>
        {queuesData.map(queueData => <div key={`venue_card_queues_${queueData.id}`} className="card notification has-text-centered">
          <p className="title" style={{ marginBottom: 50 }}>
            {queueData.description} 
          </p>
          {queueData.dashData.length !== 0 && <ResponsiveContainer width="100%" height={200}>
            <ComposedChart data={queueData.dashData} syncId="queueSnapTimeDetails" >
              <Tooltip
                content={<VisitorsAndQueuesTooltip />}
              />
              <Legend />
              {queueData.dashData.length > 20 && <Brush
                className="brushDisplayNone"
                startIndex={queueData.dashData.length - 20}
                dataKey='infoDate'
                height={20}
                tickFormatter={(val) => ``}
                stroke="#363636"
              />}
              <XAxis
                dataKey="infoDate"
                tickFormatter={(val) => formatAsPtBr(val, 'DD MMM HH:mm')}
              />
              <YAxis
                orientation="left"
                yAxisId="left"
                interval="preserveEnd"
                domain={[0, 'dataMax+10']}
              />
              <YAxis
                tick={false}
                tickLine={false}
                label={false}
                axisLine={false}
                orientation="right"
                yAxisId="right"
                interval="preserveEnd"
                domain={[0, 'dataMax+10']}
              />
              <Bar
                yAxisId="left"
                name="Visitantes Loja"
                maxBarSize={15}
                dataKey="siteVisitors"
                fill="#00d1b2"
              />
              <Bar
                yAxisId="left"
                name="Pessoas na Fila"
                maxBarSize={15}
                dataKey="venueVisitors"
                fill="#87BFD1"
              />
            </ComposedChart>
          </ResponsiveContainer>}
          {queueData.dashData.length !== 0 && <ResponsiveContainer width="100%" height={200}>
            <ComposedChart data={queueData.dashData} syncId="queueSnapTimeDetails">
              <Tooltip
                content={<QueuesMeanTimeTooltip />}
              />
              <Legend />
              {queueData.dashData.length > 20 && <Brush
                className="brushDisplayNone"
                startIndex={queueData.dashData.length - 20}
                dataKey='infoDate'
                height={20}
                tickFormatter={(val) => ``}
                stroke="#363636"
              />}
              <XAxis
                dataKey="infoDate"
                tickFormatter={(val) => `${formatAsPtBr(val, 'DD MMM HH:mm')}`}
              />
              <YAxis
                tick={false}
                tickLine={false}
                label={false}
                axisLine={false}
                orientation="right"
                yAxisId="right"
                unit=" pessoas"
                interval="preserveEnd"
                domain={[0, 'dataMax+10']}
              />
              <YAxis
                orientation="left"
                yAxisId="left"
                interval="preserveEnd"
                tickFormatter={(value=0) => {
                  return secondsToLabel(value);
                }}
                domain={[0, 600]}
              />
              <Area
                name="Tempo médio de fila"
                yAxisId="left"
                dataKey="meanTime"
                type={"monotone"}
                stroke="#87BFD1"
                fill="#87BFD1"
              />
            </ComposedChart>
          </ResponsiveContainer>}
          {queueData.dashData.length !== 0 && <ResponsiveContainer width="100%" height={200}>
            <ComposedChart
              data={queueData.dashData}
              syncId="queueSnapTimeDetails"
            >
              <Tooltip
                content={<QueuesServicedTooltip />}
              />
              <Legend />
              {queueData.dashData.length > 20 && <Brush 
                startIndex={queueData.dashData.length - 20}
                dataKey='infoDate'
                height={20}
                tickFormatter={(val) => ``}
                stroke="#363636"
              />}
              <XAxis
                dataKey="infoDate"
                tickFormatter={(val) => `${formatAsPtBr(val, 'DD MMM HH:mm')}`}
              />
              <YAxis
                tick={false}
                tickLine={false}
                label={false}
                axisLine={false}
                orientation="right"
                yAxisId="right"
                unit=" pessoas"
                interval="preserveEnd"
                domain={[0, 'dataMax+10']}
              />
              <YAxis
                orientation="left"
                yAxisId="left"
                interval="preserveEnd"
                unit="%"
                domain={[0, 100]}
              />
              <Bar
                maxBarSize={15}
                stackId="area1"
                name="Atendidos em até 3 minutos"
                yAxisId="left"
                dataKey="lessThan3MinutesRate"
                // type="monotone"
                stroke="#6DD184"
                fill="#6DD184"
              />
              <Bar
                maxBarSize={15}
                stackId="area1"
                name="Atendidos entre 3 e 7 minutos"
                yAxisId="left"
                dataKey="lessThan7MinutesRate"
                // type="monotone"
                stroke="#43A1D1"
                fill="#43A1D1"
              />
              <Bar
                maxBarSize={15}
                stackId="area1"
                name="Atendidos após 7 minutos"
                yAxisId="left"
                dataKey="higherThan7MinutesRate"
                // type="monotone"
                stroke="#D14D59"
                fill="#D14D59"
              />
            </ComposedChart>
          </ResponsiveContainer>}
        </div>)}
      </Column>}
      {isLoadingQueuesData && <Column isSize={12}>
        <div className="card notification has-text-centered">
          <IsLoading isBorderless={true} fontSize={80} />
        </div>
      </Column>}
    </Columns>
  </Page>
}

export default DashQueues;