import React, { Component } from 'react';

import Columns, { Column } from '../../../../components/Columns';
import { ProjectDiagramIcon } from '../../../../components/icons';
import VenueVisitorsChart from './VenueVisitorsChart';


import { subDays, format } from 'date-fns';
import { get, getServerDate } from '../../../../utils';
import DateFilterOneRange from './DateFilterOneRange';
import { addDays } from 'date-fns';

export default class VenueTraffic extends Component {
  constructor(props) {
    super(props);

    const CURRDATE = getServerDate();

    const filterRanges = {
      startDate: format(subDays(CURRDATE, 14), 'YYYY-MM-DD'),
      endDate: format(subDays(CURRDATE, 1), 'YYYY-MM-DD'),
    }
    const filterRangesHourly = {
      startDate: format(subDays(CURRDATE, 14), 'YYYY-MM-DD'),
      endDate: format(subDays(CURRDATE, 1), 'YYYY-MM-DD'),
    }

    this.state = {
      venues: [],
      dailyEntries: [],
      hourEntries: [],
      selectedVenue: 0,
      isLoadingVenueData: true,
      isLoadingVenueDataHourly: true,
      filterRanges,
      filterRangesHourly,
      dailyVenueVisitorsSales: [],
      hourlyVenueVisitorsSales: []
    }
  }

  _loadVenues = () => {
    const { siteId } = this.props;
    this.setState({ isLoadingVenueData: true, isLoadingVenueDataHourly: true }, () => {
      get(`admin/videos/sites/${siteId}/venuesvisitors`)
        .then(res => {
          if (res.data.data.length) {
            this.handleChange({
              target: {
                value: res.data.data[0].id
              }
            });
          }

          this.setState({ venues: res.data.data, isLoadingVenueDataHourly: false, isLoadingVenueData: false });
        })
    })
  }

  componentDidMount() {
    this._loadVenues()
  }

  _changeDate = () => (ranges) => {
    let { endDate, startDate } = ranges;

    startDate = format(startDate, 'YYYY-MM-DD');
    endDate = format(endDate, 'YYYY-MM-DD');

    this.setState({ filterRanges: { ...ranges, startDate, endDate } }, async () => {
      await this.getDaily();
      await this._getDailySalesVenue();
    });
  }

  _changeDateHourly = () => (ranges) => {
    let { endDate, startDate } = ranges;

    startDate = format(startDate, 'YYYY-MM-DD');
    endDate = format(endDate, 'YYYY-MM-DD');

    this.setState({ filterRangesHourly: { ...ranges, startDate, endDate } }, async () => {
      await this.getHourly();
      await this._getHourlySalesVenue();
    });
  }

  getHourly = async () => {
    const { siteId } = this.props;
    const { selectedVenue, filterRangesHourly } = this.state
    this.setState({ isLoadingVenueDataHourly: true }, async () => {
      const hourly = await get(`dashboard/foottraffic/sites/${siteId}/hourlyvenues/${selectedVenue}`, { begin: filterRangesHourly.startDate, end: filterRangesHourly.endDate })
      this.setState({ isLoadingVenueDataHourly: false, hourEntries: hourly.data.data });
    })
  }

  getDaily = async () => {
    const { siteId } = this.props;
    const { selectedVenue, filterRanges } = this.state
    this.setState({ isLoadingVenueData: true }, async () => {
      const daily = await get(`dashboard/foottraffic/sites/${siteId}/dailyvenues/${selectedVenue}`, { begin: filterRanges.startDate, end: filterRanges.endDate })
      this.setState({ isLoadingVenueData: false, dailyEntries: daily.data.data });
    })
  }

  _getDailySalesVenue = async () => {
    const { siteId } = this.props;
    const { selectedVenue, filterRanges } = this.state;
    const dailyData = await get(`dashboard/foottraffic/sites/${siteId}/sales-category-venue/${selectedVenue}`, {
      begin: filterRanges.startDate,
      end: filterRanges.endDate
    })
    this.setState({ dailyVenueVisitorsSales: dailyData.data })
  };

  _getHourlySalesVenue = async () => {
    const { siteId } = this.props;
    const { selectedVenue, filterRangesHourly } = this.state
    const hourlyData = await get(`dashboard/foottraffic/sites/${siteId}/sales-category-hourly/${selectedVenue}`, {
      begin: filterRangesHourly.startDate,
      end: filterRangesHourly.endDate
    })
    this.setState({ hourlyVenueVisitorsSales: hourlyData.data })
  };

  // getDescription = async () => {
  //   const { siteId, venues } = this.state;
  //   const description = await get(`admin/videos/sites/${siteId}/venuesvisitors`, {
  //     description: venues.description
  //   })
  //   console.log("descrição", description)
  // }
  
  handleChange = (event) => {
    const selectedVenue = parseInt(event.target.value)
    this.setState({ selectedVenue }, async () => {
      await this.getDaily()
      await this.getHourly()
      await this._getDailySalesVenue()
      await this._getHourlySalesVenue()
    });
  }
  
  render() {
    const {
      venues,
      selectedVenue,
      dailyEntries,
      hourEntries,
      isLoadingVenueData,
      isLoadingVenueDataHourly,
      filterRanges,
      filterRangesHourly,
      dailyVenueVisitorsSales,
      hourlyVenueVisitorsSales
    } = this.state;

    const { isSuvinil = false } = this.props;
    const venueToDisplay = venues.find((v) => v.id === selectedVenue) || {}

    return <div style={{ width: '100%' }} >
      <Column isSize={12} style={{ display: 'flex', justifyContent: 'center', marginBottom: 10, marginTop: 10 }}>
        {venues.length > 1 && <div className="field">
          <div className="control has-icons-left is-expanded">
            <div className={`select is-medium is-fullwidth `} >
              <select value={selectedVenue} onChange={this.handleChange} style={{ fontSize: '1em' }}>
                <option value={0}>Selecione uma área</option>
                {venues.map(venue =>
                  <option key={venue.id} value={venue.id}>{venue.description}</option>
                )}
              </select>
            </div>
            <ProjectDiagramIcon className="is-left" />
          </div>
        </div>}
        {venues.length === 0 && <p className="subtitle is-4 has-text-centered">
          Não há áreas cadastradas.
        </p>}
        {venues.length === 1 && <p className="subtitle is-4 has-text-centered">
          {venueToDisplay.description}
        </p>}
      </Column>
      <Columns>
        <Column isSize={12}>
          <div className="card" >
            <header className="card-header" style={{ borderBottom: '1px solid #eee', padding: '10px', justifyContent: 'space-between' }}>
              <div className="title is-5 is-marginless">
                Visitantes da Área por Dia
              </div>
            </header>
            <div className="card-content">
              <Columns>
                {isLoadingVenueData && <Column isSize={12}>
                  <div className="has-text-centered" style={{ width: '100%', height: 300, paddingTop: '70px' }}>
                    <span>
                      <span className="button is-loading is-large is-bordless" />
                      <p className="subtitle is-5">Aguarde, carregando...</p>
                    </span>
                  </div>
                </Column>}
                {!isLoadingVenueData && venues.length === 0 && <Column isSize={12}>
                  <div className="has-text-centered" style={{ width: '100%', height: 300, paddingTop: '70px' }}>
                    <span>
                      <p className="subtitle is-5">Não há dados.</p>
                    </span>
                  </div>
                </Column>}
                {selectedVenue > 0 && !isLoadingVenueData && <Column style={{ marginBottom: 20, marginTop: 20 }} isSize={12}>
                  <VenueVisitorsChart
                    isSuvinil={isSuvinil}
                    description={venueToDisplay.description}
                    quantidadeDeVenues={venues.length}
                    data={dailyEntries}
                    formatDate="DD/MM/YYYY"
                    filterRanges={filterRanges}
                    venueVisitorsSales={dailyVenueVisitorsSales} />
                </Column>}
              </Columns>
            </div>
            <footer className="card-footer">
              <span className="button has-text-left is-white card-footer-item is-radiusless is-shadowless is-bordless" style={{ borderRight: 'none' }}>
                <DateFilterOneRange maxDate={addDays(getServerDate(), -1)} changeDates={this._changeDate()} actualRanges={{ ranges: filterRanges }} />
              </span>
              <span className="card-footer-item" style={{ borderRight: 'none' }}>
              </span>
              <span className="card-footer-item" style={{ borderRight: 'none' }}>
              </span>
              <span className="card-footer-item" style={{ borderRight: 'none' }}>
              </span>
            </footer>
          </div>
        </Column>
      </Columns>
      <Columns>
        <Column isSize={12}>
          <div className="card" >
            <header className="card-header" style={{ borderBottom: '1px solid #eee', padding: '10px', justifyContent: 'space-between' }}>
              <div className="title is-5 is-marginless">
                Visitantes da Área por Hora
              </div>
            </header>
            <div className="card-content">
              <Columns>
                {isLoadingVenueDataHourly && <Column isSize={12}>
                  <div className="has-text-centered" style={{ width: '100%', height: 300, paddingTop: '70px' }}>
                    <span>
                      <span className="button is-loading is-large is-bordless" />
                      <p className="subtitle is-5">Aguarde, carregando...</p>
                    </span>
                  </div>
                </Column>}
                {!isLoadingVenueDataHourly && venues.length === 0 && <Column isSize={12}>
                  <div className="has-text-centered" style={{ width: '100%', height: 300, paddingTop: '70px' }}>
                    <span>
                      <p className="subtitle is-5">Não há dados.</p>
                    </span>
                  </div>
                </Column>}
                {selectedVenue > 0 && !isLoadingVenueDataHourly && <Column style={{ marginBottom: 20, marginTop: 20 }} isSize={12}>
                  <VenueVisitorsChart
                    isSuvinil={isSuvinil}
                    description={venueToDisplay.description}
                    quantidadeDeVenues={venues.length}
                    data={hourEntries}
                    isHourly={true}
                    venueVisitorsSales={hourlyVenueVisitorsSales}
                    filterRanges={filterRangesHourly}
                  />
                </Column>}
              </Columns>
            </div>
            <footer className="card-footer">
              <span className="button has-text-left is-white card-footer-item is-radiusless is-shadowless is-bordless" style={{ borderRight: 'none' }}>
                <DateFilterOneRange maxDate={addDays(getServerDate(), -1)} changeDates={this._changeDateHourly()} actualRanges={{ ranges: filterRangesHourly }} />
              </span>
              <span className="card-footer-item" style={{ borderRight: 'none' }}>
              </span>
              <span className="card-footer-item" style={{ borderRight: 'none' }}>
              </span>
              <span className="card-footer-item" style={{ borderRight: 'none' }}>
              </span>
            </footer>
          </div>
        </Column>
      </Columns>
    </div>
  }
}
