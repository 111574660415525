import React, { Component } from 'react';

import { subDays, format } from 'date-fns';
import _ from 'lodash';

import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';

import Page from '../../../../../components/Page';
import Columns, { Column } from '../../../../../components/Columns';
import { ChartLineIcon, CalendarAltIcon, AngleDown } from '../../../../../components/icons';

import GeralShopBox from './charts/GeralShopBox';
import TooqQuadrant from './TooqQuadrant';

import { get, getServerDate } from '../../../../../utils';
import VisitorsChart from './charts/VisitorsChart';
import SalesChart from './charts/SalesChart';
import DatePickerFilter from '../analytics/DatePickerFilter';

class FoottrafficCompare extends Component {

  constructor(props) {
    super(props);

    this.scrollCompareSection = React.createRef();
    this.scrollQuadrantTooq = React.createRef();

    this.state = {
      sites: [],
      sitesSelected: [],
      sitesSlots: {},
      mergedDashData: [],
      section: 'initial',
      comparing: false,
      loadingCompare: false,
      sitesSlotsData: {},
      currentGroup: props.group.id,
      loadingQuadrant: true,
      sitesQuadrant: [],
      selectedDate: subDays(getServerDate(), 1)
    }
  }

  componentDidMount() {
    document.addEventListener('scroll', this._scrollListener);
    const {selectedDate} = this.state;
    this._loadData(selectedDate)
  }

  _loadData = (date) =>{
    const {hasVideoModules} = this.props;
    this.setState({ loadingQuadrant: true }, () => {
      get(`dashboard/${hasVideoModules ? 'video': 'foottraffic'}/groups/${this.state.currentGroup}`, {
        date: format(date, 'YYYY-MM-DD')
      })
      .then(resp => resp.data)
      .then(({ data, quadrantBoundsSuggestion, sites }) => {
        
        const quadrant = data.map(site => {
          const finded = sites.find(s => s.id === site.siteId);
          return {
            ...finded,
            ...site
          }
        });

        this.setState({
          sites,
          quadrantSuggestion: quadrantBoundsSuggestion,
          sitesQuadrant: quadrant,
          loadingQuadrant: false
        });
      });
    });
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this._scrollListener);
  }

  _scrollListener = (e) => {
    const { section } = this.state;

    let supportPageOffset = window.pageXOffset !== undefined;
    let isCSS1Compat = ((document.compatMode || '') === 'CSS1Compat');
    let scroll = {
      x: supportPageOffset ? window.pageXOffset : isCSS1Compat ? document.documentElement.scrollLeft : document.body.scrollLeft,
      y: supportPageOffset ? window.pageYOffset : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop
    };

    if((window.screen.height - scroll.y) > 350) {
      if(section !== 'initial')
        this.setState({ section: 'initial' });
    } else {
      if(section !== 'compare')
        this.setState({ section: 'compare' });
    }
  }

  _handleClickShop = (id) => {
    const { sitesSelected, sitesSlots } = this.state;
    const slots = { ...sitesSlots };

    if(slots[1] === id || slots[2] === id) {
      const { sitesSlotsData } = this.state;

      if(slots[1] === id) {
        slots[1] = undefined;
        delete sitesSlotsData[1];
      } else {
        slots[2] = undefined;
        delete sitesSlotsData[2];
      }

      const withoutSelected = sitesSelected.filter(site => site !== id);
      this.setState({
        comparing: false,
        mergedDashData: [],
        sitesSlotsData: sitesSlotsData,
        sitesSelected: withoutSelected,
        sitesSlots: slots
      });
      return;
    }

    let last = null;
    
    if(slots[1] && slots[2]) {
      slots[2] = id;
      last = slots[1];
    } else if(slots[1] && !slots[2]) {
      slots[2] = id;
      last = slots[1];
    } else if(!slots[1] && slots[2]) {
      slots[1] = id;
      last = slots[2];
    } else {
      slots[1] = id;
      last = slots[2];
    }

    const sitesSelectedNew = last ? [ last, id ] : [ id ];

    this.setState({
      sitesSelected: sitesSelectedNew,
      sitesSlots: slots
    });
  }

  _handleCompareShops = () => {

    const {hasVideoModules} = this.props;
    const { loadingCompare, sitesSlots, selectedDate } = this.state;
    if(loadingCompare)
      return;

    this.setState({ loadingCompare: true }, () => {
      const loadBoard = async () => {
        const dataShop1 = await get(`dashboard/${hasVideoModules ? 'video': 'foottraffic'}/sites/${sitesSlots[1]}/basic`, {
          date: format(selectedDate, 'YYYY-MM-DD'),
          days: '7,15,30,60,90'
        }).then(resp => {
          return resp.data;
        });

        const dataShop2 = await get(`dashboard/${hasVideoModules ? 'video': 'foottraffic'}/sites/${sitesSlots[2]}/basic`, {
          date: format(selectedDate, 'YYYY-MM-DD'),
          days: '7,15,30,60,90'
        }).then(resp => {
          return resp.data;
        });

        return { dataShop1, dataShop2 };
      }

      loadBoard().then(({ dataShop1, dataShop2 }) => {

        const sitesSlotsData = {
          1: dataShop1.header,
          2: dataShop2.header
        }

        let mergedData = {};
        dataShop1.data.forEach(row => {
          const day = { ...mergedData[row.info_date] };
          day[1] = row;
          mergedData[row.info_date] = day;
        });

        dataShop2.data.forEach(row => {
          const day = { ...mergedData[row.info_date] };
          day[2] = row;
          mergedData[row.info_date] = day;
        });

        mergedData = _.toArray(mergedData);
        this.setState({ comparing: true, loadingCompare: false, sitesSlotsData, mergedDashData: mergedData }, () => {
          this.scrollCompareSection.current.scrollIntoView({ block: 'start',  behavior: 'smooth' });
        });
      });

    });
  }

  _dismissCompare = () => {
    this.setState({
      sitesSelected: [],
      sitesSlots: {},
      mergedDashData: [],
      section: 'initial',
      comparing: false,
      loadingCompare: false,
      sitesSlotsData: {}
    }, () => {
      window.scrollTo(0, 0);
    });
  }

  _renderDatePickerLabel = () => {
    const { selectedDate } = this.state;
    return <div style={{ display: 'flex', maxWidth: '100%', justifyContent: 'center' }}>
      <span
        className="tag is-large has-text-left is-dark is-shadowless is-bordless"
        style={{
          borderRight: 'none',
          justifyContent: 'center'
        }}
      >
        <CalendarAltIcon />
        <span>{format(selectedDate, 'DD/MM/YY')}</span>
      </span>
    </div>
  }
  _changeDateFilter = () => (date) => {
    this.setState({
      selectedDate: date
    }, () => {
      this._loadData(date);
    });
  }

  render() {
    const {
      sites,
      sitesSelected,
      sitesSlots,
      sitesSlotsData,
      section,
      comparing,
      loadingCompare,
      sitesQuadrant,
      loadingQuadrant,
      quadrantSuggestion,
      mergedDashData,
      selectedDate
    } = this.state;

    const sitesNotSelected = sitesQuadrant
      .filter(site => sitesSelected.indexOf(site.siteId) === -1)
      .map(site => site.siteId);

    //a ordem dos elementos impacta no z-index do svg.
    const dashData = [
      ...sitesNotSelected,
      ...sitesSelected
    ].map(site => sitesQuadrant.find(s => s.id === site));

    const site1 = {
      ...sites.find(s => s.id === sitesSlots[1]),
      dashData: sitesSlotsData[1]
    }

    const site2 = {
      ...sites.find(s => s.id === sitesSlots[2]),
      dashData: sitesSlotsData[2]
    }

    const {hasVideoModules} = this.props;

    return (<Page reactRef={this.scrollQuadrantTooq}>
      <Columns isMultiline>
        <Column isSize={12}>
          <p className="title">Comparação de lojas</p>
          <p className="subtitle"><ChartLineIcon /> Analise a performance de diferentes lojas</p>
          <p className="has-text-centered">
            {selectedDate && <DatePickerFilter 
              renderLabel={this._renderDatePickerLabel}
              className={'top-to-down-right'}
              changeDates={this._changeDateFilter()}
              currDate={selectedDate}
            />}
          </p>
        </Column>
        <Column isSize={12} style={{ marginTop: 30, display: 'flex', justifyContent: 'center' }}>
          {loadingQuadrant && <div style={{ height: 450, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <span className="button is-loading is-static is-bordless is-large" style={{ fontSize: 80 }} ></span>
          </div>}
          {!loadingQuadrant && <TooqQuadrant
            data={dashData}
            quadrantSuggestion={quadrantSuggestion}
            sitesSlots={sitesSlots}
            handleMouseOver={this._handleMouseOver}
            handleClickShop={this._handleClickShop}
            sitesSelected={sitesSelected}
          />}
        </Column>
        <Column isSize={12}>
          <Columns isMultiline style={{ justifyContent: 'center' }}>
            <Column
              isSize={4}
            >
              {site1.name ? <div
                className="notification is-white"
                style={{
                  borderBottom: '5px solid #00d1b2'
                }}
              >
                <span className="delete is-large" style={{ marginLeft: 30 }} onClick={() => this._handleClickShop(site1.siteId)}></span>
                <div className="subtitle is-5 has-text-centered has-text-weight-bold">
                  {site1.name}
                </div>
              </div> : <div
                className="notification"
                style={{
                  background: '#ffffff',
                  borderBottom: '5px dashed #00d1b2'
                }}
              >
                <p className="subtitle is-5 has-text-centered">
                  Nenhum
                </p>
              </div>}
            </Column>
            <Column isSize={'is-narrow'} className="heading has-text-centered">
              <div style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                <p className="help has-text-weight-bold">
                  vs
                </p>
              </div>
            </Column>
            <Column
              isSize={4}
            >
              {site2.name ? <div className="notification is-white"
                  style={{
                    borderBottom: '5px solid #0043ff',
                  }}
                >
                  <span className="delete is-large" style={{ marginLeft: 30 }} onClick={() => this._handleClickShop(site2.siteId)}></span>
                  <p className="subtitle is-5 has-text-centered has-text-weight-bold">
                    {site2.name}
                  </p>
                </div>
              : <div className="notification"
                  style={{
                    background: '#ffffff', borderBottom: '5px dashed #0043ff'
                  }}
                >
                <p className="subtitle is-5 has-text-centered">
                  Nenhum
                </p>
              </div>}
            </Column>
          </Columns>
          <Columns style={{ justifyContent: 'center' }}>
            {!comparing && site1.name && site2.name && <Column isSize={'is-narrow'}>
              <div className={`button is-primary is-medium is-fullwidth ${loadingCompare ? 'is-loading': ''}`} onClick={this._handleCompareShops}>
                <span>Comparar</span>
                <AngleDown /> 
              </div>
            </Column>}
            {comparing && site1.name && site2.name && <Column isSize={'is-narrow'}>
              <div className={`button is-danger is-medium is-fullwidth ${loadingCompare ? 'is-loading': ''}`} onClick={this._dismissCompare}>
                <span>Cancelar</span>
              </div>
            </Column>}
          </Columns>
        </Column>
      </Columns>
      {section === 'compare' && site1.name && site2.name && <nav className="navbar is-white is-fixed-top" style={{ zIndex: 10, top: '3.55rem', boxShadow: '0px 0px 5px #CCC' }}>
        <Columns isGapless style={{ justifyContent: 'center', width: '100%' }}>
          <Column isSize={4} style={{
            borderBottom: '5px solid #00d1b2',
            borderRadius: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <div className="subtitle is-6 has-text-centered has-text-weight-bold">
              {site1.name}
            </div>
          </Column>
          <Column isSize="is-narrow" style={{
            padding: 20, 
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <span
              className="delete is-large is-danger"
              onClick={this._dismissCompare}
            />
          </Column>
          <Column isSize={4} style={{
            borderBottom: '5px solid #0043ff',
            borderRadius: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
           }}>
            <p className="subtitle is-6 has-text-centered has-text-weight-bold">
              {site2.name}
            </p>
          </Column>
        </Columns>
      </nav>}

      <div ref={this.scrollCompareSection}></div>

      <Columns isMultiline style={{ top: window.screen.height, marginTop: 150 }}>
        <Column isSize={12}>
          <p className="has-text-centered">
            <span className="tag is-large is-dark">
              <CalendarAltIcon style={{ marginRight: 5 }} /> {format(selectedDate, 'DD/MM/YY')}
            </span>
          </p>
        </Column>
        <Column>
          <GeralShopBox periods={site1.dashData} hasVideoModules={hasVideoModules} justify='flex-start' isPrimary />
        </Column>
        <Column isSize={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {loadingCompare && <span className="button is-loading is-static is-bordless" style={{ fontSize: 45 }}></span>}
        </Column>
        <Column>
          <GeralShopBox periods={site2.dashData} hasVideoModules={hasVideoModules} justify='flex-end' />
        </Column>
        <Column isSize={12}>
          <VisitorsChart site1={site1} site2={site2} data={mergedDashData} hasVideoModules={hasVideoModules} />
        </Column>
        <Column isSize={12}>
          <SalesChart site1={site1} site2={site2} data={mergedDashData} hasVideoModules={hasVideoModules} />
        </Column>

      </Columns>
    </Page>
    )
  }
}


const mapStateToProps = (state, props) => {
  const currentGroup = state.sites.currentGroup;
  const sites = _.toArray({ ...state.sites.all })
    .filter(site => site.group.id === currentGroup)
    .filter(site => {
      return (site.modules.foottraffic && site.foottrafficData) || (site.modules.video)
    })
    .map(site => {
      return {
        id: site.id,
        name: site.name,
        group: site.group,
        permission: site.userPermissions.permission,
        modules: site.modules
      }
    });

  return { sites }
}

export default withRouter(connect(mapStateToProps, {})(FoottrafficCompare));