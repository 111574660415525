import React, { Component } from 'react';
import _ from 'lodash';

import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  LabelList,
  Brush,
  Tooltip
} from 'recharts';

import { format, subDays, differenceInDays, addDays } from 'date-fns';
import {pt} from 'date-fns/locale'

import withRouter from "react-router-dom/withRouter";
import { connect } from 'react-redux';

import { SalesIcon, ExclamationCircleIcon } from '../../../../../components/icons';
import Page, { PageTitle } from '../../../../../components/Page';
import { get, formatDateMonthDayOfWeek, getServerDate } from '../../../../../utils';
import Columns, { Column } from '../../../../../components/Columns';

import DateFilterOneRange from './DateFilterOneRange';
import LabelCustomizedAuditing from './LabelCustomizedAuditing';

class DashSalesOnline extends Component {

  constructor(props) {
    super(props);

    const currentDate = getServerDate();

    this.state = {
      sales: [],
      yesterday: {},
      today: [],
      lasthour: {},
      todayLoading: false,
      mainLoading: false,
      dailyRanges: {
        startDate: subDays(currentDate, 7),
        endDate: subDays(currentDate, 1)
      },
      mainRanges: {
        startDate: subDays(currentDate, 365),
        endDate: subDays(currentDate, 1)
      } 
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const { startDate, endDate } = this.state.mainRanges;
    this._loadSalesRate(startDate, endDate);
    this._loadDailySales();
  }

  _loadDailySales = () => {
    const siteId = this.props.site.id;
    const {dailyRanges} = this.state;
    const startDate = format(dailyRanges.startDate, 'YYYY-MM-DD');
    const endDate = format(dailyRanges.endDate, 'YYYY-MM-DD');
    this.setState({ todayLoading: true }, () => {
      get(`dashboard/foottraffic/sites/${siteId}/salesrate`, { type: 'HOURLY', begin: startDate, end: endDate })
        .then(resp => this.setState({ todayLoading: false, today: resp.data.data, lasthour: resp.data.header }));
    })
  } 

  _loadSalesRate = (startDate, endDate) => {
    const siteId = this.props.site.id;
    const begin = format(startDate, 'YYYY-MM-DD');
    const end = format(endDate, 'YYYY-MM-DD');
    
    this.setState({ mainLoading: true }, () => {
      get(`dashboard/foottraffic/sites/${siteId}/salesrate`, { begin, end })
        .then(resp => this.setState({ mainLoading: false, yesterday: resp.data.header,sales: resp.data.data }));
    })
  }

  _changeDateFilter = () => (ranges) => {
    let { endDate, startDate } = ranges;

    startDate = format(startDate, 'YYYY-MM-DD');
    endDate = format(endDate, 'YYYY-MM-DD');

    this.setState({ dailyRanges: { ...ranges, startDate, endDate } }, () => {
      this._loadDailySales();
    });
  }

  render() {
    const { site } = this.props;
    const { mainLoading, sales, today, todayLoading, dailyRanges } = this.state;

    const visitorsDayList = sales.map(s => s.visitors);
    const visitorsHourList = today.map(s => s.visitors);

    const minVisitorsDay = _.min(visitorsDayList);
    const maxVisitorsDay = _.max(visitorsDayList);
    const minVisitorsHour = _.min(visitorsHourList);
    const maxVisitorsHour = _.max(visitorsHourList);

    const diffBetweenDates = differenceInDays(dailyRanges.endDate, dailyRanges.startDate);
    const brushSize = 24 * (diffBetweenDates);

    return <Page>
    <PageTitle
      title={<span>
        <span style={{ marginRight: '10px' }}>
          {`${(site && site.name) || ''} `}
        </span>
        <p className="subtitle">Vendas vs Tráfego <SalesIcon /></p>
      </span>}
    />
      <div className="tile is-ancestor">
        <div className="tile is-vertical is-12">
          <div className="tile">
            <div className="tile is-parent is-vertical is-12">
              <article className="tile is-child card notification is-white has-text-centered">
                  <p className="subtitle is-3">Dados de {`${format(dailyRanges.startDate, 'DD/MM/YY', {locale: pt})} - ${format(dailyRanges.endDate, 'DD/MM/YY', {locale: pt})}`}</p>
                  {todayLoading && <span className="button is-loading is-bordless is-white is-very-big"></span>}
                  {!todayLoading && <ResponsiveContainer width="100%" height={300}>
                    <ComposedChart cursor={false && this.props.permission === 0 ? "pointer": ""} data={today}
                        margin={{top: 20, right: 0, left: 0, bottom: 20}}>
                      <CartesianGrid strokeDasharray="3 10" />
                      <XAxis dataKey="info_date" interval={0} tickFormatter={(val) => `${format(val, 'DD/MM - HH:mm', {locale: pt})}`} />
                      <YAxis yAxisId="left" orientation="left" stroke="#343434" domain={[minVisitorsHour, maxVisitorsHour]}/>
                      <YAxis yAxisId="right" orientation="right" stroke="#343434" tickFormatter={(val) => `${val}%`} domain={[0, 120]}/>
                      <Legend content={this.renderChartLegend} />
                      <Brush
                          startIndex={diffBetweenDates >= 1 ? brushSize + 7 : 7}
                          endIndex={diffBetweenDates >= 1 ? brushSize + 23 : 23}
                          dataKey='info_date'
                          tickFormatter={(val) => `${format(val, 'DD/MM - HH:mm', {locale: pt})}`}
                          height={20}
                          stroke="#000000"/>
                      <Tooltip
                        content={<CustomToolTip type='HOURLY' />}
                        isAnimationActive={false}/>
                      <Bar
                        key="visitors"
                        yAxisId="left"
                        name="Visitantes"
                        dataKey="visitors"
                        maxBarSize={15}
                        fill="#0043ff"
                        isAnimationActive={false}
                        minPointSize={3}
                        connectNulls={true}
                      >
                        <LabelList
                          dataKey="auditingOk"
                          position="top"
                          content={<LabelCustomizedAuditing />}
                        />
                      </Bar>
                      <Bar
                        key="total_sales"
                        yAxisId="left"
                        name="Vendas"
                        dataKey="total_sales"
                        maxBarSize={15}
                        fill="#00d1b2"
                        isAnimationActive={false}
                        minPointSize={3}
                        connectNulls={true}
                      />
                      <Line key="conversionRate"
                        yAxisId="right"
                        name="Taxa de conversão"
                        dataKey="conversionRate"
                        strokeDasharray="3 5"
                        fill="#000"
                        stroke="#000"
                        isAnimationActive={false}
                        connectNulls={true}
                      >
                        <LabelList dataKey="conversionRate" position="top" formatter={(val) => `${val}%`} fontSize={22} />
                      </Line>
                    </ComposedChart>
                  </ResponsiveContainer>}
                  {!todayLoading && <footer className="card-footer" style={{borderTop: 'none', paddingLeft: 40}}>
                    <span className="button has-text-left is-white card-footer-item is-radiusless is-shadowless is-bordless" style={{ borderRight: 'none' }}>
                      <DateFilterOneRange maxDate={addDays(getServerDate(), -1)} changeDates={this._changeDateFilter()} actualRanges={{ ranges: dailyRanges }}/>
                    </span>
                    <span className="card-footer-item" style={{ borderRight: 'none' }}>
                    </span>
                    <span className="card-footer-item" style={{ borderRight: 'none' }}>
                    </span>
                    <span className="card-footer-item" style={{ borderRight: 'none' }}>
                    </span>
                  </footer>}
              </article>
            </div>
          </div>
        </div>
      </div>
      <Columns isMultiline>
        <Column isSize={12}>
          {mainLoading && <div className="card notification is-white has-text-centered">
            <p className="subtitle is-3">Último ano</p>
            <span className="button is-loading is-bordless is-white is-very-big"></span>
          </div>}
          {!mainLoading && <div className="card notification is-white has-text-centered">
            <p className="subtitle is-3">Último ano</p>
            <ResponsiveContainer width="100%" height={300}>
              <ComposedChart cursor={false && this.props.permission === 0 ? "pointer": ""} data={sales}
                  margin={{top: 20, right: 10, left: 10, bottom: 20}}>
                <CartesianGrid strokeDasharray="3 10" />
                <XAxis dataKey="info_date" tickFormatter={(val) => `${formatDateMonthDayOfWeek(`${val} 00:00:00`)}`}/>
                <YAxis yAxisId="left" orientation="left" stroke="#343434" domain={[minVisitorsDay, maxVisitorsDay]} />
                <YAxis yAxisId="right" orientation="right" stroke="#343434" tickFormatter={(val) => `${val}%`} domain={[0, 120]}/>
                <Legend content={this.renderChartLegend} />
                <Brush
                  startIndex={sales.length - 31}
                  endIndex={sales.length - 1}
                  dataKey='info_date'
                  tickFormatter={(val) => `${format(val, 'DD/MM ddd', {locale: pt})}`}
                  height={20}
                  stroke="#000000"/>
                <Tooltip
                  content={<CustomToolTip />}
                  isAnimationActive={false}/>
                <Bar
                  key="visitors"
                  yAxisId="left"
                  name="Visitantes"
                  dataKey="visitors"
                  maxBarSize={15}
                  fill="#0043ff"
                  isAnimationActive={false}
                  minPointSize={3}
                  connectNulls={true}
                >
                  <LabelList
                    dataKey="auditingOk"
                    position="top"
                    content={<LabelCustomizedAuditing />}
                  />
                </Bar>
                <Bar
                  key="total_sales"
                  yAxisId="left"
                  name="Vendas"
                  dataKey="total_sales"
                  maxBarSize={15}
                  fill="#00d1b2"
                  isAnimationActive={false}
                  minPointSize={3}
                  connectNulls={true}
                />
                <Line key="taxaConversao"
                  yAxisId="right"
                  name="Taxa de conversão"
                  dataKey="conversionRate"
                  strokeDasharray="3 5"
                  fill="#000"
                  stroke="#000"
                  isAnimationActive={false}
                  connectNulls={true}
                >
                  <LabelList dataKey="conversionRate" position="top" formatter={(val) => `${val}%`} fontSize={22} />
                </Line>
              </ComposedChart>
            </ResponsiveContainer>
          </div>}
        </Column>
      </Columns>
    </Page>
  }
}

class CustomToolTip extends Component {
  render() {
    const { type, active } = this.props;
    if(active) {
      const { payload=[] } = this.props;

      if(payload === null)
        return null;

      const data = payload[0] || { payload: {} };

      return (<div className="card">
        <div  style={{ padding: '0px 5px 0px 5px' }}>
          <p className="heading has-text-centered">
            <span>Em {type === 'HOURLY' ? format(data.payload.info_date, 'DD/MM - HH:mm') : format(data.payload.info_date, 'DD-MM-YYYY')}</span>
          </p>

          <span className="heading has-text-centered" style={{ fontWeight: 'bold', marginBottom: '-5px', color: '#0043ff' }}>
            <span>Visitantes</span>
          </span>
          <span className="heading has-text-centered" style={{ color: '#0043ff' }}>
            <span>{data.payload.visitors}</span>
          </span>

          <span className="heading has-text-centered" style={{ fontWeight: 'bold', marginBottom: '-5px', color: '#00d1b2' }}>
            <span>Vendas</span>
          </span>
          <span className="heading has-text-centered" style={{ color: '#00d1b2' }}>
            {data.payload.hour && <span>{data.payload.sales}</span>}
            {!data.payload.hour && <span>{data.payload.total_sales}</span>}
          </span>
          
          <span className="heading has-text-centered" style={{ fontWeight: 'bold', marginBottom: '-5px', color: '#000000' }}>
            <span>Taxa de conversão</span>
          </span>
          <span className="heading has-text-centered" style={{ color: '#000000' }}>
            {data.payload.hour && <span>{data.payload.rate}</span>}
            {!data.payload.hour && <span>{data.payload.conversionRate}</span>}
          </span>

          {!data.payload.auditingOk && <span
            className="help is-danger">
            <ExclamationCircleIcon />
            <span>
              Dados não confiáveis.
            </span>
          </span>}
        </div>
      </div>)
    }
    return null;
  }
}

function mapStateToProps(state, props) {
  return { site: props.site }
}

export default withRouter(connect(mapStateToProps)(DashSalesOnline));