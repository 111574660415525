import React, { Component } from 'react';
import _ from 'lodash';

import randomColor from 'randomcolor';

import {
  addDays
} from 'date-fns';


import {
  ResponsiveContainer,
  BarChart,
  Cell,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Tooltip,
  LabelList
} from 'recharts';

import DateFilterOneRange from './DateFilterOneRange';
import { formatDateDayOfWeek, getServerDate } from '../../../../../utils';
import DownloadButton from '../../../../../components/DownloadButton';
import * as Icons from '../../../../../components/icons';
import LabelCustomizedAuditing from './LabelCustomizedAuditing';

const colors = ['#0043ff', '#00d1b2', '#ff424b', '#23527c', '#7571ff', '#4267b2', '#d6249f', '#E8770C', '#D1C000', '#8D00DE' ]

class VenueVisitors extends Component {

  constructor(props) {
    super(props);
    const CURRDATE = addDays(getServerDate(), -1);
    const {
      filterRanges = {
        startDate: addDays(CURRDATE, -6),
        endDate: CURRDATE,
        key: 'selectionRange1'
      },
      dash = {
        header: {},
        data: []
      },
      dashVideo = false
    } = props;

    this.state = {
      isLoading: false,
      typeChart: 'DAILY',
      filterRanges: filterRanges,
      dash: dash,
      selectedDate: null,
      dashVideo
    }
  }

  _changeDateFilter = () => (ranges) => {
    this.setState({ filterRanges: ranges }, () => {
      this.loadData();
    });
  }

  loadData = () => {
    this.setState({ isLoading: true, selectedDate: null }, () => {
      const { filterRanges } = this.state;
      this.props.loadData(filterRanges.startDate, filterRanges.endDate)
        .then(result => {
          this.setState({ isLoading: false, dash: result });
        });
    });
  }

  render() {
    const { viewOptions } = this.props;
    const typeData = (viewOptions && viewOptions.foottraffic && viewOptions.foottraffic.attractiveness && viewOptions.foottraffic.attractiveness.metric === 'percentage') ? 'conversionRate' : 'peopleCount';

    const { dash, filterRanges, isLoading, selectedDate, dashVideo } = this.state;

    const venuesToIgore = dash.venues
      .filter(venue =>
        venue.fittingroom || venue.passersBy || venue.cashdesk || venue.cashdeskQueue)
      .map(venue => venue.id);
      
    // const venuesToIgore = dash.venues
    // .filter(venue => 
    //   venue.count_as_passers_by || venue.count_as_cashdesk || venue.count_as_fittingroom || venue.count_as_cashiers_queue || !venue.model_created || venue.dontShow)
    //   .map(venue => parseInt(venue.id, 10));


    const chartVenues = dash.header.venues
      .map(v => v.venueid)
      .filter(venue => !venuesToIgore.includes(venue))
  
    const data = dash.data.map(date => {
      const venues = date.venues
        .filter(venue => !venuesToIgore.includes(venue.venueid))
        .reduce((acc, curr) => {
          acc[curr.venueid] = curr;
          return acc;
        }, {});
      return { ...date, venues }
    });

    let selectedDateChart = [];
    if(selectedDate) {
      const dataVenues = data.find(data => data.info_date === selectedDate) || { venues: {} };

      selectedDateChart = _.chain(dataVenues.venues).toArray().value();
    }

    let nextColor = colors.values();
    const colorsChart = _.range(0, chartVenues.length).map((val, index) => {
      if(colors[index]) {
        return colors[index];
      } else {
        const color = nextColor.next();
        if(color.done) {
          nextColor = colors.values();
          const colorHas = nextColor.next().value;
          return randomColor({ hue: colorHas, seed: index * 150 });
        } else {
          return randomColor({ hue: color.value, seed: index * 150 });
        }
      }
    });

    return (<div className="card notification is-white is-paddingless ">
      <header className="card-header" style={{ padding: '20'}}>
        <p className="card-header-title">
          Visitantes nas áreas
        </p>
        {!dashVideo && <span className="card-header-icon" aria-label="Download PDF">
          <DownloadButton site={this.props.site} name='venuevisitors' begin={selectedDate ? selectedDate: filterRanges.startDate} end={selectedDate ? selectedDate: filterRanges.endDate}/>
        </span>}
      </header>
      <div className="card-content" style={{
        justifyContent: 'center',
        flexDirection: 'column',
        display: 'flex',
        height: '600px'
      }}>
        <p className="subtitle is-5 has-text-centered">Atratividade {selectedDate && <span>no dia <b>{formatDateDayOfWeek(selectedDate)}</b></span>}</p>
        <p className="help has-text-centered">Indicador do número de visitantes da área que permaneceram ao menos 1 minuto na mesma. {!selectedDate && <b>Clique no dia para expandir.</b>} </p>
        {selectedDate ?
          (isLoading
            ? <div className="has-text-centered" style={{ height:400, paddingTop: '100px' }}>
                <span>
                  <span className="button is-loading is-large is-bordless" />
                  <p className="subtitle is-5">Aguarde, carregando...</p>
                </span>
              </div>
            : <div>
                <ResponsiveContainer width="100%" height={400}>
                  <BarChart width={500} height={300} data={selectedDateChart}
                    margin={{top: 20, right: 30, left: 20, bottom: 5}}>
                    <CartesianGrid strokeDasharray="1 2"/>
                    <XAxis height={80} interval={0} angle={10} textAnchor="middle" tickMargin={15} type="category" dataKey="venueid" stroke={"#000000"} fill={"#000000"} tickFormatter={(val) => {
                      const venue = dash.venues.find(v => v.id === val) || { name : '' };
                      return venue.name;
                    }}/>
                    <YAxis/>
                    <Tooltip
                      content={<div></div>}
                      isAnimationActive={false}/>
                    <Bar
                      minPointSize={4}
                      barSize={30}
                      isAnimationActive={false}
                      dataKey={typeData}
                      label={
                        {
                          position: 'top',
                          formatter: (val) => {
                            if(typeData === 'conversionRate')
                              return `${val}%`
                            return val;
                          }
                        }
                      }
                      fill={'#0043ff'}
                    >
                      {
                        selectedDateChart.map((entry, index) => (
                          <Cell key={`cellbar-${index}`} fill={colorsChart[index]}/>
                        ))
                      }
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
                {!selectedDateChart[0].auditingOk && <p className="help is-danger has-text-centered">
                  <Icons.ExclamationCircleIcon />
                  <span>
                    Dados não confiáveis.
                  </span>
                </p>}
                <span className="button subtitle is-6" onClick={() => this.setState({ selectedDate: null })}> Ver linha do tempo </span>
              </div>)
        : isLoading 
          ? <div className="has-text-centered" style={{ height:400, paddingTop: '100px' }}>
              <span>
                <span className="button is-loading is-large is-bordless" />
                <p className="subtitle is-5">Aguarde, carregando...</p>
              </span>
            </div>
          : <ResponsiveContainer width="100%" height={400}>
              <BarChart width={600} height={300} data={data}
                margin={{top: 20, right: 30, left: 20, bottom: 5}}>
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey="info_date" tickFormatter={(val) => formatDateDayOfWeek(val)}/>
                <YAxis hide={true}/>
                <Tooltip
                  content={(props) => <div></div>}
                  isAnimationActive={false}/>
                <Legend />
                {chartVenues.map((venueid, index) => {
                  const venue = dash.venues.find(v => v.id === venueid);
                  return <Bar
                    minPointSize={4}
                    key={`${venueid}`}
                    barSize={30}
                    cursor="pointer" 
                    onClick={(val) => this.setState({ selectedDate: val.info_date })}
                    isAnimationActive={false}
                    name={venue ? venue.name : venueid}
                    dataKey={`venues.${venueid}.peopleCount`}
                    stackId="a"
                    fill={colorsChart[index] || '#0043ff'}
                  >
                    {index + 1 === chartVenues.length && <LabelList
                      dataKey={`venues.${venueid}.auditingOk`}
                      position="top"
                      content={<LabelCustomizedAuditing addX={8} />}
                    />}
                  </Bar>
                })}
              </BarChart>
            </ResponsiveContainer>}
      </div>
      <footer className="card-footer" style={{ padding: '20px'}}>
        <span className="button has-text-left is-white card-footer-item is-radiusless is-shadowless is-bordless" style={{ borderRight: 'none' }}>
          <DateFilterOneRange changeDates={this._changeDateFilter()} actualRanges={{ ranges: filterRanges }}/>
        </span>
        <span className="card-footer-item" style={{ borderRight: 'none' }}>
        </span>
        <span className="card-footer-item" style={{ borderRight: 'none' }}>
        </span>
        <span className="card-footer-item" style={{ borderRight: 'none' }}>
        </span>
      </footer>
    </div>)
  }
}

export default (VenueVisitors);