import React from 'react';

import {pt as ptBR} from 'react-date-range/src/locale';
import Columns, { Column } from '../../../../components/Columns';
import { CalendarAltIcon } from '../../../../components/icons';
import { Calendar } from 'react-date-range';

import '../../../../styles/Calendar.css';

import {
  addDays,
  subDays,
  format,
  isValid,
  parse,
  isBefore
} from 'date-fns';
import { getServerDate } from '../../../../utils';

class CalendarDatePicker extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      date: props.date,
      dateField: format(props.date, 'DD/MM/YY')
    }
  }

  _handleChange = (d) => {
    this.setState({
      date: d,
      dateField: format(d, 'DD/MM/YY')
    });
  }

  _changeInputField = (field) => (e) => {
    const { value } = e.target;
    const { dateField } = this.state;
    const fields = { dateField };
    fields[field] = value;
    this.setState({ ...fields }, () => {
      const { dateField } = this.state;
      const brasilStartDateField = parse(dateField, 'DD/MM/YY', getServerDate());
      const isStartDateValid = isValid(brasilStartDateField);

      if(isStartDateValid) {
        this.setState({
          date: brasilStartDateField
        });
      }
    });
  }

  _handleConfirm = (canConfirm) => () => {
    if(!canConfirm) {
      return;
    }

    const { date } = this.state;
    this.props.onChange(date);
  }

  render() {
    const { color='#00d1b2', maxDate=subDays(getServerDate(), 1) } = this.props;
    const { date, dateField } = this.state; 

    const brasilStartDateFieldFormat = parse(dateField, 'DD/MM/YY', getServerDate());
    const isStartBeforeToday = isBefore(brasilStartDateFieldFormat, format(addDays(maxDate, 1), 'YYYY-MM-DD'));
    const startDateIsValid = isStartBeforeToday && isValid(brasilStartDateFieldFormat);

    const canConfirm = startDateIsValid;

    return <div style={{ display: 'flex' }}>
      <div className="card notification dontShowDefinedRanges">
        <Columns style={{marginTop: 0, marginBottom: 0, justifyContent: 'center'}}>
          <Column isSize={'is-narrow'}>
            <label className="label has-text-centered">Dia</label>
            <div className="field has-addons has-addons-right ">
              <div className="control is-expanded has-icons-left">
                <input
                  style={{ maxWidth: 200, borderColor: startDateIsValid && color }}
                  className={`input ${startDateIsValid ? '' : 'is-danger'} is-large`}
                  placeholder="Ex: 14/12/19"
                  value={dateField}
                  onChange={this._changeInputField('dateField')}
                />
                <span className="icon is-small is-left">
                  <CalendarAltIcon />
                </span>
                {!isValid(brasilStartDateFieldFormat)
                  ? <p className="help is-danger">Data incorreta</p>
                  : !isStartBeforeToday
                    ? <p className="help is-danger">Data deve ser menor ou igual à {format(maxDate, 'DD/MM/YY')}</p> : <p></p>}
              </div>
            </div>
          </Column>
        </Columns>
        <Calendar
          direction="horizontal"
          months={1}
          date={date}
          maxDate={maxDate}
          locale={ptBR}
          onChange={this._handleChange}
          rangeColors={[color]}
        />
        <div className="buttons is-centered" style={{ marginTop: 20 }}>
          <div className={`button ${canConfirm ? 'is-primary': 'is-static'}`} onClick={this._handleConfirm(canConfirm)}>Confirmar</div>
          <div className="button is-default" onClick={this.props.close}>Cancelar</div>
        </div>
      </div>
    </div>
  }
}

export default CalendarDatePicker;