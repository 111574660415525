import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';

import { get, post, del } from '../../../../../utils';
import Columns, { Column } from '../../../../../components/Columns';
import * as Icons from '../../../../../components/icons';

import notification from '../../../../../utils/notification';
import DvrsImages from './DvrsImages';

class CameraArea extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isRemovingDraw: false,
      isAddinngDraw: false,
      isLoadingVenues: false,
      isLoadingSavedVertices: false,
      selectedVenue: 0,
      savedVertices: [],
      vertices: [],
      venues: [],
      subMenuSelected: 'draw'
    }
  }

  componentDidMount() {
    this._loadVenues();
    this._loadVertices();

    const node = ReactDOM.findDOMNode(this.refs.canvas);
    const rect = node.getBoundingClientRect();
    const { left, top } = rect;
    this.setState({ top, left });
  }

  _setSubMenuAsDraw = () => {
    this.setState({ subMenuSelected: 'draw' });
  }

  _setSubMenuAsDvrImages = () => {
    this.setState({ subMenuSelected: 'dvr_images' });
  }

  _isDrawClosed = () => {
    const { vertices } = this.state;
    const endVertice = vertices.find(vertice => vertice.end);
    return !!endVertice;
  }

  _saveDraw = () => {
    const { site, camera } = this.props;
    const { isAddinngDraw, isRemovingDraw, selectedVenue, vertices, savedVertices } = this.state;

    if(isAddinngDraw || isRemovingDraw)
      return;

    const points = vertices.map(vertice => `${vertice.x},${vertice.y}`).join(' ');

    this.setState({isAddinngDraw: true}, () => {
      post(`admin/videos/sites/${site.id}/cameras/${camera.id}/vertices`, {
        venueId: selectedVenue,
        vertices: points
      })
      .then(resp => {
        notification.success('Dados salvos com sucesso!')
        savedVertices.push(resp.data.data);
        this.setState({ isAddinngDraw: false, selectedVenue: 0, savedVertices, vertices: [] });
      });
    })
  }

  handleMouseDown = (evt) => {
    evt.persist();

    const e = evt.target;
    if(e.localName !== 'svg' && e.localName !== 'image')
      return;

    const dim = e.getBoundingClientRect();
    const x = parseInt(evt.clientX - dim.left, 10);
    const y = parseInt(evt.clientY - dim.top, 10);
    const { vertices, selectedVenue } = this.state;

    if(this._isDrawClosed())
      return;

    if(!selectedVenue)
      return;

    vertices.push({ x, y });
    this.setState({ vertices });
  };

  _undoLastVertice = () => {
    const { vertices } = this.state;
    vertices.pop();
    this.setState({ vertices });
  }

  endDraw = (x, y) => (e) => {
    const { vertices } = this.state;

    const firstPoint = _.first(vertices);
    if(firstPoint.x !== x || firstPoint.y !== y) {
      return;
    }

    vertices.push({ x, y, end: true });
    this.setState({ vertices })
  }

  _removeDrawsFromVenue = (venueId) => {
    return () => {
      const { site, camera } = this.props;
      const { isAddinngDraw, isRemovingDraw, savedVertices } = this.state;
  
      if(isAddinngDraw || isRemovingDraw)
        return;
  
  
      this.setState({ isRemovingDraw: true }, () => {
        del(`admin/videos/sites/${site.id}/cameras/${camera.id}/vertices-venue/${venueId}`)
          .then(resp => {
            notification.success('Removido com sucesso!')
            this.setState({ isRemovingDraw: false, savedVertices: savedVertices.filter(v => v.venueId !== venueId) });
          });
      })
    }
  }

  _loadVertices = () => {
    const { site } = this.props;
    const { camera } = this.props;

    this.setState({ isLoadingSavedVertices: true }, () => {
      get(`admin/videos/sites/${site.id}/cameras/${camera.id}/vertices`)
        .then(resp => resp.data.data)
        .then(savedVertices => {
          this.setState({ savedVertices, isLoadingSavedVertices: false });
        });
    });
  }

  _loadVenues = () => {
    const { id } = this.props.site;
    this.setState({ isLoadingVenues: true }, () => {
      get(`admin/videos/sites/${id}/venues`)
        .then(resp => {
          const venues = resp.data.data;
          const first = _.first(venues) || {}
          this.setState({ venues, selectedVenue: first.id, isLoadingVenues: false });
        });
    });
  }

  _onSelectVenue = (e) => {
    e.persist();

    const { value } = e.target;
    this.setState({ selectedVenue: parseInt(value, 10) });
  }

  _switchCameraSrc = (image) => {
    const { camera } = this.props;
    post(`admin/videos/sites/${this.props.site.id}/cameras/${camera.id}/dvrimage/${image.id}`)
      .then(() => {
        this._setSubMenuAsDraw();
      })
  }

  render() {
    const { camera } = this.props;
    const { subMenuSelected, venues, isRemovingDraw, isAddinngDraw, vertices, isLoadingVenues, selectedVenue, savedVertices, isLoadingSavedVertices } = this.state;
    const endVertice = vertices.find(vertice => vertice.end);
    const color = (endVertice) ? '#00d1b2' : '#222222';

    const savedVerticesWithVenueData = savedVertices.map(savedPolygon => {
        const { venueId, cameraVenueId, points } = savedPolygon;
        const venue = venues.find(venue => venue.id === venueId);
        return { ...venue, venueId, cameraVenueId, points };
      });

    return (<div className="notification card">
    <p className="title is-5">URL: {camera.url}</p>
    <p className="subtitle is-5">Descrição: {camera.description}</p>
      <Columns isMultiline>
        <Column isSize={4}>
          <label className="label">Todas as Áreas da loja:</label>
          <div className="field is-grouped is-small">
            <span className={`control has-icons-left is-expanded ${isLoadingVenues ? 'is-loading' : ''}`}>
              <span className={`select is-fullwidth `}>
                <select onChange={this._onSelectVenue} value={selectedVenue}>
                  <option value={0}>Selecione uma área</option>
                  {venues.map(venue => <option key={`venue-options-${venue.id}`} value={venue.id}>{venue.description}</option>)}
                </select>
              </span>
              <Icons.MapMarkedIcon className="is-left"/>
            </span>
          </div>
          <div className="buttons">
            <div className="button is-default" onClick={this._undoLastVertice}>
              Desfazer
            </div>
            {this._isDrawClosed()
              ? <div className={`button is-primary ${isAddinngDraw ? 'is-loading' : ''}`} onClick={this._saveDraw}>
                Salvar Desenho
              </div>
              : <div className="button is-static">
                Feche o Poligono para salvar
              </div>}
          </div>
        </Column>
        <Column isSize={2}>
          <div className="field">
            <label className="label">Width</label>
            <div className="control">
              <input value={camera.width} className="input" type="text" placeholder="Width (ex: 1280)" />
            </div>
          </div>
          <div className="field">
            <label className="label">Height</label>
            <div className="control">
              <input value={camera.height} className="input" type="text" placeholder="Height (ex: 720)" />
            </div>
          </div>
        </Column>
        <Column>
          <label className="label">Areas relacionadas:</label>
          {isLoadingSavedVertices && <span className="button is-loading is-bordless"></span>}
          <Columns isMultiline>
            {savedVerticesWithVenueData.map(venue => {
              return <Column isSize="is-narrow">
                <div className="tags has-addons" style={{ marginRight: 5 }}>
                  <span key={`camera-venue-vertices-cancel-${venue.venueId}-${venue.points}`} className="tag is-success">
                    {venue.description}
                  </span>
                  <span className={`tag is-delete is-small `} onClick={this._removeDrawsFromVenue(venue.venueId)}></span>
                </div>
              </Column>
            })}
          </Columns>
          {isRemovingDraw && <span>Aguarde removendo... <span className="button is-bordless is-loading" /> </span>}
        </Column>
        <Column isSize={12}>
          <div className="tabs">
            <ul>
              <li className={subMenuSelected === "draw" ? "is-active": ""}>
                <button onClick={this._setSubMenuAsDraw}>Poligono</button>
              </li>
              <li className={subMenuSelected === "dvr_images" ? "is-active": ""}>
                <button onClick={this._setSubMenuAsDvrImages}>Todas os dvrs da loja</button>
              </li>
            </ul>
          </div>
        </Column>
        {subMenuSelected === 'dvr_images' && <Column isSize={12}>
          <DvrsImages site={this.props.site} camera={camera} changeCameraImage={this._switchCameraSrc}/>
        </Column>}
        {subMenuSelected === 'draw' && <Column isSize={12}>
          <svg
            name="svgDraw"
            style={{
              border: '1px solid #222222',
              borderRadius: 5,
              cursor: 'crosshair'
            }}
            width={camera.width}
            height={camera.height}
            ref="canvas"
            >
              <image
                href={camera.imageSrc}
                x="0"
                y="0"    
                onMouseDown={this.handleMouseDown}
                width={camera.width}
                height={camera.height}
              />
              {vertices.map((vertice, i) => {
                if(vertices.length === i + 1) {
                  return <circle
                    onMouseDown={this._undoLastVertice}
                    key={`venue-vertice-${vertice.x}-${vertice.y}-${vertice.end}`}
                    cx={vertice.x}
                    cy={vertice.y}
                    fill={'red'}
                    r="14"
                  />
                }

                return <circle
                  onMouseDown={this.endDraw(vertice.x, vertice.y)}
                  key={`venue-vertice-${vertice.x}-${vertice.y}-${vertice.end}`}
                  cx={vertice.x}
                  cy={vertice.y}
                  fill={color}
                  r="14"
                />
              })}

              {vertices.length > 1 && <polyline points={vertices.map(vertice => `${vertice.x},${vertice.y}`).join(' ')} fill="none" stroke="black" />}
              {savedVerticesWithVenueData.map(venueVertices => <polyline
                key={`vertice-${venueVertices.venueId}-${venueVertices.points}`}
                points={venueVertices.points}
                fill="#72CB46"
                fillOpacity={0.2}
                text
                stroke='#222222'
                strokeWidth={venueVertices.venueId === selectedVenue ? 10 : 1}
              >
                Teste
              </polyline>)}
          </svg>
        </Column>}
      </Columns>
    </div>);
  }
}

export default (CameraArea);
