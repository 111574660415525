import React, { Component } from 'react';

import { format } from 'date-fns';
import randomColor from 'randomcolor';
import withRouter from "react-router-dom/withRouter";
import { connect } from 'react-redux';

import { HomeIcon, WalkingIcon, CogIcon } from '../../../../../components/icons';
import Page, { PageTitle } from '../../../../../components/Page';
import { get } from '../../../../../utils';

import PeoplecountChart from './PeoplecountChart';
import VisitorsRateChart from './VisitorsRateChart';
import SalesRateChart from './SalesRateChart';
import CustomerBehaviorChart from './CustomerBehaviorChart';
import ReturnRateChart from './ReturnRateChart';
import VisitorsAndPassersByHourly from './VisitorsAndPassersByHourly';
import HeatMapVenues from './HeatMapVenues';

import * as HeatMapVenuesVideo from '../../video/HeatMapVenues';
import * as CustomerBehaviorChartVideo from '../../video/CustomerBehaviorChart';

import AvailableSensor from './AvailableSensor';

import VenueTraffic from '../../video/VenueTraffic';

class DashAnalytics extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      toogleModal: false,
      modalContent: null,

      visitorsReturnsErr: false,
      visitorsRateInsideShopErr: false,
      venuePeopleCountErr: false,
      peopleCountErr: false,

      noDataToShow: false,
      sales: null,
      dataSourceDefinition: {
        passersby: null,
        visitors: null,
        venues: null,
        visitors_returns: null
      }
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    this.__loadDataSourceDefinition();
  }

  __loadDataSourceDefinition = () => {
    const siteId = this.props.site.id;
    return get(`dashboard/foottraffic/sites/${siteId}/datasourcedefinition`)
    .then(resp => {
      const {
        passersby='rf',
        visitors='rf',
        venues='rf',
        visitors_returns='rf'
      } = resp.data.data;

      this.setState({
        dataSourceDefinition: {
          passersby,
          visitors,
          venues,
          visitors_returns
        }
      });
    })
    .catch(err => {
      return err;
    });
  }

  loadVisitorReturnsRate = (startDate, endDate) => {
    const siteId = this.props.site.id;
    const begin = format(startDate, 'YYYY-MM-DD HH:mm:SS');
    const end = format(endDate, 'YYYY-MM-DD HH:mm:SS');
    const timezonebegin = format(startDate, 'Z');
    const timezoneend = format(endDate, 'Z');

    return get(`dashboard/foottraffic/sites/${siteId}/returnsrate`, { begin, end, timezonebegin, timezoneend })
    .catch(err => {
      this.setState({ visitorsReturnsErr: true });
      return { data: { header: {}, data: [] } };
    })
    .then(resp => resp.data);
  }

  loadSalesRate = (startDate, endDate) => {
    const siteId = this.props.site.id;
    const begin = format(startDate, 'YYYY-MM-DD');
    const end = format(endDate, 'YYYY-MM-DD');

    return get(`dashboard/foottraffic/sites/${siteId}/salesrate`, { begin, end })
    .catch(err => {
      this.setState({ visitorsRateInsideShopErr: true });
      return { 
        data: { 
          header: {
            total_sales: 0,
            visitors: 0,
            conversionRate: 0
          },
          data: []
        }
      };
    })
    .then(resp => resp.data);
  }

  loadVenuePeopleCount = async (startDate, endDate, heatmap) => {
    const siteId = this.props.site.id;
    const begin = format(startDate, 'YYYY-MM-DD HH:mm:SS');
    const end = format(endDate, 'YYYY-MM-DD HH:mm:SS');
    const timezonebegin = format(startDate, 'Z');
    const timezoneend = format(endDate, 'Z');

    const venueResponse = await get(`dashboard/foottraffic/sites/${siteId}/venuevisitors`, { begin, end, timezonebegin, timezoneend }).then(res => res.data);
    if(!venueResponse || !venueResponse.header) {
      return {
        data: { header: { venues: [] }, data: [] }, venues: [], heatmap: false
      }
    }

    const venues = venueResponse.header.venuesInfo.map((venue, i) => {
      return {
        ...venue,
        color: randomColor({ hue: '#0043ff', seed: i*1234 })
      }
    });

    return { ...venueResponse, venues };
  }

  loadHeatmapsV2 = async (startDate) => {
    const siteId = this.props.site.id;
    const date = format(startDate, 'YYYY-MM-DD HH:mm:ss');
    const venueResponse = await get(`dashboard/foottraffic/sites/${siteId}/heatmapv2`, { date }).then(res => res.data);
    const hasHeatmap = venueResponse.data.length;
    return { date, hasHeatmap, heatmaps: venueResponse.data };
  }

  loadVenuePeopleCountVideo = async (startDate, endDate, heatmap) => {
    const siteId = this.props.site.id;
    const begin = format(startDate, 'YYYY-MM-DD');
    const end = format(endDate, 'YYYY-MM-DD');

    const venueResponse = await get(`dashboard/foottraffic/sites/${siteId}/dailyvenues`, { begin, end }).then(res => res.data);
    if(!venueResponse || !venueResponse.header) {
      return {
        data: { header: { venues: [] }, data: [] }, venues: [], heatmap: false
      }
    }

    const venues = venueResponse.header.venues.map((venue, i) => {
      return {
        ...venue,
        color: randomColor({ hue: '#0043ff', seed: i*1234 })
      }
    });

    return { ...venueResponse, venues };
  }

  loadHeatmaps = async (startDate) => {
    const siteId = this.props.site.id;
    const date = format(startDate, 'YYYY-MM-DD HH:mm:SS');
    const venueResponse = await get(`dashboard/foottraffic/sites/${siteId}/heatmaps`, { date }).then(res => res.data);
    const hasHeatmap = venueResponse.data.length;
    return { date, hasHeatmap, heatmaps: venueResponse.data };
  }

  
  loadPeopleCount = (type, startDate, endDate, startDate2, endDate2) => {
    const siteId = this.props.site.id;
    const begin = format(startDate, 'YYYY-MM-DD HH:mm:SS');
    const end = format(endDate, 'YYYY-MM-DD HH:mm:SS');

    const timezonebegin = format(startDate, 'Z');
    const timezoneend = format(endDate, 'Z');

    if(startDate2 && endDate2) {
      const begin2 = format(startDate2, 'YYYY-MM-DD HH:mm:SS');
      const end2 = format(endDate2, 'YYYY-MM-DD HH:mm:SS');

      const timezonebegin2 = format(startDate2, 'Z');
      const timezoneend2 = format(endDate2, 'Z');

      return get(`dashboard/foottraffic/sites/${siteId}/peoplecount`, { type, begin, end, timezonebegin, timezoneend, begin2, end2, timezonebegin2, timezoneend2 })
        .catch(err => {
          this.setState({ peopleCountErr: true });
          return { data: { header: {}, data: [] } };
        })
        .then(resp => resp.data);
    }
    
    return get(`dashboard/foottraffic/sites/${siteId}/peoplecount`, { type, begin, end, timezonebegin, timezoneend })
      .catch(err => {
        this.setState({ peopleCountErr: true });
        return { data: { header: {}, data: [] } };
      })
      .then(resp => resp.data);
  }

  _toogleModal = (content) => {
    const { toogleModal } = this.state;
    this.setState({ toogleModal: !toogleModal, modalContent: content });
  }

  render() {
    const { site, tooqAdmin } = this.props;
    const siteId = this.props.site.id;

    const {
      toogleModal,
      modalContent,
      noDataToShow,
      sales,
      dataSourceDefinition
    } = this.state;

    if(noDataToShow) {
      return <Page>
        <PageTitle
          title={<span>
              <span style={{ marginRight: '10px' }}>
                {`${(site && site.name) || ''} `}
              </span>
              <p className="subtitle">Tráfego da loja <WalkingIcon /></p>
            </span>}
        />
         <section className="hero fadeOpacity-3" >
          <div className="hero-body">
            <div className="container is-fluid has-text-centered">
              <p className="title is-2"><CogIcon className="is-large"/> O administrador da loja deve fazer a configuração.</p>
              <p className="subtitle is-6">Caso tenha dúvidas entre em contato com TooqSoluções!</p>
            </div>
          </div>
        </section>
      </Page>
    }

    return <Page>
      <PageTitle
        title={<span>
          <span style={{ marginRight: '10px' }}>
            {`${(site && site.name) || ''} `}
          </span>
          <p className="subtitle">Fluxo da loja <WalkingIcon /></p>
        </span>}
      />
      {toogleModal && <div className="modal is-active">
        <div className="modal-background" onClick={this._toogleModal}></div>
        <div className="modal-content is-paddingless ">
          {modalContent}
        </div>
        <button className="modal-close is-large" onClick={this._toogleModal} aria-label="close"></button>
      </div>}
      <span className="subtitle is-6 margin-bottom-10">
        <HomeIcon />
        <span> Performance Geral</span>
      </span>
      <div className="tile is-vertical is-ancestor margin-top-10">
        <div className="tile is-12">
          <div className="tile is-8 is-parent">
            <PeoplecountChart loadPeopleCount={this.loadPeopleCount}/>
          </div>
          <div className="tile is-4 is-vertical is-parent">
            <div className="tile is-child"> 
              <VisitorsRateChart site={this.props.site} siteId={siteId} loadPeopleCount={this.loadPeopleCount} showMore={this._toogleModal} />
            </div>
            <div className="tile is-child">
              {site.modules.sales && <SalesRateChart sales={sales} site={this.props.site} siteId={siteId} loadSalesRate={this.loadSalesRate} showMore={this._toogleModal} />}
            </div>
          </div>
        </div>
      </div>
      <div className="tile is-vertical is-ancestor margin-top-10">
        <div className="tile is-12 is-primary">
          <div className="tile is-parent">
            <VisitorsAndPassersByHourly site={this.props.site} siteId={siteId} loadPeopleCount={this.loadPeopleCount} />
          </div>
        </div>
      </div>
      {site.modules.venues && (dataSourceDefinition.venues === 'rf') && <div className="tile is-vertical is-ancestor margin-top-10">
        <div className="tile is-12 is-primary">
          <div className="tile is-parent">
            <CustomerBehaviorChart site={this.props.site} siteId={siteId} loadVenuePeopleCount={this.loadVenuePeopleCount} showMore={this._toogleModal} />
          </div>
        </div>
      </div>}
      {site.modules.venues && (dataSourceDefinition.venues === 'rf') && <div className="tile is-vertical is-ancestor margin-top-10">
        <div className="tile is-12 is-primary">
          <div className="tile is-parent">
            <HeatMapVenues site={this.props.site} siteId={siteId} loadHeatmaps={this.loadHeatmaps} showMore={this._toogleModal} />
          </div>
        </div>
      </div>}

      {site.modules.venues && (dataSourceDefinition.venues === 'video') && <div className="tile is-ancestor margin-top-10"> 
        <div className="tile is-12 is-parent">
          <div className="tile is-child">
            <CustomerBehaviorChartVideo.default
              site={this.props.site}
              siteId={siteId}
              loadVenuePeopleCount={this.loadVenuePeopleCountVideo}
              showMore={this._toogleModal}
              dashVideo={true}
            />
          </div>
        </div> 
      </div>}

      {site.modules.venues && (dataSourceDefinition.venues === 'video') && <div className="tile is-ancestor margin-top-10"> 
        <div className="tile is-12 is-parent">
          <div className="tile is-child">
            <HeatMapVenuesVideo.default
              site={this.props.site}
              siteId={siteId}
              loadHeatmaps={this.loadHeatmapsV2}
              showMore={this._toogleModal}
            />
          </div>
        </div> 
      </div>}

      {/* {site.modules.venues && (dataSourceDefinition.venues === 'video') && <div className="tile is-ancestor margin-top-10">  */}
      {true && (dataSourceDefinition.venues === 'video') && <div className="tile is-ancestor margin-top-10"> 
        <div className="tile is-12 is-parent">
          <div className="tile is-child">
            <VenueTraffic site={this.props.site} isSuvinil={false} siteId={siteId} dashVideo={true} />
          </div>
        </div> 
      </div>}

      {site.modules.loyalty && <div className="tile is-ancestor margin-top-10"> 
        <div className="tile is-12 is-parent">
          <div className="tile is-child">
            <ReturnRateChart site={this.props.site} siteId={siteId} loadVisitorReturnsRate={this.loadVisitorReturnsRate} showMore={this._toogleModal}/>
          </div>
        </div> 
      </div>}

      {tooqAdmin && <div className="tile is-ancestor margin-top-10">   
        <div className="tile is-12 is-parent">
          <div className="tile is-child">
            <AvailableSensor site={this.props.site} siteId={siteId} showMore={this._toogleModal} />
          </div>
        </div> 
      </div>}
    </Page>
  }
}


function mapStateToProps(state, props) {
  return {
    tooqAdmin: state.app.user.tooqAdmin,
    site: props.site
  }
}

export default withRouter(connect(mapStateToProps)(DashAnalytics));