import React from 'react';

import {pt as ptBR} from 'react-date-range/src/locale';
import Columns, { Column } from '../../../../components/Columns';
import { CalendarAltIcon } from '../../../../components/icons';
import { DateRangePicker } from 'react-date-range';

import '../../../../styles/Calendar.css';

import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  isSameDay,
  subDays,
  getISODay,
  format,
  isValid,
  parse,
  isBefore
} from 'date-fns';
import { getServerDate } from '../../../../utils';

const currentDate = getServerDate()

const defineds = {
  startOfWeek: startOfWeek(currentDate),
  endOfWeek: endOfWeek(currentDate),
  startOfLastWeekOnMonday: startOfWeek(addDays(currentDate, -7), { weekStartsOn: 1 }),
  endOfLastWeekOnMonday: endOfWeek(addDays(currentDate, -7), { weekStartsOn: 1 }),
  startOfLastWeek: startOfWeek(addDays(currentDate, -7)),
  endOfLastWeek: endOfWeek(addDays(currentDate, -7)),
  startOfToday: startOfDay(currentDate),
  endOfToday: endOfDay(currentDate),
  startOfYesterday: startOfDay(addDays(currentDate, -1)),
  endOfYesterday: endOfDay(addDays(currentDate, -1)),
  startOfMonth: startOfMonth(currentDate),
  endOfMonth: endOfMonth(currentDate),
  startOfLastMonth: startOfMonth(addMonths(currentDate, -1)),
  endOfLastMonth: endOfMonth(addMonths(currentDate, -1)),
  yesterday: subDays(currentDate, 1),
  startOfLastWeekend: subDays(addDays(currentDate, 5 - getISODay(currentDate)), 7),
  endOfLastWeekend: addDays(subDays(addDays(currentDate, 5 - getISODay(currentDate)), 7), 2)
};

const staticRangeHandler = {
  range: {},
  isSelected(range) {
    const definedRange = this.range();
    return (
      isSameDay(range.startDate, definedRange.startDate) &&
      isSameDay(range.endDate, definedRange.endDate)
    );
  },
};

export function createStaticRanges(ranges) {
  const result = ranges.map(range => ({ ...staticRangeHandler, ...range }));
  return result;
}

export const noneStaticRanges = createStaticRanges([]);

const defaultStaticRanges = createStaticRanges([
  {
    label: 'Últimos 7 dias',
    range: () => ({
      startDate: addDays(defineds.yesterday, - 6),
      endDate: defineds.yesterday,
      range: {
        startDate: addDays(defineds.yesterday, - 6),
        endDate: defineds.yesterday,
      }
    }),
  },
  {
    label: 'Últimos 15 dias',
    range: () => ({
      startDate: addDays(defineds.yesterday, - 14),
      endDate: defineds.yesterday,
      range: {
        startDate: addDays(defineds.yesterday, - 14),
        endDate: defineds.yesterday,
      }
    }),
  },
  {
    label: 'Últimos 30 dias',
    range: () => ({
      startDate: addDays(defineds.yesterday, - 29),
      endDate: defineds.yesterday,
      range: {
        startDate: addDays(defineds.yesterday, - 29),
        endDate: defineds.yesterday,
      }
    }),
  },
  {
    label: 'Último fim de semana',
    range: () => ({
      startDate: defineds.startOfLastWeekend,
      endDate: defineds.endOfLastWeekend,
      range: {
        startDate: defineds.startOfLastWeekend,
        endDate: defineds.endOfLastWeekend,
      }
    }),
  },
  {
    label: 'Última semana (ini. SEGUNDA)',
    range: () => ({
      startDate: defineds.startOfLastWeekOnMonday,
      endDate: defineds.endOfLastWeekOnMonday,
      range: {
        startDate: defineds.startOfLastWeekOnMonday,
        endDate: defineds.endOfLastWeekOnMonday,
      }
    }),
  },
  {
    label: 'Última semana (ini. DOMINGO)',
    range: () => ({
      startDate: defineds.startOfLastWeek,
      endDate: defineds.endOfLastWeek,
      range: {
        startDate: defineds.startOfLastWeek,
        endDate: defineds.endOfLastWeek,
      }
    }),
  },
  {
    label: 'Último mês',
    range: () => ({
      startDate: defineds.startOfLastMonth,
      endDate: defineds.endOfLastMonth,
      range: {
        startDate: defineds.startOfLastMonth,
        endDate: defineds.endOfLastMonth,
      }
    }),
  }
]);

const defaultInputRanges = [];


class CalendarRangePicker extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      range: {
        startDate: props.range.startDate,
        endDate: props.range.endDate 
      },
      startDateField: format(props.range.startDate, 'DD/MM/YY'),
      endDateField: format(props.range.endDate, 'DD/MM/YY')
    }
  }

  _handleChange = (d) => {
    this.setState({
      range: {
        startDate: d.range.startDate,
        endDate: d.range.endDate 
      },
      startDateField: format(d.range.startDate, 'DD/MM/YY'),
      endDateField: format(d.range.endDate, 'DD/MM/YY')
    });
  }

  _changeInputField = (field) => (e) => {
    const { value } = e.target;
    const { startDateField, endDateField } = this.state;
    const fields = { startDateField, endDateField };
    fields[field] = value;
    this.setState({ ...fields }, () => {
      const { startDateField, endDateField } = this.state;
      const brasilStartDateField = parse(startDateField, 'DD/MM/YY', getServerDate());
      const brasilEndDateField = parse(endDateField, 'DD/MM/YY', getServerDate());
      const isStartDateValid = isValid(brasilStartDateField);
      const isEndDateValid = isValid(brasilEndDateField);
      // const endHigherThanBegin = isBefore(brasilStartDateField, brasilEndDateField)

      if(isStartDateValid && isEndDateValid) {
        this.setState({
          range: {
            startDate: brasilStartDateField,
            endDate: brasilEndDateField
          }
        });
      }
    });
  }

  _handleConfirm = (canConfirm) => () => {
    if(!canConfirm) {
      return;
    }

    const { range } = this.state;
    this.props.onChange({ range });
  }

  render() {
    const { color='#00d1b2', maxDate=subDays(currentDate, 1) } = this.props;
    const { range } = this.state; 
    const ranges = [{ ...range, key: 'range' }];
    const { startDateField, endDateField } = this.state;

    const brasilStartDateFieldFormat = parse(startDateField, 'DD/MM/YY', getServerDate())
    const brasilEndDateFieldFormat = parse(endDateField, 'DD/MM/YY', getServerDate())

    const endHigherThanBegin = !isBefore(brasilEndDateFieldFormat, brasilStartDateFieldFormat)
    const isStartBeforeMaxDate = isBefore(brasilStartDateFieldFormat, format(addDays(maxDate, 1), 'YYYY-MM-DD'));
    const isEndBeforeMaxDate = isBefore(brasilEndDateFieldFormat, format(addDays(maxDate, 1), 'YYYY-MM-DD'));
    const isBeforeMaxDate = isStartBeforeMaxDate && isEndBeforeMaxDate
    const startDateIsValid = isStartBeforeMaxDate && endHigherThanBegin && isValid(brasilStartDateFieldFormat)
    const endDateIsValid = isEndBeforeMaxDate && endHigherThanBegin && isValid(brasilEndDateFieldFormat)

    const canConfirm = startDateIsValid && endDateIsValid && endHigherThanBegin && isBeforeMaxDate;

    return <div>
      <div className="card notification calendarTooltip">
        <Columns style={{marginTop: 0, marginBottom: 0, justifyContent: 'center'}}>
          <Column isSize={'is-narrow'}>
            <label className="label has-text-centered">Data Inicio</label>
            <div className="field has-addons has-addons-right">
              <div className="control is-expanded has-icons-left">
                <input
                  style={{ maxWidth: 150, borderColor:  endDateIsValid && color }}
                  className={`input ${startDateIsValid ? '' : 'is-danger'}`}
                  placeholder="Ex: 14/12/19"
                  value={startDateField}
                  onChange={this._changeInputField('startDateField')}
                />
                <span className="icon is-small is-left">
                  <CalendarAltIcon />
                </span>
                {!isValid(brasilStartDateFieldFormat)
                  ? <p className="help is-danger">Data incorreta</p>
                  : !isStartBeforeMaxDate
                    ? <p className="help is-danger">Data deve ser menor ou igual à {format(maxDate, 'DD/MM/YY')}</p>
                    : !endHigherThanBegin ? <p className="help is-danger">Data inicio é maior que data fim.</p> : <p></p>}
              </div>
            </div>
          </Column>
          <Column isSize={'is-narrow'}>
            <label className="label has-text-centered">Data fim</label>
            <div className="field has-addons has-addons-right ">
              <div className="control is-expanded has-icons-left">
                <input
                  style={{ maxWidth: 150, borderColor: endDateIsValid && color }}
                  className={`input ${endDateIsValid ? '' : 'is-danger'}`}
                  placeholder="Ex: 14/12/19"
                  value={endDateField}
                  onChange={this._changeInputField('endDateField')}
                />
                <span className="icon is-small is-left">
                  <CalendarAltIcon />
                </span>
                {!isValid(brasilEndDateFieldFormat)
                  ? <p className="help is-danger">Data incorreta</p>
                  : !isEndBeforeMaxDate
                    ? <p className="help is-danger">Data deve ser menor ou igual à {format(maxDate, 'DD/MM/YY')}</p> : <p></p>}
              </div>
            </div>
          </Column>
        </Columns>
        <DateRangePicker
          startDatePlaceholder="Data Inicio"
          endDatePlaceholder="Data Fim"
          editableDateInputs={true}
          direction="horizontal"
          months={1}
          maxDate={maxDate}
          rangeColors={[color]}
          locale={ptBR}
          inputRanges={defaultInputRanges}
          dateDisplayFormat="dd/MM/yy"
          staticRanges={defaultStaticRanges}
          ranges={ranges}
          monthDisplayFormat="MMMM"
          onChange={this._handleChange}
          moveRangeOnFirstSelection={false}
          showPreview={true}
          weekStartsOn={1}
        />
        <div className="buttons">
          <div className={`button ${this.props.loadingButton ? 'is-loading' : ''} ${canConfirm ? 'is-primary': 'is-static'}`} onClick={this._handleConfirm(canConfirm)}>Confirmar</div>
          <div className="button is-default" onClick={this.props.close}>Cancelar</div>
        </div>
      </div>
    </div>
  }
}

export default CalendarRangePicker;