import { combineReducers } from 'redux';

import { reducer as App } from './app';
import { reducer as Sites } from './sites';

const rootReducer = combineReducers({
    app: App,
    sites: Sites
});

export default rootReducer;