
import React from 'react';

import { StethoscopeIcon } from '../../../../components/icons';
import { formatAsPtBr } from '../../../../utils';

const SLA_GOOD = (val) => val >= 75 && val <= 125;
const SLA_MED = (val) => (val < 75 && val >= 70) || (val <= 130 && val > 125);

const HealthSiteModal = (props) => {
  const { site, audits, isVenue=false, venue={}, isVisitor=false, isPassers=false } = props;

  return <div className="modal is-active">
    <div className="modal-background" onClick={() => props.closeModal()}></div>
    <div className="modal-content is-paddingless " style={{ width: '70%' }}>
      <div className="card notification calendarNotDisplay-left-bar">
        <p className="title is-4 has-text-centered">{site.name}</p>
        <p className="subtitle is-4 has-text-centered">
          <StethoscopeIcon />
          {isVenue && <span> {venue.venue.description} </span>}
          {isVisitor && <span> Visitantes</span>}
          {isPassers && <span> Passantes</span>}
        </p>
  
        <table className="table" style={{ width: '100%' }}>
          <thead>
            <tr>
              <th>Dia Semana</th>
              <th>Data</th>
              <th>Hora</th>
              <th>Auditado</th>
              <th>Dashboard</th>
              <th>Acurácia</th>
            </tr>
          </thead>
          <tbody>
            {audits.map((audit, i) => <tr key={`auditoria_summary_${i}`}>
              <td>{formatAsPtBr(audit.snapDate, 'dddd')}</td>
              <td>{audit.snapDate}</td>
              <td>{audit.snapTime}</td>
              <td>{audit.visitors}</td>
              <td>{audit.visitorsDashboard}</td>
              <td style={{ backgroundColor: SLA_GOOD(audit.accuracy) ? '#34a853' : SLA_MED(audit.accuracy) ? '#ffd666' : '#d76359' }}>{audit.accuracy}%</td>
            </tr>)}
          </tbody>
        </table>
      </div>
    </div>
  </div>
}


export default HealthSiteModal;