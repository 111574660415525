import React, { Component } from 'react';

import { get, post } from '../../../../utils';
import Columns, { Column } from '../../../../components/Columns';
import notification from '../../../../utils/notification';

class SalesIntegrationAPI extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      shopIdIntegracao: '',
      shopIdIntegracaoOriginal: ''
    }
  }

  componentDidMount() {
    this._loadingShopIdIntegracao();
  }

  _saveShopIdIntegracao = () => {
    const { siteId } = this.props;
    this.setState({ isLoading: true }, () => {
      const { shopIdIntegracao } = this.state;
      post(`admin/tooq/sites/${siteId}/modules/sales`, {
        shopIdIntegracao
      })
      .then(resp => {
        notification.success('Dados salvos com sucesso!')
        this.setState({
          isLoading: false,
          shopIdIntegracao,
          shopIdIntegracaoOriginal: shopIdIntegracao
        });
      })
    });
  }

  _onChangeShopIdIntegracao = (e) => {
    const { value } = e.target;
    this.setState({ shopIdIntegracao: value });
  }
  
  _loadingShopIdIntegracao() {
    const { siteId } = this.props;
    this.setState({ isLoading: true }, () => {
      get(`admin/tooq/sites/${siteId}/modules/sales`).then(resp => {
        const { shopIdIntegracao } = resp.data.data;
        this.setState({
          isLoading: false,
          shopIdIntegracao,
          shopIdIntegracaoOriginal: shopIdIntegracao
        });
      });
    });
  }

  render() {
    const { shopIdIntegracaoOriginal, shopIdIntegracao, isLoading } = this.state;
    return (<Columns isMultiline>
      <Column isSize={4}>
        <label className="label">Id de integração loja</label>
        <div className="field is-grouped">
          <div className={`control is-expanded `}>
            <input
              disabled={isLoading}
              onChange={this._onChangeShopIdIntegracao}
              className="input"
              type="text"
              placeholder="Id integração loja Ex: 010069"
              value={shopIdIntegracao}
            />
          </div>
          <span className="control">
            {shopIdIntegracao === shopIdIntegracaoOriginal 
              ? <span className={`button is-static ${isLoading? 'is-loading':''}`}>
                  <span>Salvo</span>
                </span>
              : <span className={`button is-primary ${isLoading? 'is-loading':''}`} onClick={this._saveShopIdIntegracao}>
                  <span>Salvar</span>
                </span>}
          </span>
        </div>
      </Column>
    </Columns>);
  }
}

export default (SalesIntegrationAPI);