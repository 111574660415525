import { addHours, differenceInHours } from 'date-fns';
import { range } from 'lodash';
import React, { useEffect, useState } from 'react'; 
import { Bar, ComposedChart, Legend, ResponsiveContainer, XAxis, YAxis, Tooltip, Line, LabelList, Brush } from 'recharts';

import { ChartLineIcon } from '../../../../components/icons';
import { formatAsPtBr } from '../../../../utils';




const CustomToolTip = (props) => {
  const { active } = props;

  if(active) {
    const { payload=[] } = props;
    const data = payload[0] || { payload: {} };

    return (<div className="card notification is-white">
      <p className="heading has-text-centered" style={{ fontWeight: 'bold' }}>
        {formatAsPtBr(data.payload.infoDate, 'HH:mm DD/MM/YY')}
      </p>

      <span className="heading has-text-left">
        <span>Vendas: <b style={{ fontSize: 22, color:"#72CB46"}}>{data.payload.sales}</b></span>
      </span>
      <span className="heading has-text-left">
        <span>Visitantes Totais: <b style={{ fontSize: 22, color:"#00d1b2"}}>{data.payload.visitorsTotal}</b></span>
      </span>
      <span className="heading has-text-left">
        <span>Conversão Totais: <b style={{ fontSize: 22 }}>{data.payload.attemptAllConversionRate}%</b></span>
      </span>
      <span className="heading has-text-left">
        <span>Visitantes Novos: <b style={{ fontSize: 22, color:"#00d1b2"}}>{data.payload.visitorsNew}</b></span>
      </span>
      <span className="heading has-text-left">
        <span>Conversão Novos: <b style={{ fontSize: 22 }}>{data.payload.attemptNewConversionRate}%</b></span>
      </span>

      <br/>
      <span className="heading has-text-left has-text-weight-bold">
        <span>Auditorias: {data.payload.audits.length}</span>

        {data.payload.audits.map(audit => <div>
          <span className="heading has-text-left has-text-weight-bold">{audit.description}: {audit.visitors === 0 ? 0 : parseInt((data.payload.visitorsNew / audit.visitors) * 100)}% ({audit.visitors})</span>
        </div>)}
      </span>

    </div>)
  }
  return null;
}

const rangeHourlyForDay = (date) => {
  const dff = differenceInHours(`${date} 23:59:59`, `${date} 00:00:00`);
  const size = dff+1;
  return range(0, size)
    .map(index => formatAsPtBr(addHours(`${date} 00:00:00`, index), 'YYYY-MM-DD HH:mm:ss'));
}


const SalesChartPreview = (props) => {
  const { closeModal, attempts, dashboardData, audits } = props;

  const [ hideWhenNoSales, setHideWhenNoSales ] = useState(false);
  const [ seeOnlyAudits, setSeeOnlyAudits ] = useState(false);

  const [ data, setData ] = useState([]);
  const [ dayData, setDayData ] = useState([]);

  const [ hide, setHide ] = useState({
    visitorsNew: true,
    visitorsBefore: true,
    sales: true,
    attemptAllConversionRate: true,
    attemptNewConversionRate: false,
  });

  const toogleDataKey = (dataKey) => {
    setHide({ ...hide, [dataKey]: !hide[dataKey] });
  }

  useEffect(() => {
    const combinedData = attempts.map(attempt => {
      const infoDate = `${attempt.snapDate} ${attempt.snapTime}`
      const dashData = dashboardData.find(dash => dash.info_date === infoDate) || {
        info_date: infoDate,
        total_sales: 0,
        visitors: 0
      };

      const auditsInThisTime = audits.filter(audit => `${audit.snapDate} ${audit.snapTime}` === infoDate);
      return {
        infoDate,
        date: attempt.snapDate,
        visitorsTotal: attempt.visitors,
        visitorsNew: attempt.visitorsNew,
        visitorsBefore: attempt.visitorsBefore,
        sales: dashData.total_sales,
        audits: auditsInThisTime,
        dbSize: attempt.dbSize,
        reidThreshold: attempt.reidThreshold,
        detectionsInHour: attempt.detectionsInHour,
        actualVisitorsDashboard: dashData.visitors,
        attemptAllConversionRate: attempt.visitors === 0 ? 0 : parseInt((dashData.total_sales / attempt.visitors) * 100),
        attemptNewConversionRate: attempt.visitorsNew === 0 ? 0 : parseInt((dashData.total_sales / attempt.visitorsNew) * 100)
      }
    })

    const days = Object.keys(combinedData.reduce((prev, curr) => {
      prev[curr.date] = true;
      return prev;
    }, {}));

    const dailyRange = days.map(day => rangeHourlyForDay(day)).flat().map(day => {
      const row = combinedData.find(data => data.infoDate === day) || {
        infoDate: day,
        visitorsTotal: 0,
        visitorsNew: 0,
        visitorsBefore: 0,
        sales: 0,
        audits: [],
        actualVisitorsDashboard: 0,
        attemptAllConversionRate: 0,
        attemptNewConversionRate: 0,
      };

      return {
        ...row,
        visitorsNew: hideWhenNoSales && row.sales === 0 ? 0 : row.visitorsNew,
        visitorsTotal: hideWhenNoSales && row.sales === 0 ? 0 : row.visitorsTotal,
        visitorsBefore: hideWhenNoSales && row.sales === 0 ? 0 : row.visitorsBefore
      };
    })
    .filter(d => !seeOnlyAudits || (d.audits.length > 0 && seeOnlyAudits))

    setData(dailyRange);

    const day = dailyRange.reduce((prev, curr) => {
      const snapDate = curr.infoDate.split(' ')[0];
      const currDay = prev[snapDate] || {
        infoDate: snapDate,
        visitors: 0,
        sales: 0
      }

      currDay.visitors += curr.visitorsNew;
      currDay.sales += curr.sales;
      prev[snapDate] = currDay;
      return prev;
    }, {});

    setDayData(Object.values(day).map(d => {
      return {
        ...d,
        salesRate: parseInt((d.sales / d.visitors) * 100)
      }
    }));

    // eslint-disable-next-line
  }, [ seeOnlyAudits, hideWhenNoSales ]);

  return <div className="modal is-active">
    <div className="modal-background" onClick={() => closeModal()}></div>
    <div className="modal-content is-paddingless " style={{ width: '90%', height: '90%' }}>
      <div className="card notification calendarNotDisplay-left-bar">
        <p className="subtitle is-4 has-text-centered">
          <ChartLineIcon style={{ marginRight: 10 }} />
          <span>
            Análise gráfica de vendas <b>Tentativa</b>
          </span>
        </p>

        <label className="checkbox">
          <input checked={seeOnlyAudits} onChange={e => setSeeOnlyAudits(e.target.checked)} type="checkbox"/>
          Ver somente horários com auditoria
        </label>

        <label className="checkbox" style={{ marginLeft: 10 }}>
          <input checked={hideWhenNoSales} onChange={e => setHideWhenNoSales(e.target.checked)} type="checkbox"/>
          Zerar visitantes quando não há vendas
        </label>

        <ResponsiveContainer width="100%" height={300}>
          <ComposedChart
            syncId="previewAttemptsId"
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            {data.length > 19 && <Brush 
              className="brushDisplayNone"
              startIndex={data.length - 19}
              dataKey='infoDate'
              height={20}
              tickFormatter={(val) => `${formatAsPtBr(val, '(ddd) DD/MM')}`}
              stroke="#363636"
            />}
            <XAxis dataKey="infoDate" tickFormatter={(val) => `${formatAsPtBr(val, 'ddd HH:mm')}`}/>
            <YAxis yAxisId="right" orientation="right"/>
            <YAxis yAxisId="left" orientation="left" />
            <Tooltip content={<CustomToolTip/>} />
            <Legend onClick={e => toogleDataKey(e.dataKey)}/>
            <Bar visibility={hide.visitorsNew ? 'visible' : 'hidden'} name="Visitantes Novos" yAxisId="left" dataKey="visitorsNew" fill="#00d1b2" stackId="visitors"/>
            <Bar visibility={hide.visitorsBefore ? 'visible' : 'hidden'} name="Visitantes hora antes" yAxisId="left" dataKey="visitorsBefore" fill="#00d1b2" opacity={0.6} stackId="visitors"/>
            <Bar visibility={hide.sales ? 'visible' : 'hidden'} name="Qtde Vendas" yAxisId="left" dataKey="sales" fill="#72CB46" stackId="sales"/>
            <Line key="attemptAllConversionRate"
              visibility={hide.attemptAllConversionRate ? 'visible' : 'hidden'}
              yAxisId="right"
              name="Conversão TODOS"
              dataKey="attemptAllConversionRate"
              strokeDasharray="3 5"
              fill="#000"
              stroke="#000"
              isAnimationActive={false}
              connectNulls={true}
            >
              {hide.attemptAllConversionRate &&  <LabelList dataKey={"attemptAllConversionRate"} position="top" formatter={(val) => `${val}%`} fontSize={22} />}
            </Line>
            <Line key="attemptNewConversionRate"
              visibility={hide.attemptNewConversionRate ? 'visible' : 'hidden'}
              yAxisId="right"
              name="Conversão NOVOS"
              dataKey="attemptNewConversionRate"
              strokeDasharray="3 5"
              fill="#000"
              stroke="#000"
              isAnimationActive={false}
              connectNulls={true}
            >
              {hide.attemptNewConversionRate && <LabelList dataKey={"attemptNewConversionRate"} position="top" formatter={(val) => `${val}%`} fontSize={22} />}
            </Line>
            {/* <Bar dataKey="uv" fill="#82ca9d" /> */}
          </ComposedChart>
        </ResponsiveContainer>

        <ResponsiveContainer width="100%" height={300}>
          <ComposedChart
            syncId="previewAttemptsId"
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            {data.length > 19 && <Brush 
              startIndex={data.length - 19}
              dataKey='infoDate'
              height={20}
              tickFormatter={(val) => `${formatAsPtBr(val, '(ddd) DD/MM')}`}
              stroke="#363636"
            />}
            <XAxis dataKey="infoDate" tickFormatter={(val) => `${formatAsPtBr(val, 'ddd HH:mm')}`}/>
            <YAxis yAxisId="right" orientation="right"/>
            <YAxis yAxisId="left" orientation="left" />
            <Tooltip />
            <Legend onClick={e => toogleDataKey(e.dataKey)}/>
            <Bar name="Detections In Hour" yAxisId="left" dataKey="detectionsInHour" fill="#0043ff"/>
            <Bar name="Vendas" yAxisId="right" dataKey="sales" fill="#72CB46"/>
          </ComposedChart>
        </ResponsiveContainer>

        <p className="subtitle is-4 has-text-centered">Visão do dia</p>
        <ResponsiveContainer width="100%" height={300}>
          <ComposedChart
            data={dayData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            {dayData.length > 19 && <Brush 
              startIndex={dayData.length - 19}
              dataKey='infoDate'
              height={20}
              tickFormatter={(val) => `${formatAsPtBr(val, '(ddd) DD/MM')}`}
              stroke="#363636"
            />}
            <XAxis dataKey="infoDate" tickFormatter={(val) => `${formatAsPtBr(val, '(ddd) DD/MM')}`}/>
            <YAxis yAxisId="right" orientation="right" domain={[0, 100]}/>
            <YAxis yAxisId="left" orientation="left" />
            <Tooltip />
            <Legend />
            <Bar visibility={hide.visitorsNew ? 'visible' : 'hidden'} name="Visitantes" yAxisId="left" dataKey="visitors" fill="#00d1b2" stackId="visitors"/>
            <Bar visibility={hide.sales ? 'visible' : 'hidden'} name="Vendas" yAxisId="left" dataKey="sales" fill="#72CB46" stackId="sales"/>

            <Line key="salesRate"
              yAxisId="right"
              name="Conversão Vendas"
              dataKey="salesRate"
              strokeDasharray="3 5"
              fill="#000"
              stroke="#000"
              isAnimationActive={false}
              connectNulls={true}
            >
              <LabelList dataKey={"salesRate"} position="top" formatter={(val) => `${val}%`} fontSize={22} />
            </Line>
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  </div>
}

export default SalesChartPreview;