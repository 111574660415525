import { useState } from "react";

export const useCrud = (idField='id') => {
  const [ behaviour, setBehaviour ] = useState({
    isLoadingList: false,
    isAddingRow: false,
    isLoadingRow: {},
    isRemovingRow: {},
    isUpdatingRow: {},

    data: [],
    error: null
  });

  const add = (request, middlewareAdd=(val) => val) => {
    if (behaviour.isAddingRow)
      return;

    setBehaviour({
      ...behaviour,
      isAddingRow: true
    })

    return request()
      .then(resp => {
        setBehaviour({
          ...behaviour,
          isAddingRow: false,
          data: [
            ...behaviour.data,
            middlewareAdd(resp.data.data)
          ]
        })
        return resp.data.data;
      }).catch(err => {
        setBehaviour({
          ...behaviour,
          isAddingRow: false
        });
        throw err;
      })
  }

  const list = (request, middlewareMap=val => val) => {
    if (behaviour.isLoadingList)
      return;
  
    setBehaviour({
      ...behaviour,
      isLoadingList: true,
      data: []
    });
    
    return request()
      .then((resp) => {
        setBehaviour({
          ...behaviour,
          isLoading: false,
          data: resp.data.data.map(middlewareMap)
        });
        return resp.data.data;
      })
      .catch((err) => {
        setBehaviour({
          ...behaviour,
          isLoading: false,
          data: [],
          error: err.toString()
        });
        throw err;
      });
  }

  const remove = (id, request) => {
    if (behaviour.isRemovingRow[id])
      return

    setBehaviour({
      ...behaviour,
      isRemovingRow: {
        ...behaviour.isRemovingRow,
        [id]: true
      }
    });

    return request()
      .then((resp) => {
        setBehaviour({
          ...behaviour,
          isRemovingRow: {
            ...behaviour.isRemovingRow,
            [id]: false
          },
          data: behaviour.data.filter(d => d[idField] !== id)
        });
        return resp.data.data;
      })
      .catch((err) => {
        setBehaviour({
          ...behaviour,
          isRemovingRow: {
            ...behaviour.isRemovingRow,
            [id]: false
          },
          error: err.toString()
        });
        console.log(err);
        throw err.error || err;
      });
  }

  const update = (id, request, middlewareUpdate) => {
    if(behaviour.isUpdatingRow[id])
      return;

    setBehaviour({
      ...behaviour,
      isUpdatingRow: {
        ...behaviour.isUpdatingRow,
        [id]: true
      }
    });

    return request()
      .then((resp) => {
        setBehaviour({
          ...behaviour,
          isUpdatingRow: {
            ...behaviour.isUpdatingRow,
            [id]: false
          },
          data: behaviour.data.map(row => {
            if(row[idField] !== id){
              return row;
            }

            return middlewareUpdate(resp.data.data)
          })
        });
        return resp.data.data;
      })
      .catch((err) => {
        setBehaviour({
          ...behaviour,
          isUpdatingRow: {
            ...behaviour.isUpdatingRow,
            [id]: false
          },
          error: err.toString()
        });
        throw err;
      });
  }

  
  const setData = (val) => {
    setBehaviour({ ...behaviour, data: val });
  }

  return {
    isLoadingList: behaviour.isLoadingList,
    isAddingRow: behaviour.isAddingRow,
    isLoadingRow: behaviour.isLoadingRow,
    isRemovingRow: behaviour.isRemovingRow,
    isUpdatingRow: behaviour.isUpdatingRow,
    data: behaviour.data,
    error: behaviour.error,
    setData,
    add,
    remove,
    list,
    update
  }
}