import React, { Component } from 'react';

import _ from 'lodash';
import Select from 'react-select';

import { connect } from 'react-redux';
import { Route, Link, withRouter } from "react-router-dom";

import { auth } from '../firebase';
import { actions as appActions } from '../state/app';
import { actions as sitesActions } from '../state/sites';

import logoActive from '../img/tooq-logo-new-green.png';

import { SignOutIcon, HomeIcon, ChartLineIcon, PerfomanceAltIcon, BugIcon } from '../components/icons';

class MainMenu extends Component {

  _toogleUserArrow() {
    this.setState({ userArrowToogle: !this.state.userArrowToogle });
  }


  _changeGroup = (val) => {
    if(this.props.currentGroup === val.value)
      return;

    this.props.setCurrentGroup(val.value);
    this.props.history.replace('/board')
  }


  render() {
    const { group, groupsSelect } = this.props;
    const {
      match,
      user,
      hasFoottrafficModules,
      hasSalesModules,
      canSeeGroupMenu,
      hasJohannModules
    } = this.props;
    const url = match.url;

    const { displayName, tooqAdmin } = user;

    return (<nav id="systemNavBar" className="navbar is-ligth is-fixed-top" style={{ zIndex: 11, boxShadow: '0px 0px 5px #363740' }}>
        <div className="navbar-brand">
          <Link className={`navbar-item`} to={`${match.url}`} style={{ fontSize: 22 }}>
            <figure className="image is-24x24" style={{ marginRight: '5px' }}>
              <img src={logoActive} alt="Logo" style={{ maxHeight: '24px' }} />
            </figure>
            {group.id === 11 ? `Smile` : `Jenny`}
          </Link>
          <label htmlFor="menu-toggle" className="navbar-burger" aria-label="menu" aria-expanded="false">
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </label>
        </div>
        <input type="checkbox" id="menu-toggle" className="is-hidden"/>
        <div className="navbar-menu">
          <div className="navbar-start">
            <Route
              exact
              path={`${url}`}
              children={({ match }) => <Link className={`navbar-item ${match ? 'is-menu-active' : ''}`} to={url}>
                <HomeIcon />
                <span>
                  Página inicial
                </span>
              </Link>}
            />
            {canSeeGroupMenu && group && hasFoottrafficModules && hasSalesModules && <Route
              exact
              path={`${url}/groups/${group.id}/dashboard/foottraffic/quadrante`}
              children={({ match }) => <Link className={`navbar-item ${match ? 'is-menu-active' : ''}`} to={`${url}/groups/${group.id}/dashboard/foottraffic/quadrante`}>
                <ChartLineIcon />
                <span>
                  Comparação de lojas
                </span>
              </Link>}
            />}

            {canSeeGroupMenu && group && hasFoottrafficModules && hasSalesModules && <Route
              exact
              path={`${url}/groups/${group.id}/dashboard/foottraffic/performance`}
              children={({ match }) => <div className="navbar-item has-dropdown is-hoverable">
                <Link className={`navbar-link ${match ? 'is-menu-active' : ''}`} to={`${url}/groups/${group.id}/dashboard/foottraffic/performance`}>
                <PerfomanceAltIcon />
                  <span>
                    Performance Lojas
                  </span>
                  <div className="navbar-dropdown">
                    <Link className="navbar-item" to={`${url}/groups/${group.id}/dashboard/foottraffic/performance/monthly-hourly`}>
                      Análise mês/hora
                    </Link>
                  </div> 
                </Link>
              </div>}
            />}
          </div>
          <div className="navbar-end">
            {tooqAdmin && <Route
              exact
              path={`${url}/groups/${group.id}/dashboard/foottraffic/anomaly`}
              children={({ match }) => <Link className={`navbar-item ${match ? 'is-menu-active' : ''}`} to={`${url}/groups/${group.id}/dashboard/foottraffic/anomaly`}>
                <BugIcon />
                <span>
                  Anomalias Lojas
                </span>
              </Link>}
            />}
            {groupsSelect.length > 1 && <span className="navbar-item" style={{ width: 200, marginRight: 20 }}>
              <Select
                className="width-100"
                defaultValue={{ value: group.id, label: group.name }}
                options={groupsSelect}
                onChange={this._changeGroup}
              />
            </span>}
            <span className="navbar-item is-black has-dropdown is-hoverable">
              <span className="navbar-link">
                <p>{displayName}</p>
              </span>
              <div className="navbar-dropdown is-right" style={{ zIndex: 9999 }} >
                <Link className="navbar-item" to={`${match.url}/me`}>
                  Meus Dados
                </Link>
                {!hasJohannModules && <Link className="navbar-item" to={`${match.url}/me/telegram`}>
                  Telegram
                </Link>}
                {tooqAdmin && <Link className="navbar-item" to={`${match.url}/me/admvarejos`}>Administração Tooq</Link>}
                <hr className="navbar-divider" />
                <Link className="navbar-item" onClick={() => { 
                  this.props.userLogout();  
                  auth.signOut();
                }} to="/">
                  <span>
                    Logout
                  </span>
                  <SignOutIcon />
                </Link>
              </div>
            </span>
          </div>
        </div>
      </nav>
    );
  }
}

function mapStateToProps(state) {
  const currentGroup = state.sites.currentGroup;
  const group = state.sites.groups[currentGroup];

  const groupsSelect = _.toArray({ ...state.sites.groups })
    .map(group => ({ value: group.id, label: group.name }));

  const canSeeGroupMenu =  (state.app.sitesPermissions && state.app.sitesPermissions.length > 0) && state
    .app
    .sitesPermissions
    .filter(permissions => permissions.permission !== 0)
    .length === 0;

  return {
    canSeeGroupMenu: canSeeGroupMenu || state.app.user.tooqAdmin,
    user: state.app.user,
    group,
    currentGroup,
    groupsSelect
  }
}

const { userLogout } = appActions;
const { setCurrentGroup } = sitesActions;
export default withRouter(connect(mapStateToProps, { userLogout, setCurrentGroup })(MainMenu));