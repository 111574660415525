import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';

const envJson = JSON.parse(process.env.REACT_APP_FIREBASE);

firebase.initializeApp(envJson);

const database = firebase.database();

let onlineListeners = [];
//return true if listener was regitered on cache;
export const registerOnlineListener = (on, ref) => {
  const index = onlineListeners.findIndex(o => o.on === on & o.ref === ref);
  if(index !== -1)
    return false;

  onlineListeners.push({ on: on, ref: ref });
  return true;
}

export const turnOffOnlineListener = (on, ref) => {
  database.ref(ref).off(on);
  onlineListeners = onlineListeners.filter(o => o.on !== on && o.ref !== ref);
}

export const turnOffListeners = () => {
  onlineListeners.forEach(listener => database.ref(listener.ref).off(listener.on));
  onlineListeners = [];
}

export const db = database;
export const auth = firebase.auth();
export const storage = firebase.storage();