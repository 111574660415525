 import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from './state';
import logger from 'redux-logger';
import promise from 'redux-promise';
import thunk from 'redux-thunk';

const middlewareHandler = () => {
  if (process.env.NODE_ENV === 'development') {
    return applyMiddleware(logger, thunk, promise)
  }
  return applyMiddleware(thunk, promise)
}

const finalCreateStore = compose(
  middlewareHandler()
)(createStore);

export default function configureStore(initialState) {
    return finalCreateStore(rootReducer, initialState);
};
