import React, { useEffect } from 'react';
import Card from '../../../../components/Card';
import Modal from '../../../../components/Modal';
import { useCrud } from '../../../../hooks/useCrud';
import { useField } from '../../../../hooks/useField';
import { get, post } from '../../../../utils';


const SkuTagsModal = props => {
  const { group } = props;
  const categories = useCrud();

  const tagDescriptionField = useField("", "Descricão da TAG");
  const tagColorField = useField("#222222", "Cor da tag");
  
  useEffect(() => {
    categories.list(
      () => get(`admin/videos/groups/${group.id}/shelf-category`)
    )
    // eslint-disable-next-line
  }, [group.id]);


  const newCategory = () => {
    if(tagDescriptionField.value.length === 0 || tagColorField.value.length === 0)
      return;

    categories.add(
      () => post(`admin/videos/groups/${group.id}/shelf-category`, {
        description: tagDescriptionField.value,
        colorHash: tagColorField.value
      })
    )
  }

  return <Modal
    handleToogleModal={props.closeModal}
    style={{ width: "90%", height: '95%' }}
    component={<Card style={{ height: '95%', overflow: 'auto' }}>
    <p className="title is-4">Cadastro de TAGs para relacionamento em prateleiras</p>
    <div className='field is-grouped'>
      <div class="control is-expanded">
        <input 
          {...tagDescriptionField}
          className="input"
          type="text"
        />
      </div>
      <div class="control">
        <input 
          {...tagColorField}
          className="input"
          type="color"
        />
      </div>
      <div class="control">
        <span class={`button is-primary ${categories.isAddingRow ? 'is-loading': ''}`} onClick={newCategory}>
          Adicionar
        </span>
      </div>
    </div>
    <table className="table is-fullwidth">
      <thead>
        <tr>
          <th>ID</th>
          <th>Descrição</th>
          <th>Cor</th>
        </tr>
      </thead>
      <tbody>
        {categories.isLoadingList && <tr>
          <td></td>
          <td>Aguarde...</td>
          <td></td>
        </tr>}
        {categories.data.map(category => <tr>
          <td>{category.id}</td>
          <td>{category.description}</td>
          <td style={{ backgroundColor: category.color_hash }}>{category.color_hash}</td>
        </tr>)}
      </tbody>
    </table>
  </Card>}/>
}

export default SkuTagsModal;