import React, {Component} from 'react';

import { connect } from 'react-redux';
import withRouter from "react-router-dom/withRouter";

import { get } from '../../../../utils';
import Columns, { Column } from '../../../../components/Columns';
import Page, { PageTitle } from '../../../../components/Page';
import UserCard from '../../../../components/board/user/UserCard';
import { UserPlusIcon, UsersIcon } from '../../../../components/icons';
import UserPermissionManager from './UserPermissionManager';

class UserPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      users: [],
      isLoading: true,
      isAddToogle: false
    };
  }

  componentDidMount() {
    this._loadUsers();
  }

  _toggleUserPermission() { 
    this.setState({ isAddToogle: !this.state.isAddToogle });
  }

  _loadUsers() {
    this.setState({ isLoading: true }, () => {
      get(`admin/tooq/users?siteId=${this.props.site.id}`)
        .then(resp => resp.data.data)
        .then(users => {
          this.setState({ isLoading: false, users })
        })
        .catch(err => {
          console.log(err);
        })
    })
  }

  _handlerRevoke = (user) => {
    this._reloadUsers(user, true);
  } 

  _reloadUsers = (user, isRemoving) => {
    const { site } = this.props;
    const { users } = this.state;

    if(isRemoving) {
      this.setState({
        users: users.filter(u => u.id !== user.id)
      });
      return;
    }

    const perm = user.sites.find(perm => perm.site_id === site.id) || { permission: 1 };
    const newUserInfo = {
      id: user.id,
      key: user.uid,
      email: user.email,
      displayName: user.displayName,
      permission: perm.permission
    }

    const u = users.find(u => u.id === user.id);
    if(u) {
      this.setState({
        users: users.map(u => u.id === user.id ? newUserInfo : u)
      });
      return;
    }

    this.setState({ users: [ ...users, newUserInfo ]});
  }

  render() {
    const { site, isFullPage=true } = this.props;
    const { users, isLoading } = this.state;

    const renderComponent = <Columns isMultiline>
      <Column style={{ marginTop: 10 }}>
        <div>
          <div className="tooltip">
            <button type="button" className="button is-primary" onClick={this._toggleUserPermission.bind(this)} >
              {!this.state.isAddToogle && <UserPlusIcon/>}
              <span>
                {this.state.isAddToogle ? 
                  'Cancelar' : 
                  'Adicionar'}
              </span>
            </button>
            {this.state.isAddToogle && <span className="tooltiptext down-right" style={{ backgroundColor: '#FFFFFF' }}>
              <div className="card notification new-user-card" style={{ maxWidth: '300px' }}>
                <UserPermissionManager onUserSetted={this._reloadUsers} site={site} alreadyadded={users} />
              </div> 
            </span>}
          </div>
        </div>
        {isLoading && <p className="subtitle is-5" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <span className="button is-loading is-white is-bordless is-large" ></span>
          <span>Aguarde carregando...</span>
        </p>}
        {!isLoading && users.length === 0 && <p className="subtitle is-5">Não há usuarios cadastrados.</p>}
        <Columns isMultiline style={{ marginTop: 10 }} >
          {users.sort((a,b) => a.permission - b.permission).map(user => <Column isSize={'is-narrow'} key={`users-card-${user.id}`}>
            <UserCard
              revokedCB={this._handlerRevoke}
              uid={user.uid}
              userInfo={user}
              canRevoke={true}
              currentUser={this.props.user.id === user.id}
              site={site}
            />
          </Column>)}
        </Columns>
      </Column>
    </Columns>

    if(!isFullPage)
      return renderComponent



    return (<Page>
      <PageTitle
        title={<span>
            <span style={{ marginRight: '10px' }}>
              {`${(site && site.name) || ''} `}
            </span>
            <p className="subtitle">Usuários <UsersIcon /></p>
          </span>}
      />
      {renderComponent}
    </Page>)
  }
}

function mapStateToProps(state, props) {
  const site = state.sites.all[props.siteId] || {};
  return {
    site,
    user: state.app.user
  }
}

export default withRouter(connect(mapStateToProps, {})(UserPage));