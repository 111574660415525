import React, { Component } from 'react';

import { format } from 'date-fns';
import {pt} from 'date-fns/locale';
import { post } from '../../../../../utils';

const AUDIT_DEFAULT = { id: 0, description: "Selecione"};


class ModalNewAuditReid extends Component {

  constructor(props) {
    super(props);
    this.state = {
      audit: AUDIT_DEFAULT,
      description: '',
      audits: [],
      isSaving: false,
      flowAnalysis: false
    }
  }
  
  _handleCancelButton = () => {
    this.props.toogleModal();
  }

  _checkFlowAnalysis = (e) => {
    const { checked } = e.target;
    this.setState({ flowAnalysis: checked });
  }

  _changeAudit = (e) => {
    const { hour } = this.props;
    const { value } = e.target;
    const audit = hour.audits.find(cam => cam.id === parseInt(value)) || AUDIT_DEFAULT

    this.setState({ audit });
  }

  _changeDescription = (e) => {
    const { value } = e.target;
    this.setState({ description: value });
  }

  _includeAudit = () => {
    const { audit, audits } = this.state;
    if (audit.id === 0 || audits.find(aud => aud.id === audit.id)) {
      return;
    }

    this.setState({
      audits: [
        audit,
        ...audits
      ]
    });
  }

  _submitForm = (e) => {
    e.preventDefault();
    const { site, day, hour } = this.props;
    const { audits, description} = this.state;
    if (audits.length === 0) {
      return;
    }

    if(this.state.isSaving){
      return;
    }
    
    this.setState({ isSaving: true }, () => {
      post(`dashboard/video/sites/${site.id}/auditReid`, {
        description,
        audits: audits.map(audit => audit.id),
        day: day.snap_date,
        hour: hour.hour
      })
      .then(resp => {
        this._handleCancelButton();
      });
    })
  }

  render() {
    const { day, hour } = this.props;
    const { audit, description, audits, isSaving } = this.state;

    return <div className="modal is-active">
    <div className="modal-background" onClick={this._handleCancelButton}></div>
    <div className="modal-content is-paddingless " style={{ width: 500 }}>
      <div className="card notification calendarNotDisplay-left-bar">
        <p className="subtitle is-4 has-text-centered">
          <span>
            Reidentificação de pessoas
          </span>
        </p>
        <p className="subtitle is-5 has-text-left">
          {format(day.snap_date, 'DD MMMM', { locale: pt })} às {hour.hour}
        </p>
        <form onSubmit={this._submitForm}>
          <label className="label" htmlFor="input-cameras-list">Auditorias:</label>
          <div className="field has-addons">
            <div className="control is-expanded">
              <div className="select is-fullwidth">
                <select value={audit.id} onChange={this._changeAudit} id="input-cameras-list" name="camera">
                  <option value="0">Selecione...</option>
                  {hour.audits.filter(foo => foo.flow_analysis === 1).map(audit => <option key={audit.id} value={audit.id}>
                    {audit.description} - {audit.cameras.map(camera => `${camera.id} - ${camera.description}`).join(' ')}
                  </option>)}
                </select>
              </div>
            </div>
            <div className="control">
              <button type="button" className="button is-primary" onClick={this._includeAudit}>Adicionar</button>
            </div>
          </div>
         
          {audits.length === 0 && <p className="subtitle is-5">Sem cameras incluídas</p>}
          {audits.length !== 0 && <table className="table is-striped is-hoverable" style={{ width: '100%' }}>
            <thead>
              <tr>
                <th>ID</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              {audits.map(audit => <tr key={`camera_id_list_added_${audit.id}`}>
                <td>
                  <div className="audit-table-detail-cel-text">
                    {audit.id}
                  </div>
                  </td>
                <td>
                  <span className="audit-table-detail-cel-number">
                    {audit.description} - {audit.cameras.map(camera => `${camera.id} - ${camera.description}`).join(' ')}
                  </span>
                </td>
              </tr>)}
            </tbody>
          </table>}
          <label className="label" htmlFor="input-audit-observation">Observação</label>
          <div className="field">
            <div className="control">
              <input
                id="input-audit-observation"
                className="input"
                type="text"
                placeholder="Ex: contarei só blusas branca"
                value={description}
                onChange={this._changeDescription}
              />
            </div>
          </div>
          <div className="buttons">
            <button
              type="submit"
              className={`button is-primary ${isSaving ? 'is-loading': ''}`}
            >
              Salvar
            </button>
            <span
              className={`button`}
              onClick={this._handleCancelButton}
            >
              Cancelar
            </span>
            
          </div>
        </form>
      </div>
    </div>
    <button className="modal-close is-large" onClick={this._handleCancelButton} aria-label="close"></button>
  </div>
  }
}

export default ModalNewAuditReid