import React, { Component } from 'react';
import {
  addDays,
} from 'date-fns';
import AvailableSensorChart from './AvailableSensorChart';
import { getServerDate } from '../../../../../utils';

class AvailableSensor extends Component{

  constructor(props) {
    super(props);
    const CURRDATE = addDays(getServerDate(), -1);
    const filterRanges = {
      startDate: addDays(CURRDATE, -6),
      endDate: CURRDATE,
      key: 'selectionRange1'
    }
    this.state = {filterRanges};
  }

  _showMore = () => {
    const { filterRanges } = this.state;
    const { shopId } = this.props;
    this.props.showMore(
      <AvailableSensorChart shopId={shopId} filterRanges={filterRanges} /> 
    );
  }

  render(){
    return(
      <div className="columns is-mobile">
        <div className="column is-2 is-offset-10">
          <a href className="button is-fullwidth is-light is-small" onClick={this._showMore}>
            <span className="icon is-small">
              <i className="fas fa-satellite-dish"></i>
            </span>
            <span> Disponibilidade Sensores </span>
          </a>
        </div>
      </div>
    )
  }
}
export default AvailableSensor;