import React, { Component } from 'react';

import { connect } from 'react-redux';

import {
  Link,
  Route,
  withRouter
} from "react-router-dom";

import * as Icons from './icons';

class MainAsideMenuVideo extends Component {

  render() {
    const { match, site } = this.props;
    const { url } = match;

    return (<aside className={`menu aside-menu is-aside-toogle`}>
      <div className="menu-aside-header" style={{ minHeight: 100 }}>
        <p className="subtitle is-5 child-to-show">
          {site.name}
        </p>
      </div>
      <p className="menu-label">
        Dashboard
      </p>
      <ul className="menu-list" style={{ position: 'static' }}>
        <Route
          path={`${url}/home`}
          children={({ match }) => <li className={`${match ? 'is-aside-selected' : '' }`}>
            <Link to={`${url}/home`}>
              <Icons.HomeIcon className="is-mini is-gray"/>
              <span className="child-to-show">Página Inicial</span>
            </Link>
          </li>}
        />
        {site.id !== 23 && <Route
          path={`${url}/sales`}
          children={({ match }) => <li className={`${match ? 'is-aside-selected' : '' }`}>
            <Link to={`${url}/sales`}>
              <Icons.SalesIcon className="is-mini is-gray"/>
              <span className="child-to-show">Vendas</span>
            </Link>
          </li>}
        />}
        {/* <Route
          path={`${url}/centauro`}
          children={({ match }) => <li className={`${match ? 'is-aside-selected' : '' }`}>
            <Link to={`${url}/centauro`}>
              <Icons.SalesIcon className="is-mini is-gray"/>
              <span className="child-to-show">Centauro</span>
            </Link>
          </li>}
        /> */}
        {site.id !== 23 && <Route
          path={`${url}/realtime`}
          children={({ match }) => <li className={`${match ? 'is-aside-selected' : '' }`}>
            <Link to={`${url}/realtime`}>
              <Icons.PlayCircle className="is-mini is-gray"/>
              <span className="child-to-show">Tempo Real</span>
            </Link>
          </li>}
        />}
        {site.id !== 23 && <Route
          path={`${url}/relatorios`}
          children={({ match }) => <li className={`${match ? 'is-aside-selected' : '' }`}>
            <Link to={`${url}/relatorios`}>
              <Icons.FilePDFIcon />
              <span className="child-to-show">Relatórios</span>
            </Link>
          </li>}
        />}
      </ul>

      <div className="menu-label">
        Administrativo
      </div>
      <ul className="menu-list" style={{ position: 'static' }}>
        {site.id !== 23 && <Route
          path={`${url}/manualcount`}
          children={({ match }) => <li className={`${match ? 'is-aside-selected' : '' }`}>
            <Link to={`${url}/manualcount`}>
              <Icons.IdBadgeIcon className="is-mini is-gray"/>
              <span className="child-to-show">Contagem manual</span>
            </Link>
          </li>}
        />}
        <Route
          path={`${url}/settings`}
          children={({ match }) => <li className={`${match ? 'is-aside-selected' : '' }`}>
            <Link to={`${url}/settings`}>
              <Icons.CogIcon className="is-mini is-gray"/>
              <span className="child-to-show">Configurações</span>
            </Link>
          </li>}
        />
      </ul>

    </aside>);
  }
}

function mapStateToProps(state, props) {
  const { match } = props;
  const site = state.sites.all[match.params.siteId] || {};
  return { site };
}

export default withRouter(connect(mapStateToProps)(MainAsideMenuVideo));
