import React, { Component } from 'react';

import {
  ResponsiveContainer,
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  Label,
  ReferenceArea,
  ReferenceLine,
  Tooltip
} from 'recharts';

class CustomShape extends Component {

  constructor(props) {
    super(props);

    const { sitesSelected } = props;

    const isSelected = sitesSelected
      .filter(shop => shop === props.siteId)
      .length !== 0;

    this.state = {
      isSelected: isSelected
    }
  }

  _onMouseLeave = () => {
    this.setState({ isOver: false });
  }

  _onMouseEnter = () => {
    this.setState({ isOver: true });
  }

  _onClick = (id) => () => this.props.handleClickShop(id)

  render() {
    const {
      isOver,
      isSelected
    } = this.state;

    const {
      x,
      y,
      height,
      name,
      siteId,
      sitesSlots
    } = this.props;

    if(isOver || isSelected) {
      const primaryColor = isSelected 
        ? (sitesSlots[1] === siteId  ? '#00d1b2' : '#0043ff')

        : sitesSlots[1] ? '#0043ff' : '#00d1b2';

      return <g
        id={siteId}
        cursor="pointer"
        onMouseLeave={this._onMouseLeave}
        onMouseEnter={this._onMouseEnter}
        onClick={this._onClick(siteId)}
      >
        <rect x={x-10} y={y-10} width={20 + (name.length * 8)} height="28" rx={10} stroke={primaryColor} strokeWidth={3} fill={'#ffffff'} />
        <circle cx={x+4} cy={y+4} r="10" fill={primaryColor}></circle>
        <text fontWeight={'bold'} x={x+18} y={y+height} fontSize={14} fill="#222" stroke="none">{name}</text>
      </g>
    }

    return <g
      id={siteId}
      cursor="pointer"
      onMouseLeave={this._onMouseLeave}
      onMouseEnter={this._onMouseEnter}
      onClick={this._onClick(siteId)}
    >
      <rect x={x-10} y={y-10} width={20 + (name.length * 8)} height="28" rx={10} stroke={'#eee'} strokeWidth={1} fill="#FFFFFF" />
      <circle cx={x+4} cy={y+4} r="10" fill="#00d1b2"></circle>
      <text x={x+18} y={y+height} fontSize={14} fill="#222" stroke="none">{name}</text>
    </g>
  }
}

class TooqQuadrant extends Component {

  render() {
    const { data, quadrantSuggestion, sitesSlots, sitesSelected, handleClickShop } = this.props;

    const {
      maxSales,
      maxVisitors,
      minSales,
      minVisitors,
      meanSales,
      meanVisitors
    } = quadrantSuggestion;

    return <ResponsiveContainer width="100%" height={450}>
      <ScatterChart
        margin={{top: 0, right: 130, left: 0, bottom: 30}}>
        <ReferenceArea isFront={true} fillOpacity={1} fill="#fff" x1={minVisitors} x2={meanVisitors} y1={minSales} y2={meanSales}>
          <Label offset={35} position="insideTop" value="ABAIXO DA MÉDIA" />
        </ReferenceArea>
        <ReferenceArea isFront={true} fillOpacity={1} fill="#fff" x1={minVisitors} x2={meanVisitors} y1={meanSales} y2={maxSales} >
          <Label offset={35} position="insideTop" value="CONVERSORES" />
        </ReferenceArea>
        <ReferenceArea isFront={true} fillOpacity={1} fill="#fff" x1={meanVisitors} x2={maxVisitors} y1={minSales} y2={meanSales} >
          <Label offset={35} position="insideTop" value="MOVIMENTADORES" />
        </ReferenceArea>
        <ReferenceArea isFront={true} fillOpacity={1} fill="#fff" x1={meanVisitors} x2={maxVisitors} y1={meanSales} y2={maxSales} >
          <Label offset={35} position="insideTop" value="ALTA PERFORMANCE" />
        </ReferenceArea>
        <ReferenceLine
          y={meanSales}
        />
        <ReferenceLine
          x={meanVisitors}
        />
        <XAxis
          dataKey={'visitors'}
          tickLine={false}
          axisLine={false}
          domain={[minVisitors, maxVisitors]}
          type="number"
          tickMargin={0}
          label={{ value: 'FLUXO VISITANTES', fontSize: 18, position: 'bottom', offset: 0 }}
          name='Fluxo de visitantes'
        />
        <YAxis
          width={120}
          dataKey={'salesRate'}
          tickLine={false}
          axisLine={false}
          label={{ value: 'CONVERSÃO EM VENDAS', angle: -90, position: 'center', fontSize: 18, offset: 20 }}
          domain={[minSales, maxSales]}
          type="number"
          name='Conversão em vendas'
          unit='%'
        />
        <Scatter
          data={data}
          shape={<CustomShape
            sitesSlots={sitesSlots}
            handleClickShop={handleClickShop}
            sitesSelected={sitesSelected}
          />}
        />
        <Tooltip cursor={{ strokeDasharray: '3 3' }} />
      </ScatterChart>
    </ResponsiveContainer>
  }
}

export default TooqQuadrant;