import React, {Component} from 'react';

import _ from 'lodash';
import {connect} from 'react-redux';

import {get, post, handleForInputChangeOnForm} from '../../../../utils';
import { UsersIcon } from '../../../../components/icons';
import notification from '../../../../utils/notification';

class UserPermissionManager extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        permission: 1,
        displayName: '',
        password: ''
      },
      emailSearch: '',
      userFound: null,
      erroSaving: false
    }
  }

  componentDidMount() {
    this._focusOnPermission();
  }

  _focusOnPermission() {
    this.permission.focus();
  }

  _focusOnEmailSearch() {
    this.emailSearch.focus();
  }

  _searchForEmail = _.debounce(() => {
    const { emailSearch } = this.state;
    
    if(emailSearch.length === 0)
      return;

    this.setState({ userFound: null, searchingEmail: true }, () => {
      get(`admin/tooq/users?email=${emailSearch}`)
        .then(resp => resp.data.data)
        .then(user => {
          this.setState({ userFound: user, searchingEmail: false });
        });
    });
  }, 300);

  _handleEmailSearch(event) {
    this.setState({ emailSearch: event.target.value }, () => this._searchForEmail());
  } 

  _handleSubmit(event) {
    event.preventDefault();
    const { formData, searchingEmail, emailSearch, userFound } = this.state;
    const { site, user } = this.props;

    const userPermissionsOnGroup = userFound ? userFound.groups.find(group => group.site_group_id === site.group.id) || {} : {};
    const canSave = !(userPermissionsOnGroup.permission === 0 || (userFound && userFound.uid === user.key));

    if(!canSave || searchingEmail)
      return;

    this.setState({ saving: true });

    const newUserData = {};
    newUserData['name'] = formData.displayName;
    newUserData['email'] = emailSearch;
    newUserData['password'] = formData.password;
    newUserData['permission'] = this.state.formData.permission;

    post(`admin/tooq/users`, {
      user: {
        displayName: newUserData.name,
        email: newUserData.email,
        password: newUserData.password
      },
      definitions: {
        siteId: site.id,
        sitePermission: newUserData.permission
      }
    }).then(val => {
      notification.success('Dados salvos com sucesso!')
      this.setState({ 
        formData: {
          permission: 1,
          displayName: '',
          password: ''
        },
        emailSearch: '',
        userFound: null,
        erroSaving: false,
        saving: false
      }, () => {
        this.props.onUserSetted(val.data.data)
        this._focusOnPermission();
      });
    });
  }

  render() {
    const { permission, displayName, password } = this.state.formData;
    const { searchingEmail, emailSearch, userFound } = this.state;
    const { user, site } = this.props;

    const userPermissionsOnGroup = userFound ? userFound.groups.find(group => group.site_group_id === site.group.id) || {} : {};
    const canSave = !(userPermissionsOnGroup.permission === 0 || (userFound && userFound.uid === user.key));

    return (<div className="columns is-gapless is-multiline" style={{ padding: '5px', marginBottom: '4px' }}>
        <div className="column is-12" style={{ padding: '10px' }}>
          <form onSubmit={this._handleSubmit.bind(this)}>
            <div className="field">
              <div className="control has-icons-left is-expanded">
                <span className="select is-fullwidth">
                  <select id="permission"
                    onChange={handleForInputChangeOnForm.bind(this)}
                    ref={(ref) => this.permission = ref}
                    value={permission}>
                    <option value={3}>Auditor</option>
                    <option value={1}>Analista</option>
                    <option value={0}>Administrador</option>
                  </select>
                </span>
                <UsersIcon className="is-small is-left" />
              </div>
            </div> 
            <div className="field">
              <div className={`control ${searchingEmail && 'is-loading'}`}>
                <input className={`input ${userFound && 'is-info'}`} type="email" placeholder="Email para Login"
                  onChange={this._handleEmailSearch.bind(this)}
                  ref={(ref) => this.emailSearch = ref}
                  value={emailSearch}
                  required/>
              </div>
            </div> 
            <div className="field">
              <div className="control">
                <input id="displayName" className={`input ${userFound && 'is-info'}`} type="text" placeholder="Nome de usuário"
                  onChange={handleForInputChangeOnForm.bind(this)}
                  ref={(ref) => this.displayName = ref}
                  value={userFound ? userFound.displayName : displayName}
                  disabled={userFound}
                  required
                />
              </div>
            </div>
            {userFound && <p className="help is-info">
                {(userFound && userFound.uid === user.uid)
                  ? 'Este usuário é você mesmo, não pode alterar suas proprias permissões.'
                  : 'Esse usuários já existe no sistema, salve para confirmar seu novo nível de permissão na loja.' }
              </p>}
            {!userFound && <div className="field">
              <div className="control">
                <input id="password" className="input" type="password" placeholder="Senha para login"
                  onChange={handleForInputChangeOnForm.bind(this)}
                  ref={(ref) => this.password = ref}
                  value={password}
                  required
                />
              </div>
            </div>}
            {this.state.erroSaving && <p className="help is-danger">
                Não foi possível salvar o novo usuário, verifique o endereço de email ou senha e tente novamente.
            </p>}
            {canSave && <div className="field is-grouped">
              <div className="control">
                <button
                  type="submit"
                  className={`button is-primary ${this.state.saving && 'is-loading'}`}
                  disabled={searchingEmail}
                >
                  Salvar
                </button>
              </div>
            </div>}
          </form>
        </div> 
      </div>);
  }
}

function mapStateToProps(state) {
  return { user: state.app.user }
}

export default connect(mapStateToProps, { })(UserPermissionManager);