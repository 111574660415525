import React, { Component } from 'react';

import * as Icons from '../../../../../components/icons';

import { get, post, del } from '../../../../../utils';
import notification from '../../../../../utils/notification';

class DeviceCamera extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoadingCameras: false,
      isLoadingCamerasDevice: false,
      selectedCamera: 0,
      camerasDevice: [],
      cameras: [],
      includingCamera: false,
      removingCamera: {},
    };
  }

  componentDidMount() {
    this._loadAllCameras();
    this._loadDeviceCameras();
  }

  _loadDeviceCameras = () => {
    const { site, device } = this.props;
    
    this.setState({ isLoadingCamerasDevice: true }, () => {
      get(`admin/videos/sites/${site.id}/devices/${device.id}/cameras`)
        .then(resp => {
          const camerasDevice = resp.data.data;
          this.setState({ isLoadingCamerasDevice: false, camerasDevice })
        });
    })
  }

  _loadAllCameras = () => {
    const { site } = this.props;
    this.setState({ isLoadingCameras: true }, () => {
      get(`admin/videos/sites/${site.id}/cameras`)
        .then(resp => {
          const cameras = resp.data.data;
          this.setState({ isLoadingCameras: false, cameras });
        });
    })
  }

  _onChangeCamera = (e) => {
    e.persist();
    const { value } = e.target;
    this.setState({ selectedCamera: parseInt(value, 10) });
  }

  _removeCameraFromDevice = (cameraId) => () => {
    const { removingCamera } = this.state;

    if(removingCamera[cameraId])
      return;


    removingCamera[cameraId] = true;

    const { site, device } = this.props;

    this.setState({ removingCamera }, () => {
      del(`admin/videos/sites/${site.id}/devices/${device.id}/cameras/${cameraId}`)
        .then(resp => {
          notification.success('Removido com sucesso!')
          const { removingCamera, camerasDevice } = this.state;
          delete removingCamera[cameraId];
          this.setState({
            removingCamera,
            camerasDevice: camerasDevice.filter(camera => camera.id !== cameraId)
          });
        })
      });
  }

  _addCameraToDevice = () => {
    const { includingCamera, camerasDevice, selectedCamera } = this.state;

    if(includingCamera)
      return;

    this.setState({ includingCamera: true }, () => {

      const isCameraInDevice = camerasDevice.find(camera => camera.id === selectedCamera);
      if(!!isCameraInDevice) {
        this.setState({ includingCamera: false });
        return;
      }

      const { site, device } = this.props;
      post(`admin/videos/sites/${site.id}/devices/${device.id}/cameras`, { cameraId: selectedCamera })
        .then(resp => {
          notification.success('Dados salvos com sucesso!')
          const { camerasDevice } = this.state;
          this.setState({ includingCamera: false, camerasDevice: [ ...camerasDevice, resp.data.data ] });
        })
    })
  }

  render() {
    const { device } = this.props;
    const {
      cameras,
      selectedCamera,
      camerasDevice,
      isLoadingCameras,
      isLoadingCamerasDevice,
      includingCamera,
      removingCamera
    } = this.state;

    return (<div className="notification card">
      <p className="title is-5"># {device.deviceHash}</p>
      <p className="subtitle is-5">{device.description}</p>
      <div className="field has-addons">
        <div className="control is-expanded">
          <div className={`select is-fullwidth`}>
            <select value={selectedCamera} onChange={this._onChangeCamera}>
              {!cameras.length && !isLoadingCameras && <option value={0}>Não há cameras cadastradas</option>}
              {cameras.length && <option value={0}>Selecione uma camera e relacione</option>}
              {cameras.map(camera => <option key={`camera-device-${camera.id}`} value={camera.id}>
                {camera.description}
              </option>)}
            </select>
          </div>
        </div>
        <div className="control">
          <div className={`button is-primary ${includingCamera ? 'is-loading' : ''}`} onClick={this._addCameraToDevice}>Incluir</div>
        </div>
      </div>
      <table className="table" style={{ width: '100%' }}>
        <thead>
          <tr>
            <th>Descrição</th>
            <th>Url</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {camerasDevice.map(camera => <tr key={`cameras-in-device-${camera.id}`}>
            <th>{camera.description}</th>
            <th>{camera.url}</th>
            <th> 
              <span className={`button is-danger is-outlined is-small ${removingCamera[camera.id] ? 'is-loading' : ''}`} onClick={this._removeCameraFromDevice(camera.id)}>
                <Icons.TrashIcon className="is-small"/>
                <span>Remover do device</span>
              </span>
            </th>
          </tr>)}
        </tbody>
      </table>
      {isLoadingCamerasDevice && <div style={{ display: 'flex', justifyContent: 'center' }}>
        <p className="button is-loading is-bordless"/> <p>Aguarde carregando</p>
      </div>}
    </div>);
  }
}

export default (DeviceCamera);