import React from 'react';

import {pt as ptBR} from 'react-date-range/src/locale';
import Columns, { Column } from '../../../../components/Columns';
import { CalendarAltIcon } from '../../../../components/icons';
import { DateRangePicker } from 'react-date-range';

import '../../../../styles/Calendar.css';

import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  isSameDay,
  subDays,
  getISODay,
  format,
  isValid,
  parse,
  isBefore
} from 'date-fns';
import { getServerDate } from '../../../../utils';

const currentDate = getServerDate()

const defineds = {
  startOfWeek: startOfWeek(currentDate),
  endOfWeek: endOfWeek(currentDate),
  startOfLastWeekOnMonday: startOfWeek(addDays(currentDate, -7), { weekStartsOn: 1 }),
  endOfLastWeekOnMonday: endOfWeek(addDays(currentDate, -7), { weekStartsOn: 1 }),
  startOfLastWeek: startOfWeek(addDays(currentDate, -7)),
  endOfLastWeek: endOfWeek(addDays(currentDate, -7)),
  startOfToday: startOfDay(currentDate),
  endOfToday: endOfDay(currentDate),
  startOfYesterday: startOfDay(addDays(currentDate, -1)),
  endOfYesterday: endOfDay(addDays(currentDate, -1)),
  startOfMonth: startOfMonth(currentDate),
  endOfMonth: endOfMonth(currentDate),
  startOfLastMonth: startOfMonth(addMonths(currentDate, -1)),
  endOfLastMonth: endOfMonth(addMonths(currentDate, -1)),
  yesterday: subDays(currentDate, 1),
  startOfLastWeekend: subDays(addDays(currentDate, 5 - getISODay(currentDate)), 7),
  endOfLastWeekend: addDays(subDays(addDays(currentDate, 5 - getISODay(currentDate)), 7), 2)
};

const staticRangeHandler = {
  range: {},
  isSelected(range) {
    const definedRange = this.range();
    return (
      isSameDay(range.startDate, definedRange.startDate) &&
      isSameDay(range.endDate, definedRange.endDate)
    );
  },
};

export function createStaticRanges(ranges) {
  const result = ranges.map(range => ({ ...staticRangeHandler, ...range }));
  return result;
}

export const noneStaticRanges = createStaticRanges([]);

const defaultStaticRanges = createStaticRanges([
  {
    label: 'Últimos 7 dias',
    range: () => ({
      startDate: addDays(defineds.yesterday, - 6),
      endDate: defineds.yesterday,
      range: {
        startDate: addDays(defineds.yesterday, - 6),
        endDate: defineds.yesterday,
      }
    }),
  },
  {
    label: 'Últimos 15 dias',
    range: () => ({
      startDate: addDays(defineds.yesterday, - 14),
      endDate: defineds.yesterday,
      range: {
        startDate: addDays(defineds.yesterday, - 14),
        endDate: defineds.yesterday,
      }
    }),
  },
  {
    label: 'Últimos 30 dias',
    range: () => ({
      startDate: addDays(defineds.yesterday, - 29),
      endDate: defineds.yesterday,
      range: {
        startDate: addDays(defineds.yesterday, - 29),
        endDate: defineds.yesterday,
      }
    }),
  },
  {
    label: 'Último fim de semana',
    range: () => ({
      startDate: defineds.startOfLastWeekend,
      endDate: defineds.endOfLastWeekend,
      range: {
        startDate: defineds.startOfLastWeekend,
        endDate: defineds.endOfLastWeekend,
      }
    }),
  },
  {
    label: 'Última semana (ini. SEGUNDA)',
    range: () => ({
      startDate: defineds.startOfLastWeekOnMonday,
      endDate: defineds.endOfLastWeekOnMonday,
      range: {
        startDate: defineds.startOfLastWeekOnMonday,
        endDate: defineds.endOfLastWeekOnMonday,
      }
    }),
  },
  {
    label: 'Última semana (ini. DOMINGO)',
    range: () => ({
      startDate: defineds.startOfLastWeek,
      endDate: defineds.endOfLastWeek,
      range1: {
        startDate: defineds.startOfLastWeek,
        endDate: defineds.endOfLastWeek,
      },
      range2: {
        startDate: subDays(defineds.startOfLastWeek, 7),
        endDate: subDays(defineds.endOfLastWeek, 7),
      }
    }),
  },
  {
    label: 'Último mês',
    range: () => ({
      startDate: defineds.startOfLastMonth,
      endDate: defineds.endOfLastMonth,
      range: {
        startDate: defineds.startOfLastMonth,
        endDate: defineds.endOfLastMonth,
      }
    }),
  }
]);

const defaultInputRanges = [];


class CalendarDoubleRangePicker extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      range1: {
        startDate: props.range1.startDate,
        endDate: props.range1.endDate 
      },
      startDateField1: format(props.range1.startDate, 'DD/MM/YY'),
      endDateField1: format(props.range1.endDate, 'DD/MM/YY'),
      range2: {
        startDate: props.range2.startDate,
        endDate: props.range2.endDate 
      },
      startDateField2: format(props.range2.startDate, 'DD/MM/YY'),
      endDateField2: format(props.range2.endDate, 'DD/MM/YY')
    }
  }

  _handleChange = (d) => {
    if(d.range1) {
      const { startDate, endDate } = d.range1;
      this.setState({
        range1: {
          startDate: startDate,
          endDate: endDate 
        },
        startDateField1: format(startDate, 'DD/MM/YY'),
        endDateField1: format(endDate, 'DD/MM/YY')
      });
      return;
    }

    if(d.range2) {
      const { startDate, endDate } = d.range2;
      this.setState({
        range2: {
          startDate: startDate,
          endDate: endDate 
        },
        startDateField2: format(startDate, 'DD/MM/YY'),
        endDateField2: format(endDate, 'DD/MM/YY')
      });
      return;
    }

  }

  _changeInputField = (field) => (e) => {
    const { value } = e.target;
    const { startDateField1, endDateField1, startDateField2, endDateField2 } = this.state;
    const fields = { startDateField1, endDateField1, startDateField2, endDateField2 };
    fields[field] = value;
    this.setState({ ...fields }, () => {
      const { startDateField1, endDateField1, startDateField2, endDateField2 } = this.state;
      const brasilStartDateField = parse(startDateField1, 'DD/MM/YY', getServerDate());
      const brasilEndDateField = parse(endDateField1, 'DD/MM/YY', getServerDate());
      const isStartDateValid = isValid(brasilStartDateField);
      const isEndDateValid = isValid(brasilEndDateField);
      const endHigherThanBegin = isBefore(brasilStartDateField, brasilEndDateField)


      const brasilStartDateField2 = parse(startDateField2, 'DD/MM/YY', getServerDate());
      const brasilEndDateField2 = parse(endDateField2, 'DD/MM/YY', getServerDate());
      const isStartDateValid2 = isValid(brasilStartDateField2);
      const isEndDateValid2 = isValid(brasilEndDateField2);
      const endHigherThanBegin2 = isBefore(brasilStartDateField2, brasilEndDateField2)

      if((field === 'startDateField1' || field === 'endDateField1')
        && isStartDateValid && isEndDateValid && endHigherThanBegin) {
        this.setState({
          range1: {
            startDate: brasilStartDateField,
            endDate: brasilEndDateField
          }
        });
        return;
      }

      if((field === 'startDateField2' || field === 'endDateField2')
        && isStartDateValid2 && isEndDateValid2 && endHigherThanBegin2) {
        this.setState({
          range2: {
            startDate: brasilStartDateField2,
            endDate: brasilEndDateField2
          }
        });
        return;
      }
    });
  }

  _handleConfirm = (canConfirm) => () => {
    if(!canConfirm) {
      return;
    }

    const { range1, range2 } = this.state;
    this.props.onChange({range1, range2});
  }

  render() {
    const { colors=['#0043ff', '#00d1b2'] } = this.props;
    const { range1, range2 } = this.state; 
    const ranges = [{ ...range1, key: 'range1' }, { ...range2, key: 'range2' }];
    const { startDateField1, endDateField1, startDateField2, endDateField2 } = this.state;

    const brasilStartDateFieldFormatRange1 = parse(startDateField1, 'DD/MM/YY', getServerDate())
    const brasilEndDateFieldFormatRange1 = parse(endDateField1, 'DD/MM/YY', getServerDate())

    const endHigherThanBeginRange1 = !isBefore(brasilEndDateFieldFormatRange1, brasilStartDateFieldFormatRange1)
    const isStartBeforeTodayRange1 = isBefore(brasilStartDateFieldFormatRange1, format(getServerDate(), 'YYYY-MM-DD'));
    const isEndBeforeTodayRange1 = isBefore(brasilEndDateFieldFormatRange1, format(getServerDate(), 'YYYY-MM-DD'));
    const isBeforeTodayRange1 = isStartBeforeTodayRange1 && isStartBeforeTodayRange1
    const startDateIsValidRange1 = isStartBeforeTodayRange1 && endHigherThanBeginRange1 && isValid(brasilStartDateFieldFormatRange1)
    const endDateIsValidRange1 = isEndBeforeTodayRange1 && endHigherThanBeginRange1 && isValid(brasilEndDateFieldFormatRange1)

    const brasilStartDateFieldFormatRange2 = parse(startDateField2, 'DD/MM/YY', getServerDate())
    const brasilEndDateFieldFormatRange2 = parse(endDateField2, 'DD/MM/YY', getServerDate())
    const endHigherThanBeginRange2 = !isBefore(brasilEndDateFieldFormatRange2, brasilStartDateFieldFormatRange2)
    const isStartBeforeTodayRange2 = isBefore(brasilStartDateFieldFormatRange2, format(getServerDate(), 'YYYY-MM-DD'));
    const isEndBeforeTodayRange2 = isBefore(brasilEndDateFieldFormatRange2, format(getServerDate(), 'YYYY-MM-DD'));
    const isBeforeTodayRange2 = isStartBeforeTodayRange2 && isEndBeforeTodayRange2
    const startDateIsValidRange2 = isStartBeforeTodayRange2 && endHigherThanBeginRange2 && isValid(brasilStartDateFieldFormatRange2)
    const endDateIsValidRange2 = isEndBeforeTodayRange2 && endHigherThanBeginRange2 && isValid(brasilEndDateFieldFormatRange2)
    

    const canConfirm = startDateIsValidRange1 &&
      endDateIsValidRange1 &&
      endHigherThanBeginRange1 &&
      isBeforeTodayRange1 &&
      startDateIsValidRange2 &&
      endDateIsValidRange2 &&
      endHigherThanBeginRange2 &&
      isBeforeTodayRange2;

    return <div className="card notification dontShowDefinedRanges calendarTooltip">
      <Columns style={{marginTop: 0, marginBottom: 20, justifyContent: 'center'}}>
        <Column>
          <Columns style={{marginTop: 0, marginBottom: 0, justifyContent: 'center'}}>
            <Column>
              <label className="label has-text-centered">Data Inicio</label>
              <div className="field has-addons has-addons-right">
                <div className="control is-expanded has-icons-left">
                  <input
                    style={{ maxWidth: 150, borderColor:  endDateIsValidRange1 && colors[0] }}
                    className={`input ${startDateIsValidRange1 ? '' : 'is-danger'}`}
                    placeholder="Ex: 14/12/19"
                    value={startDateField1}
                    onChange={this._changeInputField('startDateField1')}
                  />
                  <span className="icon is-small is-left">
                    <CalendarAltIcon />
                  </span>
                  {!isValid(brasilStartDateFieldFormatRange1)
                    ? <p className="help is-danger">Data incorreta</p>
                    : !isStartBeforeTodayRange1
                      ? <p className="help is-danger">Data deve ser menor que hoje</p>
                      : !endHigherThanBeginRange1 ? <p className="help is-danger">Data inicio é maior que data fim.</p> : <p></p>}
                </div>
              </div>
            </Column>
            <Column>
              <label className="label has-text-centered">Data fim</label>
              <div className="field has-addons has-addons-right ">
                <div className="control is-expanded has-icons-left">
                  <input
                    style={{ maxWidth: 150, borderColor: endDateIsValidRange1 && colors[0] }}
                    className={`input ${endDateIsValidRange1 ? '' : 'is-danger'}`}
                    placeholder="Ex: 14/12/19"
                    value={endDateField1}
                    onChange={this._changeInputField('endDateField1')}
                  />
                  <span className="icon is-small is-left">
                    <CalendarAltIcon />
                  </span>
                  {!isValid(brasilEndDateFieldFormatRange1)
                    ? <p className="help is-danger">Data incorreta</p>
                    : !isEndBeforeTodayRange1
                      ? <p className="help is-danger">Data deve ser menor que hoje</p> : <p></p>}
                </div>
              </div>
            </Column>
          </Columns>

          <Columns style={{marginTop: 0, marginBottom: 20, justifyContent: 'center'}}>
            <Column>
              <label className="label has-text-centered">Data Inicio</label>
              <div className="field has-addons has-addons-right">
                <div className="control is-expanded has-icons-left">
                  <input
                    style={{ maxWidth: 150, borderColor:  endDateIsValidRange2 && colors[1] }}
                    className={`input ${startDateIsValidRange2 ? '' : 'is-danger'}`}
                    placeholder="Ex: 14/12/19"
                    value={startDateField2}
                    onChange={this._changeInputField('startDateField2')}
                  />
                  <span className="icon is-small is-left">
                    <CalendarAltIcon />
                  </span>
                  {!isValid(brasilStartDateFieldFormatRange2)
                    ? <p className="help is-danger">Data incorreta</p>
                    : !isStartBeforeTodayRange2
                      ? <p className="help is-danger">Data deve ser menor que hoje</p>
                      : !endHigherThanBeginRange2 ? <p className="help is-danger">Data inicio é maior que data fim.</p> : <p></p>}
                </div>
              </div>
            </Column>
            <Column >
              <label className="label has-text-centered">Data fim</label>
              <div className="field has-addons has-addons-right ">
                <div className="control is-expanded has-icons-left">
                  <input
                    style={{ maxWidth: 150, borderColor: endDateIsValidRange2 && colors[1] }}
                    className={`input ${endDateIsValidRange2 ? '' : 'is-danger'}`}
                    placeholder="Ex: 14/12/19"
                    value={endDateField2}
                    onChange={this._changeInputField('endDateField2')}
                  />
                  <span className="icon is-small is-left">
                    <CalendarAltIcon />
                  </span>
                  {!isValid(brasilEndDateFieldFormatRange2)
                    ? <p className="help is-danger">Data incorreta</p>
                    : !isEndBeforeTodayRange2
                      ? <p className="help is-danger">Data deve ser menor que hoje</p> : <p></p>}
                </div>
              </div>
            </Column>
          </Columns>
        </Column>
        <Column>
          <DateRangePicker
            startDatePlaceholder="Data Inicio"
            endDatePlaceholder="Data Fim"
            editableDateInputs={true}
            direction="horizontal"
            months={1}
            maxDate={subDays(currentDate, 1)}
            rangeColors={colors}
            locale={ptBR}
            inputRanges={defaultInputRanges}
            dateDisplayFormat="dd/MM/yy"
            staticRanges={defaultStaticRanges}
            ranges={ranges}
            monthDisplayFormat="MMMM YYYY"
            onChange={this._handleChange}
            moveRangeOnFirstSelection={false}
            showPreview={true}
            mon
            weekStartsOn={1}
          />
        </Column>
      </Columns>
      <div className="buttons">
        <div className={`button ${canConfirm ? 'is-primary': 'is-static'}`} onClick={this._handleConfirm(canConfirm)}>Confirmar</div>
        <div className="button is-default" onClick={this.props.close}>Cancelar</div>
      </div>
    </div>
  }
}

export default CalendarDoubleRangePicker;