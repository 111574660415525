import React, {Component} from 'react';

import {connect} from 'react-redux';

import {withRouter} from "react-router-dom";
import Page, {PageTitle} from '../../../components/Page';
import { TelegramIcon } from '../../../components/icons';
import QRCode from 'qrcode.react';
import notification from '../../../utils/notification';
import { get, post } from '../../../utils';


class TelegramUserSettings extends Component {

  constructor(props) {
    super(props);
    this.state = {
      token: '',
      isLoadingToken: false
    }
  }

  componentDidMount() {
    this._loadToken();
  }

  _generateNewToken = () => {
    const { isLoadingToken } = this.state;
    if(isLoadingToken)
      return;

    const { uid } = this.props.user;
    this.setState({ isLoadingToken: true }, () => {
      post(`auth/telegramtoken`,{
        uid: uid,
        type: 'USER'
      })
      .then(resp => this.setState({
        isLoadingToken: false,
        token: resp.data.data.token
      }))
    });
  }

  _loadToken = () => {
    const { isLoadingToken } = this.state;
    const { uid } = this.props.user;
    if(isLoadingToken)
      return;

    this.setState({ isLoadingToken: true }, () => {
      get(`auth/telegramtoken/${uid}`)
        .then(resp => this.setState({
          isLoadingToken: false,
          token: resp.data.data.token
        }))
    })
  }

  _copyToClipBoard = (linkToOpenChat) => () => {
    navigator.clipboard.writeText(linkToOpenChat);
    notification.success('Copiado!', 'Link Telegram', 2000);
  }

  render() {
    const { isLoadingToken, token } = this.state;
    return (
      <Page>
        <PageTitle title={(<div>
          <TelegramIcon />
          <span style={{ marginLeft: '15px' }}>Telegram</span> 
        </div>)} />
        <div className="card" style={{width: 356, height: 356}}>
          <div
            className="card-content"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%'
            }}
          >
            {!isLoadingToken && !token && <div
              className="subtitle is-5 has-text-centered cursor-pointer"
              onClick={this._generateNewToken}
              style={{
                backgroundColor: !isLoadingToken && !token ? '#eeeeee': '#ffffff',
                padding: 50,
                borderRadius: 15
              }}
            >
              Clique aqui para gerar um token
            </div>}
            {!isLoadingToken && token && <div 
              style={{
                maxWidth: 256,
                margin: 25,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%'
              }}
            >
              <p className="help is-default has-text-centered" style={{ marginBottom: 10}}>
                Aponte a camera do celular para o QR Code
              </p>
              <QRCode
                size={256}
                style={{cursor: 'pointer'}}
                value={`https://t.me/tooqbot?start=${token}`}
                onClick={this._copyToClipBoard(`https://t.me/tooqbot?start=${token}`)}
              />
              <p className="help is-default has-text-centered">
                Ou <span
                onClick={this._copyToClipBoard(`https://t.me/tooqbot?start=${token}`)}
                style={{
                  fontWeight: 'bold',
                  cursor: 'pointer',
                  textDecoration: 'underline'
                }}
              >clique aqui</span> para copiar o link de acesso para o telegram.
              </p>
            </div>}
            {isLoadingToken && <span
              className="button is-loading is-white is-borderless"
              style={{fontSize: 50}}
            />}
          </div>
        </div>
      </Page>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.app.user,
    userInfo: state.app.userInfo,
    group: state.app.userGroup
  }
}

export default withRouter(connect(mapStateToProps)(TelegramUserSettings));