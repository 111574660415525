import React, { useState, useEffect } from 'react';
import IsLoading from '../../../../components/IsLoading';
import { del, get } from '../../../../utils';
import notification from '../../../../utils/notification';
import AddAuditScheduler from './AddAuditoria';


const OPTIONS = {
  "Monday": "Segunda-feira",
  "Tuesday": "Terça-feira",
  "Wednesday": "Quarta-feira",
  "Thursday": "Quinta-feira",
  "Friday": "Sexta-feira",
  "Saturday": "Sábado",
  "Sunday": "Domingo"
}

const Auditoria = (props) => {
  const { site } = props;
  const [camerasOptions, setCamerasOptions] = useState([]);
  const [schedulerList, setSchedulerList] = useState([]);
  const [modalAddAuditScheduler, setModalAddAuditScheduler] = useState(false)
  const [isLoadingSchedulerList, setIsLoadingSchedulerList] = useState(false)

  useEffect(() => {
    setCamerasOptions([]);
    get(`admin/videos/sites/${site.id}/cameras`)
      .then(resp => {
        const cameras = resp.data.data;
        const cameraOptions = cameras.map(camera => ({ value: camera.id, label: camera.description }))
        setCamerasOptions(cameraOptions);
      });

    setIsLoadingSchedulerList(true);
    get(`admin/audit/scheduler`, { siteId: site.id })
      .then(resp => {
        setIsLoadingSchedulerList(false);
        setSchedulerList(resp.data.data)
      })
  }, [site.id]);

  const [isDeleting, setIsDeleting] = useState(false);
  const onClickDeleteSchedule = (id) => {
    if (isDeleting) {
      return;
    }

    setIsDeleting(true);
    del(`admin/audit/scheduler/${id}/${site.id}`)
      .then((resp) => {
        setIsDeleting(false)
        setSchedulerList(schedulerList.filter(r => r.id !== id));
        notification.success('Removido com successo.')
      })
      .catch(err => {
        notification.error('Não foi possivel remover o registro, tente novamente mais tarde.')
      })
  }

  const handleAdd = newRow => {
    setSchedulerList([...schedulerList, newRow])
  };

  return <div>
    <div className='columns is-centered'>
      <div className='column is-narrow'>
        <button
          className="button is-primary"
          onClick={() => setModalAddAuditScheduler(!modalAddAuditScheduler)}>
          Adicionar
        </button>
      </div>
    </div>
    {modalAddAuditScheduler && <AddAuditScheduler
      closeModal={() => setModalAddAuditScheduler(null)}
      siteId={site.id}
      handleAdd={handleAdd} />
    }

    <table className='table is-fullwidth is-hoverable'>
      <thead>
        <tr>
          <th>ID</th>
          <th>Câmeras</th>
          <th>Dias da semana</th>
          <th>Hora inicio</th>
          <th>Hora fim</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {schedulerList.map(scheduler => <tr key={`sites-videos-auditoria-schedulerlist-${scheduler.id}`}>
          <td className='is-vcentered'>{scheduler.id}</td>
          <td className='is-vcentered'>{scheduler.cameras.map(c => {
            const camera = camerasOptions.find(camera => camera.value === c) || {
              value: c,
              label: ''
            }
            return <span style={{ marginRight: 5 }} className='tag is-default is-medium'>
              #{camera.value} - {camera.label}
            </span>
          })}</td>
          <td className='is-vcentered'>{scheduler.daysOfWeek.map(d => <span style={{ marginRight: 5 }} className="tag is-default is-medium">{OPTIONS[d]}</span>)}</td>
          <td className='is-vcentered'>{scheduler.beginTime}</td>
          <td className='is-vcentered'>{scheduler.endTime}</td>
          <td className='is-vcentered'>
            <div className="button is-danger is-light" onClick={() => onClickDeleteSchedule(scheduler.id)}>
              Excluir
            </div>
          </td>
        </tr>)}
      </tbody>
    </table>
    {isLoadingSchedulerList && <div style={{display:'flex', justifyContent:'center', width:'100%'}}><IsLoading isBorderless={true} fontSize={60}/></div>}
  </div>
  // </Page>
}

export default Auditoria;