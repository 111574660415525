import React, { Component } from 'react';
import { db } from '../../../../../firebase';
import { Link, withRouter } from "react-router-dom";

import { format, differenceInMinutes } from 'date-fns';
import {connect} from 'react-redux';
import { ExclamationTriangleIcon } from '../../../../../components/icons';
import { getServerDate, put } from '../../../../../utils';

class SensorList extends Component {
  constructor(props){
    super(props);

    const { lastData } = props.venue;
    
    const last = {...lastData, collectTime: format(lastData.collectTime, 'X')}
    this.state = {
      serverDate: getServerDate(),
      isloading: false,
      realtimeValues: last
    };
  };

  componentDidMount(){
    this._loadRealTime();
  };

  componentWillUnmount(){
    const { venue, siteId } = this.props;
    db.ref(`/johann/${siteId}/sensors/${venue.sensorid}/realtime/values`).off('value')
  };

  _loadRealTime() {
    const { venue, siteId } = this.props;
    db.ref(`/johann/${siteId}/sensors/${venue.sensorid}/realtime/values`).on('value', this._realtimeTemperatureAndHumidity)
  };

  _realtimeTemperatureAndHumidity = async (data) => {
    if(data === null || !data.exists())
      return;

    const serverDate = getServerDate();
    this.props.onChangeRealtimeValue(this.props.areaId, this.props.venue.id, serverDate, data.toJSON());
  };

  _handleInactive = (venue) => {
    const { siteId, areaId } = this.props;

    put(`admin/johann/sites/${siteId}/venues/${venue.id}/inactive`, {
      isInactive: !venue.isInactive
    }).then(res => {venue.makeInactive(areaId, venue.id, !venue.isInactive)})
  };

  render() {
    let diff = 0;
    const { venue, siteId } = this.props;
    const { realtimeValues={}, serverDate } = this.props.venue;

    const getTemperature = () => {
      if (!realtimeValues)
        return 0;

      let temperature = realtimeValues.temperature;
  
      if(venue.multi_thermo_part){
        temperature = (realtimeValues.temperature + realtimeValues.temperature_2) / 2
      };

      return temperature;
    }

    const temperature = getTemperature();

    const hasWarn = realtimeValues.temperature && (
      temperature > venue.maxTemp ||
      temperature < venue.minTemp
    );

    if(realtimeValues.collectTime){
      diff = differenceInMinutes(serverDate, new Date(realtimeValues.collectTime *1000))
    }

    const isOffline = diff > 30;
    
    return (
      <tr>
        <td>
          <Link to={`/board/sites/${siteId}/dashboard/johann/venue/${venue.id}`} className={venue.isInactive ? "tag is-default" : "has-text-black"}>
            {venue.placa} #{venue.name}
          </Link>
        </td>
        <td>
          {format(realtimeValues.collectTime * 1000, 'DD/MM HH:mm:ss')}
        </td>
        {isOffline ? 
          <td className="has-text-danger" >OFFLINE</td> 
        : 
          <td className="has-text-success" >ONLINE</td>
        }
        {venue.multi_thermo_part ? 
          <td className={"subtitle "+ (hasWarn ? "has-text-danger subtitle" : "has-text-success subtitle")}>
            {venue.isInactive ? "-" : `${Number(Math.round(temperature  * 100) / 100).toFixed(1)}˚C`} {hasWarn && !venue.isInactive && <ExclamationTriangleIcon style={{fontSize: 20}}/>}
          </td>
        :
          <td className={"subtitle "+ (hasWarn ? "has-text-danger subtitle" : "has-text-success subtitle")}>
            {venue.isInactive ? "-" : `${Number(Math.round(temperature  * 100) / 100).toFixed(1)}˚C`} {hasWarn && !venue.isInactive && <ExclamationTriangleIcon style={{fontSize: 20}}/>}
          </td>
        }
        <td className={"subtitle "+ (hasWarn ? "has-text-danger subtitle" : "has-text-success subtitle")}>
          {venue.isInactive ? "-" : `${venue.hasHumi ? realtimeValues.humidity+'%' : '-'}`}
        </td>
        <td className={"subtitle "+ (hasWarn ? "has-text-danger subtitle" : "has-text-success subtitle")}>
          {venue.isInactive ? "-" : `${venue.multi_thermo_part ? Number(Math.round(realtimeValues.temperature * 100) / 100).toFixed(1)+'˚C' : '-'}`} {venue.multi_thermo_part && hasWarn && <ExclamationTriangleIcon style={{fontSize: 20}}/>}
        </td>
        <td className={"subtitle "+ (hasWarn ? "has-text-danger subtitle" : "has-text-success subtitle")}>
          {venue.isInactive ? "-" : `${venue.multi_thermo_part ? Number(Math.round(realtimeValues.temperature_2  * 100) / 100).toFixed(1)+'˚C' : '-'}`} {venue.multi_thermo_part && hasWarn && <ExclamationTriangleIcon style={{fontSize: 20}}/>}
        </td>
        <td className={"subtitle "}>
          <span className={`tag ${realtimeValues.open_lvl >= venue.ldrval ? 'is-default' : 'is-primary'} `}>
            {venue.isInactive ? "-" : `${venue.ldrval ? realtimeValues.open_lvl >= venue.ldrval ? 'ABERTA' : 'FECHADA' : '-'}`}
          </span>
        </td>
        <td>
          <div className="field" style={{marginTop: 10}}>
            <input id={`sensorlist_venue_${venue.id}`} type="checkbox" className="switch is-rounded" checked={venue.isInactive} onChange={() => this._handleInactive(venue)} />
            <label for={`sensorlist_venue_${venue.id}`} ClassName="label" ></label>
          </div>
        </td>
      </tr>
    );

  };

};

function mapStateToProps(state, props) {
  const site = state.sites.all[props.match.params.siteId] || {};
  return { site }
};

export default withRouter(connect(mapStateToProps)(SensorList));
