import React, { Component } from 'react';

import withRouter from "react-router-dom/withRouter";
import { connect } from 'react-redux';

import {format} from 'date-fns'

import { DownloadIcon } from './icons';
import {get} from '../utils';


class DownloadButton extends Component {
  constructor(props){
    super(props)
    this.state = {
      downloading: false,
    }
  }

  
  render() {
    const {downloading} = this.state;
    const { is='is-white', label } = this.props;
    return (
        <button className={`button ${is} ${downloading ? 'is-loading' : ''}`} onClick={this._downloadPdf}>
          <DownloadIcon />
          {label && <span>{label}</span>}
        </button>
      )
  }

  _downloadPdf = () => {
    const { name, begin, end, params={}, excel=false, site, sitesObj=[]} = this.props;
    if(this.state.downloading)
      return;

      let type = 'pdf'
      if(excel){
        type = 'excel'
      }

    this.setState({ downloading: true },  () => {
      get(`reports/foottraffic/${type}/sites/${site.id}/${name}`, {
        begin: format(begin, 'YYYY-MM-DD'),
        end: format(end, 'YYYY-MM-DD'), 
        ...params
      }, { responseType: 'blob' }).then((response) => {
        const { begin, end,name } = this.props;
        const beginStr = format(begin, 'DDMMYY');
        const endStr = format(end, 'DDMMYY');

        let fileTitle = '';
        const { type } = params;
        if(name === 'peoplecount')
          fileTitle = type === 'HOURLY' ? 'Tráfego Hora Hora':'Tráfego';
        else if(name === 'returnsrate')
          fileTitle = 'Retornos';
        else if(name === 'salesrate')
          fileTitle = 'Visitantes e Vendas';
        else if(name === 'venuevisitors')
          fileTitle = 'Visitantes Áreas';
        else if(name === 'general')
          fileTitle = 'Relatórios';

        const fileName = `${sitesObj.length <= 1 ? site.name+' ': ''}${fileTitle} ${beginStr}-${endStr}.${excel ? 'xlsx' : 'pdf'}`;
        const url = window.URL.createObjectURL(response.data)
        const a = document.createElement('a')
        a.href = url
        a.target = '_blank';
        a.download = fileName;
        a.click();
        a.remove();

        setTimeout(() => {
          this.setState({
            downloading: false,
          }, () => window.URL.revokeObjectURL(url));
        }, 100);
      }).catch(err => {
        console.log(err);
        this.setState({
          downloading: false,
        })
      });
    
    })
  }
}
function mapStateToProps(state, props) {
  const site = props.site;
  return { site }
}

export default withRouter(connect(mapStateToProps)(DownloadButton));