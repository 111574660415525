import React, { Component } from 'react';

import { get } from '../../../../utils';

import { format } from 'date-fns';
import randomColor from 'randomcolor';
import withRouter from "react-router-dom/withRouter";
import { connect } from 'react-redux';

import CustomerBehaviorChart from './CustomerBehaviorChart';
import VisitorsAndPassersByHourly from '../foottraffic/analytics/VisitorsAndPassersByHourly';
import VenueTraffic from './VenueTraffic';

import Page from '../../../../components/Page';
import Columns, { Column } from '../../../../components/Columns';
import * as Icons from '../../../../components/icons';
import PeoplecountChart from '../foottraffic/analytics/PeoplecountChart';
import VisitorsRateChart from '../foottraffic/analytics/VisitorsRateChart';
import SalesRateChart from '../foottraffic/analytics/SalesRateChart';
import HeatMapVenues from './HeatMapVenues';
import SkusNielsen from '../ambev/SkusNielsen';

class DashboardVideo extends Component {
  constructor(props){
    super(props);
    this.state = {
      loading: false,
      toogleModal: false,
      modalContent: null,
      sales: null,
      visitorsReturnsErr: false,
      visitorsRateInsideShopErr: false,
      venuePeopleCountErr: false,
      peopleCountErr: false,
    }
  }

  loadHeatmaps = async (startDate) => {
    const siteId = this.props.site.id;
    const date = format(startDate, 'YYYY-MM-DD HH:mm:ss');
    const venueResponse = await get(`dashboard/video/sites/${siteId}/heatmaps`, { date }).then(res => res.data);
    const hasHeatmap = venueResponse.data.length;
    return { date, hasHeatmap, heatmaps: venueResponse.data };
  }

  loadPeopleCount = (type, startDate, endDate, startDate2, endDate2) => {
    const siteId = this.props.site.id;
    const begin = format(startDate, 'YYYY-MM-DD HH:mm:SS');
    const end = format(endDate, 'YYYY-MM-DD HH:mm:SS');

    const timezonebegin = format(startDate, 'Z');
    const timezoneend = format(endDate, 'Z');

    if(startDate2 && endDate2) {
      const begin2 = format(startDate2, 'YYYY-MM-DD HH:mm:SS');
      const end2 = format(endDate2, 'YYYY-MM-DD HH:mm:SS');

      const timezonebegin2 = format(startDate2, 'Z');
      const timezoneend2 = format(endDate2, 'Z');

      return get(`dashboard/video/sites/${siteId}/peoplecount`, { type, begin, end, timezonebegin, timezoneend, begin2, end2, timezonebegin2, timezoneend2 })
        .catch(err => {
          this.setState({ peopleCountErr: true });
          return { data: { header: {}, data: [] } };
        })
        .then(resp => resp.data);
    }
    
    return get(`dashboard/video/sites/${siteId}/peoplecount`, { type, begin, end, timezonebegin, timezoneend })
      .catch(err => {
        this.setState({ peopleCountErr: true });
        return { data: { header: {}, data: [] } };
      })
      .then(resp => resp.data);
  }
  loadSalesRate = (startDate, endDate) => {
    const siteId = this.props.site.id;
    const begin = format(startDate, 'YYYY-MM-DD');
    const end = format(endDate, 'YYYY-MM-DD');

    return get(`dashboard/video/sites/${siteId}/salesrate`, { begin, end })
    .catch(err => {
      this.setState({ visitorsRateInsideShopErr: true });
      return { 
        data: { 
          header: {
            total_sales: 0,
            visitors: 0,
            conversionRate: 0
          },
          data: []
        }
      };
    })
    .then(resp => resp.data);
  }

  _toogleModal = (content) => {
    const { toogleModal } = this.state;
    this.setState({ toogleModal: !toogleModal, modalContent: content });
  }

  loadVenuePeopleCount = async (startDate, endDate, heatmap) => {
    const siteId = this.props.site.id;
    const begin = format(startDate, 'YYYY-MM-DD');
    const end = format(endDate, 'YYYY-MM-DD');

    const venueResponse = await get(`dashboard/video/sites/${siteId}/venuevisitors`, { begin, end }).then(res => res.data);
    if(!venueResponse || !venueResponse.header) {
      return {
        data: { header: { venues: [] }, data: [] }, venues: [], heatmap: false
      }
    }

    const venues = venueResponse.header.venues.map((venue, i) => {
      return {
        ...venue,
        color: randomColor({ hue: '#0043ff', seed: i*1234 })
      }
    });

    return { ...venueResponse, venues };
  }

  render() {
    const { group, site } = this.props;
    const siteId = this.props.site.id;
    const {
      toogleModal,
      modalContent,
      sales
    } = this.state;

    return <Page>
      <Columns isMultiline>
        <Column isSize={12}>
          <p className="title">{group.name} - {site.name} {this.state.reloadingShops && <span className="button is-loading is-static is-bordless"/>}</p>
          <p className="subtitle"><Icons.HomeIcon/>Tráfego da loja</p>
        </Column>
        <Column isSize={12}>
          <div>
            {toogleModal && <div className="modal is-active">
              <div className="modal-background" onClick={this._toogleModal}></div>
              <div className="modal-content is-paddingless " style={{width: '80%'}}>
                {modalContent}
              </div>
              <button className="modal-close is-large" onClick={this._toogleModal} aria-label="close"></button>
            </div>}
            <Columns isMultiline>
              <Column isSize={12}>
                <Columns>
                  <Column className="tile" isSize={8}>
                    <PeoplecountChart loadPeopleCount={this.loadPeopleCount}/>
                  </Column>
                  <Column isSize={4}>
                    <Columns isMultiline>
                      <Column isSize={12}>
                        <VisitorsRateChart site={this.props.site} siteId={siteId} loadPeopleCount={this.loadPeopleCount} showMore={this._toogleModal} dashVideo={true}/>
                      </Column>
                      <Column isSize={12}>
                        <SalesRateChart sales={sales} site={this.props.site} siteId={siteId} loadSalesRate={this.loadSalesRate} showMore={this._toogleModal} dashVideo={true}/>
                      </Column>
                    </Columns>
                  </Column>
                </Columns>
              </Column>
              <Column isSize={12}>
                <VisitorsAndPassersByHourly site={this.props.site} siteId={siteId} loadPeopleCount={this.loadPeopleCount} dashVideo={true}/>
              </Column>
              <Column isSize={12}>
                <CustomerBehaviorChart site={this.props.site} siteId={siteId} loadVenuePeopleCount={this.loadVenuePeopleCount} showMore={this._toogleModal} dashVideo={true} />
              </Column>
              <Column isSize={12}>
                <HeatMapVenues site={this.props.site} siteId={siteId} loadHeatmaps={this.loadHeatmaps} showMore={this._toogleModal} />
              </Column>
              <Column isSize={12}>
                <VenueTraffic site={this.props.site} siteId={siteId} dashVideo={true} />
              </Column>
              <Column isSize={12}>
                <SkusNielsen  siteId={siteId} />
              </Column>
            </Columns>
          </div>
        </Column>
      </Columns>
    </Page>
  }
}


function mapStateToProps(state, props) {
  return {
    tooqAdmin: true,
    site: props.site
  }
}

export default withRouter(connect(mapStateToProps)(DashboardVideo));
