import React, { useEffect, useState } from 'react';
import {
  PieChart,
  Pie,
  Brush,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
  LabelList,
  Area
} from 'recharts';

import _ from 'lodash';
import t from 'prop-types';
import tinycolor from 'tinycolor2';

import Page, { PageTitle } from '../../../../components/Page';
import Columns, { Column } from '../../../../components/Columns';

import { CalendarAltIcon, FillIcon } from '../../../../components/icons';
import IsLoading from '../../../../components/IsLoading';

import { get, getServerDate, formatAsPtBr } from '../../../../utils';
import { addDays, format, toDate, subDays } from 'date-fns';
import { pt } from 'date-fns/locale';
import CalendarRangePicker from '../calendar/CalendarRangePicker';
import DisposalPlanogramModal from './DisposalPlanogramModal';

const CalendarFilter = (props) => {
  const [ toogleFilter, setToogleFilter ] = useState(false);
  const _toogleFilter = () => {
    setToogleFilter(!toogleFilter);
  }

  const {
    begin,
    end,
    setBegin,
    setEnd,
    style={},
    maxDate,
    className='bottom-left-to-right'
  } = props;

  const setConfirmDates = data => {
    const { startDate, endDate } = data.range;
    setBegin(format(startDate, 'YYYY-MM-DD'));
    setEnd(format(endDate, 'YYYY-MM-DD'));
    _toogleFilter();
  };

  return <div className="tooltip">
    <small
      className="cursor-pointer help"
      onClick={_toogleFilter}
      style={{ textDecoration: 'underline', ...style }}
    >
      De <b>{formatAsPtBr(begin, 'DD MMM')}</b> até <b>{formatAsPtBr(end, 'DD MMM')}</b>
    </small>
    {toogleFilter && <span className={`tooltiptext ${className}`} style={{ left: '0%', bottom: '120%'}}>
      <CalendarRangePicker
        maxDate={maxDate}
        range={{
          startDate: toDate(begin),
          endDate: toDate(end)
        }}
        close={_toogleFilter}
        onChange={setConfirmDates}
      />
    </span>}
  </div>
}

const useTagsProducts = (siteId) => {
  const [ tags, setTags ] = useState([]);
  const [ isLoading, setIsLoading ] = useState(true);

  useEffect(() => {
    setIsLoading(true)

    get(`dashboard/shelf/sites/${siteId}/produtotags`)
      .then(resp => {
        setTags(resp.data.data);
        setIsLoading(false);
      });
  }, [ siteId ]);

  return [ isLoading, tags ];
}

const useDailySalesProductsVsVisitors = (siteId, begin, end) => {
  const [ dailyVisitors, setDailyVisitors ] = useState([]);
  const [ dailySales, setDailySales ] = useState([]);
  const [ isLoading, setIsLoading ] = useState(true);

  useEffect(() => {

    setIsLoading(true);

    const requestVisitors = get(`dashboard/foottraffic/sites/${siteId}/peoplecount`, {
      begin,
      end
    });

    const requestSales = get(`dashboard/suvinil/sites/${siteId}/produtosales`, {
      begin,
      end
    });

    Promise.all([ requestVisitors, requestSales ])
      .then(([ vistiorsResponse, salesResponse ]) => {
        setDailyVisitors(vistiorsResponse.data.data);
        setDailySales(salesResponse.data.data);
        return true;
      })
      .then(() => {
        setIsLoading(false);
        return true;
      })
  }, [ siteId, begin, end ]);

  return [ isLoading, dailyVisitors, dailySales ];
}

const useDailySalesVsVisitors = (siteId, begin, end) => {
  const [ dailyVisitors, setDailyVisitors ] = useState([]);
  const [ dailySales, setDailySales ] = useState([]);
  const [ dailyVisitorsVenue, setDailyVisitorsVenue ] = useState([]);
  const [ isLoading, setIsLoading ] = useState(true);

  useEffect(() => {

    setIsLoading(true);

    const requestVisitors = get(`dashboard/foottraffic/sites/${siteId}/peoplecount`, {
      begin,
      end
    });

    const requestVisitorsVenue = get(`dashboard/foottraffic/sites/${siteId}/dailyvenues`, {
      begin,
      end,
      isSellout: true
    })

    const requestSales = get(`dashboard/suvinil/sites/${siteId}/sales`, {
      begin,
      end
    });

    Promise.all([ requestVisitors, requestSales, requestVisitorsVenue ])
      .then(([ vistiorsResponse, salesResponse, visitorsVenueResponse ]) => {
        setDailyVisitors(vistiorsResponse.data.data);
        setDailySales(salesResponse.data.data);
        setDailyVisitorsVenue(visitorsVenueResponse.data.data);
        return true;
      })
      .then(() => {
        setIsLoading(false);
        return true;
      })
  }, [ siteId, begin, end ]);

  return [ isLoading, dailyVisitors, dailySales, dailyVisitorsVenue ];
}

const maxLitrosVendidosProducts = (isLoadingSales, dailySalesProducts) => {
  if (isLoadingSales) {
    return 0;
  }

  const maxLitrosList = dailySalesProducts.map((dayData) => {
    const tags = Object.values(dayData.tags);
    const totalLitrosVendidos = _.max(tags.map(tag => {
      const totalLitros = _.sumBy(tag.produtosVendidos, 'totalLitros');
      return totalLitros;
    }));
    return totalLitrosVendidos;
  });


  return _.max(maxLitrosList);
}

const mashVisitorsAndProductSales = (isLoadingVisitors, isLoadingSales, selectedTag, dailyVisitors, dailySales) => {
  if (isLoadingVisitors || isLoadingSales || !selectedTag || selectedTag === 0) {
    return [];
  }

  return dailySales.map(daily => {
    const { snapDate, tags } = daily;
    const tagData = tags[`${selectedTag}`];
    const {produtosVendidos} = tagData;

    const visitorsData = dailyVisitors.find(d => d.info_date === daily.snapDate) || {
      visitorsCount: 0
    }

    const totalLitros = _.sumBy(produtosVendidos, 'totalLitros');
    const quantidadeLatas = _.sumBy(produtosVendidos, 'quantidadeLatas');
    const litrosVisitantes = visitorsData.visitorsCount === 0
      ? 0
      : totalLitros === 0 ? 0
      : totalLitros / visitorsData.visitorsCount;

    return {
      snapDate,
      tagId: selectedTag,
      tagDescricao: tagData.tagDescricao,
      tagColor: tagData.color,
      visitors: visitorsData.visitorsCount,
      totalLitros: isNaN(totalLitros) ? 0 : totalLitros,
      quantidadeLatas: isNaN(quantidadeLatas) ? 0 : quantidadeLatas,
      litrosVisitantes
    }
  })
}


const mashVistorsAndSales = (isLoadingVisitors, dailySales, dailyVisitors, dailyVisitorsVenue) => {
  if (isLoadingVisitors) {
    return [];
  }

  return dailySales.map(daily => {
    const venueVisitors = dailyVisitorsVenue.find(d => daily.snapDate === d.info_date) || {
      venues: []
    };
    
    const visitorsSellout = venueVisitors.venues.map(venue => venue.visitorsCount).reduce((a, b) => a + b, 0); 

    const visitor = dailyVisitors.find(d => d.info_date === daily.snapDate) || {visitorsCount: 0};

    const visitorsToShow = daily.totalLitros === 0
      ? 0
      : venueVisitors.venues.length === 0 ? visitor.visitorsCount : visitorsSellout;


    return {
      ...daily,
      visitors: visitorsToShow,
      litrosVisitantes: daily.totalLitros === 0 
        ? 0 
        : visitorsToShow === 0 ? 0 : parseFloat((daily.totalLitros / visitorsToShow).toFixed(1))
    }
  })
}

const mashDisposalAndFixRateWithSales = (isLoadingVisitors, isLoadingDisposal, isLoadingPlanogramIssues,
  planogramIssues, disposalGeneral, salesMashed) => {

  if (isLoadingDisposal || isLoadingPlanogramIssues || isLoadingVisitors) {
    return [];
  }

  return salesMashed.map((row) => {
    const dayfixRate = planogramIssues.find(d => d.snapDate === row.snapDate) || {
      fixRate: 0
    }

    const dayDisposal = disposalGeneral.find(d => d.snapDate === row.snapDate) || {
      disposalRate: 0
    }

    return {
      ...row,
      fixRate: row.totalLitros === 0 ? 0 : dayfixRate.fixRate,
      disposalRate: row.totalLitros === 0 ? 0 : dayDisposal.disposalRate
    }
  })
}

const usePlanogramIssues = (siteId, begin, end) => {
  const [ acceptableTimeWrong, setAcceptableTimeWrong ] = useState(600)
  const [ isLoadingPlanogramIssues, setIsLoadingPlanogramIssues ] = useState(true);
  const [ planogramIssues, setPlanogramIssues ] = useState([]);
  useEffect(() => {
    setIsLoadingPlanogramIssues(true)
    get(`dashboard/shelf/sites/${siteId}/kpi/planogramissues`, {
        begin,
        end
      })
      .then(resp => {
        setAcceptableTimeWrong(resp.data.header.acceptableTimeWrong)
        setPlanogramIssues(resp.data.data);
      })
      .then(() => setIsLoadingPlanogramIssues(false))
  }, [siteId, begin, end]);

  return [isLoadingPlanogramIssues, planogramIssues, acceptableTimeWrong];
}

const useDisposalGeneral = (siteId, begin, end) => {
  const [ isLoadingDisposal, setIsLoadingDisposal ] = useState(true);
  const [ disposalGeneral, setDisposalGeneral ] = useState([]);
  useEffect(() => {
    setIsLoadingDisposal(true);
    get(`dashboard/shelf/sites/${siteId}/kpi/disposal`, {
        begin,
        end
      })
      .then(resp => resp.data.data)
      .then(setDisposalGeneral)
      .then(() => setIsLoadingDisposal(false));
  }, [siteId, begin, end]);
  return [isLoadingDisposal, disposalGeneral];
}

const useDisposalTags = (siteId, begin, end) => {
  const [ isLoadingDisposalTags, setIsLoadingDisposalTags ] = useState(true);
  const [ disposalTags, setDisposalTags ] = useState([]);
  useEffect(() => {
    setIsLoadingDisposalTags(true);
    get(`dashboard/shelf/sites/${siteId}/kpi/disposaltags`, {
        begin,
        end
      })
      .then(resp => resp.data.data)
      .then(setDisposalTags)
      .then(() => setIsLoadingDisposalTags(false));
  }, [siteId, begin, end]);
  return [ isLoadingDisposalTags, disposalTags ];
}

const useTagsComposition = (siteId, begin, end) => {
  const [ isLoadingDisposalTags, setIsLoadingDisposalTags ] = useState(true);
  const [ disposalTags, setDisposalTags ] = useState([]);
  useEffect(() => {
    setIsLoadingDisposalTags(true);
    get(`dashboard/shelf/sites/${siteId}/kpi/issues-composition`, {
        begin,
        end
      })
      .then(resp => resp.data.data)
      .then(setDisposalTags)
      .then(() => setIsLoadingDisposalTags(false));
  }, [siteId, begin, end]);
  return [ isLoadingDisposalTags, disposalTags ];
}

const TitleAsBoxFilter = ({ title, right, left, isLoading, begin, end, setBegin, setEnd }) => {
  const [ toogleFilter, setToogleFilter ] = useState(false);
  const _toogleFilter = () => {
    setToogleFilter(!toogleFilter);
  }

  if (isLoading) {
    return <div style={{marginBottom: 20, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
      <p className="subtitle is-5 has-text-centered">{title}</p>
      <p style={{marginTop: 5}} >
        <span className="tag is-dark heading is-medium">
          <CalendarAltIcon style={{marginRight: 5 }}/>
          ...
        </span>
      </p>
    </div>
  }

  const setConfirmDates = data => {
    const { startDate, endDate } = data.range;
    setBegin(format(startDate, 'YYYY-MM-DD'));
    setEnd(format(endDate, 'YYYY-MM-DD'));
    _toogleFilter();
  };

  return <div style={{width: '100%'}}>
    <p className="subtitle is-5 has-text-centered" style={{marginBottom: 0}}>{title}</p>
    <div
      className="tooltip"
      style={{
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 5,
        marginBottom: 20
      }}
    >
      <span className="tag is-dark is-medium cursor-pointer" onClick={_toogleFilter}>
        <CalendarAltIcon style={{marginRight: 5 }}/>
        {begin ? formatAsPtBr(begin, 'DD MMM') : '...'} até {end ? formatAsPtBr(end, 'DD MMM') : '...'}
      </span>
      {toogleFilter && <span className="tooltiptext" style={{ right, left, top: '100%' }}>
        <CalendarRangePicker
          maxDate={addDays(getServerDate(), -1)}
          range={{
            startDate: toDate(begin),
            endDate: toDate(end)
          }}
          close={_toogleFilter}
          onChange={setConfirmDates}
        />
      </span>}
    </div>
  </div>
}

const ProgressBarElement = ({ description, rate, progressColor, isBig=false, isLoading, showPercent=true }) => {
  if (isLoading) {
    return <div style={{
      width: '100%',
      borderRadius: 5,
      height: isBig ? 50 : 40,
      backgroundColor: '#CCCCCC',
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 10
    }}>
      <IsLoading isBorderless={true} fontSize={isBig ? 20 : 12} backgroundColor={"#cccccc"}/>
    </div>
  }

  return <div style={{
    width: '100%'
  }}>
    <div style={{
      width: '100%',
      borderRadius: 5,
      height: isBig ? 50 : 40,
      backgroundColor: '#CCCCCC',
      marginBottom: 10
    }}>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: showPercent ? 'space-between' : 'flex-start',
        width: `${rate > 100 ? 100: rate}%`,
        minWidth: '1%',
        borderRadius: 5,
        height: isBig ? 50 : 40,
        backgroundColor: progressColor
      }}>
        <span style={{
          fontWeight: isBig ? 'bold' : '',
          whiteSpace: 'nowrap',
          fontSize: isBig ? '1.5rem' : '1.2rem',
          marginLeft: 10,
          marginRight: showPercent ? 0 : 10,
          color: tinycolor(progressColor).isDark() ? '#FFFFFF': '#363636'
        }}>
          {description}
        </span>
        {showPercent && <div style={{
          margin: 5,
          padding: 5,
          fontSize: isBig ? '1rem' : '0.8rem',
          textDecoration: 'weight',
          backgroundColor: '#ffffff',
          justifyContent: 'center',
          display: 'flex',
          alignItems: 'center',
          borderRadius: 15,
          minWidth: 40
        }}>
          <b>{rate}%</b>
        </div>}
      </div>
    </div>
  </div>
}

const FixRate = ({
  begin, end,
  setBegin, setEnd,
  isLoadingPlanogramIssues, planogramIssues, acceptableTimeWrong
}) => {

  if (isLoadingPlanogramIssues) {
    return <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '100%'
    }}>
      <TitleAsBoxFilter title="Taxa de Reparo" left="0%" isLoading={true} />
      <IsLoading isBorderless={true} fontSize={60} backgroundColor={"#fff"}/>
    </div>
  }

  const rowsFromRange = planogramIssues.map(p => p.planogramIssues).flat();
  const rowsFromRangeSolvedIssues = planogramIssues.map(p => p.issuesSolvedInTime).flat();
  const dayMeanTime = (_.meanBy(rowsFromRange, 'timeOccurence'));

  const dayMeanTimeRate = parseInt((dayMeanTime / acceptableTimeWrong) * 100);

  return <div style={{
      display:'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%'
    }}>
    <TitleAsBoxFilter
      left="0%"
      title="Taxa de Reparo"
      begin={begin}
      end={end}
      setBegin={setBegin}
      setEnd={setEnd}
    />
    <Columns isMultiline isCentered>
      <Column isSize={12}>
        <ProgressBarElement
          isLoading={isLoadingPlanogramIssues}
          isBig={true}
          description="Taxa de reparo"
          rate={parseInt((rowsFromRangeSolvedIssues.length / rowsFromRange.length) * 100)}
          progressColor={'#178F32'}
        />
        <p className="help has-text-centered"><b>{rowsFromRangeSolvedIssues.length}</b> de <b>{rowsFromRange.length}</b> falhas no planograma foram resolvidas em menos de {parseInt(acceptableTimeWrong / 60)} minutos</p>
      </Column>
      <Column isSize={12}>
        <p className="heading"><b>TEMPO MÉDIO DE RUPTURA</b></p>
        <ProgressBarElement
          isLoading={isLoadingPlanogramIssues}
          isBig={true}
          description={`${parseInt(dayMeanTime / 60) } minutos`}
          rate={dayMeanTimeRate}
          progressColor={'#363636'}
          showPercent={false}
        />
        {dayMeanTimeRate > 100
          ? <p className="help has-text-centered">A média de tempo de reparo está {dayMeanTimeRate - 100}% acima do limite aceitável para a essa loja.</p>
          : <p className="help has-text-centered">Na médias suas falhas no planogramas estão sendo resolivdas a tempo.</p>}
      </Column>
    </Columns>
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div className="tag is-light is-medium">
        TEMPO ACEITÁVEL: <b style={{ marginLeft: 5 }}>{parseInt(acceptableTimeWrong / 60)} MINUTOS</b>
      </div>
    </div>
  </div>
}

const GeneralDisposal = ({
  siteId,
  beginGeneralDisposal, endGeneralDisposal,
  setBeginGeneralDisposal, setEndGeneralDisposal,
  isLoadingDisposal, disposalGeneral,
  isLoadingDisposalTags, disposalTags}) => {

  const samplesCounted = _.sumBy(disposalGeneral, 'samplesCount') || 0;
  const samplesNotOkCounted = _.sumBy(disposalGeneral, 'samplesNotOkCount') || 0;

  const disposalTagsReduced = disposalTags
  .map(disposalTag => {
    return disposalTag.tags
      .filter(tag => tag.tagIdFather === 1)
  })
  .reduce((prev, curr) => {
    curr.forEach(c => {
      const tagArray = prev[c.id] || {
        id: c.id,
        description: c.description,
        color: c.color,
        shelfs: c.shelfs,
        days: []
      };
    
      tagArray.days.push(c);
      prev[c.id] = tagArray;
    });
    return prev;
  }, {});

  const disposalTagsMeans = Object.values(disposalTagsReduced)
    .map(disposal => {
    const samplesCounted = parseInt(_.meanBy(disposal.days, 'samplesCount') || 0);
    const samplesNotOkCounted = parseInt(_.meanBy(disposal.days, 'samplesNotOkCount') || 0);
    return {
      ...disposal,
      samplesCounted,
      samplesNotOkCounted,
      disposalRate: parseInt(((samplesCounted - samplesNotOkCounted) / samplesCounted) * 100)
    }
  });

  return <div style={{ width: '100%' }}>
    <TitleAsBoxFilter
      isLoading={isLoadingDisposal || isLoadingDisposalTags}
      title="Taxa de Planograma"
      begin={beginGeneralDisposal}
      end={endGeneralDisposal}
      setBegin={setBeginGeneralDisposal}
      setEnd={setEndGeneralDisposal}/>
      <Columns isMultiline isCentered>
        <Column isSize={12}>
          <ProgressBarElement
            isLoading={isLoadingDisposal || isLoadingDisposalTags}
            isBig={true}
            description="Geral"
            rate={parseInt(((samplesCounted - samplesNotOkCounted) / samplesCounted) * 100)}
            progressColor={'#233B97'}
          />
          <p className="help has-text-centered">{samplesCounted - samplesNotOkCounted} de {samplesCounted} amostras estavam ok.</p>
        </Column>
        {isLoadingDisposalTags ? <IsLoading isBorderless={true} fontSize={60} backgroundColor={"#fff"}/> : <Column isSize={12} style={{marginTop: 20}}>
          <p className="heading has-text-centered">Principais tags:</p>
          {disposalTagsMeans.map(tag => <ProgressBarElement
            key={`disposal_tag_rate_id_${tag.id}`}
            isLoading={isLoadingDisposalTags}
            description={tag.description}
            rate={tag.disposalRate}
            progressColor={tag.color}/>)}
          <p className="help has-text-centered">De todas as amostras coletadas quantas não relataram falhas</p>
        </Column>}
        {!isLoadingDisposalTags && <Column isSize={12} style={{ display: 'flex', justifyContent: 'center'}}>
          <DisposalPlanogramModal
            siteId={siteId}
            begin={beginGeneralDisposal}
            end={endGeneralDisposal}
          />
        </Column>}
      </Columns>
    </div>
}

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = (props) => {
  const { cx, cy, midAngle, innerRadius, outerRadius, percent } = props;

  const radius = innerRadius + (outerRadius - innerRadius) * 1.1;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="#222" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central" fontWeight={'bold'}>
      {`${(percent).toFixed(0)}%`}
    </text>
  );
};


const GeneralIssuesCompose = ({
  begin,
  end,
  setBegin,
  setEnd,
  isLoadingDisposalTags,
  disposalTags
}) => {
  const higherDisposal = _.maxBy(disposalTags, 'percent') || {};
  const higherDisposalMax = !higherDisposal.percent || isNaN(higherDisposal.percent) === true ? 0 : higherDisposal.percent.toFixed(0);

  return <div style={{
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%'
  }}>

    <TitleAsBoxFilter
      right="0%"
      isLoading={isLoadingDisposalTags}
      title="Composição falhas"
      begin={begin}
      end={end}
      setBegin={setBegin}
      setEnd={setEnd}/>
    <Columns isMultiline isCentered>
      <Column isSize={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {isLoadingDisposalTags
          ? <IsLoading isBorderless={true} fontSize={60}/>
          : <PieChart width={250} height={250}>
            <Pie
              dataKey="sum"
              data={disposalTags}
              cx={125}
              cy={125}
              outerRadius={80}
              label={renderCustomizedLabel}
              labelLine={false}
              isAnimationActive={false}
            >
              {disposalTags.map((entry, index) => (
                <Cell key={`cell-${index}_key`} fill={entry.tagColorHash} />
              ))}
            </Pie>
          </PieChart>}
        {!isLoadingDisposalTags && <div className="help">
          <b style={{color: higherDisposal.tagColorHash}}>{higherDisposal.tagDescription}</b> foi responsável por <b style={{color: higherDisposal.tagColorHash}}>{higherDisposalMax}%</b> das falhas no planograma.
        </div>}
      </Column>
    </Columns>
    <Columns isMultiline style={{ justifyContent: 'center' }}>
      {!isLoadingDisposalTags && disposalTags.map(tag => <Column key={`tag_column_key_${tag.tagDescription}`} isSize={'is-narrow'} style={{ padding: 2 }}>
        <span className="tag is-small" style={{ color: '#fff', backgroundColor: tag.tagColorHash}}>#{tag.tagDescription}</span>
      </Column>)}
    </Columns>
  </div>
}

const VisitorsLitrosToolTip = ({ active, payload=[] }) => {
  if(active) {
    const data = payload[0] || { payload: {} };
    return (<div className="card notification" style={{ padding: '0px 5px 0px 5px' }}>
      <p className="heading has-text-centered" style={{ fontWeight: 'bold' }}>
        {format(data.payload.snapDate, 'DD/MM - ddd', {
          locale: pt
        })}
      </p>
      
      <span className="heading has-text-centered" style={{ color: '#1A7CBA' }}>
        <span>Litros por Visitante</span>
      </span>
      <span className="heading has-text-centered" style={{ fontWeight: 'bold', color: '#1A7CBA', fontSize: '1.2rem' }}>
        <span>{data.payload.litrosVisitantes}L</span>
      </span>
    </div>)
  }
  return null;
}

const VisitorsToolTip = ({ active, payload=[] }) => {
  if(active) {
    const data = payload[0] || { payload: {} };
    return (<div className="card notification" style={{ padding: '0px 5px 0px 5px' }}>
      <p className="heading has-text-centered" style={{ fontWeight: 'bold' }}>
        {format(data.payload.snapDate, 'DD/MM - ddd', {
          locale: pt
        })}
      </p>

      <span className="heading has-text-centered" style={{  color: '#21AE99' }}>
        <span>Visitantes</span>
      </span>
      <span className="heading has-text-centered" style={{ fontWeight: 'bold', color: '#21AE99', fontSize: '0.8rem' }}>
        <span>{data.payload.visitors}</span>
      </span>

      <span className="heading has-text-centered" style={{  color: '#1A7CBA' }}>
        <span>Sellout (litros)</span>
      </span>
      <span className="heading has-text-centered" style={{ fontWeight: 'bold', color: '#1A7CBA', fontSize: '0.8rem' }}>
        <span>{data.payload.totalLitros}L</span>
      </span>
    </div>)
  }
  return null;
}


const ShelfDash = (props) => {
  const { site } = props;

  const beginDayOfMonth =  format(subDays(getServerDate(), 30), 'YYYY-MM-DD');
  const endDayOfMonth =  format(subDays(getServerDate(), 1), 'YYYY-MM-DD');

  const [ beginPlanogramIssues, setBeginPlanogramIssues ] = useState(beginDayOfMonth);
  const [ endPlanogramIssues, setEndPlanogramIssues ] = useState(endDayOfMonth);
  
  const [ beginDisposalGeneral, setBeginDisposalGeneral ] = useState(beginDayOfMonth);
  const [ endDisposalGeneral, setEndDisposalGeneral ] = useState(endDayOfMonth);
  
  
  // KPIS do topo da pagina.
  // const [ isLoadingVisitors, dailyVisitors, dailySales ] = useDailySalesVsVisitors(site.id, beginD1, endD1);
  const [ isLoadingPlanogramIssues, planogramIssues, acceptableTimeWrong ] = usePlanogramIssues(site.id, beginPlanogramIssues, endPlanogramIssues);
  const [ isLoadingDisposal, disposalGeneral ] = useDisposalGeneral(site.id, beginDisposalGeneral, endDisposalGeneral);
  const [ isLoadingDisposalTags, disposalTags ] = useDisposalTags(site.id, beginDisposalGeneral, endDisposalGeneral);

  const [ beginDisposalTagsCompose, setBeginDisposalTagsCompose ] = useState(beginDayOfMonth);
  const [ endDisposalTagsCompose, setEndDisposalTagsCompose ] = useState(endDayOfMonth);
  const [ isLoadingDisposalTagsCompose, disposalTagsCompose ] = useTagsComposition(site.id, beginDisposalTagsCompose, endDisposalTagsCompose);

  // Graficos de linha do tempo
  //   visitantes vs vendas
  const [ beginVisitors, setBeginVisitors ] = useState(beginDayOfMonth);
  const [ endVisitors, setEndVisitors ] = useState(endDayOfMonth);
  const [ isLoadingVisitors, dailyVisitors, dailySales, dailyVisitorsVenue ] = useDailySalesVsVisitors(site.id, beginVisitors, endVisitors);
  const maxDailyVisitors = parseInt(_.max(dailyVisitors.map(v => v.visitorsCount)));
  const maxDailySales = parseInt(_.max(dailySales.map(d => d.totalLitros))) + 2;
  
  //   disposicao vs reparo vs vendas
  const [ beginDisposalVsFixRate, setBeginDisposalVsFixRate ] = useState(beginDayOfMonth);
  const [ endDisposalVsFixRate, setEndDisposalVsFixRate ] = useState(endDayOfMonth);
  const [ isLoadingSales, dVisitors, dSales ] = useDailySalesVsVisitors(site.id, beginDisposalVsFixRate, endDisposalVsFixRate);
  const [ isLoadingPlanogramIssuesTimeline, planogramIssuesTimeline ] = usePlanogramIssues(site.id, beginDisposalVsFixRate, endDisposalVsFixRate);
  const [ isLoadingDisposalTimeline, disposalGeneralTimeline ] = useDisposalGeneral(site.id, beginDisposalVsFixRate, endDisposalVsFixRate);
  const maxDSales = parseInt(_.max(dSales.map(s => s.totalLitros))) + 5;

  const [ selectedTagId, setSelectedTagId ] = useState(0);
  const [ beginProductVisitors, setBeginProductVisitors ] = useState(beginDayOfMonth);
  const [ endProductVisitors, setEndProductVisitors ] = useState(endDayOfMonth);

  const [ isLoadingProductSales, dProductVisitors, dProductSales ] = useDailySalesProductsVsVisitors(site.id, beginProductVisitors, endProductVisitors);
  const [ isLoadingTagsProducts, tagsProducts ] = useTagsProducts(site.id)

  const mashedProductSales = mashVisitorsAndProductSales(isLoadingProductSales, isLoadingProductSales, selectedTagId, dProductVisitors, dProductSales);
  const maxMashedProductSales = maxLitrosVendidosProducts(isLoadingProductSales, dProductSales);
  const maxVisitorsProductsSales = _.max(dProductVisitors.map(d => d.visitorsCount));

  // maybe util
  // const shelfs = useShelfs(site.id);
  // const tags = useTags(site.id);


  const mashedVisitors = mashVistorsAndSales(isLoadingVisitors, dailySales, dailyVisitors, dailyVisitorsVenue);
  const mashedVisitorsForDisposalAndFixRate = mashVistorsAndSales(isLoadingSales, dSales, dVisitors, dailyVisitorsVenue);
  const mashedDisposalFixRateSales = mashDisposalAndFixRateWithSales(
    isLoadingSales,
    isLoadingDisposalTimeline,
    isLoadingPlanogramIssuesTimeline,
    planogramIssuesTimeline,
    disposalGeneralTimeline,
    mashedVisitorsForDisposalAndFixRate
  );

  return (<Page>
    <PageTitle
      title={<span>
        <span style={{ marginRight: '10px' }}>
          {site.name}
        </span>
        <p className="subtitle">Ánalise de Gôndolas <FillIcon /></p>
      </span>}
    />
    <Columns isMultiline>
      <Column isSize={12}>
        <div className="tile is-ancestor">
          <div className="tile is-vertical is-12">

            <div className="tile">
              <div className="tile is-parent">
                <div className="tile is-child card notification">
                  <FixRate
                    begin={beginPlanogramIssues}
                    end={endPlanogramIssues}
                    setBegin={setBeginPlanogramIssues}
                    setEnd={setEndPlanogramIssues}
                    acceptableTimeWrong={acceptableTimeWrong}
                    isLoadingPlanogramIssues={isLoadingPlanogramIssues}
                    planogramIssues={planogramIssues}/>
                </div>
              </div>
              <div className="tile is-parent">
                <div className="tile is-child card notification">
                  <GeneralDisposal
                    siteId={site.id}
                    beginGeneralDisposal={beginDisposalGeneral}
                    endGeneralDisposal={endDisposalGeneral}
                    setBeginGeneralDisposal={setBeginDisposalGeneral}
                    setEndGeneralDisposal={setEndDisposalGeneral}
                    isLoadingDisposal={isLoadingDisposal}
                    disposalGeneral={disposalGeneral}
                    isLoadingDisposalTags={isLoadingDisposalTags}
                    disposalTags={disposalTags}
                  />
                </div>
              </div>
              <div className="tile is-parent">
                <div className="tile is-child card notification">
                  <GeneralIssuesCompose
                    begin={beginDisposalTagsCompose}
                    end={endDisposalTagsCompose}
                    setBegin={setBeginDisposalTagsCompose}
                    setEnd={setEndDisposalTagsCompose}
                    isLoadingDisposalTags={isLoadingDisposalTagsCompose}
                    disposalTags={disposalTagsCompose}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Column>
      <Column isSize={12}>
        {isLoadingVisitors &&  <div className="card notification is-white" style={{
          display:'flex',
          justifyContent: 'center'
        }}>
          <IsLoading isBorderless={true} fontSize={80}/>
        </div>}
        {!isLoadingVisitors && <div className="card notification is-white">
          <p className="subtitle is-5 has-text-centered">Visitantes vs Sellout (litros)</p>
          <ResponsiveContainer width="100%" height={250}>
            <ComposedChart
                syncId="litrosVendidosVisitantes"
                data={mashedVisitors}
                margin={{top: 20, right: 50, left: 50, bottom: 20}}>
              <CartesianGrid  stroke="#eeeeee" />
              <XAxis dataKey="snapDate" tickFormatter={(val) => `${format(val, 'DD/MM - ddd', {
                locale: pt
              })}`}/>
              <Legend />
              <YAxis domain={[0, maxDailyVisitors]} yAxisId="left" orientation="left" stroke="#343434"/>
              <YAxis domain={[0, maxDailySales]} yAxisId="right" orientation="right" stroke="#343434" unit="L"/>
              {mashedVisitors.length > 15 && <Brush 
                className="brushDisplayNone"
                startIndex={mashedVisitors.length - 15}
                dataKey='snapDate'
                height={20}
                tickFormatter={(val) => `${format(val, 'DD/MM', {locale: pt})}`}
                stroke="#363636"
              />}
              <Tooltip
                content={<VisitorsToolTip/>}
                isAnimationActive={false}/>
              <Bar
                key="visitors"
                yAxisId="left"
                name="Visitantes"
                dataKey="visitors"
                maxBarSize={20}
                fill="#21AE99"
                stroke="#21AE99"
                isAnimationActive={false}
                connectNulls={true}
              >
                <LabelList dataKey="visitors" content={(props) => {
                  const { x, y, width, value } = props;
                  const radius = 10;
                  
                  if (width < 20)
                    return null;

                  return (
                    <g>
                      <text
                        fontSize={12}
                        fontWeight="bold"
                        x={x + width / 2}
                        y={y - radius}
                        fill="#21AE99"
                        textAnchor="middle"
                        dominantBaseline="middle"
                      >
                        {value}
                      </text>
                    </g>
                  );
                }} />
              </Bar>
              <Bar
                key="sales"
                yAxisId="right"
                name="Sellout (litros)"
                dataKey="totalLitros"
                maxBarSize={20} 
                fill="#1A7CBA"
                stroke="#125580"
                isAnimationActive={false}
                connectNulls={true}
              >
                <LabelList dataKey="totalLitros" content={(props) => {
                  const { x, y, width, value } = props;
                  const radius = 10;
                  
                  if (width < 20)
                    return null;


                  return (
                    <g>
                      <text
                        fontSize={12}
                        fontWeight="bold"
                        x={x + width / 2}
                        y={y - radius}
                        fill="#1A7CBA"
                        textAnchor="middle"
                        dominantBaseline="middle"
                      >
                        {value.toFixed(1)}L
                      </text>
                    </g>
                  );
                }}/>
              </Bar>
            </ComposedChart>
          </ResponsiveContainer>

          <ResponsiveContainer width="100%" height={200}>
            <ComposedChart
                syncId="litrosVendidosVisitantes"
                data={mashedVisitors}
                margin={{top: 20, right: 50, left: 50, bottom: 20}}>
              <CartesianGrid  stroke="#eeeeee" />
              <XAxis dataKey="snapDate" tickFormatter={(val) => `${format(val, 'DD/MM - ddd', {
                locale: pt
              })}`}/>
              <Legend />
              <YAxis domain={[ 0, 25]} yAxisId="right" orientation="right" stroke="#343434" unit="L"/>
              <YAxis domain={[ 0, 25]} yAxisId="left" orientation="left" stroke="#343434" unit="L"/>
              <Tooltip
                content={<VisitorsLitrosToolTip/>}
                isAnimationActive={false}/>
              <Area
                type="monotone"
                key="litrosVisitors"
                yAxisId="left"
                name="Litros por Visitante"
                dataKey={"litrosVisitantes"}
                fill="#1A7CBA"
                stroke="#125580"
                isAnimationActive={false}
                connectNulls={false}
              />
              {mashedVisitors.length > 15 && <Brush 
                startIndex={mashedVisitors.length - 15}
                dataKey='snapDate'
                height={20}
                tickFormatter={(val) => `${format(val, 'DD/MM', {locale: pt})}`}
                stroke="#363636"
              />}
            </ComposedChart>
          </ResponsiveContainer>
          <CalendarFilter
            maxDate={addDays(getServerDate(), -1)}
            begin={beginVisitors}
            end={endVisitors}
            setBegin={setBeginVisitors}
            setEnd={setEndVisitors}
          />
        </div>}
      </Column>
      <Column isSize={12}>
        {(isLoadingSales || isLoadingDisposalTimeline || isLoadingPlanogramIssuesTimeline) &&  <div className="card notification is-white" style={{
          display:'flex',
          justifyContent: 'center'
        }}>
          <IsLoading isBorderless={true} fontSize={80}/>
        </div>}
        {!isLoadingSales && !isLoadingDisposalTimeline && !isLoadingPlanogramIssuesTimeline && <div className="card notification is-white">
          <p className="subtitle is-5 has-text-centered">Planograma vs Reparo vs Sellout (litros)</p>
          <ResponsiveContainer width="100%" height={250}>
            <ComposedChart
                syncId="disposalAndFixRate"
                data={mashedDisposalFixRateSales}
                margin={{top: 20, right: 50, left: 50, bottom: 20}}>
              <CartesianGrid  stroke="#eeeeee" />
              <XAxis dataKey="snapDate" tickFormatter={(val) => `${format(val, 'DD/MM - ddd', {
                locale: pt
              })}`}/>
              {mashedDisposalFixRateSales.length > 15 && <Brush
                className="brushDisplayNone"
                startIndex={mashedDisposalFixRateSales.length - 15}
                dataKey='snapDate'
                height={20}
                tickFormatter={(val) => `${format(val, 'DD/MM', {locale: pt})}`}
                stroke="#363636"
              />}
              <Legend />
              <YAxis domain={[ 0, 100 ]} yAxisId="left" orientation="left" stroke="#343434" unit="%"/>
              <YAxis domain={[ 0, maxDSales ]} yAxisId="right" orientation="right" stroke="#343434" unit="L"/>
              <Tooltip
                // content={<VisitorsToolTip/>}
                isAnimationActive={false}/>
              <Bar
                key="disposalRate"
                yAxisId="left"
                name="Taxa de Planograma"
                dataKey={"disposalRate"}
                maxBarSize={20}
                fill="#1944be"
                stroke="#1944be"
                isAnimationActive={false}
                connectNulls={true}
                background={{ fill: '#eeeeee' }}
              >
                <LabelList dataKey="disposalRate" content={(props) => {
                  const { x, y, width, value } = props;
                  const radius = 10;
                  
                  if (width < 20)
                    return null;

                  return (
                    <g>
                      <text
                        fontSize={12}
                        fontWeight="bold"
                        x={x + width / 2}
                        y={y - radius}
                        fill="#1944be"
                        textAnchor="middle"
                        dominantBaseline="middle"
                      >
                        {value}%
                      </text>
                    </g>
                  );
                }} />
              </Bar>
              <Bar
                key="totalLitros"
                yAxisId="right"
                name="Sellout (litros)"
                dataKey="totalLitros"
                maxBarSize={20} 
                fill="#1A7CBA"
                stroke="#125580"
                isAnimationActive={false}
                connectNulls={true}
              >
                <LabelList dataKey="totalLitros" content={(props) => {
                  const { x, y, width, value } = props;
                  const radius = 10;
                  
                  if (width < 20)
                    return null;


                  return (
                    <g>
                      <text
                        fontSize={12}
                        fontWeight="bold"
                        x={x + width / 2}
                        y={y - radius}
                        fill="#1A7CBA"
                        textAnchor="middle"
                        dominantBaseline="middle"
                      >
                        {value.toFixed(1)}L
                      </text>
                    </g>
                  );
                }}/>
              </Bar>
            </ComposedChart>
          </ResponsiveContainer>

          <ResponsiveContainer width="100%" height={250}>
            <ComposedChart
                syncId="disposalAndFixRate"
                data={mashedDisposalFixRateSales}
                margin={{top: 20, right: 50, left: 50, bottom: 20}}>
              <CartesianGrid  stroke="#eeeeee" />
              <XAxis dataKey="snapDate" tickFormatter={(val) => `${format(val, 'DD/MM - ddd', {
                locale: pt
              })}`}/>
              {mashedDisposalFixRateSales.length > 15 && <Brush
                startIndex={mashedDisposalFixRateSales.length - 15}
                dataKey='snapDate'
                height={20}
                tickFormatter={(val) => `${format(val, 'DD/MM', {locale: pt})}`}
                stroke="#363636"
              />}
              <Legend />
              <YAxis
                domain={[ 0, 100 ]}
                yAxisId="left"
                orientation="left"
                stroke="#343434"
                unit="%"/>
              <YAxis domain={[ 0, maxDSales ]} yAxisId="right" orientation="right" stroke="#343434" unit="L"/>
              <Tooltip
                // content={<VisitorsToolTip/>}
                isAnimationActive={false}/>
              <Bar
                key="fixRate"
                yAxisId="left"
                name="Taxa de Reparo"
                dataKey={"fixRate"}
                maxBarSize={20}
                fill="#178F32"
                stroke="#178F32"
                isAnimationActive={false}
                connectNulls={true}
                background={{ fill: '#eeeeee' }}
              >
                <LabelList dataKey="fixRate" content={(props) => {
                  const { x, y, width, value } = props;
                  const radius = 10;
                  
                  if (width < 20)
                    return null;


                  return (
                    <g>
                      <text
                        fontSize={12}
                        fontWeight="bold"
                        x={x + width / 2}
                        y={y - radius}
                        fill="#178F32"
                        textAnchor="middle"
                        dominantBaseline="middle"
                      >
                        {value}%
                      </text>
                    </g>
                  );
                }} />
              </Bar>
              <Bar
                key="totalLitros"
                yAxisId="right"
                name="Sellout (litros)"
                dataKey="totalLitros"
                maxBarSize={20} 
                fill="#1A7CBA"
                stroke="#125580"
                isAnimationActive={false}
                connectNulls={true}
              >
                <LabelList dataKey="totalLitros" content={(props) => {
                  const { x, y, width, value } = props;
                  const radius = 10;
                  
                  if (width < 20)
                    return null;


                  return (
                    <g>
                      <text
                        fontSize={12}
                        fontWeight="bold"
                        x={x + width / 2}
                        y={y - radius}
                        fill="#1A7CBA"
                        textAnchor="middle"
                        dominantBaseline="middle"
                      >
                        {value.toFixed(1)}L
                      </text>
                    </g>
                  );
                }}/>
              </Bar>
            </ComposedChart>
          </ResponsiveContainer>
          <CalendarFilter
            maxDate={addDays(getServerDate(), -1)}
            begin={beginDisposalVsFixRate}
            end={endDisposalVsFixRate}
            setBegin={setBeginDisposalVsFixRate}
            setEnd={setEndDisposalVsFixRate}
          />
        </div>}
      </Column>
      <Column>
        {(isLoadingProductSales || isLoadingTagsProducts) &&  <div className="card notification is-white" style={{
          display:'flex',
          justifyContent: 'center'
        }}>
          <IsLoading isBorderless={true} fontSize={80}/>
        </div>}

        {!isLoadingProductSales && !isLoadingTagsProducts && <div className="card notification is-white">
          <p className="subtitle is-5 has-text-centered">Visitantes vs Vendas Linha de Produto</p>              
          <div style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center'
          }}>
          <div className="select">
              <select value={selectedTagId} onChange={(e) => setSelectedTagId(parseInt(e.target.value))}>
                <option value={0}>Selecione uma Linha de Produto</option>
                {tagsProducts.map(tag => <option key={`linha_produto_key_${tag.tagId}`} value={tag.tagId}>{tag.tagDescricao}</option>)}
              </select>
            </div>
          </div>
          {(!selectedTagId || selectedTagId === 0) && <p className="subtitle is-3 has-text-centered" style={{ margin: 30, marginBottom: 100, marginTop: 100 }}>
            Não há Linha de Produto Selecionada
          </p>}
          {selectedTagId !== 0 && selectedTagId && <ResponsiveContainer width="100%" height={250}>
            <ComposedChart
                syncId="tagProduct"
                data={mashedProductSales}
                margin={{top: 20, right: 50, left: 50, bottom: 20}}>
              <CartesianGrid  stroke="#eeeeee" />
              <XAxis dataKey="snapDate" tickFormatter={(val) => `${format(val, 'DD/MM - ddd', {
                locale: pt
              })}`}/>
              { mashedProductSales.length > 15 && <Brush
                className="brushDisplayNone"
                startIndex={ mashedProductSales.length - 15}
                dataKey='snapDate'
                height={20}
                tickFormatter={(val) => `${format(val, 'DD/MM', {locale: pt})}`}
                stroke="#363636"
              />}
              <Legend />
              <YAxis domain={[ 0, maxVisitorsProductsSales]} yAxisId="left" orientation="left" stroke="#343434" unit=""/>
              <YAxis domain={[ 0, maxMashedProductSales ]} yAxisId="right" orientation="right" stroke="#343434" unit="L"/>
              <Tooltip
                // content={<VisitorsToolTip/>}
                isAnimationActive={false}/>
              <Bar
                key="visitors"
                yAxisId="left"
                name="Visitantes"
                dataKey={"visitors"}
                maxBarSize={20}
                fill="#21AE99"
                stroke="#21AE99"
                isAnimationActive={false}
                connectNulls={true}
              >
                <LabelList dataKey="visitors" content={(props) => {
                  const { x, y, width, value } = props;
                  const radius = 10;
                  
                  if (width < 20)
                    return null;

                  return (
                    <g>
                      <text
                        fontSize={12}
                        fontWeight="bold"
                        x={x + width / 2}
                        y={y - radius}
                        fill="#21AE99"
                        textAnchor="middle"
                        dominantBaseline="middle"
                      >
                        {value}
                      </text>
                    </g>
                  );
                }} />
              </Bar>
              <Bar
                key="totalLitros"
                yAxisId="right"
                name="Sellout (litros)"
                dataKey="totalLitros"
                maxBarSize={20} 
                fill="#1A7CBA"
                stroke="#125580"
                isAnimationActive={false}
                connectNulls={true}
              >
                <LabelList dataKey="totalLitros" content={(props) => {
                  const { x, y, width, value } = props;
                  const radius = 10;
                  
                  if (width < 20)
                    return null;


                  return (
                    <g>
                      <text
                        fontSize={12}
                        fontWeight="bold"
                        x={x + width / 2}
                        y={y - radius}
                        fill="#1A7CBA"
                        textAnchor="middle"
                        dominantBaseline="middle"
                      >
                        {value.toFixed(1)}L
                      </text>
                    </g>
                  );
                }}/>
              </Bar>
            </ComposedChart>
          </ResponsiveContainer>}
          {selectedTagId !== 0 && selectedTagId && <ResponsiveContainer width="100%" height={200}>
            <ComposedChart
                syncId="tagProduct"
                data={mashedProductSales}
                margin={{top: 20, right: 50, left: 50, bottom: 20}}>
              <CartesianGrid  stroke="#eeeeee" />
              <XAxis dataKey="snapDate" tickFormatter={(val) => `${format(val, 'DD/MM - ddd', {
                locale: pt
              })}`}/>
              <Legend />
              <YAxis domain={[ 0, 3]} yAxisId="right" orientation="right" stroke="#343434" unit="L"/>
              <YAxis domain={[ 0, 3]} yAxisId="left" orientation="left" stroke="#343434" unit="L"/>
              <Tooltip
                isAnimationActive={false}/>
              <Area
                type="monotone"
                key="litrosVisitors"
                yAxisId="left"
                name="Litros por Visitante"
                dataKey={"litrosVisitantes"}
                fill="#1A7CBA"
                stroke="#125580"
                isAnimationActive={false}
                connectNulls={false}
              />
              {mashedProductSales.length > 15 && <Brush 
                startIndex={mashedProductSales.length - 15}
                dataKey='snapDate'
                height={20}
                tickFormatter={(val) => `${format(val, 'DD/MM', {locale: pt})}`}
                stroke="#363636"
              />}
            </ComposedChart>
          </ResponsiveContainer>}
          

          {selectedTagId !== 0 && selectedTagId && <CalendarFilter
            maxDate={addDays(getServerDate(), -1)}
            begin={beginProductVisitors}
            end={endProductVisitors}
            setBegin={setBeginProductVisitors}
            setEnd={setEndProductVisitors}
          />}
        </div>}
      </Column>
    </Columns>
  </Page>)
}

ShelfDash.prototype = {
  site: t.object
}

export default ShelfDash;
