import { format } from 'date-fns';
import _ from 'lodash';
import tinycolor from 'tinycolor2';
import React, { useEffect, useState } from 'react';
import Modal from '../../../../components/Modal';
import { FillIcon } from '../../../../components/icons';
import IsLoading from '../../../../components/IsLoading';
import { get, formatAsPtBr } from '../../../../utils';
import Columns, { Column } from '../../../../components/Columns';
import { useShelfs, useTags } from '../../../../hooks/venues';


const DisposalPlanogram = (props) => {
  const { siteId, begin, end } = props;  

  const [ isLoading, setIsLoading ] = useState(false);
  const [ shelfData, setShelfData ] = useState([]);
  const [ shelfs, isLoadingShelfs ] = useShelfs(siteId);
  const [ tags, isLoadingTags ] = useTags(siteId);
  const [ beginFilter ] = useState(begin);
  const [ endFilter ] = useState(end);

  useEffect(() => {
    setIsLoading(true);
    setShelfData([]);
    get(`dashboard/shelf/sites/${siteId}/kpi/disposalvenues`, {
      begin: format(beginFilter, 'YYYY-MM-DD'),
      end: format(endFilter, 'YYYY-MM-DD')
    })
    .then(resp => {
      setShelfData(resp.data.data);
      setIsLoading(false);
    });
  }, [siteId, beginFilter, endFilter]);

  const venuesSorted = _.sortBy(Object.values(shelfs), 'venueDescription');
 
  return <div
    className="card notification is-white"
    style={{
      width: '100%'
    }}
  >
    <p className="title is-5"><FillIcon style={{ marginRight: 5 }}/> Taxa Planograma</p>
    <p className="subtitle is-5">Use os quadros e tabelas a baixo para entender o comportamento da apresentação dos produtos.</p>
    <Columns isGapless={true} style={{ marginBottom: 100 }}>
      {(isLoading || isLoadingShelfs || isLoadingTags) && <Column isSize={12} style={{
        display: 'flex',
        justifyContent: 'center',
        alignItens: 'center'
      }}>
        <IsLoading fontSize={80} backgroundColor="#ffffff" isBorderless={true} />
      </Column>}
      {!isLoading && !isLoadingShelfs && !isLoadingTags && <Column isSize={2}>
          <div className="table-container">
            <table className="table" style={{
              width:'100%',
              borderRight: '1px solid #cccccc'
            }}>
              <thead>
                <tr>
                  <th>Tags</th>
                  <th><abbr title="Prateleira">Prat</abbr></th>
                </tr>
              </thead>
              <tbody>
                {venuesSorted.map(shelf => <tr key={`shelf_row_theader_days_${shelf.venueId}`}>
                  <td className="tag-tooltip">
                    <div
                      style={{
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'wrap'
                    }}>
                      {shelf.tags.map(tag => {
                        const t = tags[tag];
                        return <div key={`tag_inside_row_${t.id}`}
                        style={{
                          border: '1px solid #eee',
                          margin: 3,
                          backgroundColor: t.color,
                          width: 10,
                          height: 10
                        }}>
                        </div>
                      })}
                      <div className="tag-tooltip-to-show card notification is-white">
                        {shelf.tags.map(tag => {
                          const t = tags[tag];
                          return <div key={`tag_inside_row__description_${t.id}`}
                          className="tag is-small"
                          style={{
                            border: '1px solid #eee',
                            backgroundColor: t.color,
                            color: tinycolor(t.color).isDark() ? '#ffffff' : '#222222',
                          }}>
                            #{t.description}
                          </div>
                        })}
                      </div>
                    </div>
                  </td>
                  <td>{shelf.venueDescription}</td>
                </tr>)}
              </tbody>
            </table>
          </div>
        </Column>}
        {!isLoading && !isLoadingShelfs && !isLoadingTags && <Column isSize={10}>
          <div className="table-container">
            <table className="table">
              <thead>
                <tr>
                  {shelfData.map(day => <th>
                    <div>
                      {formatAsPtBr(day.snapDate, 'DD/MM')}
                    </div>
                  </th>)}
                </tr>
              </thead>
              <tbody>
                {venuesSorted.map(shelf => <tr key={`shelf_row_tbody_days_${shelf.venueId}`}>
                  {shelfData.map(day => {
                    const disposalRate = day.venues ?
                      day.venues[shelf.venueId] ? day.venues[shelf.venueId].disposalRate : 0
                      : 0;

                    return <th style={{
                      backgroundColor: `rgba(25, 68, 190, ${(disposalRate/100).toFixed(2)})`,
                      color: disposalRate < 30 ? '#363636' : '#ffffff',
                      textAlign: 'center',
                      justifyContent: 'center'
                    }}>{disposalRate}%</th>
                  })}
                </tr>)}
              </tbody>
            </table>
          </div>
        </Column>}
      </Columns>
    </div>
}


const DisposalPlanogramModal = (props) => {
  const { siteId, begin, end } = props;
  const [ isOpen, setIsOpen ] = useState(false);

  const _toogle = () => {
    setIsOpen(!isOpen);
  }

  if (!isOpen) {
    return <span
      className="button is-outlined is-small"
      onClick={_toogle}>
      Detalhar
    </span>;
  }

  return <Modal
    style={{ width: '97%', minHeight: '80%' }}
    handleToogleModal={_toogle}
    component={<DisposalPlanogram
      siteId={siteId}
      begin={begin}
      end={end}
    />}
  />
}

export default DisposalPlanogramModal;