import React, { Component } from 'react';

import { post, put } from '../../../../../utils';
import * as Icons from '../../../../../components/icons';
import notification from '../../../../../utils/notification';

class VenueForm extends Component {

  constructor(props) {
    super(props);

    const {
      description='',
      isVisitor=true,
      isPassersBy=false,
      isVenue=false,
      isShelf=false,
      isCheckoutQueue=false
    } = props.venue || {};

    this.state = {
      fields: {
        description,
        isVisitor,
        isPassersBy,
        isVenue,
        isShelf,
        isCheckoutQueue,
      },
      isSaving: false
    }
  }

  componentWillReceiveProps(nextProps) {
    if(this.props.venue) {

      if(nextProps.venue.id !== this.props.venue.id) {
        const {
          description='',
          isVisitor=false,
          isPassersBy=false,
          isVenue=false,
          isShelf=false,
          isCheckoutQueue
        } = nextProps.venue;
        this.setState({
          fields: {
            description,
            isVisitor,
            isPassersBy,
            isShelf,
            isVenue,
            isCheckoutQueue
          },
          isSaving: false
        });
      }

    }
  }

  _onChange = (e) => {
    const {fields} = this.state;
    const {value, name} = e.target;

    fields[name] = value;
    this.setState({ fields });
  }

  _onChangeSelect = (e) => {
    e.persist();
    const { value } = e.target;
    const { fields } = this.state;

    fields.isPassersBy = value === 'isPassersBy';
    fields.isVisitor = value === 'isVisitor';
    fields.isVenue = value === 'isVenue';
    fields.isShelf = value === 'isShelf';
    fields.isCheckoutQueue = value === 'isCheckoutQueue';

    this.setState({ fields });
  }

  _submitForm = (e) => {
    e.preventDefault();

    const {isSaving, fields} = this.state;
    if(isSaving)
      return;

    this.setState({ isSaving: true }, () => {
      const { siteId, venue } = this.props;
      const { description, isVisitor, isPassersBy, isVenue, isShelf, isCheckoutQueue } = fields;

      if(venue && venue.id) {
        put(`admin/videos/sites/${siteId}/venues/${venue.id}`, {
          description,
          isVisitor,
          isPassersBy,
          isVenue,
          isShelf,
          isCheckoutQueue
        })
          .then((resp) => {
            notification.success('Dados salvos com sucesso!')
            this.setState({ isSaving: false }, () => {
              this.props.handleSubmit(resp.data.data);
            });
          });

        return;
      }

      post(`admin/videos/sites/${siteId}/venues`, {
        description,
        isVisitor,
        isPassersBy,
        isVenue,
        isShelf,
        isCheckoutQueue
      })
        .then(resp => {
          notification.success('Dados salvos com sucesso!')
          this.setState({
            fields: {
              description: ''
            },
            isSaving: false
          }, () => this.props.handleSubmit(resp.data.data));
        });
    });
  }

  render() {
    const { venue={} } = this.props;
    const { isSaving, fields } = this.state;
    const fieldValue = fields.isVisitor ? 'isVisitor'
      : fields.isPassersBy ? 'isPassersBy'
      : fields.isVenue ? 'isVenue'
      : fields.isShelf ? 'isShelf' 
      : fields.isCheckoutQueue ? 'isCheckoutQueue' : '';

    return (<form onSubmit={this._submitForm}>
      <div className="field is-grouped is-small">
        <span className="control has-icons-left has-icons-right is-expanded">
          <input
            onChange={this._onChange}
            value={fields.description}
            name="description"
            className="input"
            type="text"
            placeholder="Descrição venue"
            required
          />
          <Icons.MapMarkedIcon className="is-small is-left"/>
        </span>
      </div>

      <div className="field is-grouped is-small">
        <span className="control has-icons-left is-expanded">
          <span className="select is-fullwidth">
            <select onChange={this._onChangeSelect} value={fieldValue}>
              {fieldValue === '' && <option value={''}>Não informado</option>}
              <option value={'isVisitor'}>Visitante</option>
              <option value={'isPassersBy'}>Passante</option>
              <option value={'isVenue'}>Área</option>
              <option value={'isShelf'}>Prateleira</option>
              <option value={'isCheckoutQueue'}>Fila</option>
            </select>
          </span>
          <Icons.WalkingIcon className="is-left"/>
        </span>

        {venue.id && <span className="control">
          {fields.description !== this.props.venue.description
          || fields.isVisitor !== this.props.venue.isVisitor
          || fields.isPassersBy !== this.props.venue.isPassersBy
          || fields.isVenue !== this.props.venue.isVenue
          || fields.isShelf !== this.props.venue.isShelf
          || fields.isCheckoutQueue !== this.props.venue.isCheckoutQueue
            ? <button
              type="submit"
              className={`button is-primary is-outlined ${isSaving ? 'is-loading' : ''}`}
            >
              <Icons.MapMarkedIcon />
              <span>Salvar venue</span>
            </button>
            : <button
              type="submit"
              className='button is-static '
            >
              <Icons.CheckIcon />
              <span>Salvo</span>
            </button>}
        </span>}

        {!venue.id && <span className="control">
          <button type="submit" className={`button is-primary ${isSaving ? 'is-loading' : ''}`}>
            <Icons.MapMarkedIcon />
            <span>Salvar</span>
          </button>
        </span>}
      </div>
    </form>);
  }
}

export default (VenueForm);



        
