import React, { Component } from 'react';

import { connect } from 'react-redux';
import withRouter from "react-router-dom/withRouter";
import _ from 'lodash';
import {
  subDays
} from 'date-fns';

import {pt} from 'react-date-range/src/locale';

import { DateRangePicker } from 'react-date-range';

import { defaultStaticRanges } from '../filter/defaults';

import Select from 'react-select';

import { FilePDFIcon, ExcelFileIcon } from '../../../../components/icons';
import Page, { PageTitle } from '../../../../components/Page';
import Columns, { Column } from '../../../../components/Columns';
import DownloadButton from '../../../../components/DownloadButton';
import { getServerDate } from '../../../../utils';

class ReportScheduler extends Component {

  constructor(props) {
    super(props); 

    const startDate = subDays(getServerDate(), 7);
    const endDate = subDays(getServerDate(), 1);

    let siteToSelect = props.sites.map(site => {return {value: site.id, label: site.name}})

    this.state = {
      ranges: {
        startDate,
        endDate,
        key: 'ranges'
      },
      checkFields: {
        passers_daily: true,
        passers_hourly: true,
        sales_daily: false,
        visitors_returns: false,
        visitors_venue: false
      },
      selectFields: {
        excelPdf: 'pdf'
      },
      siteToSelect,
      sitesSelected:  [{value: props.site.id, label: props.site.name}]
    }
  }

  _selectDateRange = params => {
    const { ranges } = this.state;
    ranges['startDate'] = params.ranges.startDate;
    ranges['endDate'] = params.ranges.endDate;
    this.setState({ ranges });
  }

  _handleCheck = e => {
    const { id, checked } = e.target;
    const { checkFields } = this.state;
    checkFields[id] = checked;
    this.setState({ checkFields });
  }

  _handleSelect = e => {
    const { value } = e.target;
    const { selectFields } = this.state;

    selectFields['excelPdf'] = value;
    this.setState({ selectFields });
  }
  _onChange = (value, {action, removedValue}) => {
    const{site} = this.props;
    if(removedValue && removedValue.value === site.id){
      return
    }
    else if(value && action !== 'clear'){
      this.setState({ sitesSelected: value });
    }
    else {
      this.setState({ sitesSelected: [{value: site.id, label: site.name}] });
    }
  } 
  
  render() {
    const { ranges, checkFields, selectFields, sitesSelected,  siteToSelect} = this.state;
    let { site, video } = this.props;

    const styles = {
      multiValue: (base, state) => {
        return state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base;
      },
      multiValueLabel: (base, state) => {
        return state.data.isFixed
          ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
          : base;
      },
      multiValueRemove: (base, state) => {
        return state.data.isFixed ? { ...base, display: 'none' } : base;
      },
    };

    var metrics = _.transform(checkFields, (result, value, key) => {
      if(value){
        result.push(key)
      }
    } , []).join(',');
    if(metrics === ''){
      metrics = undefined;
    }

    const siteToSelectStr = sitesSelected.map(v => v.value).join(',')
    const isExcel = selectFields.excelPdf === 'excel'
    return <Page>
    <PageTitle
      title={<span>
        <span style={{ marginRight: '10px' }}>
          {`${(site.name) || ''} `}
        </span>
        <p className="subtitle">Relatórios <FilePDFIcon /></p>
      </span>}
    />        
      <Columns isMultiline>
        <Column isSize={9}>
          <div className="card notification is-white has-text-centered">
            <Columns isMultiline>
              <Column isSize={8}>
                <p className="title is-5">Defina o período</p>
                <DateRangePicker
                  months={1}
                  direction='horizontal'
                  maxDate={subDays(getServerDate(), 1)}
                  scroll={{ enabled: false }}
                  rangeColors={['#363740']}
                  dragSelectionEnabled={true}
                  locale={pt}
                  ranges={[ranges]} 
                  inputRanges={[]}
                  showPreview={false}
                  staticRanges={defaultStaticRanges}
                  onChange={this._selectDateRange}
                />
              </Column>
              <Column isSize={4}>
                <p className="title is-5">Parâmetros</p>
                <div className="field has-text-left">
                  <div className="control">
                    <label htmlFor='passers_daily' className="checkbox">
                      <input checked={this.state.checkFields.passers_daily} onChange={this._handleCheck} id='passers_daily' type="checkbox" />
                      <span> Passantes e Visitantes</span>
                    </label>
                  </div>
                </div>
                <div className="field has-text-left">
                  <div className="control">
                    <label htmlFor='passers_hourly' className="checkbox">
                      <input checked={this.state.checkFields.passers_hourly} onChange={this._handleCheck} id='passers_hourly' type="checkbox" />
                      <span> Passantes e Visitantes Hora Hora </span>
                    </label>
                  </div>
                </div>
                {site.modules.sales && <div className="field has-text-left">
                  <div className="control">
                    <label htmlFor='sales_daily' className="checkbox">
                      <input checked={this.state.checkFields.sales_daily} onChange={this._handleCheck} id='sales_daily' type="checkbox" />
                      <span> Visitantes e Vendas</span>
                    </label>
                  </div>
                </div>}
                {site.modules.loyalty && <div className="field has-text-left">
                  <div className="control">
                    <label htmlFor='visitors_returns' className="checkbox">
                      <input checked={this.state.checkFields.visitors_returns} onChange={this._handleCheck} id='visitors_returns' type="checkbox" />
                      <span> Fidelidade </span>
                    </label>
                  </div>
                </div>}
                {/* {site.modules.venues && <div className="field has-text-left">
                  <div className="control">
                    <label htmlFor='visitors_venue' className="checkbox">
                      <input checked={this.state.checkFields.visitors_venue} onChange={this._handleCheck} id='visitors_venue' type="checkbox" />
                      <span> Atratividade </span>
                    </label>
                  </div>
                </div>} */}
                <div className="field has-text-left">
                  <label className="label">
                    Formato do arquivo:
                  </label>
                  <div className="control is-expanded has-icons-left">
                    <div className="select is-fullwidth">
                      <select onChange={this._handleSelect} value={this.state.selectFields.excelPdf}>
                        <option value='pdf'>PDF</option>
                        <option value='excel'>Excel</option>
                      </select>
                    </div>
                    {this.state.selectFields.excelPdf === 'excel' ? <ExcelFileIcon className="is-left has-text-success"/> : <FilePDFIcon  className="is-left has-text-danger"/>}
                  </div>
                </div>
                <div className="field has-text-left">
                  <label className="label">
                    Lojas:
                  </label>
                  <div className="control is-expanded has-icons-left">
                    <div className="control is-expanded" style={{width: '90%'}}>
                      <Select  
                        value={sitesSelected}
                        options={siteToSelect}
                        closeMenuOnSelect={false}
                        onChange={this._onChange} 
                        styles={styles}
                        isMulti 
                        name="colors"
                        placeholder="Lojas"
                        className="basic-multi-select"
                        classNamePrefix="select"/>
                    </div>
                  </div>
                </div>
              </Column>
            </Columns>
            <div className="field is-grouped is-grouped-left">
              <p className="control">
                <DownloadButton site={this.props.site} is='is-primary' label='Baixar' name="general" begin={ranges.startDate} end={ranges.endDate} excel={isExcel} sitesObj={sitesSelected} params={{metrics, sites: siteToSelectStr}} video={video}/>
              </p>
            </div>
          </div>
        </Column>
      </Columns>
    </Page> 
  }
}


function mapStateToProps(state, props) {
  const currentGroup = state.sites.currentGroup;
  const sites = _.toArray({ ...state.sites.all })
    .filter(site => site.group.id === currentGroup)
    .filter(site => site.isActive)
    .sort((a, b) => a.name.localeCompare(b.name));

  const isSuvinil = props.site.modules.isSuvinil;

  return { site: props.site,
           sites: sites.filter(s => isSuvinil ? s.id === props.site.id : true) }
}

export default withRouter(connect(mapStateToProps)(ReportScheduler));