import React, { Component } from 'react';

import { withRouter } from "react-router-dom";
import {
  connect
} from 'react-redux';

import Page, { PageTitle } from '../../../../components/Page';
import { format } from 'date-fns'
import { pt } from 'react-date-range/src/locale';

import { DateRange } from 'react-date-range';

import Columns, { Column } from '../../../../components/Columns';
import * as Icons from '../../../../components/icons';
import { subDays } from 'date-fns';

import { defaultStaticRanges } from '../filter/defaults';

import Select from 'react-select'
import { get, getServerDate, post } from '../../../../utils';

class DedoDuro extends Component {

  constructor(props) {
    super(props);

    const startDate = getServerDate();
    const endDate = getServerDate();

    this.state = {
      isLoading: false,
      downloading: false,
      users: [],
      ranges: {
        startDate,
        endDate,
        key: 'ranges'
      },
      selectUsers: [],
      data: []
    }
  }
  componentDidMount() {
    get(`admin/tooq/users?siteId=${this.props.site.id}`)
      .then(resp => resp.data.data)
      .then(userArray => {
        const selectUsers = userArray.map(({ id, displayName }) => {
          return { value: id, label: displayName }
        })
        this.setState({ selectUsers }, () => {
          this.onSearchClick()
        })
      })
      .catch(err => {
        console.log(err);
      })
  }

  onSearchClick = () => {
    const { ranges, users, isLoading } = this.state;
    const { site } = this.props
    if (isLoading) {
      return;
    }
    const dateIni = ranges.startDate;
    const dateFim = ranges.endDate;
    this.setState({ isLoading: true }, () => {
      post('reports/dedoduro', { siteId: site.id, users, dateIni, dateFim })
        .then(resp => {
          const data = resp.data.data
          this.setState({ data, isLoading: false })
        })
    })
  }

  handleChange = (newValue) => {
    if (newValue) {
      const userIds = newValue.map(val => val.value)
      this.setState({ users: userIds })
    } else {
      this.setState({ users: [] })
    }
  };
  _downloadPdf = () => {

    const { ranges, users, downloading } = this.state;
    const { site } = this.props;
    if (downloading)
      return;

    this.setState({ downloading: true }, () => {
      const siteId = site.id;
      get('reports/johann/dedoduro', {
        siteId: siteId,
        users: users.join(','),
        dateBegin: format(ranges.startDate, 'YYYY-MM-DD'),
        dateEnd: format(ranges.endDate, 'YYYY-MM-DD')
      }, { responseType: 'blob' }).then((response) => {
        const { site } = this.props;
        const begin = format(ranges.startDate, 'DDMMYY');
        const end = format(ranges.endDate, 'DDMMYY');

        const fileName = `JENNY-TH-${site.name}-${begin}${end}.pdf`;
        const url = window.URL.createObjectURL(response.data)
        const a = document.createElement('a')
        a.href = url
        a.target = '_blank';
        a.download = fileName;
        a.click();
        a.remove();

        setTimeout(() => {
          this.setState({
            downloading: false,
            erroNoDownload: ''
          }, () => window.URL.revokeObjectURL(url));
        }, 100);
      })
        .catch(err => {
          console.log(err);
          this.setState({
            downloading: false,
            erroNoDownload: 'Não foi possível baixar o arquivo tente novamente mais tarde.'
          })
        });
    });
  }
  _selectDateRange = params => {
    const { ranges } = this.state;
    ranges['startDate'] = format(params.ranges.startDate, 'YYYY-MM-DD');
    ranges['endDate'] = format(params.ranges.endDate, 'YYYY-MM-DD');
    this.setState({ ranges });
  }

  render() {
    const { site, group } = this.props;
    const { ranges, isLoading, data, selectUsers, downloading } = this.state;
    return (
      <Page>
        <PageTitle
          title={<span>

            <p className="title">{group.name} - {site.name}</p>
            <p className="subtitle"><Icons.HandPointRightIcon /> Trilha de Auditoria</p>
          </span>}
        >
          {(site.id === 17) && <span className={`button is-dark is-outlined ${downloading ? 'is-loading' : ''}`} onClick={this._downloadPdf}>
            <span className="icon is-small">
              <i className="far fa-file-alt"></i>
            </span>
            <span>
              Relatório
            </span>
          </span>}
        </PageTitle>
        <Columns isMultiline>
          <Column isSize={12}>
            <Columns>
              <Column isSize={4}>
                <div className="card notification is-white ">
                  <div className="field">
                    <p className="title is-5" style={{ marginBottom: 10 }}>Usuários</p>
                    <div className="control is-expanded" style={{ width: '90%' }}>
                      <Select options={selectUsers}
                        onChange={this.handleChange}
                        isMulti
                        name="colors"
                        placeholder="Usuários"
                        className="basic-multi-select"
                        classNamePrefix="select" />
                    </div>
                  </div>

                  <p className="title is-5" style={{ marginBottom: 10 }}>Defina o período</p>
                  <div className="has-text-centered">
                    <DateRange
                      months={1}
                      direction='horizontal'
                      minDate={subDays(getServerDate(), 365)}
                      maxDate={getServerDate()}
                      scroll={{ enabled: false }}
                      rangeColors={['#363740']}
                      dragSelectionEnabled={true}
                      locale={pt}
                      ranges={[ranges]}
                      inputRanges={[]}
                      showPreview={false}
                      staticRanges={defaultStaticRanges}
                      onChange={this._selectDateRange}
                    />
                  </div>
                  <p><button type="button " onClick={this.onSearchClick} className={`button is-dark ${isLoading ? 'is-loading' : ''}`} >Pesquisar</button></p>
                </div>
              </Column>
              <Column isSize={8}>

                <div className="card notification is-white ">
                  {isLoading ?
                    <div className="has-text-centered" style={{ height: 275, paddingTop: '80px' }}>
                      <span>
                        <span className="button is-loading is-large is-bordless" />
                        <p className="subtitle is-5">Aguarde, carregando...</p>
                      </span>
                    </div> :
                    <div>
                      {data.length === 0 ?
                        <div className="has-text-centered" style={{ height: 110, paddingTop: '45px' }}>
                          <span>
                            <p className="subtitle is-5">Não há informações com os parametros especificados. Realize a consulta novamente.</p>
                          </span>
                        </div> :
                        <table className="table is-striped is-hoverable is-fullwidth" style={{ width: '100' }} >
                          <thead>
                            <tr>
                              <th>Usuário</th>
                              <th style={{ maxWidth: 300 }}>Descrição</th>
                              <th>Ação</th>
                              <th>Data</th>
                              <th>Hora</th>
                            </tr>
                          </thead>
                          <tbody style={{ overflowY: 'scroll' }}>
                            {data.map(({ user_name, description, action, insert_date, insert_time }, index) => {
                              return (
                                <tr key={`dedo-duro-item-${index}`}>
                                  <td>{user_name}</td>
                                  <td style={{ maxWidth: 300 }}>{description}</td>
                                  <td>{action}</td>
                                  <td>{insert_date}</td>
                                  <td>{insert_time}</td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      }
                    </div>
                  }
                </div>
              </Column>
            </Columns>
          </Column>
        </Columns>
      </Page>
    )
  }
}

function mapStateToProps(state, props) {
  return {
    user: state.app.user
  }
}

export default withRouter(connect(mapStateToProps)(DedoDuro));