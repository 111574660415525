import React, { Component } from 'react';
import {
  withRouter,
  Link
} from "react-router-dom";

import format from 'date-fns/format';

import Columns, { Column } from '../Columns';
import SensorCard from '../../containers/board/dashboard/johann/home/SensorCard';

class SiteIndice extends Component {

  constructor(props) {
    super(props);
    this.state = {
      toogleJohann: false
    }
  }

  _toogleJohann = () => this.setState({ toogleJohann: !this.state.toogleJohann });

  render() {
    const { id, name, modules, userPermissions, foottrafficData, johannData=[] } = this.props.site;
 
    if(userPermissions.permission === 3) {
      return <div className="card" style={{
        borderRadius: '5px 5px 5px 5px',
        borderColor: '#0043ff'
      }}>
        <div className="card-header has-text-centered" style={{ border: 'none', borderRadius: '5px 5px 0px 0px' }}>
          <p className="title is-4" style={{ width: '100%', padding: '10px' }}>{name}</p>
        </div>
      
        <div className="card-footer has-text-centered" style={{
          borderRadius: '0px 0px 5px 5px'
        }}>
          <Link className="card-footer-item help heading cursor-pointer" to={`/board/sites/${id}/dashboard/foottraffic/home`} style={{ width: '100%', textDecoration: 'underline' }}>
            ver mais
          </Link>
        </div>
      </div>
    }


    return(<div className="card" style={{ borderRadius: '5px 5px 5px 5px', borderColor: '#0043ff' }}>
      <div className="card-header has-text-centered" style={{ border: 'none', borderRadius: '5px 5px 0px 0px' }}>
        <p className="title is-4" style={{ width: '100%', padding: '10px' }}>{name}</p>
      </div>

      {modules.foottraffic && foottrafficData && <div className="card-content">
        <Columns isMultiline>
          <Column style={{paddingTop: 0}} isSize={12} className="has-text-centered">
            <p className="help heading">ULTIMA POSIÇÃO EM</p>
            <p className="subtitle is-3">{format(foottrafficData.position.snapTime, 'DD/MM/YY')}</p>
          </Column>
          {/* <Column isSize={6} className="has-text-centered">
            <p className="subtitle is-6 heading" style={{ marginBottom: 0 }}>Taxa de visitantes</p>
            <p className="subtitle is-1" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <span
                className={`tag ${foottrafficData.variation.visitorsRate > 0 ? 'is-success' : 'is-danger'}`}
                style={{ marginRight: 10, fontSize: 20 }}>
                  {foottrafficData.variation.visitorsRate > 0 ? '+' : ''} {foottrafficData.variation.visitorsRate}%
              </span>
              {foottrafficData.position.visitorsRate}%
            </p>
          </Column> */}
          <Column style={{paddingTop: 0}} isSize={12} className="has-text-centered">
            <p className="subtitle is-6 heading" style={{ marginBottom: 0 }}>Taxa de vendas</p>
            <p title="Percentual de pessoas que passaram em frente a loja e entraram" className="subtitle is-2" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <span
                className={`tag ${foottrafficData.variation.salesRate > 0 ? 'is-success' : 'is-danger'}`}
                style={{ marginRight: 10, fontSize: 20 }}
              >
                {foottrafficData.variation.salesRate > 0 ? '+' : ''} {foottrafficData.variation.salesRate}%
              </span>
              {foottrafficData.position.salesRate}%
            </p>
          </Column>
          <Column style={{paddingTop: 0, paddingBottom:0}} isSize={12}>
            <p title="Percentual de vendas realizadas em comparação com o número de pessoas que entraram na loja" className="help has-text-centered">Variação em relação ao mesmo dia na semana passada <b>{foottrafficData.sameDayAtlastWeek && format(foottrafficData.sameDayAtlastWeek.snapTime, 'DD/MM/YY')}</b> </p>
          </Column>
          {modules.johann && <Column isSize={12}>
            <p className="help heading has-text-centered cursor-pointer" style={{ textDecoration: 'underline' }} onClick={this._toogleJohann}>
              {this.state.toogleJohann ? 'Ocultar sites de temperatura' : 'Mostrar sites de temperatura'}
            </p>
          </Column>}
          {modules.johann && this.state.toogleJohann && <Column isSize={12}>
            <Columns isMultiline isGapless>
              {johannData.map(johann => <Column key={johann.id}>
                <SensorCard siteId={id} venue={johann}/>
              </Column>)}
            </Columns>
          </Column>}
        </Columns>
      </div>}

      {modules.johann && <Columns isMultiline isGapless>
        {johannData.map(johann => <Column isSize={12} key={johann.id} style={{ padding: '15px' }}>
          <div style={{ padding: '10px' }}>
            <SensorCard siteId={id} venue={johann}/>
          </div>
        </Column>)}
      </Columns>}

      {!modules.foottraffic && !modules.johann && !modules.queues && !modules.video && !modules.isAmbev && <Columns isMultiline>
        <Column isSize={12} className="has-text-centered">
          <p className="subtitle is-5">Loja sem modulo configurado.</p>
        </Column>
      </Columns>}
      
      {modules.isAmbev && <div className="card-footer has-text-centered" style={{ borderRadius: '0px 0px 5px 5px' }}>
        {userPermissions.permission < 1 && <Link className="card-footer-item help heading cursor-pointer" to={`/board/sites/${id}/dashboard/ambev/settings`} style={{ width: '100%', textDecoration: 'underline' }}>
          Editar
        </Link>}
        <Link className="card-footer-item help heading cursor-pointer" to={`/board/sites/${id}/dashboard/ambev/home`} style={{ width: '100%', textDecoration: 'underline' }}>
          ver mais
        </Link>
      </div>}

      {modules.foottraffic && <div className="card-footer has-text-centered" style={{ borderRadius: '0px 0px 5px 5px' }}>
        {userPermissions.permission < 1 && <Link className="card-footer-item help heading cursor-pointer" to={`/board/sites/${id}/admin/config`} style={{ width: '100%', textDecoration: 'underline' }}>
          Editar
        </Link>}
        <Link className="card-footer-item help heading cursor-pointer" to={`/board/sites/${id}/dashboard/foottraffic/home`} style={{ width: '100%', textDecoration: 'underline' }}>
          ver mais
        </Link>
      </div>}
    </div>)
  };
}

export default withRouter(SiteIndice);