import React from 'react';
import _ from 'lodash';

import Columns, { Column } from '../../../../components/Columns';
import { get, post, del } from '../../../../utils';

class VideoVenueSettings extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoadingVenues: false,
      selectedVenue: {},
      venues: [],
      savedVertices: [],
      heatmaps: [],
      isLoadingHeatmaps: false,
      mouseOuverVenue: {},
      vertices: [],
      currentHeatmapDrawing: 0
    }
  }

  componentDidMount() {
    this._loadVenues();
    this._loadHeatmaps();
  }

  _loadVenues = () => {
    const { id } = this.props.site;
    this.setState({ isLoadingVenues: true }, () => {
      get(`admin/videos/sites/${id}/venues`)
        .then(resp => {
          const venues = resp.data.data;
          this.setState({ venues, isLoadingVenues: false });
        });
    });
  }

  _loadHeatmaps = () => {
    const { id } = this.props.site;
    this.setState({ isLoadingHeatmaps: true }, () => {
      get(`admin/videos/sites/${id}/heatmap`)
        .then(resp => {
          const heatmaps = resp.data.data;
          this.setState({ heatmaps, isLoadingHeatmaps: false });
        });
    });
  }

  _onChangeVenue = (e) => {
    e.preventDefault();
    const { value } = e.target;
    const { venues } = this.state;

    const venue = venues.find(venue => venue.id === parseInt(value));
    this.setState({ selectedVenue: venue });
  }

  _isDrawClosed = () => {
    const { vertices } = this.state;
    const endVertice = vertices.find(vertice => vertice.end);
    return !!endVertice;
  }


  _cleanDraw = () => {
    this.setState({
      vertices: []
    });
  }

  _saveDraw = () => {
    const {
      id
    } = this.props.site;

    const {
      currentHeatmapDrawing,
      selectedVenue,
      vertices,
      isSavingHeatmapPath
    } = this.state;

    if(!this._isDrawClosed())
      return;

    if(isSavingHeatmapPath)
      return;

    this.setState({ isSavingHeatmapPath: true }, () => {
      const stringVertices = vertices.map(vertice => `${vertice.x},${vertice.y}`).join(' ');
      post(`admin/videos/sites/${id}/heatmap/${currentHeatmapDrawing}/venuepath`, {
        venueId: selectedVenue.id,
        poligonsPoints: stringVertices
      })
      .then(resp => {
        const {heatmaps} = this.state;
        const pathId = resp.data.data.id;
        this.setState({
          heatmaps: heatmaps.map(heatmap => {
            if(heatmap.id === currentHeatmapDrawing) {
              return {
                ...heatmap,
                venues: [
                  ...heatmap.venues,
                  {
                    pathId,
                    venueId: selectedVenue.id,
                    venueDescription: selectedVenue.description,
                    venuePaths: stringVertices
                  }
                ]
              } 
            }
            return heatmap;
          }),
          isSavingHeatmapPath: false,
          vertices: []
        });
      });
    });
  }

  handleMouseDown = (heatmapId) => (evt) => {
    const { currentHeatmapDrawing } = this.state;
    evt.persist();

    if(currentHeatmapDrawing !== 0 && currentHeatmapDrawing !== heatmapId) {
      return;
    }

    const e = evt.target;
    if(e.localName !== 'svg' && e.localName !== 'image')
      return;

    const dim = e.getBoundingClientRect();
    const x = evt.clientX - dim.left;
    const y = evt.clientY - dim.top;
    const { vertices, selectedVenue } = this.state;

    if(this._isDrawClosed())
      return;

    if(!selectedVenue.id)
      return;

    this.setState({
      currentHeatmapDrawing: heatmapId,
      vertices: [
        ...vertices,
        { x, y }
      ]
    });
  };

  _undoLastVertice = () => {
    const { vertices } = this.state;
    vertices.pop();
    this.setState({ vertices });
  }

  _onMouseClickVenuePath = (heatmapId, venue) => (e) => {
    this.setState({
      selectedVenue: {
        heatmapId: heatmapId,
        pathId: venue.pathId,
        id: venue.venueId,
        description: venue.venueDescription
      }
    })
  }

  endDraw = (x, y) => (e) => {
    const { vertices } = this.state;

    const firstPoint = _.first(vertices);
    if(firstPoint.x !== x || firstPoint.y !== y) {
      return;
    }

    this.setState({
      vertices: [
        ...vertices,
        { x, y, end: true }
      ]
    })
  }

  _deletePath = () => {
    const {
      id
    } = this.props.site;

    const {
      selectedVenue,
      isRemovingPath
    } = this.state;

    if(isRemovingPath)
      return;

    this.setState({ isRemovingPath: true }, () => {
      del(`admin/videos/sites/${id}/heatmap/${selectedVenue.heatmapId}/venuepath/${selectedVenue.pathId}`)
      .then(resp => {
        const {
          heatmaps
        } = this.state;

        this.setState({
          isRemovingPath: false,
          heatmaps: heatmaps.map(heatmap => {
            if (heatmap.id !== selectedVenue.heatmapId)
              return heatmap;
            
            return {
              ...heatmap,
              venues: heatmap.venues.filter(venue => venue.pathId !== selectedVenue.pathId)
            }
          })
        });
      });
    })
  }

  render() {

    const {
      selectedVenue,
      isLoadingVenues,
      venues,
      vertices,
      heatmaps,
      isLoadingHeatmaps,
      isSavingHeatmapPath,
      isRemovingPath
    } = this.state;

    return (<Columns isMultiline>
      <Column isSize={4}>
        <div className={`field has-addons`}>
          <div className="control is-expanded">
            <div className={`select is-fullwidth`}>
              <select value={selectedVenue.id} onChange={this._onChangeVenue}>
                {venues.length === 0 && isLoadingVenues && <option value={0}>Aguarde carregando...</option>}
                {venues.length === 0 && !isLoadingVenues && <option value={0}>Não há cameras cadastradas</option>}
                {venues.length > 0 && <option value={0}>Selecione uma area e desenhe</option>}
                {venues.map(venue => <option
                  key={`venue-device-${venue.id}`}
                  value={venue.id}>
                  {venue.description}
                </option>)}
              </select>
            </div>
          </div>
        </div>
      </Column>
      <Column isSize={12}>
        {this._isDrawClosed() && <p className="buttons">
          <span
            className={`button is-primary ${isSavingHeatmapPath ? 'is-loading' : ''}`}
            onClick={this._saveDraw}
          >
            Salvar desenho
          </span>
          <span
            className="button is-danger is-outlined"
            onClick={this._cleanDraw}
          >
            Limpar desenho atual
          </span>
        </p>}
      </Column>
      <Column isSize={12}>
        {isLoadingHeatmaps && <div style={{ height: 400, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <span className="button is-loading is-white is-bordless is-large" style={{ fontSize: 80 }} ></span>
        </div>}

        {!isLoadingHeatmaps && heatmaps.map(heatmap => <svg
          key={`heatmap-setup-id-${heatmap.id}`}
          style={{ border: '1px solid black', cursor: 'crosshair' }}
          width={800}
          height={600}
          ref="canvas"
          onMouseDown={this.handleMouseDown(heatmap.id)}
        >
          <image
            x={0}
            y={0}
            xlinkHref={heatmap.imgSrc}
            width={800}
            height={600}
            onMouseDown={this.handleMouseDown(heatmap.id)}
          />
          {vertices.length > 1 && <polyline
            points={vertices.map(vertice => `${vertice.x},${vertice.y}`).join(' ')}
            fill="none"
            stroke="black"
          />}
          {vertices.map((vertice, i) => {
            if(vertices.length === i + 1) {
              return <circle
                onMouseDown={this._undoLastVertice}
                key={`venue-vertice-${vertice.x}-${vertice.y}-${vertice.end}`}
                cx={vertice.x}
                cy={vertice.y}
                fill={'red'}
                r="5"
              />
            }

            return <circle
              onMouseDown={this.endDraw(vertice.x, vertice.y)}
              key={`venue-vertice-${vertice.x}-${vertice.y}-${vertice.end}`}
              cx={vertice.x}
              cy={vertice.y}
              fill={`#222`}
              r="5"
            />
          })}
          {heatmap.venues.map(venue => <polyline
            cursor="pointer"
            points={venue.venuePaths}
            fill="#00d1b2"
            fillOpacity={selectedVenue.pathId === venue.pathId ? 1 : 0.2}
            stroke='#222222'
            strokeWidth={1}
            onClick={this._onMouseClickVenuePath(heatmap.id, venue)}
          />)}
        </svg>)}
      </Column>
      <Column isSize={4}>
        {selectedVenue.id && selectedVenue.pathId && <div>
          <p className="title is-5">{selectedVenue.description}</p>
          <p className="subtitle is-5">#{selectedVenue.id}</p>
          <span className={`button is-danger is-outlined ${isRemovingPath ? 'is-loading' : ''}`} onClick={this._deletePath}>Remover desenho</span>
        </div>}
      </Column>
    </Columns>);
  }
}

export default (VideoVenueSettings);