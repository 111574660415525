import React, { Component } from 'react';

import { 
  Route, 
  Redirect,
  Switch,
  withRouter
} from "react-router-dom";

import { connect } from 'react-redux';

import { auth } from './firebase';
import { get } from './utils';

import Login from './containers/Login';
import Board from './containers/Board';

import { actions as appActions } from './state/app';
import NewPasswordForm from './containers/login/NewPasswordForm';
import { getServer } from './utils/serverdate';

import {ddRum} from './datadog';

const PrivateRoute = ({ component: Component, user, ...rest }) => {
  return (<Route
      {...rest}
      render={props =>
        user ? (
          <Component {...props} user={user} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
        )
      }
    />);
}

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      uid: null
    }
  }

  componentDidMount() {
    auth.onIdTokenChanged(async user => {
      if(!user) { /*LOGOUT*/
        localStorage.setItem('token', null)
        this.props.userLogout();
        ddRum.removeUser()
        return;
      }
      
      const token = await user.getIdToken(true);
      const serverDate = await getServer();
      localStorage.setItem('token', token);
      localStorage.setItem('offset', serverDate.offset);

      get(`admin/tooq/users?key=${user.uid}`)
        .then(resp => {
          ddRum.setUser({
            id: user.uid,
            email: user.email,
            name: user.displayName
          })
          const { data } = resp.data;
          this.props.userLogin(data);
        })
        .catch(err => {
          this.props.userLogout();
        });
    });
  }

  render() {
    return (
      <div className="App">
        {!this.props.app.loadingApp &&
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/new-password" component={NewPasswordForm} />
          <PrivateRoute path="/board" user={this.props.app.user} component={Board} />
          <Redirect to="/login" />
        </Switch>}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    app: state.app
  }
}

const { userLogin, userLogout } = appActions;

export default withRouter(connect(mapStateToProps, {
  userLogin,
  userLogout
})(App));