import React from 'react';

import {
  Sector
} from 'recharts';

const colors = ['#0043ff', '#00d1b2', '#ff424b', '#23527c', '#7571ff', '#4267b2', '#d6249f', '#E8770C', '#D1C000', '#8D00DE' ];

const PieLabel = (props) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, primaryLabel, secondaryLabel, primaryMiddleText, secondaryMiddleText, opacity, col } = props;    

  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g opacity={opacity}>
      <text x={cx} y={cy} dy={8} fontSize={30} textAnchor="middle" fontWeight={'bold'} fill={'#23527c'}>{primaryMiddleText}</text>
      <text x={cx} y={cy} dy={25} fontSize={10} textAnchor="middle" fill={'#23527c'}>{secondaryMiddleText}</text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={colors[col]}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={colors[col]}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={colors[col]} fill="none"/>
      <circle cx={ex} cy={ey} r={2} fill={colors[col]} stroke="none"/>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fontWeight={'bold'} fill={colors[col]}>{primaryLabel}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#4a4a4a">
        {secondaryLabel}
      </text>
    </g>
  );
}

export default PieLabel;