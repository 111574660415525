import React, { Component } from 'react';

import {
  format
} from 'date-fns';

import CalendarRangePicker from '../calendar/CalendarRangePicker';

class DateFilterOneRange extends Component {

  constructor(props) {
    super(props);

    this.state = {
      toogleFilter: false
    }
  }

  _toogleFilter = () => {
    this.setState({ toogleFilter: !this.state.toogleFilter });
  }

  _confirmSelectedDates = (range) => {
    this.props.changeDates(range);
    this._toogleFilter();
  }

  render() {
    const { ranges } = this.props.actualRanges;
    const { style, maxDate } = this.props;
    const { toogleFilter } = this.state;
    const { className = 'bottom-left-to-right' } = this.props;

    return <>
      <small className="cursor-pointer help" onClick={this._toogleFilter} style={{ textDecoration: 'underline', ...style }}>
        De <b>{format(ranges.startDate, 'DD/MM/YY')}</b> até <b>{format(ranges.endDate, 'DD/MM/YY')}</b>
      </small>
      <div className="tooltip">
        {toogleFilter && <>
          <span className={`tooltiptext ${className}`}/>
          <CalendarRangePicker
              maxDate={maxDate}
              range={ranges}
              close={this._toogleFilter}
              onChange={(item) => this._confirmSelectedDates(item.range)}
            />
        </>}
      </div>
    </>
  }
}

export default (DateFilterOneRange);
