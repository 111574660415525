import React, { Component } from 'react';

class JohannAreaRow extends Component {
  
  constructor(props) {
    super(props);
    
    const {
      id,
      description,
      principal,
      type,
      area_principal_id,
      principal_name
    } = props.area;
    this.state = {
      forms: {
        id,
        description,
        principal,
        type,
        area_principal_id,
        principal_name
      }
    } 
  }

  render() {
    const { area } = this.props;
    const { forms } = this.state;

    return <tr>
      <td>
        {forms.description}
      </td>
      <td>
        {forms.type === 'TRUCK_SHED' ? "Galpão": "Frota de Caminhão"}
      </td>
      <td>
        <div className="field is-small">
          <div className="control has-icons-right is-expanded">
            <input name="principal" defaultChecked={forms.principal} disabled type="checkbox" value={forms.principal}/>
          </div>
        </div>
      </td>
      <td>
        {forms.area_principal_id > 0 ? `${forms.area_principal_id} - ${forms.principal_name}` : 'Não viculado'}
      </td>
      <td>
        <div className="field is-grouped">
          <span className="control"  onClick={() => this.props.selectRowEdit(area.id)}>
            <span className={`button `}>
              Editar
            </span>
          </span>
        </div>
      </td>
    </tr>
  }
}

export default JohannAreaRow;