import React from 'react';

const IsLoading = ({backgroundColor, isBorderless, fontSize, className="" }) => {
  return <span
    className={`button is-loading ${isBorderless ? 'is-bordless' : ''} ${className}`}
    style={{
      fontSize,
      backgroundColor
    }}>
  </span>
}

export const IsLoadingCentered = (props) => {
  return <div style={{ width: '100%', display: 'flex', justifyContent: 'center'}}>
    <IsLoading {...props} />
  </div>
}

export default IsLoading;