import React, { Component } from 'react';
import logoActive from '../img/tooq-logo-new-green.png';

export default class Page extends Component {
  render() {
    const { isLoading, msg } = this.props;
    return (<div className={`pageloader ${isLoading ? 'is-active': ''}`}>
      <div
        style={{ top: '27%', position: 'absolute', width: '100%', justifyContent: 'center', display: 'flex' }}
      >
        <img
          src={logoActive}
          alt="Logo"
          style={{ height: 48 }}
        />
      </div>
      <span className="title">
        {msg}
      </span>
    </div>);
  }
}