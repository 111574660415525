import React, { Component } from 'react';

import {format} from 'date-fns';
import {pt} from 'date-fns/locale';


import { IdBadgeIcon, PlusCircleIcon } from '../../../../../components/icons';
import Page, { PageTitle } from '../../../../../components/Page';
import Columns, { Column } from '../../../../../components/Columns';

import DayToCount from './DayToCount';

import './ManualCount.css';
import HourToCount from './HourToCount';
import ModalNewAudit from './ModalNewAudit';
import ModalCounter from './ModalCounter';
import ModalAnalysis from './ModalAnalysis';
import { get, post } from '../../../../../utils';
import ModalNewAuditReid from './ModalNewAuditReid';
import ModalAnalysisReid from './ModalAnalysisReid';

class ManualCount extends Component {

  constructor(props) {
    super(props);

    this.state = {
      days: [],
      loading: false,
      selectedDay: null,
      selectedHour: null,
      auditReids: [],
      loadingReids: false,
      selectedAudit: null,
      selectedAuditReid: null,
      toogleModalAdd: false,
      toogleModalCounter: false,
      toogleModalAddCounter: false,
      toogleModalAnalysisReid: false,
      tooglePlusDaysWithImages: false,
      isLoadingDaysWithImages: false
    };
  }

  componentDidMount() {
    this._loadDaysToAudit();
  }

  _loadDaysToAudit = () => {
    const { site } = this.props;
    this.setState({
      loading: true,
      tooglePlusDaysWithImages: false,
    }, () => {
      get(`dashboard/video/sites/${site.id}/auditday`)
      .then(resp => this.setState({ loading: false, days: resp.data.data }))
    });
  }

  _onClickPlusDaysToAudit = () => {
    const {
      isLoadingDaysWithImages,
      tooglePlusDaysWithImages
    } = this.state;

    if(tooglePlusDaysWithImages) {
      this.setState({ tooglePlusDaysWithImages: false });
      return;
    }

    if(isLoadingDaysWithImages) {
      return;
    }

    const {
      site
    } = this.props;
    
    this.setState({
      tooglePlusDaysWithImages: true,
      isLoadingDaysWithImages: true
    }, () => {
      get(`dashboard/video/sites/${site.id}/imagesday`)
        .then(resp => {
          this.setState({
            daysWithImages: resp.data.data,
            isLoadingDaysWithImages: false
          })
        });
    })
  }


  _includeNewDayToAudit = (day) => {
    const { site } = this.props;
    const { isSavingNewDayToAudit } = this.state;

    if(isSavingNewDayToAudit) {
      return;
    }

    this.setState({ isSavingNewDayToAudit: true }, () => {
      post(`dashboard/video/sites/${site.id}/auditday`, {
        day
      }).then(resp => {
        this.setState({ isSavingNewDayToAudit: false }, this._loadDaysToAudit)
      })
    });
  }

  _toogleModalAdd = () => {
    const { toogleModalAdd } = this.state;
    this.setState({ toogleModalAdd: !toogleModalAdd });
  }
  _toogleModalAddReid = () => {
    const { toogleModalAddCounter } = this.state;
    this.setState({ toogleModalAddCounter: !toogleModalAddCounter });
  }

  _toogleModalCounter = (audit) => {
    const { toogleModalCounter } = this.state;
    this.setState({ toogleModalCounter: !toogleModalCounter, selectedAudit: audit })
  }

  _toogleModalAnalysis = (audit) => {
    const { toogleModalAnalysis } = this.state;
    this.setState({ toogleModalAnalysis: !toogleModalAnalysis, selectedAudit: audit })
  }
  _toogleModalAnalysisReid = (audit) => {
    const { toogleModalAnalysisReid } = this.state;
    this.setState({ toogleModalAnalysisReid: !toogleModalAnalysisReid, selectedAuditReid: audit })
  }

  _selectDay = (day) => () => {
    const { selectedDay, isLoadingHours } = this.state;

    if (isLoadingHours)
      return;

    if(selectedDay && selectedDay.snap_date === day.day) {
      this.setState({ selectedDay: null });
      return;
    }

    const { site } = this.props;
    this.setState({ isLoadingHours: true, selectedDay: null }, () => {
      get(`dashboard/video/sites/${site.id}/audit`, {
        day: day.day
      }).then(resp => {
        this.setState({
          isLoadingHours: false,
          selectedDay: resp.data.data,
          selectedHour: resp.data.data.hours[0]
        })
      });
    })
  }

  _selectHour = (hour) => () => {
    const { site } = this.props;
    const {selectedDay} = this.state
    this.setState({ selectedHour: hour, loadingReids: true }, ()=>{
      get(`dashboard/video/sites/${site.id}/auditReid`, {
        day: selectedDay.snap_date,
        hour: hour.hour
      })
      .then(resp => {
        this.setState({auditReids: resp.data.data, loadingReids: false})
      })
    });
  }

  _addAuditToList = (audit) => {
    const { selectedHour, selectedDay } = this.state;
    const { audits } = selectedHour;

    const result = {
      ...selectedHour,
      audits: [...audits, audit]
    }

    const newSelectedDay = {
      ...selectedDay,
      hours: selectedDay.hours.map(hour => {
        if(hour.hour === selectedHour.hour) {
          return result;
        }
        return hour;
      })
    }

    this.setState({
      selectedDay: newSelectedDay,
      selectedHour: result,
      toogleModalAdd: false
    });
  }

  render() {
    const {
      site,
      isUserTooqAdmin=false
    } = this.props;

    const {
      days,
      daysWithImages=[],
      loading=false,
      isLoadingHours=false,
      selectedDay,
      auditReids,
      loadingReids=false,
      selectedHour,
      selectedAudit,
      selectedAuditReid,
      toogleModalAdd,
      toogleModalCounter,
      toogleModalAnalysis,
      toogleModalAddCounter,
      toogleModalAnalysisReid,
      isLoadingDaysWithImages,
      tooglePlusDaysWithImages
    } = this.state;

    return <Page>
      <PageTitle
        title={<span>
          <span style={{ marginRight: '10px' }}>
            {`${(site.name) || ''} `}
          </span>
          <p className="subtitle">horários disponíveis<IdBadgeIcon /></p>
        </span>}
      />

      {toogleModalCounter && selectedAudit && <ModalCounter
        site={site}
        day={selectedDay}
        hour={selectedHour}
        audit={selectedAudit}
        toogleModal={this._toogleModalCounter}
      />}

      {toogleModalAnalysis && selectedAudit && <ModalAnalysis
        site={site}
        day={selectedDay}
        hour={selectedHour}
        audit={selectedAudit}
        toogleModal={this._toogleModalAnalysis}
      />} 

      {toogleModalAnalysisReid && selectedAuditReid && <ModalAnalysisReid
        site={site}
        day={selectedDay}
        hour={selectedHour}
        audit={selectedAuditReid}
        toogleModal={this._toogleModalAnalysisReid}
      />}

      {selectedHour && toogleModalAdd && <ModalNewAudit
        site={site}
        day={selectedDay}
        hour={selectedHour}
        toogleModal={this._toogleModalAdd}
        updateAudits={this._addAuditToList}
      />}
      

      {selectedHour && toogleModalAddCounter && <ModalNewAuditReid
        site={site}
        day={selectedDay}
        hour={selectedHour}
        toogleModal={this._toogleModalAddReid}
        updateAudits={this._addAuditToList}
      />}

      <Columns isMultiline>
        <Column isSize={12}>
          <div className="card" style={{ width: '100%' }}>
            <header className="card-header" style={{ padding: 10}}>
              <p className="title is-5">
                Dias disponíveis para auditoria
                {isUserTooqAdmin && <PlusCircleIcon
                  onClick={this._onClickPlusDaysToAudit}
                  className="cursor-pointer"
                  style={{ marginLeft: 15, color: '#00d1b2' }}
                />}
              </p>
            </header>
            <div className="card-content">
              {loading && <p style={{ padding: 40 }} className="subtitle is-5 has-text-centered">Aguarde, carregando...</p>}
              {!loading && days.length === 0 && <p style={{ padding: 40 }} className="subtitle is-5">Não há registros</p>}
              <Columns style={{ overflow: 'auto' }}>
                {tooglePlusDaysWithImages && <Column key={`days_available_toCount-new_day`} isSize='is-narrow' className="has-text-centered">
                  <DayToCount
                    isLoadingDays={isLoadingDaysWithImages}
                    includingDay={true}
                    daysAlreadyIncluded={days}
                    days={daysWithImages}
                    onClick={this._includeNewDayToAudit}
                  />
                </Column>}
                {!loading && days.map(day => <Column key={`days_available_toCount-${day.day}`} isSize='is-narrow' className="has-text-centered">
                  <DayToCount
                    dateSelected={selectedDay && selectedDay.snap_date}
                    date={day.day}
                    cameras={[]}
                    onClick={this._selectDay(day)}
                  />
                </Column>)}
              </Columns>
            </div>
          </div>
        </Column>
        {!selectedDay && !isLoadingHours && <Column isSize={12}>
          <div className="card notification" style={{ backgroundColor: 'whitesmoke' }}>
          <p className="subtitle is-5 has-text-centered"> Selecione um dia à cima para visualizar os horários disponíveis. </p>
          </div>
        </Column>}
        {isLoadingHours && <Column isSize={12}>
          <div className="card notification" style={{ backgroundColor: 'whitesmoke' }}>
          <p className="subtitle is-5 has-text-centered"> Aguarde carregando... </p>
          </div>
        </Column>}
        {selectedDay && <Column isSize={12}>
          <p className="subtitle is-4 has-text-left" style={{ marginBottom: 0 }}>
            {format(selectedDay.snap_date, 'DD MMMM', { locale: pt })}
          </p>
          <p className="subtitle is-6 has-text-left" style={{ marginBottom: 20 }}>
            {format(selectedDay.snap_date, 'dddd', { locale: pt })}, horários disponíveis:
          </p>
          <div className="tabs-audits tabs is-boxed is-small">
            <ul>
              {selectedDay.hours.map(hour => <li key={`hours_available_toCount-${hour.hour}`} className={selectedHour.hour === hour.hour ? "is-active": ""}>
                <HourToCount
                  hour={hour.hour}
                  audits={hour.audits}
                  cameras={hour.cameras}
                  onClick={this._selectHour(hour)}
                />
              </li>)}
            </ul>
          </div>
          {selectedHour && <div className="card notification card-audits">
            <Columns isMultiline>
              {isUserTooqAdmin && <Column isSize={12}>
                <div className="buttons">
                  <span className="button is-primary" onClick={this._toogleModalAdd}>
                    <IdBadgeIcon />
                    <span>Incluir auditoria</span>
                  </span>
                  <span className="button is-primary" onClick={this._toogleModalAddReid}>
                    <IdBadgeIcon />
                    <span>Incluir Reid</span>
                  </span>
                </div>
              </Column>}
              <Column isSize={12}>
                <p className="subtitle is-5">Auditorias:</p>
                <table className="table is-striped is-hoverable" style={{ width: '100%'}}>
                  <thead>
                    <tr>
                      <th>
                        <span className="heading">
                          ID
                        </span></th>
                      <th>
                        <span className="heading">
                          Descrição
                        </span></th>
                      <th>
                        <span className="heading">
                          Cameras
                        </span></th>
                      <th>
                        <span className="heading">
                          Ações
                        </span></th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedHour.audits.map(audit => <tr key={`list-audits-id-${audit.id}`}>
                      <td>{audit.id}</td>
                      <td>{audit.description}</td>
                      <td>
                        <ul>
                          {audit.cameras.map(camera => <li key={`list-audits-cameras-${camera.id}`}>{camera.id} - {camera.description}</li>)}
                        </ul>
                      </td>
                      <td>
                        {audit.flow_analysis ? <div className="buttons">
                          <span className="button is-link is-outlined" onClick={() => this._toogleModalAnalysis(audit)}>Análise eventos</span>
                        </div> : <div className="buttons">
                          <span className="button is-primary is-outlined" onClick={() => this._toogleModalCounter(audit)}>Contagem</span>
                        </div>}
                      </td>
                    </tr>)}
                  </tbody>
                </table>
              </Column>
              {auditReids.length > 0 && !loadingReids && <Column isSize={12}>
                <p className="subtitle is-5">Auditorias Reid:</p>
                <table className="table is-striped is-hoverable" style={{ width: '100%'}}>
                  <thead>
                    <tr>
                      <th>
                        <span className="heading">
                          ID
                        </span>
                      </th>
                      <th>
                        <span className="heading">
                          Descrição
                        </span>
                      </th>
                      <th>
                        <span className="heading">
                          Auditorias
                        </span>
                      </th>
                      <th>
                        <span className="heading">
                          Ações
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {auditReids.map(audit => <tr key={`list-audits-id-${audit.id}`}>
                      <td>{audit.id}</td>
                      <td>{audit.description}</td>
                      <td>
                        <ul>
                          {audit.audits.map(aud => <li key={`list-audits-cameras-${aud.id}`}>{aud.id} - {aud.description}</li>)}
                        </ul>
                      </td>
                      <td>
                        <div className="buttons">
                          <span className="button is-link is-outlined" onClick={() => this._toogleModalAnalysisReid(audit)}>Análise Reid</span>
                        </div>
                      </td>
                    </tr>)}
                  </tbody>
                </table>
              </Column>}
            </Columns>
          </div>}
        </Column>}
      </Columns>
    </Page>
  }
}

export default ManualCount