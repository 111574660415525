import React, { Component } from 'react';

import {
  addDays, format,  
} from 'date-fns';

import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  Cell,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  LabelList,
  Tooltip
} from 'recharts';

import DateFilterOneRange from './DateFilterOneRange';
import LabelCustomizedAuditing from './LabelCustomizedAuditing';
import { formatDateMonthDayOfWeek, getServerDate } from '../../../../../utils';
import Columns, { Column } from '../../../../../components/Columns';

import { post } from '../../../../../utils';
import DownloadButton from '../../../../../components/DownloadButton';
import notification from '../../../../../utils/notification';
import * as Icons from '../../../../../components/icons';

const FIELDS_INITIAL_VALUE = {
  totalSales: 0,
  totalItems: 0,
  totalValue: 0.0
}

class SalesAndVisitors extends Component {

  constructor(props) {
    super(props);
    const CURRDATE = addDays(getServerDate(), -1);
    const {
      filterRanges = {
        startDate: addDays(CURRDATE, -6),
        endDate: CURRDATE,
        key: 'selectionRange1'
      },
      dash = {
        header: {},
        data: []
      }
    } = props;

    this.firstFormField = React.createRef();

    this.state = {
      isLoading: false,
      filterRanges: filterRanges,
      dash: dash,
      selectedDate: '',
      fields: {
        ...FIELDS_INITIAL_VALUE
      },
      saveAnyWay: false,
      saving: false,
      errorOnSave: false,
      controlIsPressed: false
    }
  }

  _changeFieldValue = (field) => (e) => {
    const { fields } = this.state;

    fields[field] = e.target.value;

    this.setState({
      fields: { ...fields }
    });
  }

  renderChartLegend = (props) => {
    const { payload } = props;
    const { filterRanges } = this.state;

    if(!payload) {
      return <div></div>;
    }

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}> 
        <div className="help has-text-centered">
          Indicador da taxa de conversão da loja, 
          uma linha do tempo de <b>{format(filterRanges.startDate, 'DD/MM/YY')} </b> até <b>{format(filterRanges.endDate, 'DD/MM/YY')} </b> 
          comparando o número de visitantes com o número de vendas.
        </div>
        <ul style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
          {payload.map((entry, index)=> {
            return <li key={`legenditem-${index}`} style={{ marginRight: '10px', color: entry.color }}>
              <b>
                {entry.dataKey === "total_sales" ? 'Vendas' 
                  : entry.dataKey === "visitors" ? 'Visitantes'
                  : 'Taxa de conversão'}
              </b>
            </li>
          })}
        </ul>
      </div>);
  }

  _changeDateFilter = () => (ranges) => {
    this.setState({ filterRanges: ranges }, () => {
      this.loadData();
    });
  }

  loadData = (cb) => {
    this.setState({ isLoading: true }, () => {
      const { filterRanges } = this.state;
      this.props.loadSalesRate(filterRanges.startDate, filterRanges.endDate)
        .then(result => {
          this.props.mirrorFunction(result);
          result.data = this.mapVideoData(result.data)
          this.setState({ isLoading: false, dash: result }, cb);
        });
    });
  }
   mapVideoData = (data) => {
     return data.map(({info_date, visitorsCount, visitors, totalSales, total_sales, conversionRate, salesRate, ticket}) => {
      return {
        info_date,
        auditingOk:true,
        visitors: visitors || visitorsCount,
        total_sales: total_sales || totalSales,
        conversionRate: conversionRate || salesRate,
      }
    })
   }

  _handleSubmitSales = (e) => {
    e.preventDefault();

    const { fields, saving, saveAnyWay, selectedDate } = this.state;
    const { totalSales, totalValue, totalItems } = fields;

    if(saving) {
      return;
    }

    if( saveAnyWay === false && (totalValue === 0 || totalItems === 0) ) {
      this.setState({ saveAnyWay: true });
      return;
    }

    if(totalSales > totalItems && totalItems !== 0) {
      this.setState({ errorOnSave: 'Total de itens menor que o total de vendas.', saving: false, saveAnyWay: false });
      return;
    }

    this.setState({ saving: true, saveAnyWay: false, errorOnSave: '' }, () => {
      const { shopId } = this.props;
      post(`sales/${shopId}/daily`, {
        date_: selectedDate,
        hour_: "00:00:00",
        shop_id_integration: shopId,
        shop_description: shopId,
        total_value: totalValue,
        total_sales: totalSales,
        total_items: totalItems
      })
      .then(resp => {
        notification.success('Dados salvos com sucesso!')
        this.setState({ saving: false, fields: { ...FIELDS_INITIAL_VALUE } }, () => {
          this.loadData(() => this.firstFormField.current.select());
        });
      })
      .catch(err => {
        this.setState({
          saving: false,
          saveAnyWay: false,
          errorOnSave: 'Não foi possível salvar o registro. Tente novamente mais tarde.'
        });
      });
    });
  }

  render() {
    const { dash, filterRanges, isLoading, selectedDate } = this.state;
    const {dashVideo} = this.props;
    return (<div className="card notification is-white is-paddingless ">
      <header className="card-header" style={{ padding: '20'}}>
        <p className="card-header-title">
          Visitantes vs Vendas
        </p>
        <a href className="card-header-icon" aria-label="Download PDF">
          <DownloadButton site={this.props.site} name='salesrate' begin={filterRanges.startDate} end={filterRanges.endDate} video={dashVideo}/>
        </a>
      </header>
      <div className="card-content" style={{
        justifyContent: 'center',
        flexDirection: 'column',
        display: 'flex',
        height: '500px'
      }}>
        <Columns>
          <Column isSize={12}>
            {isLoading 
              ? <div className="has-text-centered" style={{ height:500, paddingTop: '100px' }}>
                  <span>
                    <span className="button is-loading is-large is-bordless" />
                    <p className="subtitle is-5">Aguarde, carregando...</p>
                  </span>
                </div>
              : <ResponsiveContainer width="100%" height={300}>
                <ComposedChart cursor={false && this.props.permission === 0 ? "pointer": ""} data={dash.data}
                    margin={{top: 20, right: 10, left: 10, bottom: 20}}>
                  <CartesianGrid strokeDasharray="3 10" />
                  <XAxis dataKey="info_date" tickFormatter={(val) => `${formatDateMonthDayOfWeek(`${val} 00:00:00`)}`}/>
                  <YAxis yAxisId="left" orientation="left" stroke="#343434"/>
                  <YAxis yAxisId="right" orientation="right" stroke="#343434" tickFormatter={(val) => `${val}%`}/>
                  <Legend content={this.renderChartLegend} />
                  <Tooltip
                    content={<CustomToolTip />}
                    isAnimationActive={false}/>
                  <Bar
                    key="visitors"
                    yAxisId="left"
                    name="Vendas"
                    dataKey="visitors"
                   maxBarSize={15}
                    fill="#0043ff"
                    isAnimationActive={false}
                    minPointSize={3}
                    connectNulls={true}
                  >
                    <LabelList
                      dataKey="auditingOk"
                      position="top"
                      content={<LabelCustomizedAuditing />}
                    />
                  </Bar>
                  <Bar
                    key="total_sales"
                    yAxisId="left"
                    name="Vendas"
                    dataKey="total_sales"
                   maxBarSize={15}
                    fill="#00d1b2"
                    isAnimationActive={false}
                    minPointSize={3}
                    connectNulls={true}
                  >
                    {
                      dash.data.map((entry, index) => (
                        <Cell fill={'#00d1b2'} stroke={selectedDate === entry.info_date ? '#000' : ''} fillOpacity={entry.auditingOk ? 1 : 0.50} key={`cell-passersby-${index}`}/>
                      ))
                    }
                  </Bar>
                  <Line key="taxaConversao"
                    yAxisId="right"
                    name="Taxa de conversão"
                    dataKey="conversionRate"
                    strokeDasharray="3 5"
                    fill="#000"
                    stroke="#000"
                    isAnimationActive={false}
                    connectNulls={true}
                  >
                    <LabelList dataKey="conversionRate" position="top" formatter={(val) => `${val}%`} fontSize={22} />
                  </Line>
                </ComposedChart>
              </ResponsiveContainer>}
          </Column>
        </Columns>
      </div>
      <footer className="card-footer" style={{ padding: '20px'}}>
        <span className="button has-text-left is-white card-footer-item is-radiusless is-shadowless is-bordless" style={{ borderRight: 'none' }}>
          <DateFilterOneRange changeDates={this._changeDateFilter()} actualRanges={{ ranges: filterRanges }}/>
        </span>
        <span className="card-footer-item" style={{ borderRight: 'none' }}>
        </span>
        <span className="card-footer-item" style={{ borderRight: 'none' }}>
        </span>
        <span className="card-footer-item" style={{ borderRight: 'none' }}>
        </span>
      </footer>
    </div>)
  }
}

class CustomToolTip extends Component {
  render() {
    const { active } = this.props;
    if(active) {
      const { payload=[] } = this.props;
      const data = payload[0] || { payload: {} };

      return (<div className="card">
        <div  style={{ padding: '0px 5px 0px 5px' }}>
          <p className="heading has-text-centered">
            Em {format(data.payload.info_date, 'DD-MM-YYYY')}
          </p>

          <span className="heading has-text-centered" style={{ fontWeight: 'bold', marginBottom: '-5px', color: '#0043ff' }}>
            <span>Visitantes</span>
          </span>
          <span className="heading has-text-centered" style={{ color: '#0043ff' }}>
            <span>{data.payload.visitors}</span>
          </span>

          <span className="heading has-text-centered" style={{ fontWeight: 'bold', marginBottom: '-5px', color: '#00d1b2' }}>
            <span>Vendas</span>
          </span>
          <span className="heading has-text-centered" style={{ color: '#00d1b2' }}>
            <span>{data.payload.total_sales}</span>
          </span>
          
          <span className="heading has-text-centered" style={{ fontWeight: 'bold', marginBottom: '-5px', color: '#000000' }}>
            <span>Taxa de conversão</span>
          </span>
          <span className="heading has-text-centered" style={{ color: '#000000' }}>
            <span>{data.payload.conversionRate}</span>
          </span>

          {!data.payload.auditingOk && <span
            className="help is-danger">
            <Icons.ExclamationCircleIcon />
            <span>
              Dados não confiáveis.
            </span>
          </span>}
        </div>
      </div>)
    }
    return null;
  }
}


export default (SalesAndVisitors);

