import React, { Component } from 'react';

import {format} from 'date-fns'
import {pt} from 'date-fns/locale'

import { CalendarAltIcon } from '../../../../../components/icons';

class DayToCount extends Component {

  constructor(props) {
    super(props);

    this.state = { 
      dayToInclude: 'selecione',
      isIncluding: false
    }
  }

  _onChangeDayToInclude = (e) => {
    const { value } = e.target;
    this.setState({ dayToInclude: value });
  }

  _onClickIncludeDayToAudit = () => {
    const {
      dayToInclude,
      isIncluding=false
    } = this.state;

    if (dayToInclude === 'selecione') {
      return;
    }

    if (isIncluding) {
      return;
    }

    this.setState({ isIncluding: true }, () => this.props.onClick(dayToInclude));
  }

  render() {
    const {
      includingDay=false,
      dateSelected,
      date,
      days=[],
      daysAlreadyIncluded=[],
      isLoadingDays=true,
      onClick
    } = this.props;
    
    const {
      dayToInclude,
      isIncluding=false
    } = this.state;

    if(includingDay) {
      return <div className={`manualcount-daytocount is-selected`} style={{
        minWidth: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        cursor: 'initial',
        backgroundColor: '#ffffff'
      }}>
        {isLoadingDays && <div>
          <span className="button is-loading is-bordless is-white"></span>
        </div>}
        {!isLoadingDays && days.length !== 0 && <div>

        </div>}
        {!isLoadingDays && days.length !== 0 && <div className="control has-icons-left">
          <CalendarAltIcon/>
          <div className="select">
            <select value={dayToInclude ? dayToInclude : days[0]} onChange={this._onChangeDayToInclude}>
              <option value={'selecione'}>
                Selecione um dia
              </option>
              {days
              .filter(day => !daysAlreadyIncluded.map(day => day.day).includes(day.snapDate))
              .map(day => <option value={day.snapDate}>
                {format(day.snapDate, 'DD/MM/YY (dddd)', { locale: pt })}
              </option>)}
            </select>
          </div>
        </div>}
        {!isLoadingDays && days.length !== 0 && <div className="control">
          <button className={`button is-primary ${isIncluding ? 'is-loading': ''}`} onClick={this._onClickIncludeDayToAudit}>Salvar</button>
        </div>}
      </div>
    }


    return <div className={`manualcount-daytocount ${dateSelected === date ? 'is-selected':''}`} onClick={onClick}>
      <p className="title is-3"> {format(date, 'DD')} </p>
      <p className="subtitle is-4"> {format(date, 'MMMM', { locale: pt })} </p>
      <div>
        <CalendarAltIcon/>
        <span>{format(date, 'ddd', { locale: pt })}</span>
      </div>
    </div>
  }
}

export default DayToCount