import React, { Component } from 'react';

import {
  format
} from 'date-fns';

import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  LabelList,
  Tooltip
} from 'recharts';
import { getServerDate } from '../../../../../../utils';


class VisitorsAndPassersByHourly extends Component {

  renderChartLegendVisitors = (props) => {
    const { payload } = props;

    if(!payload) {
      return <div></div>;
    }

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}> 
        <div className="help has-text-centered">
          Indicador da taxa de visitantes da loja, do dia de hoje,
          comparando o número de passantes com o número de visitantes.
        </div>
        <ul style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
          {payload.map((entry, index)=> {
            return <li key={`legenditem-${index}`} style={{ marginRight: '10px', color: entry.color }}>
              <b>
                {entry.dataKey === "passersByCount" ? 'Passantes' 
                  : entry.dataKey === "visitorsCount" ? 'Visitantes'
                  : entry.dataKey === "visitorsBeforeCount" ? 'Visitantes permanecentes hora anterior'
                  : 'Taxa de visitantes'}
              </b>
            </li>
          })}
        </ul>
      </div>
    );
  }

  renderChartLegendSales = (props) => {
    const { payload } = props;

    if(!payload) {
      return <div></div>;
    }

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}> 
        <div className="help has-text-centered">
          Indicador da taxa de vendas da loja, do dia de hoje, 
          comparando o número de visitantes com o número de vendas.
        </div>
        <ul style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
          {payload.map((entry, index)=> {
            return <li key={`legenditem-${index}`} style={{ marginRight: '10px', color: entry.color }}>
              <b>
                {entry.dataKey === "total_sales" ? 'Vendas' 
                  : entry.dataKey === "visitors" ? 'Visitantes'
                  : 'Conversão em vendas'}
              </b>
            </li>
          })}
        </ul>
      </div>
    );
  }

  render() {
    const { visitorsHour, salesHour, isLoading } = this.props;
    return (<div className="card" style={{
      width: '100%'
    }}>
      <header className="card-header" style={{ padding: '20'}}>
        <p className="card-header-title is-5 is-marginless"style={{ fontSize: '1.25rem'}}>
          Detalhe do dia {!isLoading ? `${format(getServerDate(), ' DD/MM/YY')}` : ' ...'}
        </p>
      </header>
      <div className="card-content">
        <p className="title is-4 has-text-centered">Visitantes</p>
        {isLoading 
        ? <div className="has-text-centered" style={{ height:300, paddingTop: '100px' }}>
            <span>
              <span className="button is-loading is-large is-bordless" />
              <p className="subtitle is-5">Aguarde, carregando...</p>
            </span>
          </div>
        : <ResponsiveContainer width="100%" height={300}>
          <ComposedChart data={visitorsHour.data}
              syncId="horaHoraChart"
              margin={{top: 20, right: 50, left: 50, bottom: 20}}>
            <CartesianGrid strokeDasharray="3 10" />
            <XAxis interval={0} dataKey="info_date" tickFormatter={(val) => `${format(val, 'HH:mm')}`}/>
            <YAxis yAxisId="left" orientation="left" stroke="#343434"/>
            <YAxis yAxisId="right" orientation="right" stroke="#343434" tickFormatter={(val) => `${val}%`}/>
            <Legend content={this.renderChartLegendVisitors} />
            <Tooltip
              content={<CustomToolTipVisitors />}
              isAnimationActive={false}/>
            <Bar
              key="visitors"
              yAxisId="left"
              name="Visitantes"
              dataKey="visitorsCount"
              maxBarSize={15}
              fill="#00d1b2"
              stroke="#00d1b2"
              stackId="peoplecount"
              isAnimationActive={false}
              minPointSize={5}
              connectNulls={true}
            />
            <Bar
              key="passersBy"
              yAxisId="left"
              name="Passantes"
              dataKey="passersByCount"
              maxBarSize={15} 
              fill="#0043ff"
              stroke="#0043ff"
              isAnimationActive={false}
              minPointSize={3}
              stackId="peoplecount"
              connectNulls={true}
            />
            <Line key="taxaConversao"
              yAxisId="right"
              name="Taxa de visitantes"
              dataKey="conversionRate"
              strokeDasharray="3 5"
              fill="#000"
              stroke="#000"
              isAnimationActive={false}
              connectNulls={true}
            >
              <LabelList dataKey="conversionRate" position="top" formatter={(val) => `${val}%`} fontSize={22} />
            </Line>
          </ComposedChart>
        </ResponsiveContainer>}
        <p className="title is-4 has-text-centered">Vendas</p>
        {isLoading
        ? <div className="has-text-centered" style={{ height:300, paddingTop: '100px' }}>
            <span>
              <span className="button is-loading is-large is-bordless" />
              <p className="subtitle is-5">Aguarde, carregando...</p>
            </span>
          </div>
        : <ResponsiveContainer width="100%" height={300}>
          <ComposedChart data={salesHour.data}
            syncId="horaHoraChart"
            margin={{top: 20, right: 50, left: 50, bottom: 20}}>
            <CartesianGrid strokeDasharray="3 10" />
            <XAxis interval={0} dataKey="info_date" tickFormatter={(val) => `${format(val, 'HH:mm')}`}/>
            <YAxis yAxisId="left" orientation="left" stroke="#343434"/>
            <YAxis yAxisId="right" orientation="right" stroke="#343434" tickFormatter={(val) => `${val}%`}/>
            <Legend content={this.renderChartLegendSales} />
            <Tooltip
              content={<CustomToolTipSales />}
              isAnimationActive={false}/>
            <Bar
              key="total_sales"
              yAxisId="left"
              name="Vendas"
              dataKey="total_sales"
              maxBarSize={15} 
              fill="#00d1b2"
              stroke="#00d1b2"
              isAnimationActive={false}
              minPointSize={3}
              stackId="peoplecount"
              connectNulls={true}
            />

            <Bar
              key="visitors"
              yAxisId="left"
              name="Visitantes"
              dataKey="visitors"
              maxBarSize={15}
              fill="#0043ff"
              stroke="#0043ff"
              stackId="peoplecount"
              isAnimationActive={false}
              minPointSize={5}
              connectNulls={true}
            />

            <Line key="taxaConversao"
              yAxisId="right"
              name="Conversão em vendas"
              dataKey="conversionRate"
              strokeDasharray="3 5"
              fill="#00d1b2"
              stroke="#00d1b2"
              isAnimationActive={false}
              connectNulls={true}
            >
              <LabelList dataKey="conversionRate" position="top" formatter={(val) => `${val}%`} fontSize={22} />
            </Line>
          </ComposedChart>
        </ResponsiveContainer>} 
      </div>
    </div>)
  }
}

class CustomToolTipVisitors extends Component {
  render() {
    const { active, payload=[] } = this.props;
    if(active) {

      let data = { payload: {} };
      if(payload && payload.length > 0){
        data = payload[0]
      }

      return (<div className="card notification" style={{ padding: '0px 5px 0px 5px' }}>
          <p className="heading has-text-centered">
            Em {format(data.payload.info_date, 'HH:mm')}
          </p>

          <span className="heading has-text-centered" style={{ fontWeight: 'bold', marginBottom: '-5px', color: '#000000' }}>
            <span>Taxa visitantes</span>
          </span>
          <span className="heading has-text-centered" style={{ color: '#000000', marginBottom: 10 }}>
            <span>{data.payload.conversionRate}%</span>
          </span>


          <span className="heading has-text-centered" style={{ fontWeight: 'bold', marginBottom: '-2px', color: '#222222' }}>
            <span>Visitantes</span>
          </span>
          <span className="heading has-text-left" style={{ marginLeft: 15, marginBottom: '-5px', fontWeight: 'bold', color: '#00d1b2' }}>
            <span>Novos: {data.payload.visitorsCount}</span>
          </span>
          <span className="heading has-text-left" style={{ marginLeft: 15, marginBottom: '-5px', color: '#222222' }}>
            <span>Hora Anterior: {data.payload.visitorsBeforeCount}</span>
          </span>
          <span className="heading has-text-left" style={{ marginLeft: 15, color: '#222222', marginBottom: 10 }}>
            <span>Total: {data.payload.visitorsCountTotal} </span>
          </span>

          <span className="heading has-text-centered" style={{ fontWeight: 'bold', marginBottom: '-5px', color: '#0043ff' }}>
            <span>Passantes</span>
          </span>
          <span className="heading has-text-centered" style={{ color: '#0043ff', marginBottom: 10 }}>
            <span>{data.payload.passersByCount}</span>
          </span>
      </div>)
    }
    return null;
  }
}


class CustomToolTipSales extends Component {
  render() {
    const { active, payload=[] } = this.props;
    if(active) {

      let data = { payload: {} };
      if(payload && payload.length > 0){
        data = payload[0]
      }

      return (<div className="card notification" style={{ padding: '0px 5px 0px 5px' }}>
          <p className="heading has-text-centered">
            Em {format(data.payload.info_date, 'HH:mm')}
          </p>

          <span className="heading has-text-centered" style={{ fontWeight: 'bold', marginBottom: '-5px', color: '#000000' }}>
            <span>Conversão em vendas</span>
          </span>
          <span className="heading has-text-centered" style={{ color: '#000000', marginBottom: 10 }}>
            <span>{data.payload.conversionRate}%</span>
          </span>

          <span className="heading has-text-centered" style={{ fontWeight: 'bold', marginBottom: '-5px', color: '#0043ff' }}>
            <span>Visitantes</span>
          </span>
          <span className="heading has-text-centered" style={{ color: '#0043ff', marginBottom: 10 }}>
            <span>{data.payload.visitors}</span>
          </span>

          <span className="heading has-text-centered" style={{ fontWeight: 'bold', marginBottom: '-5px', color: '#00d1b2' }}>
            <span>Vendas</span>
          </span>
          <span className="heading has-text-centered" style={{ color: '#00d1b2', marginBottom: 10 }}>
            <span>{data.payload.total_sales}</span>
          </span>
      </div>)
    }
    return null;
  }
}

export default (VisitorsAndPassersByHourly);