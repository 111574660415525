import React, { Component } from 'react';

import { connect } from 'react-redux';

import {
  Link,
  Route,
  withRouter
} from "react-router-dom";

import * as Icons from './icons';

class MainAsideMenuAmbev extends Component {

  render() {
    const { match, site, user } = this.props;
    const { url } = match;

    const { userPermissions } = site;
    
    return (<aside className={`menu aside-menu is-aside-toogle`}>
      <div className="menu-aside-header" style={{ minHeight: 100 }}>
        <p className="subtitle is-5 child-to-show">
          {site.name}
        </p>
      </div>
      <p className="menu-label">
        Dashboard
      </p>
      <ul className="menu-list" style={{ position: 'static' }}>
        <Route
          path={`${url}/home`}
          children={({ match }) => <li className={`${match ? 'is-aside-selected' : '' }`}>
            <Link to={`${url}/home`}>
              <Icons.HomeIcon className="is-mini is-gray"/>
              <span className="child-to-show">Página Inicial</span>
            </Link>
          </li>}
        />
      </ul>
      {(user.tooqAdmin || userPermissions.permission === 0) && <div className="menu-label">
        Administrativo
      </div>}
      {(user.tooqAdmin || userPermissions.permission === 0) && <ul className="menu-list" style={{ position: 'static' }}>
        <Route
          path={`${url}/settings`}
          children={({ match }) => <li className={`${match ? 'is-aside-selected' : '' }`}>
            <Link to={`${url}/settings`}>
              <Icons.CogIcon className="is-mini is-gray"/>
              <span className="child-to-show">Configurações</span>
            </Link>
          </li>}
        />
      </ul>}
    </aside>);
  }
}

function mapStateToProps(state, props) {
  const { match } = props;
  const site = state.sites.all[match.params.siteId] || {};
  return { site,  user: state.app.user };
}

export default withRouter(connect(mapStateToProps)(MainAsideMenuAmbev));
