import React, { Component } from 'react';

import { post, put } from '../../../../../utils';
import * as Icons from '../../../../../components/icons';
import notification from '../../../../../utils/notification';

class DeviceForm extends Component {

  constructor(props) {
    super(props);

    const { description='', deviceHash='' } = props.device || {};

    this.state = {
      fields: {
        description,
        deviceHash
      },
      isSaving: false
    }
  }

  componentWillReceiveProps(nextProps) {
    if(this.props.device) {

      if(nextProps.device.id !== this.props.device.id) {
        const { description='', deviceHash='' } = nextProps.device;
        this.setState({
          fields: {
            description,
            deviceHash
          },
          isSaving: false
        });
      }

    }
  }

  _onChange = (e) => {
    const {fields} = this.state;
    const {value, name} = e.target;

    fields[name] = value;
    this.setState({ fields });
  }

  _submitForm = (e) => {
    e.preventDefault();

    const {isSaving, fields} = this.state;
    if(isSaving)
      return;

    this.setState({ isSaving: true }, () => {
      const { siteId, device } = this.props;
      const { description, deviceHash } = fields;

      if(device && device.id) {
        put(`admin/videos/sites/${siteId}/devices/${device.id}`, { deviceHash, description })
          .then((resp) => {
            notification.success('Dados salvos com sucesso!')
            this.setState({ isSaving: false }, () => {
              this.props.handleSubmit(resp.data.data);
            });
          });

        return;
      }

      post(`admin/videos/sites/${siteId}/devices`, { description, deviceHash })
        .then(resp => {
          notification.success('Dados salvos com sucesso!')
          this.setState({
            fields: {
              description: '',
              deviceHash: ''
            },
            isSaving: false
          }, () => this.props.handleSubmit(resp.data.data));
        });
    });
  }

  render() {
    const { device={} } = this.props;
    const { isSaving, fields } = this.state;
    return (<form onSubmit={this._submitForm}>
      {device.id && <p className="help heading has-text-weight-bold">#{device.deviceHash}</p>}
      <div className="field is-grouped is-small">
        <span className="control has-icons-left has-icons-right is-expanded">
          <input
            onChange={this._onChange}
            value={fields.description}
            name="description"
            className="input"
            type="text"
            placeholder="Descrição Device"
            required
          />
          <Icons.HddIcon className="is-small is-left"/>
        </span>
        {device.id && <span className="control">
          {fields.description !== this.props.device.description
            ? <button
              type="submit"
              className={`button is-primary ${device.id ? 'is-outlined' : ''} ${isSaving ? 'is-loading' : ''}`}
            >
              <Icons.HddIcon />
              <span>Salvar device</span>
            </button>
            : <button
              type="submit"
              className='button is-static '
            >
              <Icons.CheckIcon />
              <span>Salvo</span>
            </button>}
        </span>}
      </div>

      {!device.id && <div className="field is-grouped is-small">
        <span className="control has-icons-left is-expanded">
          <input
            onChange={this._onChange}
            value={fields.deviceHash}
            name="deviceHash"
            className="input"
            type="text"
            placeholder="Hash do Device"
            required
          />
          <Icons.RobotIcon className="is-small is-left"/>
        </span>
        <span className="control">
          <button type="submit" className={`button is-primary ${isSaving ? 'is-loading' : ''}`}>
            <Icons.HddIcon />
            <span>Salvar</span>
          </button>
        </span>
      </div>}
      {/* <div className="field is-grouped is-grouped-right">
      </div> */}
    </form>);
  }
}

export default (DeviceForm);



        
