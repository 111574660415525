import React, { Component } from 'react';

import { IdBadgeIcon } from '../../../../components/icons';
import { format } from 'date-fns';
import {pt} from 'date-fns/locale';
import Columns, { Column } from '../../../../components/Columns';
import { get,post, put } from '../../../../utils';
import IsLoading from '../../../../components/IsLoading';


class ModalShelf extends Component {

  constructor(props) {
    super(props);

    this.state = {
      cameras: [],
      images: {},
      currentPosition: 0,
      seconds: [],
      anomaliesSelected: [],
      isloading: false,
    }
  }

  componentDidMount() {
    const { site, audit } = this.props;
    this.setState({ isloading: true, isLoadingCameras: true }, () => {
      get(`dashboard/shelf/sites/${site.id}/anomalies`, {
        date: audit.snapDate,
        hour: parseInt(audit.snapTime.split(':')[0])
      })
      .then(resp => {
        const seconds = Object.values(resp.data.data);
        const currentPosition = seconds.findIndex(s => s.snapTime === audit.lastImage);
        this.setState({
          isloading: false,
          images: resp.data.data,
          seconds,
          currentPosition: currentPosition === -1 ? 0 : currentPosition
        }, this._loadCameras);
      });
    });
  }
  
  _handleConcluir = () => {
    const { isConcluindo=false } = this.state;
    const { site, audit } = this.props;

    if(isConcluindo) {
      return;
    }

    this.setState({ isConcluindo: true }, () => {
      put(`dashboard/video/sites/${site.id}/audit/${audit.id}/status`, {
        status: "1"
      })
      .then(resp => {
        this.setState({ isConcluindo: false }, () => {
          this.props.toogleModal(true);
        });
      });
    })
  }

  _loadCameras = () => {
    const { site } = this.props;
    this.setState({ isLoadingCameras: true }, () => {
      get(`admin/videos/sites/${site.id}/cameras`)
        .then(resp => {
          const cameras = resp.data.data;
          this.setState({ cameras, isLoadingCameras: false });
        });
    })
  }

  _handleCancelButton = () => {
    this.props.toogleModal();
  }

  _sendAnomaliesInverted = (cb) => () => {
    const { site, audit } = this.props;
    const { isLoadingAnomaliesInverted=false, anomaliesSelected, currentPosition, seconds } = this.state;

    if(isLoadingAnomaliesInverted)
      return;

    if (anomaliesSelected.length === 0) {
      cb();
      return;
    }

    const actualSecond = seconds[currentPosition];

    this.setState({ isLoadingAnomaliesInverted: true }, () => {
      post(`dashboard/shelf/sites/${site.id}/audits/${audit.id}/anomalies-revert`, {
        snapDate: audit.snapDate,
        snapTime: actualSecond.snapTime,
        anomaliesIds: anomaliesSelected.join(',')
      }).then((resp) => {
        this.setState({
          isLoadingAnomaliesInverted: false,
          seconds: this.state.seconds.map((s, i) => {
            if (i === currentPosition) {
              return {
                ...s,
                shelfs: s.shelfs.map((shelf) => {
                  if(anomaliesSelected.includes(shelf.anomalyId)) {
                    return {
                      ...shelf,
                      falsePositive: shelf.falsePositive === 1 ? 0 : 1
                    }
                  }
                  return shelf
                })
              };
            }
            return s;
          })
        }, cb);
      });
    });
  }

  _loadPrevious = (i) => () => {
    if(this.state.currentPosition - i < 0){
      this.setState({ currentPosition: 0, anomaliesSelected: [] });
      return;
    }

    this.setState({ currentPosition: this.state.currentPosition - i, anomaliesSelected: [] });
  }

  _loadNext = (i) => () => {
    if(this.state.currentPosition + i >= this.state.seconds.length){
      this.setState({ currentPosition: this.state.seconds.length - 1, anomaliesSelected: [] });
      return;
    }

    this.setState({ currentPosition: this.state.currentPosition + i, anomaliesSelected: [] });
  }

  _setAnomalyToInvert = (anomalyId) => {
    if(this.state.anomaliesSelected.includes(anomalyId)) {
      this.setState({
        anomaliesSelected: this.state.anomaliesSelected.filter(anomaly => anomaly !== anomalyId)  
      })
      return;
    }

    this.setState({
      anomaliesSelected: [
        ...this.state.anomaliesSelected, anomalyId
      ]
    })
  }

  render() {
    const {
      audit
    } = this.props;

    const {
      currentPosition,
      isloading,
      isLoadingCameras,
      cameras,
      seconds,
      anomaliesSelected
    } = this.state;

    const actualToShow = seconds[currentPosition] || {
      images: {},
      shelfs: [],
      snapTime: "25:25:25"
    }

    const next20 = seconds.slice(currentPosition, currentPosition + 20);
    const camsAudit = audit.cameras.map(camera => camera.id);
    const isLoading = isloading || isLoadingCameras;

    return <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card" style={{ backgroundColor: 'white', width: '95%', height: '90%', borderRadius: 5  }}>
        <div className="modal-card-body">
          <p className="title is-4 has-text-left">
            <IdBadgeIcon />
            <span>
              Auditoria de Prateleiras
            </span>
          </p>
          <p className="subtitle is-5 has-text-left">
            <b>#{audit.id}</b> "{audit.description}" {format(audit.snapDate, 'DD MMMM', { locale: pt })} {audit.snapTime} até {audit.snapTime.replace('00:00', '59:59')}
          </p>
          <Columns>
            {isLoading && <Column className="chart_justifycenter" isSize={12}>
              <IsLoading fontSize={60} isBorderless={true} />
            </Column>}
            {next20.map(a => Object.entries(a.images)).flat().map(([c, img]) => {
              const camera = cameras.find(cam => parseInt(cam.id) === parseInt(c)) || {
                width: 1200,
                height: 720
              };

              return <svg
                key={`shelf_audit_next_image_${img}_${camera.width}x${camera.height}`}
                width={camera.width}
                height={camera.height}
                style={{ display: 'none' }}>
                <image
                  xlinkHref={img}
                  x="0"
                  y="0"
                  width={camera.width}
                  height={camera.height}
                />
              </svg>
            })}

            {!isLoading && cameras
            .filter(camera => camsAudit.includes(camera.id))
            .map(camera => <Column key={`shelf_audit_camera_${camera.id}__${currentPosition}`}>
              <p className="help heading"><b>#{camera.id}</b>{camera.description}</p>
              <svg width={camera.width} height={camera.height} style={{ marginTop: 10, borderRadius: 4 }}>
                <image
                  xlinkHref={actualToShow.images[camera.id]}
                  x="0"
                  y="0"
                  width="100%"
                  height="100%"
                />
                {actualToShow
                  .shelfs
                  .filter(shelf => shelf.cameraId === camera.id)
                  .map(shelf => {
                    const isInverted = anomaliesSelected.includes(shelf.anomalyId);
                    const isAnomaly = shelf.isAnomaly === 1 ? shelf.falsePositive === 0 : shelf.falsePositive === 1;

                    return <polyline
                      key={`shelf_audit_camera_${camera.id}__${currentPosition}_${shelf.anomalyId}`}
                      onClick={() => this._setAnomalyToInvert(shelf.anomalyId)}
                      cursor="pointer"
                      points={shelf.points}
                      fillOpacity={0}
                      stroke={(isInverted ? !isAnomaly : isAnomaly) ? "red" : "green"}
                      strokeWidth={3}
                    />
                  })}
              </svg>
            </Column>)}
          </Columns>
        </div>
        
        <footer className="modal-card-foot">
          <Columns style={{ width: '100%', justifyContent: 'center' }}>
            <Column className="is-narrow">
              <p className="subtitle is-5 has-text-centered" style={{marginBottom: 5}}>Imagem {currentPosition + 1} de {seconds.length} </p>
              <div className="buttons is-centered" style={{marginBottom: 5}}>
                <span className={`button ${this.state.isLoadingAnomaliesInverted ? 'is-loading' : ''}`} onClick={this._sendAnomaliesInverted(this._loadPrevious(1))}>Voltar</span>
                {currentPosition === seconds.length - 1 && <span
                  className={`button is-primary ${this.state.isConcluindo ? 'is-loading': ''}`}
                  onClick={() => this._handleConcluir()}>
                  Concluir
                </span>}
                <span className={`button ${this.state.isLoadingAnomaliesInverted ? 'is-loading' : ''}`} onClick={this._sendAnomaliesInverted(this._loadNext(1))}>Avançar</span>
              </div>
              <progress
                className={`progress is-primary `}
                value={currentPosition}
                max={seconds.length}>
              </progress>
            </Column>
          </Columns>
        </footer>
      </div>
      <button className="modal-close is-large" onClick={this._handleCancelButton} aria-label="close"></button>
    </div>
  }
}

export default ModalShelf;