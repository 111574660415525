import React, { Component } from 'react';
import Columns, { Column } from '../../../../components/Columns';
import { CalendarAltIcon, MinusIcon, PlusCircleIcon, SyncAltIcon } from '../../../../components/icons';


import _ from 'lodash';

import {format} from 'date-fns'
import {pt} from 'date-fns/locale'

import { get, post, put } from '../../../../utils';
import notification from '../../../../utils/notification';

import { STATUS_AUDITORIA } from './enums';


export default class AuditDashboard extends Component {

  constructor(props) {
    super(props);

    this.state = {
      days: [],
      closeDesconsiderados: true,
      closeEmAndamento: true,
      closeFinalizado: true,
      attemptStatus: {},
      isLoadingAudit: false,
      audits: [],
      attempts: [],
      dashboardData: [],
      considerarLoading: {},
      desconsiderarLoading: {},
      naoIniciadoLoading: {},
      descriptionSelected: [],
      attemptsToHide: [],
      toogleNewAttempt: false,
      submissionDynamicFields: {
        dbSize: 300,
        reid: [],
        detectionsInHour: [],
        minThreshold: 3,
        maxThreshold: 7
      },
      submissionFields: {
        dbSize: 300,
        reidThreshold: 4.5,
        snapDate: null
      }
    };
  }

  componentDidMount() {
    this._loadAuditAndAttempts();
    this._loadAttemptsStatus();
  }

  _loadAuditAndAttempts = () => {
    const { site } = this.props;
    this.setState({ isLoadingAudit: true }, () => {
      const promise1 = get(`dashboard/video/sites/${site.id}/allaudit?auditType=passersby`)
        .then(response => response.data.data)
        .catch(err => []);
      const promise2 = get(`dashboard/video/sites/${site.id}/attempts?attemptType=passersby`)
        .then(response => response.data.data)
        .catch(err => []);

      Promise.all([promise1, promise2])
        .then(async ([promise1, promise2]) => {
          const daysReduced = promise1.reduce((prev, curr) => {
            prev[curr.snapDate] = true;
            return prev;
          }, {});

          const days = Object.keys(daysReduced);
          const daysSales = await get(`dashboard/foottraffic/sites/${site.id}/salesindays`, {
            days: days.join(',')
          })
          .then(response => response.data.data)
          .catch(err => []);


          this.setState({
            isLoadingAudit: false,
            audits: promise1,
            attempts: promise2,
            dashboardData: daysSales
          })
        });
    })
  }

  _setStatus = (auditId, status) => {
    const { site } = this.props;
    const desconsiderarLoading = {...this.state.desconsiderarLoading};
    const considerarLoading = { ...this.state.considerarLoading };
    const naoIniciadoLoading = { ...this.state.naoIniciadoLoading };

    if(desconsiderarLoading[auditId] || considerarLoading[auditId] || naoIniciadoLoading[auditId]) {
      return;
    }

    if(status === STATUS_AUDITORIA.NAO_INICIADO) {
      naoIniciadoLoading[auditId] = true;
    }
    if(status === STATUS_AUDITORIA.FINALIZADO) {
      considerarLoading[auditId] = true;
    }
    if(status === STATUS_AUDITORIA.DESCONSIDERAR) {
      desconsiderarLoading[auditId] = true;
    }

    this.setState({ naoIniciadoLoading, considerarLoading, desconsiderarLoading }, () => {
      put(`dashboard/video/sites/${site.id}/audit/${auditId}/status`, {
        status
      }).then(resp => {
        delete naoIniciadoLoading[auditId];
        delete considerarLoading[auditId];
        delete desconsiderarLoading[auditId];

        const audits = this.state.audits.map(audit => {
          if(audit.id === auditId) {
            return {...audit, status }
          }
          return audit;
        })
        
        this.setState({ naoIniciadoLoading, considerarLoading, desconsiderarLoading, audits })
      })
      .catch(err => {
        delete naoIniciadoLoading[auditId];
        delete considerarLoading[auditId];
        delete desconsiderarLoading[auditId]
        this.setState({ naoIniciadoLoading, considerarLoading, desconsiderarLoading })
      })
    });
  }

  _selectAttempt = (description) => {
    const { descriptionSelected, attemptsToHide } = this.state;
    if(descriptionSelected.includes(description)) {
      this.setState({
        attemptsToHide: [ ...attemptsToHide.filter(at => at.description !== description) ],
        descriptionSelected: descriptionSelected.filter(sel => sel !== description)
      });
      return;
    }


    this.setState({ descriptionSelected: [...descriptionSelected, description] });
  }

  _hideAttempt = (description, snapDate, snapTime) => {
    const { attemptsToHide=[] } = this.state;
    this.setState({ attemptsToHide: [ ...attemptsToHide, { description, snapDate, snapTime }] })
  }

  _toogleIncludeNewAttempt = () => {
    const { toogleNewAttempt } = this.state;
    this.setState({ toogleNewAttempt: !toogleNewAttempt });
  }

  _changeSubmissionFields = (e) => {
    const { name, value } = e.target;

    const { submissionFields } = this.state;
    submissionFields[name] = value;
    this.setState({ submissionFields });
  }
  
  _submitAttempt = (day) => () => {
    const { site } = this.props;
    const { submissionFields, isSubmiting=false } = this.state;
    const { dbSize, reidThreshold } = submissionFields;

    if(isSubmiting) {
      return;
    }

    this.setState({ isSubmiting: true }, () => {
      post(`dashboard/video/sites/${site.id}/attempts`, {
        dbSize,
        reidThreshold,
        snapDate: day,
        doPassersby: true
      }).then(resp => {
        notification.success('Submeteu com sucesso!');
        this.setState({ isSubmiting: false }, this._toogleIncludeNewAttempt);
      });
    })

  }
  
  _submitAttemptDynamic = (days) => () => {
    const { site } = this.props;
    const { submissionDynamicFields, isSubmitingDynamic=false } = this.state;
    const { dbSize, reid, detectionsInHour, minThreshold, maxThreshold } = submissionDynamicFields;

    if(isSubmitingDynamic) {
      return;
    }

    this.setState({ isSubmitingDynamic: true }, () => {
      post(`dashboard/video/sites/${site.id}/attempts`, {
        dbSize,
        teach: {
          days,
          detectionsInHour,
          reid,
          minThreshold,
          maxThreshold
        },
        doPassersby: true
      }).then(resp => {
        notification.success('Submeteu com sucesso!');
        this.setState({ isSubmitingDynamic: false });
      });
    })

  }
  
  _changeFieldsToDynamic = (attemptsToDisplay) => () => {
    const { submissionDynamicFields } = this.state;
    this.setState({
      submissionDynamicFields: {
        ...submissionDynamicFields,
        reid: attemptsToDisplay.map(attempt => attempt.reidThreshold),
        detectionsInHour: attemptsToDisplay.map(attempt => attempt.detectionsInHour),
      }
    })
  }

  _onChangeFieldsDynamicSubmission = (e) => {
    const { name, value } = e.target;
    const { submissionDynamicFields } = this.state;

    submissionDynamicFields[name] = value;
    this.setState({ submissionDynamicFields })
  }

  _loadAttemptsStatus = () => {
    const { site } = this.props;

    this.setState({ isLoadingStatus: true }, () => {
      get(`dashboard/video/sites/${site.id}/attemptsstatus?attemptType=passersby`)
        .then(resp => {
          this.setState({ isLoadingStatus: false, attemptStatus: resp.data.data });
        });
    })

  }

  render() {
    const {
      attempts,
      attemptsToHide=[],
      audits,
      dashboardData,
      desconsiderarLoading,
      considerarLoading,
      naoIniciadoLoading,
      descriptionSelected=[],
      toogleNewAttempt,
      submissionFields,
      isSubmiting=false,
      submissionDynamicFields,
      attemptStatus,
      isSubmitingDynamic
    } = this.state;

    const auditsReducedDay = Object.keys(audits
    .filter(audit => audit.status === STATUS_AUDITORIA.FINALIZADO)
    .reduce((prev, curr) => {
      prev[`${curr.snapDate}`] = true;
      return prev;
    }, {}));

    const auditsMerged = audits
      .map(auditoria => {
      const dashboardDataFromTime = dashboardData
        .find(dashdata => dashdata.info_date === `${auditoria.snapDate} ${auditoria.snapTime}`);
        
      const { passersby } = dashboardDataFromTime;
      const acuracia = Math.round((passersby / auditoria.visitors) * 100)

      const backgroundColorAcuracy = acuracia >= 75 && acuracia <= 125 ? '#34a853'
        : acuracia < 70 || acuracia > 130 ? '#d76359'
        : '#ffd666';

      return {
        id: auditoria.id,
        auditoriaDescription: auditoria.description,
        auditoriaSnapDate: auditoria.snapDate,
        auditoriaSnapTime: auditoria.snapTime,
        auditoriaVisitors: auditoria.visitors,
        dashVisitors: passersby,
        backgroundColorAcuracy,
        acuracia,
        status: auditoria.status,
        desconsiderarLoading: desconsiderarLoading[auditoria.id],
        considerarLoading: considerarLoading[auditoria.id],
        naoIniciadoLoading: naoIniciadoLoading[auditoria.id]
      }
    });

    const auditsSummary = {
      audits: auditsMerged
        .filter(auditoria => auditoria.status === STATUS_AUDITORIA.FINALIZADO),
      auditsLowAccuracy: auditsMerged
        .filter(auditoria => auditoria.status === STATUS_AUDITORIA.FINALIZADO)
        .filter(audit => audit.acuracia < 70 || audit.acuracia > 130),
      auditsMedAccuracy: auditsMerged
        .filter(auditoria => auditoria.status === STATUS_AUDITORIA.FINALIZADO)
        .filter(audit => (audit.acuracia >= 70 && audit.acuracia < 75) || (audit.acuracia <= 130 && audit.acuracia > 125)),
      auditsGoodAccuracy: auditsMerged
        .filter(auditoria => auditoria.status === STATUS_AUDITORIA.FINALIZADO)
        .filter(audit => (audit.acuracia >= 75 && audit.acuracia <= 125))
    }


    const attemptsMerged = attempts.map(attempt => {
      const auditDataFromDay = auditsSummary.audits
        .find(auditoria => `${attempt.snapDate} ${attempt.snapTime}` === `${auditoria.auditoriaSnapDate} ${auditoria.auditoriaSnapTime}`) || {};

      const dashDataFromDay = dashboardData
        .find(dash => dash.info_date === `${attempt.snapDate} ${attempt.snapTime}`) || {};

      const totalSales = dashDataFromDay.total_sales ? dashDataFromDay.total_sales : 0;

      const auditoriaSaleRate = auditDataFromDay.auditoriaVisitors >= 0 ? Math.round((totalSales / auditDataFromDay.auditoriaVisitors) * 100) : 0;
      const attemptSaleRate = attempt.visitorsNew >= 0 ? Math.round((totalSales / attempt.passersby) * 100) : 0;
      
      const acuracia = auditDataFromDay.auditoriaVisitors >= 0 ? Math.round((attempt.passersby / auditDataFromDay.auditoriaVisitors) * 100) : 0;

      const backgroundColorAcuracy = acuracia >= 75 && acuracia <= 125 ? '#34a853'
        : acuracia < 70 || acuracia > 130 ? '#d76359'
        : '#ffd666';

      return {
        id: attempt.id,
        auditId: auditDataFromDay.id,
        description: attempt.description,
        snapDate: attempt.snapDate,
        snapTime: attempt.snapTime,
        visitorsAudit: auditDataFromDay.auditoriaVisitors,
        visitorsNew: attempt.passersby,
        visitorsBefore: 0,
        backgroundColorAcuracy,
        acuracia,
        totalSales,
        auditoriaSaleRate,
        attemptSaleRate,
        dbSize: attempt.dbSize,
        reidThreshold: attempt.reidThreshold,
        detectionsInHour: attempt.detectionsInHour,
        status: auditDataFromDay.status
      }
    });

    const attemptsReduced = attemptsMerged
      .filter(attempt => attempt.status === STATUS_AUDITORIA.FINALIZADO)
      .reduce((prev, curr) => {
      const descriptionAttempts = prev[curr.description] || [];
      descriptionAttempts.push(curr);
      prev[curr.description] = descriptionAttempts;
      return prev;
    }, {});

    const attemptsToDisplay = attemptsMerged
      .filter(attempt => attempt.status === STATUS_AUDITORIA.FINALIZADO)
      .filter(attempt => descriptionSelected.includes(attempt.description))
      .filter(attempt => {
        const attemptShouldBeHide = attemptsToHide
          .find(at => at.description === attempt.description
            && at.snapDate === attempt.snapDate
            && at.snapTime === attempt.snapTime);
        return !attemptShouldBeHide
      })
      .sort((a, b) => {
        return parseInt(format(`${a.snapDate} ${a.snapTime}`, 'x')) - parseInt(format(`${b.snapDate} ${b.snapTime}`, 'x'))
      });
    

    const attemptsPerformance = Object
      .keys(attemptsReduced)
      .map(description => {
        const attempts = attemptsReduced[description];

        const accuracies = attempts.map(attempt => attempt.acuracia);
        const auditoriaSaleRates = attempts.map(attempt => attempt.auditoriaSaleRate);
        const attemptSaleRates = attempts.map(attempt => attempt.attemptSaleRate);
        const meanAccuracy = Math.round(_.mean(accuracies));

        const backgroundColorAcuracy = meanAccuracy >= 75 && meanAccuracy <= 125 ? '#34a853'
          : meanAccuracy < 70 || meanAccuracy > 130 ? '#d76359'
          : '#ffd666';

        const auditsLowAccuracy = attempts
          .filter(audit => audit.acuracia < 70 || audit.acuracia > 130);

        const auditsMedAccuracy = attempts
          .filter(audit => (audit.acuracia >= 70 && audit.acuracia < 75) || (audit.acuracia <= 130 && audit.acuracia > 125));

        const auditsGoodAccuracy = attempts
          .filter(audit => (audit.acuracia >= 75 && audit.acuracia <= 125));

        return {
          description,
          attempts,
          backgroundColorAcuracy,
          auditsLowAccuracy,
          auditsMedAccuracy,
          auditsGoodAccuracy,
          meanAccuracy: meanAccuracy,
          meanAuditoriaSaleRates: Math.round(_.mean(auditoriaSaleRates)),
          meanAttemptSaleRates: Math.round(_.mean(attemptSaleRates))
        }
      });

    return <Columns isMultiline>
      <Column>
        <div className="notification notification-passersby-attempt-dashboard card " style={{ width: '100%' }}>
          <Columns>
            <Column isSize='is-narrow' style={{ backgroundColor: '#d76359', borderRadius: '5px', margin: '5px' }}>
              Ruim: {Math.round((auditsSummary.auditsLowAccuracy.length / auditsSummary.audits.length) * 100)}%
            </Column>
            <Column isSize='is-narrow' style={{ backgroundColor: '#ffd666', borderRadius: '5px', margin: '5px' }}>
              Médio: {Math.round((auditsSummary.auditsMedAccuracy.length / auditsSummary.audits.length) * 100)}%
            </Column>
            <Column isSize='is-narrow' style={{ backgroundColor: '#34a853', borderRadius: '5px', margin: '5px' }}>
              Bom: {Math.round((auditsSummary.auditsGoodAccuracy.length / auditsSummary.audits.length) * 100)}%
            </Column>
          </Columns>
        </div>
      </Column>
      <Column isSize={12}>
          <div className="notification notification-passersby-attempt-dashboard card" style={{ width: '100%' }}>
            <h1 className="subtitle is-4 cursor-pointer" onClick={() => this.setState({
              closeFinalizado: !this.state.closeFinalizado
            })}>Acurácia dados finalizados</h1>
            {!this.state.closeFinalizado && <table className="table is-hoverable">
              <thead>
                <tr>
                  <th>ID Auditoria</th>
                  <th>Dia Semana</th>
                  <th>Descrição auditoria</th>
                  <th>Dia</th>
                  <th>Hora</th>
                  <th>Passantes Auditados</th>
                  <th>Passantes Dashboard</th>
                  <th>Acurácia</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {auditsMerged
                  .filter(auditoria => auditoria.status === STATUS_AUDITORIA.FINALIZADO)
                  .map(auditoria => <tr>
                    <td>{auditoria.id}</td>
                  <td>{format(auditoria.auditoriaSnapDate, 'dddd', { locale: pt })}</td>
                  <td>{auditoria.auditoriaDescription}</td>
                  <td>{auditoria.auditoriaSnapDate}</td>
                  <td>{auditoria.auditoriaSnapTime}</td>
                  <td>{auditoria.auditoriaVisitors}</td>
                  <td>{auditoria.dashVisitors}</td>
                  <td style={{ backgroundColor: auditoria.backgroundColorAcuracy }}>{auditoria.acuracia}%</td>
                  <td>
                    <span className={`button ${auditoria.desconsiderarLoading ? 'is-loading':''}`} onClick={() => this._setStatus(auditoria.id, STATUS_AUDITORIA.DESCONSIDERAR)}>Desconsiderar</span>
                  </td>
                  <td>
                    <span className={`button ${auditoria.naoIniciadoLoading ? 'is-loading':''}`} onClick={() => this._setStatus(auditoria.id, STATUS_AUDITORIA.NAO_INICIADO)}>Não iniciado</span>
                  </td>
                </tr>)}
              </tbody>
              <tfoot>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>{Math.round(_.mean(auditsMerged
                              .filter(auditoria => auditoria.status === STATUS_AUDITORIA.FINALIZADO)
                              .map(auditoria => auditoria.acuracia)))}%</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tfoot>
            </table>}
          </div>
        </Column>

        <Column isSize={12}>
          <div className="notification notification-passersby-attempt-dashboard card" style={{ width: '100%' }}>
            <h1 className="subtitle is-4 cursor-pointer" onClick={() => this.setState({
              closeEmAndamento: !this.state.closeEmAndamento
            })}>Auditorias em andamento</h1>
            {!this.state.closeEmAndamento && <table className="table is-hoverable">
              <thead>
                <tr>
                  <th>ID Auditoria</th>
                  <th>Dia Semana</th>
                  <th>Descrição auditoria</th>
                  <th>Dia</th>
                  <th>Hora</th>
                  <th>Visitantes Auditados</th>
                  <th>Visitantes Dashboard</th>
                  <th>Acurácia</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {auditsMerged
                  .filter(auditoria => auditoria.status === STATUS_AUDITORIA.NAO_INICIADO)
                  .map(auditoria => <tr>
                    <td>{auditoria.id}</td>
                  <td>{format(auditoria.auditoriaSnapDate, 'dddd', { locale: pt })}</td>
                  <td>{auditoria.auditoriaDescription}</td>
                  <td>{auditoria.auditoriaSnapDate}</td>
                  <td>{auditoria.auditoriaSnapTime}</td>
                  <td>{auditoria.auditoriaVisitors}</td>
                  <td>{auditoria.dashVisitors}</td>
                  <td style={{ backgroundColor: auditoria.backgroundColorAcuracy }}>{auditoria.acuracia}%</td>
                  <td>
                    <span className={`button ${auditoria.desconsiderarLoading ? 'is-loading':''}`} onClick={() => this._setStatus(auditoria.id, STATUS_AUDITORIA.DESCONSIDERAR)}>Desconsiderar</span>
                  </td>
                  <td>
                    <span className={`button ${auditoria.considerarLoading ? 'is-loading':''}`} onClick={() => this._setStatus(auditoria.id, STATUS_AUDITORIA.FINALIZADO)}>Considerar Finalizado</span>
                  </td>
                </tr>)}
              </tbody>
            </table>}
          </div>
        </Column>

        <Column isSize={12}>
          <div className="notification notification-passersby-attempt-dashboard card" style={{ width: '100%' }}>
            <h1 className="subtitle is-4 cursor-pointer" onClick={() => this.setState({
              closeDesconsiderados: !this.state.closeDesconsiderados
            })}>Auditorias desconsideradas</h1>
            {!this.state.closeDesconsiderados && <table className="table is-hoverable">
              <thead>
                <tr>
                  <th>ID Auditoria</th>
                  <th>Dia Semana</th>
                  <th>Descrição auditoria</th>
                  <th>Dia</th>
                  <th>Hora</th>
                  <th>Visitantes Auditados</th>
                  <th>Visitantes Dashboard</th>
                  <th>Acurácia</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {auditsMerged
                  .filter(auditoria => auditoria.status === STATUS_AUDITORIA.DESCONSIDERAR)
                  .map(auditoria => <tr>
                  <td>{auditoria.id}</td>
                  <td>{format(auditoria.auditoriaSnapDate, 'dddd', { locale: pt })}</td>
                  <td>{auditoria.auditoriaDescription}</td>
                  <td>{auditoria.auditoriaSnapDate}</td>
                  <td>{auditoria.auditoriaSnapTime}</td>
                  <td>{auditoria.auditoriaVisitors}</td>
                  <td>{auditoria.dashVisitors}</td>
                  <td style={{ backgroundColor: auditoria.backgroundColorAcuracy }}>{auditoria.acuracia}%</td>
                  <td>
                    <span className={`button ${auditoria.naoIniciadoLoading ? 'is-loading':''}`} onClick={() => this._setStatus(auditoria.id, STATUS_AUDITORIA.NAO_INICIADO)}>Não iniciado</span>
                  </td>
                  <td>
                    <span className={`button ${auditoria.considerarLoading ? 'is-loading':''}`} onClick={() => this._setStatus(auditoria.id, STATUS_AUDITORIA.FINALIZADO)}>Considerar Finalizado</span>
                  </td>
                </tr>)}
              </tbody>
            </table>}
          </div>
        </Column>
        <Column>
          <div className="notification notification-passersby-attempt-dashboard card" style={{ width: '100%' }}>
            <h1 className="subtitle is-4">
              Tentativas {toogleNewAttempt ? <MinusIcon
                onClick={this._toogleIncludeNewAttempt}
                className="cursor-pointer"
                style={{
                  color: '#00d1b2',
                  marginLeft: 15
                }}
              /> : <PlusCircleIcon
              onClick={this._toogleIncludeNewAttempt}
              className="cursor-pointer"
              style={{
                color: '#00d1b2',
                marginLeft: 15
              }}
            />}
            </h1>
            {toogleNewAttempt && <div style={{ border: '1px solid #ccc', padding: 10 }}>
              <Columns>
                <Column isSize={2}>
                  <div className="field">
                    <label className="label">Dia</label>
                    <p className="control has-icons-left">
                      <span className="select">
                        <select name="snapDate" onChange={this._changeSubmissionFields} value={submissionFields.snapDate ? submissionFields.snapDate : auditsReducedDay[0]}>
                          {auditsReducedDay.map(day => <option key={'key_submission_snapDate'} value={day}>
                            {format(day, 'DD/MM/YY ddd', { locale: pt})}
                          </option>)}
                        </select>
                      </span>
                      <CalendarAltIcon className="is-left" />
                    </p>
                  </div>
                </Column>
                <Column isSize={2}>
                  <div className="field">
                    <label className="label">Reid Threshold</label>
                    <div className="control">
                      <input
                        className="input"
                        type="number"
                        placeholder="ReID Threshold"
                        value={submissionFields.reidThreshold}
                        name="reidThreshold"
                        onChange={this._changeSubmissionFields}
                      />
                    </div>
                  </div>
                </Column>
                <Column isSize={2}>
                  <div className="field">
                    <label className="label">DB Size</label>
                    <div className="control">
                      <input
                        className="input"
                        type="number"
                        placeholder="DB Size"
                        value={submissionFields.dbSize}
                        name="dbSize"
                        onChange={this._changeSubmissionFields}
                      />
                    </div>
                  </div>
                </Column>
                <Column isSize={2}>
                  <label className="label">Você pode:</label>
                  <div className="field is-grouped">
                    <div className="control">
                      <span className={`button is-primary ${isSubmiting ? 'is-loading': ''}`} onClick={this._submitAttempt(submissionFields.snapDate ? submissionFields.snapDate : auditsReducedDay[0])}>
                        Submeter
                      </span>
                    </div>
                    <div className="control">
                      <span className="button is-light" onClick={this._toogleIncludeNewAttempt}>
                        Cancel
                      </span>
                    </div>
                  </div>
                </Column>
              </Columns>
            </div>}
            <table className="table is-hoverable">
              <thead>
                <tr>
                  <th>Descrição tentativa</th>
                  <th>Executando</th>
                  <th>Com Erro</th>
                  <th>Finalizado</th>
                  <th> <SyncAltIcon onClick={this._loadAttemptsStatus} className="cursor-pointer"/> </th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(attemptStatus).map((description) => <tr>
                  <td>{description}</td>
                  <td>
                    <span
                      style={{
                        backgroundColor: '#4285f4',
                        color: '#fff',
                        display: 'flex',
                        justifyContent: 'center',
                        minWidth: 20,
                        borderRadius: 20
                      }}
                    >
                      {attemptStatus[description].filter(status => status.status === 'running').length}
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: '#d76359',
                        color: '#fff',
                        display: 'flex',
                        justifyContent: 'center',
                        minWidth: 20,
                        borderRadius: 20
                      }}
                    >
                      {attemptStatus[description].filter(status => status.status === 'warning').length}
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: '#34a853',
                        color: '#fff',
                        display: 'flex',
                        justifyContent: 'center',
                        minWidth: 20,
                        borderRadius: 20
                      }}
                    >
                      {attemptStatus[description].filter(status => status.status === 'done').length}
                    </span>
                  </td>
                  <th></th>
                </tr>)}
              </tbody>
            </table>
          </div>
        </Column>
        <Column isSize={12}>
          <div className="notification notification-passersby-attempt-dashboard card" style={{ width: '100%' }}>
            <h1 className="subtitle is-4">
              Histórico Tentativas
            </h1>
            <table className="table is-hoverable">
              <thead>
                <tr>
                  <th>Descrição tentativa</th>
                  <th>Média Acuracia</th>
                  <th>Frequencia Acurácias </th>
                  <th> </th>
                  <th> <SyncAltIcon onClick={this._loadAuditAndAttempts} className="cursor-pointer"/> </th>
                </tr>
              </thead>
              <tbody>
                {attemptsPerformance
                  .map(attempt => <tr>
                  <td>
                    <div>{attempt.description}</div>
                  </td>
                  <td style={{ backgroundColor: attempt.backgroundColorAcuracy }}>
                    {attempt.meanAccuracy}%
                  </td>
                  <td style={{ padding: 20 }}>
                    <Columns>
                      <Column isSize={'is-narrow'} style={{ padding: 5, fontSize: '0.8em', backgroundColor: '#d76359', borderRadius: '5px', margin: '1px' }}>
                        Ruim: {Math.round((attempt.auditsLowAccuracy.length / attempt.attempts.length) * 100)}%
                      </Column>
                      <Column isSize={'is-narrow'} style={{ padding: 5, fontSize: '0.8em', backgroundColor: '#ffd666', borderRadius: '5px', margin: '1px' }}>
                        Médio: {Math.round((attempt.auditsMedAccuracy.length / attempt.attempts.length) * 100)}%
                      </Column>
                      <Column isSize={'is-narrow'} style={{ padding: 5, fontSize: '0.8em', backgroundColor: '#34a853', borderRadius: '5px', margin: '1px' }}>
                        Bom: {Math.round((attempt.auditsGoodAccuracy.length / attempt.attempts.length) * 100)}%
                      </Column>
                    </Columns>
                  </td>
                  <td>
                    {!descriptionSelected.includes(attempt.description) && <span className="button is-small is-primary is-outlined" onClick={() => this._selectAttempt(attempt.description)}>Selecionar tentativa</span>}
                    {descriptionSelected.includes(attempt.description) && <span className="button is-small  is-outlined" onClick={() => this._selectAttempt(attempt.description)}>Deselecionar</span>}
                  </td>
                  <td>
                  </td>
                </tr>)}
              </tbody>
            </table>
            
          </div>
        </Column>
        <Column isSize={12}>
          <div className="notification notification-passersby-attempt-dashboard card" style={{ width: '100%' }}>
            <h1 className="subtitle is-4">
              Tentativas de parametros
            </h1>
            <table className="table is-hoverable">
              <thead>
                <tr>
                  <th>Dia Semana</th>
                  <th>Descrição auditoria</th>
                  <th>Dia</th>
                  <th>Hora</th>
                  <th>Auditado</th>
                  <th>Algoritmo</th>
                  <th>Acurácia</th>
                  <th>DB SIZE</th>
                  <th>Reid Threshold</th>
                  <th>Detections In Hour</th>
                  <th></th>

                </tr>
              </thead>
              <tbody>
                {attemptsToDisplay
                  .map(attempt => <tr>
                  <td>{format(attempt.snapDate, 'dddd', { locale: pt })}</td>
                  <td>{attempt.description}</td>
                  <td>{attempt.snapDate}</td>
                  <td>{attempt.snapTime}</td>
                  <td>{attempt.visitorsAudit}</td>
                  <td>{attempt.visitorsNew}</td>
                  <td style={{ backgroundColor: attempt.backgroundColorAcuracy }}>{attempt.acuracia}%</td>
                  <td>{attempt.dbSize}</td>
                  <td>{attempt.reidThreshold}</td>
                  <td>{attempt.detectionsInHour}</td>

                  <td
                    className="cursor-pointer"
                    onClick={() => this._hideAttempt(attempt.description, attempt.snapDate,attempt.snapTime)}
                  >
                    <MinusIcon style={{color: '#d76359'}} />
                  </td>
                </tr>)}
              </tbody>
            </table>
            <span className="button is-primary is-outlined" onClick={this._changeFieldsToDynamic(attemptsToDisplay)}>Aplicar para geração de métricas</span>
          </div>
        </Column>
        <Column>
          <div className="notification notification-passersby-attempt-dashboard" style={{ width: '100%' }}>
            <h1 className="subtitle is-4" style={{ marginTop: 20 }}>
              Submeter tentativa dinâmica
            </h1>
            <div className="field">
              <label className="label">DB Size</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  style={{ width: "100%" }}
                  value={submissionDynamicFields.dbSize}
                  name="dbSize"
                  onChange={this._onChangeFieldsDynamicSubmission}
                />
              </div>
            </div>
            <div className="field">
              <label className="label">List Reid Thresholds</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  style={{ width: "100%" }}
                  value={submissionDynamicFields.reid}
                  disabled
                />
              </div>
            </div>
            <div className="field">
              <label className="label">List Detections in Hour</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  style={{ width: "100%" }}
                  value={submissionDynamicFields.detectionsInHour}
                  disabled
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Min Threshold</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  style={{ width: "100%" }}
                  value={submissionDynamicFields.minThreshold}
                  name="minThreshold"
                  onChange={this._onChangeFieldsDynamicSubmission}
                />
              </div>
            </div>

            <div className="field">
              <label className="label">Max Threshold</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  style={{ width: "100%" }}
                  value={submissionDynamicFields.maxThreshold}
                  name="maxThreshold"
                  onChange={this._onChangeFieldsDynamicSubmission}
                />
              </div>
            </div>

            <span className={`button is-primary ${isSubmitingDynamic ? 'is-loading': ''}`} onClick={this._submitAttemptDynamic(auditsReducedDay)}>
              Métricas Dinâmicas
            </span>
          </div>
        </Column>
      </Columns>
  }
}
