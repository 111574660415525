import React, { Component } from 'react';

import {
  withRouter
} from "react-router-dom";

import { ExclamationCircleIcon } from './icons';

/***
 * Template for page container
 */
class PageNotFound extends Component {
  render() {
    const { style } = this.props;
    return (
      <section className="hero fadeOpacity-3" style={style} >
        <div className="hero-body">
          <div className="container is-fluid has-text-centered">
            <p className="title is-2">
              <ExclamationCircleIcon className="has-text-info is-large"/>
              Página não encontrada.</p>
            <p className="subtitle is-6">Está página não existe ou você não tem permissão para acessa-la.</p>
          </div>
        </div>
      </section>
    );
  }
}

export default withRouter(PageNotFound);