import _ from 'lodash';
import { LOGOUT } from './app';
export const ADD_SITES='ADD_SITES';
export const CURRENT_GROUP='CURRENT_GROUP';

const INITIAL_STATE = {
  all: {},
  groups: {},
  currentGroup: null
};

export const actions = {

  addMany: (sites) => {
    return dispatch => dispatch({ type: ADD_SITES, payload: sites })
  },

  setCurrentGroup: (group) => {
    return dispatch => dispatch({ type: CURRENT_GROUP, payload: group });
  }

}

export const reducer = (state = INITIAL_STATE, { payload, type }) => {
  switch(type) {
    case LOGOUT: {
      return INITIAL_STATE;
    }

    case ADD_SITES: {
      const all = { ...state.all };
      const groups = { ...state.groups };

      payload
        .map(site => {
          groups[site.group.id] = site.group;
          return {
            id: site.id,
            name: site.name,
            isActive: site.isActive,
            isOnline: site.isOnline,
            group: site.group,
            modules: site.modules,
            userPermissions: site.userPermissions,
            foottrafficData: site.foottrafficData,
            johannData: site.johannData,
            queuesData: site.queuesData
          }
        })
        .forEach(site => all[site.id] = site);

      const groupsArray = _.toArray(groups);
      const currentGroup = (groupsArray[0] || {}).id;

      return { ...state, all, currentGroup, groups };
    }

    case CURRENT_GROUP: {
      return { ...state, currentGroup: payload };
    }

    default: {
      return state;
    }
  }
}