import React, { Component } from 'react';

import _ from 'lodash';
import * as Icons from '../../../../components/icons';
import { get, post } from '../../../../utils';

class SyncronizeToSite extends Component {

  constructor(props) {
    super(props);
    this.state = {
      devices: [],
      toogleModal: false,
      jsonToSync: {},
      textToShow: '',
      isJsonOk: true,
      isLoadingSyncData: false,
      selectedDevice: 0,
      isDoingSync: false
    }
  }
  
  componentDidMount() {
    this._loadDataToSync();
  }

  _loadDataToSync = () => {
    this.setState({ isLoadingSyncData: true }, () => {
      const { site } = this.props;
      get(`admin/videos/sites/${site.id}/sync`)
        .then(resp => {
          const devices = resp.data.data;
          const selectedDevice = _.first(devices) || {};
          this.setState({ devices, isLoadingSyncData: false }, this._handleSelectDevice(selectedDevice));
        })
    });
  }

  _toogleModal = () => {
    this.setState({
      toogleModal: !this.state.toogleModal
    });
  }

  _changeText = (e) => {
    e.preventDefault();

    const { value } = e.target;
    this._setTextToShowAtTextArea(value);
  }

  _setTextToShowAtTextArea = (value) => {
    let isJsonOk = true;
    try {
      JSON.parse(value);
    } catch(e) {
      isJsonOk = false;
    }

    if(!isJsonOk) {
      this.setState({ textToShow: value, isJsonOk: false });
      return;
    }

    this.setState({ jsonToSync: JSON.parse(value), textToShow: JSON.stringify(JSON.parse(value), null, 3), isJsonOk: true });
  } 

  _handleSelectDevice = (device) => () => {
    this.setState({ selectedDevice: device.id }, () => {
      this._setTextToShowAtTextArea(JSON.stringify(device.cameras));
    });
  }

  _syncDevice = () => {
    const { site } = this.props;
    const { isDoingSync } = this.state;

    if(isDoingSync)
      return;
    
    this.setState({ isDoingSync: true }, () => {
      const { selectedDevice, jsonToSync } = this.state;
      post(`admin/videos/sites/${site.id}/sync`, {
        siteId: site.id,
        deviceId: selectedDevice,
        cameras: jsonToSync
      })
      .then(resp => {
        this.setState({ isDoingSync: false });
      })
    });
  }

  render() {
    const {
      isLoadingSyncData,
      selectedDevice,
      devices,
      textToShow,
      isJsonOk,
      isDoingSync
    } = this.state;

    return (<div className="card notification" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      {isLoadingSyncData && <div style={{ display: 'flex', textAlign: 'center', justifyContent: 'center', flexDirection: 'column' }}>
        <div className="button is-loading is-bordless"></div>
        <p className="subtitle is-5 has-text-centered">Aguarde carregando</p>
      </div>}
      <div className="tabs">
        <ul>
          {devices.map(device => <li
            key={device.id}
            className={device.id === selectedDevice ? 'is-active' : ''}
          >
            <a href onClick={this._handleSelectDevice(device)}>
              <Icons.HddIcon />
              <span>{device.description}</span>
            </a>
          </li>)}
        </ul>
      </div>
      <div className="buttons">
        <div className={`button is-primary ${isDoingSync ? 'is-loading': ''}`} onClick={this._syncDevice}>Sincronizar</div>
      </div>
      {!isJsonOk && <p className="help is-danger">O texto precisa estar no formato JSON. O texto está formatado errado!</p>}
      <textarea
        style={{ width: '100%' }}
        rows="20"
        cols="200"
        onChange={this._changeText}
        value={textToShow}
      />
    </div>);
  }
}

export default (SyncronizeToSite);
