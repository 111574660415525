import React, { Component } from 'react';

import { format } from 'date-fns';

import CalendarDoubleRangePicker from '../../calendar/CalendarDoubleRangePicker';

class DateFilterTwoRanges extends Component {

  constructor(props) {
    super(props);

    this.state = {
      toogleFilter: false
    }
  }

  _toogleFilter = () => {
    this.setState({ toogleFilter: !this.state.toogleFilter });
  }

  _confirmSelectedDates = (range1, range2) => {
    this.props.changeDates(range1, range2);
    this._toogleFilter();
  }

  render() {
    const { toogleFilter } = this.state;
    const { ranges, ranges2 } = this.props.actualRanges;
    const { className = 'bottom-left-to-right' } = this.props;

    return <div className="tooltip">
      <small
        className="cursor-pointer help"
        onClick={this._toogleFilter}
        style={{ textDecoration: 'underline' }}
      >
        De <b>{format(ranges2.startDate, 'DD/MM/YY')}</b> até <b>{format(ranges2.endDate, 'DD/MM/YY')}</b> vs <b>{format(ranges.startDate, 'DD/MM/YY')}</b> até <b>{format(ranges.endDate, 'DD/MM/YY')}</b>
      </small>
      {toogleFilter && <div className={`tooltiptext ${className}`}>
        <CalendarDoubleRangePicker
          range1={ranges}
          range2={ranges2}
          close={this._toogleFilter}
          onChange={(item) => this._confirmSelectedDates(item.range1, item.range2)}
        />
      </div>}
    </div>
  }
}

export default (DateFilterTwoRanges);
