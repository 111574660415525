import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react';
import { MultiSelect } from "react-multi-select-component";
import Modal from '../../../../components/Modal';
import Card from '../../../../components/Card';
import { ArchiveIcon, CheckIcon, SearchIcon } from '../../../../components/icons';
import { get, post, put } from '../../../../utils';
import IsLoading from '../../../../components/IsLoading';
import notification from '../../../../utils/notification';
import Columns, { Column } from '../../../../components/Columns';


const SkuRow = (props) => {
  const { sku, deactivating, deactivateSku, activateSku, editSku } = props;

  return <tr>
    <td>{sku.id}</td>
    <td>{sku.skuDescription}</td>
    <td>{sku.skuCleanDescription}</td>
    <td>{sku.detectionLabelDescription}</td>
    <td>{sku.categoryName}</td>
    <td>{sku.manufacturerName}</td>
    <td>{sku.skuGrouper}</td>
    <td>{sku.widthCm}</td>
    <td>{sku.sizeMl}</td>
    <td>
      <button className='button is-link is-oulined' onClick={() => editSku(sku)} >
        Editar
      </button>
      {sku.active === 1 ? <button className={`button is-danger is-outlined ${deactivating[sku.id] ? 'is-loading': ''}`} onClick={() => deactivateSku(sku.id)}>
        <span>Desativar</span> <ArchiveIcon />
      </button> : <button className={`button is-primary is-outlined ${deactivating[sku.id] ? 'is-loading': ''}`} onClick={() => activateSku(sku.id)}>
        <span>Ativar</span> <CheckIcon />
      </button>}
      
    </td>
  </tr>
}

const useField = (defaultValue="") => {
  const [ value, setValue ] = useState(defaultValue);
  return {
    value,
    setFieldValue: (value) => setValue(value),
    onChange: (e) => setValue(e.target.value),
    clearField: () => setValue(defaultValue) 
  }
}

const AddForm = forwardRef((props, ref) => {
  const { groupId, handleAddList, isLoadingDetectionLabelOptions, detectionLabelOptions, categoryOptions, manufacturerOptions, isLoading, sites, setSiteSelect, siteSelect } = props; 
  const skuId = useField(0);
  const skuDescriptionRef = useRef(null);
  const skuDescription = useField();
  const skuGrouper = useField();
  const skuCategoryId = useField(0);
  const skuManufacturerId = useField(0);
  const widthCm = useField(0.0);
  const sizeMl = useField(0);
  const detectionLabelId = useField(0);

  const [adding, setAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const cantSave = detectionLabelId.value === "0";

  const setNewState = (sku) => {
    setIsEditing(true);
    skuId.setFieldValue(sku.id);
    skuDescription.setFieldValue(sku.skuDescription);
    skuGrouper.setFieldValue(sku.skuGrouper);
    skuCategoryId.setFieldValue(sku.categoryId);
    skuManufacturerId.setFieldValue(sku.manufacturerId);
    widthCm.setFieldValue(sku.widthCm);
    sizeMl.setFieldValue(sku.sizeMl);
    detectionLabelId.setFieldValue(sku.detectionLabelId);
    setSiteSelect(sku.sites);
  };

  useImperativeHandle(ref,
    () => ({
      addState: setNewState,
    })
  );

  const onSubmit = (e) => {
    e.preventDefault();
    if(adding || cantSave)
      return;

    if(isEditing){
      setAdding(true);
      put(`admin/share-gondola/site-group/${groupId}/skudata/${skuId.value}`, {
        detectionLabelId: detectionLabelId.value,
        skuGrouper: skuGrouper.value,
        categoryId: skuCategoryId.value,
        manufacturerId: skuManufacturerId.value,
        widthCm: widthCm.value,
        sizeMl: sizeMl.value,
        sitesSelected: siteSelect
      }).then(() => {
        skuDescription.clearField();
        detectionLabelId.clearField();
        skuGrouper.clearField();
        skuCategoryId.clearField();
        skuManufacturerId.clearField();
        widthCm.clearField();
        sizeMl.clearField();
        setSiteSelect([]);
        notification.success('Sku editado com sucesso!');
        setIsEditing(false);
      }).catch((err) => {
        notification.error('Não foi possível editar o SKU!');
      }).then(() => {
        setAdding(false);
      });
    } else {
      setAdding(true);
      post(`admin/share-gondola/site-group/${groupId}/skudata`, {
        skuDescription: skuDescription.value,
        detectionLabelId: detectionLabelId.value,
        skuGrouper: skuGrouper.value,
        categoryId: skuCategoryId.value,
        manufacturerId: skuManufacturerId.value,
        widthCm: widthCm.value,
        sizeMl: sizeMl.value,
        sitesSelected: siteSelect
      })
      .then(resp => {
        handleAddList(resp.data.data);
        skuDescription.clearField();
        widthCm.clearField();
        sizeMl.clearField();
        setSiteSelect([]);
        skuDescriptionRef.current.focus();
        notification.success('SKU adicionado com sucesso!')
        return resp.data.data;
      })
      .catch(err => {
        notification.success('Não foi possível adicionar o SKU!')
      })
      .then(() => {
        setAdding(false);
      })
    };
  };

  const sitesSelect = sites.map(site => {
    return {
      label: site.name,
      value: site.id
    }
  });

  return <form style={{ marginBottom: 10 }} onSubmit={onSubmit} ref={ref}>
    <Columns isMultiline>
      <Column isSize={2}>
        <div className="field">
          <label className="label">Descrição do SKU</label>
          <div className="control">
            {isEditing ?  
            <input
              disabled
              autoFocus={true}
              ref={skuDescriptionRef}
              className="input"
              type="text"
              placeholder="Ex: CABARÉ"
              maxLength={200} {...skuDescription} />
            : 
            <input
              autoFocus={true}
              ref={skuDescriptionRef}
              className="input"
              type="text"
              placeholder="Ex: CABARÉ"
              maxLength={200} {...skuDescription} />}
          </div>
        </div>
      </Column>
      <Column isSize={1}>
        <div className="field">
          <label className="label">Categoria</label>
          <div className="control">
            <div className="select">
              <select value={skuCategoryId.value} onChange={skuCategoryId.onChange}>
                <option value={"0"}>{isLoading ? 'Aguarde carregando...' : 'Selecione uma opção'}</option>
                {categoryOptions.map(category => <option key={`category_option_${category.id}`} value={category.id}>
                  {category.category}
                </option>)}
              </select>
            </div>
          </div>
        </div>
      </Column>
      <Column isSize={1}>
        <div className="field">
          <label className="label">Fabricante</label>
          <div className="control">
            <div className="select">
              <select value={skuManufacturerId.value} onChange={skuManufacturerId.onChange}>
                <option value={"0"}>{isLoading ? 'Aguarde carregando...' : 'Selecione uma opção'}</option>
                {manufacturerOptions.map(manufacturer => <option key={`manufacturer_option_${manufacturer.id}`} value={manufacturer.id}>
                  {manufacturer.name}
                </option>)}
              </select>
            </div>
          </div>
        </div>
      </Column>
      <Column isSize={1}>
        <div className="field">
          <label className="label">Tipo</label>
          <div className="control">
            <div className="select">
              <select value={detectionLabelId.value} onChange={detectionLabelId.onChange}>
                <option value={"0"}>{isLoadingDetectionLabelOptions ? 'Aguarde carregando...' : 'Selecione uma opção'}</option>
                {detectionLabelOptions.map(detectionLabel => <option key={`detection_label_option_${detectionLabel.id}`} value={detectionLabel.id}>
                  {detectionLabel.label}
                </option>)}
              </select>
            </div>
          </div>
        </div>
      </Column>
      <Column isSize={1}>
        <div className="field">
          <label className="label">Agrupador</label>
          <div className="control">
            <input
            className="input"
            type="text"
            placeholder="Ex: FUTUREBEV_AMBEV"
            maxLength={200} {...skuGrouper} />
          </div>
        </div>
      </Column>
      <Column isSize={1}>
        <div className="field">
          <label className="label">Largura (cm)</label>
          <div className="control">
            <input
              className="input"
              type="number"
              placeholder="Ex: 6.5"
              maxLength={200}
              step=".1"
              {...widthCm} />
          </div>
        </div>
      </Column>
      <Column isSize={1}>
        <div className="field">
          <label className="label">Volume (ml)</label>
          <div className="control">
            <input
              className="input"
              type="number"
              placeholder="Ex: 1000"
              step=".1"
              maxLength={200}
              {...sizeMl} />
          </div>
        </div>
      </Column>
      <Column>
      <div className="field">
        <label className="label">Relacionar Lojas</label>
        <div className="control">
          <MultiSelect
            options={sitesSelect}
            value={siteSelect}
            onChange={setSiteSelect}
            labelledBy="Select"
          />
        </div>
      </div>
      </Column>
      <Column isSize={1}>
        <label className="label" style={{ visibility: 'hidden'}}>Button</label>
        <div className="field is-grouped">
          <div className="control">
            {isEditing ?
            <button type="submit" className={`button is-primary ${adding ? 'is-loading': ''}`} disabled={cantSave}>
              Salvar
            </button> 
            :
            <button type="submit" className={`button is-primary ${adding ? 'is-loading': ''}`} disabled={cantSave}>
              Adicionar
            </button>}
          </div>
        </div>
      </Column>
    </Columns>
  </form>
});


const SkuDataModal = (props) => {
  const { group } = props;
  const [ isLoading, setIsLoading ] = useState(false);
  const [ refresh, setRefresh ] = useState(false);
  const [ skus, setSkus ] = useState([]);
  const [ sites, setSites ] = useState([]);
  const [ siteSelect, setSiteSelect ] = useState([]);
  const [ showActives, setShowActives ] = useState(1);
  const [ searchField, setSearchField ] = useState("");
  const [ deactivating, setDeactivating ] = useState({});
  const [ detectionLabelOptions, setDetectionLabelOptions ] = useState([]);
  const [ isLoadingDetectionLabelOptions, setIsLoadingDetectionLabelOptions ] = useState([]);
  const [ categoryOptions, setCategoryOptions ] = useState([]);
  const [ manufacturerOptions, setManufacturerOptions ] = useState([]);
  const [ csvFileInput, setCsvFileInput ] = useState(null);
  const [ selectedFile, setSelectedFile ] = useState(false);
  const refForm = useRef();

  useEffect(() => {
    setIsLoading(true);
    setSkus([])
    get(`admin/share-gondola/site-group/${group.id}/skudata?showActives=${showActives}`)
      .then(resp => {
        setSkus(resp.data.data);
        setIsLoading(false);
        setRefresh(false);
      })
  }, [ group.id, showActives, refresh ]);

  useEffect(() => {
    setIsLoading(true);
    get(`admin/tooq/sites`, {groupId: group.id})
    .then(resp => {
      setSites(resp.data.data);
      setIsLoading(false);
    });
  }, [group.id]);

  useEffect(() => {
    setIsLoading(true);
    setCategoryOptions([]);
    get(`admin/share-gondola/category`)
      .then(resp => {
        setCategoryOptions(resp.data.data);
        setIsLoading(false)
      })
  }, []);

  useEffect(() => {
    setIsLoading(true);
    setManufacturerOptions([]);
    get(`admin/share-gondola/manufacturer`)
      .then(resp => {
        setManufacturerOptions(resp.data.data);
        setIsLoading(false)
      })
  }, []);

  useEffect(() => {
    setIsLoadingDetectionLabelOptions(true);
    setDetectionLabelOptions([]);
    get(`admin/share-gondola/detection-label`)
      .then(resp => {
        setIsLoadingDetectionLabelOptions(false);
        setDetectionLabelOptions(resp.data.data);
      })
  }, []);

  const addSkuDataCsv = () => {
    if(csvFileInput.name.split('.').pop() !== 'csv'){
      return notification.error('Precisa ser um CSV');
    }

    post(`admin/share-gondola/site-group/${group.id}/csv/skudata`, 
      {csvFile: csvFileInput}, 
      {headers: {'Content-Type': 'multipart/form-data'}
    }).then(resp => {
      setSelectedFile(false);
      setCsvFileInput(null);
      notification.success(`Sku adicionados!`);
    })
    .catch(err => {
      notification.error('Houve um erro ao tentar adicionar o SKU via CSV. Tente novamente mais tarde.');
    })
  };

  const handleFileInput = (value) => {
    setCsvFileInput(value);
    setSelectedFile(true);
  };

  const deactivateSku = (skuId) => {
    setDeactivating({ ...deactivating, [skuId]: true });
    put(`admin/share-gondola/site-group/${group.id}/skudata/${skuId}/deactivate`)
      .then(resp => {
        setDeactivating({ ...deactivating, [skuId]: false });
        setSkus(skus.filter(sku => sku.id !== skuId));
        notification.success(`Sku #${skuId} foi desativado.`);
      })
      .catch(err => {
        setDeactivating({ ...deactivating, [skuId]: false });
        notification.error('Houve um erro ao tentar desativar o SKU. Tente novamente mais tarde.');
      })
  }

  const activateSku = (skuId) => {
    setDeactivating({ ...deactivating, [skuId]: true });
    put(`admin/share-gondola/site-group/${group.id}/skudata/${skuId}/activate`)
      .then(resp => {
        setDeactivating({ ...deactivating, [skuId]: false });
        setSkus(skus.filter(sku => sku.id !== skuId));
        notification.success(`Sku #${skuId} foi ativado.`);
      })
      .catch(err => {
        setDeactivating({ ...deactivating, [skuId]: false });
        notification.error('Houve um erro ao tentar ativar o SKU. Tente novamente mais tarde.');
      })
  };

  const skusFiltered = skus.filter(sku => {
    if (searchField.length === 0) return true;
    return sku.skuDescription.toUpperCase().includes(searchField.toUpperCase());
  });

  const addSkuData = (skuData) => {
    if(showActives === 0)
      return;
    
    const detectionLabel = detectionLabelOptions.find(d => parseInt(d.id) === parseInt(skuData.detectionLabelId)) || {}
    const categoryName = categoryOptions.find(c => parseInt(c.id) === parseInt(skuData.categoryId)) || {}
    const manufacturerName = manufacturerOptions.find(m => parseInt(m.id) === parseInt(skuData.manufacturerId)) || {}
    skuData.detectionLabelDescription = detectionLabel.label;
    skuData.categoryName = categoryName.category;
    skuData.manufacturerName = manufacturerName.name
    setSkus([ ...skus, skuData ]);
  };

  return <Modal
    handleToogleModal={props.closeModal}
    style={{ width: "90%", height: '95%' }}
    component={<Card style={{ height: '95%', overflow: 'auto' }}>
      <div style={{display: 'flex', justifyContent: 'space-between'}} >
        <p className="title is-4">Cadastro de SKU's</p>
        <div>
          <label className="label">Adicionar Sku com CSV:</label>
          <div className="file">
            <label className="file-label">
              <input className="file-input" type="file" name="resume" onChange={(e) => handleFileInput(e.target.files[0])} />
              <span className="file-cta">
                <span className="file-icon">
                  <i className="fas fa-upload"></i>
                </span>
                <span className="file-label">
                  {csvFileInput ? csvFileInput.name : 'Escolha um Arquivo...'}
                </span>
              </span>
            </label>
            {selectedFile ? <button style={{marginLeft: 10}} className="button is-primary" onClick={() => addSkuDataCsv()} >Adicionar</button>
            :
            <button style={{marginLeft: 10}} className="button is-static">Adicionar</button>}
          </div>
        </div>
      </div>

      <AddForm
        ref={refForm}
        groupId={group.id}
        siteSelect={siteSelect}
        setSiteSelect={setSiteSelect}
        sites={sites}
        handleAddList={addSkuData}
        showActives={showActives}
        detectionLabelOptions={detectionLabelOptions}
        isLoadingDetectionLabelOptions={isLoadingDetectionLabelOptions}
        categoryOptions={categoryOptions}
        manufacturerOptions={manufacturerOptions}
        isLoading={isLoading}
      />
      <div className="field">
        <input
          id="switchExample"
          type="checkbox"
          name="switchExample"
          className="switch"
          onChange={(e) => setShowActives(e.target.checked ? 1 : 0)}
          checked={showActives === 1}
        />
        <label htmlFor="switchExample">Ver somente ativos</label>
      </div>
      
      <div className="field">
        <div className="control has-icons-left ">
          <input
            className="input"
            placeholder="Pesquise pelo SKU"
            value={searchField}
            onChange={e => setSearchField(e.target.value)} />
          <SearchIcon className="is-left"/> 
        </div>   
      </div>

      <table className="table is-fullwidth">
        <thead>
          <tr>
            <th>ID</th>
            <th>Descrição</th>
            <th>Descrição(sem caracter especial)</th>
            <th>Label Detecção</th>
            <th>Categoria</th>
            <th>Fabricante</th>
            <th>Agrupador</th>
            <th>Largura (cm)</th>
            <th>Volume (ml)</th>
            <th>Opções</th>
            <th>
              <button className='button is-primary' onClick={() => setRefresh(true)}>
                Refresh  
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          {skusFiltered.map(sku => <SkuRow
            key={`key_sku_${sku.id}`}
            editSku={refForm.current?.addState}
            sku={sku}
            deactivating={deactivating}
            deactivateSku={deactivateSku}
            activateSku={activateSku}
          />)}
        </tbody>
      </table>
      {isLoading && <div style={{ display: 'flex', justifyContent: 'center'}}>
        <IsLoading isBorderless={true} fontSize={60} />
      </div>}
    </Card>}
  />
}

export default SkuDataModal;