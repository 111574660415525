import React, { Component } from 'react';

import withRouter from "react-router-dom/withRouter";
import { connect } from 'react-redux';
import Page from '../../../../../components/Page';
import Columns, { Column } from '../../../../../components/Columns';
import * as Icons from '../../../../../components/icons';
import CalcadoChart from './CalcadoChart';
import ParedaoChart from './ParedaoChart';
import BancoChart from './BancoChart';
import ConversaoChart from './ConversaoChart';
import IsLoading from '../../../../../components/IsLoading';
import { Sankey, SankeyNode, SankeyLink} from 'reaviz';
import { get } from '../../../../../utils';
import FunnelChart from './FunnelChar';

class CentauroDashboard extends Component {
  constructor(props){
    super(props);
    this.state = {
      loading: true,
      indexCard: 1,
      summary: {},
      calcadoData: {head:{visitantes: 0, percent:0}, data:[]},
      paredaoData: {head:{visitantes: 0, percent:0}, data:[]},
      bancoData: {head:{sentadas: 0, percent:0}, data:[]},
      caixaData: {head:{conversionRate: 0, percent:0}, data:[]}
    }
  }
  _selectCard = (indexCard) =>{
    this.setState({indexCard})
  }

  loadData (){
    const { site } = this.props;
    
    const summaryPromise = get(`dashboard/centauro/sites/${site.id}/summary`).then(resp => resp.data);
    const calcadoPromise = get(`dashboard/centauro/sites/${site.id}/calcado`).then(resp => resp.data);
    const paredaoPromise = get(`dashboard/centauro/sites/${site.id}/paredao`).then(resp => resp.data);
    const bancoPromise = get(`dashboard/centauro/sites/${site.id}/banco`).then(resp => resp.data);
    const caixaPromise = get(`dashboard/centauro/sites/${site.id}/conversao`).then(resp => resp.data);

    Promise.all([calcadoPromise, paredaoPromise, bancoPromise, caixaPromise, summaryPromise])
    .then(promises =>{
      this.setState({calcadoData: promises[0].data, paredaoData: promises[1].data, bancoData: promises[2].data, caixaData: promises[3].data, summary:promises[4].data,  loading:false})
    })
  }
  _orderValueSummary = summary =>{
    const array =[{value:0, name: 'tempoMedioCalcados', time: summary.tempoMedioCalcados}, {value:0, name: 'totalTempoMedioAtendidas', time: summary.totalTempoMedioAtendidas}, 
                  {value:0, name: 'totalTempoMedioInteracao', time: summary.totalTempoMedioInteracao},{value:0, name: 'totalBancoTempoAteSentar', time: summary.totalBancoTempoAteSentar}, 
                  {value:0, name: 'totalBancoTempo', time: summary.totalBancoTempo}, {value:0, name: 'totalTempoMedioFila', time: summary.totalTempoMedioFila}];
    let val = 80
    array.sort((a,b) => a.time - b.time).forEach(arr => {
      arr.value = val;
      val+= 4
    })
    return array
  }
  _getSummary = (name, summary) =>{
    return summary.find(s => s.name === name)
  }
  _secondsToLabel = (val) => {
    if(val === 0)
      return `0 min 0 seg`;

    const [ minutes, seconds='0' ] = `${val/60}`.split('.');
    const secondsLabel = `${60 * parseInt(seconds.slice(0,2)) / 10}`.slice(0, 2);

    if (seconds === '0' || parseInt(secondsLabel) >= 60)
      return `${minutes} min`;
      

    return `${minutes} min ${secondsLabel} seg`;
  }
  componentDidMount() {
    this.loadData()
  }
  render(){
    const { group, site } = this.props;
    const {indexCard, calcadoData, bancoData, paredaoData, caixaData, summary, loading} = this.state;
    let summarySort = []
    if(!loading) {
      summarySort = this._orderValueSummary(summary);
    }
    return (<Page>
      <Columns isMultiline>
        <Column isSize={12}>
          <p className="title">{group.name} - {site.name} </p>
          <p className="subtitle"><Icons.HomeIcon/> Tráfego da loja</p>
        </Column>
        {!loading && <Column isSize={4}>
          <div className="card notification">
            <div className="card-content" style={{padding:10}}>
              <p style={{marginBottom: 10}} className="subtitle has-text-centered">Tempo médio</p>
              <ProgressBarTime
                isLoading={false}
                isBig={false}
                description="Área de Calçados"
                rate={this._getSummary('tempoMedioCalcados',summarySort).value }
                value={this._secondsToLabel(this._getSummary('tempoMedioCalcados',summarySort).time)}
                progressColor={'#B63545'}
              />
              <ProgressBarTime
                isLoading={false}
                isBig={false}
                description="Entrada até ser Atendido"
                rate={this._getSummary('totalTempoMedioAtendidas',summarySort).value}
                value={this._secondsToLabel(this._getSummary('totalTempoMedioAtendidas',summarySort).time )}
                progressColor={'rgb(244, 109, 67)'}
              />
              <ProgressBarTime
                isLoading={false}
                isBig={false}
                description="Interação no Paredão"
                rate={this._getSummary('totalTempoMedioInteracao',summarySort).value}
                value={this._secondsToLabel(this._getSummary('totalTempoMedioInteracao',summarySort).time)}
                progressColor={'rgb(155 156 50)'}
              />
              <ProgressBarTime
                isLoading={false}
                isBig={false}
                description="Entrada até Sentar"
                rate={this._getSummary('totalBancoTempoAteSentar',summarySort).value}
                value={this._secondsToLabel(this._getSummary('totalBancoTempoAteSentar',summarySort).time)}
                progressColor={'rgb(253, 174, 97)'}
              />
              <ProgressBarTime
                isLoading={false}
                isBig={false}
                description="Permanência Sentado"
                rate={this._getSummary('totalBancoTempo',summarySort).value}
                value={this._secondsToLabel(this._getSummary('totalBancoTempo',summarySort).time)}
                progressColor={'rgb(72 118 230)'}
              />
              <ProgressBarTime
                isLoading={false}
                isBig={false}
                description="Permanência na Fila"
                rate={this._getSummary('totalTempoMedioFila',summarySort).value}
                value={this._secondsToLabel(this._getSummary('totalTempoMedioFila',summarySort).time)}
                progressColor={'rgb(90 181 80)'}
              />
            </div>
          </div>
        </Column>}
        {!loading && <Column isSize={5}>
          <div className="card notification" style={{padding:0}}>
            <div className="card-content" style={{padding:20, background: "rgb(55, 55, 55)"}}>
            <Sankey
              colorScheme={["rgba(158, 1, 66, 0.9)", "rgba(213, 62, 79, 0.9)", "rgba(244, 109, 67, 0.9)", 
                            "rgba(253, 174, 97, 0.9)", "rgba(254, 224, 139, 0.9)", "rgba(255, 255, 191, 0.9)", 
                            "rgba(171, 221, 164, 0.9)", "rgba(174, 174, 174, 0.9)"]}
              height={354}
              width={"100%"}
              nodes={[
                
                <SankeyNode key={`foo-010`} title="Fora da Loja" id="*" />,
                <SankeyNode key={`foo-0`} title="Entraram" id="entrada" value={summary.initEntry} />,
                <SankeyNode key={`foo-1`} title="Calçados" id="calcados" />,
                <SankeyNode key={`foo-2`} title="Atendidos" id="atendido" />,
                <SankeyNode key={`foo-3`} title="Banco" id="banco" />,
                <SankeyNode key={`foo-5`} title="Caixa" id="caixa" />,
                <SankeyNode key={`foo-6`} title="Venda" id="venda" />,
                <SankeyNode key={`foo-7`} title="Perda" id="perda" />,
                <SankeyNode key={`foo-7`} title="Outras Áreas" id="outros" />,
              ]}
              links={
                summary.data.map(({orig, dest, count}, index) => 
                <SankeyLink key={`fo2-${index}`} 
                            opacity={(active, disabled) => active ? 0.8 : disabled ? 0.35 : 0.5} 
                            source={orig} 
                            target={dest} 
                            value={count}  />) 
              }
              />
            </div>
          </div>
        </Column>}
        {!loading && <Column isSize={3}>
          <div className="card notification" style={{padding:0, minHeight: 393}}>
            <div className="card-content">
             <FunnelChart />
            </div>
          </div>
        </Column>}
        {!loading && <Column isSize={12}>
          <div className="card">
            <div className="card-content" style={{padding:0}}>
              <Columns isGapless>
                <Column style={{padding:0}}>
                  <div className={`isScore-indice ${indexCard === 1 ? 'indice-active': ''}`} style={{padding:24}} onClick={() => this._selectCard(1)}>
                    <p style={{margin:0}} className="heading has-text-centered  is-size-7">Calçados </p>
                    <Columns style={{display: 'flex', alignItems: 'baseline', margin:0}}isGapless>
                      <Column className= "has-text-centered">
                        <p style={{margin: 0}} className="title is-size-2">{calcadoData.head.visitantes} </p>
                        <p style={{margin: 0}} className={`${calcadoData.head.percent <0 ? 'has-text-danger': 'has-text-primary' } has-text-weight-bold`}> {calcadoData.head.percent}%</p>
                        <span style={{margin: 0}} className="heading">Visitantes</span>
                      </Column>
                      <Column className= "has-text-centered"> 
                        <p style={{margin: 0}} className="title is-size-5">{this._secondsToLabel(calcadoData.head.tempoMedio)} </p>
                        <p style={{margin: 0}} className={`${calcadoData.head.percentTempo <0 ? 'has-text-danger': 'has-text-primary' } has-text-weight-bold`}> {calcadoData.head.percentTempo}%</p>
                        <span style={{margin: 0}} className="heading">Tempo Médio</span>
                      </Column>
                    </Columns>
                  </div>
                </Column>
                <Column style={{padding:0}}>

                  <div className={`isScore-indice ${indexCard === 2 ? 'indice-active': ''}`} style={{padding:24}}  onClick={() => this._selectCard(2)}>
                    <p style={{margin:0}} className="heading has-text-centered   is-size-7">Paredão </p>
                    <Columns style={{display: 'flex', alignItems: 'baseline', margin:0}}isGapless>
                      <Column className= "has-text-centered">
                        <p style={{margin: 0}} className="title is-size-2">{paredaoData.head.visitantes}</p>
                        <p style={{margin: 0}}className={`${paredaoData.head.percent <0 ? 'has-text-danger': 'has-text-primary' } has-text-weight-bold`}>{paredaoData.head.percent}%</p>
                        <span style={{margin: 0}} className="heading">Visitantes</span>
                      </Column>
                      <Column className= "has-text-centered">
                        <p style={{margin: 0}} className="title is-size-5">{this._secondsToLabel(paredaoData.head.tempoMedioInteracao)}</p>
                        <p style={{margin: 0}}className={`${paredaoData.head.percentTempo <0 ? 'has-text-danger': 'has-text-primary' } has-text-weight-bold`}>{paredaoData.head.percentTempo}%</p>
                        <span style={{margin: 0}} className={`heading `}>Tempo Médio Interação</span>
                      </Column>
                    </Columns>
                  </div>
                </Column>

                <Column style={{padding:0}}>

                  <div className={`isScore-indice ${indexCard === 3 ? 'indice-active': ''}`}  style={{padding:24}}  onClick={() => this._selectCard(3)}>
                    <p style={{margin:0}} className="heading has-text-centered   is-size-7">Banco</p>
                    <Columns style={{display: 'flex', alignItems: 'baseline', margin:0}}isGapless>
                      <Column className= "has-text-centered">
                        <p style={{margin: 0}} className="title is-size-2">{bancoData.head.sentadas}</p>
                        <p style={{margin: 0}} className={`${bancoData.head.percent <0 ? 'has-text-danger': 'has-text-primary' } has-text-weight-bold`} > {bancoData.head.percent}%</p>
                        <span style={{margin: 0}} className="heading">Visitantes Sentados</span>
                      </Column>
                      <Column className= "has-text-centered">
                        <p style={{margin: 0}} className="title is-size-5">{this._secondsToLabel(bancoData.head.tempoMedio)}</p>
                        <p style={{margin: 0}} className={`${bancoData.head.percentTempo <0 ? 'has-text-danger': 'has-text-primary' } has-text-weight-bold`} > {bancoData.head.percentTempo}%</p>
                        <span style={{margin: 0}} className={`heading `}>Tempo Médio Sentado</span>
                      </Column>
                    </Columns>
                  </div>
                </Column>
                <Column style={{padding:0}}>
                  <div className={`isScore-indice ${indexCard === 4 ? 'indice-active': ''}`}  style={{padding:24}}  onClick={() => this._selectCard(4)}>
                    <p className="heading has-text-centered   is-size-7" style={{margin:0}}>Percentual de Vendas </p>
                    <Columns style={{display: 'flex', alignItems: 'baseline', margin:0}}isGapless>
                      <Column className= "has-text-centered">
                        <p style={{margin: 0}} className="title is-size-2">{caixaData.head.conversionRate}%</p>
                        <p className={`${caixaData.head.percent <0 ? 'has-text-danger': 'has-text-primary' } has-text-weight-bold`}> {caixaData.head.percent}%</p>
                        <span style={{margin: 0}} className="heading">conversão</span>
                      </Column>
                    </Columns>
                  </div>
                </Column>
              </Columns>

            </div>
          </div>
          <Columns isMultiline>
            <Column isSize={12}>
              <div className="card">  
                <div className="card-content">
                  <div className="content" >
                    {indexCard === 1 && <CalcadoChart data={calcadoData.data} conversaoData={caixaData.data}/>}
                    {indexCard === 2 && <ParedaoChart  data={paredaoData.data}/>}
                    {indexCard === 3 && <BancoChart  data={bancoData.data}/> }
                    {indexCard === 4 && <ConversaoChart  data={caixaData.data}/>}
                  </div>
                </div>
              </div>
            </Column>
          </Columns>
        </Column>}
      </Columns>
    </Page>)
  }
}

const ProgressBarTime = ({ description, rate, progressColor, isBig=false, isLoading, value }) => {
  if (isLoading) {
    return <div style={{
      width: '100%',
      borderRadius: 5,
      height: isBig ? 50 : 40,
      backgroundColor: '#CCCCCC',
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 10
    }}>
      <IsLoading isBorderless={true} fontSize={isBig ? 20 : 12} backgroundColor={"#cccccc"}/>
    </div>
  }
  return <div style={{
    width: '100%'
  }}>
    <div style={{
      width: '100%',
      borderRadius: 5,
      height: isBig ? 50 : 40,
      marginBottom: 10
    }}>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: `${rate > 100 ? 100: rate}%`,
        minWidth: '1%',
        borderRadius: 5,
        height: isBig ? 50 : 40,
        backgroundColor: progressColor
      }}>
        <span style={{
          fontWeight: isBig ? 'bold' : '',
          color: 'white',
          whiteSpace: 'nowrap',
          fontSize: isBig ? '1.5rem' : '1rem',
          marginLeft: 10
        }}>
          {description}
        </span>
        <div style={{
          margin: 5,
          padding: 5,
          fontSize: isBig ? '1rem' : '0.7rem',
          textDecoration: 'weight',
          backgroundColor: '#ffffff',
          justifyContent: 'center',
          display: 'flex',
          alignItems: 'center',
          borderRadius: 15,
          minWidth: 40
        }}>
          <b>{value}</b>
        </div>
      </div>
    </div>
  </div>
}

function mapStateToProps(state, props) {
  return {
    tooqAdmin: true,
    site: props.site
  }
}

export default withRouter(connect(mapStateToProps)(CentauroDashboard));