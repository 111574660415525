import React, { Component } from 'react';
import { format, subDays } from 'date-fns';
import withRouter from "react-router-dom/withRouter";
import { connect } from 'react-redux';
import tinycolor from "tinycolor2";

import chroma from 'chroma-js';
import Select from 'react-select';

import Page, { PageTitle } from '../../../../components/Page';
import Columns, { Column } from '../../../../components/Columns';
import { FillIcon, SyncAltIcon } from '../../../../components/icons';

import { get, getServerDate } from '../../../../utils';
import {
  Area,
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';


const dot = (color = '#ccc') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 10,
    width: 10,
  },
});

const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? data.color === '#FFFFFF' ? '#EEEEEE' : data.color
        : isFocused
        ? data.color === '#FFFFFF' ? '#EEEEEE' : color.alpha(0.1).css()
        : null,
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? chroma.contrast(color, 'white') > 2
          ? 'white'
          : 'black'
        : data.color === "#EEEEEE" ? "#222222" : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor:
          !isDisabled && (isSelected ? data.color: color.alpha(0.3).css()),
      },
    };
  },
  input: styles => ({ ...styles, ...dot() }),
  placeholder: styles => ({ ...styles, ...dot() }),
  singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
};


class CustomToolTipCompare extends Component {


  _secondsToLabel = (val) => {
    if(val === 0)
      return `0 min 0 seg`;

    const [ minutes, seconds='0' ] = `${val/60}`.split('.');
    const secondsLabel = `${60 * parseInt(seconds.slice(0,2)) / 10}`.slice(0, 2);

    if (seconds === '0' || parseInt(secondsLabel) >= 60)
      return `${minutes} min`;
      

    return `${minutes} min ${secondsLabel} seg`;
  }

  render() {
    const { active } = this.props;
    if(!active)
      return null;

    const { payload=[] } = this.props;
    const data = payload[0] || { payload: {} };

    return (<div className="card notification" style={{padding: '5px'}}>
      <p className="heading has-text-centered">
        Em {format(data.payload.leftTag.label, 'DD/MM HH:00')}
      </p>
      {data.dataKey === 'leftTag.rupturesMeanTime' ? <div>
        <p
          className="heading has-text-centered"
          style={{
            fontWeight: 'bold',
            color: '#222'
          }}>
          <span>{data.name}</span>
        </p>
        
        <div className="heading has-text-centered" style={{ color: data.payload.leftTag.color }}>
          <div style={{
            fontWeight: 'bold'
          }}>
            #{data.payload.leftTag.description}: {this._secondsToLabel(data.payload.leftTag.rupturesMeanTime)}
          </div>
        </div>
        <div className="heading has-text-centered" style={{ color: data.payload.rightTag.color }}>
          <div style={{
            fontWeight: 'bold'
          }}>
            #{data.payload.rightTag.description}: {this._secondsToLabel(data.payload.rightTag.rupturesMeanTime)}
          </div>
        </div>
      </div>
      :
      <div>
        <p
          className="heading has-text-centered"
          style={{
            fontWeight: 'bold',
            color: '#222'
          }}>
          <span>Tipo de Rupturas</span>
        </p>
        <div className="heading has-text-centered" style={{ color: data.payload.leftTag.color }}>
          <div style={{
            fontWeight: 'bold'
          }}>
            Ruptura Padrão: {data.payload.leftTag.rupturesCountType0}
          </div> 
        </div>

        <div className="heading has-text-centered" style={{ color: data.payload.rightTag.color }}>
          <div style={{
            fontWeight: 'bold'
          }}>
            Ruptura Padrão: {data.payload.rightTag.rupturesCountType0}
          </div> 
        </div>
        <div className="heading has-text-centered" style={{ color: data.payload.leftTag.color }}>
          <div style={{
            fontWeight: 'bold'
          }}>
            Falha no planograma: {data.payload.leftTag.rupturesCountType1}
          </div> 
        </div>

        <div className="heading has-text-centered" style={{ color: data.payload.rightTag.color }}>
          <div style={{
            fontWeight: 'bold'
          }}>
            Falha no planograma: {data.payload.rightTag.rupturesCountType1}
          </div> 
        </div>
        <div className="heading has-text-centered" style={{ color: data.payload.leftTag.color }}>
          <div style={{
            fontWeight: 'bold'
          }}>
            Outros: {data.payload.leftTag.rupturesCountType2}
          </div> 
        </div>

        <div className="heading has-text-centered" style={{ color: data.payload.rightTag.color }}>
          <div style={{
            fontWeight: 'bold'
          }}>
            Outros: {data.payload.rightTag.rupturesCountType2}
          </div> 
        </div>
      </div>}
    </div>)
  }
}



class CustomToolTip extends Component {


  _secondsToLabel = (val) => {
    if(val === 0)
      return `0 min 0 seg`;

    const [ minutes, seconds='0' ] = `${val/60}`.split('.');
    const secondsLabel = `${60 * parseInt(seconds.slice(0,2)) / 10}`.slice(0, 2);

    if (seconds === '0' || parseInt(secondsLabel) >= 60)
      return `${minutes} min`;
      

    return `${minutes} min ${secondsLabel} seg`;
  }

  render() {
    const { active } = this.props;
    if(!active)
      return null;

    const { payload=[], colorHash } = this.props;
    const data = payload && payload.length > 0 ? payload[0] : { payload: {} };

    return (<div className="card notification" style={{padding: '5px'}}>
      <p className="heading has-text-centered">
        Em {format(data.payload.label, 'DD/MM HH:00')}
      </p>
      {data.dataKey === 'rupturesMeanTime' ? <div>
        <p
          className="heading has-text-centered"
          style={{
            fontWeight: 'bold',
            color: '#222'
          }}>
          <span>{data.name}</span>
        </p>
        <div className="heading has-text-centered" style={{ color: data.fill }}>
          <div style={{
            fontWeight: 'bold'
          }}>
            {this._secondsToLabel(data.payload.rupturesMeanTime)}
          </div> 
        </div> 
      </div>: <div>
        <p
          className="heading has-text-centered"
          style={{
            fontWeight: 'bold',
            color: '#222'
          }}>
          <span>Ruptura Padrão</span>
        </p>
        <div className="heading has-text-centered" style={{ color: colorHash, opacity: 1 }}>
          <div style={{
            fontSize: '20px',
            fontWeight: 'bold'
          }}>
          {data.payload.rupturesCountType0}
          </div> 
        </div> 
        <p
          className="heading has-text-centered"
          style={{
            fontWeight: 'bold',
            color: '#222'
          }}>
          <span>Falha no planograma</span>
        </p>
        <div className="heading has-text-centered" style={{ color: colorHash, opacity: 0.7 }}>
          <div style={{
            fontSize: '20px',
            fontWeight: 'bold'
          }}>
          {data.payload.rupturesCountType1}
          </div> 
        </div> 
        <p
          className="heading has-text-centered"
          style={{
            fontWeight: 'bold',
            color: '#222'
          }}>
          <span>Outros</span>
        </p>
        <div className="heading has-text-centered" style={{ color: colorHash, opacity: 0.5 }}>
          <div style={{
            fontSize: '20px',
            fontWeight: 'bold'
          }}>
          {data.payload.rupturesCountType2}
          </div> 
        </div> 
      </div>}
      
    </div>)
  }
}

class DashGondolaAnalytics extends Component {

  constructor(props) {
    super(props);

    this.gondolasRef = {};
    this.state = {
      gondolas: [],
      generalSLA: {},
      selectedShelf: {},
      shelfAnalytics: {},
      shelfAnalyticsRange: {
        startDate: format(subDays(getServerDate(), 1), 'YYYY-MM-DD'),
        endDate: format(subDays(getServerDate(), 1), 'YYYY-MM-DD')
      },
      shelfOver: 0,
      isLoadingGondolas: false,
      isLoadingShelf: false,
      isLoadingGeneralSLA: false,
      isLoadingShelfCategories: false,
      shelfCategories: [],
      leftShelfCategory: {},
      rightShelfCategory: {},
      leftShelfCategoryData: {},
      rightShelfCategoryData: {},
      gondolasLive: {}
    }
  }

  componentDidMount() {
    this._loadGondolas();
    this._loadShelfCategories();
  }

  componentWillUnmount() {
    clearInterval(this.intervalTimer);
  }

  _loadCategoryData = () => {
    const {
      leftShelfCategory,
      rightShelfCategory,
      isLoadingShelfCategoryCharts
    } = this.state;
    const { site } = this.props;

    if(isLoadingShelfCategoryCharts)
      return;

    this.setState({ isLoadingShelfCategoryCharts: true }, () => {
      const getLeft = get(`dashboard/shelf/sites/${site.id}/category/${leftShelfCategory.value}/daily`, {
        begin: format(subDays(getServerDate(), 1), 'YYYY-MM-DD'),
        end: format(subDays(getServerDate(), 1), 'YYYY-MM-DD')
      });

      const getRight = get(`dashboard/shelf/sites/${site.id}/category/${rightShelfCategory.value}/daily`, {
        begin: format(subDays(getServerDate(), 1), 'YYYY-MM-DD'),
        end: format(subDays(getServerDate(), 1), 'YYYY-MM-DD')
      });

      Promise.all([ getLeft, getRight ])
        .then(data => {
          const [ respLeftData, respRightData ] = data;
          const leftData = respLeftData.data.data;
          const rightData = respRightData.data.data;

          this.setState({
            isLoadingShelfCategoryCharts: false,
            shelfCategoryChartData: leftData.ruptures.map(rupture => {
              const leftTag = leftData.ruptures.find(left => left.label === rupture.label);
              const rightTag = rightData.ruptures.find(right => right.label === rupture.label);
              return {
                leftTag: {
                  color: leftData.colorHash,
                  description: leftData.description,
                  ...leftTag
                },
                rightTag: {
                  color: rightData.colorHash,
                  description: rightData.description,
                  ...rightTag
                }
              }
            }),
            leftShelfCategoryData: leftData,
            rightShelfCategoryData: rightData
          })
        })
    });
  }

  _loadShelfLiveState = () => {
    const { site } = this.props;
    const { isLoadingShelfLiveState, gondolas } = this.state;
    if(isLoadingShelfLiveState)
      return true

    this.setState({ isLoadingShelfLiveState: true }, () => {
      const gondolasLiveState = gondolas.map(gondola => get(`dashboard/shelf/sites/${site.id}/live/${gondola.id}`).then(resp => ({ ...gondola, lastLiveStateUpdate: format(getServerDate(), 'DD/MM HH:mm:ss'),  liveState: resp.data.data })));
      Promise.all(gondolasLiveState)
        .then(gondolaLiveState => {
          this.setState({
            isLoadingShelfLiveState: false,
            lastLiveStateUpdate: format(getServerDate(), 'DD/MM HH:mm:ss'),
            gondolas: gondolaLiveState
          });
        });
    });
  }

  _loadShelfCategories = () => {
    const { isLoadingShelfCategories } = this.state;
    const { site } = this.props;

    if(isLoadingShelfCategories)
      return;

    this.setState({ isLoadingShelfCategories: true }, () => {
      get(`dashboard/shelf/sites/${site.id}/shelfcategory`).then(resp => {
        const { data } = resp.data;
        const shelfCategories = data.map(category => ({
          value: category.id,
          label: `#${category.description}`,
          color: category.colorHash}));
        this.setState({
          isLoadingShelfCategories: false,
          shelfCategories,
          leftShelfCategory: shelfCategories[0],
          rightShelfCategory: shelfCategories[1]
        });
      });
    });
  }

  _loadSLA = () => {
    const { isLoadingGeneralSLA } = this.state;
    const { site } = this.props;

    if(isLoadingGeneralSLA)
      return;

    this.setState({ isLoadingGeneralSLA: true }, () => {
      get(`dashboard/shelf/sites/${site.id}/generalsla`, {
        begin: format(getServerDate(), 'YYYY-MM-DD'),
        end: format(getServerDate(), 'YYYY-MM-DD')
      }).then(resp => {
        const { data } = resp.data;
        this.setState({ isLoadingGeneralSLA: false, generalSLA: data }, () => {
          this._loadShelfAnalytics(data.venuesRuptures[0].venue);
        });
      });
    });
  }

  _loadGondolas = () => {
    const { site } = this.props;
    this.setState({ isLoadingGondolas: true }, () => {
      get(`dashboard/shelf/sites/${site.id}/gondolas`)
        .then(resp => {
          const { data } = resp.data;
          this.setState({ isLoadingGondolas: false, gondolas: data }, this._loadShelfLiveState);
        });
    });
  }

  _loadShelfAnalytics = (shelf) => {
    const { shelfAnalyticsRange, isLoadingShelf } = this.state;
    const { startDate, endDate } = shelfAnalyticsRange;

    if(isLoadingShelf)
      return;

    const { site } = this.props;
    this.setState({
      isLoadingShelf: true,
      selectedShelf: shelf,
      shelfAnalytics: {}
    }, () => {
      get(`dashboard/shelf/sites/${site.id}/venues/${shelf.id}/hourly`, {
        begin: startDate,
        end: endDate
      })
      .then(resp => {
        const { data } = resp.data;
        this.setState({
          isLoadingShelf: false,
          shelfAnalytics: data
        });
      });
    });
  }

  _secondsToLabel = (val) => {
    if(val === 0 || !val)
      return "0s";

    let [ minutes, seconds='0' ] = `${val/60}`.split('.');
    if (parseInt(minutes) >= 60) {
      const [h, minutos='0' ] = `${(parseInt(minutes) / 60)}`.split('.');

      if (minutes === '0' || parseInt(minutos.slice(0,2)) > 60)
        return `${h}h`;

      return `${h}h ${minutos.slice(0,2)}m`;
    }

    const secondsLabel = `${60 * parseInt(seconds.slice(0,2)) / 10}`.slice(0, 2);

    if (seconds === '0' || parseInt(secondsLabel) >= 60)
      return `${minutes}m`;

    return `${minutes}m ${secondsLabel}s`;
  }
  hexToRGB = (hex, alpha) => {
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
        return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
        return "rgb(" + r + ", " + g + ", " + b + ")";
    }
  }

  render() {
    const { site } = this.props;
    const {
      gondolas,
      shelfOver,
      isLoadingGondolas,
      isLoadingShelf,
      selectedShelf,
      shelfAnalytics,
      isLoadingShelfCategories,
      shelfCategories,
      leftShelfCategory,
      rightShelfCategory,
      leftShelfCategoryData,
      rightShelfCategoryData,
      isLoadingShelfCategoryCharts,
      shelfCategoryChartData=[]
    } = this.state;

    return <Page>
      <PageTitle
        title={<span>
          <span style={{ marginRight: '10px' }}>
            {`${(site && site.name) || ''} `}
          </span>
          <p className="subtitle">Ánalise de Gôndolas <FillIcon /></p>
        </span>}
      />
      <Columns isMultiline style={{ justifyContent: 'center' }}>
        {isLoadingGondolas && <Column className="is-narrow">
          <div
          className="card notification"
          style={{width: 850, height:400}}>
            Aguarde...
          </div>
        </Column>}
        {!isLoadingGondolas && <Column className="is-narrow" style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}>
          {gondolas.map(gondola => {
            const { id, name, src, shelfs, lastLiveStateUpdate, liveState=[] } = gondola;
            const idsInLiveState = liveState.map(live => live.venueId);

            //TODO: Original deve vir da imagem e Seggested deve ser pego do display 
            let originalHeight = 432;
            let originalWidth = 850;

            if (id === 3) {
              originalWidth = 800;
              originalHeight = 550;
            }

            let suggestedWidth = 850;
            let suggestedHeight = 432;

            if (id === 3) {
              suggestedWidth = 800;
              suggestedHeight = 550;
            }

            return <div key={`gondola-${id}`} className="card notification"  style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}>
              <p className="title is-4">{name}</p>
              <svg
                style={{ borderRadius: '3px 3px 3px 3px' }}
                width={suggestedWidth}
                height={suggestedHeight}
              >
              <image
                x={0}
                y={0}
                xlinkHref={src}
                width={suggestedWidth}
                height={suggestedHeight}
              />
              {
                shelfs.map((shelf) => {
                  const isInLiveStateRupture = idsInLiveState.includes(shelf.id);
                  const isOverShelftTop5 = shelfOver === shelf.id;
                  const isSelected = (selectedShelf.id && selectedShelf.id === shelf.id);
                  const { id, points } = shelf;
                  const [x, y, w, h] = points.split(',');
                  
                  const yFactor = suggestedHeight/originalHeight;
                  const xFactor = suggestedWidth/originalWidth;

                  const newX = parseInt(parseInt(x) * xFactor);
                  const newY = parseInt(parseInt(y) * yFactor);
                  const newW = parseInt(parseInt(w) * xFactor);
                  const newH = parseInt(parseInt(h) * yFactor);

                  const fill = isInLiveStateRupture ? '#E92641' : isOverShelftTop5 ? "#ffffff" : "#0043ff";
                  return (<rect
                    key={`shelf_svg_draw_id${id}`}
                    stroke="#000000"
                    cursor={isLoadingShelf ? "wait": "pointer"}
                    id={`shelf_svg_draw_id${id}`}
                    height={newH}
                    width={newW}
                    strokeWidth="1.5"
                    fill={fill}
                    y={newY}
                    x={newX}
                    fillOpacity={(isSelected || isInLiveStateRupture) ? 0.6 : isOverShelftTop5 ? 0.6 : 0.1}
                    onClick={() => this._loadShelfAnalytics(shelf)}
                  />);
                })
              }
            </svg>
            <p className="help">Última atualização às {lastLiveStateUpdate} <SyncAltIcon style={{cursor: 'pointer'}} onClick={this._loadShelfLiveState} /></p>
          </div>
          })}
        </Column>}
        <Column isSize={12}>
          {isLoadingShelf && <div className="card notification">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: 500,
              }}>
              <p className="subtitle is-3">Aguarde Carregando...</p>
            </div>
          </div>}
          {!isLoadingShelf && !selectedShelf.id && <div 
                className="card notification"
                style={{backgroundColor: '#eee'}}>
            <Columns isMultiline>
              <Column isSize={12}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: 500,
                    backgroundColor: '#eee'
                  }}>
                  <p className="subtitle is-3">Selecione uma prateleira à cima para detalhar</p>
                </div>
              </Column>
              <Column
                isSize={12}>
              </Column>
            </Columns>
          </div>}
          {!isLoadingShelf && selectedShelf.id && <div className="card notification">
            <p className="subtitle is-3 has-text-centered">{selectedShelf.description}</p>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
              {selectedShelf.categories.map(category => <span
                key={`shelf_category_key_${category.id}`}
                className="tag"
                style={{
                  border: '1px solid #eee',
                  backgroundColor: category.colorHash,
                  margin: 5,
                  color: tinycolor(category.colorHash).isDark() ? '#FFFFFF':'#222222'
                }}>
                #{category.description}
              </span>)}
            </div>
            <Columns isMultiline>
              <Column isSize={12}>
                <ResponsiveContainer width="100%" height={250}>
                  <ComposedChart data={shelfAnalytics}
                    syncId="gondola_chart_sync"
                    margin={{top: 20, right: 10, left: 10, bottom: 20}}>
                    <CartesianGrid strokeDasharray="3 10" />
                    <XAxis dataKey="label" tickFormatter={(val) => `${format(val, `DD/MM HH:00`)}`}/>
                    <YAxis yAxisId="left" orientation="left" stroke="#343434" allowDecimals={false}/>
                    <Legend />
                    <Tooltip
                      content={<CustomToolTip colorHash={selectedShelf.categories[1]?.colorHash ?? "#222222"}/>}
                      isAnimationActive={false}/>
                    <Bar
                      key="rupturesCountType0"
                      yAxisId="left"
                      name="Ruptura Padrão"
                      dataKey="rupturesCountType0"
                      maxBarSize={15}
                      fill={this.hexToRGB(selectedShelf.categories[1]?.colorHash ?? "#222222", 1)}
                      isAnimationActive={false}
                      minPointSize={3}
                      connectNulls={true}
                      stackId="a"
                    />
                    <Bar
                      key="rupturesCountType1"
                      yAxisId="left"
                      name="Falha no planograma"
                      dataKey="rupturesCountType1"
                      maxBarSize={15}
                      fill={this.hexToRGB(selectedShelf.categories[1]?.colorHash ?? "#222222", 0.7)}
                      isAnimationActive={false}
                      minPointSize={3}
                      connectNulls={true}
                      stackId="a"
                    />
                    <Bar
                      key="rupturesCountType2"
                      yAxisId="left"
                      name="Outros"
                      dataKey="rupturesCountType2"
                      maxBarSize={15}
                      fill={this.hexToRGB(selectedShelf.categories[1]?.colorHash ?? "#222222", 0.5)}
                      isAnimationActive={false}
                      minPointSize={3}
                      connectNulls={true}
                      stackId="a"
                    />
                  </ComposedChart>
                </ResponsiveContainer>
              </Column>
              <Column isSize={12}>
                <ResponsiveContainer width={"100%"} height={250}>
                  <ComposedChart data={shelfAnalytics}
                      syncId="gondola_chart_sync"
                      margin={{top: 20, right: 10, left: 10, bottom: 20}}>
                    <CartesianGrid strokeDasharray="3 10" />
                    <XAxis dataKey="label" tickFormatter={(val) => `${format(val, `DD/MM HH:00`)}`}/>
                    <YAxis yAxisId="left" tickFormatter={val => this._secondsToLabel(val)} orientation="left" stroke="#343434" />
                    <Legend content={this.renderChartLegend} />
                    <Tooltip
                      content={<CustomToolTip />}
                      isAnimationActive={false}/>
                    <Area
                      type="monotone" 
                      key="rupturesMeanTime"
                      yAxisId="left"
                      name="Tempo médio de ruptura"
                      dataKey="rupturesMeanTime"
                      strokeDasharray="3 5"
                      fillOpacity={0.3}
                      fill={selectedShelf.categories[1]?.colorHash ?? "#222222"}
                      stroke={selectedShelf.categories[1]?.colorHash ?? "#222222"}
                      isAnimationActive={false}
                      connectNulls={true}
                    />
                  </ComposedChart>
                </ResponsiveContainer>
              </Column>
            </Columns>
          </div>}
          {!isLoadingShelfCategories && shelfCategories.length === 0 && <div 
                className="card notification"
                style={{backgroundColor: '#eee'}}>
            <Columns isMultiline>
              <Column isSize={12}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: 500,
                    backgroundColor: '#eee'
                  }}>
                  <p className="subtitle is-3">Não há categorias</p>
                </div>
              </Column>
            </Columns>
          </div>}
          {isLoadingShelfCategories && <div className="card notification">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: 500,
              }}>
              <p className="subtitle is-3">Aguarde Carregando...</p>
            </div>
          </div>}
          {!isLoadingShelfCategories && shelfCategories.length !== 0 && <div className="card notification">
            <p className="subtitle is-3 has-text-centered">Comparar tags</p>
            <Columns style={{ justifyContent: 'space-arround'}}>
              <Column>
                <Select
                  defaultValue={leftShelfCategory}
                  label="Single select"
                  options={shelfCategories}
                  styles={colourStyles}
                  onChange={(val) => {
                    this.setState({leftShelfCategory: val});
                  }}
                />
              </Column>
              <Column isSize={'is-narrow'}>
                <span
                  className={`button is-primary ${isLoadingShelfCategoryCharts ? 'is-loading': ""}`}
                  onClick={() => this._loadCategoryData()}>
                  Comparar
                </span>
              </Column>
              <Column>
                <Select
                  defaultValue={rightShelfCategory}
                  label="Single select"
                  options={shelfCategories}
                  styles={colourStyles}
                  onChange={(val) => {
                    this.setState({rightShelfCategory: val});
                  }}
                />
              </Column>
            </Columns>
            <Columns isMultiline>
              {isLoadingShelfCategoryCharts && <Column isSize={12}>
                  <div style={{ height: 500 }}>
                  </div>
              </Column>}
              {!isLoadingShelfCategoryCharts && shelfCategoryChartData.length === 0  &&<Column isSize={12}>
                  <div style={{ height: 500 }}>
                  </div>
              </Column>}
              {!isLoadingShelfCategoryCharts && shelfCategoryChartData.length !== 0 && <Column isSize={12}>
                <ResponsiveContainer width="100%" height={250}>
                  <ComposedChart data={shelfCategoryChartData}
                    syncId="gondola_tags_compare_chart_sync"
                    margin={{top: 20, right: 10, left: 10, bottom: 20}}>
                    <CartesianGrid strokeDasharray="3 10" />
                    <XAxis dataKey="leftTag.label" tickFormatter={(val) => `${format(val, `DD/MM HH:00`)}`}/>
                    <YAxis yAxisId="left" orientation="left" stroke="#343434" allowDecimals={false}/>
                    <Legend content={this.renderChartLegend} />
                    <Tooltip
                      content={<CustomToolTipCompare />}
                      isAnimationActive={false}/>
                    <Bar
                      key="leftTag.rupturesCountType0"
                      yAxisId="left"
                      name="Ruptura Padrão"
                      dataKey="leftTag.rupturesCountType0"
                      maxBarSize={15}
                      fill={this.hexToRGB(leftShelfCategoryData.colorHash, 1)}
                      isAnimationActive={false}
                      minPointSize={3}
                      connectNulls={true}
                      stackId="a"
                    />
                    <Bar
                      key="leftTag.rupturesCountType1"
                      yAxisId="left"
                      name="Falha no planograma"
                      dataKey="leftTag.rupturesCountType1"
                      maxBarSize={15}
                      fill={this.hexToRGB(leftShelfCategoryData.colorHash, 0.7)}
                      isAnimationActive={false}
                      minPointSize={3}
                      connectNulls={true}
                      stackId="a"
                    />
                    <Bar
                      key="leftTag.rupturesCountType2"
                      yAxisId="left"
                      name="Outros"
                      dataKey="leftTag.rupturesCountType2"
                      maxBarSize={15}
                      fill={this.hexToRGB(leftShelfCategoryData.colorHash, 0.5)}
                      isAnimationActive={false}
                      minPointSize={3}
                      connectNulls={true}
                      stackId="a"
                    />
                    {/* <Bar
                      key="rightTag.rupturesCount"
                      yAxisId="left"
                      name="Quantidade de rupturas"
                      dataKey="rightTag.rupturesCount"
                      maxBarSize={15}
                      fill={rightShelfCategoryData.colorHash}
                      isAnimationActive={false}
                      minPointSize={3}
                      connectNulls={true}
                    /> */}
                    <Bar
                      key="rightTag.rupturesCountType0"
                      yAxisId="left"
                      name="Ruptura Padrão"
                      dataKey="rightTag.rupturesCountType0"
                      maxBarSize={15}
                      fill={this.hexToRGB(rightShelfCategoryData.colorHash, 1)}
                      isAnimationActive={false}
                      minPointSize={3}
                      connectNulls={true}
                      stackId="b"
                    />
                    <Bar
                      key="rightTag.rupturesCountType1"
                      yAxisId="left"
                      name="Falha no planograma"
                      dataKey="rightTag.rupturesCountType1"
                      maxBarSize={15}
                      fill={this.hexToRGB(rightShelfCategoryData.colorHash, 0.7)}
                      isAnimationActive={false}
                      minPointSize={3}
                      connectNulls={true}
                      stackId="b"
                    />
                    <Bar
                      key="rightTag.rupturesCountType2"
                      yAxisId="left"
                      name="Outros"
                      dataKey="rightTag.rupturesCountType2"
                      maxBarSize={15}
                      fill={this.hexToRGB(rightShelfCategoryData.colorHash, 0.5)}
                      isAnimationActive={false}
                      minPointSize={3}
                      connectNulls={true}
                      stackId="b"
                    />
                  </ComposedChart>
                </ResponsiveContainer>
              </Column>}
              {!isLoadingShelfCategoryCharts && shelfCategoryChartData.length !== 0 && <Column isSize={12}>
                <ResponsiveContainer width={"100%"} height={250}>
                  <ComposedChart data={shelfCategoryChartData}
                      syncId="gondola_tags_compare_chart_sync"
                      margin={{top: 20, right: 10, left: 10, bottom: 20}}>
                    <CartesianGrid strokeDasharray="3 10" />
                    <XAxis dataKey="leftTag.label" tickFormatter={(val) => `${format(val, `DD/MM HH:00`)}`}/>
                    <YAxis yAxisId="left" tickFormatter={val => this._secondsToLabel(val)} orientation="left" stroke="#343434" />
                    <Legend content={this.renderChartLegend} />
                    <Tooltip
                      content={<CustomToolTipCompare />}
                      isAnimationActive={false}/>
                    <Area
                      type="monotone" 
                      key="leftTag.rupturesMeanTime"
                      yAxisId="left"
                      name="Tempo médio de ruptura"
                      dataKey="leftTag.rupturesMeanTime"
                      strokeDasharray="3 5"
                      fillOpacity={0.3}
                      fill={leftShelfCategoryData.colorHash}
                      stroke={leftShelfCategoryData.colorHash}
                      isAnimationActive={false}
                      connectNulls={true}
                    />
                    <Area
                      type="monotone" 
                      key="rightTag.rupturesMeanTime"
                      yAxisId="left"
                      name="Tempo médio de ruptura"
                      dataKey="rightTag.rupturesMeanTime"
                      strokeDasharray="3 5"
                      fillOpacity={0.3}
                      fill={rightShelfCategoryData.colorHash}
                      stroke={rightShelfCategoryData.colorHash}
                      isAnimationActive={false}
                      connectNulls={true}
                    />
                  </ComposedChart>
                </ResponsiveContainer>
              </Column>}
            </Columns>
          </div>}
        </Column>
      </Columns>
    </Page>
  }
}

function mapStateToProps(state, props) {
  return {
    site: props.site
  }
}

export default withRouter(connect(mapStateToProps)(DashGondolaAnalytics));