import React, { Component } from 'react';

import { connect } from 'react-redux';

import {
  Route,
  Switch,
  withRouter
} from "react-router-dom";

import _toArray from 'lodash/toArray';
import { format, subDays } from 'date-fns';

import { DndProvider } from 'react-dnd'
import Backend from 'react-dnd-html5-backend'

import PageLoader from '../components/PageLoader';
import PageNotFound from '../components/PageNotFound';
import MainMenu from '../components/MainMenu';
import MainAsideMenuFoottraffic from '../components/MainAsideMenuFoottraffic';
import MainAsideMenuVideo from '../components/MainAsideMenuVideo';
import MainAsideMenuJohann from '../components/MainAsideMenuJohann';
import MainAsideMenuAmbev from '../components/MainAsideMenuAmbev';

import Me from './board/user/Me';
import TelegramUserSettings from './board/user/TelegramUserSettings';
import AdmVarejos from './board/admtooq/Admvarejos';
import Home from './board/Home';
import HomePage from './board/HomePage';

import SitePage from './board/sites/SitePage';
import DashboardJohannVenue from './board/dashboard/johann/DashboardJohannVenue'
import DashboardJohannHome from './board/dashboard/johann/home/DashboardJohannHome'
import ReportScheduler from './board/dashboard/reports/ReportScheduler';
import ScheduleList from './board/dashboard/reports/ScheduleList';
import FoottrafficCompare from './board/dashboard/foottraffic/compare/FoottrafficCompare';
import PerformanceSites from './board/dashboard/foottraffic/compare/PerformanceSites';
import DashSalesOnline from './board/dashboard/foottraffic/analytics/DashSalesOnline';
import FootTrafficDash from './board/dashboard/foottraffic/analytics/DashAnalytics';
import FootTrafficDashSuvinil from './board/dashboard/foottraffic/analytics/DashAnalyticsSuvinil';
import ShelfDash from './board/dashboard/suvinil/ShelfDash';
import ShelfDashAmbev from './board/dashboard/ambev/ShelfDash';
import DashboardShelf from './board/dashboard/shelfs/DashGondolaAnalytics';
import DashboardVideo from './board/dashboard/video/DashboardVideo';
import DashQueues from './board/dashboard/queues/DashQueues';

import ManualCountCentauro from './board/dashboard/administrative/manualcount/ManualCount';
import ManualCount from './board/dashboard/audit/ManualCount';

import AuditDashboardMain from './board/dashboard/audit/AuditDashboardMain';
import AuditVisitorsDashboard from './board/dashboard/audit/AuditVisitorsDashboard';
import AuditPassersDashboard from './board/dashboard/audit/AuditPassersDashboard';
import AuditVenueDashboard from './board/dashboard/audit/AuditVenueDashboard';
import AnomalyGroupHistPage from './board/dashboard/anomaly/AnomalyGroupHistPage';

import { actions as siteActions } from '../state/sites';
import { actions as appActions } from '../state/app';

import DashRealtime from './board/dashboard/foottraffic/analytics/realtime/DashRealtime';
import DashRealtimeVideo from './board/dashboard/video/realtime/DashRealtime';
import CentauroDashboard from './board/dashboard/video/centauro/CentauroDashboard'
import { get, getServerDate } from '../utils';
import DashboardSalesVideo from './board/dashboard/video/DashboardSalesVideo';
import DedoDuro from './board/dashboard/dedoduro/DedoDuro';
import HealthSiteDash from './board/dashboard/audit/HealthSiteDash';
import SiteSetup from './board/dashboard/audit/SiteSetup';
import ShareGondolaDashBoard from './board/dashboard/ambev/ShareGondolaDashBoard';

class Board extends Component {

  componentDidMount() {
    this.props.isLoading();
    this.loadSites()
      .then(() => this.props.notLoading());
  }

  loadSites() {
    return get(`dashboard/home/${this.props.user.uid}/sites`, { date: format(subDays(getServerDate(), 1), 'YYYY-MM-DD') })
      .then(resp => {
        this.props.addSites(resp.data.data);
      }).catch(err => {
        console.log(err);
      });
  }

  render() {
    const {
      match,
      hasSalesModules = false,
      hasFoottrafficModules = false,
      hasJohannModules = false,
      hasVideoModules = false,
      isLoadingShops,
      group,
      sitesNotArray,
      groupsNotArray,
      user
    } = this.props;

    if (isLoadingShops)
      return <PageLoader isLoading={isLoadingShops} msg={"Aguarde autenticando..."} />;

    return (<div>
      <DndProvider backend={Backend}>
        <MainMenu
          match={match}
          group={group}
          hasSalesModules={hasSalesModules}
          hasFoottrafficModules={hasFoottrafficModules}
          hasVideoModules={hasVideoModules}
          hasJohannModules={hasJohannModules}
        />
        <Route path={`${match.url}/sites/:siteId/dashboard/foottraffic`} component={MainAsideMenuFoottraffic} />
        <Route path={`${match.url}/sites/:siteId/dashboard/ambev`} component={MainAsideMenuAmbev} />
        <Route path={`${match.url}/sites/:siteId/dashboard/video`} component={MainAsideMenuVideo} />
        <Route path={`${match.url}/sites/:siteId/dashboard/johann`} component={MainAsideMenuJohann} />
        <div className="application-content" style={{ width: '100%' }}>
          <Switch>
            <Route
              path={`${match.url}/me/telegram`}
              component={TelegramUserSettings}
            />
            <Route
              path={`${match.url}/me/admvarejos`}
              component={AdmVarejos}
            />
            <Route
              path={`${match.url}/me`}
              component={Me}
            />
            <Route path={`${match.url}/groups/:groupId`} component={(props) => {
              const group = groupsNotArray[props.match.params.groupId];
              if (!group) {
                return <PageNotFound />
              }

              return <Switch>
                <Route path={`${props.match.url}/dashboard/foottraffic/anomaly`} children={<AnomalyGroupHistPage group={group} />} />
                <Route path={`${props.match.url}/dashboard/foottraffic/quadrante`} children={<FoottrafficCompare group={group} />} />
                <Route path={`${props.match.url}/dashboard/video/quadrante`} children={<FoottrafficCompare group={group} hasVideoModules={hasVideoModules} />} />
                <Route path={`${props.match.url}/dashboard/foottraffic/performance/monthly-hourly`} children={<HomePage group={group} />} />
                <Route exact path={`${props.match.url}/dashboard/foottraffic/performance`} children={<PerformanceSites group={group} />} />
              </Switch>
            }} />
            <Route path={`${match.url}/sites/:siteId`} component={(props) => {
              const { siteId } = props.match.params;
              const site = sitesNotArray[siteId];

              if (!site) {
                return <PageNotFound />
              }

              return <Switch>
                <Route
                  path={`${props.match.url}/admin/config`}
                  component={(props) => {
                    if (site.userPermissions.permission > 0) {
                      return <PageNotFound />
                    }
                    return <SitePage site={site} />
                  }}
                />
                <Route
                  path={`${props.match.url}/dashboard/video`}
                  component={(props) => {
                    if (!site.modules.video) {
                      return <PageNotFound />
                    }

                    return <Switch>
                      <Route path={`${props.match.url}/home`} children={site.id === 23 ? <CentauroDashboard group={group} site={site} /> : <DashboardVideo group={group} site={site} />} />
                      <Route path={`${props.match.url}/sales`} children={<DashboardSalesVideo group={group} site={site} />} />
                      <Route path={`${props.match.url}/realtime`} children={<DashRealtimeVideo site={site} />} />
                      <Route path={`${props.match.url}/relatorios`} children={<ReportScheduler site={site} video={true} />} />
                      <Route path={`${props.match.url}/manualcount`} children={<ManualCountCentauro user={user} site={site} isUserTooqAdmin={user.tooqAdmin} />} />
                      <Route path={`${props.match.url}/settings`} children={<SitePage site={site} />} />
                    </Switch>
                  }}
                />
                <Route
                  path={`${props.match.url}/dashboard/foottraffic`}
                  component={(props) => {
                    if (!site.modules.foottraffic) {
                      return <PageNotFound />
                    }

                    const { userPermissions, modules } = site;
                    const { shelf, isSuvinil, queues } = modules;

                    if (userPermissions.permission === 3) {
                      return <Switch>
                        <Route path={`${props.match.url}/home`} children={<ManualCount user={user} site={site} />} />
                      </Switch>
                    }

                    if (isSuvinil) {
                      return <Switch>
                        <Route path={`${props.match.url}/home`} children={<ShelfDash site={site} />} />
                        <Route path={`${props.match.url}/visitors`} children={<FootTrafficDashSuvinil site={site} />} />
                        {queues && <Route path={`${props.match.url}/queues`} children={<DashQueues site={site} />} />}
                        <Route path={`${props.match.url}/gondolas`} children={<DashboardShelf site={site} />} />
                        {user.tooqAdmin && <Route path={`${props.match.url}/setup`} children={<SiteSetup site={site} />} />}
                        <Route path={`${props.match.url}/relatorios`} children={<ReportScheduler site={site} />} />
                        <Route path={`${props.match.url}/dedoduro`} children={<DedoDuro group={group} site={site} />} />
                        {user.tooqAdmin && <Route path={`${props.match.url}/sitehealth`} children={<HealthSiteDash site={site} />} />}
                        {user.tooqAdmin && <Route path={`${props.match.url}/setup`} children={<SiteSetup site={site} />} />}
                        {user.tooqAdmin && <Route path={`${props.match.url}/audits`} children={<AuditDashboardMain site={site} />} />}
                        {(user.tooqAdmin || site.userPermissions.permission === 0) && <Route path={`${props.match.url}/manualcount`} children={<ManualCount user={user} site={site} isUserTooqAdmin={user.tooqAdmin} />} />}
                        {(user.tooqAdmin || site.userPermissions.permission === 0) && <Route path={`${props.match.url}/settings`} children={<SitePage site={site} />} />}
                      </Switch>
                    }

                    if (shelf) {
                      return <Switch>
                        <Route path={`${props.match.url}/home`} children={<ShelfDashAmbev site={site} />} />
                        <Route path={`${props.match.url}/visitors`} children={<FootTrafficDash site={site} />} />
                        {queues && <Route path={`${props.match.url}/queues`} children={<DashQueues site={site} />} />}
                        <Route path={`${props.match.url}/sales`} children={<DashSalesOnline site={site} />} />
                        <Route path={`${props.match.url}/gondolas`} children={<DashboardShelf site={site} />} />
                        <Route path={`${props.match.url}/relatorios`} children={<ReportScheduler site={site} />} />
                        {user.tooqAdmin && <Route path={`${props.match.url}/sitehealth`} children={<HealthSiteDash site={site} />} />}
                        {user.tooqAdmin && <Route path={`${props.match.url}/setup`} children={<SiteSetup site={site} />} />}
                        {(user.tooqAdmin || site.userPermissions.permission === 0) && <Route path={`${props.match.url}/manualcount`} children={<ManualCount user={user} site={site} isUserTooqAdmin={user.tooqAdmin} />} />}
                        {user.tooqAdmin && <Route path={`${props.match.url}/audits`} children={<AuditDashboardMain site={site} />} />}
                        {(user.tooqAdmin || site.userPermissions.permission === 0) && <Route path={`${props.match.url}/settings`} children={<SitePage site={site} />} />}
                      </Switch>
                    }

                    return <Switch>
                      <Route path={`${props.match.url}/home`} children={<FootTrafficDash site={site} />} />
                      <Route path={`${props.match.url}/sales`} children={<DashSalesOnline site={site} />} />
                      <Route path={`${props.match.url}/dedoduro`} children={<DedoDuro group={group} site={site} />} />
                      {queues && <Route path={`${props.match.url}/queues`} children={<DashQueues site={site} />} />}
                      <Route path={`${props.match.url}/realtime`} children={<DashRealtime site={site} />} />
                      <Route path={`${props.match.url}/relatorios`} children={<ReportScheduler site={site} />} />
                      {(user.tooqAdmin || site.userPermissions.permission === 0) && <Route path={`${props.match.url}/manualcount`} children={<ManualCount user={user} site={site} isUserTooqAdmin={user.tooqAdmin} />} />}
                      {user.tooqAdmin && <Route path={`${props.match.url}/sitehealth`} children={<HealthSiteDash site={site} />} />}
                      {user.tooqAdmin && <Route path={`${props.match.url}/audits`} children={<AuditDashboardMain site={site} />} />}
                      {user.tooqAdmin && <Route path={`${props.match.url}/setup`} children={<SiteSetup site={site} />} />}
                      {user.tooqAdmin && <Route path={`${props.match.url}/dashaudit/visitors`} children={<AuditVisitorsDashboard site={site} />} />}
                      {user.tooqAdmin && <Route path={`${props.match.url}/dashaudit/passers`} children={<AuditPassersDashboard site={site} />} />}
                      {user.tooqAdmin && <Route path={`${props.match.url}/dashaudit/area`} children={<AuditVenueDashboard site={site} />} />}
                      {(user.tooqAdmin || site.userPermissions.permission === 0) && <Route path={`${props.match.url}/settings`} children={<SitePage site={site} />} />}
                    </Switch>
                  }}
                />
                <Route
                  path={`${props.match.url}/dashboard/ambev`}
                  component={(props) => {
                    if (!site.modules.isAmbev) {
                      return <PageNotFound />
                    }

                    return <Switch>
                      <Route path={`${props.match.url}/home`} children={<ShareGondolaDashBoard site={site} />} />
                      <Route path={`${props.match.url}/settings`} children={<SitePage site={site} />} />
                    </Switch>
                  }}
                />
                <Route
                  path={`${props.match.url}/dashboard/johann`}
                  component={(props) => {
                    if (!site.modules.johann) {
                      return <PageNotFound />
                    }

                    return <Switch>
                      <Route path={`${props.match.url}/home`} children={<DashboardJohannHome group={group} site={site} />} />
                      {(site.userPermissions.permission === 3 || site.userPermissions.permission === 0) && <Route path={`${props.match.url}/dedoduro`} children={<DedoDuro group={group} site={site} />} />}
                      <Route path={`${props.match.url}/venue/:venueId`} children={<DashboardJohannVenue group={group} site={site} user={user} />} />
                      <Route path={`${props.match.url}/relatorios`} children={<ScheduleList site={site} group={group} user={user} />} />
                      {site.userPermissions.permission === 0 && <Route path={`${props.match.url}/admin`} children={<SitePage site={site} />} />}
                    </Switch>
                  }}
                />
                <Route
                  component={PageNotFound}
                />
              </Switch>
            }} />
            <Route exact path={match.url} children={<Home group={group} />} />
            <Route component={PageNotFound} />
          </Switch>
        </div>
      </DndProvider>
    </div>)
  }
}

function mapStateToProps(state, props) {
  const currentGroup = state.sites.currentGroup;
  const groups = _toArray({ ...state.sites.groups });

  const sitesFromCurrentGroup = _toArray({ ...state.sites.all }).filter(site => site.group.id === currentGroup);
  const hasFoottrafficModules = sitesFromCurrentGroup.filter(site => site.modules.foottraffic).length > 0;
  const hasSalesModules = sitesFromCurrentGroup.filter(site => site.modules.sales).length > 0;
  const hasJohannModules = sitesFromCurrentGroup.filter(site => site.modules.johann).length > 0;
  const hasVideoModules = sitesFromCurrentGroup.filter(site => site.modules.video).length > 0;
  const hasQueuesModules = sitesFromCurrentGroup.filter(site => site.modules.queues).length > 0;

  return {
    sitesNotArray: state.sites.all,
    groupsNotArray: state.sites.groups,
    groups,
    currentGroup,
    group: state.sites.groups[currentGroup],
    user: state.app.user,
    isLoadingShops: state.app.isLoadingShops,
    hasFoottrafficModules,
    hasVideoModules,
    hasJohannModules,
    hasQueuesModules,
    hasSalesModules
  }
}

export default withRouter(
  connect(mapStateToProps, {
    addSites: siteActions.addMany,
    isLoading: appActions.isLoading,
    notLoading: appActions.notLoading,
    setCurrentGroup: siteActions.setCurrentGroup
  })(Board));
