import React, {useState, useEffect} from 'react';
import { CalendarAltIcon, PerfomanceAltIcon } from '../../components/icons/index';
import CalendarRangePicker from '../board/dashboard/calendar/CalendarRangePicker';
import { formatAsPtBr, get, getServerDate } from '../../utils';
import IsLoading from '../../components/IsLoading';
import Page, { PageTitle } from '../../components/Page';
import { addDays, subMonths, toDate, format } from 'date-fns';
import Columns, { Column } from '../../components/Columns';
import _ from 'lodash';

const TitleAsBoxFilter = ({ title, right, left, isLoading, begin, end, setBegin, setEnd }) => {
  const [ toogleFilter, setToogleFilter ] = useState(false);
  const _toogleFilter = () => {
    setToogleFilter(!toogleFilter);
  }

  if (isLoading) {
    return <div style={{marginBottom: 20, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
      <p className="subtitle is-5 has-text-centered">{title}</p>
      <p style={{marginTop: 5}} >
        <span className="tag is-primary heading is-medium">
          <CalendarAltIcon style={{marginRight: 5 }}/>
          ...
        </span>
      </p>
    </div>
  }

  const setConfirmDates = data => {
    const { startDate, endDate } = data.range;
    setBegin(formatAsPtBr(startDate, 'YYYY-MM-DD'));
    setEnd(formatAsPtBr(endDate, 'YYYY-MM-DD'));
    _toogleFilter();
  };

  return <div style={{width: '100%'}}>
    <p className="subtitle is-5 has-text-centered" style={{marginBottom: 0}}>{title}</p>
    <div
      className="tooltip"
      style={{
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 5,
        marginBottom: 20
      }}
    >
      <span className="tag is-primary is-medium cursor-pointer" onClick={_toogleFilter}>
        <CalendarAltIcon style={{marginRight: 5 }}/>
        {begin ? formatAsPtBr(begin, 'DD MMMM') : '...'} até {end ? formatAsPtBr(end, 'DD MMMM') : '...'}
      </span>
      {toogleFilter && <span className="tooltiptext" style={{ right, left, top: '100%' }}>
        <CalendarRangePicker
          maxDate={addDays(getServerDate(), -1)}
          range={{
            startDate: toDate(begin),
            endDate: toDate(end)
          }}
          close={_toogleFilter}
          onChange={setConfirmDates}
        />
      </span>}
    </div>
  </div>
}

const gradient = [
  [
    0,
    [248,105,107]
  ],
  [
    50,
    [255,235,132]
  ],
  [
    100,
    [99, 190, 123]
  ]
];

const pickHex = (valueData, maxValue, minValue, midValue) => {
  // sem dados.
  if(valueData === 0)
    return [255, 255, 255];

  const colorPicker = (weight, color1, color2) => {
    const p = weight;
    const w = p * 2 - 1;
    const w1 = (w/1+1) / 2;
    const w2 = 1 - w1;
    const rgb = [Math.round(color1[0] * w1 + color2[0] * w2),
      Math.round(color1[1] * w1 + color2[1] * w2),
      Math.round(color1[2] * w1 + color2[2] * w2)];
    return rgb
  }

  //àcima do meio
  if(valueData > midValue) {
    const variante = (maxValue - midValue);
    const colorWeight = (valueData - midValue) / variante;
    const color1 = gradient[2][1]
    const color2 = gradient[1][1]
    return colorPicker(colorWeight, color1, color2);
  }

  //média ou abaixo do meio
  const variante = (midValue - minValue);
  const colorWeight = (valueData - minValue) / variante;
  const color1 = gradient[1][1]
  const color2 = gradient[0][1]
  return colorPicker(colorWeight, color1, color2);
}

const HomePage = (props) => {
  const { group } = props;
  
  const [ isLoadingTableDateHour, setIsLoadingTableDateHour ] = useState(false);
  const [ siteDataHour, setSiteDataHour ] = useState([]);
  const [ ciaDataHour, setCiaDataHour ] = useState([]);
  
  const [ isLoadingTableDate, setIsLoadingTableDate ] = useState(false);
  const [ siteDataMonths, setSiteData ] = useState([]);
  const [ ciaDataMonths, setCiaData ] = useState([]);

  
  //Dados da companhia por hora;
  const maxCiaVisitors = _.max(ciaDataHour.map(c => c.visitorsSum))
  const minCiaVisitors = _.min(ciaDataHour.map(c => c.visitorsSum))
  const midCiaVisitors = (maxCiaVisitors - minCiaVisitors) / 2 + minCiaVisitors; 
  
  const maxCiaSales = _.max(ciaDataHour.map(c => c.totalSalesSum))
  const minCiaSales = _.min(ciaDataHour.map(c => c.totalSalesSum))
  const midCiaSales = (maxCiaSales - minCiaSales) / 2 + minCiaSales; 
  
  const maxCiaSalesRate = _.max(ciaDataHour.map(c => c.salesRateSum))
  const minCiaSalesRate = _.min(ciaDataHour.map(c => c.salesRateSum))
  const midCiaSalesRate = (maxCiaSalesRate - minCiaSalesRate) / 2 + minCiaSalesRate; 
  
  const [ beginInputHour, setBeginInputHour ] = useState(getServerDate(7));
  const [ endInputHour, setEndInputHour ] = useState(getServerDate(1));

  
  //Dados da companhia por mês;
  const maxCiaVisitorsMonths = _.max(ciaDataMonths.map(c => c.visitorsSum));
  const minCiaVisitorsMonths = _.min(ciaDataMonths.map(c => c.visitorsSum));
  const midCiaVisitorsMonths = (maxCiaVisitorsMonths - minCiaVisitorsMonths) / 2 + minCiaVisitorsMonths;

  const maxCiaSalesMonths = _.max(ciaDataMonths.map(c => c.totalSalesSum));
  const minCiaSalesMonths = _.min(ciaDataMonths.map(c => c.totalSalesSum));
  const midCiaSalesMonths = (maxCiaSalesMonths - minCiaSalesMonths) / 2 + minCiaSalesMonths;

  const maxCiaSalesRateMonths = _.max(ciaDataMonths.map(c => c.salesRateSum));
  const minCiaSalesRateMonths = _.min(ciaDataMonths.map(c => c.salesRateSum));
  const midCiaSalesRateMonths = (maxCiaSalesRateMonths - minCiaSalesRateMonths) / 2 + minCiaSalesRateMonths; 

  const [ beginInputMonths, setBeginInputMonths ] = useState(subMonths(getServerDate(), 6));
  const [ endInputMonths, setEndInputMonths ] = useState(getServerDate(1));
  
  //Dados das lojas por hora;
  const salesSitesArrHourly = siteDataHour.map(s => {
    const visitors = s.hours.map(row => row.visitorsSum);
    const sales = s.hours.map(row => row.totalSalesSum);
    const salesRate = s.hours.map(row => row.salesRateSum);

    const maxVisitors = _.max(visitors.filter(a => a !== 0)) || 0;
    const minVisitors = _.min(visitors.filter(a => a !== 0)) || 0;
    const midVisitors = (maxVisitors - minVisitors) / 2 + minVisitors;

    const maxSales = _.max(sales.filter(a => a !== 0)) || 0;
    const minSales = _.min(sales.filter(a => a !== 0)) || 0;
    const midSales = (maxSales - minSales) / 2 + minSales;

    const maxSalesRate = _.max(salesRate.filter(a => a !== 0)) || 0;
    const minSalesRate = _.min(salesRate.filter(a => a !== 0)) || 0;
    const midSalesRate = (maxSalesRate - minSalesRate) / 2 + minSalesRate;

    return {
      siteId: s.siteId,
      siteName: s.siteName,
      siteNickName: s.siteNickName,
      minVisitors,
      maxVisitors,
      midVisitors,
      minSales,
      maxSales,
      midSales,
      minSalesRate,
      maxSalesRate,
      midSalesRate,
      visitors,
      sales,
      salesRate
    }
  });


  // Dados das lojas por mês;
  const salesSitesArrMonths = siteDataMonths.map(s => {
    const visitors = s.monthlyData.map(row => row.visitorsSum);
    const sales = s.monthlyData.map(row => row.totalSalesSum);
    const salesRate = s.monthlyData.map(row => row.salesRateAvg);

    const maxVisitors = _.max(visitors.filter(a => a !== 0)) || 0;
    const minVisitors = _.min(visitors.filter(a => a !== 0)) || 0;
    const midVisitors = (maxVisitors - minVisitors) / 2 + minVisitors;

    const maxSales = _.max(sales.filter(a => a !== 0)) || 0;
    const minSales = _.min(sales.filter(a => a !== 0)) || 0;
    const midSales = (maxSales - minSales) / 2 + minSales;

    const maxSalesRate = _.max(salesRate.filter(a => a !== 0)) || 0;
    const minSalesRate = _.min(salesRate.filter(a => a !== 0)) || 0;
    const midSalesRate = (maxSalesRate - minSalesRate) / 2 + minSalesRate;

    return {
      siteId: s.siteId,
      siteName: s.siteName,
      siteNickName: s.siteNickName,
      maxVisitors,
      minVisitors,
      midVisitors,
      maxSales,
      minSales,
      midSales,
      maxSalesRate,
      minSalesRate,
      midSalesRate,
      visitors,
      sales,
      salesRate
    };
  });


  const loadSalesSitesAndCia = () => {
    setIsLoadingTableDate(true);
    setSiteData([]);
    setCiaData([]);
    get(`dashboard/foottraffic/groups/${group.id}/monthly`, {
      begin: format(beginInputMonths, 'YYYY-MM-DD'),
      end: format(endInputMonths, 'YYYY-MM-DD')
    })
    .then(resp => {
      setSiteData(resp.data.data.sites);
      setCiaData(resp.data.data.cia);
      setIsLoadingTableDate(false);
    });
  };

  useEffect(loadSalesSitesAndCia, [group.id, beginInputMonths, endInputMonths]);

  const loadSalesSitesAndCiaHourly = () => {
    setSiteDataHour([]);
    setCiaDataHour([]);
    setIsLoadingTableDateHour(true);
    get(`dashboard/foottraffic/groups/${group.id}/hourly-aggregated`, {
      begin: format(beginInputHour, 'YYYY-MM-DD'), 
      end: format(endInputHour, 'YYYY-MM-DD')
    })
    .then(resp => {
      setSiteDataHour(resp.data.data.sites);
      setCiaDataHour(resp.data.data.cia);
      setIsLoadingTableDateHour(false);
    });
    

  };
  useEffect(loadSalesSitesAndCiaHourly, [group.id, beginInputHour, endInputHour]);


  //Gera linhas e colunas com os dados mensais das lojas(sites);
  const SitesMonthsMapped = salesSitesArrMonths.map(sid => [
    <tr className="is-performance-rows">
      <td className="td-home" style={{width: 120, minWidth: 120, maxWidth: 120}}>
        <div className="tag is-default" style={{width: '100%'}}>
          <div className="has-text-centered" style={{width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', display: 'inline-block'}}>
            {sid.siteNickName || sid.siteName}
          </div>
        </div>
      </td>
      <td className="td-home" style={{width: 95, minWidth: 95, maxWidth: 95}}>
        Visitantes
      </td>
      {sid.visitors.map(val => {
        const color = pickHex(val, sid.maxVisitors, sid.minVisitors, sid.midVisitors).join(',')
        return <td className="td-home has-text-centered" style={{background: `rgb(${color})`}}>
          {val === 0 ? '-' : val}
        </td>
      })}
    </tr>,
    <tr className="is-performance-rows" >
      <td className="td-home" style={{width: 120, minWidth: 120, maxWidth: 120}}/>
      <td className="td-home" style={{width: 95, minWidth: 95, maxWidth: 95}}>
        Vendas
      </td>
      {sid.sales.map(val => {
        const color = pickHex(val, sid.maxSales, sid.minSales, sid.midSales).join(',')
        return <td className="td-home has-text-centered" style={{background: `rgb(${color})`}}>
          {val === 0 ? '-' : val}
        </td>
      })}
    </tr>,
    <tr className="is-performance-rows" >
      <td className="td-home is-last-row" style={{width: 120, minWidth: 120, maxWidth: 120}}/>
      <td className="td-home is-last-row " style={{width: 95, minWidth: 95, maxWidth: 95}}>
        Conversão
      </td>
      {sid.salesRate.map(val => {
        const color = pickHex(val, sid.maxSalesRate, sid.minSalesRate, sid.midSalesRate).join(',')
        return <td className="td-home has-text-centered is-last-row" style={{ background: `rgb(${color})` }}>
          {val === 0 ? `-` : `${val}%`}
        </td>
      })}
    </tr>
  ]).flat()

  //gera o cabeçalho com os meses filtrados(cia);
  const monthsCiaMapped = ciaDataMonths.map(cmon => <th className="has-text-centered">
    <div className="tag is-light">{`${cmon.readableMonth ? formatAsPtBr(cmon.readableMonth, 'MMMM/YY') : '...'}`}</div>
  </th>)

  //gera colunas com os dados mensais da companhia(cia);
  const visitorsCia = ciaDataMonths.map(vis => 
      <td className="td-home has-text-centered" 
        style={{background: `rgb(${pickHex(vis.visitorsSum, maxCiaVisitorsMonths, minCiaVisitorsMonths, midCiaVisitorsMonths).join(',')})`}}
      >
        {vis.visitorsSum === 0 ? '-' : vis.visitorsSum}
      </td>)

  const salesCia = ciaDataMonths.map(vis => 
      <td className="td-home has-text-centered" 
        style={{background: `rgb(${pickHex(vis.totalSalesSum, maxCiaSalesMonths, minCiaSalesMonths, midCiaSalesMonths).join(',')})`}}
      >
        {vis.totalSalesSum === 0 ? '-' : vis.totalSalesSum}
      </td>)

  const salesRateCia = ciaDataMonths.map(vis => 
      <td className="td-home has-text-centered" 
        style={{background: `rgb(${pickHex(vis.salesRateSum, maxCiaSalesRateMonths, minCiaSalesRateMonths, midCiaSalesRateMonths).join(',')})`}}
      >
        {vis.salesRateSum === 0 ? '-' : `${vis.salesRateSum}%`}
      </td>)


  return <Page>
    <PageTitle title={<div>
      <p className="title">{group.name} </p>
      <p className="subtitle"><PerfomanceAltIcon/> Análise mês/hora</p>
    </div>}/>
    <Columns isMultiline>
      <Column isSize={12}>
        <div className="card notification">
          <TitleAsBoxFilter 
            title="Desempenho Mensal"
            begin={beginInputMonths}
            end={endInputMonths}
            setBegin={setBeginInputMonths}
            setEnd={setEndInputMonths}
          />

          {isLoadingTableDate && <div style={{display: "flex", justifyContent: 'center'}}>
            <IsLoading isBorderless={true} fontSize={60} />
          </div>}
          
          {/*tabela de dados mensais*/}
          {!isLoadingTableDate && <div className="table-container is-narrow" style={{width: "100%"}}>
            <table className="table" style={{width: "100%"}}>
              <thead>
                <th>LOJAS</th>
                <th></th>
                {monthsCiaMapped}
              </thead>
              <tbody>
                {SitesMonthsMapped}
                <tr className="is-performance-rows">
                  <td className="td-home">
                    <div className="tag is-dark is-medium">CIA</div>
                  </td>
                  <td className="td-home" style={{width: 95, minWidth: 95, maxWidth: 95}}>
                    <div className="has-text-left">Visitantes</div>
                  </td>
                  {visitorsCia}
                </tr>
                <tr className="is-performance-rows">
                  <td className="td-home" style={{width: 120, minWidth: 120, maxWidth: 120}}/>
                  <td className="td-home" style={{width: 95, minWidth: 95, maxWidth: 95}}>
                    <div className="has-text-left">Vendas</div>
                  </td>
                  {salesCia}
                </tr>
                <tr className="is-performance-rows">
                  <td className="td-home" style={{width: 120, minWidth: 120, maxWidth: 120}}/>
                  <td className="td-home" style={{width: 95, minWidth: 95, maxWidth: 95}}>
                    <div className="has-text-left">Conversão</div>
                  </td>
                  {salesRateCia}
                </tr>
              </tbody>
            </table>
          </div>}
        </div>
      </Column>
      
      <Column isSize={12}>
        <div className="card notification">
          <TitleAsBoxFilter 
            title="Desempenho por Hora"
            begin={beginInputHour}
            end={endInputHour}
            setBegin={setBeginInputHour}
            setEnd={setEndInputHour}
          />
              
          {isLoadingTableDateHour && <div style={{display: "flex", justifyContent: 'center'}}>
            <IsLoading isBorderless={true} fontSize={60} />
          </div>}

          {/*tabela de dados por hora*/}          
          {!isLoadingTableDateHour && <div className="table-container" style={{minWidth: "100%"}}>
            <table className="table" style={{minWidth: "100%"}}>
              <thead>
                <tr className="is-performance-rows">
                  <th>LOJAS</th>
                  <th></th>
                  {ciaDataHour.slice(8).map(chour => <th>
                    <div className="tag is-light">{`${chour.snapTime.split(':')[0]}:${chour.snapTime.split(':')[1]}`}</div>
                  </th>)}
                </tr>
              </thead>
              <tbody>
                {salesSitesArrHourly.map(sid => [
                  <tr className="is-performance-rows">
                    <td className="td-home" style={{width: 120, minWidth: 120, maxWidth: 120}}>
                      <div className="tag is-default" style={{width: '100%'}} >
                        <div className="has-text-centered" style={{width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', display: 'inline-block'}}>
                          {sid.siteNickName || sid.siteName}
                        </div>
                      </div>  
                    </td>
                    <td className="td-home" style={{width: 95, minWidth: 95, maxWidth: 95}}> 
                      <div className="has-text-left">Visitantes</div>
                    </td>
                    {sid.visitors.slice(8).map(value => {
                      const color = pickHex(value, sid.maxVisitors, sid.minVisitors, sid.midVisitors)
                        .join(',')
                      return <td className="td-home has-text-centered" style={{ background: `rgb(${color})` }}>
                        {value === 0 ? `-` : `${value}`}
                      </td>
                    })}
                  </tr>,
                  <tr className="is-performance-rows">
                    <td className="td-home" />
                    <td className="td-home" style={{width: 95, minWidth: 95, maxWidth: 95}}> 
                      <div className="has-text-left">Vendas</div>
                    </td>
                    {sid.sales.slice(8).map(value => {
                      const color = pickHex(value, sid.maxSales, sid.minSales, sid.midSales).join(',')
                      return <td className="td-home has-text-centered" style={{ background: `rgb(${color})` }}>
                        {value === 0 ? `-` : `${value}`}
                      </td>
                    })}
                  </tr>,
                  <tr className="is-performance-rows">
                    <td className="td-home is-last-row" />
                    <td className="td-home is-last-row" style={{width: 95, minWidth: 95, maxWidth: 95}}>
                      <div className="has-text-left">Conversão</div>
                    </td>
                    {sid.salesRate.slice(8).map(value => {
                      const color = pickHex(value, sid.maxSalesRate, sid.minSalesRate, sid.midSalesRate)
                        .join(',')
                      return <td className="td-home has-text-centered is-last-row" style={{ background: `rgb(${color})` }}>
                        {value === 0 ? `-` : `${value}%`}
                      </td>
                    })}
                  </tr>]).flat()}

                  <tr className="is-performance-rows">
                    <td className="td-home">
                      <div className="tag is-dark is-medium">CIA</div>
                    </td>
                    <td className="td-home" style={{width: 95, minWidth: 95, maxWidth: 95}}>
                      <div className="has-text-left">Visitantes</div>
                    </td>
                    {ciaDataHour.slice(8).map(hourT => <td className="td-home has-text-centered" style={{background: `rgb(${pickHex(hourT.visitorsSum, maxCiaVisitors, minCiaVisitors, midCiaVisitors).join(',')})`}}>
                      {hourT.visitorsSum === 0 ? `-` : `${hourT.visitorsSum}`}
                    </td>)}
                  </tr>

                  <tr className="is-performance-rows">
                    <td className="td-home" />
                    <td className="td-home" style={{width: 95, minWidth: 95, maxWidth: 95}}>
                      <div className="has-text-left">Vendas</div>
                    </td>
                    {ciaDataHour.slice(8).map(hourT => <td
                      className="td-home has-text-centered"
                      style={{
                        background: `rgb(${pickHex(hourT.totalSalesSum, maxCiaSales, minCiaSales, midCiaSales).join(',')})`
                      }}>
                      {hourT.totalSalesSum === 0 ? `-` : `${hourT.totalSalesSum}`}
                    </td>)}
                  </tr>
                  
                  <tr className="is-performance-rows">
                    <td className="td-home" />
                    <td className="td-home" style={{width: 95, minWidth: 95, maxWidth: 95}}>
                      <div className="has-text-left">Conversão</div>
                    </td>
                    {ciaDataHour.slice(8).map(hourT => <td className="td-home has-text-centered" style={{ background: `rgb(${pickHex(hourT.salesRateSum, maxCiaSalesRate, minCiaSalesRate, midCiaSalesRate).join(',')})`}}>
                      {hourT.salesRateSum === 0 ? `-` : `${hourT.salesRateSum}%`}
                    </td>)}
                  </tr>
              </tbody>
            </table>
          </div>}
        </div>
      </Column>
    </Columns>
  </Page>
}

export default (HomePage);