import React, { Component } from 'react';

import { post, put } from '../../../../../utils';
import * as Icons from '../../../../../components/icons';
import notification from '../../../../../utils/notification';

class CameraForm extends Component {

  constructor(props) {
    super(props);

    const { description='', url='' } = props.camera || {};

    this.state = {
      fields: {
        description,
        url
      },
      isSaving: false
    }
  }

  componentWillReceiveProps(nextProps) {
    if(this.props.camera) {

      if(nextProps.camera.id !== this.props.camera.id) {
        const { description='', url='' } = nextProps.camera;
        this.setState({
          fields: {
            description,
            url
          },
          isSaving: false
        });
      }

    }
  }

  _onChange = (e) => {
    const {fields} = this.state;
    const {value, name} = e.target;

    fields[name] = value;
    this.setState({ fields });
  }

  _submitForm = (e) => {
    e.preventDefault();

    const {isSaving, fields} = this.state;
    if(isSaving)
      return;

    this.setState({ isSaving: true }, () => {
      const { siteId, camera } = this.props;
      const { description, url } = fields;

      if(camera && camera.id) {
        put(`admin/videos/sites/${siteId}/cameras/${camera.id}`, { description, url })
          .then((resp) => {
            notification.success('Dados salvos com sucesso!')
            this.setState({ isSaving: false }, () => {
              this.props.handleSubmit(resp.data.data);
            });
          });

        return;
      }

      post(`admin/videos/sites/${siteId}/cameras`, { description, url })
        .then(resp => {
          notification.success('Dados salvos com sucesso!')
          this.setState({
            fields: {
              description: '',
              url: ''
            },
            isSaving: false
          }, () => this.props.handleSubmit(resp.data.data));
        });
    });
  }

  _toogleModalDvr = (camera) => () => {
    this.props.toogleModalDvr(camera);
  }

  render() {
    const { camera={} } = this.props;
    const { isSaving, fields } = this.state;
    return (<form onSubmit={this._submitForm}>
      {/* {!camera.id && <div className="control is-expanded" style={{marginBottom: 10 }} onClick={this._toogleModalDvr(null)}>
        <div
          style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}
          className="button is-small is-default is-outlined is-fullwidth has-text-centered">
          <Icons.VideoIcon /> <span>Selecionar origem</span>
        </div>
      </div>}
      {camera.id && <div className="control is-expanded" style={{marginBottom: 10 }} onClick={this._toogleModalDvr(camera)}>
        <p className="subtitle is-5">
          Origem: 192.168.3.25 Canal 12
        </p>
      </div>}
      {camera.id && <div className="control is-expanded" style={{marginBottom: 10 }} onClick={this._toogleModalDvr(camera)}>
        <div
          style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}
          className="button is-small is-default is-outlined is-fullwidth has-text-centered">
          <Icons.VideoIcon /> <span>Mudar origem</span>
        </div>
      </div>} */}
      <div className="field is-grouped is-small">
        <span className="control has-icons-left has-icons-right is-expanded">
          <input
            onChange={this._onChange}
            value={fields.description}
            name="description"
            className="input"
            type="text"
            placeholder="Descrição camera"
            required
          />
          <Icons.VideoIcon className="is-small is-left"/>
        </span>
      </div>

      <div className="field is-grouped is-small">
        <span className="control has-icons-left is-expanded">
          <input
            onChange={this._onChange}
            value={fields.url}
            name="url"
            className="input"
            type="text"
            placeholder="URL acesso da câmera"
            required
          />
          <Icons.EthernetIcon className="is-small is-left"/>
        </span>

        {camera.id && <span className="control">
          {fields.description !== this.props.camera.description
           || fields.url !== this.props.camera.url
            ? <button
              type="submit"
              className={`button is-primary is-outlined ${isSaving ? 'is-loading' : ''}`}
            >
              <Icons.VideoIcon />
              <span>Salvar camera</span>
            </button>
            : <button
              type="submit"
              className='button is-static '
            >
              <Icons.CheckIcon />
              <span>Salvo</span>
            </button>}
        </span>}

        {!camera.id && <span className="control">
          <button type="submit" className={`button is-primary ${isSaving ? 'is-loading' : ''}`}>
            <Icons.VideoIcon />
            <span>Salvar</span>
          </button>
        </span>}
      </div>
    </form>);
  }
}

export default (CameraForm);



        
