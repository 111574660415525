import React, { Component } from 'react';
import { get } from '../../../../../utils';

import * as Icons from '../../../../../components/icons';
import Columns, { Column } from '../../../../../components/Columns';
import Modal from '../../../../../components/Modal';
import JohannAreaRow from './JohannAreaRow';
import JohannAreaForm from './JohannAreaForm';

class JohannAreasListForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      toogleAdd: false,
      loading: false,
      areas: [],
      venues: [],
      toogleModal: false,
      toogleModalAdd: false,
      modalAreaId: 0,
      forms: {}
    }
  }

  componentDidMount() {
    this._loadAreas()
  }

  _loadAreas = () => {
    this.setState({ loading: true }, () => {
      const { siteId } = this.props;
      const foo1 = get(`admin/johann/sites/${siteId}/areas`);
      const foo2 = get(`admin/johann/sites/${siteId}/venues`);
      
      Promise.all([foo1, foo2])
      .then(promises => this.setState({ toogleAdd: false, loading: false, venues: promises[1].data.data, areas: promises[0].data.data }) )
    })
  }

  _toogleModal = (id) => {
    const reloadVenuesWhenLeave = this.state.toogleModal;
    this.setState({ toogleModal: !this.state.toogleModal, modalAreaId: id }, () => reloadVenuesWhenLeave ? this._loadAreas(): '');
  }

  render() {
    const { siteId } = this.props;
    const {loading, areas, toogleModal, modalAreaId, venues} = this.state;  

    let mappedVenues = []
    let mappedAreas = []
    if(!loading){
      mappedVenues = venues.map(v => {
        return {value: v.id, label: v.name, area_id: v.areaId}
      })
      mappedAreas = areas.filter(a => !a.principal && a.type === 'TRUCK_SHED').map(a => {
        return {value: a.id, label: a.description, principal_id: a.area_principal_id}
      })
    }
    
    const selectedArea = modalAreaId 
      ? areas.find(area => area.id === modalAreaId) || {}
      : {};
    return (<Columns isMultiline>
            
      {toogleModal && <Modal handleToogleModal={this._toogleModal} component={ <JohannAreaForm  toggle={this._toogleModal} reloadAreas={this._loadAreas} areas={mappedAreas} venues={mappedVenues} area={selectedArea} siteId={this.props.siteId} />  } />}
      <Column style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
        <div className="button is-primary"  onClick={this._toogleModal} >
          <Icons.MicroChipIcon />
          <span>Nova Área</span>
        </div>
      </Column>
      <Column isSize={12} style={{ height: '100%' }}>
        <table className="table is-striped is-hoverable is-fullwidth">
          <thead>
            <tr>
              <th>Descrição</th>
              <th>Tipo de área</th>
              <th>Principal</th>
              <th>Área pai</th>
              <th style={{width: 86}}>Opções</th>
            </tr>
          </thead>
          {!loading && 
          <tbody>
            {areas.map(area => <JohannAreaRow selectRowEdit={this._toogleModal} key={area.id} area={area} siteId={siteId} />)}
          </tbody>}
        </table>
       
        {loading && <p className="has-text-centered subtitle is-4 "><span className="button is-loading is-white is-bordless is-very-big"></span> </p>}
        {!loading && areas.length === 0 &&  <p className="has-text-centered subtitle is-4 "> Não há dados cadastrados. </p>}
      </Column>
    </Columns>);
  }
}

export default (JohannAreasListForm);