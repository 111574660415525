import React, { Component } from 'react';

import {
  format, differenceInDays, addDays
} from 'date-fns';

import {
  ResponsiveContainer,
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Tooltip,
  Brush,
  Line,
  Cell,
  LabelList
} from 'recharts';

import DateFilterOneRange from './DateFilterOneRange';
import DownloadButton from '../../../../components/DownloadButton';
import { getServerDate } from '../../../../utils';

class VisitorsAndPassersByHourly extends Component {

  constructor(props) {
    super(props);
    const CURRDATE = getServerDate();

    const chartDate = addDays(CURRDATE, -1);

    
    const {
      filterRanges = {
        startDate: format(addDays(chartDate, -14), 'YYYY-MM-DD') ,
        endDate: format(chartDate, 'YYYY-MM-DD'),
        key: 'selectionRange1'
      },
      dash = {
        header: {},
        data: []
      }, 
      dashVideo = false
    } = props;

    this.state = {
      isLoading: false,
      typeChart: 'HOURLY',
      filterRanges: filterRanges,
      dash: dash,
      dashVideo
    }
  }
  
  componentDidMount() {
    this.loadData();
  }
  
  _changeDateFilter = () => (ranges) => {
    let { endDate, startDate } = ranges;

    startDate = format(startDate, 'YYYY-MM-DD');
    endDate = format(endDate, 'YYYY-MM-DD');

    this.setState({ filterRanges: { ...ranges, startDate, endDate } }, () => {
      this.loadData();
    });
  }

  loadData = () => {
    this.setState({ isLoading: true }, () => {
      const { typeChart, filterRanges } = this.state;
      this.props.loadPeopleCount(typeChart, filterRanges.startDate, filterRanges.endDate)
        .then(result => {
          this.setState({ isLoading: false, dash: result });
        });
    });
  }

  render() {
    const { dash, filterRanges, isLoading, dashVideo } = this.state;
    const diffBetweenDates = differenceInDays(filterRanges.endDate, filterRanges.startDate);
    const brushSize = 24 * (diffBetweenDates);
    return (<div className="card" style={{
      width: '100%'
    }}>
      <header className="card-header" style={{ padding: '20'}}>
        <p className="card-header-title  headingis-5 is-marginless"style={{ fontSize: '1.25rem'}}>
        Visitantes vs Vendas - Hora
        </p>
        {!dashVideo && <a href className="card-header-icon" aria-label="Download PDF">
          <DownloadButton site={this.props.site} name='peoplecount' begin={filterRanges.startDate} end={filterRanges.endDate} params={{type: 'HOURLY'}}/>
        </a>}
      </header>
      <div className="card-content">
        <p className="subtitle is-4 has-text-centered">
          Dados de 
          {!isLoading 
            ? diffBetweenDates === 0 
              ? `${format(filterRanges.endDate, ' DD/MM/YY')}` : `${format(filterRanges.startDate, ' DD/MM/YY')} até ${format(filterRanges.endDate, 'DD/MM/YY')}`
            : ' ...'
          }
        </p>
        {isLoading 
        ? <div className="has-text-centered" style={{ height:300, paddingTop: '100px' }}>
            <span>
              <span className="button is-loading is-large is-bordless" />
              <p className="subtitle is-5">Aguarde, carregando...</p>
            </span>
          </div>
        : (dash.header.hasSales ? <ResponsiveContainer width="100%" height={300}>
          <ComposedChart data={dash.data}
              margin={{top: 20, right: 50, left: 50, bottom: 20}}>
            <CartesianGrid strokeDasharray="3 10" />
            <XAxis interval={0} dataKey="info_date" tickFormatter={(val) => `${format(val, 'HH:mm')}`}/>
            <YAxis yAxisId="left" orientation="left" stroke="#343434"/>
            <YAxis yAxisId="left" orientation="left" stroke="#343434"/>
              <YAxis yAxisId="right" orientation="right" stroke="#343434"/>
              <Legend />
              <Tooltip
                content={<CustomToolTip hasSales={dash.header.hasSales} />}
                isAnimationActive={false}
              />
              <Brush
                startIndex={diffBetweenDates >= 1 ? brushSize + 7 : 7}
                endIndex={diffBetweenDates >= 1 ? brushSize + 22 : 22}
                dataKey='info_date'
                tickFormatter={(val) => `${format(val, 'DD/MM - HH:mm')}`}
                height={20}
                stroke="#000000"/>
              <Bar
                key="visitorsCount"
                yAxisId="left"
                name="Visitantes Total"
                dataKey="visitorsCount"
                maxBarSize={15}
                fill="#0043ff"
                stroke="#0043ff"
                isAnimationActive={false}
                minPointSize={3}
                connectNulls={true}
                color="#222"
              >
                {
                  dash.data.map((entry, index) => (
                    <Cell color="#222" fill={'#0043ff'} fillOpacity={1} key={`cell-visitor-${index}`}/>
                  ))
                }
              </Bar>
              <Bar
                key="totalSales"
                yAxisId="left"
                name="Vendas"
                dataKey="totalSales"
                maxBarSize={15}
                fill="#00d1b2"
                stroke="#00d1b2"
                isAnimationActive={false}
                minPointSize={3}
                connectNulls={true}
                color="#222"
              >
                {
                  dash.data.map((entry, index) => (
                    <Cell color="#222" fill={'#00d1b2'} fillOpacity={1} key={`cell-visitor-${index}`}/>
                  ))
                }
              </Bar>
              <Line key="salesRate"
                yAxisId="right"
                name="Taxa de vendas"
                dataKey="salesRate"
                strokeDasharray="3 5"
                fill="#000"
                stroke="#000"
                isAnimationActive={false}
                connectNulls={true}
                position="top"
              >
                <LabelList
                  dataKey="salesRate"
                  position="top" 
                  formatter={(val) => `${val}%`}
                  fontSize={22}
                />
              </Line>
          </ComposedChart>
        </ResponsiveContainer> : <ResponsiveContainer width="100%" height={300}>
          <ComposedChart data={dash.data}
              margin={{top: 20, right: 50, left: 50, bottom: 20}}>
            <CartesianGrid strokeDasharray="3 10" />
            <XAxis interval={0} dataKey="info_date" tickFormatter={(val) => `${format(val, 'HH:mm')}`}/>
            <YAxis yAxisId="left" orientation="left" stroke="#343434"/>
            <YAxis yAxisId="right" orientation="right" stroke="#343434" tickFormatter={(val) => `${val}%`}/>
            <Legend />
            <Brush
              startIndex={diffBetweenDates >= 1 ? brushSize + 7 : 7}
              endIndex={diffBetweenDates >= 1 ? brushSize + 22 : 22}
              dataKey='info_date'
              tickFormatter={(val) => `${format(val, 'DD/MM - HH:mm')}`}
              height={20}
              stroke="#000000"/>
            <Tooltip
              content={<CustomToolTip />}
              isAnimationActive={false}/>
            <Bar
              key="visitors"
              yAxisId="left"
              name="Visitantes"
              dataKey="visitorsCount"
              maxBarSize={15}
              fill="#0043ff"
              stroke="#0043ff"
              stackId="peoplecount"
              color="#222222"
              isAnimationActive={false}
              minPointSize={5}
              connectNulls={true}
              label={{position: 'top'}}
            />
          </ComposedChart>
        </ResponsiveContainer>)} 
        </div>
      <footer className="card-footer">
        <span className="button has-text-left is-white card-footer-item is-radiusless is-shadowless is-bordless" style={{ borderRight: 'none' }}>
          <DateFilterOneRange maxDate={addDays(getServerDate(), -1)} changeDates={this._changeDateFilter()} actualRanges={{ ranges: filterRanges }}/>
        </span>
        <span className="card-footer-item" style={{ borderRight: 'none' }}>
        </span>
        <span className="card-footer-item" style={{ borderRight: 'none' }}>
        </span>
        <span className="card-footer-item" style={{ borderRight: 'none' }}>
        </span>
      </footer>
    </div>)
  }
}

class CustomToolTip extends Component {
  render() {
    const { active, payload=[], hasSales } = this.props;
    if(active) {

      let data = { payload: {} };
      if(payload && payload.length > 0){
        data = payload[0]
      }

      return (<div className="card notification" style={{ padding: '0px 5px 0px 5px' }}>
          {hasSales ? <div  style={{ padding: '0px 15px 0px 15px' }}>
            <p className="heading has-text-centered">
              Em {format(data.payload.info_date, 'DD/MM HH:mm')}
            </p>

            <span className="heading has-text-centered" style={{
              width: '100%',
              fontWeight: 'bold',
              marginBottom: '-5px'
            }}>
              <span>Visitantes</span>
            </span>

            <span className="heading has-text-centered" style={{
              letterSpacing: 0.5,
              width: '100%',
              fontSize: '1.5em',
              marginBottom: '-5px',
              fontWeight: 'bold',
              color: '#0043ff'
            }}>
              <span>{data.payload.visitorsCount}</span>
            </span>
            <span className="heading has-text-centered" style={{ letterSpacing: 0.5, width: '100%', fontWeight: 'bold', color: '#0043ff' }}>
              <span>({data.payload.newVisitorsCount} novos)</span>
            </span>

            <span className="heading has-text-centered" style={{ width: '100%', fontWeight: 'bold', marginBottom: '-5px' }}>
              <span>Vendas</span>
            </span>
            <span className="heading has-text-centered" style={{fontWeight: 'bold', letterSpacing: 0.5, width: '100%', fontSize: '1.5em', color: '#00d1b2' }}>
              <span>{data.payload.totalSales}</span>
            </span>

            <span className="heading has-text-centered" style={{ width: '100%', fontWeight: 'bold', marginBottom: '-3px' }}>
              <span>Taxa vendas</span>
            </span>
            <span className="heading has-text-centered" style={{ letterSpacing: 0.5, width: '100%', fontSize: '0.5em', marginBottom: '-5px'}}>
              <span>(vendas / todos)</span>
            </span>
            <span className="heading has-text-centered" style={{fontWeight: 'bold', letterSpacing: 0.5, width: '100%', fontSize: '1.5em', color: '#222222' }}>
              <span>{data.payload.salesRate}%</span>
            </span>

          </div> : <div  style={{ padding: '0px 5px 0px 5px' }}>
            <p className="heading has-text-centered">
              Em {format(data.payload.info_date, 'DD/MM HH:mm')}
            </p> 
            <span className="heading has-text-centered" style={{ fontWeight: 'bold', marginBottom: '-2px', color: '#222222' }}>
              <span>Visitantes</span>
            </span>
            <span className="heading has-text-centered" style={{ letterSpacing: 0.5, fontSize: '1.5em', marginBottom: '-5px', fontWeight: 'bold', color: '#0043ff' }}>
              <span> {data.payload.visitorsCount}</span>
            </span>
          </div>}
      </div>)
    }
    return null;
  }
}

export default (VisitorsAndPassersByHourly);