import React, { Component } from 'react';

import {
  format,
  differenceInDays
} from 'date-fns';

import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Bar,
  ComposedChart,
  Brush,
  Legend,
  Area,
  Line,
  LabelList
} from 'recharts';
import Columns, { Column } from '../../../../components/Columns';

export default class VenueVisitorsChart extends Component {
  constructor(props) {
    super(props)
    this.state = {
      startIndex: 0, endIndex: 0
    }
  }
  componentDidMount() {
    const { filterRanges } = this.props;
    const diffBetweenDates = differenceInDays(format(filterRanges.endDate, 'YYYY-MM-DD'), format(filterRanges.startDate, 'YYYY-MM-DD'));
    const brushSize = (24 * (diffBetweenDates));
    this.setState({ startIndex: diffBetweenDates >= 1 ? brushSize + 7 : 7, endIndex: diffBetweenDates >= 1 ? brushSize + 22 : 22 })
  }
  render() {
    const { startIndex, endIndex } = this.state;
    const { quantidadeDeVenues, isSuvinil = false, description } = this.props;
    const { data = [], formatDate = 'DD/MM HH:mm', isHourly = false } = this.props;
    const { venueVisitorsSales = [] } = this.props;

    const chartDataSecondFloorDaily = venueVisitorsSales.map(d => {
      const { info_date, snapDate, sales, visitors, venueId, salesRate, timeSpentMean } = d;
      return {
        snapDate: info_date || snapDate,
        sales: sales || 0,
        visitors: visitors || 0,
        salesRate: salesRate || 0,
        salesRateHourly: Math.round((sales / visitors) * 100) || 0,
        venueId: venueId,
        timeSpentMean: timeSpentMean
      }
    })

    const chartData = data.map(d => {
      const { timeSpentMean, visitorsCount, siteVisitors, info_date, visitorsRate } = d;
      return {
        info_date,
        visitorsCount,
        timeSpentMean: timeSpentMean / 60,
        siteVisitors,
        visitorsRate: visitorsRate,
        visitorsRateHourly: siteVisitors === 0 ? 0 : Math.round((visitorsCount / siteVisitors) * 100)
      }
    });

    return (
      <Columns isMultiline>
        <Column isSize={12}>
          {quantidadeDeVenues >= 1 && <ResponsiveContainer width={'100%'} height={isHourly ? 275 : 225}>
            <ComposedChart data={chartData} margin={{ top: 0, right: 50, left: 20, bottom: 0 }} syncId={`id${isHourly}`}>
              <CartesianGrid strokeDasharray="3 10" />
              <XAxis dataKey="info_date" tickFormatter={date => format(date, formatDate)} />
              {!isHourly && <YAxis yAxisId="left" orientation="left" dataKey="siteVisitors" domain={[0, 'dataMax+300']} fill="#00d1b2" />}
              {isHourly && <YAxis yAxisId="left" orientation="left" dataKey="siteVisitors" domain={[0, 'dataMax+50']} fill="#00d1b2" />}
              {!isHourly && <YAxis yAxisId="right" orientation="right" dataKey="visitorsRate" domain={[0, 120]} fill="#363636" />}
              {isHourly && <YAxis yAxisId="right" orientation="right" dataKey="visitorsRate" domain={[0, 120]} fill="#363636" />}
              {isHourly && <Brush
                className="brushDisplayNone"
                startIndex={startIndex}
                endIndex={endIndex}
                dataKey='info_date'
                tickFormatter={(val) => ``}
                height={30}
                stroke="#000000" />}
              <Tooltip
                content={<CustomToolTip
                  isSuvinil={isSuvinil}
                  formatDate={formatDate}
                  isAnimationActive={false} />}
              />

              <Legend />
              {!isSuvinil && <Bar
                yAxisId="left"
                type="monotone"
                name="Visitantes Loja"
                label={false}
                connectNulls={true}
                dataKey="siteVisitors"
                isAnimationActive={false}
                stroke="#00d1b2"
                barSize={20}
                fill="#00d1b2"
              />}
              <Bar
                yAxisId="left"
                type="monotone"
                name={`Visitantes ${description}`}
                label={false}
                connectNulls={true}
                dataKey="visitorsCount"
                isAnimationActive={false}
                stroke="#F05C5A"
                barSize={20}
                fill="#F05C5A"
              />
              {!isSuvinil && <Line
                name={`Atratividade ${description}`}
                yAxisId="right"
                type="monotone"
                connectNulls={true}
                dataKey={isHourly ? "visitorsRateHourly" : "visitorsRate"}
                stroke="#363636"
                fill="#363636"
                isAnimationActive={false}
              >
                <LabelList
                  dataKey={isHourly ? "visitorsRateHourly" : "visitorsRate"}
                  position="top"
                  isAnimationActive={false}
                  formatter={(val) => `${val}%`}
                  fontSize={22}
                />
              </Line>}
            </ComposedChart>
          </ResponsiveContainer>}
        </Column>

        {/*Graphic to sales, visitors and rate sales*/}
        <Column isSize={12}>
          {!isSuvinil && quantidadeDeVenues >= 1 && <ResponsiveContainer width={'100%'} height={isHourly ? 275 : 225}>
            <ComposedChart data={chartDataSecondFloorDaily} margin={{ top: 0, right: 50, left: 20, bottom: 0 }} syncId={`id${isHourly}`}>
              <CartesianGrid strokeDasharray="3 10" />
              <XAxis dataKey="snapDate" tickFormatter={date => format(date, formatDate)} />
              {!isHourly && <YAxis yAxisId="left" orientation="left" dataKey="visitors" domain={[0, 'dataMax+300']} fill="#00d1b2" />}
              {isHourly && <YAxis yAxisId="left" orientation="left" dataKey="visitors" domain={[0, 'dataMax+50']} fill="#00d1b2" />}
              {!isHourly && <YAxis yAxisId="right" orientation="right" dataKey="sales" domain={[0, 120]} fill="#363636" />}
              {isHourly && <YAxis yAxisId="right" orientation="right" dataKey="sales" domain={[0, 120]} fill="#363636" />}
              {isHourly && <Brush
                className="brushDisplayNone"
                startIndex={startIndex}
                endIndex={endIndex}
                dataKey='snapDate'
                tickFormatter={(val) => ``}
                height={30}
                stroke="#000000" />}
              <Tooltip
                content={<CustomToolTipVenue
                  venueDescription={description}
                  isSuvinil={isSuvinil}
                  formatDate={formatDate}
                  isAnimationActive={false}
                  venueVisitorsSales={venueVisitorsSales} />}
              />
              <Legend />
              {!isSuvinil && <Bar
                yAxisId="left"
                type="monotone"
                name={`Visitantes ${description}`}
                label={false}
                connectNulls={true}
                dataKey="visitors"
                isAnimationActive={false}
                stroke="#F05C5A"
                barSize={20}
                fill="#F05C5A"
              />}
              <Bar
                yAxisId="left"
                type="monotone"
                name={`Vendas ${description}`}
                label={false}
                connectNulls={true}
                dataKey="sales"
                isAnimationActive={false}
                stroke="#7C81FC"
                barSize={20}
                fill="#7C81FC"
              />
              <Line
                name={`Conversão ${description}`}
                yAxisId="right"
                type="monotone"
                connectNulls={true}
                dataKey={'salesRate' && 'salesRateHourly'}
                stroke="#363636"
                fill="#363636"
                isAnimationActive={false}
              >
                <LabelList
                  dataKey={'salesRate' && 'salesRateHourly'}
                  position="top"
                  isAnimationActive={false}
                  formatter={(val) => `${val}%`}
                  fontSize={22}
                />
              </Line>
            </ComposedChart>
          </ResponsiveContainer>}
        </Column>

        <Column isSize={12}>
          <ResponsiveContainer width={'100%'} height={isHourly ? 275 : 225}>
            <ComposedChart data={chartData} margin={{ top: 0, right: 50, left: 20, bottom: 0 }} syncId={`id${isHourly}`}>
              <CartesianGrid strokeDasharray="3 10" />
              <XAxis dataKey="info_date" tickFormatter={date => format(date, formatDate)} />
              <YAxis unit=" min" yAxisId="left" orientation="left" dataKey="timeSpentMean" domain={[0, 'dataMax+3']} fill="#00d1b2" tickFormatter={label => parseInt(label, 10)} />
              <YAxis unit=" min" yAxisId="right" orientation="right" dataKey="timeSpentMean" domain={[0, 'dataMax+3']} fill="#00d1b2" tickFormatter={label => parseInt(label, 10)} />
              {isHourly && <Brush
                startIndex={startIndex}
                endIndex={endIndex}
                dataKey='info_date'
                tickFormatter={(val) => `${format(val, 'DD/MM - HH:mm')}`}
                height={30}
                stroke="#000000" />}
              <Tooltip
                content={<CustomToolTip
                  formatDate={formatDate}
                  isAnimationActive={false} />} />
              <Legend />
              <Area
                yAxisId="left"
                type="monotone"
                connectNulls={true}
                name={`Tempo médio ${description}`}
                dataKey="timeSpentMean"
                stroke="#F05C5A"
                strokeWidth={2}

                isAnimationActive={false}
                fillOpacity={0.2}
                fill="#F05C5A"
                activeDot={true}
                label={props => <g transform={`translate(${props.x},${props.y})`}>
                  <text x={7} y={-10} dx={0} dy={-5} textAnchor="end" fill="#222">{parseInt(props.value, 10) > 0 ? `${parseInt(props.value, 10)}m` : ''}  {Math.round((props.value % 1) * 60)}s</text>
                </g>}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </Column>
      </Columns>);
  }
}

class CustomToolTip extends Component {
  render() {
    const { active, payload = [], formatDate, isSuvinil = false } = this.props;

    if (active) {

      let data = { payload: {} };
      if (payload && payload.length > 0) {
        data = payload[0]
      }
      let val = data.dataKey === 'timeSpentMean' ? `${parseInt(data.value, 10) > 0 ? `${parseInt(data.value, 10)}m` : ''}  ${Math.round((data.value % 1) * 60)}s` : data.value

      if (data.dataKey === 'timeSpentMean' || isSuvinil) {
        return (<div className="card notification" style={{ padding: '0px 5px 0px 5px' }}>
          <p className="heading has-text-centered">
            Em {format(data.payload.info_date, formatDate)}
          </p>
          <span className="heading has-text-centered" style={{ fontWeight: 'bold', marginBottom: '-2px', color: data.color }}>
            <span>{data.name}</span>
          </span>
          <span className="has-text-centered" style={{ display: 'block', letterSpacing: '1px', fontSize: '1.5em', marginBottom: '-5px', fontWeight: 'bold', color: data.color }}>
            <span>{val}</span>
          </span>
        </div>)
      }

      return (<div className="card notification" style={{ padding: '0px 5px 0px 5px' }}>
        <p className="heading has-text-centered">
          Em {format(data.payload.info_date, formatDate)}
        </p>
        <span className="heading has-text-centered" style={{ fontWeight: 'bold', marginBottom: '-2px', color: "#00d1b2" }}>
          <span>Visitante Loja</span>
        </span>
        <span className="has-text-centered" style={{ display: 'block', letterSpacing: '1px', fontSize: '1.5em', marginBottom: '5px', fontWeight: 'bold', color: "#00d1b2" }}>
          <span>{data.payload.siteVisitors || 0}</span>
        </span>
        <span className="heading has-text-centered" style={{ fontWeight: 'bold', marginBottom: '-2px', color: "#c8142d" }}>
          <span>Visitantes Area</span>
        </span>
        <span className="has-text-centered" style={{ display: 'block', letterSpacing: '1px', fontSize: '1.5em', marginBottom: '5px', fontWeight: 'bold', color: "#c8142d" }}>
          <span>{data.payload.visitorsCount || 0}</span>
        </span>
        <span className="heading has-text-centered" style={{ fontWeight: 'bold', marginBottom: '-2px', color: "#363636" }}>
          <span>Atratividade</span>
        </span>
        <span className="has-text-centered" style={{ display: 'block', letterSpacing: '1px', fontSize: '1.5em', marginBottom: '5px', fontWeight: 'bold', color: "#363636" }}>
          <span>{data.payload.visitorsRate || 0}%</span>
        </span>
      </div>)
    }
    return null;
  }
}

//Information card to visitors, sales and rate sales
class CustomToolTipVenue extends Component {
  render() {
    const { active, payload = [], formatDate, isSuvinil = false, venueDescription } = this.props;

    if (active) {

      let data = { payload: {} };
      if (payload && payload.length > 0) {
        data = payload[0]
      }
      let val = data.dataKey === 'timeSpentMean' ? `${parseInt(data.value, 10) > 0 ? `${parseInt(data.value, 10)}m` : ''}  ${Math.round((data.value % 1) * 60)}s` : data.value

      if (data.dataKey === 'timeSpentMean' || isSuvinil) {
        return (<div className="card notification" style={{ padding: '0px 5px 0px 5px' }}>
          <p className="heading has-text-centered">
            Em {format(data.payload.snapDate, formatDate)}
          </p>
          <span className="heading has-text-centered" style={{ fontWeight: 'bold', marginBottom: '-2px', color: data.color }}>
            <span>{data.name}</span>
          </span>
          <span className="has-text-centered" style={{ display: 'block', letterSpacing: '1px', fontSize: '1.5em', marginBottom: '-5px', fontWeight: 'bold', color: data.color }}>
            <span>{val}</span>
          </span>
        </div>)
      }

      return (<div className="card notification" style={{ padding: '0px 5px 0px 5px' }}>
        <p className="heading has-text-centered">
          Em {format(data.payload.snapDate, formatDate)}
        </p>
        <span className="heading has-text-centered" style={{ fontWeight: 'bold', marginBottom: '-2px', color: "#c8142d" }}>
          <span>{`Visitante ${venueDescription}`}</span>
        </span>
        <span className="has-text-centered" style={{ display: 'block', letterSpacing: '1px', fontSize: '1.5em', marginBottom: '5px', fontWeight: 'bold', color: "#c8142d" }}>
          <span>{data.payload.visitors || data.payload.visitors || 0}</span>
        </span>
        <span className="heading has-text-centered" style={{ fontWeight: 'bold', marginBottom: '-2px', color: "#7C81FC" }}>
          <span>{`Vendas ${venueDescription}`}</span>
        </span>
        <span className="has-text-centered" style={{ display: 'block', letterSpacing: '1px', fontSize: '1.5em', marginBottom: '5px', fontWeight: 'bold', color: "#7C81FC" }}>
          <span>{data.payload.sales || data.payload.sales || 0}</span>
        </span>
        <span className="heading has-text-centered" style={{ fontWeight: 'bold', marginBottom: '-2px', color: "#363636" }}>
          <span>{`conversão ${venueDescription}`}</span>
        </span>
        <span className="has-text-centered" style={{ display: 'block', letterSpacing: '1px', fontSize: '1.5em', marginBottom: '5px', fontWeight: 'bold', color: "#363636" }}>
          <span>{data.payload.salesRate || data.payload.salesRateHourly || 0}%</span>
        </span>
      </div>)
    }
    return null;
  }
}