export const LOGOUT='LOGOUT';
export const IS_LOADING_SHOPS='IS_LOADING_SHOPS';
export const CHANGE_USER_STATE='CHANGE_USER_STATE';
export const CHANGE_USER_NAME='CHANGE_USER_NAME';
export const CHANGE_USER_EMAIL='CHANGE_USER_EMAIL';
export const PERMISSIONS_ON_GROUP='PERMISSIONS_ON_GROUP';
export const PERMISSIONS_ON_SHOP='PERMISSIONS_ON_SHOP';

const INITIAL_STATE = {
  loadingApp: true,
  isLoadingShops: true,
  user: null,
  sitesPermissions: {},
  groupsPermissions: {}
};

export const actions = {
  isLoading: () => dispatch => dispatch({ type: IS_LOADING_SHOPS, payload: true }),
  notLoading: () => dispatch => dispatch({ type: IS_LOADING_SHOPS, payload: false }),
  userLogin: (user) => {
    return dispatch => {
      dispatch({ type: CHANGE_USER_STATE, payload: user });
    }
  },
  userLogout: () => {
    return dispatch => {
      dispatch({ type: CHANGE_USER_STATE, payload: null });
      dispatch({ type: LOGOUT, payload: null });
    }
  },
  changeUserDisplayName: (newName) => {
    return dispatch => {
      dispatch({ type: CHANGE_USER_NAME, payload: newName });
    } 
  },
  changeUserEmail: (newEmail) => {
    return dispatch => {
      dispatch({ type: CHANGE_USER_EMAIL, payload: newEmail });
    } 
  }
};

export const reducer = (state = INITIAL_STATE, action) => {
  const { payload } = action;
  switch(action.type) {
    case LOGOUT: {
      return { ...INITIAL_STATE, user: null, loadingApp: false };
    }
    case CHANGE_USER_STATE: {
      if(payload === null) {
        return { ...state, user: null, sitesPermissions: {}, groupsPermissions: {}, loadingApp: false }
      }
      const { id, uid, email, displayName, disabled, metadata, passwordConfig, sites, groups, tooqAdmin=false } = payload;
      return { ...state, user: { id, uid, displayName, email, disabled, metadata, passwordConfig, tooqAdmin }, sitesPermissions: sites, groupsPermissions: groups, loadingApp: false }
    }
    case CHANGE_USER_NAME: {
      const { user } = state;
      user.displayName = payload;
      return { ...state, user }
    }
    case CHANGE_USER_EMAIL: {
      const { user } = state;
      user.email = payload;
      return { ...state, user }
    }
    case IS_LOADING_SHOPS: {
      return { ...state, isLoadingShops: payload }
    }
    default: {
      return state;
    }
  }
};