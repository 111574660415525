import React, { Component } from 'react';
import { addDays } from 'date-fns';

import {
  Bar,
  CartesianGrid,
  ComposedChart,
  LabelList,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';

import { dateToDDMMYYYY, formatDateMonthDayOfWeek, getServerDate } from '../../../../../utils';
import DateFilterOneRange from './DateFilterOneRange';
import LabelCustomizedAuditing from './LabelCustomizedAuditing';
import DownloadButton from '../../../../../components/DownloadButton';
import * as Icons from '../../../../../components/icons';

class VisitorsAndPassersBy extends Component {

  constructor(props) {
    super(props);
    const CURRDATE = addDays(getServerDate(), -1);
    const {
      filterRanges = {
        startDate: addDays(CURRDATE, -6),
        endDate: CURRDATE,
        key: 'selectionRange1'
      },
      dash = {
        header: {},
        data: []
      }, 
      dashVideo = false
    } = props;

    this.state = {
      isLoading: false,
      typeChart: 'DAILY',
      filterRanges: filterRanges,
      dash,
      dashVideo
    }
  }
  componentDidMount() {

    const { dash} = this.state;
    if(dash.data.length === 0){
      this.loadData()
    }
   
  }
  renderChartLegend = (props) => {
    const { payload } = props;
    const { filterRanges } = this.state;

    if(!payload) {
      return <div></div>;
    }

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}> 
        <div className="help has-text-centered">
          Indicador da taxa de visitantes da loja, 
          uma linha do tempo de <b>{dateToDDMMYYYY(filterRanges.startDate)} </b> até <b>{dateToDDMMYYYY(filterRanges.endDate)} </b> 
          comparando o número de passantes com o número de visitantes.
        </div>
        <ul style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
          {payload.map((entry, index)=> {
            return <li key={`legenditem-${index}`} style={{ marginRight: '10px', color: entry.color }}>
              <b>
                {entry.dataKey === "passersByCount" ? 'Passantes' 
                  : entry.dataKey === "visitorsCount" ? 'Visitantes'
                  : 'Taxa de visitantes'}
              </b>
            </li>
          })}
        </ul>
      </div>
    );
  }


  _changeDateFilter = () => (ranges) => {
    this.setState({ filterRanges: ranges }, () => {
      this.loadData();
    });
  }

  loadData = () => {
    this.setState({ isLoading: true }, () => {
      const { typeChart, filterRanges } = this.state;
      this.props.loadPeopleCount(typeChart, filterRanges.startDate, filterRanges.endDate)
        .then(result => {
          this.setState({ isLoading: false, dash: result });
        });
    });
  }

  render() {
    const { dash, filterRanges, isLoading, dashVideo } = this.state;
    return (<div className="card notification is-white is-paddingless" style={{ width: '100%' }}>
      <header className="card-header" style={{ padding: '20' }}>
        <div className="card-header-title">
          Visitantes vs Passantes
        </div>
        <a href className="card-header-icon" aria-label="Download PDF">
          <DownloadButton site={this.props.site} name='peoplecount' begin={filterRanges.startDate} end={filterRanges.endDate} video={dashVideo}/>
        </a>
      </header>
      <div className="card-content" style={{
        justifyContent: 'center',
        flexDirection: 'column',
        display: 'flex',
        height: '500px'
      }}>
        {isLoading 
        ? <div className="has-text-centered" style={{ height: 500, paddingTop: '100px' }}>
            <span>
              <span className="button is-loading is-large is-bordless" />
              <p className="subtitle is-5">Aguarde, carregando...</p>
            </span>
          </div>
        : <ResponsiveContainer width="100%" height={300}>
          <ComposedChart data={dash.data}
              margin={{top: 20, right: 50, left: 10, bottom: 20}}>
            <CartesianGrid strokeDasharray="3 10" />
            <XAxis dataKey="info_date" tickFormatter={(val) => `${formatDateMonthDayOfWeek(val)}`}/>
            <YAxis yAxisId="left" orientation="left" stroke="#343434"/>
            <YAxis yAxisId="right" orientation="right" stroke="#343434" tickFormatter={(val) => `${val}%`}/>
            <Legend content={this.renderChartLegend} />
            <Tooltip
              content={<CustomToolTip />}
              isAnimationActive={false}/>
            <Bar
              key="passersBy"
              yAxisId="left"
              name="Passantes"
              dataKey="passersByCount"
              maxBarSize={15}
              fill="#0043ff"
              isAnimationActive={false}
              minPointSize={3}
              connectNulls={true}
            />
            <Bar
              key="visitors"
              yAxisId="left"
              name="Visitantes"
              dataKey="visitorsCount"
              maxBarSize={15}
              fill="#00d1b2"
              isAnimationActive={false}
              minPointSize={3}
              connectNulls={true}
            >
              <LabelList
                dataKey="auditingOk"
                position="top"
                content={<LabelCustomizedAuditing />}
              />
            </Bar>

            <Line key="taxaConversao"
              yAxisId="right"
              name="Taxa de visitantes"
              dataKey="conversionRate"
              strokeDasharray="3 5"
              fill="#000"
              stroke="#000"
              isAnimationActive={false}
              connectNulls={true}
            >
              <LabelList dataKey="conversionRate" position="top" formatter={(val) => `${val}%`} fontSize={22} />
            </Line>
          </ComposedChart>
        </ResponsiveContainer>} 
      </div>
      <footer className="card-footer" style={{ padding: '20px'}}>
        <span className="button has-text-left is-white card-footer-item is-radiusless is-shadowless is-bordless" style={{ borderRight: 'none' }}>
          <DateFilterOneRange changeDates={this._changeDateFilter()} actualRanges={{ ranges: filterRanges }}/>
        </span>
        <span className="card-footer-item" style={{ borderRight: 'none' }}>
        </span>
        <span className="card-footer-item" style={{ borderRight: 'none' }}>
        </span>
        <span className="card-footer-item" style={{ borderRight: 'none' }}>
        </span>
      </footer>
    </div>)
  }
}

class CustomToolTip extends Component {
  render() {
    const { active } = this.props;
    if(active) {
      const { payload=[] } = this.props;
      const data = payload[0] || { payload: {} };

      return (<div className="card">
        <div  style={{ padding: '0px 5px 0px 5px' }}>
          <p className="heading has-text-centered">
            Em {formatDateMonthDayOfWeek(data.payload.info_date)}
          </p>

          <span className="heading has-text-centered" style={{ fontWeight: 'bold', marginBottom: '-5px', color: '#0043ff' }}>
            <span>Passantes</span>
          </span>
          <span className="heading has-text-centered" style={{ color: '#0043ff' }}>
            <span>{data.payload.passersByCount}</span>
          </span>

          <span className="heading has-text-centered" style={{ fontWeight: 'bold', marginBottom: '-5px', color: '#00d1b2' }}>
            <span>Visitantes</span>
          </span>
          <span className="heading has-text-centered" style={{ color: '#00d1b2' }}>
            <span>{data.payload.visitorsCount}</span>
          </span>
          
          <span className="heading has-text-centered" style={{ fontWeight: 'bold', marginBottom: '-5px', color: '#000000' }}>
            <span>Taxa de visitantes</span>
          </span>
          <span className="heading has-text-centered" style={{ color: '#000000' }}>
            <span>{data.payload.conversionRate}</span>
          </span>

          {!data.payload.auditingOk && <span
            className="help is-danger">
            <Icons.ExclamationCircleIcon />
            <span>
              Dados não confiáveis.
            </span>
          </span>}
        </div>
      </div>)
    }
    return null;
  }
}

export default (VisitorsAndPassersBy);