import React, { useState, useEffect } from 'react';
import { get, post } from '../../../../utils';
import Select from 'react-select';
import notification from '../../../../utils/notification';


const OPTIONS = [
  { value: "Monday", label: "Segunda-feira" },
  { value: "Tuesday", label: "Terça-feira" },
  { value: "Wednesday", label: "Quarta-feira" },
  { value: "Thursday", label: "Quinta-feira" },
  { value: "Friday", label: "Sexta-feira" },
  { value: "Saturday", label: "Sábado" },
  { value: "Sunday", label: "Domingo" }
]


const AddAuditScheduler = (props) => {
  const {siteId, closeModal, handleAdd} = props;
  const [camerasOptions, setCamerasOptions] = useState([]);
  const [camerasSelected, setCamerasSelected] = useState([]);
  const [isAdding, setIsAdding] = useState(false);
  const [dayOfWeekArray, setDayOfWeekArray] = useState(OPTIONS);
  const [begin, setBegin] = useState("13:00:00");
  const [end, setEnd] = useState("13:59:00");
  
  useEffect(() => {
    setCamerasOptions([]);
    setCamerasSelected([]);
    get(`admin/videos/sites/${siteId}/cameras`)
      .then(resp => {
        const cameras = resp.data.data;
        const cameraOptions = cameras.map(camera => ({ value: camera.id, label: camera.description }))
        setCamerasOptions(cameraOptions);
        setCamerasSelected(cameraOptions);
      })
  },[siteId]);

  const onClickAddAuditScheduler = (e) => {
    e.preventDefault();

    if (isAdding) {
      return;
    }

    setIsAdding(true);
    post(`admin/audit/scheduler`, {
      siteId,
      daysOfWeek: dayOfWeekArray.map(day => day.value),
      cameras: camerasSelected.map(camera => camera.value),
      beginTime: begin,
      endTime: end
    })
      .then(resp => {
        handleAdd(resp.data.data);
        setIsAdding(false);
        notification.success('Registro inserido com successo.')
        closeModal()
      })
      .catch(err => {
        notification.error('Não foi possivel inserir o registro, tente novamente mais tarde.')
      })
  }

  return <div className='modal is-active' style={{ width: '100%' }}>
    <div className="modal-background" onClick={closeModal}></div>
    <form className='modal-card' onSubmit={onClickAddAuditScheduler}>
      <header className='modal-card-head'>
        <p className="modal-card-title">Adicionar cronograma de auditoria</p>
      </header>
      <section className='modal-card-body' style={{ height: '50vh' }}>
        <div className='control'>
          <div className="field">
            <label className="label">Câmeras</label>
            <div className="control is-expanded">
              <Select
                isMulti
                closeMenuOnSelect={false}
                options={camerasOptions}
                value={camerasSelected}
                placeholder="Cameras"
                onChange={e => setCamerasSelected(e)}
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Dias da semana</label>
            <div className="control is-expanded">
              <Select
                isMulti
                closeMenuOnSelect={false}
                options={OPTIONS}
                value={dayOfWeekArray}
                placeholder="Dias da semana"
                onChange={e => setDayOfWeekArray(e)}
              />
            </div>
          </div>
          <div className="field is-horizontal">
            <div className='field-body'>
              <div className='field'>
                <label className='label'>Hora início</label>
                <div className="control is-expanded">
                  <input
                    className="input is-success"
                    type="time"
                    value={begin}
                    onChange={e => setBegin(e.target.value)} />
                </div>
              </div>
              <div className='field'>
                <label className='label'>Hora fim</label>
                <div className="control is-expanded">
                  <input
                    className="input is-success"
                    type="time"
                    value={end}
                    onChange={e => setEnd(e.target.value)} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer className='modal-card-foot'>
        <button type='submit' className="button is-primary">
          Adicionar</button>
        <button className='button' onClick={closeModal}>Cancelar</button>
      </footer>
    </form>
  </div>

};

export default AddAuditScheduler;
