import React, { Component } from 'react';

/***
 * Template for page container
 */
export default class Page extends Component {
  render() {
    const { reactRef, style } = this.props;
    return (<div ref={reactRef} className="container is-fluid" style={{ paddingTop: 100, ...style, marginBottom: 20 }} >
      {this.props.children}
    </div>);
  }
}

export class PageTitle extends Component {
  render() {
    const { title, isLoading, children } = this.props;
    return (<nav className="level" style={{ marginBottom: '1.5em' }}>
    <div className="level-left">
      <div className="level-item">
        <h1 className="title">
          {title} {isLoading && <button style={{height: '1.75em'}} className="button is-text is-large is-loading"></button>}
        </h1>
      </div>
    </div>
    <div className="level-right">
      <div className="level-item">
        {children}
      </div>
    </div>
  </nav>);
  }
}