import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import Page, { PageTitle } from '../../../components/Page';
import { CogsIcon } from '../../../components/icons';
import { get, post, put } from '../../../utils';
import Columns, { Column } from '../../../components/Columns';
import IsLoading from '../../../components/IsLoading';
import SiteDetails from './SiteDetails';
import AddSite from './AddSite';
import SkuDataModal from './sharegondolas/SkuDataModal';
import SkuTagsModal from './shelf/SkuTagsModal';
import TelegramUsersModal from './telegram/TelegramUsersModal';


const AdmVarejos = () => {
  const [ inputGroup, setInputGroup ] = useState("");
  const [ groups, setGroups ] = useState([]);
  const [ sites, setSites ] = useState([]);
  const [ siteEdit, setSiteEdit ] = useState();
  const [ selectedGroup, setSelectedGroup ] = useState({});
  const [ modalEditSite, setModalEditSite ] = useState(false);
  const [ modalAddSite, setModalAddSite ] = useState(false);
  const [ editGroupName, setEditGroupName ] = useState("");
  const [ buttonloading, setButtonLoading] = useState(false);
  const [ isLoadingSites, setIsLoadingSites ] = useState(false);
  const [ modalSkuData, setModalSkuData ] = useState(false);
  const [ modalTelegram, setModalTelegram ] = useState(false);
  const [ modalTagsData, setModalTagsData ] = useState(false);

  const loadGroups = () => {
    get('admin/tooq/groups/')
      .then(response => setGroups(response.data.data));
  };

  useEffect(loadGroups, []);

  const loadSites = () => {
    if (!selectedGroup.id)
      return;

    setIsLoadingSites(true);
    get(`admin/tooq/groups/${selectedGroup.id}`)
      .then(response => setSites(response.data.data.sites))
      .then(() => setIsLoadingSites(false));
  };

  useEffect(loadSites, [selectedGroup.id]);

  const onChangeInputGroup = (event) => {
    setInputGroup(event.target.value);
  };

  const onClickAddGroup = (event) => {
    event.preventDefault();

    setButtonLoading(true);

    post(`admin/tooq/groups/`, {
      name: inputGroup
    })
    .then(response => setGroups([...groups, response.data.data]))
    .then(setButtonLoading(false));

    setInputGroup("");
  };

  const editNameGroup = (event) => {
    event.preventDefault();
    if (editGroupName.trim() === "")
      return;

    put(`admin/tooq/groups/${selectedGroup.id}`, {
      name: editGroupName
    })
    .then(response => setGroups([...groups, response.data.data]))
    .then(loadGroups)
    .then(() => setSelectedGroup({...selectedGroup, name: editGroupName}));
  };

  const selectGroup = (group) => {
    if (group.id === selectGroup.id)
      return;

    setSites([]);
    setSelectedGroup(group);
    setEditGroupName(group.name);
    window.scroll({
      top: 100,
      behavior: 'smooth'
    });
  };

  const modalAddClickedSite = (group) => {

    if (group) {
      setModalAddSite(true)
    } else {
      setModalAddSite(false)
    }
  };

  const modalEditSiteClicked = (site) => {
    setSiteEdit(site)
    if (site) {
      setModalEditSite(true)
    } else {
      setModalEditSite(null)
    }
  };

  const groupMapped = groups.map(group => <Column key={`key_${group.id}`} isSize={12}>
    <div className={`card notification cursor-pointer ${group === selectedGroup ? 'is-primary is-light' : ''}`}>
      <Columns>
        <Column onClick={() => selectGroup(group)}>
          <p className="title is-5">{group.name}</p>
          <p className="subtitle is-5">#{group.id}</p>
        </Column>
      </Columns>
    </div>
  </Column>)

  const sitesMapped = sites.map(s => <tr key={`sites-${s.id}`}>
    <td>{s.id}</td>
    <td>{s.name}</td>
    <td>{s.nickName}</td>
    <td>
      <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
        <div className={`${s.modules.foottraffic === true ? 'is-circle' : 'is-circle2'}`} />
      </div>
    </td>
    <td>
      <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }} >
        <div className={`${s.modules.johann === true ? 'is-circle' : 'is-circle2'}`} />
      </div>
    </td>
    <td>
      <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
        <div className={`${s.modules.loyalty === true ? 'is-circle' : 'is-circle2'}`} />
      </div>
    </td>
    <td>
      <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
        <div className={`${s.modules.queues === true ? 'is-circle' : 'is-circle2'}`} />
      </div>
    </td>
    <td>
      <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
        <div className={`${s.modules.sales === true ? 'is-circle' : 'is-circle2'}`} />
      </div>
    </td>
    <td>
      <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
        <div className={`${s.modules.shelf === true ? 'is-circle' : 'is-circle2'}`} />
      </div>
    </td>
    <td>
      <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
        <div className={`${s.modules.showcases === true ? 'is-circle' : 'is-circle2'}`} />
      </div>
    </td>
    <td>
      <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
        <div className={`${s.modules.venues === true ? 'is-circle' : 'is-circle2'}`} />
      </div>
    </td>
    <td>
      <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
        <div className={`${s.modules.video === true ? 'is-circle' : 'is-circle2'}`} />
      </div>
    </td>
    <td>
      <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
        <div className={`${s.modules.isSuvinil === true ? 'is-circle' : 'is-circle2'}`} />
      </div>
    </td>
    <td>
      <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
        <div className={`${s.modules.isAmbev === true ? 'is-circle' : 'is-circle2'}`} />
      </div>
    </td>
    <td>
      <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
        <button onClick={() => modalEditSiteClicked(s)} className="button is-primary">Editar</button>
      </div>
    </td>
  </tr>)

  return <Page>
    {modalSkuData && <SkuDataModal closeModal={() => setModalSkuData(false)} group={selectedGroup} />}
    {modalTagsData && <SkuTagsModal closeModal={() => setModalTagsData(false)} group={selectedGroup} />}
    {modalTelegram && <TelegramUsersModal closeModal={() => setModalTelegram(false)} group={selectedGroup} />}
    {modalAddSite && <div className="modal is-active">
      <div className="modal-background">
        <div className="modal-content" style={{marginTop: "5%"}}>
          <AddSite 
            loadSites={loadSites}
            setButtonLoading={setButtonLoading} 
            modalAddClickedSite={modalAddClickedSite} 
            selectedGroup={selectedGroup} />
        </div>
        <button className="modal-close is-large" onClick={() => modalAddClickedSite(false)} aria-label="close"></button>
      </div>
    </div>}
    {modalEditSite && <div className="modal is-active">
      <div className="modal-background">
        <div className="modal-content" style={{marginTop: "5%"}}>
          <SiteDetails  
            setModalEditSite={setModalEditSite} 
            site={siteEdit} 
            loadSites={loadSites}
          />
        </div>
        <button className="modal-close is-large" onClick={() => setModalEditSite(false)} aria-label="close"></button>
      </div>
    </div>}
    <PageTitle title={(<div>
      <CogsIcon />
      <span style={{ marginLeft: '15px' }}>Gerenciamento Varejos</span>
    </div>)} />
    <Columns>
      <Column isSize={3}>
        <p className="subtitle is-3 has-text-centered">Clientes</p>
        <Columns isMultiline>
          <Column isSize={12}>
            <form>
              <div className="field has-addons">
                <div className="control is-expanded">
                  <input
                    className="input"
                    onChange={onChangeInputGroup}
                    value={inputGroup}
                    placeholder="Nome do Grupo"
                    type="text"
                  />
                </div>
                <div className="control">
                  {inputGroup.trim() === ""
                    ? <button 
                      className="button is-static">
                      Adicionar
                    </button>
                    : <button 
                      onClick={onClickAddGroup} 
                      className={`button is-primary ${buttonloading ? 'is-loading' : ''}`}
                    >
                      Adicionar
                    </button>}
                </div>
              </div>
            </form>
          </Column>
          {groupMapped}
        </Columns>
      </Column>
      <Column isSize={9}>
        <p className="subtitle is-3 has-text-centered">Lojas</p>
        {!selectedGroup.name && <Column>
            <div className="card">
              <div className="card-content">
                <div className="subtitle is-3 has-text-centered">
                  Selecione um cliente ao lado
                </div>
              </div>
            </div>
          </Column>}
        
        {selectedGroup.name && <div className="card notification">
          <Columns style={{justifyContent: "space-between", display: "flex"}}>
            <Column isSize={6}>
              <div className="field has-addons">
                <div className="control is-expanded">
                  <input 
                    className="input" 
                    type="text" 
                    value={editGroupName}
                    onChange={(e) => setEditGroupName(e.target.value)} 
                  />
                </div>
                <div className="control">
                  {
                    editGroupName.trim() !== selectedGroup.name
                    ? 
                    <button className='button is-primary is-outlined' onClick={editNameGroup}>Salvar</button>
                    : 
                    <button className='button is-static'>Salvar</button>
                  }
                </div>                
              </div>
            </Column>
            <Column isSize={"is-narrow"}>
              <buttons className="buttons">
                <button 
                  className="button is-default "
                  onClick={() => setModalTagsData(true)} 
                >
                  Tags
                </button>
                <button 
                  className="button is-default "
                  onClick={() => setModalTelegram(true)} 
                >
                  Telegram
                </button>
                <button 
                  className="button is-default "
                  onClick={() => setModalSkuData(true)} 
                >
                  Cadastro de SKUs
                </button>
                <button 
                  className="button is-primary "
                  onClick={() => modalAddClickedSite(selectedGroup)} 
                >
                  Adicionar Loja
                </button>
              </buttons>       
            </Column>
          </Columns>
        </div> }

        {isLoadingSites && <div className="card notification" style={{display: "flex", justifyContent: 'center'}}>
          <IsLoading isBorderless={true} fontSize={60} />
        </div>}

        {!isLoadingSites && sites.length !== 0 && <div className="card notification" style={{overflowX: "auto"}}>
          <table className="table is-fullwidth">
            <thead>
              <tr>
                <th>ID</th>
                <th>Nome</th>
                <th>NickName</th>
                <th className="has-text-centered">foottraffic</th>
                <th className="has-text-centered">johann</th>
                <th className="has-text-centered">loyalty</th>
                <th className="has-text-centered">queues</th>
                <th className="has-text-centered">sales</th>
                <th className="has-text-centered">shelf</th>
                <th className="has-text-centered">showcases</th>
                <th className="has-text-centered">venues</th>
                <th className="has-text-centered">Video</th>
                <th className="has-text-centered">isSuvinil</th>
                <th className="has-text-centered">isAmbev</th>
                <th className="has-text-centered">Ações</th>
              </tr>
            </thead>
            <tbody>
              {sitesMapped}
            </tbody>
          </table>
          {modalEditSite}
        </div>}
      </Column>
    </Columns>
  </Page>
};

export default withRouter(AdmVarejos);