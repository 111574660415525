import React, { useEffect, useState } from 'react';

import Columns, { Column } from '../../../../components/Columns';
import IsLoading from '../../../../components/IsLoading';
import { useGondolas } from '../../../../hooks/venues';
import { useCrud } from '../../../../hooks/useCrud';
import { useField, useFieldCheckBox } from '../../../../hooks/useField';
import { get, del, postImage, post } from '../../../../utils';
import notification from '../../../../utils/notification';
import { TrashIcon } from '../../../../components/icons';
import Gondola from './Gondola';


const useVenuesShelfs = (siteId) => {
  const [shelves, setShelves] = useState([])
  const [shelvesIsLoading, setShelvesIsLoading] = useState(true);

  useEffect(() => {
    setShelvesIsLoading(true);
    get(`admin/videos/sites/${siteId}/venues`)
      .then((resp) => resp.data.data)
      .then((venues) => {
        setShelvesIsLoading(false);
        setShelves(venues.filter(venue => venue.isShelf));
      })
  }, [siteId])

  return [shelves, shelvesIsLoading]
}

const ShelfSettings = (props) => {
  const { site } = props;
  const [gondolas, isLoading, doReload, removeGondola] = useGondolas(site.id);
  const [shelf, setShelf] = useState({});
  const [modalIsActive, setModalIsActive] = useState(false);
  const [modalIaTraining, setmodalIaTraining] = useState(false);
  const [inputDescription, setInputDescription] = useState("");
  const [inputFile, setInputFile] = useState(null);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [confirm, setConfirm] = useState({});
  const [shelves, shelvesIsLoading] = useVenuesShelfs(site.id);
  const [selectedSku, setSelectedSku] = useState(null);

  const selectedTag = useField("", "", (val) => parseInt(val))
  const selectedTagIsPrincipal = useFieldCheckBox(false)

  const venueSkus = useCrud();
  const gondolaVenueShelfCategory = useCrud();
  const shelfCategories = useCrud();

  const onSelectedSku = (e) => {
    const { value } = e.target;
    setSelectedSku(parseInt(value));
  }

  const removeGondolaVenueShelfCategory = (gondolaVenueShelfCategoryId) => () => {
    gondolaVenueShelfCategory.remove(
      gondolaVenueShelfCategoryId,
      () => del(`admin/videos/sites/${site.id}/gondolas/${shelf.gondola.id}/gondola-venue/${shelf.gondolaVenueId}/tag/${gondolaVenueShelfCategoryId}`)
    )
  }

  const removeVenueSku = venueSkuId => () => {
    venueSkus.remove(
      venueSkuId,
      () => del(`admin/videos/sites/${site.id}/gondolas/${shelf.gondola.id}/venues/${shelf.venueId}/sku/${venueSkuId}`))
      .catch(err => notification.error("Não foi possivel deletar o registro."));
  }

  const saveTagToVenue = () => {
    if (selectedTag.value === 0 || !shelf.gondolaVenueId)
      return;

    gondolaVenueShelfCategory.add(
      () => post(`admin/videos/sites/${site.id}/gondolas/${shelf.gondola.id}/gondola-venue/${shelf.gondolaVenueId}/tag`, {
        tagId: selectedTag.value,
        isHeader: selectedTagIsPrincipal.checked ? 1 : 0
      }),
      (val) => {
        const tagDescription = shelfCategories.data.find(r => r.id === val.shelf_category_id);
        return {
          ...val,
          gondolaVenueId: shelf.gondolaVenueId,
          tagDescription: tagDescription.description,
          tagColor: tagDescription.color_hash
        }
      }
    )
  }

  const saveSkuToVenue = () => {
    if (!shelf.venueId || !selectedSku)
      return;

    venueSkus.add(
      () => post(`admin/videos/sites/${site.id}/gondolas/${shelf.gondola.id}/venues/${shelf.venueId}/sku`, {
        skuId: selectedSku
      }),
      (data) => {
        const sku = skus.find(sku => selectedSku === sku.id);
        const s = shelves.find(s => shelf.venueId === s.id);
        return {
          id: data.id,
          venueId: shelf.venueId,
          venueDescription: s.description,
          skuDataId: selectedSku,
          skuDataDescription: sku.description
        }
      }
    )
      .then((resp) => {
        setSelectedSku(0);
      });
  }

  const [skus, setSkus] = useState([]);

  useEffect(() => {
    setSkus([])
    get(`admin/share-gondola/sites/${site.id}/group-skudata?showActives=1`)
      .then(resp => {
        setSkus(resp.data.data);
      })

    shelfCategories.list(() => get(`admin/videos/sites/${site.id}/shelf-category`))
    // eslint-disable-next-line
  }, [site.id]);

  const selectShelf = ({ gondolaVenueId, venueId, gondola }) => {
    setShelf({
      gondolaVenueId,
      venueId,
      gondola
    });

    if (!venueId || !gondola)
      return;

    venueSkus.list(() => get(`admin/videos/sites/${site.id}/gondolas/${gondola.id}/venues/${venueId}/sku`))
      .catch(err => notification.error('Problema ao carregar registros de SKU da area'));

    gondolaVenueShelfCategory.list(
      () => get(`admin/videos/sites/${site.id}/gondolas/${gondola.id}/gondola-venue/${gondolaVenueId}/tag`),
      (row) => {
        const shelfCategory = shelfCategories.data.find(r => r.id === row.tagId);
        return {
          ...row,
          tagDescription: shelfCategory.description,
          tagColor: shelfCategory.color_hash
        }
      }
    )
      .catch(err => notification.error('Problema ao carregar registros de Tags do desenho'));
  };

  const postPicture = (file, description) => {
    setIsLoadingButton(true);

    const data = new FormData();
    data.append('file', file);
    data.append('description', description);

    postImage(`admin/videos/sites/${site.id}/gondolas/uploadpicture`, data)
      .then(() => {
        setInputDescription("")
        setInputFile(null)
        setIsLoadingButton(false);
        setModalIsActive(false);
        setmodalIaTraining(false);
        setConfirm(false);
        notification.success("Cadastrado com sucesso.")
      })
      .then(doReload)
      .catch(err => {
        notification.error("Problema ao cadastrar imagem da gondola.")
      })

  };

  const deletePicture = (pictureId) => {

    if (isLoadingButton) {
      return;
    }

    setIsLoadingButton(true)

    del(`admin/videos/sites/${site.id}/gondolas/picture/${pictureId}`)
      .then(() => setIsLoadingButton(false))
      .then(() => removeGondola(pictureId))
      .then(() => {
        notification.success("Deletado com sucesso.")
      })
      .catch(err => {
        notification.error("Problema ao deletar imagem da gondola.")
      })
  };

  return <div>
    {modalIsActive && <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-content">
        <div className="card notification" >
          <div className="card-content" >
            <h2 className="subtitle is-2" style={{ marginBottom: 20, textAlign: 'center' }}>Planograma</h2>
            <label className="label">Descrição:</label>
            <input className="input" type="text" onChange={(e) => setInputDescription(e.target.value)} style={{ marginBottom: 10 }} />
            <label className="label">Enviar Foto:</label>
            <div className="file has-name">
              <label className="file-label">
                <input className="file-input" type="file" name="file" onChange={(e) => setInputFile(e.target.files[0])} />
                <span className="file-cta">
                  <span className="file-icon">
                    <i className="fas fa-upload"></i>
                  </span>
                  <span className="file-label">
                    Escolha um arquivo
                  </span>
                </span>
                <span className="file-name">
                  {inputFile ? inputFile.name : "..."}
                </span>
              </label>
            </div>
            <div className="buttons" style={{ marginTop: 25 }}>
              {!inputFile || !inputDescription ?
                <button className="button is-static">Salvar</button>
                :
                <button className={`button is-primary ${isLoadingButton ? 'is-loading' : ''}`} onClick={() => postPicture(inputFile, inputDescription)} >Salvar</button>
              }
              <button className="button is-default" onClick={() => setModalIsActive(false)} >Cancelar</button>
            </div>
          </div>
        </div>
      </div>
      <button className="modal-close is-large" aria-label="close" onClick={() => setModalIsActive(false)}></button>
    </div>}
    {modalIaTraining && <div className="modal is-active">
      <div className='modal-background'></div>
      <div className='modal-card'>
        <header className='modal-card-head'>
          <p className='modal-card-title'>Modelo de treinamento</p>
        </header>
        <section className='modal-card-body'>
          <div className='field'>
            <label className="label">Descrição</label>
            <input className="input" type="text" />
          </div>
          <div className='field'>
            <label className="label">Bucket do dataset</label>
            <input className="input" type="text" />
          </div>
          <div className='field is-horizontal'>
            <div className='field-body'>
              <div className='field'>
                <label className="label">Batchsize</label>
                <input className="input" type="number" />
              </div>
              <div className='field'>
                <label className="label">Epochs</label>
                <input className="input" type="number" />
              </div>
            </div>
          </div>
        </section>
        <footer className='modal-card-foot'>
          <button type='submit' className="button is-primary">Adicionar</button>
          <button className='button is-default' onClick={() => setmodalIaTraining(false)}>Cancelar</button>
        </footer>
      </div>
      <button className="modal-close is-large" aria-label="close" onClick={() => setmodalIaTraining(false)}></button>
    </div>}
    <div className='columns is-centered'>
      <div className='column is-narrow'>
        <button className="button is-primary" onClick={() => setModalIsActive(true)}>
          Enviar Planograma
        </button>
      </div>
      <div className='column is-narrow'>
        <button className="button is-primary" onClick={() => setmodalIaTraining(true)}>
          Treinamentos de IA
        </button>
      </div>
    </div>
    <Columns isMultiline>
      {isLoading && <Column isSize={12} className="chart_justifycenter">
        <IsLoading fontSize={60} isBorderless={true} />
      </Column>}
      {gondolas.map(gondola => <Column key={`gondola_image_${gondola.id}`} isSize={12}>
        <Columns>
          <Column isSize={8} style={{ overflow: 'auto' }}>
            <p className="subtitle is-3" style={{ marginTop: 10, marginBottom: 5 }}>{gondola.name}</p>
            <Gondola
              site={site}
              gondola={gondola}
              onSelectShelf={selectShelf}
              venuesShelves={shelves}
              venuesShelvesIsLoading={shelvesIsLoading}
            />
            <div>
              {!confirm[gondola.id] ?
                <button className="button is-outlined is-danger" onClick={() => setConfirm({ ...confirm, [gondola.id]: true })}>Excluir</button>
                :
                <div className="buttons">
                  <button
                    className="button is-default"
                    onClick={() => setConfirm({ ...confirm, [gondola.id]: false })}>
                    Cancelar
                  </button>
                  <button
                    className={isLoadingButton ? 'button is-danger is-loading' : 'button is-danger'}
                    onClick={() => deletePicture(gondola.id)}>
                    Confirmar Exclusão
                  </button>
                </div>}
            </div>
          </Column>
          {shelf.gondola && shelf.venueId && shelf.gondola.id === gondola.id && <Column isSize={4}>
            <Columns isMultiline>
              <Column isSize={12}>
                <p className="subtitle is-4">Adicionar SKU na prateleira</p>
                <div className="field is-grouped">
                  <div className="control is-expanded">
                    <div className="select is-fullwidth">
                      <select value={selectedSku} onChange={onSelectedSku}>
                        <option value={0}>Selecione um SKU</option>
                        {skus.map(sku => <option value={sku.id}>
                          {sku.description}
                        </option>)}
                      </select>
                    </div>
                  </div>
                  <div className="control">
                    <div className={`button is-primary ${venueSkus.isAddingRow ? 'is-loading' : ''}`} onClick={saveSkuToVenue}>
                      Adicionar
                    </div>
                  </div>
                </div>
                <table className='table is-fullwidth'>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>VENUE</th>
                      <th>SKU</th>
                      <th>AÇÃO</th>
                    </tr>
                  </thead>
                  <tbody>
                    {venueSkus.isLoadingList && <tr>
                      <td></td>
                      <td>Aguarde...</td>
                      <td></td>
                      <td></td>
                    </tr>}
                    {venueSkus.data.map(venueSku => <tr>
                      <td>{venueSku.id}</td>
                      <td>{venueSku.venueId} {venueSku.venueDescription}</td>
                      <td>{venueSku.skuDataDescription}</td>
                      <td>
                        <span className={`button is-danger ${venueSkus.isRemovingRow[venueSku.id] ? 'is-loading' : ''}`} onClick={removeVenueSku(venueSku.id)}>
                          <span className="icon">
                            <TrashIcon />
                          </span>
                        </span>
                      </td>
                    </tr>)}
                  </tbody>
                </table>
              </Column>
              <Column isSize={12}>
                <p className="subtitle is-4">Adicionar Tags</p>
                <div className="field is-grouped">
                  <div className="control is-expanded">
                    <div className="select is-fullwidth">
                      <select
                        {...selectedTag}
                      >
                        {!shelfCategories.isLoadingList && <option value={0}>Selecione um SKU</option>}
                        {shelfCategories.isLoadingList && <option value={0}>Aguarde...</option>}
                        {shelfCategories.data.map(tag => <option value={tag.id} style={{ color: tag.color }}>
                          {tag.description}
                        </option>)}
                      </select>
                    </div>
                  </div>
                  <div className="control">
                    <label className="checkbox">
                      <input type="checkbox" {...selectedTagIsPrincipal} />
                      Usar como cabeçalho
                    </label>
                  </div>
                  <div className="control">
                    <div className={`button is-primary ${gondolaVenueShelfCategory.isAddingRow ? 'is-loading' : ''}`} onClick={saveTagToVenue}>
                      Adicionar
                    </div>
                  </div>
                </div>
                <table className='table is-fullwidth'>
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>Id Gondola Venue</th>
                      <th>Tag</th>
                      <th>Cabeçalho</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {gondolaVenueShelfCategory.isLoadingList && <tr>
                      <td></td>
                      <td>Aguarde...</td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>}
                    {gondolaVenueShelfCategory.data.map(row => <tr>
                      <td>{row.id}</td>
                      <td>{row.gondolaVenueId}</td>
                      <td style={{ backgroundColor: row.tagColor }}>
                        {row.tagDescription}
                      </td>
                      <td>{row.isHeader === 0 ? 'Não' : 'Sim'}</td>
                      <td>
                        <span
                          className={`button is-danger ${gondolaVenueShelfCategory.isRemovingRow[row.id] ? 'is-loading' : ''}`}
                          onClick={removeGondolaVenueShelfCategory(row.id)}>
                          <TrashIcon />
                        </span>
                      </td>
                    </tr>)}
                  </tbody>
                </table>
              </Column>
            </Columns>
          </Column>}
        </Columns>
      </Column>)}
    </Columns>
  </div>
}

export default ShelfSettings;