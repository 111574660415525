import React, { Component } from 'react';

import { withRouter } from "react-router-dom";

import PerformanceTable from './PerformanceTable';

class PerformanceSites extends Component {

  constructor(props) {
    super(props);

    this.state = {
      including: false,
      descriptionField: ''
    };
  }

  _handleCancelButton = () => {
    this.props.toogleModal();
  }
  
  render() {
    const { indices, showLoyalty, showPassersBy } = this.props;

    return (<div className="modal is-active">
      <div className="modal-background" onClick={this._handleCancelButton}></div>
      <div
        className="modal-content is-paddingless notification is-white"
        style={{ width: '95%', fontSize: '0.77em' }}
      >
        <PerformanceTable
          isDrag={false}
          table={indices['indice1']}
          tableNumber={indices['indice1'].info_date}
          showLoyalty={showLoyalty}
          showPassersBy={showPassersBy}
        />
        <PerformanceTable
          isDrag={false}
          table={indices['indice2']}
          tableNumber={indices['indice2'].info_date}
          showLoyalty={showLoyalty}
          showPassersBy={showPassersBy}
        />
        <PerformanceTable
          isDrag={false}
          isCompareTable={true}
          table={indices['variance']}
          tableNumber={indices['variance'].info_date}
          showLoyalty={showLoyalty}
          showPassersBy={showPassersBy}
        />
      </div>
      <button className="modal-close is-large" onClick={this._handleCancelButton} aria-label="close"></button>
    </div>)
  }
}

export default withRouter(PerformanceSites);