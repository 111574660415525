
import React, { Component } from 'react';
import Columns, { Column } from '../../../../../components/Columns';
import Select from 'react-select';
import { post, put, del } from '../../../../../utils';
import notification from '../../../../../utils/notification';

class JohannAreaForm extends Component {
  constructor(props) {
    super(props); 

    const {
      id = 0,
      description='',
      type="TRUCK_SHED",
      principal = false,
      area_principal_id = 0
    } = props.area;
    const {venues =[], areas =[]} = this.props

    this.state = {
      forms: {
        id,
        description,
        type,
        principal,
        area_principal_id
      },
      sensors: venues.filter(v => v.area_id === 0 || !v.area_id || v.area_id === id),
      areas: areas.filter(v => v.principal_id === 0 || v.principal_id === id ),
      sensorsSelected:  venues.filter(v => id > 0 && v.area_id === id),
      areasSelected: areas.filter(v => id > 0 && v.principal_id === id),
      isSaving: false
    } 
  }
  _onChangeChecked = (e) => {
    const { forms } = this.state;
    const { checked, name } = e.target;
    forms[name] = checked;
    this.setState({ forms });
  }
  _onChangeValue = (e) => {
    const { forms } = this.state;
    const { value, name } = e.target;


    const parsedValue = parseFloat(value);
    if(parsedValue.isNaN)
      forms[name] = parsedValue;
    else
      forms[name] = value;

    this.setState({ forms });
  }
  _onChangeSelect = (value, {action, name, removedValue}) => {
    if(value && action !== 'clear'){
      if(name ==="sensor"){
        this.setState({ sensorsSelected: value });
      }else{
        this.setState({ areasSelected: value });
      }
    }
    else {
      if(name ==="sensor"){
        this.setState({ sensorsSelected: [] });
      }else{
        this.setState({ areasSelected: [] });
      }
    }
  } 
  _onDelete = (e) => {
    e.preventDefault();
    const { forms } = this.state;
    this.setState({ isSaving: true }, () => {
      const { siteId } = this.props;
      const {id} = forms;
      del(`admin/johann/sites/${siteId}/areas/${id}`).then(resp => {
        notification.success('Dados deletados com sucesso!')
      }).catch(err => {
        notification.error(err.message)
      }).finally( () =>{
        this.setState({ isSaving: false }, () => this.props.toggle());
      });
    })
  }
  _onSave = (e) => {
    e.preventDefault();

    const { isSaving, forms, sensorsSelected, areasSelected } = this.state;
    if(isSaving)
      return;

    this.setState({ isSaving: true }, () => {
      const { siteId } = this.props;
      const {
        id,
        description,
        type,
        principal,
       } = forms;
      
      const body = { description, type, principal, venues: sensorsSelected.map(s => s.value), areas: areasSelected.map(s => s.value) };
      const addUpdate = id ? put(`admin/johann/sites/${siteId}/areas/${id}`, body) : post(`admin/johann/sites/${siteId}/areas`, body);
      addUpdate.then(resp => {
        notification.success('Dados salvos com sucesso!')
      }).catch(err => {
        notification.error(err.message)
      }).finally( () =>{
        this.setState({ isSaving: false }, () => this.props.toggle());
      });
    });
  }


  render(){
    const {area} = this.props;
    const {forms,  isSaving, sensors, sensorsSelected, areas, areasSelected} = this.state;    
    const styles = {
      multiValue: (base, state) => {
        return state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base;
      },
      multiValueLabel: (base, state) => {
        return state.data.isFixed
          ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
          : base;
      },
      multiValueRemove: (base, state) => {
        return state.data.isFixed ? { ...base, display: 'none' } : base;
      },
    };
    const canShowSensorSelect = (forms.type === "TRUCK_FLEET" || !forms.principal)
    const canShowAreaSelect = forms.type === "TRUCK_SHED"

    let areasList = areas.filter(a => a.value !== area.id )
    if(area.id === undefined){
      areasList = areas
    }
    return (
      <div className="card">
        <header className="card-header">
          <p className="card-header-title">
            { area && area.id > 0  ? `${area.description}`: "Novo Sensor"}
          </p>
        </header>
        <div className="card-content">
          <Columns isMultiline >
            <Column isSize={4}>
              <div className="field is-small">
                <div className="control has-icons-right is-expanded">
                  <label className="label">Descrição</label>
                  <input name="description" onChange={this._onChangeValue} className="input" value={forms.description || ''} type="text" />
                  <span className="help">Descrição da Area</span>
                </div>
              </div>
            </Column>
            <Column isSize={2}>
              <div className="field is-small">
                <div className="control is-expanded" >
                  <label className="label">Tipo de area</label>
                  <label className="radio">
                    <input 
                      type="radio" 
                      name="type" 
                      value="TRUCK_SHED" 
                      checked={forms.type === "TRUCK_SHED"} 
                      style={{marginRight: 5}} 
                      onChange={this._onChangeValue} />
                    Galpão
                  </label>
                  <label className="radio" style={{margin: 0}}>
                    <input 
                      type="radio" 
                      name="type" 
                      value="TRUCK_FLEET" 
                      checked={forms.type === "TRUCK_FLEET"} 
                      style={{marginRight: 5}} 
                      onChange={this._onChangeValue} />
                    Frota De caminhões
                  </label>
                </div>
              </div>
            </Column>
            {canShowAreaSelect && <Column isSize={2}>
              <div className="field is-small">
                <div className="control is-expanded">
                  <label className="label">É uma área principal</label>
                  <input name="principal" defaultChecked={forms.principal} onChange={this._onChangeChecked}  type="checkbox" value={forms.principal}/>
                </div>
              </div>
            </Column>}
            {canShowAreaSelect && forms.principal && <Column isSize={4}>
              <div className="field is-small">
                <div className="control has-icons-right is-expanded">
                  <label className="label">Selecionar Áreas</label>
                  <Select  
                    value={areasSelected}
                    options={areasList}
                    closeMenuOnSelect={false}
                    onChange={this._onChangeSelect} 
                    styles={styles}
                    isMulti 
                    name="area"
                    placeholder="Áreas"
                    className="basic-multi-select"
                    classNamePrefix="select"/>
                  <span className="help">Selecione as áreas</span>
                </div>
              </div>
            </Column>}
            {canShowSensorSelect && <Column isSize={4}>
              <div className="field is-small">
                <div className="control has-icons-right is-expanded">
                  <label className="label">Selecionar Sensores</label>
                  <Select  
                    value={sensorsSelected}
                    options={sensors}
                    closeMenuOnSelect={false}
                    onChange={this._onChangeSelect} 
                    styles={styles}
                    isMulti 
                    name="sensor"
                    placeholder="Sensores"
                    className="basic-multi-select"
                    classNamePrefix="select"/>
                  <span className="help">Selecione os sensores</span>
                </div>
              </div>
            </Column>}
          </Columns>
        </div>
        <footer className="card-footer">
          <p className="card-footer-item" style={{justifyContent: "flex-start"}}>

            <Columns style={{ width: '100%' }} >
              <Column isSize={6}>
                {area.id > 0 && <button className={`button is-danger ${isSaving ? 'is-loading': ''}`} onClick={this._onDelete}>Excluir</button>}
              </Column>
              <Column isSize={6}>
                <div className="buttons is-right">
                  <button className={`button is-primary ${isSaving ? 'is-loading': ''}`} onClick={this._onSave}>Salvar</button>
                </div>
              </Column>
            </Columns>
          </p>
        </footer>
      </div>
    )
  }
}
export default JohannAreaForm;