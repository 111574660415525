import React, { useEffect } from 'react';
import { get, post } from '../../../../utils';
import { format } from 'date-fns';
import Select from 'react-select';
import notification from '../../../../utils/notification';
import { useField, useMultipleReactSelect } from '../../../../hooks/useField';
import { useCrud } from '../../../../hooks/useCrud';
import Columns, { Column } from '../../../../components/Columns';


const modelTypeGenericOptions = [
  { value: 1, label: "Genérico" },
  { value: 2, label: "Loja" },
  { value: 3, label: "Câmera" }
];

const modelTypeProductOptions = [
  { value: 1, label: "Classificador Lata ou ruptura" },
  { value: 2, label: "Classificador SKU" }
];


const AddTrainingModal = (props) => {
  const { siteId, closeModal, handleAdd } = props;
  const models = useCrud();
  const transforms = useCrud();
  const datasets = useCrud();
  const cameras = useCrud();
  const training = useCrud();

  const descriptionField = useField(`Treinamento ${format(new Date(), 'YYYY-MM-DD HH:mm:ss')}`, "", (val) => parseInt(val));
  const batchSizeField = useField(50, "", (val) => parseInt(val));
  const epochField = useField(20, "", (val) => parseInt(val));
  const modelTypeGenericField = useField(0, "", (val) => parseInt(val));
  const modelTypeProductField = useField(0, "", (val) => parseInt(val));

  const transformsField = useMultipleReactSelect(transforms.data.map(transf => ({ value: transf.id, label: transf.compose })));
  const datasetsField = useMultipleReactSelect(datasets.data.map(dataset => ({ value: dataset.id, label: dataset.description })));
  const modelsField = useField(0, "", val => parseInt(val));

  const camerasOptions = cameras.data.map(dataset => ({ value: dataset.id, label: dataset.description }));
  const camerasField = useMultipleReactSelect(camerasOptions);


  useEffect(() => {
    models.list(
      () => get(`mlops/ia-model`)
    )
    .then(data => modelsField.setValue(parseInt(data[0].id)));

    datasets.list(
      () => get(`mlops/ia-dataset?siteId=${siteId}`)
    )

    transforms.list(
      () => get(`mlops/ia-transforms`)
    )

    cameras.list(
      () => get(`admin/videos/sites/${siteId}/cameras`)
    )

    //eslint-disable-next-line
  }, [siteId]);

  const submitTraining = (e) => {
    e.preventDefault();

    console.log(siteId,
      descriptionField.value,
      batchSizeField.value,
      epochField.value,
      modelsField.value,
      modelTypeGenericField.value,
      modelTypeProductField.value,
      
      transformsField.value,
      camerasField.value,
      datasetsField.value
    );
    training.add(
      () => post(`mlops/training`, {
        site_id: siteId,
        description: descriptionField.value,
        batchsize: batchSizeField.value,
        epoch: epochField.value,
        model_id: modelsField.value,
        model_type_generic: modelTypeGenericField.value,
        model_type_product: modelTypeProductField.value,
        transforms: transformsField.value,
        cameras: camerasField.value,
        datasets: datasetsField.value
      })
    )
    .then(data => {
      handleAdd();
      notification.success(`Registro inserido com successo ID = ${data.id}.`);
      closeModal();
    })
    .catch(err => {
      console.log(err);
      notification.error('Não foi possivel inserir o registro, tente novamente mais tarde.')
    });
  }

  return <div className="modal is-active">
    <div className='modal-background' onClick={closeModal}></div>
    <form onSubmit={submitTraining} className='modal-card' style={{ height: "90%"}}>
      <header className='modal-card-head'>
        <p className='modal-card-title'>Modelo de treinamento</p>
      </header>
      <section className='modal-card-body'>
        <Columns>
          <Column isSize={8}>
            <div className='field'>
              <label className="label">Descrição</label>
              <input className="input" type="text" {...descriptionField} required/>
            </div>
          </Column>
          <Column isSize={2}>
            <div className='field'>
              <label className="label">Batchsize</label>
              <input className="input" type="number" {...batchSizeField} required/>
            </div>
          </Column>
          <Column isSize={2}>
            <div className='field'>
              <label className="label">Epochs</label>
              <input className="input" type="number" {...epochField} required/>
            </div>
          </Column>
        </Columns>
        
        <Columns>
          <Column isSize={4}>
            <label className="label">Modelo</label>
            <div className='select is-fullwidth'>
              <select {...modelsField} required>
                {models.data.map(option => <option value={option.id}>{option.name}</option>)}
              </select>
            </div>
          </Column>
          <Column isSize={4}>
            <label className="label">Tipo Modelo</label>
            <div className='control is-expanded'>
              <div className='select is-fullwidth'>
                <select {...modelTypeGenericField} required>
                  <option value={0}>Selecione uma opção</option>
                  {modelTypeGenericOptions.map(option => <option value={option.value}>{option.label}</option>)}
                </select>
              </div>
            </div>
          </Column>
          <Column isSize={4}>
            <label className="label">Tipo Classificador</label>
            <div className='select is-fullwidth'>
              <select {...modelTypeProductField} required>
                <option value={0}>Selecione uma opção</option>
                {modelTypeProductOptions.map(option => <option value={option.value}>{option.label}</option>)}
              </select>
            </div>
          </Column>
        </Columns>
        <Columns>
          <Column isSize={4}>
            <label className="label">Transforms</label>
            <Select
              {...transformsField}
              isMulti
              closeMenuOnSelect={false}
              placeholder="Data Transformers"
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </Column>
          <Column isSize={4}>
            <label className="label">Datasets</label>
            <Select
              {...datasetsField}
              isMulti
              closeMenuOnSelect={false}
              placeholder="Data Transformers"
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </Column>
          {modelTypeGenericField.value === 3 /*cameras*/ && <Column isSize={4}>
            <div className='field'>
              <label className="label">Cameras</label>
              <Select
                {...camerasField}
                isMulti
                closeMenuOnSelect={false}
                placeholder="Cameras do modelo"
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </div>
          </Column>}
        </Columns>
      </section>
      <footer className='modal-card-foot'>
        <button type='submit' className={`button is-primary ${training.isAddingRow ? 'is-loading' : ''}`}>Adicionar</button>
        <button className='button' onClick={closeModal}>Cancelar</button>
      </footer>
    </form>

  </div>

};

export default AddTrainingModal;