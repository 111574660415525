import React, { useState } from 'react';
import { format } from 'date-fns';
import Columns, { Column } from '../../../../components/Columns';
import { postImage, post, getServerDate } from '../../../../utils';
import notification from '../../../../utils/notification';

const validateXml = (xml) => {
  const data = new FormData();
  data.append('file', xml);

  return postImage(`admin/ambev/manualxml/validate`, data)
    .then((resp) => {
      return {
        xmlName: xml.name,
        ...resp.data
      };
    });
}

const VALID_TYPES = [
  "FUTUREBEV_AMBEV",
  "FUTUREBEV_CONCORRENTE",
  "RUPTURA_FUTUREBEV_AMBEV",
  "RUPTURA_FUTUREBEV_CONCORRENTE",
]

const ShareGondola = (props) => {
  const { site } = props;

  const [snapDate, setSnapDate] = useState(format(getServerDate(1), "YYYY-MM-DD"));
  const [period, setPeriod] = useState("morning");

  const [xmls, setXmls] = useState([]);

  const [xmlsValidated, setXmlsValidated] = useState([]);
  const [validations, setValidations] = useState({});

  const [xmlIsSave, setXmlIsSave] = useState({});

  const saveXml = (xml) => {
    const { size, annotation } = xml;
    // Só pode salvar as tags que são validas.
    const payload = {
      siteId: site.id,
      snapDate: format(snapDate, "YYYY-MM-DD"),
      period: period,
      annotations: {
        size,
        types: [
          ...annotation.filter(type => VALID_TYPES.includes(type.type))
        ], 
        xmlName: xml.xmlName
      }
    }

    post(`admin/ambev/sites/${site.id}/manualxml`, payload)
      .then((resp) => {
        notification.success(`XML salvo ${xml.xmlName} com Sucesso`);
        setXmlIsSave({
          ...xmlIsSave, 
          [xml.xmlName]: true
        });
      });
  }

  const reconhecerBtn = () => {
    const xmlsPromises = Array.from(xmls).map(xml => validateXml(xml));

    Promise.all(xmlsPromises)
      .then(xmlsvalidated => {
        const xmlsNames = xmlsvalidated.reduce((acc, curr) => {
          acc[curr.xmlName] = false
          return acc
        }, {})
        setXmlIsSave(xmlsNames)
        const validations = xmlsvalidated.reduce((acc, curr) => {
          const validTypes = curr.annotation.filter(type => VALID_TYPES.includes(type.type));
          const invalidTypes = curr.annotation.filter(type => !VALID_TYPES.includes(type.type));
          const validsNotFoundInXml = VALID_TYPES.filter(VALID_TYPE => {
            return !curr.annotation.map(type => type.type).includes(VALID_TYPE)
          })

          acc[curr.xmlName] = {
            validTypes: validTypes.map(v => ({ ...v, isOk: false })),
            invalidTypes: invalidTypes.map(v => ({ ...v, isOk: false })),
            validsNotFoundInXml: validsNotFoundInXml.map(type => ({ type, isOk: false })),
          };

          return acc;
        }, {});

        setValidations(validations);
        setXmlsValidated(xmlsvalidated);
      })
  }

  const fileMapped = xmlsValidated.map(xml => {
    const { validTypes, invalidTypes, validsNotFoundInXml } = validations[xml.xmlName]

    const setValidationsXml = (key, index) => {
      setValidations({
        ...validations,
        [xml.xmlName]: {
          ...validations[xml.xmlName],
          [key]: validations[xml.xmlName][key].map((v, i) => {
            if (i === index) {
              return {
                ...v,
                isOk: true
              }
            }
            return v;
          })
        }
      })
    }

    const canSaveInvalidTypes = invalidTypes.filter(v => !v.isOk);
    const canSaveValidsNotFoundInXml = validsNotFoundInXml.filter(v => !v.isOk);
    const canSave = (canSaveInvalidTypes.length === 0 && canSaveValidsNotFoundInXml.length === 0);

    return (xmlIsSave[xml.xmlName] ? (
        <div className='card notification is-primary'>
          <p className="subtitle is-3">{xml.xmlName}</p>
          {`${xml.xmlName} Salvo`}
        </div>
      ) : (

        <div className='card notification'>
          <p className="subtitle is-3">{xml.xmlName}</p>
          <p className="subtitle is-5">Categorias válidas encontradas:</p>
          {validTypes.length === 0 && <p>Não há.</p>}
          {validTypes.map(valid => <div>
            <b>{valid.type}</b>: {valid.sharePercent}%
          </div>)}
          <br />

          {invalidTypes.length !== 0 && <p className="subtitle is-5">Categorias inválidas encontradas:</p>}
          {invalidTypes.map((invalid, i) => <div>
            <b>- {invalid.type}</b>
            {!invalid.isOk && <span className="button is-small is-danger is-outlined" onClick={() => setValidationsXml("invalidTypes", i)}>OK</span>}
          </div>)}
          {invalidTypes.length !== 0 && <br />}
          {validsNotFoundInXml.length !== 0 && <p className="subtitle is-5">Válidos não encontrados no arquivo:</p>}
          {validsNotFoundInXml.map((validNotFoundInXml, i) => <div>
            <b>- {validNotFoundInXml.type}</b>
            {!validNotFoundInXml.isOk && <span className="button is-small is-warning is-outlined" onClick={() => setValidationsXml("validsNotFoundInXml", i)}>OK</span>}
          </div>)}
          <br />
          {validTypes.length === 0
            ? <span>Seu xml não possuí classes validas não será possivel salvar.</span>
            : canSave
              ? <span className="button is-primary" onClick={() => saveXml(xml)}>Salvar</span>
              : <span className="button is-static">Clique em todos os "Oks"</span>} 
        </div>
      ))
  })

  return (<Columns isMultiline>
    <Column isSize={6}>
      <div style={{ maxWidth: '100%' }}>
        <input className="input is-fullwidth" type="date" value={snapDate} onChange={(e) => setSnapDate(e.target.value)} />
      </div>
    </Column>
    <Column isSize={6}>
      <div className="select is-fullwidth" >
        <select value={period} onChange={(e) => setPeriod(e.target.value)}>
          {!period ? <option>Selecione um Período</option> : ""}
          <option value="morning">Manhã</option>
          <option value="afternoon">Tarde</option>
        </select>
      </div>
    </Column>
    <Column isSize={6}>
      <div className="file has-name is-fullwidth" >
        <label className="file-label">
          <input multiple="multiple" className="file-input" type="file" onChange={e => setXmls(e.target.files)} />
          <span className="file-cta">
            <span className="file-icon">
              <i className="fas fa-upload"></i>
            </span>
            <span className="file-label" >
              Escolha o(s) Arquivo(s)
            </span>
          </span>
          <span className="file-name" >
            {Array.from(xmls).map(xml => xml.name).join(',')}
          </span>
        </label>
      </div>
    </Column>
    <Column isSize={6}>
      <div className='buttons is-centered'>
        <input className='button is-primary is-normal' type='button' value='Reconhecer' style={{ width: '40%', justifyContent: 'center', alignItems: 'center' }} onClick={reconhecerBtn}>
        </input>
      </div>
    </Column>
    <Column>
      {fileMapped}
    </Column>
  </Columns>
  )

};

export default ShareGondola;