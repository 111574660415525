import { datadogRum } from '@datadog/browser-rum';

function initializeDataDog() {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DD_APPID,
    clientToken: process.env.REACT_APP_DD_CLI_TOKEN,
    site: process.env.REACT_APP_DD_SITE,
    service: process.env.REACT_APP_DD_SERVICE,
    env: process.env.REACT_APP_DD_ENV,
    // Specify a version number to identify the deployed version of your application in Datadog 
    version: process.env.REACT_APP_DD_VERSION,
    sampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input'
  });
    
  datadogRum.startSessionReplayRecording();
  return datadogRum;
}

export const ddRum = datadogRum;
export default initializeDataDog