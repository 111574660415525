import { useEffect, useState } from "react"
import { get } from "../utils";


const useShelfs = (siteId) => {
  const [ shelfs, setShelfs ] = useState([]);
  const [ isLoading, setIsLoading ] = useState(false);
  
  useEffect(() => {
    setIsLoading(true)
    get(`dashboard/shelf/sites/${siteId}/shelfs`)
      .then(response => response.data.data)
      .then(setShelfs)
      .then(() => setIsLoading(false));
  }, [siteId]);
  
  return [shelfs, isLoading];
}

const useTags = (siteId) => {
  const [ tags, setTags ] = useState([]);
  const [ isLoading, setIsLoading ] = useState(false);
  
  useEffect(() => {
    setIsLoading(true);
    get(`dashboard/shelf/sites/${siteId}/tags`)
      .then(response => response.data.data)
      .then(setTags)
      .then(() => setIsLoading(false));
  }, [siteId]);

  return [tags, isLoading];
}

const useGondolas = (siteId) => {
  const [ isLoading, setIsLoading ] = useState(false);
  const [ gondolas, setGondolas ] = useState([]);
  const [ forceReload, setForceReload ] = useState(false);
  
  useEffect(() => {
    setIsLoading(true);
    get(`dashboard/shelf/sites/${siteId}/gondolas`)
      .then(resp => setGondolas(resp.data.data))
      .then(() => setIsLoading(false))
  }, [ siteId, forceReload ]);

  const doReload = () => {
    setGondolas([])
    setForceReload(!forceReload)
  }

  const removeGondola = (gondolaId) => {
    setGondolas(gondolas.filter(gondola => gondola.id !== gondolaId))
  }

  return [ gondolas, isLoading, doReload, removeGondola ]
}

export {
  useGondolas,
  useShelfs,
  useTags
};