import React, { Component } from 'react';
import Columns, { Column } from '../../../../../components/Columns';
import { CaretDownIcon, CaretLeftIcon } from '../../../../../components/icons';
import SensorCard from './SensorCard';
import SensorList from './SensorList';

class AreaSectionComponent extends Component {
  constructor(props){
    super(props);
    
    this.state = {
      expanded: true
    }
  }

  render() {
    const {area, site, buttonLisOrBox, buttonOrdered, onChangeRealtimeValue} = this.props;
    const {expanded} = this.state;

    const temperaturaEstaFora = (venue) => {
      const { realtimeValues={}, multi_thermo_part } = venue;
      const getTemperature = () => {
        if (!realtimeValues.temperature)
          return 0;

        let temperature = realtimeValues.temperature;
    
        if(multi_thermo_part){
          temperature = (realtimeValues.temperature + realtimeValues.temperature_2) / 2
        };

        return temperature;
      }

      const temperature = getTemperature();

      return realtimeValues.temperature && (
        temperature > venue.maxTemp ||
        temperature < venue.minTemp
      );
    }

    const sortedVenue = area.venues.sort((a, b) => {
      if(!buttonOrdered){
        if(a.placa > b.placa) return 1;
        if(a.placa < b.placa) return -1; 
      }
      const aTempFora = temperaturaEstaFora(a) ? 1 : 0;
      const bTempFora = temperaturaEstaFora(b) ? 1 : 0;
      const response = bTempFora - aTempFora;
      return response;
    });

    /* const placa = sortedVenue.map(e => {
      return e.placa;
    })

    const placasVenue = [];
    placasVenue.push(placa)
    placa.sort() */

    return (
      <Column isSize={12}>  
        <div className="tabs" style={{marginBottom: 0}}>
          <ul>
            <li>
              <a href onClick={() => this.setState({expanded: !expanded})}>
                <span>{area.description}</span>
                {expanded ? 
                <CaretDownIcon />
                :
                <CaretLeftIcon />}
              </a>
            </li>
          </ul>
        </div>
        {expanded && <Columns isMultiline>
        {buttonLisOrBox ?  
          <table className="table" style={{width: '100%'}}>
            <thead>
              <tr>
                <th>Nome</th>
                <th>Última atualização</th>
                <th>Situação do Sensor</th>
                <th>Temperatura atual/média</th>
                <th>Nível umidade</th>
                <th>Temperatura 1</th>
                <th>Temperatura 2</th>
                <th>Situação da porta</th>
                <th>Inativar Sensor</th>
              </tr>
            </thead>
            <tbody>
              {sortedVenue.map(venue =>
                <SensorList key={`venue_home_show_list_${venue.id}`} areaId={area.id} onChangeRealtimeValue={onChangeRealtimeValue} siteId={site.id} venue={venue} />
              )}
            </tbody>
          </table>
          : 
          sortedVenue.map(venue => <Column key={`venue_home_show_card_${venue.id}`} isSize={3}>
            <SensorCard areaId={area.id}  onChangeRealtimeValue={onChangeRealtimeValue} siteId={site.id} venue={venue} />
          </Column>)}
        </Columns>}
      </Column>
    )
  }
}

class AreaSection extends Component {
  constructor(props){
    super(props);
    
    this.state = {
      expanded: true
    }
  }
  render() {
    const {area, site, buttonLisOrBox, buttonOrdered} = this.props;
    const {expanded} = this.state;

    return area.principal ? 
        <Column isSize={12}>  
          <div className="tabs" style={{marginBottom: 0}}>
            <ul>
              <li>
                <a href onClick={() => this.setState({expanded: !expanded})}>
                  <span>{area.description}</span>
                  {expanded ? 
                  <CaretDownIcon />
                  :
                  <CaretLeftIcon />}
                </a>
              </li>
            </ul>
          </div>
          {
            expanded && area.areas.map(a => {
              return <AreaSectionComponent onChangeRealtimeValue={this.props.onChangeRealtimeValue} key={`child-area-${a.id}`} buttonLisOrBox={buttonLisOrBox} area={a} site={site}/>
            })
          }
        </Column>
        : <AreaSectionComponent onChangeRealtimeValue={this.props.onChangeRealtimeValue} area={area} site={site} buttonLisOrBox={buttonLisOrBox} buttonOrdered={buttonOrdered} />  
  }
}

export default AreaSection;