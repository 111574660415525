import React, { Component } from 'react';

import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  Line,
  ComposedChart,
  CartesianGrid,
  Brush,
  Legend
} from 'recharts';

import { formatDateMonthDayOfWeek } from '../../../../../../utils';
import Columns, { Column } from '../../../../../../components/Columns';

class SalesChart extends Component {

  render() {
    const { data, site1, site2, hasVideoModules } = this.props;

    if(!data.length) {
      return <div
        className="card notification"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: 400,
          backgroundColor: '#eee'
        }}>
        <p className="subtitle is-3">Não há lojas selecionadas para comparar</p>
      </div>
    }

    const dashData = data.map(data => {
      const site1 = {...data[1]};
      const site2 = {...data[2]};
      return {
        label: formatDateMonthDayOfWeek(site1.info_date),
        site1: {...site1, salesRate: hasVideoModules ? site1.salesRate : Math.round((site1.total_sales / site1.visitors) * 100) },
        site2: {...site2, salesRate: hasVideoModules ? site2.salesRate : Math.round((site2.total_sales / site2.visitors) * 100) }
      }
    });

    return (<div className="card notification is-white">
      <p className="subtitle is-3 has-text-centered">Visitantes e Vendas</p>
      <ResponsiveContainer width="100%" height={400}>
        <ComposedChart data={dashData}
          margin={{top: 20, right: 50, left: 10, bottom: 20}}>
        <CartesianGrid strokeDasharray="3 10" />
        <XAxis dataKey="label"/>
        <YAxis yAxisId="left" orientation="left" stroke="#343434"/>
        <YAxis yAxisId="right" orientation="right" stroke="#343434" tickFormatter={(val) => `${val}%`} domain={['dataMin-100', 'dataMax+5']}/>
        <Legend content={(props) => { 
          return <div style={{ width: '100%', padding: 10, display: 'flex', justifyContent: 'center' }}>
            <div style={{ width: '100%', padding: 10 }}>
              <p className="help has-text-centered">
                Dados correspondente aos últimos 90 dias. 
                Use a barra à cima para percorrer entre as datas. 
                Comparação entre <span style={{ textTransform: 'uppercase' }} className="has-text-weight-bold has-text-primary">{site1.name}</span> & <span style={{ textTransform: 'uppercase' }} className="has-text-weight-bold has-text-link">{site2.name}</span> do percentual de visitante que fizeram uma compra.
              </p>
              <Columns style={{ justifyContent: 'center' }}>
                <Column isSize={1}>
                  <p style={{ opacity: .5 }} className="help heading is-primary has-text-weight-bold has-text-centered">
                    visitantes
                  </p>
                </Column>
                <Column isSize={1}>
                  <p className="help heading is-primary has-text-weight-bold has-text-centered">
                    vendas
                  </p>
                </Column>
                <Column isSize={1}>
                  <p style={{ opacity: .5 }} className="help heading is-link has-text-weight-bold has-text-centered">
                    visitantes
                  </p>
                </Column>
                <Column isSize={1}>
                  <p className="help heading is-link has-text-weight-bold has-text-centered">
                    vendas
                  </p>
                </Column>
              </Columns>
            </div>
          </div>
        }} />
        <Brush
          startIndex={dashData.length - 7}
          dataKey='label'
          height={40}
          stroke="#222222"
        />
        <Tooltip
          content={<CustomToolTip site1Info={site1} site2Info={site2}  hasVideoModules={hasVideoModules}/>}
          isAnimationActive={false}/>
        <Bar
          yAxisId="left"
          name='Sales'
          stackId={'people1'}
          dataKey={hasVideoModules ? `site1.totalSales`: `site1.total_sales`}
         maxBarSize={15}
          fill="#00d1b2"
          isAnimationActive={false}
          minPointSize={3}
          connectNulls={true}
        />
        <Bar
          yAxisId="left"
          name='Visitors'
          dataKey={hasVideoModules ? `site1.visitorsCount`:`site1.visitors`}
         maxBarSize={15}
          fillOpacity={.5}
          fill="#00d1b2"
          stackId={'people1'}
          isAnimationActive={false}
          minPointSize={3}
          connectNulls={true}
        />
        <Bar
          yAxisId="left"
          name={site2.name}
         maxBarSize={15}
          stackId={'people2'}
          dataKey={hasVideoModules ? `site2.totalSales`: `site2.total_sales`}
          fill="#0043ff"
          isAnimationActive={false}
          minPointSize={3}
          connectNulls={true}
        />
        <Bar
          yAxisId="left"
          name={site2.name}
         maxBarSize={15}
          stackId={'people2'}
          dataKey={hasVideoModules ? `site2.visitorsCount` : `site2.visitors`}
          fill="#0043ff"
          isAnimationActive={false}
          minPointSize={3}
          connectNulls={true}
          fillOpacity={.5}
        />
        <Line
          yAxisId="right"
          name={`Variação`}
          dataKey={`site2.salesRate`}
          strokeDasharray="3 5"
          fill="#0043ff"
          stroke="#0043ff"
          isAnimationActive={false}
          connectNulls={true}
        />
        <Line
          yAxisId="right"
          name={`Variação`}
          dataKey={`site1.salesRate`}
          strokeDasharray="3 5"
          fill="#00d1b2"
          stroke="#00d1b2"
          isAnimationActive={false}
          connectNulls={true}
        />
      </ComposedChart>
    </ResponsiveContainer>
  </div>)
  }

}

class CustomToolTip extends Component {
  render() {
    const { active, hasVideoModules } = this.props;
    if(active) {
      const { payload=[], site1Info, site2Info } = this.props;
      const data = payload[0] || { payload: {} };

      const { label, site1, site2 } = data.payload;

      return (<div className="card">
        <div style={{ padding: '0px 5px 0px 5px' }}>
          <p className="heading has-text-centered">
            {label}
          </p>
          <div className="heading has-text-left">
            <p style={{ marginTop: 10, width: '100%' }} className="tag is-primary has-text-weight-bold">{site1Info.name}</p>
            <p className="help">VISITANTES: <b>{site1 && !hasVideoModules ? site1.visitors : site1.visitorsCount}</b></p>
            <p className="help">VENDAS: <b>{site1 && !hasVideoModules ? site1.total_sales : site1.totalSales}</b></p>
            <p className="help">TAXA VENDAS:<b> {site1 ? site1.salesRate : 0}</b></p>
          </div>
          <div className="heading has-text-left">
            <p style={{ marginTop: 10, width: '100%' }} className="tag is-link has-text-weight-bold">{site2Info.name}</p>
            <p className="help">VISITANTES: <b>{site2 && !hasVideoModules? site2.visitors : site2.visitorsCount}</b></p>
            <p className="help">VENDAS: <b>{site2 && !hasVideoModules ? site2.total_sales : site2.totalSales}</b></p>
            <p className="help">TAXA VENDAS:<b> {site2 ? site2.salesRate : 0}</b></p>
          </div>
        </div>
      </div>)
    }
    return null;
  }
}

export default SalesChart