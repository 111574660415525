import React, {Component} from 'react';

class JohannMinMaxCard extends Component {

  _handleSearchTerm = (e) => {
    this.setState({ searchTerm: e.target.value });
  }

  render() {
    const { hasLoaded, label, value, subvalue } = this.props;

    if(!hasLoaded)
      return <div className="card notification has-text-centered" style={{ padding: 8, backgroundColor: '#eee' }}>
        <p className={`tag is-normal has-text-weight-bold`} style={{ width: '100%', backgroundColor: '#d5d5d5' }}>{label}</p>    
        <p style={{ marginTop: 20 }} className="subtitle is-2">0</p>
        <p style={{ marginBottom: 15 }} className="help heading">{subvalue}</p>
      </div>

    return <div className="card notification has-text-centered is-white" style={{ padding: 8 }}>
      <p className={`tag is-link is-normal has-text-weight-bold`} style={{ width: '100%' }}>{label}</p>    
      <p style={{ marginTop: 20 }} className="subtitle is-2">{value}</p>
      <p style={{ marginBottom: 0 }} className="help heading">{subvalue}</p>
    </div>
  }
}

export default JohannMinMaxCard