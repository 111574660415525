import React, { Component } from 'react';
import Columns, { Column } from '../../../../../../components/Columns';
import {format} from 'date-fns';

class SumaryDash extends Component {

  render () {
    const { isLoading, visitorsHour, salesHour, visitors, sales } = this.props;  
    return (
      <div  style={{width: '100%'}}>
      <Columns>
        <Column isSize={6} className="has-text-centered"> 
          <div className="card" style={{width: '100%'}}>
            <div className="card-content">
              <p className="tag is-dark subtitle is-6 heading" style={{ marginBottom: 2 }}>Acumulado do dia</p>
              <p className="help heading" style={{ marginBottom: 7, marginTop: 2 }}>(até a ultima hora)</p>
              
              {isLoading ? <div className="has-text-centered" style={{ height:130 }}>
                <span>
                  <span className="button is-loading is-large is-bordless" />
                  <p className="subtitle is-5">Aguarde, carregando...</p>
                </span>
              </div>
              :
              <Columns>
                <Column>
                  <p className="heading has-text-wieght-bold" style={{ marginBottom: 0 }}>Taxa de visitantes</p>
                  <p className="subtitle is-1" style={{ marginBottom: 15 }}> {visitors.header.conversionRate}%</p>
                  <div className="title" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 0}}>
                    {isLoading
                      ? <progress style={{ height: '30px', width: '75%'  }} className="progress" max="100"></progress>
                      : <progress style={{ height: '30px', width: '75%' }} className="progress" value={visitors.header.conversionRate} max={100}></progress>}
                  </div>
                  <div className="subtitle" style={{ marginTop: 5}}>
                    {visitors.header.visitorsCount} / {visitors.header.passersByCount}
                  </div>
                </Column> 
                <Column>
                  <p className="heading has-text-wieght-bold" style={{ marginBottom: 0 }}>Taxa de conversão</p>
                  <p className="subtitle is-1" style={{ marginBottom: 15 }}> {sales.header.conversionRate}%</p>
                  <div className="title" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 0}}>
                    {isLoading
                      ? <progress style={{ height: '30px', width: '75%'  }} className="progress" max="100"></progress>
                      : <progress style={{ height: '30px', width: '75%' }} className="progress" value={sales.header.conversionRate} max={100}></progress>}
                  </div>
                  <div className="subtitle" style={{ marginTop: 5}}>
                  {sales.header.total_sales} / {sales.header.visitors}
                  </div>
                </Column>
              </Columns>
              }
            </div>
          </div>
        </Column>
        <Column isSize={6}  className="has-text-centered"> 
          <div className="card" style={{width: '100%'}}>
            <div className="card-content">
              <p className="tag is-dark subtitle is-6 heading" style={{ marginBottom: 26 }}>Última hora {isLoading ? 'HH' : format(salesHour.header.info_date, 'HH')}:00 - {isLoading ? 'HH' :format(salesHour.header.info_date, 'HH')}:59</p>
              
              {isLoading ? <div className="has-text-centered" style={{ height:130 }}>
                <span>
                  <span className="button is-loading is-large is-bordless" />
                  <p className="subtitle is-5">Aguarde, carregando...</p>
                </span>
              </div>
              :
              
              <Columns>
                <Column>
                  <p className="heading has-text-wieght-bold" style={{ marginBottom: 0 }}>Taxa de visitantes</p>
                  <p className="subtitle is-1" style={{ marginBottom: 15 }}> {visitorsHour.header.conversionRate}%</p>
                  <div className="title" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 0}}>
                    {isLoading
                      ? <progress style={{ height: '30px', width: '75%'  }} className="progress" max="100"></progress>
                      : <progress style={{ height: '30px', width: '75%' }} className="progress" value={visitorsHour.header.conversionRate} max={100}></progress>}
                  </div>
                  <div className="subtitle" style={{ marginTop: 5}}>
                     {visitorsHour.header.visitorsCount} / {visitorsHour.header.passersByCount}
                  </div>
                </Column> 
                <Column>
                  <p className="heading has-text-wieght-bold" style={{ marginBottom: 0 }}>Taxa de conversão</p>
                  <p className="subtitle is-1" style={{ marginBottom: 15 }}> {salesHour.header.conversionRate}%</p>
                  <div className="title" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 0}}>
                    {isLoading
                      ? <progress style={{ height: '30px', width: '75%'  }} className="progress" max="100"></progress>
                      : <progress style={{ height: '30px', width: '75%' }} className="progress" value={salesHour.header.conversionRate} max={100}></progress>}
                  </div>
                  <div className="subtitle" style={{ marginTop: 5}}>
                    {salesHour.header.total_sales} / {salesHour.header.visitors}
                  </div>
                </Column>
              </Columns>
              }
            </div>
          </div>
        </Column>
      </Columns>
      </div>
    )
  }
}
export default SumaryDash;