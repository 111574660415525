import React, { Component } from 'react';
import Columns, { Column } from './Columns';

class Modal extends Component {

  _toogleModal() {
    this.props.handleToogleModal();
  }

  render() {
    const { component, style, title, isCard, image, cardStyle={}, buttonsLeft, buttonsRight }= this.props;

    if(isCard) 
      return (<div className="modal is-active">
        <div className="modal-background" onClick={() => this._toogleModal()}></div>
        <div className="modal-card" style={cardStyle}>
          <header className="modal-card-head">
            <p className="modal-card-title">{title}</p>
            <button className="delete" aria-label="close" onClick={() => this._toogleModal()}></button>
          </header>
          <section className="modal-card-body">
            {component}
          </section>
          <footer className="modal-card-foot">
            <Columns style={{ width: '100%' }} >
              <Column isSize={6}>
                <div className="buttons is-left" style={{ width: '100%' }}>
                  {buttonsLeft.map(button => <button
                    key={button.label}
                    className={`button ${button.type}`}
                    onClick={button.onClick}
                    disabled={button.disabled}
                  >
                    {button.label}
                  </button>)}
                </div>
              </Column>
              <Column isSize={6}>
                <div className="buttons is-right" style={{ width: '100%' }}>
                  {buttonsRight.map(button => <button
                    key={button.label}
                    className={`button ${button.type}`}
                    onClick={button.onClick}
                    disabled={button.disabled}
                  >
                    {button.label}
                  </button>)}
                </div>
              </Column>
            </Columns>
          </footer>
        </div>
      </div>);

    if(image)
      return (<div className="modal is-active">
        <div className="modal-background" onClick={() => this._toogleModal()}></div>
        <div className="modal-content">
          <p className="image is-4by3">
            <img src={image} alt="Imagem não encontrada" />
          </p>
        </div>
        <button className="modal-close is-large" aria-label="close" onClick={() => this._toogleModal()}></button>
      </div>);

    return (<div className="modal is-active">
      <div className="modal-background" onClick={() => this._toogleModal()}></div>
        <div className="modal-content" style={style}>
          {component}
        </div>
        <button className="modal-close is-large" aria-label="close" onClick={() => this._toogleModal()}></button>
    </div>);
  }
}

export default Modal;