import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_API_URL;

export const getServer = async () => {
  const det2 = new Date();
  const resp = await axios.get(`${BASE_URL}/health`);
  const date = new Date(parseInt(resp.data.timestamp))
  const timestamp = parseInt(resp.data.timestamp)
  const offset = date - det2
  return {date, timestamp, offset};
};