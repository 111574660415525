
import React, { Component } from 'react';

import { get } from '../../../../../utils';
import Columns, { Column } from '../../../../../components/Columns';
import * as Icons from '../../../../../components/icons';
import VenueForm from './VenueForm';

class VenueContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      venues: [],
      toogleAdd: false
    }
  }

  componentDidMount() {
    this._loadVenues();
  }

  _loadVenues = () => {
    const { id } = this.props.site;
    this.setState({ isLoading: true }, () => {
      get(`admin/videos/sites/${id}/venues`)
        .then(resp => {
          const venues = resp.data.data;
          this.setState({ venues, isLoading: false });
        });
    });
  }

  _handleNewVenue = (venue) => {
    this.setState({
      venues: [
        ...this.state.venues,
        venue
      ]
    });
  }

  _handleEditVenue = (venue) => {
    const venues = this.state.venues.map(d => {
      if(d.id === venue.id) {
        return venue;
      }
      return d;
    });

    this.setState({ venues });
  }

  _changeDeviceCameras = (venues) => {
    this.setState({ venuesFromDevice: venues });
  }

  _toogleAdd = () => {
    this.setState({ toogleAdd: !this.state.toogleAdd });
  }

  render() {
    const { site } = this.props;
    const {
      isLoading,
      venues
    } = this.state;

    return (<div className="notification card">
      <p className="subtitle is-4"><Icons.MapMarkedIcon /> Areas para monitorar</p>
      <VenueForm siteId={site.id} handleSubmit={this._handleNewVenue} />
      {isLoading && <p className="subtitle is-5" style={{ marginTop: 15, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <span className="button is-loading is-white is-bordless is-large" ></span>
        <span>Aguarde carregando...</span>
      </p>}
      <Columns isMultiline style={{ marginTop: 10 }}>
        {venues.map(venue => <Column
          key={`admin-venues-${venue.id}`}
          isSize={12}
        >
          <div className={`notification card`}>
            <VenueForm siteId={site.id} handleSubmit={this._handleEditVenue} venue={venue} />
          </div>
        </Column>)}
      </Columns>
    </div>);
  }
}

export default (VenueContainer);