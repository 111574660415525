import React from 'react';

import {
  isValid,
  format
} from 'date-fns';

import {
  pt
} from 'date-fns/locale';

import {
  DraggableIcon,
} from '../../../../../components/icons';
import Columns, { Column } from '../../../../../components/Columns';

const gradient = [
  [
      0,
      [248,105,107]
  ],
  [
      50,
      [255,235,132]
  ],
  [
      100,
      [99, 190, 123]
  ]
];

const pickHex = (valueData, maxValue, minValue, midValue, isCompareTable, wereNone, becomeNone) => {
  //sem dados.
  if(!isCompareTable) {
    if(valueData === 0)
      return [255, 255, 255];
  }

  if(wereNone || becomeNone) {
    return [255, 255, 255];
  }

  const colorPicker = (weight, color1, color2) => {
    const p = weight;
    const w = p * 2 - 1;
    const w1 = (w/1+1) / 2;
    const w2 = 1 - w1;
    const rgb = [Math.round(color1[0] * w1 + color2[0] * w2),
      Math.round(color1[1] * w1 + color2[1] * w2),
      Math.round(color1[2] * w1 + color2[2] * w2)];
    return rgb
  }
  
  //àcima do meio
  if(valueData > midValue) {
    const variante = (maxValue - midValue);
    const colorWeight = (valueData - midValue) / variante;
    
    const color1 = gradient[2][1]
    const color2 = gradient[1][1]
    return colorPicker(colorWeight, color1, color2);
  }

  //média ou abaixo do meio
  const variante = (midValue - minValue);
  const colorWeight = (valueData - minValue) / variante;
  
  const color1 = gradient[1][1]
  const color2 = gradient[0][1]
  return colorPicker(colorWeight, color1, color2);
}

const DataCell = (props) => {
  const { value, bounds, isRate, noColor=false, isCompareTable } = props;

  const valueData = value[isRate ? 'rate' : isCompareTable ? 'variance': 'count']
  const maxValue = bounds[isRate ? 'maxRate' : isCompareTable ? 'maxVariance': 'maxCount'];
  const minValue = bounds[isRate ? 'minRate' : isCompareTable ? 'minVariance': 'minCount'];
  const midValue = bounds[isRate ? 'midRate' : isCompareTable ? 'midVariance': 'midCount'];

  let valueToShow = null;

  const unit = (isRate || isCompareTable) ? '%' : '';

  const {wereNone=false, becomeNone=false} = value;

  if(wereNone || becomeNone)
    valueToShow = `-`;
  else if (!isCompareTable && !valueData) {
    valueToShow = `-`;
  } else {
    valueToShow = `${valueData}${unit}`
  }

  const rgb = pickHex(valueData, maxValue, minValue, midValue, isCompareTable, wereNone, becomeNone);
  return <td
    className="has-text-centered has-text-weight-bold"
    style={{ backgroundColor: noColor ? '#363740': `rgb(${rgb[0]},${rgb[1]},${rgb[2]})`, color: noColor ? "#EEEEEE": "#363636"}}
  >
    {valueToShow}
  </td>
}

const PerformanceTable = (props) => {
  const {
    item = null,
    table,
    isDragging = false,
    connectDragSource = (obj) => obj,
    connectDragPreview = (obj) => obj,
    connectDropTarget = (obj) => obj,
    isOver = false,
    isDrag = false,
    isCompareTable = false,
    showPassersBy = false,
    showLoyalty = false
  } = props;

  const {
    isPersonalized,
    isRate=true,
    info_date,
    visitors,
    returns,
    passersBy,
    sales,
    cels
  } = table;

  const printTBody = (isFirst) => {
    return <tbody>
      {showPassersBy && <tr>
        {isFirst && <th>Passantes</th>}
        {cels.slice(isFirst ? 0 : 1, isFirst ? 1 : undefined).map((cel, i) => <DataCell
          noColor={isFirst}
          key={`passersBy-${i}`}
          isCompareTable={isCompareTable}
          value={cel.passersBy}
          bounds={passersBy}
          isRate={false}
        />)}
      </tr>}
      {<tr>
        {isFirst && <th>Visitantes</th>}
        {cels.slice(isFirst ? 0 : 1, isFirst ? 1 : undefined).map((cel, i) => <DataCell
          noColor={isFirst}
          key={`sales-${i}`}
          isCompareTable={isCompareTable}
          isRate={false}
          value={cel.visitors}
          bounds={visitors}
        />)}
      </tr>}
      {showPassersBy && <tr>
        {isFirst && <th>Taxa Visitantes</th>}
        {cels.slice(isFirst ? 0 : 1, isFirst ? 1 : undefined).map((cel, i) => <DataCell
          noColor={isFirst}
          key={`visitors-${i}`}
          isCompareTable={isCompareTable}
          isRate={isRate}
          value={cel.visitors}
          bounds={visitors}
        />)}
      </tr>}
      {<tr>
        {isFirst && <th>Vendas</th>}
        {cels.slice(isFirst ? 0 : 1, isFirst ? 1 : undefined).map((cel, i) => <DataCell
          noColor={isFirst}
          key={`sales-${i}`}
          isCompareTable={isCompareTable}
          isRate={false}
          value={cel.sales}
          bounds={sales}
        />)}
      </tr>}
      {<tr>
        {isFirst && <th>Taxa Vendas</th>}
        {cels.slice(isFirst ? 0 : 1, isFirst ? 1 : undefined).map((cel, i) => <DataCell
          noColor={isFirst}
          key={`sales-${i}`}
          isCompareTable={isCompareTable}
          isRate={isRate}
          value={cel.sales}
          bounds={sales}
        />)}
      </tr>}
      {showLoyalty && <tr>
        {isFirst && <th>Taxa Fidelidade</th>}
        {cels.slice(isFirst ? 0 : 1, isFirst ? 1 : undefined).map((cel, i) => <DataCell
          noColor={isFirst}
          key={`returns-${i}`}
          isCompareTable={isCompareTable}
          isRate={isRate}
          value={cel.returns}
          bounds={returns}
        />)}
      </tr>}
    </tbody>
  }

  const isOverStyle = {
    border: '2px solid #00d1b2'
  }

  const isDraggingStyle = {
    opacity: 0.55
  }

  const isSomeItemBeingDraggedStyle = {
    opacity: 0.8
  }

  let styles = {
    width: '100%',
    marginBottom: 20
  };

  let cardStyle = {}

  if(isOver)
    cardStyle = {
      ...isOverStyle
    }

  if(item !== null)
    styles = {
      ...styles,
      ...isSomeItemBeingDraggedStyle
    };

  if(isDragging)
    styles = {
      ...styles,
      ...isDraggingStyle
    };

  const lookAsPersonalized = isPersonalized && isDrag;

  const ConnectedPreviewElement = <div
    style={styles}
  >
    {lookAsPersonalized && <p className="help is-primary is-italic has-text-left">
      A tabela abaixo é correspondente ao período de <b className="has-text-dark">{info_date}</b>.
      <b className="has-text-danger has-text-decoration-underline cursor-pointer" onClick={props._removeIndexFromList}>Clique aqui para remover da análise</b>
    </p>}

    {connectDragPreview(<div className={`card notification is-performance-rows ${lookAsPersonalized ? 'is-row-custom' : ''}`}  style={cardStyle}>
      <Columns isGapless>
        <Column isSize={2}>
          <div className={`table-container`}>
            <table className={`table is-fullwidth`}>
              <thead>
                <tr>
                  <th
                    className="has-text-centered table-performance-header"
                    style={{width: 200}}
                  >
                    {connectDragSource(<span
                      title="Clique e arraste para comparar com outro périodo"
                      className={`tag is-primary heading ${isDrag ? 'cursor-pointer' : ''}`}
                    >
                      {isDrag && <DraggableIcon />}

                      {lookAsPersonalized ? 'Personalizado'
                        : isValid(info_date) ? format(info_date, 'MMMM/YY', {locale: pt}) : isCompareTable ? 'Variação' : info_date}
                    </span>)}
                  </th>
                  {cels.slice(0, 1).map((cel, i) => <th key={`sites-name-${i}`} className="has-text-centered">
                    <span className={'tag is-dark heading'}>
                      {cel.nickName ? cel.nickName: cel.name}
                    </span>
                  </th>)}
                </tr>
              </thead>
              {printTBody(true)}
            </table>
          </div>
        </Column>
        <Column isSize={10}>
          <div className={`table-container`}>
            <table className={`table is-fullwidth`}>
              {connectDragPreview(<thead>
                <tr>
                  {cels.slice(1).map((cel, i) => <th key={`sites-name-${i}`} className="has-text-centered">
                    <span className={'tag is-light heading'}>
                      {cel.nickName ? cel.nickName: cel.name}
                    </span>
                  </th>)}
                </tr>
              </thead>)}
              {printTBody()}
            </table>
          </div>
        </Column>
      </Columns>
    </div>)}
    {/* <div style={{ width: '100%', overflowX: 'auto' }}> */}
      
    {/* </div> */}
  </div>

  if(!isDragging) {
    return connectDropTarget(ConnectedPreviewElement);
  }

  return ConnectedPreviewElement;
}

export default PerformanceTable;