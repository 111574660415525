import React, { Component } from 'react';

import { FileInvoiceIcon, IdBadgeIcon, FileVideoIcon, GenderlessIcon, GenderMaleIcon, GenderFemaleIcon } from '../../../../../components/icons';
import { format } from 'date-fns';
import {pt} from 'date-fns/locale';
import Columns, { Column } from '../../../../../components/Columns';
import { get,post } from '../../../../../utils';

import './ModalAnalysisReid.css';


class ModalAnalysisReid extends Component {

  constructor(props) {
    super(props);

    this.state = {
      personFrames: [],
      framesSelected: [],
      personReid: [],
      filteredReid:[],
      genderFilter: -1,
      isloadingFrames: false,
      isloadingReid: false,
      isLoadingReidSave: false,
      gender: '0',
      isSaving: false, 
      tab: 'group'
    }
  }

  componentDidMount() {
    this.getPersonFrames()
  }
  getPersonFrames = () =>{
    const {
      audit,
      site
    } = this.props;
    this.setState({isloadingFrames: false, personFrames:[]}, () => {
      get(`dashboard/video/sites/${site.id}/auditReid/person-frames`, 
      {auditIds: audit.audits.map(audit => audit.id)})
      .then((resp) =>{
        this.setState({personFrames: resp.data.data})
      });
    });
  }

  _handleSelected = (frame) =>{
    const {framesSelected} = this.state;
    const remove = framesSelected.find(fs => fs.id === frame.id);
    if(remove){
      const frames = framesSelected.filter(fs => fs.id !== frame.id)
      this.setState({framesSelected: frames})
    }else{
      framesSelected.push(frame)
      this.setState({framesSelected})
    }
  }

  _onRadioChange =(event) =>{
    this.setState({
      gender: event.target.value
    });
  }

  onJoinSelect = () =>{
    const {personReid, genderFilter, isLoadingReidSave} = this.state;
    const{audit, site} = this.props;
    const persons = personReid.filter(person => person.isSelected)
    if(isLoadingReidSave){
      return;
    }
    if(persons.length > 1 ){
      const person = persons[0];
      this.setState({isLoadingReidSave: true}, () =>{
        post(`dashboard/video/sites/${site.id}/auditReid/${audit.id}/join-persons`, {person, personsToJoin: persons.filter(p => p.id !== person.id)})
        .then(res =>{
          const pe = res.data.data;
          pe.isSelected = false;
          const deleted = personReid.filter(person => !person.isSelected)
          deleted.push(pe)
          this.setState({personReid: deleted, isLoadingReidSave: false}, () => this.onFilter(genderFilter))
        })
      })
    }
  }
  onDismantleSelect = () =>{
    const {personReid, genderFilter, isLoadingReidSave} = this.state;
    const{audit, site} = this.props;
    const  persons = personReid.filter(person => person.isSelected)

    if(isLoadingReidSave){
      return;
    }
    if(persons.length > 0 ){
      this.setState({isLoadingReidSave: true}, () => {
        post(`dashboard/video/sites/${site.id}/auditReid/${audit.id}/dismantle-persons`, {persons})
        .then(res =>{
          const deleted = personReid.filter(person => !person.isSelected);
          this.setState({personReid: deleted, isLoadingReidSave: false}, () => this.onFilter(genderFilter))
        })
      })
    }
  }

  _savePersonFrames = () =>{
    const{audit, site} = this.props;
    const {framesSelected, gender, personFrames} = this.state;

    const persons = framesSelected.map(frame => frame.id);
    this.setState({isSaving: true} ,() => {
      post(`dashboard/video/sites/${site.id}/auditReid/${audit.id}/person`, {persons, gender})
      .then(resp =>{
  
        const deletedFrames = personFrames.filter(frame => !framesSelected.includes(frame));
  
        this.setState({framesSelected: [], gender: '0', personFrames: deletedFrames, isSaving: false})
      })
    })
  }

  _getPersonReidFrames = () =>{
    const{audit, site} = this.props;
    this.setState({isloadingReid: true, personReid: []}, ()=>{
      get(`dashboard/video/sites/${site.id}/auditReid/${audit.id}/person`)
      .then(resp =>{
        this.setState({personReid: resp.data.data, isloadingReid:false}, () => this.onFilter(-1))
      })
    })
  }


  _handleCancelButton = () => {
    this.props.toogleModal();
  }
  _onClickTab = (tab) =>{
    this.setState({tab: tab}, ()=>{
      if(tab === 'review'){
        this._getPersonReidFrames();
      }else{
        this.getPersonFrames();
      }
    })
  }
  onFilter =(gender) =>{
    const {personReid} = this.state;
    if(gender > 0){
      const filteredReid = personReid.filter(p => p.gender > 0)
      this.setState({filteredReid, genderFilter: 1})
    }else if(gender === 0 ){
      const filteredReid = personReid.filter(p => p.gender === 0)
      this.setState({filteredReid, genderFilter: 0})
    }else{
      this.setState({filteredReid: personReid, genderFilter: -1})
    }
  }

  render() {
    const {
      day,
      hour,
      audit,
    } = this.props;

    const {isloadingFrames, personFrames, framesSelected, isSaving, tab, filteredReid, isLoadingReidSave} = this.state;

    return <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card" style={{ backgroundColor: 'white', width: '95%', height: '90%', borderRadius: 5  }}>
        <div className="modal-card-body">
          <p className="title is-4 has-text-left">
            <IdBadgeIcon />
            <span>
              Análise Reid
            </span>
          </p>
          <p className="subtitle is-5 has-text-left">
            <b>#{audit.id}</b> "{audit.description}" {format(day.snap_date, 'DD MMMM', { locale: pt })} {hour.hour} até {hour.hour.replace('00:00', '59:59')}
          </p>
          <div className="tabs is-boxed ">
            <ul>
              <li style={{width: 150}} className={ tab === "group" ? "is-active" : ""}>
                <a href onClick={() => this._onClickTab("group")}>
                  <FileVideoIcon />
                  <span>Agrupamento</span>
                </a>
              </li>
              <li style={{width: 150}} className={ tab === "review" ? "is-active" : ""}>
                <a href onClick={() => this._onClickTab("review")}>
                  <FileInvoiceIcon />
                  <span>Revisão</span>
                </a>
              </li>
            </ul>
          </div>
          {tab === "group" && <Columns isMultiline style={{ height: '80%'}}> 
           {framesSelected.length > 0 && <Column isSize={12} style={{outline: '1px solid #000', outlineOffset: 0, minHeight: '182px'}}>
              <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                {framesSelected.map(frame => {
                  return <div key={`person_counted_select_${frame.id}`}  className="manualcount-frametocount is-selected" 
                  onClick={() => {
                    frame.isSelected = !frame.isSelected; 
                    this._handleSelected(frame)
                  }}
                  style={{ width: 64, margin: 5, display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                    <img alt="personimage" src={frame.image_url} style={{ width: 64, height: 128, marginBottom: 5}}/>
                  </div>
                })}
              </div>
              <div className="control">
              <label className="radio">
                  
                  <input type="radio" 
                  checked={this.state.gender === "0"}
                  style={{marginRight: 2}} 
                  value="0" 
                  onChange={this._onRadioChange}
                  name="gender"/>
                  <GenderMaleIcon />
                  Masculino
                </label>
                <label className="radio">
                  
                  <input type="radio" 
                  checked={this.state.gender === "1"}
                  style={{marginRight: 2}} 
                  value="1" 
                  onChange={this._onRadioChange}
                  name="gender"/>
                  <GenderFemaleIcon />
                  Feminino
                </label>
                <label className="radio">
                  
                  <input type="radio" 
                  checked={this.state.gender === "2"}
                  style={{marginRight: 2}} 
                  value="2" 
                  onChange={this._onRadioChange}
                  name="gender"/>
                  <GenderlessIcon />
                  Outro
                </label>
              </div>
              <button className={`button is-primary is-outlined ${(isSaving ? 'is-loading' : '')}`} 
              onClick={() => this._savePersonFrames()}>Salvar pessoa</button>
            </Column>}
            <Column isSize={12} style={{ display: 'flex', alignItems: 'center', verticalAlign: 'middle'}}>
              {isloadingFrames && <p className="subtitle is-5">Aguarde, localizando pessoas já contadas...</p>}
              {!isloadingFrames && <div style={{ height: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                {personFrames.map(frame => {
                  return <div key={`person_counted_${frame.id}`} onClick={() => {
                    frame.isSelected = !frame.isSelected; 
                    this._handleSelected(frame)
                  }} className={`manualcount-frametocount ${frame.isSelected ? `is-selected` : ``}`} style={{width: 64, margin: 5, display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                    <img alt="personimage" src={frame.image_url} style={{width: 64, height: 128, marginBottom: 5}}/>
                  </div>
                })}
              </div>}
            </Column>
          </Columns>}
          {tab === "review" && <Columns  isMultiline style={{maxHeight: '100%', display: 'flex', flexDirection: 'row'}}> 
            <Column isSize={12}>
              <button className="button " onClick={ ()=> this.onFilter(0)}>Masculino</button>
              <button className="button " onClick={ ()=> this.onFilter(1)}> Feminino</button> 
              <button className="button " onClick={ ()=> this.onFilter(-1)}> Limpar</button> 
            </Column>
            {filteredReid.map(person =>{
              return <Column isSize={3}>
                <div key={`person_reid_counted_${person.id}`} className={`manualcount-review ${person.isSelected ? 'is-selected' :''}`} >
                  <span style={{width: '100%',height: '100%'}} onClick={() => {
                                                                                person.isSelected = !person.isSelected ;
                                                                                this.forceUpdate();
                                                                              }}>
                    <Columns isMultiline  style={{width: '100%', maxHeight: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 0}}>
                      {person.frames.map(frame =>{
                        return <Column isSize={3}> <div key={`person_reid_counted_${frame.id}`} className={`manualcount-frametocount is-disabled`} style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                          <img alt="personimage" src={frame.image_url} style={{width: '98%', height: 128}}/>
                        </div>
                        </Column>
                      })}
                    </Columns>
                    <div className="column" style={{display: 'flex', justifyContent: 'center', paddingTop:0}}>
                      <span className="icon">
                        {person.gender === 0 ? <GenderMaleIcon /> : person.gender === 1 ? <GenderFemaleIcon /> : <GenderlessIcon /> }
                      </span>
                      <span>{person.gender === 0 ? 'Masculino' : person.gender === 1 ? 'Feminino' : 'Outro'}</span>
                    </div>
                  </span>
                </div>
              </Column>
            })}  
          </Columns>}
        </div>
        {tab === "review" && <footer className="modal-card-foot">
         <div>
            <button className={`button is-primary ${isLoadingReidSave ? 'is-loading': ''}`} onClick={ ()=> this.onJoinSelect()}>Justar grupos</button>
            <button className={`button is-danger ${isLoadingReidSave ? 'is-loading': ''}`} onClick={ ()=> this.onDismantleSelect()}> Desmanchar grupos</button> 
          </div>
        </footer>
        }
      </div>
      <button className="modal-close is-large" onClick={this._handleCancelButton} aria-label="close"></button>
    </div>
  }
}

export default ModalAnalysisReid