import React, {Component} from 'react';

import _ from 'lodash';
import {connect} from 'react-redux';

import {withRouter, Link} from "react-router-dom";

import {auth} from '../../../firebase/index';


import Page, {PageTitle} from '../../../components/Page';
import Columns, {Column} from '../../../components/Columns';
import { LockIcon, UserIcon } from "../../../components/icons";

import { actions as appActions } from '../../../state/app';
import {getServerDate, handleForInputChangeOnForm, post} from '../../../utils';
import notification from '../../../utils/notification';
import { addDays } from 'date-fns';

class Me extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        displayName: '',
        email: '',
        tooqAdmin: false,
        pass: '',
        passAgain: ''
      },
      actualData: {
        displayName: '',
        email: '',
        tooqAdmin: false,
        pass: '',
        passAgain: ''
      },
      firstLogin: false,
      lastChange: false,
      loading: true,
      saving: false,
      tooglePassChange: false,
      regEx:{
        capital : /[A-Z]/,
        digit   : /[0-9]/,
        special  : /[\W]/,
        full    : /^[A-Za-z0-9\W]{6,50}$/
      }
    }
  }

  componentDidMount() {
    const { user } = this.props;
    const {passwordConfig} = user;

    let firstLogin = false;
    let lastChange = false;

    if(passwordConfig && passwordConfig.first_login === 1){
      firstLogin = true
    }else if(passwordConfig && addDays(passwordConfig.last_change, 90).getTime() < getServerDate().getTime() ){
      lastChange = true;
    }
     
    const userInfo = {
      displayName: user.displayName,
      email: user.email
    };

    if(firstLogin){
      notification.warn('É necessário cadastrar uma nova senha para o seu novo acesso.')
    } else if(lastChange){
      notification.warn('Senha expirada. É necessário cadastrar uma nova senha.')
    }
    
    this.setState({
      formData: {
        ...userInfo
      },
      lastChange,
      firstLogin,
      tooglePassChange: lastChange || firstLogin,
      actualData: userInfo, 
      loading: false
    }); 
  }
  
  _handleSubmit(event) {
    event.preventDefault();
    const { formData, actualData, saving, passEquals, firstLogin, lastChange } = this.state;
    const {user} = this.props;
    
    if(saving) 
      return;
      
    const endSave = () => {
      if(firstLogin || lastChange){
        notification.success('Senha alterada com sucesso!');
        appActions.userLogout();
        auth.signOut();
        return
      }
      notification.success('Finalizado com sucesso!');
      this.setState({ 
        saving: false,
        actualData: { ...formData, pass: '', passAgain: '' }
      })
    }
    
    this.setState({ saving: true });
    if(this.state.tooglePassChange) {
      if(!passEquals){
        if(formData.pass !== formData.passAgain) {
          notification.error('As duas senhas não conferem')
        }else{
          notification.error('A senha não respeita os requisitos de senha')
        }
        this.setState({ saving: false });
      } else {
        post(`auth/new-password`, {uid: user.uid, password: formData.pass})
          .then(endSave)
          .catch((err = {}) => {
            const message = err.code === 'auth/requires-recent-login' 
              ? 'Para sua segurança, para alterar a senha, será necesário deslogar e logar novamente na aplicação, em seguinda volte aqui para alterar a senha'
              : err.code === 'auth/weak-password' 
              ? 'A senha informada é muito fraca' : 'Não foi possível alterar a senha, tente novamente mais tarde.' ;
            notification.error(message)
            this.setState({ saving: false });
          });
      }
      return;
    }

    const saveDisplayName = (cb) => {
      auth.currentUser.updateProfile({ displayName: formData.displayName })
        .then((val) => {
          //this will refresh the token and retrive the data to the cliente at App.js
          auth.currentUser.getIdToken(true);
          this.props.changeUserDisplayName(formData.displayName);
          cb(endSave);
        }).catch((err) => {
          notification.error('Não foi possível salvar o novo nome de usuário, tente novamente mais tarde.')
          this.setState({ saving: false });
        });
    }

    const saveEmail = (cb) => {
      auth.currentUser.updateEmail(formData.email)
        .then((val) =>  {
          this.props.changeUserDisplayName(formData.email);
          cb(endSave);
        })
        .catch(err => {
          const message = err.code === 'auth/requires-recent-login' 
            ? 'Para sua segurança, para alterar o email, será necesário deslogar e logar novamente na aplicação, em seguinda volte aqui para alterar o e-mail.' 
            : err.code === 'auth/email-already-in-use' 
            ? 'Endereço de email já está sendo usado por outro usuário na aplicação.' 
            : err.code === 'auth/invalid-email' ? 
            'Este endereço de e-mail é invalido.' : 'A conexão com o servidor foi perdida, por favor, tente novamente mais tarde.';

          notification.error(message)
          this.setState({ saving: false });
        });
    }

    if(formData.displayName !== actualData.displayName && formData.email !== actualData.email) {
      saveEmail(saveDisplayName);
      return;
    }

    if(formData.displayName !== actualData.displayName) {
      saveDisplayName(endSave);
      return;
    }

    if(formData.email !== actualData.email) {
      saveEmail(endSave);
      return;
    }

    this.setState({ saving: false });
  }

  showMessageForDifferentPass = _.debounce(() => {
    const { pass, passAgain } = this.state.formData;
    if(!passAgain) return;

    if(pass !== passAgain) {
      this.setState({passEquals: false });
    }
    else{
      if(this.passwordValidate(pass)){
        this.setState({passEquals: true });
      }else{
        this.setState({passEquals: false });
      }
    }
  }, 100);

  _passwordVerification(event) {
    handleForInputChangeOnForm.bind(this)(event, this.showMessageForDifferentPass);
  }
  passwordValidate(password) {
    const re = this.state.regEx
    return re.capital.test(password) && 
           re.digit.test(password) && 
           re.special.test(password) && 
           re.full.test(password);
  } 

  render() {
    const { formData , regEx, firstLogin, lastChange} = this.state;
    const capitalTest = regEx.capital.test(formData.pass);
    const digitTest = regEx.digit.test(formData.pass);
    const specialTest = regEx.special.test(formData.pass);
    const fullTest = regEx.full.test(formData.pass);

    return (
      <Page>
        <PageTitle title={(<div>
          <UserIcon />
          <span style={{ marginLeft: '15px' }}>Meus dados</span> 
        </div>)} />
        <div className="card">
          <div className="card-content">
            <Columns isMultiline>
              <Column isSize={6}>
                <form onSubmit={this._handleSubmit.bind(this)}>
                  <Columns isMultiline>
                    {formData.tooqAdmin && <Column isSize={12}>
                      <div className="field">
                        <label className="label">TooqAdmid</label> 
                      </div>
                    </Column>}
                    {!this.state.tooglePassChange && <Column isSize={12}>
                      <div className="field">
                        <label className="label">Name</label>
                        <div className="control">
                          <input className="input" type="text" placeholder="Nome de usuário"
                            id="displayName"
                            onChange={handleForInputChangeOnForm.bind(this)}
                            value={formData.displayName}
                            required
                          />
                        </div>
                      </div>
                    </Column>}
                    {!this.state.tooglePassChange && <Column isSize={12}>
                      <div className="field">
                        <label className="label">Email para login</label>
                        <div className="control">
                          <input className="input" type="text" placeholder="Endereço de email"
                            id="email"
                            onChange={handleForInputChangeOnForm.bind(this)}
                            value={formData.email}
                            required
                          />
                        </div>
                      </div>
                    </Column>}
                    {this.state.tooglePassChange && <Column isSize={12}>
                      <div className="field">
                        <label className="label">Nova Senha</label>
                        <div className="control has-icons-left">
                          <input className="input" type="password" placeholder="Nova Senha"
                            id="pass"
                            onChange={this._passwordVerification.bind(this)}
                            value={formData.pass}
                            required
                          />
                          <LockIcon className="is-small is-left"/>
                        </div>
                      </div>
                    </Column>}
                    {this.state.tooglePassChange && <Column isSize={12}>
                      <div className="field">
                        <label className="label">Digite novamente</label>
                        <div className="control has-icons-left">
                          <input className="input" type="password" placeholder="Repita a senha"
                            id="passAgain"
                            onChange={this._passwordVerification.bind(this)}
                            value={formData.passAgain}
                            required
                          />
                          <LockIcon className="is-small is-left"/>
                        </div>
                      </div>
                    </Column>}
                    <Column isSize={12}>
                      <div className="field is-grouped">
                        {!this.state.tooglePassChange && <div className="control">
                          <button type="submit" className={`button is-primary ${this.state.saving && 'is-loading'}`}>Salvar</button>
                        </div>}
                        {!this.state.tooglePassChange && <div className="control">
                          <button type="button" className="button is-text" onClick={() => this.setState({ tooglePassChange: !this.state.tooglePassChange}) }>Alterar Senha</button>
                        </div>}
                        {this.state.tooglePassChange && <div className="control">
                          <Link className={`button is-primary ${this.state.saving && 'is-loading'}`} onClick={this._handleSubmit.bind(this)} to="/board">
                            Confirmar Nova Senha
                          </Link>
                        </div>}
                        {this.state.tooglePassChange && (!firstLogin && !lastChange) && <div className="control">
                          <button type="button" className="button is-text" onClick={() => this.setState({ tooglePassChange: !this.state.tooglePassChange}) }>Cancelar</button>
                        </div>}
                      </div>
                    </Column>
                  </Columns>
                </form>
              </Column>
              {this.state.tooglePassChange && <Column isSize={6}style={{borderLeft: '1px solid #cfcfcf'}}>
                <div className="field">
                  <label className="title is-5">Requisitos da senha:</label> 
                  <Column isSize={12}>
                    <Column isSize={12} style={{paddingTop: '0'}}>
                      <ul style={{listStyleType: "square"}}>
                        <li>
                          <label className={`subtitle is-6 ${!capitalTest ? 'has-text-danger': ''}	`}>Possuir pelo menos uma letra maiuscula;</label>
                        </li>
                        <li>
                          <label className={`subtitle is-6 ${!specialTest ? 'has-text-danger': ''}	`}>Possuir pelo menos um caractere especial;</label>
                        </li>
                        <li>
                          <label className={`subtitle is-6 ${!digitTest ? 'has-text-danger': ''}	`}>Possuir pelo menos um numero; </label>
                        </li>
                        <li>
                          <label className={`subtitle is-6 ${!fullTest ? 'has-text-danger': ''}	`}>Possuir de 6 a 50 caracteres;</label>
                        </li>
                      </ul>
                    </Column>
                  </Column>
                </div>
              </Column>}
            </Columns>
          </div>
        </div>
      </Page>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.app.user,
    userInfo: state.app.userInfo,
    group: state.app.userGroup
  }
}
const { changeUserEmail, changeUserDisplayName } = appActions;
export default withRouter(connect(mapStateToProps, {changeUserEmail, changeUserDisplayName})(Me));