import React, { Component } from 'react';

class JohannRow extends Component {
  
  constructor(props) {
    super(props);
    
    const {
      id,
      intervalTimeNotify='',
      maxOutLimitNotify='',
      // maxHumi='',
      maxTemp='',
      // minHumi='',
      placa = '',
      minTemp='',
      name='',
      sensorid='',
      gps = true,
      multi_thermo_part = true,
      hasHumi = false,
      ldrval = 0
    } = props.venue;

    this.state = {
      forms: {
        id,
        intervalTimeNotify,
        maxOutLimitNotify,
        // maxHumi,
        maxTemp,
        // minHumi,
        placa,
        minTemp,
        name,
        sensorid,
        gps,
        multi_thermo_part,
        hasHumi,
        ldrval
      },
      isSaving: false
    } 
  }

  render() {
    const { venue } = this.props;
    const { isSaving,forms } = this.state;

    return <tr>
      <td >
        {this.state.forms.sensorid} 
      </td>
      <td>
        {this.state.forms.placa} 
      </td>
      <td>
        {this.state.forms.name}
      </td>
      <td>
        {`${forms.minTemp}°C à ${forms.maxTemp}°C `}
      </td>
      <td>
        <div className="field is-small">
          <div className="control has-icons-right is-expanded">
            <input name="gps" defaultChecked={this.state.forms.gps} disabled type="checkbox" value={this.state.forms.gps}/>
          </div>
        </div>
      </td>
      <td>
        <div className="field is-small">
          <div className="control has-icons-right is-expanded">
            <input name="hasHumi" defaultChecked={this.state.forms.hasHumi} disabled type="checkbox" value={this.state.forms.hasHumi}/>
          </div>
        </div>
      </td>
      <td>
        <div className="field is-grouped">
          <span className="control"  onClick={() => this.props.selectRowEdit(venue.id)}>
            <span className={`button `}>
              Editar
            </span>
          </span>
        

          <span className="control">
            <span disabled={isSaving} className="button is-link" onClick={() => this.props.selectRow(venue.id)}>
              mais opções
            </span>
          </span>
        </div>
      </td>
    </tr>
  }
}

export default JohannRow;