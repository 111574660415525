import React, { Component } from 'react';

import Columns, { Column } from '../../../../components/Columns';
import Modal from '../../../../components/Modal';
import * as Icons from '../../../../components/icons';
import DeciveContainer from './devices/DeciveContainer';
import CameraContainer from './cameras/CameraContainer';
import VenueContainer from './venues/VenueContainer';
import SyncronizeToSite from './SyncronizeToSite';

class VideoSettingForms extends Component {

  constructor(props) {
    super(props);

    this.state = {
      toogleModal: false
    }
  }

  _toogleModal = () => {
    this.setState({ toogleModal: !this.state.toogleModal });
  }

  render() {
    const { site } = this.props;
    const { toogleModal } = this.state;

    return (<Columns isMultiline>
      {toogleModal && <Modal
        handleToogleModal={this._toogleModal}
        component={<SyncronizeToSite site={site} />}
      />}
      <Column isSize={12}>
        <div className="buttons is-right">
          <div className="button is-warning is-outlined" onClick={this._toogleModal}>
            <Icons.SyncAltIcon /> <span>Sincronizar dados</span>
          </div>
        </div>
      </Column>
      <Column isSize={4}>
        <VenueContainer site={site} />
      </Column>
      <Column isSize={4}>
        <CameraContainer site={site} />
      </Column>
      <Column isSize={4}>
        <DeciveContainer site={site} />
      </Column>
    </Columns>);
  }
}

export default (VideoSettingForms);