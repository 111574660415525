
import React, { useEffect } from 'react';
import Card from '../../../../components/Card';
import {IsLoadingCentered} from '../../../../components/IsLoading';
import Modal from '../../../../components/Modal';
import { useCrud } from '../../../../hooks/useCrud';
import { useField } from '../../../../hooks/useField';
import { get, post } from '../../../../utils';
import notification from '../../../../utils/notification';


const IaTransformsModal = props => {
  const transforms = useCrud();

  const composeField = useField();
  const pickleField = useField();

  const newModel = (e) => {
    e.preventDefault();

    return transforms.add(
      () => post(`mlops/ia-transforms`, {
        compose: composeField.value,
        pickle: pickleField.value
      })
    )
    .then(() => {
      composeField.setValue('');
      pickleField.setValue('');
    })
    .catch(err => {
      notification.error('Erro ao salvar o modelo');
    })
  }

  useEffect(() => {
    transforms.list(
      () => get(`mlops/ia-transforms`)
    )
    //eslint-disable-next-line
  }, []);

  return <Modal
      handleToogleModal={props.closeModal}
      style={{ width: "35%", height: '50%' }}
      component={<Card style={{ height: '95%', overflow: 'auto' }}>
      <p className="title is-4">IA Transforms</p>
      <form className="field is-grouped" onSubmit={newModel}>
        <div className="control is-expanded">
          <input className='input' {...composeField} placeholder="Descrição do compose"  required/>
        </div>
        <div className="control is-expanded">
          <input className='input' {...pickleField} placeholder="Binário do Python"  required/>
        </div>
        <div className="control">
          <button type='submit' className={`button is-primary ${transforms.isAddingRow ? 'is-loading': ''}`}>
            Salvar
          </button>
        </div>
      </form>

      <table className='table is-fullwidth'>
        <thead>
          <tr>
            <th>ID</th>
            <th>Compose</th>
            <th>Pickle</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {transforms.data.map(row => <tr>
            <td>{row.id}</td>
            <td>{row.compose}</td>
            <td>{JSON.stringify(row.pickle)}</td>
            <td></td>
          </tr>)}
        </tbody>
      </table>
      {transforms.isLoadingList && <IsLoadingCentered fontSize={40} isBorderless={true} />}
    </Card>}
  />
}

export default IaTransformsModal;