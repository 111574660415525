import React, { Component } from 'react';
import {
  ResponsiveContainer,BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,ReferenceLine
} from 'recharts';

import {format} from 'date-fns';

export default class MinMaxPerDayChart extends Component {
  render() {
    const {detail, venue, isHumi = false} = this.props;

    if(isHumi){
      return (
        <ResponsiveContainer 
          width={'100%'}
          height={300}
        >
          <BarChart
            data={detail}
            margin={{
              top: 5, right: 30, left: 20, bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="collectDay" tickFormatter={date => format(date, 'DD/MM/YYYY') } />
            <YAxis domain={[0,'dataMax + 5']} tickFormatter={val => `${val}%`}/>
            <Tooltip labelFormatter={date => format(date, 'DD/MM/YYYY') }/>
            <ReferenceLine label="Maxima Permitida" y={venue.maxHumi} />
            <ReferenceLine label="Minima Permitida" y={venue.minHumi} />
            <Legend />
            <Bar maxBarSize={15} name={'Umidade mínima'} unit={'%'} dataKey="minHumidity" fill="#0043ff" label={{fontSize:11, position: 'top' }}/>
            <Bar maxBarSize={15} name={'Umidade média'}  unit={'%'} dataKey="avgHumidity" fill="#00d1b2" label={{fontSize:11, position: 'top' }}/>
            <Bar maxBarSize={15} name={'Umidade máxima'} unit={'%'} dataKey="maxHumidity" fill="#ff424b" label={{fontSize:11, position: 'top' }}/>
          </BarChart>
        </ResponsiveContainer>
      ) 
    } else {
      return (
        <ResponsiveContainer 
          width={'100%'}
          height={300}
        >
          <BarChart
            data={detail}
            margin={{
              top: 5, right: 30, left: 20, bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="collectDay" tickFormatter={date => format(date, 'DD/MM/YYYY') } />
            <YAxis domain={[0,'dataMax + 2']} tickFormatter={val => `${Number(val).toFixed(1)}˚C`}/>
            <Tooltip labelFormatter={date => format(date, 'DD/MM/YYYY') }/>
            <ReferenceLine label="Maxima Permitida" y={venue.maxTemp} />
            <ReferenceLine label="Minima Permitida" y={venue.minTemp} />
            <Legend />
            <Bar maxBarSize={15} name={'Temperatura mínima'} unit={'˚C'} dataKey="minTemperature" fill="#0043ff" label={{fontSize:11, position: 'top' }}/>
            <Bar maxBarSize={15} name={'Temperatura média'}  unit={'˚C'} dataKey="avgTemperature" fill="#00d1b2" label={{fontSize:11, position: 'top' }}/>
            <Bar maxBarSize={15} name={'Temperatura máxima'} unit={'˚C'} dataKey="maxTemperature" fill="#ff424b" label={{fontSize:11, position: 'top' }}/>
          </BarChart>
        </ResponsiveContainer>
      )
    } 
  }
}
