import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import {connect} from 'react-redux';
import Page from '../../../../../components/Page';
import Columns, { Column } from '../../../../../components/Columns';
import * as Icons from '../../../../../components/icons';
import { addDays } from 'date-fns';
import { get, getServerDate } from '../../../../../utils';
import AreaSection from './AreaSection';

class DashboardJohannHome extends Component {

  constructor(props) {
    super(props);

    this.state = {
      searchTerm: -1,
      toggleModal: false,
      areas: [],
      buttonLisOrBox: false,
      buttonOrdered: false,

    }
  }

  _toggleHover() {
    this.setState({hover: !this.state.hover})
  }

  _handleSearchTerm = (id) => {
    this.setState({ searchTerm: id});
  }

  componentDidMount() {
    const {user, site} = this.props;
    const {passwordConfig} = user;
    let firstLogin = false;
    let lastChange = false;

    if(passwordConfig && passwordConfig.first_login === 1){
      firstLogin = true
    }else if(passwordConfig && addDays(passwordConfig.last_change, 90).getTime() < getServerDate().getTime() ){
      lastChange = true;
    }
    if(firstLogin || lastChange){
      this.setState({toggleModal: true, firstLogin, lastChange})
    }
    this.setState({loading:true} , () => {
        get(`admin/johann/sites/${site.id}/venues-dash`)
        .then(resp => this.setState({loading: false, venues: [], areas: resp.data.data.map(area => {
          const venues = area.venues.map(venue => {
            return {
              ...venue,
              makeInactive: (areaId, venueId, isInactive) => {
                this.setState({
                  areas: this.state.areas.map(area => {
                    if (area.id === areaId) {
                      const { venues } = area;
                      return {
                        ...area,
                        venues: venues.map(venue => {
                          if(venue.id === venueId) {
                            return { ...venue, isInactive  };
                          }
                          return venue;
                        })
                      };
                    }
            
                    return area;
                  })
                })
              }
            };
          });

          return {
            ...area,
            venues
          }
        }) }))
      }
    )
  }

  onChangeRealtimeValue = (areaId, venueId, serverDate, realtimeValues) => {
    this.setState({
      areas: this.state.areas.map(area => {
        if (area.id === areaId) {
          const { venues } = area;
          return {
            ...area,
            venues: venues.map(venue => {
              if(venue.id === venueId) {
                return { ...venue, realtimeValues, serverDate };
              }
              return venue;
            })
          };
        }

        return area;
      })
    })
  };



  render() {
    const { site, group } = this.props;
    const { searchTerm, toggleModal, firstLogin, lastChange, loading, areas, buttonLisOrBox, buttonOrdered } = this.state;
    const areasFiltered = areas.filter(area => searchTerm === -1 || area.id === searchTerm);
    
    return (
      <Page>
        <Columns isMultiline>
            <Column isSize={12}>
              <p className="title">{group.name} - {site.name}</p>
              <p className="subtitle"><Icons.HomeIcon/> Seja bem-vindo</p>
            </Column>
            
            {loading && <Column isSize={12}>
              <div className="has-text-centered" style={{ width: '100%', height:300, paddingTop: '70px' }}>
                <span>
                  <span
                    className="button is-loading is-large is-bordless is-default"
                    style={{ backgroundColor: "rgb(247, 248, 252)", fontSize: 60 }}
                  />
                  <p className="subtitle is-5">Aguarde, carregando...</p>
                </span>
              </div>
            </Column>}
            {!loading && !areas.length && <Column isSize={12}>
              <div className="subtitle is-3 has-text-centered">
                <div className="button is-static is-bordless is-large" style={{ fontSize: 60 }}></div>
                <div>Nenhum sensor encontrado.</div>
              </div>
            </Column>}
            {!loading && <Column isSize={12}>
              <div className="tabs is-centered">
                <ul>
                  <li className={searchTerm === -1 ? "is-active": ""}><a href onClick={() => this._handleSearchTerm(-1)} >Todos</a></li> 
                  {areas.map (a => {
                    return <li key={a.id} className={searchTerm === a.id ? "is-active": ""}><a href onClick={() => this._handleSearchTerm(a.id)} >{a.description}</a></li>  
                  })}
                </ul>
              </div>
            </Column>}

            {!loading && <Column style={{padding: 0, display: 'flex', justifyContent: 'left'}}>
              {buttonLisOrBox 
              ? 
              <button className="button is-medium is-default" style={{backgroundColor: '#F7F8FC'}} onClick={() => this.setState({buttonLisOrBox: !buttonLisOrBox})}>
                <span>Mostrar Cards</span><Icons.BoxIcon />
              </button>
              : 
              <button className="button is-medium is-default" style={{backgroundColor: '#F7F8FC'}} onClick={() => this.setState({buttonLisOrBox: !buttonLisOrBox})}>
                <span>Mostrar em Lista</span><Icons.BarsIcon />
              </button>}

              {buttonOrdered 
              ? 
              <button 
                className="button is-medium is-default" 
                style={{backgroundColor: '#F7F8FC', marginLeft: 5}} 
                onClick={() => this.setState({buttonOrdered: !buttonOrdered})}
              >
                <span>Ordem Alfabética</span>
              </button>
              : 
              <button 
                className="button is-medium is-default" 
                style={{backgroundColor: '#F7F8FC', marginLeft: 5}} 
                onClick={() => this.setState({buttonOrdered: !buttonOrdered})}
              >
                <span>Ordem Por Alerta</span><Icons.ExclamationTriangleIcon />
              </button>}
            </Column>}

            {areasFiltered.map(area => 
              <AreaSection
                key={`principal-area-${area.id}`}
                area={area}
                site={site}
                buttonLisOrBox={buttonLisOrBox}
                buttonOrdered={buttonOrdered}
                onChangeRealtimeValue={this.onChangeRealtimeValue}
              />)}
          </Columns>

          {toggleModal && <div className="modal is-active">
            <div className="modal-background" ></div>
            <div className="modal-content is-paddingless ">
              {firstLogin &&<div className="message is-success">
                <p className="message-header">
                  BEM VINDO!
                </p>
                <p className="message-body">
                  Bem vindo a plataforma TOOQ Smile, para maior segurança será nessário redefinir sua senha.
                  <p className="message-footer">
                    <Link className={`button is-primary `} onCLick={() => this.setState({toggleModal: false})} to="/board/me">
                      Redefinir senha
                    </Link>
                  </p>
                </p>
              </div>}
              {lastChange && <div className="message is-danger">
                <p className="message-header">
                  SENHA EXPIRADA!
                </p>
                <p className="message-body">
                  Sua senha expirou. Por favor defina uma nova senha.
                  <p className="message-footer">
                    <Link className={`button is-danger `} onCLick={() => this.setState({toggleModal: false})} to="/board/me">
                      Definir senha
                    </Link>
                  </p>
                </p>
              </div>}
            </div>
          </div>}
      </Page>
    )
  }
}
function mapStateToProps(state, props) {
  return {
    user: state.app.user
  }
}

export default withRouter(connect(mapStateToProps)(DashboardJohannHome));