import React, { Component } from 'react';
import {format} from 'date-fns';
import {Map, Circle, GoogleApiWrapper} from 'google-maps-react';
import Columns, { Column } from '../../../../components/Columns';

class MapChart extends Component {
  render() {

    const {row, address} = this.props;

    const coords ={lat: row.lat, lng: row.lon}
    const containerStyle = {
      position: 'relative',  
      width: '100%',
      height: '100%'
    }
    return (<div className="card notification"  style={{width: '100%'}}>
              <Columns >
                <Column isSize={3}>
                  <p className="subtitle is-5" >Periodo:</p>
                  <h1 className="title">{format(row.collectDay, 'DD/MM - HH:mm')}</h1>
                  <p className="subtitle is-5" style={{marginTop: 0}} >Endereço aproximado:</p>
                  <p className="subtitle is-6" style={{fontWeight: 'bold', marginTop: '-1.25rem'}} >{address}</p>
                </Column>
                <Column isSize={9} >
                  <Map 
                    google={this.props.google} 
                    containerStyle={containerStyle}
                    style={{width: '100%', height: 300, position: 'relative'}}
                    zoom={14}
                    draggable={false}
                    clickableIcons={false}
                    keyboardShortcuts={false}
                    zoomControl={true}
                    mapTypeControl={false}
                    scaleControl={false}
                    streetViewControl={false}
                    panControl={false}
                    rotateControl={false}
                    fullscreenControl={false}
                    initialCenter={coords}>
                      <Circle
                        radius={300}
                        center={coords}
                        strokeOpacity={0}
                        strokeWeight={5}
                        fillColor='#FF0000'
                        fillOpacity={0.2}
                      />
                  </Map>
                </Column>
              </Columns>
            </div>)
  }
}
const LoadingContainer = (props) => (
  <span className="button is-loading is-white" style={{ fontSize: 30 }}></span>
)

export default GoogleApiWrapper({apiKey: ("AIzaSyAy1-WyaCtApwJwfAGK1eS9xBvHbvoWP64"),
                                 LoadingContainer: LoadingContainer})(MapChart)


