import React, { useState } from 'react';
import { put } from '../../../utils';

const SiteDetails = (props) => {
  const { site } = props;
  const { setModalEditSite } = props;
  const { loadSites } = props;
  const [ siteName, setName ] = useState(site.name);
  const [ siteNickName, setSiteNickName ] = useState(site.nickName);
  const [ foottraffic, setFoottraffic ] = useState(site.modules.foottraffic);
  const [ johann, setJohann ] = useState(site.modules.johann);
  const [ loyalty, setLoyalty ] = useState(site.modules.loyalty);
  const [ queues, setQueues ] = useState(site.modules.queues);
  const [ sales, setSales ] = useState(site.modules.sales);
  const [ shelf, setShelf ] = useState(site.modules.shelf);
  const [ showcases, setShowcases ] = useState(site.modules.showcases);
  const [ venues, setVenues] = useState(site.modules.venues);
  const [ video, setVideo] = useState(site.modules.video);
  const [ isSuvinil, setIsSuvinil] = useState(site.modules.isSuvinil);
  const [ isAmbev, setIsAmbev ] = useState(site.modules.isAmbev);
  const [ buttonEditLoading, setButtonEditLoading ] = useState(false);

  const cancelEditSite = () => {
    setModalEditSite(false);
  };

  const editSiteSave = () => {

    if(buttonEditLoading) {
      return;
    }

    setButtonEditLoading(true);

    put(`admin/tooq/sites/${site.id}`,
    { 
      site:{
        name: siteName,
        nickName: siteNickName
      },
      modules: {
        foottraffic: foottraffic,
        johann: johann,
        loyalty: loyalty,
        queues: queues,
        sales: sales,
        shelf: shelf,
        showcases: showcases,
        venues: venues,
        video: video,
        isSuvinil: isSuvinil,
        isAmbev: isAmbev,
        instagram: false,
        facebook: false,
      }
    })
    .then(() => setModalEditSite(false))
    .then(() => setButtonEditLoading(false))
    .then(() => loadSites())
  };


  return <div className="card" style={{width: '100%'}}>
    <div className="card-header">
      <p className="card-header-title">Editando {siteName}</p>
    </div>
    <div className="card-content">
      <div className="content">
        <div className="field">        
          <label className="label">Nome da loja:</label>
          <div className="control">
            <input 
              className="input is-primary" 
              type="text" 
              onChange={(e) => setName(e.target.value)} value={siteName} 
            />
          </div>
        </div>
        <div className="field">
          <label className="label">NickName da loja:</label>
          <div className="control">
            <input 
              className="input is-primary" 
              type="text" 
              onChange={(e) => setSiteNickName(e.target.value)} value={siteNickName} 
            />
          </div>
        </div>
        <div>
          <label className="checkbox">
            <input type="checkbox" onChange={(e) => setFoottraffic(e.target.checked)} checked={foottraffic}/>
             foottraffic
          </label>
        </div>
        <div>
          <label className="checkbox">
            <input type="checkbox" onChange={(e) => setJohann(e.target.checked)} checked={johann}/>
             johann
          </label>
        </div>
        <div>
          <label className="checkbox">
            <input type="checkbox" onChange={(e) => setLoyalty(e.target.checked)} checked={loyalty}/>
             loyalty
          </label>
        </div>
        <div>
          <label className="checkbox">
            <input type="checkbox" onChange={(e) => setQueues(e.target.checked)} checked={queues}/>
             queues
          </label>
        </div>
        <div>
          <label className="checkbox">
            <input type="checkbox" onChange={(e) => setSales(e.target.checked)} checked={sales}/>
             sales
          </label>
        </div>
        <div>
          <label className="checkbox">
            <input type="checkbox" onChange={(e) => setShelf(e.target.checked)} checked={shelf}/>
             shelf
          </label>
        </div>
        <div>
          <label className="checkbox">
            <input type="checkbox" onChange={(e) => setShowcases(e.target.checked)} checked={showcases}/>
             showcases
          </label>
        </div>
        <div>
          <label className="checkbox">
            <input type="checkbox" onChange={(e) => setVenues(e.target.checked)} checked={venues}/>
             venues
          </label>
        </div>
        <div>
          <label className="checkbox">
            <input type="checkbox" onChange={(e) => setVideo(e.target.checked)} checked={video}/>
             video
          </label>
        </div>
        <div>
        <label className="checkbox">
            <input type="checkbox" onChange={(e) => setIsSuvinil(e.target.checked)} checked={isSuvinil}/>
             isSuvinil
          </label>
        </div>
        <div>
          <label className="checkbox">
            <input type="checkbox" onChange={(e) => setIsAmbev(e.target.checked)} checked={isAmbev}/>
             isAmbev
          </label>
          <div className="buttons" style={{ marginTop: 15 }}>
            <button 
              onClick={() => editSiteSave()} 
              className={`button is-normal is-primary ${buttonEditLoading ? 'is-loading' : ''}`}
            >
              Salvar
            </button>
            <button 
              onClick={() => cancelEditSite()}
              className="button is-default">
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
};

export default SiteDetails;
