import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import Columns, { Column } from "../../../../../components/Columns";
import { ArrowsIcon, TimesIcon, CheckIcon, MinusIcon, ShuffleIcon } from '../../../../../components/icons';
import { get, post } from '../../../../../utils';
import notification from '../../../../../utils/notification';


const parsePoints = (points) => points
  .split(' ')
  .map(pointStr => pointStr.split(','))
  .map(point => [parseInt(point[0]), parseInt(point[1])]);
  
const parsePointsToShow = (points, widthFator, heigthFator) => parsePoints(points)
  .map(point => [parseInt(point[0] / widthFator), parseInt(point[1] / heigthFator)])
  .map(point => `${point[0]},${point[1]}`)
  .join(" ")
  

const MarcacaoLatas = props => {
  const {
    site,
    camera,
    showHeigth,
    showWidth,
    //fatores necessarios por causa da resolucao da foto vs resolucao em que esta sendo feito o desenho
    widthFator,
    heigthFator
  } = props;

  const [ boxVenueSelected, setBoxVenue ] = useState({
    x: 0,
    y: 0,
    width: 400,
    height: 40,
    coluna: 4,
    linha: 2
  })

  const [ selectedVertice, setSelectedV ] = useState({});
  const [ vertices, setVertices ] = useState([]);
  const [ insertingSnippet, setInsertingSnippet ] = useState(false);
  const [ latas, setLatas ] = useState({});
  const [ snippetScreen, setSnippetScreen ] = useState([]);
  const [ selectedSnippet, setSelectedSnippet ] = useState(null);
  const [ canMove, setCanMove ] = useState(false);
  const [ moving, setMoving ] = useState(false);

  useEffect(() => {
    get(`admin/videos/sites/${site.id}/cameras/${camera.id}/vertices-n-snippets`)
      .then(resp => {
        const vertices = resp.data.data.map(vertice => {
          return {
            id: vertice.id,
            venueId: vertice.venueId,
            points: parsePointsToShow(vertice.polygon_points, widthFator, heigthFator),
            snippets: vertice.snippets.map(snippet => ({
              id: snippet.id,
              verticeId: vertice.id,
              minX: parseInt(snippet.minX / widthFator),
              minY: parseInt(snippet.minY / heigthFator),
              maxX: parseInt(snippet.maxX / widthFator),
              maxY: parseInt(snippet.maxY / heigthFator),
              width: parseInt(snippet.width / widthFator),
              height: parseInt(snippet.height / heigthFator)
            }))
          }
        });

        const snippets = vertices.reduce((acc, curr) => {
          return {
            ...acc,
            [curr.id]: curr.snippets
          }
        }, {});
        
        setLatas(snippets);
        setVertices(vertices);
      })
    // eslint-disable-next-line
  }, [ camera.id ]);

  const onChangeColunaLinha = (e, field) => {
    const {value} = e.target;
    setBoxVenue({ ...boxVenueSelected, [field]: value });
  }

  const setBoxVenueCoord = (x, y) => {
    setBoxVenue({ ...boxVenueSelected, x, y });
  }

  const getVerticeSnippets = (verticeId) => {
    return latas[verticeId] || []
  }

  const setSelectedVertice = (venue, wborder, hborder) => {
    const minX = _.min(parsePoints(venue.points).map(points => points[0]))
    const minY = _.min(parsePoints(venue.points).map(points => points[1]))
    const minYToSet = minY-50 < 0 ? 0 : minY-50;

    const { width } = boxVenueSelected;
    const minXToSet = (minX + width) > wborder ? wborder - width : minX;

    setBoxVenueCoord(minXToSet, minYToSet);
    setSelectedV({
      ...venue
    });
    setSnippetScreen(getVerticeSnippets(venue.id));
  }

  const insertVenueSnippets = (verticeId) => {
    setCanMove(false);
    if (insertingSnippet)
      return;

    setInsertingSnippet(true)
    const snippets = snippetScreen.map(snippet => {
      return {
        verticeId: verticeId,
        minX: parseInt(snippet.minX * widthFator),
        minY: parseInt(snippet.minY * heigthFator),
        maxX: parseInt(snippet.maxX * widthFator),
        maxY: parseInt(snippet.maxY * heigthFator),
        width: parseInt(snippet.width * widthFator),
        height: parseInt(snippet.height * heigthFator)
      }
    })

    post(`admin/videos/sites/${site.id}/cameras/${camera.id}/vertices/${verticeId}/snippet`, {
      snippets
    }).then(resp => {
      setLatas({ ...latas, [verticeId]: snippetScreen });
      setInsertingSnippet(false);
      setSelectedV({});
      setSnippetScreen([]);
      setSelectedSnippet(null);
      notification.success('Recortes salvos no banco.')
    });
  }

  const generateLatasInVenue = (verticeId, shelfPoints, colunas=4, linhas=2) => {
    const minX = _.min(parsePoints(shelfPoints).map(points => points[0]))
    const maxX = _.max(parsePoints(shelfPoints).map(points => points[0]))
    const minY = _.min(parsePoints(shelfPoints).map(points => points[1]))
    const maxY = _.max(parsePoints(shelfPoints).map(points => points[1]))

    const width = maxX - minX;
    const height = maxY - minY;

    const lataHeight = parseInt(height / linhas);
    const lataWidth = parseInt(width / colunas);

    let countId = 0;
    const lataBoxes = _.range(0, linhas).map(linha => {
      return _.range(0, colunas).map(coluna => {
        countId++;
        const x = parseInt(((coluna * lataWidth) + minX));
        const y = parseInt(((linha * lataHeight) + minY));

        return {
          id: countId,
          verticeId,
          minX: x,
          minY: y,
          maxX: parseInt(x+lataWidth),
          maxY: parseInt(y+lataHeight),
          width: parseInt(lataWidth),
          height: parseInt(lataHeight)
        }
      })
    }).flat()

    setSnippetScreen(lataBoxes);
    return lataBoxes;
  }

  const latasSelected = getVerticeSnippets(selectedVertice.id);

  
  const moveSnippetScreen = (id, e, resizing) => {
    if(!id || !canMove || !moving) 
      return;
    
    const { movementX, movementY } = e;
    const snippet = snippetScreen.map(s => {
      if (s.id === id) {
        const newMinX = s.minX+movementX;
        const newMinY = s.minY+movementY;
        if (resizing) {
          const newWidth = s.width - movementX;
          const newHeight = s.height - movementY;
          return {
            ...s,
            minX: newMinX,
            maxX: newMinX + newWidth,
            minY: newMinY,
            maxY: newMinY + newHeight,
            width: newWidth,
            height: newHeight
          }
        }

        return {
          ...s,
          minX: newMinX,
          maxX: newMinX + s.width,
          minY: newMinY,
          maxY: newMinY + s.height
        }
      }
      
      return s;
    });
    setSnippetScreen(snippet);
  }
  const onDragMove = (e, resizing) => moveSnippetScreen(selectedSnippet, e, resizing)
  const onDragRelease = e => setMoving(false);
  const onDragClick = e => canMove && setMoving(true);
  const boxSelected = snippetScreen.find(snippet => snippet.id === selectedSnippet);

  return (<Columns isMultiline>
      <Column isSize={12}>
        <svg
          name="svgDraw"
          style={{
            border: '1px solid #222222',
            borderRadius: 5
          }}
          width={showWidth}
          height={showHeigth}
        >
            <image
              href={camera.imageSrc}
              x="0"
              y="0"
              width={showWidth}
              height={showHeigth}
            />
            
            
            {vertices.map(venueVertices => {
            const verticeSnippets = getVerticeSnippets(venueVertices.id);

            const onClickToSelect = () => () => {
              if (selectedVertice.id && selectedVertice.id !== venueVertices.id) {
                notification.warn('Clique em salvar ou fechar para mudar de desenho')
                return;
              }

              if (selectedVertice.id === venueVertices.id)
                return;

              setSelectedVertice(venueVertices, showWidth, showHeigth)
            }

            return <g key={`vertice-${venueVertices.id}-${venueVertices.points}`}>
              <polyline
                points={venueVertices.points}
                fillOpacity={0}
                cursor="pointer"
                onClick={onClickToSelect()}
                stroke='green'
                strokeWidth={venueVertices.id === selectedVertice.id ? 4 : 2}
              />
              {venueVertices.id !== selectedVertice.id && verticeSnippets.map(box => <rect
                onClick={onClickToSelect()}
                cursor="pointer"
                x={box.minX}
                y={box.minY}
                width={box.width}
                height={box.height}
                stroke="#222222"
                fillOpacity={0.1}
              />)}
            </g>})}
            {snippetScreen
            .map(box => <g>
              <rect
                onMouseDown={(e) => setSelectedSnippet(box.id)}
                x={box.minX}
                y={box.minY}
                width={box.width}
                height={box.height}
                stroke={selectedSnippet === box.id ? "green" : "#222222"}
                fillOpacity={0.1}
              />
            </g>)}
            {selectedSnippet && canMove && <g>
              <rect
                onMouseMove={(e) => onDragMove(e)}
                onMouseDown={e => onDragClick(e)}
                onMouseUp={e => onDragRelease(e)}
                x={boxSelected.minX}
                y={boxSelected.minY}
                width={boxSelected.width}
                height={boxSelected.height}
                fill="green"
                opacity={0.5}/>
              <circle
                onMouseMove={(e) => onDragMove(e, true)}
                onMouseDown={e => onDragClick(e)}
                onMouseUp={e => onDragRelease(e)}
                cx={boxSelected.minX}
                cy={boxSelected.minY}
                fill={"black"}
                r={6} />
            </g>}
            {selectedVertice.id && <g x={boxVenueSelected.x} y={boxVenueSelected.y}>
              <foreignObject
              x={boxVenueSelected.x}
              y={boxVenueSelected.y}
              width={boxVenueSelected.width}
              height={boxVenueSelected.height}>
                <div style={{
                  width: boxVenueSelected.width,
                  backgroundColor: "#ffffff",
                  borderRadius: 15,
                  padding: 5,
                  paddingLeft: 15,
                  paddingRight: 15
                }}>
                  {insertingSnippet && <div className="field is-horizontal">
                    <div className="field">
                      <span className="button is-small is-primary is-loading">
                        <span className="icon">
                          <CheckIcon />
                        </span>
                        <span>({latasSelected.length})</span>
                      </span>
                    </div>
                  </div>}
                  {!insertingSnippet && <div className="field is-horizontal">
                    <div className="field-body">
                      <div className="field">
                        <button
                          type="button"
                          disabled={!selectedSnippet}
                          className="button is-small is-danger"
                          onClick={() => {
                            setCanMove(false)
                            setSelectedSnippet(null)
                            setSnippetScreen(snippetScreen.filter(s => s.id !== selectedSnippet))
                          }}
                        >
                          <MinusIcon />
                        </button>
                      </div>
                      <div className="field">
                        <button type={`button ${canMove ? 'is-active': ''}`} onClick={() => {
                          setCanMove(!canMove)
                          if (!canMove)
                            setMoving(false)
                        }} disabled={!selectedSnippet} className="button is-small is-link">
                          <ArrowsIcon />
                        </button>
                      </div>
                      <div className="field">
                        <input
                          onChange={e => onChangeColunaLinha(e, 'linha')}
                          value={boxVenueSelected['linha']}
                          className="input is-small"
                          type="number"
                          placeholder='Linhas ex: 2'
                          style={{ width: 60, maxWidth: 60}}
                        />
                      </div>
                      <div className="field">
                        <input
                          onChange={e => onChangeColunaLinha(e, 'coluna')}
                          value={boxVenueSelected['coluna']}
                          className="input is-small"
                          type="number"
                          placeholder='Colunas ex: 4'
                          style={{ width: 60, maxWidth: 60}}/>
                      </div>
                      <div className="field">
                        <span
                          className="button is-small is-info"
                          onClick={() => generateLatasInVenue(
                              selectedVertice.id,
                              selectedVertice.points,
                              boxVenueSelected['coluna'],
                              boxVenueSelected['linha'])}
                        >
                          <ShuffleIcon />
                        </span>
                      </div>

                      <div className="field">
                        <span
                          className="button is-small is-primary"
                          onClick={() => insertVenueSnippets(selectedVertice.id)}
                        >
                          <span className="icon">
                            <CheckIcon />
                          </span>
                          <span>({snippetScreen.length})</span>
                        </span>
                      </div>

                      <div className="field">
                        <span className="button is-small is-default" onClick={() => {
                          setSnippetScreen([])
                          setSelectedV({})
                          setSelectedSnippet(null);
                        }}>
                          <TimesIcon />
                        </span>
                      </div>
                    </div>
                  </div>}
                </div>
              </foreignObject>
            </g>}
        </svg>
      </Column>
    </Columns>)
}
export default MarcacaoLatas;