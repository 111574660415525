import React, { Component } from 'react';

import { connect } from 'react-redux';
import { Link, withRouter } from "react-router-dom";
import { EnvelopeIcon } from '../../components/icons';

import { handleForInputChangeOnForm, post } from '../../utils';

class RecoveryPassForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      formData: {
        email: this.props.match.params.email || ''
      },
      isSending: 0 //0 - Não enviado, 1 - Enviando, 2 - Enviado, 3 - Erro no envio
    }
  }
  
  componentDidMount(){
    this.emailInput.focus();
  }

  _sendEmail({ email }) {
    this.setState({ isSending: 1 });
    post('auth//recovery-email', {mail:email}).then(cb => {
      this.setState({ isSending: 2 });
    }).catch(err => {
      this.setState({ isSending: 3 });
    });
  }

  _onSubmit(e) {
    e.preventDefault();
    this._sendEmail(this.state.formData);
  }

  render() {
    const { email } = this.state.formData;

    return (
      <form onSubmit={this._onSubmit.bind(this)}>
        {this.state.isSending !== 2 ? <div className="field">
          <label htmlFor="email" className="label has-text-white">Email</label>
          <div className="control has-icons-left">
            <input 
              id="email"
              className="input"
              type="email"
              placeholder="E-mail"
              value={email}
              onChange={handleForInputChangeOnForm.bind(this)}
              ref={(input) => { this.emailInput = input }}
            />
            <EnvelopeIcon className="is-small is-left"/>
          </div>
          <p className="help is-info">Enviaremos um e-mail com as instruções para recuperação de senha.</p>
        </div> : (
        <div className="notification is-primary fadeOpacity-1" style={{ marginTop: '15px' }}>
          <small>E-mail enviado com sucesso</small>
        </div>)}
        {this.state.isSending === 3 && <div className="notification is-danger">
          Erro ao enviar o e-mail tente novamente mais tarde.
        </div>}
        <div className="field is-grouped">
          {this.state.isSending !== 2 && <div className="control">
            <button className={`button is-primary ${this.state.isSending === 1 && 'is-loading'}`} type="submit">Recuperar senha</button>
          </div>}
          <div className="control">
            <Link to="/" className="button is-default">Voltar</Link>
          </div>
        </div>
      </form>);
  }
} 

function mapStateToProps(state) {
  return {
      app: state.app
  }
}

export default withRouter(connect(mapStateToProps)(RecoveryPassForm));