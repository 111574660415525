import React, { Component } from 'react';

import { connect } from 'react-redux';
import { Link, withRouter } from "react-router-dom";

import { LockIcon, EnvelopeIcon } from '../../components/icons';

import { auth } from '../../firebase';

import { handleForInputChangeOnForm } from '../../utils';

class LoginForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      formData: {
        email: '',
        password: ''
      },
      isLoggin: false,
      loginError: null
    }
  }

  componentDidMount(){
    this.emailInput.focus();
  }

  _loggin(user) {

    this.setState({ isLoggin: true }, () => {
      auth.signInWithEmailAndPassword(user.email, user.password).then(user => {
        this.setState({ isLoggin: false }, this.props.loggedinHandler);
      }).catch(err => {
        if(err.code === 'auth/wrong-password' || err.code === 'auth/invalid-email') {
          this.setState({ isLoggin: false, loginError: 'E-mail ou senha estão incorretos!' });
        } else if(err.code === 'auth/user-disabled') {
          this.setState({ isLoggin: false, loginError: 'Seu usuário foi desabilitado pelo administrador!' });
        } else {
          this.setState({ isLoggin: false, loginError: 'Este endereço de e-mail não existe!' });
        }
      });
    });
    

  }

  _handleInput(event) {
    if(this.state.loginError)
      this.setState({ loginError: null });
    
      handleForInputChangeOnForm.bind(this)(event);
  }

  _onSubmit(e) {
    e.preventDefault();
    this._loggin(this.state.formData);
  }

  render() {
    const { match } = this.props;
    const { email, password } = this.state.formData;
    
    return (<form onSubmit={this._onSubmit.bind(this)}>
        <div className="field">
          <label htmlFor="email" className="label has-text-white">Email</label>
          <div className="control has-icons-left">
            <input 
              id="email"
              className="input"
              type="email"
              placeholder="E-mail"
              value={email}
              onChange={this._handleInput.bind(this)}
              ref={(input) => { this.emailInput = input }} 
              required
            />
            <EnvelopeIcon className="is-small is-left"/>
          </div>
        </div>
        <div className="field">
          <label htmlFor="password" className="label has-text-white">Senha</label>
          <div className="control has-icons-left">
            <input 
              id="password"
              className="input"
              type="password"
              placeholder="Senha"
              value={password}
              onChange={this._handleInput.bind(this)}
              required
            />
            <LockIcon className="is-small is-left"/>
          </div>
          <p className="help is-primary">Esqueceu a senha? <Link className="is-link-primary" style={{ textDecoration: 'underscore' }} to={`${match.url}/pass-recovery/${email}`}>Clique aqui</Link></p>
        </div>
        {this.state.loginError && <div className="notification is-danger">
          <small>{this.state.loginError}</small>
        </div>}
        <div className="field is-grouped">
          <div className="control">
            <button className={`button is-primary ${this.state.isLoggin && 'is-loading'}`} type="submit">Entrar</button>
          </div>
        </div>
    </form>);
  }
} 

function mapStateToProps(state) {
  return {
      app: state.app
  }
}

export default withRouter(connect(mapStateToProps)(LoginForm));