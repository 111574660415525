import React, { Component } from 'react';

import { ClockIcon } from '../../../../../components/icons';

class HourToCount extends Component {

  _onClick = (event) => {
    event.preventDefault();
    this.props.onClick();
  }

  render() {
    const { hour } = this.props;
    // eslint-disable-next-line
    return <a href="javascript:;" onClick={this._onClick}>
      <ClockIcon />
      <span>{hour}</span>
    </a>
  }
}

export default HourToCount