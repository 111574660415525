import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import { unregister } from './registerServiceWorker';
import configureStore from './store';

import { Provider } from 'react-redux';
import { BrowserRouter as Router } from "react-router-dom";

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';

import './styles/index.css';


const store = configureStore();


ReactDOM.render(<Provider store={store} >
                  <Router>
                    <App />
                  </Router>
                </Provider>, document.getElementById('root'));
unregister();
