import React, { Component } from 'react';
import { get } from '../../../../utils';

import * as Icons from '../../../../components/icons';
import Columns, { Column } from '../../../../components/Columns';
import Modal from '../../../../components/Modal';

import JohannMoreOptions from './JohannMoreOptions';
import JohannRow from './JohannRow';
import JohannForm from './JohannForm';

class JohannListForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      toogleAdd: false,
      loading: false,
      venues: [],
      toogleModal: false,
      toogleModalAdd: false,
      modalVenueId: '',
      forms: {}
    }
  }


  _toogleModalAdd = () => {

    const reloadVenuesWhenLeave = this.state.toogleModalAdd;
    
    this.setState({ toogleModalAdd: !this.state.toogleModalAdd, modalVenueId: 0, loading: this.state.toogleModalAdd }, () => reloadVenuesWhenLeave ? this._loadVenues(): '');
  }

  componentDidMount() {
    this._reloadVenues();
  }

  _reloadVenues = () =>{
    this.setState({ loading: true }, () => {
      this._loadVenues();
    });
  } 

  _loadVenues = () => {
    const { siteId } = this.props;
    get(`admin/johann/sites/${siteId}/venues`)
      .then(resp => this.setState({ toogleAdd: false, loading: false, venues: resp.data.data }));
  }

  _toogleModalEdit = (id) => {
    this.setState({ toogleModalAdd: !this.state.toogleModalAdd, modalVenueId: id });
  }

  _toogleModal = (id) => {
    this.setState({ toogleModal: !this.state.toogleModal, modalVenueId: id, forms: {} });
  }

  _removeVenueFromList = (id) => {
    const venues = this.state.venues.filter(venue => venue.id !== id);
    this.setState({ modalVenueId: undefined, toogleModal: false, toogleAdd: false, loading: false, venues: venues });
  }

  render() {
    const { loading, venues, toogleModal, modalVenueId, toogleModalAdd } = this.state;
    const { siteId } = this.props;

    const selectedVenue = modalVenueId 
      ? venues.find(venue => venue.id === modalVenueId)
      : {};

    return (<Columns isMultiline>
      {toogleModal && <JohannMoreOptions
        tooqadmin={this.props.tooqadmin}
        siteId={siteId}
        venue={selectedVenue}
        handleRemoveVenue={this._removeVenueFromList}
        handleToogleModal={this._toogleModal} />}

      {toogleModalAdd && <Modal handleToogleModal={this._toogleModalAdd} component={<JohannForm venue={selectedVenue} toggle={this._toogleModalAdd} reloadVenues={this._loadVenues} siteId={this.props.siteId} />  } />}
      <Column style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
        <div className="button is-primary" onClick={this._toogleModalAdd}>
          <Icons.MicroChipIcon />
          <span>Novo sensor</span>
        </div>
        <div className="field has-addons is-small">
          <div className="control is-expanded">
            {/* <input name="name" className="input" value={this.state.searchTerm} type="text" placeholder="Pesquise pelo nome..." /> */}
          </div>
        </div>
      </Column>
      <Column isSize={12} style={{ height: '100%' }}>
        <table className="table is-striped is-hoverable is-fullwidth">
          <thead>
            <tr>
              <th>ID Sensor</th>
              <th>Placa</th>
              <th>Descrição</th>
              <th><abbr title="Extensão de temperatura">Extensão de temperatura</abbr></th>
              <th>Tem GPs</th>
              <th>Tem Umidade</th>
              <th>Opções</th>
            </tr>
          </thead>
          {!loading && 
          <tbody>
            {venues.map(venue => <JohannRow selectRow={this._toogleModal} selectRowEdit={this._toogleModalEdit} key={venue.id} venue={venue} siteId={this.props.siteId} />)}
          </tbody>}
        </table>
        {loading && <p className="has-text-centered subtitle is-4 "><span className="button is-loading is-white is-bordless is-very-big"></span> </p>}
        {!loading && venues.length === 0 && <p className="has-text-centered subtitle is-4 "> Não há dados cadastrados. </p>}
      </Column>
    </Columns>);
  }
}

export default (JohannListForm);