import { useState } from "react"


export const useField = (defaultValue='', defaultPlaceholder='', onChangeMiddleware=val=>val) => {
  const [ value, setValue ] = useState(defaultValue);
  const [ placeholder, setPlaceHolder ] = useState(defaultPlaceholder);

  return {
    value,
    placeholder,
    setPlaceHolder,
    setValue,
    onChange: (e) => {
      const val = onChangeMiddleware(e.target.value);
      setValue(val);
    }
  }
}


export const useMultipleReactSelect = (options=[], defaultSelected=[]) => {
  const [ value, setValues ] = useState(defaultSelected);

  return {
    options,
    value,
    onChange: (e) => {
      if(!e){
        setValues([]);
        return;
      }
      setValues(e);
    }
  }
}

export const useFieldCheckBox = (defaultChecked=false) => {
  const [ checked, setChecked ] = useState(defaultChecked);

  return {
    checked,
    setChecked,
    onChange: (e) => {
      setChecked(e.target.checked);
    }
  }
}