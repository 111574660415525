import React, { useEffect, useState, Component } from 'react';

import Columns, { Column } from '../../../../../../components/Columns';
import Card from '../../../../../../components/Card';
import { formatAsPtBr, get, getServerDate } from '../../../../../../utils';
import { format, toDate } from 'date-fns';
import CalendarRangePicker from '../../../calendar/CalendarRangePicker';
import IsLoading from '../../../../../../components/IsLoading';
import { Bar, Brush, ComposedChart, Legend, Line, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import Tooltip from 'recharts/lib/component/Tooltip';
import { CalendarAltIcon } from '../../../../../../components/icons';
import CartesianGrid from 'recharts/lib/cartesian/CartesianGrid';
import Area from 'recharts/lib/cartesian/Area';

const CalendarFilter = (props) => {
  const {
    begin,
    end,
    setBegin,
    setEnd,
    maxDate,
    isLoading=false,
    className='bottom-left-to-right'
  } = props;

  const [ toogleFilter, setToogleFilter ] = useState(false);
  const _toogleFilter = () => {
    if(isLoading) {
      return;
    }

    setToogleFilter(!toogleFilter);
  }

  const setConfirmDates = data => {
    const { startDate, endDate } = data.range;
    setBegin(format(startDate, 'YYYY-MM-DD'));
    setEnd(format(endDate, 'YYYY-MM-DD'));
    _toogleFilter();
  };

  return <div className="tooltip">
    <span
      className="tag is-light is-large cursor-pointer"
      onClick={_toogleFilter}
    >
      <CalendarAltIcon /> De <b style={{ marginLeft: 5 }}>{formatAsPtBr(begin, 'DD MMMM YYYY')}</b><span style={{ marginLeft: 5, marginRight: 5}}>até</span><b>{formatAsPtBr(end, 'DD MMMM YYYY')}</b>
    </span>
    {toogleFilter && <span className={`tooltiptext ${className}`} style={{ top: '120%' }}>
      <CalendarRangePicker
        maxDate={maxDate}
        range={{
          startDate: toDate(begin),
          endDate: toDate(end)
        }}
        close={_toogleFilter}
        onChange={setConfirmDates}
      />
    </span>}
  </div>
}


const useChart = (siteId, begin, end) => {
  const [ isLoading, setIsLoading ] = useState(false);
  const [ chart, setChart ] = useState([]);
  const [ venues, setVenues ] = useState([]);

  useEffect(() => {
    setChart([])
    setVenues([]);

    setIsLoading(true);
    get(`dashboard/foottraffic/sites/${siteId}/hourlyselloutvenues`, {
      begin: format(begin, 'YYYY-MM-DD'),
      end: format(end, 'YYYY-MM-DD')
    })
    .then(response => {
      setVenues(response.data.venues);
      setChart(response.data.data)
    })
    .then(() => setIsLoading(false))
    .catch(() => setIsLoading(false));

  }, [ siteId, begin, end ]);
  return [chart, venues, isLoading];
}

const VisitorsHourly = (props) => {
  const { siteId } = props;
  
  const [ begin, setBegin ] = useState(getServerDate(14));
  const [ end, setEnd ] = useState(getServerDate(1));
  const [ chart, venues, isLoading ] = useChart(siteId, begin, end);


  const hasSelloutVenues = venues.length;

  return <Card>
    <p className="subtitle is-4 has-text-centered">Hora a Hora</p>
    <div style={{ display: 'flex', width: '100%', justifyContent: 'center', padding: 20 }}>
      <CalendarFilter
        isLoading={isLoading}
        maxDate={getServerDate(1)}
        begin={begin}
        end={end}
        setBegin={setBegin}
        setEnd={setEnd}
      />
    </div>
    <Columns isMultiline>
      <Column isSize={12}>
        {isLoading && <div style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: 250
        }}
        >
          <IsLoading isBorderless fontSize={60} />
          Aguarde carregando...
        </div>}
        {!isLoading && <ResponsiveContainer width="100%" height={250}>
          <ComposedChart
            data={chart}
            syncId="chartId"
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: 20,
            }}
          > 
            <XAxis dataKey="infoDate" tickFormatter={(val) => formatAsPtBr(val, 'DD/MM HH:00')}  tickLine={false}/>
            <YAxis yAxisId="left" orientation="left" stroke="#343434" tickLine={false}/>
            <YAxis yAxisId="right" orientation="right" stroke="#343434" tickFormatter={(val) => `${val}%`}  tickLine={false} />
            <Legend />
            <Tooltip 
                content={<CustomToolTip />}
                isAnimationActive={false}
              />
            {chart.length > 20 && <Brush
              className="brushDisplayNone"
              startIndex={chart.length - 20}
              dataKey='infoDate'
              height={20}
              tickFormatter={(val) => ``}
              stroke="#363636"
            />}
            <Bar
              yAxisId="left"
              dataKey="siteVisitors"
              barSize={hasSelloutVenues ? 20 : 30}
              fill="#00d1b2"
              name="Visitantes Loja"
              />
            {hasSelloutVenues && <Bar
              yAxisId="left"
              dataKey="visitorsCount"
              barSize={20}
              fill="#87BFD1"
              name="Visitantes Tintas"
              />}
            {hasSelloutVenues && <Line
              animationDuration={300}
              yAxisId="right"
              type="monotone"
              dataKey="selloutVisitorsRate"
              stroke="#87BFD1"
              name="Taxa Visitantes Tintas"
              />}
          </ComposedChart>
        </ResponsiveContainer>}

        {<Column isSize={12}>
          {isLoading && <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: 250
          }}
          >
            <IsLoading isBorderless fontSize={60} />
            Aguarde carregando...
          </div>}
          {!isLoading && <ResponsiveContainer width={'100%'} height={275}>
              <ComposedChart 
                data={chart}
                syncId="chartId"
                margin={{
                  top: 20,
                  right: 20,
                  bottom: 20,
                  left: 20}}>
                <CartesianGrid strokeDasharray="3 10" />
                <XAxis dataKey="infoDate" tickFormatter={date => formatAsPtBr(date, 'DD/MM HH:00') } />
                <YAxis unit=" min" yAxisId="left" orientation="left" dataKey="visitorsMeanTime" domain={[0, 'dataMax+3']}  fill="#00d1b2" tickFormatter={label => parseInt(label, 10)}/>
                <YAxis unit=" min" yAxisId="right" orientation="right" dataKey="visitorsMeanTime" domain={[0, 'dataMax+3']}  fill="#00d1b2" tickFormatter={label => parseInt(label, 10)}/>
                {chart.length > 20 && <Brush
                  startIndex={chart.length - 20}
                  dataKey='infoDate'
                  tickFormatter={(val) => `${format(val, '')}`}
                  height={20}
                  stroke="#000000"/>}
                <Tooltip 
                  content={<CustomToolTipMeanTime />} 
                  isAnimationActive={false}
                />
                <Legend />
                <Area
                  yAxisId="left"
                  type="monotone"
                  connectNulls={true}
                  name="Tempo médio Visitantes"
                  dataKey="visitorsMeanTime"
                  stroke="#c8142d"
                  strokeWidth={2}
                  isAnimationActive={false}
                  fillOpacity={0.2}
                  fill="#c8142d"
                  activeDot={true}
                  label={props => <g transform={`translate(${props.x},${props.y})`}>
                    <text x={7} y={-10} dx={0} dy={-5} textAnchor="end" fill="#222">{parseInt(props.value, 10) > 0 ? `${parseInt(props.value, 10)}m` : '' }  {Math.round((props.value % 1) * 60)}s</text>
                  </g>}
                />
              </ComposedChart>
            </ResponsiveContainer>}
          </Column>}
      </Column>
    </Columns>
  </Card>
}

class CustomToolTip extends Component {
  render() {
    const { active, payload=[]} = this.props;

    if(active) {

      let data = { payload: {} };
      if(payload && payload.length > 0){
        data = payload[0]
      }

      return (<div className="card notification" style={{ padding: '0px 5px 0px 5px' }}>
        <p className="heading has-text-centered">
          Em {data.payload.snapDate ? format(data.payload.snapDate, "DD/MM/YYYY") : format(data.payload.infoDate, "DD/MM HH:00")}
        </p>
        <span className="heading has-text-centered" style={{ fontWeight: 'bold', marginBottom: '-2px', color: "#00d1b2" }}>
          <span>Visitante Loja</span>
        </span>
        <span className="has-text-centered" style={{display: 'block', letterSpacing: '1px', fontSize: '1.5em', marginBottom: '5px', fontWeight: 'bold', color: "#00d1b2" }}>
          <span>{data.payload.siteVisitors || 0}</span>
        </span>
        <span className="heading has-text-centered" style={{ fontWeight: 'bold', marginBottom: '-2px', color: "#87BFD1" }}>
          <span>Visitantes Tintas</span>
        </span>
        <span className="has-text-centered" style={{display: 'block', letterSpacing: '1px', fontSize: '1.5em', marginBottom: '5px', fontWeight: 'bold', color: "#87BFD1" }}>
          <span>{data.payload.visitorsCount || 0}</span>
        </span>
        <span className="heading has-text-centered" style={{ fontWeight: 'bold', marginBottom: '-2px', color: "#87BFD1" }}>
          <span>Taxa Visitantes Tintas</span>
        </span>
        <span className="has-text-centered" style={{display: 'block', letterSpacing: '1px', fontSize: '1.5em', marginBottom: '5px', fontWeight: 'bold', color: "#87BFD1" }}>
          <span>{`${data.payload.selloutVisitorsRate}%` || 0}</span>
        </span>
      </div>)
    }
    return null;
  }
}

class CustomToolTipMeanTime extends Component {
  render() {
    const { active, payload=[]} = this.props;

    if(active) {

      let data = { payload: {} };
      if(payload && payload.length > 0){
        data = payload[0]
      }

      let val = data.dataKey === 'visitorsMeanTime' ? `${parseInt(data.value, 10) > 0 ? `${parseInt(data.value, 10)}m` : '' }  ${Math.round((data.value % 1) * 60)}s` :data.value
      
      return (<div className="card notification" style={{ padding: '0px 5px 0px 5px' }}>
          <p className="heading has-text-centered">
            Em {data.payload.snapDate ? format(data.payload.snapDate, "DD/MM/YYYY") : format(data.payload.infoDate, "DD/MM HH:00")}
          </p>
          <span className="heading has-text-centered" style={{ fontWeight: 'bold', marginBottom: '-2px', color: "#c8142d" }}>
            <span>Tempo médio Visitantes</span>
          </span>
          <span className="has-text-centered" style={{display: 'block', letterSpacing: '1px', fontSize: '1.5em', marginBottom: '-5px', fontWeight: 'bold', color: data.color }}>
            <span>{val}</span>
          </span>
      </div>)
    }
    return null;
  }
}

export default VisitorsHourly;