import React, {Component} from 'react';

import _ from 'lodash';
import {connect} from 'react-redux';

import {withRouter, Redirect} from "react-router-dom";


import Page, {PageTitle} from '../../components/Page';
import Columns, {Column} from '../../components/Columns';
import { LockIcon } from "../../components/icons";

import jwtToken from 'jsonwebtoken';
import {handleForInputChangeOnForm, post} from '../../utils';
import notification from '../../utils/notification';
import { actions as appActions } from '../../state/app';

class NewPasswordForm extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      decoded:{},
      formData:{
        pass: '',
        passAgain: ''
      },
      actualData: {
        displayName: '',
        pass: '',
        passAgain: ''
      },
      loading: true,
      saving: false,
      tooglePassChange: false,
      regEx:{
        capital : /[A-Z]/,
        digit   : /[0-9]/,
        special  : /[\W]/,
        full    : /^[A-Za-z0-9\W]{6,50}$/
      }
    }
  }



  componentDidMount() {
     const split = this.props.location.pathname.split('/');
     const jwt = split[split.length - 1];

     const decoded = jwtToken.decode(jwt, 'Ttooq!7!');
     this.setState({decoded});
  }
  
  _handleSubmit(event) {
    event.preventDefault();
    const { formData, saving, decoded, passEquals } = this.state;
    
    if(saving) 
      return;

    if(!passEquals){
      if(formData.pass !== formData.passAgain) {
        notification.error('As duas senhas não conferem')
      }else{
        notification.error('As duas senhas não respeitam os requisitos de senha')
      }
    }
    

    const endSave = () => {
      notification.success('Finalizado com sucesso!');
      this.setState({ 
        saving: false,
        decoded: null,
        actualData: { ...formData, pass: '', passAgain: '' }
      })
    }

    if(passEquals) {
      this.setState({ saving: true });
      post(`auth/new-password`, {uid: decoded.uid, password: formData.pass})
        .then(endSave)
        .catch((err = {}) => {
          const message = err.code === 'auth/requires-recent-login' 
            ? 'Para sua segurança, para alterar a senha, será necesário deslogar e logar novamente na aplicação, em seguinda volte aqui para alterar a senha'
            : err.code === 'auth/weak-password' 
            ? 'A senha informada é muito fraca' : 'Não foi possível alterar a senha, tente novamente mais tarde.' ;
          notification.error(message)
          this.setState({ saving: false });
        });
    }
  }

  showMessageForDifferentPass = _.debounce(() => {
    const { pass, passAgain } = this.state.formData;
    if(!passAgain) return;

    if(pass !== passAgain) {
      this.setState({passEquals: false });
    }
    else{
      if(this.passwordValidate(pass)){
        this.setState({passEquals: true });
      }else{
        this.setState({passEquals: false });
      }
    }
      
  }, 100);

  passwordValidate(password) {
    const re = this.state.regEx
    return re.capital.test(password) && 
           re.digit.test(password) && 
           re.special.test(password) && 
           re.full.test(password);
  } 

  _passwordVerification(event) {
    handleForInputChangeOnForm.bind(this)(event, this.showMessageForDifferentPass);
  }

  render() {
    const { formData, decoded,regEx } = this.state;

    if(decoded === null){
      appActions.userLogout()
      return <Redirect to='/login' />
    }
    const capitalTest = regEx.capital.test(formData.pass);
    const digitTest = regEx.digit.test(formData.pass);
    const specialTest = regEx.special.test(formData.pass);
    const fullTest = regEx.full.test(formData.pass);
    return (
      <Page>
        <PageTitle title={(<div>
          <span style={{ marginRight: '5px' }}>Nova Senha</span> 
          <LockIcon />
        </div>)} />
        <div className="card">
          <div className="card-content">
            <Columns isMultiline>
              <Column isSize={6}>
                <form onSubmit={this._handleSubmit.bind(this)}>
                  <Columns isMultiline>
                    <Column isSize={12}>
                      <div className="field">
                        <label className="label">Nova Senha</label>
                        <div className="control has-icons-left">
                          <input className="input" type="password" placeholder="Nova Senha"
                            id="pass"
                            onChange={this._passwordVerification.bind(this)}
                            value={formData.pass}
                            required
                          />
                          <LockIcon className="is-small is-left"/>
                        </div>
                      </div>
                    </Column>
                    <Column isSize={12}>
                      <div className="field">
                        <label className="label">Digite novamente</label>
                        <div className="control has-icons-left">
                          <input className="input" type="password" placeholder="Repita a senha"
                            id="passAgain"
                            onChange={this._passwordVerification.bind(this)}
                            value={formData.passAgain}
                            required
                          />
                          <LockIcon className="is-small is-left"/>
                        </div>
                      </div>
                    </Column>
                    <Column isSize={12}>
                      <div className="field is-grouped">
                        <div className="control">
                          <button type="submit" className={`button is-primary ${this.state.saving && 'is-loading'}`}>Confirmar Nova Senha</button>
                        </div>
                      </div>
                    </Column>
                  </Columns>
                </form>
              </Column>

              <Column isSize={6}style={{borderLeft: '1px solid #cfcfcf'}}>
                <div className="field">
                  <label className="title is-5">Requisitos da senha:</label> 
                  <Column isSize={12}>
                    <Column isSize={12} style={{paddingTop: '0'}}>
                      <ul style={{listStyleType: "square"}}>
                        <li>
                          <label className={`subtitle is-6 ${!capitalTest ? 'has-text-danger': ''}	`}>Possuir pelo menos uma letra maiuscula;</label>
                        </li>
                        <li>
                          <label className={`subtitle is-6 ${!specialTest ? 'has-text-danger': ''}	`}>Possuir pelo menos um caractere especial;</label>
                        </li>
                        <li>
                          <label className={`subtitle is-6 ${!digitTest ? 'has-text-danger': ''}	`}>Possuir pelo menos um numero; </label>
                        </li>
                        <li>
                          <label className={`subtitle is-6 ${!fullTest ? 'has-text-danger': ''}	`}>Possuir de 6 a 50 caracteres;</label>
                        </li>
                      </ul>
                    </Column>
                  </Column>
                </div>
              </Column>
            </Columns>
          </div>
        </div>
      </Page>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.app.user,
    userInfo: state.app.userInfo,
    group: state.app.userGroup
  }
}
export default withRouter(connect(mapStateToProps)(NewPasswordForm));