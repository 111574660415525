import React, { Component } from 'react';

import {
  addDays,
  format
} from 'date-fns';

import {
  CalendarAltIcon, ExclamationCircleIcon
} from '../../../../components/icons';

import DatePickerFilter from './DatePickerFilter';


import { dateToDDMMYYYY, getServerDate } from '../../../../utils';

class HeatMapVenues extends Component {

  constructor(props) {
    super(props);

    const CURRDATE = addDays(getServerDate(), -1);

    this.state = {
      date: format(CURRDATE,'YYYY-MM-DD'),
      isLoading: true,
      hasHeatmap: false,
      heatmaps: []
    }
  }

  componentDidMount() {
    this.loaddata();
  }

  _changeDateFilter = () => (date) => {
    this.setState({ date }, this.loaddata);
  }

  loaddata = () => {
    this.setState({ isLoading: true }, () => {
      const { date } = this.state;
      this.props.loadHeatmaps(date)
      .then(result => {
          const { date, hasHeatmap, heatmaps } = result;
          this.setState({ isLoading: false, date, hasHeatmap, heatmaps });
        });
    });
  }

  _renderDatePickerLabel = () => {
    const { date } = this.state;
    return <div style={{ display: 'flex', justifyContent: 'center' }}>
      <span
        className="button is-large has-text-centered is-white card-footer-item is-radiusless is-shadowless is-bordless"
        style={{
          borderRight: 'none',
          maxWidth: '100%',
          justifyContent: 'center'
        }}
      >
        <CalendarAltIcon />
        <span>Heatmap em <b>{dateToDDMMYYYY(date)}</b></span>
      </span>
    </div>
  }

  render() {
    const {
      isLoading,
      date,
      hasHeatmap=false,
      heatmaps
    } = this.state;

    if(!isLoading && !hasHeatmap) {
      return <div></div>
    }

    return <div style={{ width: '100%' }} className="card">
      <header className="card-header" style={{ borderBottom: '1px solid #eee', padding: '10px', justifyContent: 'space-between' }}> 
        <div className="title is-5 is-marginless">
          Heatmap da loja
        </div>
      </header>
      
      <div className="card-content">
        <div className="tile is-ancestor">
          <div className="tile is-12 is-vertical is-parent">
            <div className="tile is-vertical is-parent has-text-centered">
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                {date 
                  && <DatePickerFilter 
                    renderLabel={this._renderDatePickerLabel}
                    className={'top-to-down-right'}
                    changeDates={this._changeDateFilter()}
                    currDate={date}
                  />}
              </div>
              {isLoading
                ? <div className="has-text-centered" style={{ height:300, paddingTop: '70px' }}>
                    <span>
                      <span className="button is-loading is-large is-bordless" />
                      <p className="subtitle is-5">Aguarde, carregando...</p>
                    </span>
                  </div>
                : <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                  {heatmaps.map(heatmap => {
                    const paths = heatmap.paths;
                    return <div key={`planta-macro-${heatmap.id}`} style={{ justifyContent: 'center', padding: '30px' }}>
                      <p className="subtitle is-4">{heatmap.description}</p>
                      <svg
                        style={{ border: '1px solid black' }}
                        width={800}
                        height={600}
                        >
                        <defs>
                          <radialGradient id="RadialGradient1">
                            <stop offset="0%" stopColor="red" />
                            <stop offset="50%" stopColor="red" />
                            <stop offset="100%" stopColor="white" stopOpacity={0}/>
                          </radialGradient>
                        </defs>
                        <image
                          x={0}
                          y={0}
                          xlinkHref={heatmap.image_src}
                          width={800}
                          height={600}
                        />
                        {
                          paths.map(({opacity, venue_id, polygon_points, rgb}) => {
                            return (
                              // <polyline
                              //   key={`path-${venue_id}`}
                              //   points={polygon_points}
                              //   fillOpacity={rgb[3]}
                              //   fill={`rgb(${rgb[0]},${rgb[1]},${rgb[2]})`}
                              // />
                              <polyline
                                key={`path-${venue_id}`}
                                points={polygon_points}
                                fillOpacity={rgb[3]}
                                fill="url(#RadialGradient1)"
                              />
                           );
                          })
                        }
                      </svg>
                      <br/>
                      {!heatmap.auditingOk && <p className="tag is-danger">
                        <ExclamationCircleIcon />
                        <span>{heatmap.info_date.split(' ')[0]} dados não confiáveis.</span>
                      </p>}
                    </div>
                  })}
                </div>}
            </div>
          </div> 
        </div>
      </div>
    </div>
  }
}

export default (HeatMapVenues);