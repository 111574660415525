import React, { useEffect, useState } from 'react';
import Modal from '../../../../components/Modal';
import Card from '../../../../components/Card';
import { TelegramIcon } from '../../../../components/icons';
import { get } from '../../../../utils';
import IsLoading from '../../../../components/IsLoading';


const  TelegramUsersModal = props => {
  const [ loading, setLoading ] = useState(false);
  const [ filterTooq, setFilterTooq ] = useState(true);
  const [ telegramUsers, setTelegramUsers ] = useState([]);

  useEffect(() => {
    setLoading(true)
    get(`admin/tooq/users?telegram=${props.group.id}`)
      .then(resp => {
        setTelegramUsers(resp.data.data)
        setLoading(false)
      })
  }, [ props.group.id ]);

  return <Modal
      handleToogleModal={props.closeModal}
      style={{ width: "90%", height: '95%' }}
      component={<Card>
        <p className="title is-4">
          <TelegramIcon /> Usuários cadastrados para receber alertas no Telegram
        </p>
        <div className='field'>
          <label className='label'>Esconder @tooqsolucoes.com.br</label>
          <input className='checkbox' type="checkbox" onChange={(e) => setFilterTooq(e.target.checked)} checked={filterTooq} />
        </div>
        <table className="table is-fullwidth">
          <thead>
            <tr>
              <th>ID</th>
              {/* <th>Chave</th> */}
              <th>Loja</th>
              <th>Nome Usuário</th>
              <th>Email</th>
            </tr>
          </thead>
          <tbody>
            {telegramUsers
            .filter(user => filterTooq ? !user.email.includes("@tooqsolucoes.com.br") : true)
            .map(user => <tr>
                <td>{user.id}</td>
                {/* <td>{user.key}</td> */}
                <td>{user.name}</td>
                <td>{user.displayName}</td>
                <td>{user.email}</td>
              </tr>)}
          </tbody>
        </table>
        {loading && <div style={{ display: 'flex', justifyContent: 'center'}}>
          <IsLoading isBorderless={true} fontSize={60} />
        </div>}
      </Card>}
    />
}
export default TelegramUsersModal 