import React, { Component } from 'react';

import {pt} from 'react-date-range/src/locale';

import { DateRangePicker } from 'react-date-range';
import Page, { PageTitle } from '../../../../components/Page';

import {TemperatureHighIcon} from '../../../../components/icons';

import withRouter from "react-router-dom/withRouter";
import { connect } from 'react-redux';

import { get, put, getServerDate } from '../../../../utils';
import SensorJohannDash from './SensorJohannDash';
import { format, subDays, toDate } from 'date-fns';
import notification from '../../../../utils/notification';

class DashboardJohannVenue extends Component {

  constructor(props) {
    super(props);

    const startDate = subDays(getServerDate(), 2);
    const endDate = getServerDate();

    this.state = {
      isloading: false,
      sensorRealtimeList: [],
      venue: {},
      data:[],
      realtimeValues: {},
      toogleReport: false,
      downloading: false,
      erroNoDownload: '',
      beginSnapDate: subDays(getServerDate(), 2),
      endSnapDate: getServerDate(),
      filter: { 
        ranges: { startDate, endDate, key: 'ranges' }
      },
      confirmReset: false
    }
  }

  _handleSelect = () => (params) => {
    const filter = { ...this.state.filter, ...params };
    this.setState({ filter });
    this.setState({beginSnapDate: format(params.ranges.startDate, "YYYY-MM-DD"), endSnapDate: format(params.ranges.endDate, "YYYY-MM-DD")})
  }

  _handleDate = (e, field) => {
    const date = e.target.value;
    //const dateFormat = new Date(date)
    //this.setState({filter: {ranges: {...this.state.filter.ranges, [field == 'beginSnapDate' ? 'startDate' : 'endDate']:  dateFormat}}})
    
    this.setState({[field]: date});
  };

  _toogleReport = () => {
    this.setState({ toogleReport: !this.state.toogleReport });
  }

  componentDidMount() {
    this._getVenue();
  }

  _getVenue() {
    const siteId = this.props.site.id;

    this.setState({ isloading: true }, () => {

      const { venueId } = this.props.match.params;
      get(`admin/johann/sites/${siteId}/venues/${venueId}`).then(resp => {
        const venue = resp.data.data;
        this.setState({ isloading: false, venue });
      });
    });
  }

  _downloadPdf = () => {
    if(this.state.downloading)
      return;

    this.setState({ downloading: true }, () => {
      const { venueId } = this.props.match.params;
      const siteId = this.props.site.id;
      const groupId = this.props.group.id;
      get('reports/johann', {
        siteId: siteId,
        venueId: [venueId],
        dateBegin: this.state.beginSnapDate, //format(this.state.filter.ranges.startDate, 'YYYY-MM-DD'),
        dateEnd: this.state.endSnapDate, //format(this.state.filter.ranges.endDate, 'YYYY-MM-DD'),
        groupId: groupId
      }).then((response) => {
        notification.success('Relatório agendado com sucesso.')
        this.setState({
          downloading: false,
          toogleReport: false,
          erroNoDownload: ''
        })
      })
      .catch(err => {
        console.log(err);
        this.setState({
          downloading: false,
          erroNoDownload: 'Não foi possível baixar o arquivo tente novamente mais tarde.'
        })
      });
    });
  };

  _resetSensor = (sensorid) => {
    put(`reports/johann/id/${sensorid}/resetSensor`)
    .then(() => {
      notification.success('Sensor resetado sucesso!')
      this.setState({confirmReset: false})
    });
  };

  render() {
    const { ranges } = this.state.filter;
    const { startDate, endDate } = ranges;
    const { isloading, downloading, venue, toogleReport, erroNoDownload, beginSnapDate, endSnapDate, confirmReset } = this.state;
    const { user } = this.props;

    // const ontem = format(subDays(getServerDate(), 1), 'DD/MM');

    return (
      <Page>
        <PageTitle
          title={<span>
            <span style={{ marginRight: '10px' }}>
              { venue && `${(venue && venue.placa + ` #` + venue.name) || 'Aguarde'}`}
            </span>
            <p className="subtitle">Temperatura <TemperatureHighIcon /></p>
          </span>}
        >
          <span className="button is-primary is-outlined" onClick={this._toogleReport}>
            <span className="icon is-small">
              <i className="far fa-file-alt"></i>
            </span>
            <span>
              Relatório
            </span>
          </span>
          {
            user.tooqAdmin && (confirmReset ?
            <div>
              <button style={{marginLeft: 5}} className='button is-danger is-outlined' onClick={() => this._resetSensor(venue.id)}>Resetar definitivamente</button>
              <button style={{marginLeft: 5}} className='button is-default' onClick={() => this.setState({confirmReset: false})}>Cancelar</button>
            </div>
            :
            <button style={{marginLeft: 5}} className='button is-danger is-outlined' onClick={() => this.setState({confirmReset: true})}>Resetar Sensor</button>)
          }
        </PageTitle>
        
        {toogleReport && <div className="modal is-active">
          <div className="modal-background" onClick={this._toogleReport}></div>
          <div className="modal-card" style={{ width: '30%', minHeight: '50%', maxHeight: '80%' }}>
            <header className="modal-card-head">
              <span className="modal-card-title">Relatório Tooq Heat</span>
              <button className="modal-close" aria-label="close" onClick={this._toogleReport}></button>
            </header>
            <section className="modal-card-body">
              {/* <span className="help has-text-centered">
                Selecione a baixo um período para análise dos dados. Estão disponiveis para download dados do dia da instalação até ontem ({ontem})
              </span> */}
              <p className="subtitle is-6 has-text-centered" style={{ marginTop: 20 }}>{format(startDate, 'DD/MM/YY')} até {format(endDate, 'DD/MM/YY')}</p>
              {/* InputDate manual */}
              <div style={{display: 'flex', justifyContent: 'center'}}>
                <input type="date" style={{marginRight: 8}} value={format(beginSnapDate, 'YYYY-MM-DD')} onChange={e => this._handleDate(e, 'beginSnapDate')} />
                Até
                <input type="date" style={{marginLeft: 8}} value={format(endSnapDate, 'YYYY-MM-DD')} onChange={e => this._handleDate(e, 'endSnapDate')} />
              </div>

              <div className="DatePickerNoWrapper" style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <DateRangePicker
                  months={1}
                  direction='horizontal'
                  minDate={toDate('2019-11-03')}
                  maxDate={getServerDate()}
                  scroll={{ enabled: false }}
                  rangeColors={['#0043ff']}
                  locale={pt}
                  ranges={[ranges]} 
                  onChange={this._handleSelect()}
                />
              </div>
            </section>
            <footer className="modal-card-foot">
              <button className={`button is-success ${downloading ? 'is-loading' : ''}`} onClick={this._downloadPdf}>
                <span className="icon is-small">
                  <i className="fas fa-file-pdf"></i>
                </span>
                <span>
                  Enfileirar Relatório
                </span>
              </button>
              <button className="button" onClick={this._toogleReport}>
                Fechar
              </button>
              {erroNoDownload && <p className="help is-danger">{erroNoDownload}</p>}
            </footer>
          </div>
        </div>}

        <div className="tile is-parent is-ancestor is-vertical margin-top-10">
          {venue.sensorid && <SensorJohannDash site={this.props.site}  isLoading={isloading} key={venue.sensorid} venue={venue}/>}
        </div>
      </Page>
    )
  }
}
function mapStateToProps(state, props) {
  return { site: props.site, group: props.group }
}

export default withRouter(connect(mapStateToProps)(DashboardJohannVenue));