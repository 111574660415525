import React, { Component } from 'react';
import { get, post, del } from '../utils/index';
import Select from 'react-select';
import { pt } from 'react-date-range/src/locale';
import { DateRangePicker } from 'react-date-range';
import { 
  subMonths,
  subDays,
  format,
  isBefore
 } from 'date-fns';

import { getServerDate } from '../../src/utils';
import { defaultStaticRanges } from '../../src/containers/board/dashboard/filter/defaults';
import Columns, { Column } from '../components/Columns';
import * as Icons from './icons';
// import _ from 'lodash';




class ModalGroupSensor extends Component {
  constructor(props) {
    super(props);

    const startDate = subDays(getServerDate(), 7);
    const endDate = subDays(getServerDate(), 1);

    this.state = {
      ranges: {
        startDate,
        endDate,
        key: 'ranges'
      },

      venues: [],
      areas: [],
      sensorSelected: [],
      loadingStartReport: false,
      
      templates: [],
      templateSelected: [],
      inputTemplateName: "",
      loadingStartTemplate: false,
      confirmDeleteTemplate: {id: "", confirm: false},
      templateSelectedId: 0
    };
  };

  _loadTemplates = () => {
      const { site, user } = this.props;

      get(`reports/johann/reporttemplate/sites/${site.id}/users/${user.id}`)
      .then(res => this.setState({templates: res.data.data}))
  };
  
  _loadAreas = () => {
    this.setState({ loading: true }, () => {
      const { site } = this.props;
      const foo1 = get(`admin/johann/sites/${site.id}/areas`);
      const foo2 = get(`admin/johann/sites/${site.id}/venues`);
      
      Promise.all([foo1, foo2])
      .then(promises => this.setState({loading: false, venues: promises[1].data.data, areas: promises[0].data.data}))
    });
  };

  componentDidMount() {
    this._loadAreas()
    this._loadTemplates()
  };

  _selectDateRange = params => {
    const { ranges } = this.state;
    ranges['startDate'] = params.ranges.startDate;
    ranges['endDate'] = params.ranges.endDate;
    this.setState({ ranges });
  };

  _onChange = (value) => {
    if(!value) {
      this.setState({sensorSelected: []})
      return;
    }

    this.setState({sensorSelected: value})
  };

  _reportsRequest = () => {
    const { site, groupId, setReportModal } = this.props;
    const { loadingStartReport=false } = this.state;

    if (loadingStartReport) {
      return;
    }

    this.setState({ loadingStartReport: true }, () => {
      get(`reports/johann`, {
        siteId: site.id,
        groupId: groupId,
        venueId: this.state.sensorSelected.map(sensor => sensor.value),
        dateBegin: format(this.state.ranges.startDate, "YYYY-MM-DD"),
        dateEnd: format(this.state.ranges.endDate, "YYYY-MM-DD"),
      })
      .then(() => setReportModal(false))
      .catch(err => this.setState({ loadingStartReport: false }));
    });
  };

  _reportTemplate = () => {
    const { site, user } = this.props;
    const { loadingStartTemplate=false } = this.state;

    if(loadingStartTemplate) {
      return;
    };

    this.setState({loadingStartTemplate: true}, () => {
      post('reports/johann/reporttemplate', {
        siteId: site.id,
        userId: user.id,
        name: this.state.inputTemplateName,
        dayBegin: format(this.state.ranges.startDate, "DD"),
        dayEnd: format(this.state.ranges.endDate, "DD"),
        venueIds: this.state.sensorSelected.map(venue => venue.value)
      }).then(() => {
        this.setState({ loadingStartTemplate: false, inputTemplateName: "",  sensorSelected: []}, () => this._loadTemplates());
      })
    });
  };

  _selectTemplate = (template) => {
    const {dayBegin, dayEnd, venueIds} = template;
    const { ranges, venues } = this.state;

    const sensorFiltered = venues.filter((v) => venueIds.some((vid)=> v.id === vid));
    const sensorsTemplate = sensorFiltered.map(sensor => {
      return {
        value: sensor.id,
        label: `#${sensor.name} | ${sensor.placa} (${sensor.sensorid})`
      }
    });
    
    const dayEndInt = parseInt(dayEnd);
    const dayBeginInt = parseInt(dayBegin);

    const dateNow = format(Date.now(), 'YYYY-MM');

    ranges['endDate'] = `${dateNow}-${dayEnd}`;
    ranges['startDate'] = `${dateNow}-${dayBegin}`;
    
    if (dayBeginInt > dayEndInt) {
      const datePastMonth = format(subMonths(Date.now(), 1), 'YYYY-MM');  
      ranges['startDate'] = `${datePastMonth}-${dayBegin}`;    
    }

    this.setState({ ranges, inputTemplateName: template.name, sensorSelected: sensorsTemplate, templateSelectedId: template.id});

  };

  _deleteTemplate = (template) => {
    const { site, user } = this.props;

    del(`reports/johann/reporttemplate/sites/${site.id}/users/${user.id}/templates/${template.id}`)
    .then(() => this.setState({confirmDeleteTemplate: {id: template.id, confirm: false}}), this._loadTemplates())
  };

  _clearFields = () => {
    this.setState({ templateSelectedId: 0, inputTemplateName: "", sensorSelected: []});
  };

  render() {
    const today = new Date();
    const { loading, ranges, venues, sensorSelected, loadingStartReport, loadingStartTemplate, inputTemplateName, templates, confirmDeleteTemplate, templateSelectedId } = this.state;
    const { setReportModal } = this.props;
    
    const sensorsToSelect = venues.map(sensor => {
      return {
        value: sensor.id,
        label: `#${sensor.name} | ${sensor.placa} (${sensor.sensorid})`
      }
    });

    const hasDaysAfterToday = isBefore(today, this.state.ranges.startDate) || isBefore(today, this.state.ranges.endDate);

    const templatesMapped = templates.map(template => {
      return <tr style={{backgroundColor: templateSelectedId === template.id ? '#eee' : ''}}>
        <td>
          {template.name}
        </td>
        <td>
          <div className="buttons">
            {<button type="button" onClick={() => this._selectTemplate(template)} className="button is-small is-primary"> 
              Selecionar
            </button>}
            {confirmDeleteTemplate.confirm && confirmDeleteTemplate.id === template.id && 
            <button type="button" className='button is-default is-small' onClick={() => this.setState({confirmDeleteTemplate: {id: template.id, confirm: false}})}>
              Cancelar
            </button>}
            {confirmDeleteTemplate.confirm && confirmDeleteTemplate.id === template.id ?
            <button type="button" className='button is-danger is-small' onClick={() => this._deleteTemplate(template)}>
              Excluir
            </button>
            :
            <button 
              type="button"
              onClick={() => this.setState({confirmDeleteTemplate: {id: template.id, confirm: true}})} 
              className='button is-danger is-outlined is-small'>
              <Icons.TrashIcon />
            </button>}
          </div>
        </td>
      </tr>
    });

    const styles = {
      multiValue: (base, state) => {
        return state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base;
      },
      multiValueLabel: (base, state) => {
        return state.data.isFixed
          ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
          : base;
      },
      multiValueRemove: (base, state) => {
        return state.data.isFixed ? { ...base, display: 'none' } : base;
      }
    };

    return (
      <div className="modal is-active">
        <div className="modal-background"></div>
        <div className="modal-card" style={{height: '90%'}}>
          <header className="modal-card-head">
            <p className="modal-card-title">Relatório</p>
            <button className="delete" aria-label="close" onClick={() => setReportModal(false)}></button>
          </header>
          <section className="modal-card-body">
            <Columns isMultiline>
              <Column style={{border: '1px solid #eee', borderRadius: 25}}>
                <p className="title is-5">Lista de Templates</p>
                <table className='table is-fullwidth'>
                  <thead>
                    <td>Nome</td>
                    <td>Opções</td>
                  </thead>
                  {!loading && <tbody>
                    {templatesMapped}
                  </tbody>}
                </table>
              </Column>
              <Column style={{border: '1px solid #eee', borderRadius: 25, margin: 5}}>
                <p className="title is-5" style={{display: 'flex', justifyContent: 'center', margin: 5}} >Defina o período</p>
                <DateRangePicker
                  months={1}
                  direction='horizontal'
                  maxDate={getServerDate()}
                  scroll={{ enabled: false }}
                  rangeColors={['#363740']}
                  dragSelectionEnabled={true}
                  locale={pt}
                  ranges={[ranges]}
                  inputRanges={[]}
                  showPreview={false}
                  staticRanges={defaultStaticRanges}
                  onChange={this._selectDateRange}
                />
              </Column>
              <Column style={{border: '1px solid #eee', borderRadius: 25, margin: 5}}>
                <div className="field has-text-left">
                  <div className="control is-expanded">
                    <div className="control is-expanded" style={{width: '90%'}}>
                      <label className="label">Nome do Template:</label>
                      <input 
                        className="input" 
                        type="text" 
                        placeholder="Digite um nome..."
                        onChange={(e) => this.setState({inputTemplateName: e.target.value})}
                        value={inputTemplateName}
                      />
                      <hr />
                      <label className="label">Selecionar Sensores:</label>
                      <Select  
                        value={sensorSelected}
                        options={sensorsToSelect}
                        closeMenuOnSelect={false}
                        onChange={this._onChange} 
                        styles={styles}
                        isMulti 
                        name="colors"
                        placeholder="Sensores"
                        className="basic-multi-select"
                        classNamePrefix="select"
                      />
                    </div>
                  </div>
                </div>
              </Column>
            </Columns>
          </section>
          <footer className="modal-card-foot" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'baseline'}}>
            <div className='buttons'>
              <button
                className={`button is-success ${loadingStartReport ? 'is-loading' : ''}`}
                onClick={() => this._reportsRequest()}
                disabled={sensorSelected.length===0 || hasDaysAfterToday}>
                Gerar relatórios
              </button>
              <button className="button" onClick={() => setReportModal(false)}>Cancel</button>
            </div>
            <div className='buttons'>
              <button className='button is-default' onClick={() => this._clearFields()}>
                Limpar campos
              </button>
              <button className={`button is-success ${loadingStartTemplate ? 'is-loading' : ''}`} onClick={() => this._reportTemplate()} disabled={sensorSelected.length===0 || inputTemplateName.length===0}>
                Salvar novo template
              </button>
            </div>
          </footer>
        </div>
      </div>
    );
  }
};

export default ModalGroupSensor
