import React, { Component } from 'react';

import {
  addDays, format
} from 'date-fns';
import { BranchHistoryIcon } from '../../../../../components/icons';
import VisitorsAndPassersBy from './VisitorsAndPassersBy';
import { getServerDate } from '../../../../../utils';

class VisitorsRateChart extends Component {

  constructor(props) {
    super(props);
    
    const CURRDATE = addDays(getServerDate(), -1);

    this.state = {
      isLoading: false,
      typeChart: 'DAILY',
      filterRanges: {
        startDate: addDays(CURRDATE, -6),
        endDate: CURRDATE,
        key: 'selectionRange1'
      },
      dash: {
        header: {},
        data: []
      }
    }
  }

  _handleFilterRangeDates = () => (ranges) => {
    this.setState({ filterRanges: ranges }, () => {
      this.loadData();
    });
  }

  loadData = () => {
    this.setState({ isLoading: true }, () => {
      const { typeChart, filterRanges } = this.state;
      this.props.loadPeopleCount(typeChart, filterRanges.startDate, filterRanges.endDate)
        .then(result => {
          this.setState({ isLoading: false, dash: result });
        });
    });
  }

  _showMore = () => {
    const { filterRanges, dash, isLoading } = this.state;
    const {shopId, dashVideo} = this.props;
    if(isLoading) {
      return;
    }

    this.props.showMore(<VisitorsAndPassersBy
        filterRanges={filterRanges}
        dash={dash}
        site={this.props.site}
        shopId={shopId}
        loadPeopleCount={this.props.loadPeopleCount}
        dashVideo={dashVideo}
      />);
  }

  componentDidMount() {
    this.loadData();
  }

  render() {
    const { isLoading, dash } = this.state;

    const { header } = dash;
    return <div className="card notification is-paddingless is-link">
      <div className="card-content"> 
        <div className="tile is-ancestor">
          <div className="tile is-6 is-vertical is-parent">
            <div className="subtitle is-6">Taxa de visitantes {header.info_date ? format(header.info_date, 'DD/MM/YY') : '...'}</div>
            {/* <div className="subtitle is-4"></div> */}
            <div className="tile is-vertical is-parent has-text-centered">
              {isLoading 
                ? <span className="button is-large is-loading is-link is-bordless"/> 
                : (<div className="title" style={{ fontSize: '100px' }}>
                    {header.conversionRate || header.visitorsRate || 0}
                    <span style={{ fontSize: '15px' }}>%</span>
                  </div>)}
            </div>
          </div>
          <div className="tile is-6 is-vertical is-parent has-text-centered">
            <div className="tile" style={{ justifyContent: 'center', alignItems: 'center' }}>
              <div className="has-text-centered">
                <div className="heading has-text-wieght-bold">
                  Visitantes vs Passantes
                </div>
                <div className="title">
                  {isLoading
                    ? <progress style={{ height: '30px' }} className="progress" max="100"></progress>
                    : <progress style={{ height: '30px' }} className="progress" value={header.visitorsCount} max={header.passersByCount || 100}>90%</progress>}
                </div>
                <div className="subtitle">
                  {header.visitorsCount} de {header.passersByCount}
                </div>
              </div>
            </div>
          </div>
        </div>  
      </div>
      <footer className="card-footer">
        <span className="button is-link card-footer-item is-bordless" style={{ borderRight: 'none' }} onClick={this._showMore}>
          <BranchHistoryIcon />
          <span>
            Ver histórico completo
          </span>
          {/* <CaretRightIcon /> */}
        </span>
        <span className="card-footer-item" style={{ borderRight: 'none' }}>
        </span>
      </footer>
    </div>
    
  }
}

export default (VisitorsRateChart);