import React, { Component } from 'react';
import _ from 'lodash';

import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';

import {
  addDays,
  format,
  compareDesc,
  subMonths
} from 'date-fns';

import {
  pt
} from 'date-fns/locale';

import {
  get, getServerDate
} from '../../../../../utils';

import Page from '../../../../../components/Page';
import Columns, { Column } from '../../../../../components/Columns';
import {
  PerfomanceAltIcon,
  PlusCircleIcon
} from '../../../../../components/icons';

import IncluirPeriodoPersonalizado from './IncluirPeriodoPersonalizado';
import PerformanceTableDraggable from './PerformanceTableDraggable';
import CompareIndices from './CompareIndices';

const mapDataToTable = (group, isPersonalized=false) => (mean) => {
  const {
    info_date,
    max,
    min,
    mid,
    data,
    passersByCount,
    visitorsCount,
    totalSales,
    totalReturns,
    visitorsRate,
    salesRate,
    returnsRate
  } = mean;

  const result = {
    info_date,
    isPersonalized,
    visitors: {
      minCount: min.minVisitors,
      minRate: min.minVisitorsRate,
      maxCount: max.maxVisitors,
      maxRate: max.maxVisitorsRate,
      midCount: mid.midVisitors,
      midRate: mid.midVisitorsRate
    },
    passersBy: {
      minCount: min.minPassersBy,
      maxCount: max.maxPassersBy,
      midCount: mid.midPassersBy
    },
    sales: {
      minCount: min.minSales,
      minRate: min.minSalesRate,
      maxCount: max.maxSales,
      maxRate: max.maxSalesRate,
      midCount: mid.midSales,
      midRate: mid.midSalesRate
    }, 
    returns: {
      minCount: min.minReturns,
      minRate: min.minReturnsRate,
      maxCount: max.maxReturns,
      maxRate: max.maxReturnsRate,
      midCount: mid.midReturns,
      midRate: mid.midReturnsRate
    }
  }

  const cels = data
  .sort((a, b) => a.nickName.localeCompare(b.nickName))
  .map(cel => {
    const { siteId, name, nickName, passersByCount, visitorsCount, totalSales, totalReturns, visitorsRate, salesRate, returnsRate } = cel;
    return {
      siteId,
      name,
      nickName,
      passersBy: {
        count: passersByCount
      },
      visitors: {
        count: visitorsCount,
        rate: visitorsRate
      },
      sales: {
        count: totalSales,
        rate: salesRate
      },
      returns: {
        count: totalReturns,
        rate: returnsRate
      }
    }
  });

  return {
    ...result,
    cels: [
      {
        name: "CIA",
        nickName: "CIA",
        passersBy: {
          count: passersByCount
        },
        visitors: {
          count: visitorsCount,
          rate: visitorsRate
        },
        sales: {
          count: totalSales,
          rate: salesRate
        },
        returns: {
          count: totalReturns,
          rate: returnsRate
        }
      },
      ...cels
    ]
  };
};

class PerformanceSites extends Component {

  constructor(props) {
    super(props);

    const CURRDATE = addDays(getServerDate(), -1);

    this.state = {
      filterRanges: {
        startDate: addDays(CURRDATE, -13),
        endDate: CURRDATE,
        key: 'filterRanges'
      },
      isLoading: false,
      toogleModal: false,
      tableData: [],
      tableDataCompare: null,
      indiceBeingDragged: null,
      isLoadingMore: false,
      loadedMore: false,
      showPassersBy: false,
      showLoyalty: false
    }
  }

  componentDidMount() {
    this.setState({ isLoading: true }, () => {
      const { group } = this.props;
      const currentDate = format(getServerDate(), 'YYYY-MM-01');
      const threeMonthsBefore = format(subMonths(getServerDate(), 4), 'YYYY-MM-01');
      get(`dashboard/foottraffic/groups/${group.id}/performance?type=MONTHLY&begin=${threeMonthsBefore}&end=${currentDate}`)
        .then(resp => {
          this._setTableData(resp.data.data, () => this.setState({ isLoading: false }))
        });
    });
  }

  _loadMore = () => {
    this.setState({ isLoadingMore: true }, () => {
      const { group } = this.props;
      const currentDate = format(subMonths(getServerDate(), 5), 'YYYY-MM-01');
      const threeMonthsBefore = format(subMonths(getServerDate(), 16), 'YYYY-MM-01');
      get(`dashboard/foottraffic/groups/${group.id}/performance?type=MONTHLY&begin=${threeMonthsBefore}&end=${currentDate}`)
        .then(resp => {
          const { tableData } = this.state;
          const tableAndCels = resp.data.data.map(mapDataToTable(this.props.group));
          const sortedArray = tableAndCels.sort((a, b) => compareDesc(a.info_date, b.info_date));
          this.setState({ loadedMore: true, isLoadingMore: false, tableData: [...tableData, ...sortedArray] });
        });
    });
  }

  _showPassersBy = () => {
    this.setState({ showPassersBy: !this.state.showPassersBy });
  }

  _showLoyalty = () => {
    this.setState({ showLoyalty: !this.state.showLoyalty });
  }

  _handleItemsDragged = (index1, index2) => {
    const { tableData } = this.state;

    const mapDataToTableCompare = {
      indice1: tableData.find(table => table.info_date === index1),
      indice2: tableData.find(table => table.info_date === index2)
    }

    const indice1DateLabel = mapDataToTableCompare.indice1.isPersonalized ?
      mapDataToTableCompare.indice1.info_date
      : format(mapDataToTableCompare.indice1.info_date, 'MMMM', {locale: pt}).substring(0, 3);

    const indice2DateLabel = mapDataToTableCompare.indice2.isPersonalized ?
      mapDataToTableCompare.indice2.info_date
      : format(mapDataToTableCompare.indice2.info_date, 'MMMM', {locale: pt}).substring(0, 3);

    const calcVariacao = (val1, val2) => {
      if(val1 > val2) {
        return Math.round(((val1 / val2) * 100) - 100);
      } else {
        return Math.round(((val1 / val2) * 100) - 100);
      }
    }

    const variance = {
      info_date: `${indice1DateLabel} vs ${indice2DateLabel}`,
      visitors: {
        minCount: 0,
        minVariance: 0,
        minRate: 0,
        maxCount: 0,
        maxVariance: 0,
        maxRate: 0,
        midCount: 0,
        midVariance: 0,
        midRate: 0
      },
      passersBy: {
        minCount: 0,
        minVariance: 0,
        maxCount: 0,
        maxVariance: 0,
        midCount: 0,
        midVariance: 0
      },
      sales: {
        minCount: 0,
        minVariance: 0,
        minRate: 0,
        maxCount: 0,
        maxVariance: 0,
        maxRate: 0,
        midCount: 0,
        midVariance: 0,
        midRate: 0
      }, 
      returns: {
        minCount: 0,
        minVariance: 0,
        minRate: 0,
        maxCount: 0,
        maxVariance: 0,
        maxRate: 0,
        midCount: 0,
        midVariance: 0,
        midRate: 0
      }
    }


    const cels = mapDataToTableCompare.indice1.cels
    .map((indice, i) => {
      const indice2 = mapDataToTableCompare.indice2.cels.slice(i, i+1)[0];
      const passersBecomeNone = indice.passersBy.count === 0;
      const visitorsBecomeNone = indice.visitors.count === 0;
      const salesBecomeNone = indice.visitors.count === 0;
      const returnsBecomeNone = indice.returns.count === 0;

      const passersWereNone = indice2.passersBy.count === 0;
      const visitorsWereNone = indice2.visitors.count === 0;
      const salesWereNone = indice2.visitors.count === 0;
      const returnsWereNone = indice2.returns.count === 0;

      const passersByVariance = calcVariacao(indice.passersBy.count, indice2.passersBy.count);
      const visitorsVariance = calcVariacao(indice.visitors.count, indice2.visitors.count);
      const salesVariance = calcVariacao(indice.sales.count, indice2.sales.count);
      const returnsVariance = calcVariacao(indice.returns.count, indice2.returns.count);

      return {
        siteId: indice.siteId,
        name: indice.name,
        nickName: indice.nickName,
        passersBy: {
          becomeNone: passersBecomeNone,
          wereNone: passersWereNone,
          variance: passersByVariance,  
          count: indice.passersBy.count - indice2.passersBy.count
        },
        visitors: {
          becomeNone: visitorsBecomeNone,
          wereNone: visitorsWereNone,
          variance: visitorsVariance,
          count: indice.visitors.count - indice2.visitors.count,
          rate: indice.visitors.rate - indice2.visitors.rate
        },
        sales: {
          becomeNone: salesBecomeNone,
          wereNone: salesWereNone,
          variance: salesVariance,
          count: indice.sales.count - indice2.sales.count,
          rate: indice.sales.rate - indice2.sales.rate
        },
        returns: {
          becomeNone: returnsBecomeNone,
          wereNone: returnsWereNone,
          variance: returnsVariance,
          count: indice.returns.count - indice2.returns.count,
          rate: indice.returns.rate - indice2.returns.rate
        }
      };
    });

    const minBy = (dataset, key, defaultValue={}) => {
      const result = { ...defaultValue }
      const minFound =_.minBy(dataset, key)
      return { ...result, ...minFound };
    }

    const maxBy = (dataset, key, defaultValue={}) => {
      const result = { ...defaultValue }
      const maxFound =_.maxBy(dataset, key)
      return { ...result, ...maxFound };
    }

    const dataSetFiltered = cels.filter(cel => !cel.visitors.becomeNone && !cel.visitors.wereNone);
    const defaultValue = {
      passersBy: {
        variance: 0
      },
      visitors: {
        rate: 0
      },
      sales: {
        rate: 0
      },
      returns: {
        rate: 0
      }
    }

    variance.passersBy.minVariance = minBy(dataSetFiltered, 'passersBy.variance', defaultValue).passersBy.variance;
    variance.passersBy.maxVariance = maxBy(dataSetFiltered, 'passersBy.variance', defaultValue).passersBy.variance;
    variance.visitors.minRate = minBy(dataSetFiltered, 'visitors.rate', defaultValue).visitors.rate;
    variance.visitors.maxRate = maxBy(dataSetFiltered, 'visitors.rate', defaultValue).visitors.rate;
    variance.sales.minRate = minBy(dataSetFiltered, 'sales.rate', defaultValue).sales.rate;
    variance.sales.maxRate = maxBy(dataSetFiltered, 'sales.rate', defaultValue).sales.rate;
    
    variance.visitors.minVariance = minBy(dataSetFiltered, 'visitors.variance', defaultValue).visitors.variance;
    variance.visitors.maxVariance = maxBy(dataSetFiltered, 'visitors.variance', defaultValue).visitors.variance;
    variance.sales.minVariance = minBy(dataSetFiltered, 'sales.variance', defaultValue).sales.variance;
    variance.sales.maxVariance = maxBy(dataSetFiltered, 'sales.variance', defaultValue).sales.variance;

    variance.returns.minRate = minBy(dataSetFiltered, 'returns.rate', defaultValue).returns.rate;
    variance.returns.maxRate = maxBy(dataSetFiltered, 'returns.rate', defaultValue).returns.rate;

    variance.passersBy.midVariance = Math.round(((variance.passersBy.maxVariance - variance.passersBy.minVariance) / 2) + variance.passersBy.minVariance);
    variance.visitors.midRate = Math.round(((variance.visitors.maxRate - variance.visitors.minRate) / 2) + variance.visitors.minRate);
    variance.sales.midRate = Math.round(((variance.sales.maxRate - variance.sales.minRate) / 2) + variance.sales.minRate);
    variance.returns.midRate = Math.round(((variance.returns.maxRate - variance.returns.minRate) / 2) + variance.returns.minRate);

    mapDataToTableCompare['variance'] = {
      ...variance, cels
    };

    this.setState({ tableDataCompare: mapDataToTableCompare });
  }

  _handleCloseDataCompare = () => {
    this.setState({ tableDataCompare: null });
  }

  _handleEndDragWithNoDrop = () => {
  }

  _setTableData = (tableData, cb) => {
    const tableAndCels = tableData.map(mapDataToTable(this.props.group));
    const sortedArray = tableAndCels.sort((a, b) => compareDesc(a.info_date, b.info_date));
    this.setState({ tableData: sortedArray }, cb);
  }

  _handleSelectCalendar = (params) => {
    const filterRanges = { ...params.filterRanges };
    this.setState({ filterRanges });
  }

  _toogleModal = () => {
    this.setState({ toogleModal: !this.state.toogleModal });
  }

  _includeTable = (table) => {
    const { tableData } = this.state;
    this.setState({
      tableData: [ mapDataToTable(this.props.group, true)(table), ...tableData ]
    });
  }

  _removeIndexFromList = (infoDate) => {
    const { tableData } = this.state;

    this.setState({
      tableData: tableData.filter((data, i) => data.info_date !== infoDate)
    });
  }

  render() {
    const {
      isLoadingMore,
      tableData,
      isLoading,
      toogleModal,
      filterRanges,
      tableDataCompare,
      loadedMore,
    } = this.state;

    const { group } = this.props;
  
    return (<Page>
      <Columns isMultiline style={{ width: '100%' }}>
        <Column isSize={12}>
          <p className="title">Performance das lojas</p>
          <p className="subtitle"><PerfomanceAltIcon /> Analise a performance das lojas em diferentes períodos</p>
        </Column>

        {toogleModal && <IncluirPeriodoPersonalizado
          filterRanges={filterRanges}
          group={group}
          dateChanged={this._handleSelectCalendar}
          toogleModal={this._toogleModal}
          includeTable={this._includeTable}
        />}

        {tableDataCompare && <CompareIndices
          indices={tableDataCompare}
          toogleModal={this._handleCloseDataCompare}
          showLoyalty={this.state.showLoyalty}
          showPassersBy={this.state.showPassersBy}
        />}

        <Column isSize={12}>
          <div className="card notification cursor-pointer isClickable" onClick={this._toogleModal}>
            <p className="subtitle is-5 has-text-centered">
              <span>
                <PlusCircleIcon className="has-text-primary" />
              </span>
              <span>Incluir período personalizado</span>
            </p>
          </div>
        </Column>
        <Column isSize={2}>
          <div className="field">
            <input
              id="switchExample"
              type="checkbox"
              name="switchExample"
              className="switch"
              onChange={this._showPassersBy}
              checked={this.state.showPassersBy}
            />
            <label htmlFor="switchExample">Visualizar Passantes</label>
          </div>
        </Column>

        {isLoading && <Column isSize={12}>
          <div className="card notification is-light" style={{ height: 200, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>  
            <span className="button is-loading is-bordless is-light is-large"></span>
            <p className="subtitle is-4 has-text-centered">Aguarde carregando...</p>
          </div>
        </Column>}

        {!isLoading && tableData && <Column isSize={12}>
          {tableData.map((data, index) =>
            <PerformanceTableDraggable
              key={data.info_date}
              table={data}
              showPassersBy={this.state.showPassersBy}
              showLoyalty={this.state.showLoyalty}
              tableNumber={data.info_date}
              onClickToRemove={this._removeIndexFromList}
              handleDnd={this._handleItemsDragged}
              handleDragWithNoDrop={this._handleEndDragWithNoDrop}
            />)}
        </Column>}
        {!loadedMore && <Column isSize={12} style={{ display: 'flex', justifyContent: 'center' }}>
          <div className={`button is-primary is-outlined ${isLoadingMore && "is-loading"}`} onClick={this._loadMore}>  
            Carregar mais 12 meses
          </div>
        </Column>}
      </Columns>
    </Page>)
  }
}

const mapStateToProps = (state, props) => {
  const currentGroup = state.sites.currentGroup;
  const sites = _.toArray({ ...state.sites.all })
    .filter(site => site.group.id === currentGroup)
    .filter(site => site.modules.foottraffic && site.foottrafficData)
    .map(site => {
      return {
        id: site.id,
        name: site.name,
        nickName: site.nickName,
        group: site.group,
        permission: site.userPermissions.permission,
        modules: site.modules
      }
    });

  return { sites }
}

export default withRouter(connect(mapStateToProps, {})(PerformanceSites));