
import React, { Component } from 'react';

class GeralShopCard extends Component {
  render() {
    const { day, visitorsRate, salesRate, type } = this.props;

    if((visitorsRate === null || visitorsRate === undefined) && (salesRate === null || salesRate === undefined))
      return <div className="card notification has-text-centered" style={{ padding: 8, backgroundColor: '#eee' }}>
        <p className={`tag ${type} is-normal has-text-weight-bold`} style={{ width: '100%', backgroundColor: '#d5d5d5' }}>Últimos {day} dias</p>    
        <p style={{ marginTop: 15 }} className="help heading">visitantes</p>
        <p className="subtitle is-5">nenhum</p>
        <p style={{ marginTop: 15 }} className="help heading">vendas</p>
        <p className="subtitle is-5">nenhum</p>
      </div>

    return <div className="card notification has-text-centered is-white" style={{ padding: 8 }}>
      <p className={`tag ${type} is-normal has-text-weight-bold`} style={{ width: '100%' }}>Últimos {day} dias</p>    
      <p style={{ marginTop: 15 }} className="help heading">visitantes</p>
      <p className="subtitle is-3 heading">{visitorsRate}%</p>
      <p style={{ marginTop: 15 }} className="help heading">vendas</p>
      <p className="subtitle is-3 heading">{salesRate}%</p>
    </div>
  }
}

export default GeralShopCard;