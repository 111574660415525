const STATUS_AUDITORIA = {
  NAO_INICIADO: "0",
  FINALIZADO: "1",
  DESCONSIDERAR: "2",
  EM_ANDAMENTO: "3"
}

const TIPOS_AUDITORIA = {
  VISITORS: "visitors",
  PASSERSBY: "passersby",
  VENUE: "venue",
  PRATELEIRA: "shelf"
}

export {
  STATUS_AUDITORIA,
  TIPOS_AUDITORIA
};