import React, { Component } from 'react';

import _ from 'lodash';
import GeralShopCard from './GeralShopCard';
import Columns, { Column } from '../../../../../../components/Columns';

const MOCKED_DATA = {
  "7": {
    "visitors": 0,
    "passersby": 0,
    "sales": 0,
    "visitorsRate": null,
    "salesRate": null
  },
  "15": {
    "visitors": 0,
    "passersby": 0,
    "sales": 0,
    "visitorsRate": null,
    "salesRate": null
  },
  "30": {
    "visitors": 0,
    "passersby": 0,
    "sales": 0,
    "visitorsRate": null,
    "salesRate": null
  },
  "60": {
    "visitors": 0,
    "passersby": 0,
    "sales": 0,
    "visitorsRate": null,
    "salesRate": null
  },
  "90": {
    "visitors": 0,
    "passersby": 0,
    "sales": 0,
    "visitorsRate": null,
    "salesRate": null
  }
}

class GeralShopBox extends Component {
  render() {
    const { periods=MOCKED_DATA, isPrimary, justify} = this.props;

    const type = isPrimary ? 'is-primary' : 'is-link';

    const periodsRendered = _
      .map(periods, (val, key) => ({ ...val, day: key }));

    return <Columns isGapless style={{ justifyContent: justify }}>
      {periodsRendered.map(period => <Column key={`${type}-${period.day}`} isSize={2} style={{ margin: 3 }}>
        <GeralShopCard type={type} day={period.day} visitorsRate={period.visitorsRate} salesRate={period.salesRate} />
      </Column>)}
    </Columns>
  }
}

export default GeralShopBox;