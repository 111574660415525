import React, { useEffect, useState, Component } from 'react';

import _ from 'lodash';
import Columns, { Column } from '../../../../../../components/Columns';
import Card from '../../../../../../components/Card';
import { CalendarAltIcon, MapMarkerAltIcon, PercentageIcon, SalesIcon, WalkingIcon } from '../../../../../../components/icons';
import { formatAsPtBr, get, getServerDate } from '../../../../../../utils';
import { format, toDate } from 'date-fns';
import CalendarRangePicker from '../../../calendar/CalendarRangePicker';
import IsLoading from '../../../../../../components/IsLoading';
import { Bar, Brush, ComposedChart, Legend, Line, ResponsiveContainer, XAxis, YAxis, Area, CartesianGrid } from 'recharts';
import Tooltip from 'recharts/lib/component/Tooltip';

const Indice = (props) => {
  const { title, icon, color, value, isLoading } = props;
  return <Card
    style={{ borderTopColor: color, cursor: 'default', backgroundColor: color, color: '#ffffff' }}
  // onClick={(e) => this.selectIndice('passersByCount', e)}
  >
    <div className="has-text-centered" style={{ width: '100%' }}>
      <span className="heading" style={{ marginBottom: '0px' }}>
        <span>{title}</span>
        {icon}
      </span>
      {isLoading && <IsLoading isBorderless backgroundColor={color} />}
      {!isLoading && <span className="subtitle is-1">
        <span>{value}</span>
      </span>}
    </div>
  </Card>
}


const CalendarFilter = (props) => {
  const {
    begin,
    end,
    setBegin,
    setEnd,
    maxDate,
    isLoading = false,
    className = 'bottom-left-to-right'
  } = props;

  const [toogleFilter, setToogleFilter] = useState(false);
  const _toogleFilter = () => {
    if (isLoading) {
      return;
    }

    setToogleFilter(!toogleFilter);
  }

  const setConfirmDates = data => {
    const { startDate, endDate } = data.range;
    setBegin(format(startDate, 'YYYY-MM-DD'));
    setEnd(format(endDate, 'YYYY-MM-DD'));
    _toogleFilter();
  };

  return <div className="tooltip">
    <span
      className="tag is-primary is-light is-large cursor-pointer"
      onClick={_toogleFilter}
    >
      <CalendarAltIcon /> De <b style={{ marginLeft: 5 }}>{formatAsPtBr(begin, 'DD MMMM YYYY')}</b><span style={{ marginLeft: 5, marginRight: 5 }}>até</span><b>{formatAsPtBr(end, 'DD MMMM YYYY')}</b>
    </span>
    {toogleFilter && <span className={`tooltiptext ${className}`} style={{ top: '120%' }}>
      <CalendarRangePicker
        maxDate={maxDate}
        range={{
          startDate: toDate(begin),
          endDate: toDate(end)
        }}
        close={_toogleFilter}
        onChange={setConfirmDates}
      />
    </span>}
  </div>
}

const useDataChart = (siteId, begin, end) => {
  const [isLoading, setIsLoading] = useState(false);
  const [chart, setChart] = useState([]);
  const [chartHeader, setChartHeader] = useState({
    siteVisitors: 0,
    selloutVisitors: 0,
    selloutVisitorsRate: 0,
    selloutTickets: 0,
    selloutTicketsRate: 0,
    hasSelloutVenues: false,
    netValue: 0,
    averageTicket: 0
  });
  const [visitorsAvgTime, setVisitorsAvgTime] = useState({})

  useEffect(() => {
    setIsLoading(true);

    const selloutPromise = get(`dashboard/suvinil/sites/${siteId}/sellout`, {
      begin: format(begin, 'YYYY-MM-DD'),
      end: format(end, 'YYYY-MM-DD')
    });

    const visitorsPromise = get(`dashboard/foottraffic/sites/${siteId}/dailyvenues`, {
      begin: format(begin, 'YYYY-MM-DD'),
      end: format(end, 'YYYY-MM-DD'),
      isSellout: true
    });

    const visitorsAvgPromise = get(`dashboard/foottraffic/sites/${siteId}/salesrate`, {
      begin: format(begin, 'YYYY-MM-DD'),
      end: format(end, 'YYYY-MM-DD'),
    })

    Promise.all([selloutPromise, visitorsPromise, visitorsAvgPromise])
      .then(([selloutResponse, visitorsResponse, visitorsAvgTimeResponse]) => {
        const selloutData = selloutResponse.data.data;
        const visitorsData = visitorsResponse.data.data;
        const hasSelloutVenues = visitorsResponse.data.venues.length !== 0;
        const visitorsAvgTime = visitorsAvgTimeResponse.data.data;

        const mashed = selloutData.map(sellout => {
          const visitors = visitorsData.find(daily => daily.info_date === sellout.snapDate) || {
            shopVisitors: 0,
            venues: []
          }

          const selloutVisitors = hasSelloutVenues
            ? (_.sum(visitors.venues.map(venue => venue.visitorsCount)) || 0)
            : visitors.shopVisitors;

          return {
            ...sellout,
            hasSelloutVenues,
            siteVisitors: visitors.shopVisitors,
            selloutTickets: sellout.totalVendas,
            selloutVisitors,
            selloutVisitorsRate: parseFloat(visitors.shopVisitors === 0 ? 0 : ((selloutVisitors / visitors.shopVisitors) * 100).toFixed(1)),
            selloutTicketsRate: parseFloat(selloutVisitors === 0 ? 0 : ((sellout.totalVendas / selloutVisitors) * 100).toFixed(1)),
            netValue: sellout.valorLiquido,
            averageTicket: parseInt(_.round(sellout.valorLiquido / sellout.totalVendas)) || 0
          }
        });

        const visitorsAvg = visitorsAvgTime.map(s => {
          const { visitorsMeanTime, info_date } = s;
          return {
            visitorsMeanTime: (visitorsMeanTime / 60) || 0,
            info_date: info_date
          };
        })

        const mashedForMean = mashed.filter(mash => true);

        const siteVisitors = parseInt(_.sum(mashedForMean.map(mash => mash.siteVisitors)) || 0);
        const selloutVisitors = parseInt(_.sum(mashedForMean.map(mash => mash.selloutVisitors)) || 0);
        const selloutTickets = parseInt(_.sum(mashedForMean.map(mash => mash.totalVendas)) || 0);
        const netValue = parseInt(_.sum(mashedForMean.map(mash => mash.valorLiquido)) || 0);

        const averageTicket = parseInt(_.round(netValue / selloutTickets)) || 0;

        const selloutVisitorsRate = parseFloat(siteVisitors === 0
          ? 0
          : ((selloutVisitors / siteVisitors) * 100).toFixed(0));

        const selloutTicketsRate = parseFloat(selloutVisitors === 0
          ? 0
          : ((selloutTickets / selloutVisitors) * 100).toFixed(0));

        setChartHeader({
          siteVisitors,
          selloutVisitors,
          selloutVisitorsRate,
          selloutTickets,
          selloutTicketsRate,
          hasSelloutVenues,
          visitorsAvg,
          netValue,
          averageTicket
        });
        setChart(mashed);
        setIsLoading(false);
        setVisitorsAvgTime(visitorsAvg)
      });
  }, [siteId, begin, end]);

  return [chart, chartHeader, isLoading, visitorsAvgTime];
}

const VisitorHeader = (props) => {
  const { siteId, formatDate = 'ddd - DD MMM' } = props;
  const [begin, setBegin] = useState(getServerDate(14));
  const [end, setEnd] = useState(getServerDate(1));
  const [chart, chartHeader, isLoading, visitorsAvgTime] = useDataChart(siteId, begin, end);

  return <div style={{ width: '100%' }}>
    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: 20 }}>
      <CalendarFilter
        isLoading={isLoading}
        maxDate={getServerDate(1)}
        begin={begin}
        end={end}
        setBegin={setBegin}
        setEnd={setEnd}
      />
    </div>
    <Columns>
      <Column>
        <Indice
          color="#00d1b2"
          isLoading={isLoading}
          title="Visitantes Loja"
          icon={<WalkingIcon />}
          value={chartHeader.siteVisitors}
          isActive={true}
        />
      </Column>
      {chartHeader.hasSelloutVenues && <Column>
        <Indice
          color="#87BFD1"
          isLoading={isLoading}
          title="Áreas de tintas"
          icon={<MapMarkerAltIcon />}
          value={chartHeader.selloutVisitors}
          isActive={true}
        />
      </Column>}
      {chartHeader.hasSelloutVenues && <Column>
        <Indice
          color="#87BFD1"
          isLoading={isLoading}
          title="Taxa de visitantes tintas"
          icon={<PercentageIcon />}
          value={`${chartHeader.selloutVisitorsRate}%`}
          isActive={true}
        />
      </Column>}
      <Column>
        <Indice
          color="#363636"
          isLoading={isLoading}
          title="Quantidade de pedidos"
          icon={<SalesIcon />}
          value={chartHeader.selloutTickets}
          isActive={true}
        />
      </Column>
      <Column>
        <Indice
          color="#363636"
          isLoading={isLoading}
          title="Taxa de Conversão"
          icon={<PercentageIcon />}
          value={`${chartHeader.selloutTicketsRate}%`}
          isActive={true}
        />
      </Column>
      <Column>
        <Indice
          color='#363636'
          isLoading={isLoading}
          title='Ticket médio'
          icon={<SalesIcon />}
          value={`R$ ${_.isNaN(chartHeader.averageTicket) || chartHeader.averageTicket === null ? 0 : chartHeader.averageTicket}`}
        />
      </Column>
    </Columns>
    <Card isPaddingless>
      <Columns isMultiline>
        <Column isSize={12}>
          <p className="subtitle is-4 has-text-centered">Diário</p>
          {isLoading && <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: 250
          }}
          >
            <IsLoading isBorderless fontSize={60} />
            Aguarde carregando...
          </div>}
          {!isLoading && <ResponsiveContainer width="100%" height={280} style={{ marginBottom: 30 }}>
            <ComposedChart
              data={chart}
              syncId="visitorsAvgTime"
              margin={{
                top: 20,
                right: 20,
                bottom: 50,
                left: 20,
              }}
            >
              <XAxis dataKey="snapDate" tickFormatter={(val) => formatAsPtBr(val, 'ddd - DD MMM')} tickLine={false} />
              <YAxis yAxisId="left" orientation="left" stroke="#343434" tickLine={false} />
              <YAxis yAxisId="right" orientation="right" stroke="#343434" tickFormatter={(val) => `${val}%`} tickLine={false} />
              <Legend />
              <Tooltip
                content={<CustomToolTip />}
                isAnimationActive={false}
              />
              {chart.length > 15 && <Brush
                className="brushDisplayNone"
                startIndex={chart.length - 15}
                dataKey='snapDate'
                height={20}
                tickFormatter={(val) => `${formatAsPtBr(val, 'DD MMM')}`}
                stroke="#363636"
              />}
              <Bar
                yAxisId="left"
                dataKey="siteVisitors"
                barSize={20}
                fill="#00d1b2"
                name="Visitantes Loja"
              />
              {chartHeader.hasSelloutVenues && <Bar
                yAxisId="left"
                dataKey="selloutVisitors"
                barSize={20}
                fill="#87BFD1"
                name="Visitantes Tintas"
              />}
              <Bar
                yAxisId="left"
                dataKey="selloutTickets"
                barSize={20}
                fill="#363636"
                name="Quantidade de Pedidos"
              />
              {chartHeader.hasSelloutVenues && <Line
                connectNulls={true}
                animationDuration={300}
                yAxisId="right"
                type="monotone"
                dataKey="selloutVisitorsRate"
                stroke="#87BFD1"
                name="Taxa Visitantes Tintas"
              />}
              <Line
                connectNulls={true}
                animationDuration={300}
                yAxisId="right"
                type="monotone"
                dataKey="selloutTicketsRate"
                stroke="#363636"
                name="Taxa Conversão"
              />
            </ComposedChart>
          </ResponsiveContainer>}

          {<Column isSize={12} style={{ marginTop: 20 }}>
            {isLoading && <div style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: 250
            }}
            >
              <IsLoading isBorderless fontSize={60} />
              Aguarde carregando...
            </div>}
            {!isLoading && chartHeader.hasSelloutVenues && <ResponsiveContainer width="100%" height={280} style={{ marginBottom: 30 }}>
              <ComposedChart
                data={chart}
                syncId="visitorsAvgTime"
                margin={{
                  top: 40,
                  right: 20,
                  bottom: 50,
                  left: 20,
                }}
              >
                <XAxis dataKey="snapDate" tickFormatter={(val) => formatAsPtBr(val, 'ddd - DD MMM')} tickLine={false} />
                <YAxis yAxisId="left" orientation="left" stroke="#343434" tickFormatter={(val) => `R$${val}`} tickLine={false} />
                <YAxis yAxisId="right" orientation="right" stroke="#343434" tickLine={false} />
                <Legend />
                <Tooltip
                  content={<CustomToolTipAverageTicket />}
                  isAnimationActive={false}
                />
                {chart.length > 15 && <Brush
                  className="brushDisplayNone"
                  startIndex={chart.length - 15}
                  dataKey='snapDate'
                  height={20}
                  tickFormatter={(val) => `${formatAsPtBr(val, 'DD MMM')}`}
                  stroke="#363636"
                />}
                {chartHeader.hasSelloutVenues && <Bar
                  yAxisId="left"
                  dataKey="averageTicket"
                  barSize={20}
                  fill="#252525"
                  name="Ticket Médio"
                  label={props => <g transform={`translate(${props.x},${props.y})`}>
                    <text x={25} y={-10} dx={0} dy={-5} textAnchor="end" fill="#222">R${props.value}</text>
                  </g>}
                />}
              </ComposedChart>
            </ResponsiveContainer>}
          </Column>}

          {<Column isSize={12} style={{ marginTop: 20 }}>
            {isLoading && <div style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: 250
            }}
            >
              <IsLoading isBorderless fontSize={60} />
              Aguarde carregando...
            </div>}
            {!isLoading && <ResponsiveContainer width={'100%'} height={280}>
              <ComposedChart data={visitorsAvgTime} syncId="visitorsAvgTime">
                <CartesianGrid strokeDasharray="3 10" />
                <XAxis dataKey="info_date" tickFormatter={date => formatAsPtBr(date, formatDate)} />
                <YAxis unit=" min" yAxisId="left" orientation="left" dataKey="visitorsMeanTime" domain={[0, 'dataMax+3']} fill="#00d1b2" tickFormatter={label => parseInt(label, 10)} />
                <YAxis unit=" min" yAxisId="right" orientation="right" dataKey="visitorsMeanTime" domain={[0, 'dataMax+3']} fill="#00d1b2" tickFormatter={label => parseInt(label, 10)} />
                {visitorsAvgTime.length > 15 && <Brush
                  startIndex={chart.length - 15}
                  dataKey='info_date'
                  tickFormatter={(val) => `${format(val, 'DD/MM')}`}
                  height={20}
                  stroke="#000000" />}
                <Tooltip
                  content={<CustomToolTipMeanTime />}
                  isAnimationActive={false}
                />
                <Legend />
                <Area
                  yAxisId="left"
                  type="monotone"
                  connectNulls={true}
                  name="Tempo médio Visitantes"
                  dataKey="visitorsMeanTime"
                  stroke="#c8142d"
                  strokeWidth={2}
                  isAnimationActive={false}
                  fillOpacity={0.2}
                  fill="#c8142d"
                  activeDot={true}
                  label={props => <g transform={`translate(${props.x},${props.y})`}>
                    <text x={7} y={-10} dx={0} dy={-5} textAnchor="end" fill="#222">{parseInt(props.value, 10) > 0 ? `${parseInt(props.value, 10)}m` : ''}  {Math.round((props.value % 1) * 60)}s</text>
                  </g>}
                />
              </ComposedChart>
            </ResponsiveContainer>}
          </Column>}
        </Column>
      </Columns>
    </Card>
  </div>;
}

class CustomToolTip extends Component {
  render() {
    const { active, payload = [] } = this.props;

    if (active) {

      let data = { payload: {} };
      if (payload && payload.length > 0) {
        data = payload[0]
      }

      return (<div className="card notification" style={{ padding: '0px 5px 0px 5px' }}>
        <p className="heading has-text-centered">
          Em {format(data.payload.snapDate, "DD/MM/YYYY")}
        </p>
        <span className="heading has-text-centered" style={{ fontWeight: 'bold', marginBottom: '-2px', color: "#00d1b2" }}>
          <span>Visitante Loja</span>
        </span>
        <span className="has-text-centered" style={{ display: 'block', letterSpacing: '1px', fontSize: '1.5em', marginBottom: '5px', fontWeight: 'bold', color: "#00d1b2" }}>
          <span>{data.payload.siteVisitors || 0}</span>
        </span>
        <span className="heading has-text-centered" style={{ fontWeight: 'bold', marginBottom: '-2px', color: "#87BFD1" }}>
          <span>Visitantes Tintas</span>
        </span>
        <span className="has-text-centered" style={{ display: 'block', letterSpacing: '1px', fontSize: '1.5em', marginBottom: '5px', fontWeight: 'bold', color: "#87BFD1" }}>
          <span>{data.payload.selloutVisitors || 0}</span>
        </span>
        <span className="heading has-text-centered" style={{ fontWeight: 'bold', marginBottom: '-2px', color: "#4A4A4A" }}>
          <span>Quantidade Pedidos</span>
        </span>
        <span className="has-text-centered" style={{ display: 'block', letterSpacing: '1px', fontSize: '1.5em', marginBottom: '5px', fontWeight: 'bold', color: "#4A4A4A" }}>
          <span>{data.payload.selloutTickets || 0}</span>
        </span>
        <span className="heading has-text-centered" style={{ fontWeight: 'bold', marginBottom: '-2px', color: "#87BFD1" }}>
          <span>Taxa Visitantes Tintas</span>
        </span>
        <span className="has-text-centered" style={{ display: 'block', letterSpacing: '1px', fontSize: '1.5em', marginBottom: '5px', fontWeight: 'bold', color: "#87BFD1" }}>
          <span>{`${data.payload.selloutVisitorsRate}%` || 0}</span>
        </span>
        <span className="heading has-text-centered" style={{ fontWeight: 'bold', marginBottom: '-2px', color: "#4A4A4A" }}>
          <span>Taxa Conversão</span>
        </span>
        <span className="has-text-centered" style={{ display: 'block', letterSpacing: '1px', fontSize: '1.5em', marginBottom: '5px', fontWeight: 'bold', color: "#4A4A4A" }}>
          <span>{`${data.payload.selloutTicketsRate}%` || 0}</span>
        </span>
      </div>)
    }
    return null;
  }
}

class CustomToolTipAverageTicket extends Component {
  render() {
    const { active, payload = [] } = this.props;

    if (active) {

      let data = { payload: {} };
      if (payload && payload.length > 0) {
        data = payload[0]
      }

      return (<div className="card notification" style={{ padding: '0px 5px 0px 5px' }}>
        <p className="heading has-text-centered">
          Em {format(data.payload.snapDate, "DD/MM/YYYY")}
        </p>
        <span className="heading has-text-centered" style={{ fontWeight: 'bold', marginBottom: '-2px', color: "#252525" }}>
          <span>Ticket Médio</span>
        </span>
        <span className="has-text-centered" style={{ display: 'block', letterSpacing: '1px', fontSize: '1.5em', marginBottom: '5px', fontWeight: 'bold', color: "#252525" }}>
          <span>{`R$ ${data.payload.averageTicket}` || 0}</span>
        </span>
        <span className="heading has-text-centered" style={{ fontWeight: 'bold', marginBottom: '-2px', color: "#252525" }}>
          <span>Valor Liquido</span>
        </span>
        <span className="has-text-centered" style={{ display: 'block', letterSpacing: '1px', fontSize: '1.5em', marginBottom: '5px', fontWeight: 'bold', color: "#252525" }}>
          <span>{`R$ ${data.payload.netValue}` || 0}</span>
        </span>
        <span className="heading has-text-centered" style={{ fontWeight: 'bold', marginBottom: '-2px', color: "#6F6F6F" }}>
          <span>Quantidade de Pedidos</span>
        </span>
        <span className="has-text-centered" style={{ display: 'block', letterSpacing: '1px', fontSize: '1.5em', marginBottom: '5px', fontWeight: 'bold', color: "#6F6F6F" }}>
          <span>{data.payload.selloutTickets || 0}</span>
        </span>
      </div>)
    }
    return null;
  }
}

class CustomToolTipMeanTime extends Component {
  render() {
    const { active, payload = [] } = this.props;

    if (active) {

      let data = { payload: {} };
      if (payload && payload.length > 0) {
        data = payload[0]
      }

      let val = data.dataKey === 'visitorsMeanTime' ? `${parseInt(data.value, 10) > 0 ? `${parseInt(data.value, 10)}m` : ''}  ${Math.round((data.value % 1) * 60)}s` : data.value

      return (<div className="card notification" style={{ padding: '0px 5px 0px 5px' }}>
        <p className="heading has-text-centered">
          Em {format(data.payload.info_date, "DD/MM/YYYY")}
        </p>
        <span className="heading has-text-centered" style={{ fontWeight: 'bold', marginBottom: '-2px', color: "#c8142d" }}>
          <span>Tempo médio Visitantes</span>
        </span>
        <span className="has-text-centered" style={{ display: 'block', letterSpacing: '1px', fontSize: '1.5em', marginBottom: '-5px', fontWeight: 'bold', color: data.color }}>
          <span>{val}</span>
        </span>
      </div>)
    }
    return null;
  }
}

export default VisitorHeader;