import React, { Component } from 'react';

import {
  ResponsiveContainer,ComposedChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Cell, ReferenceLine
} from 'recharts';

import {format} from 'date-fns';
import MapChart from './MapChart';
import _ from 'lodash'

import axios from 'axios'

export default class MinMaxPerDayWithGPS extends Component {
  constructor(props){
    super(props)
    this.state ={
      activeIndex: undefined, 
      toogleModal: false,
      address: ''
    }
  }
  handleClick = (data, index) =>{

    const {detail} = this.props;
    let row = detail[index]
    axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${row.lat},${row.lon}&key=AIzaSyAy1-WyaCtApwJwfAGK1eS9xBvHbvoWP64`)
    .then(({data}) => {
      this.setState({
        activeIndex: index,
        toogleModal: true,
        address: data.status !== "ZERO_RESULTS" ? data.results[0].formatted_address : ''
      });
    })
  }
  _toogleModal = () => {
    const { toogleModal } = this.state;
    this.setState({ toogleModal: !toogleModal,  activeIndex: undefined});
  }
  render() {
    const {detail, venue} = this.props;
    let {activeIndex, toogleModal, address} = this.state
    let row = {}
    if(activeIndex !== undefined){
      row = detail[activeIndex]
    } 

    const min = _.minBy(detail, (arr) => parseFloat(arr.avgTemperature)) || {temperature: 0};
    const max = _.maxBy(detail, (arr) => parseFloat(arr.avgTemperature)) || {temperature: 0};
    return (<div>
              <ResponsiveContainer 
                width={'100%'}
                height={400}
              >
                <ComposedChart
                  data={detail}
                  margin={{
                    top: 5, right: 30, left: 20, bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="2 2" />
                  <XAxis dataKey="collectDay" tickFormatter={date => format(date, 'DD/MM - HH:mm') } />
                  <YAxis  domain={[parseFloat(min.avgTemperature) -2, parseFloat(max.avgTemperature) +2]} tickFormatter={val => `${Number(val).toFixed(1)}˚C`}/>
                  <ReferenceLine label="Maxima Permitida" y={venue.maxTemp} />
                  <ReferenceLine label="Minima Permitida" y={venue.minTemp} />
                  <Legend />
                  <Tooltip
                    content={<CustomToolTip />}
                    isAnimationActive={false}/>
                  <Bar name={'Temperatura média'}  
                       maxBarSize={40}
                       unit={'˚C'} 
                       dataKey="chartBar" fill="#00d1b2" 
                       label={{fontSize:11, position: 'top' }}
                       connectNulls={true}

                        barSize={15}
                        minPointSize={3}
                        isAnimationActive={false}
                       onClick={this.handleClick}>
                      {
                        detail.map((entry, index) => {
                          const hasWarn = parseFloat(entry.maxTemperature) > venue.maxTemp ||
                                          parseFloat(entry.minTemperature) < venue.minTemp;

                          const color = hasWarn ? '#f14668' : '#00d1b2';
                          return <Cell key={`cell-${index}`} cursor="pointer" fill={color} />;
                        })
                      }
                  </Bar>
                </ComposedChart>
            </ResponsiveContainer>
            { activeIndex !== undefined && toogleModal && 
              <div className="modal is-active">
                <div className="modal-background" onClick={this._toogleModal}></div>
                <div className="modal-content is-paddingless ">
                  <MapChart row={row} address={address}/>
                </div>
                <button className="modal-close is-large" onClick={this._toogleModal} aria-label="close"></button>
              </div>
            }
          </div>)
  }
}

class CustomToolTip extends Component {
  render() {
    const { active, payload=[] } = this.props;
    if(active) {

      let data = { payload: {} };
      if(payload && payload.length > 0){
        data = payload[0]
      }

      return (<div className="card notification" style={{ padding: '0px 5px 0px 5px' }}>
          <p className="heading has-text-centered">
            Em {format(data.payload.collectDay, 'DD/MM HH:mm')}
          </p>

          <span className="heading has-text-centered" style={{ fontWeight: 'bold', marginBottom: '-2px', color: '#222222' }}>
            <span>Temperatura média</span>
          </span>
          <span className="heading has-text-centered" style={{ fontSize: '1.5em', marginTop:'-5px', marginBottom: '-5px', fontWeight: 'bold', color: '#00d1b2' }}>
            <span> {data.payload.avgTemperature}</span>
          </span>
          <span className="heading has-text-centered" style={{ fontWeight: 'bold', marginBottom: '2px', color: '#222222' }}>
            <span>Temperatura mínima</span>
          </span>
          <span className="heading has-text-centered" style={{ fontSize: '1.5em', marginTop:'-5px', marginBottom: '-5px', fontWeight: 'bold', color: '#0043ff' }}>
            <span> {data.payload.minTemperature}</span>
          </span><span className="heading has-text-centered" style={{ fontWeight: 'bold', marginBottom: '2px', color: '#222222' }}>
            <span>Temperatura máxima</span>
          </span>
          <span className="heading has-text-centered" style={{ fontSize: '1.5em', marginTop:'-5px', marginBottom: '-5px', fontWeight: 'bold', color: '#ffdd57' }}>
            <span> {data.payload.maxTemperature}</span>
          </span>
      </div>)
    }
    return null;
  }
}
