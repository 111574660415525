import React, { Component } from 'react';

class LabelCustomizedAuditing extends Component {

  render () {
    const {x, y, value, addX=0 } = this.props;
    if (!value) {
      return (
        <svg
          x={(x-3) + addX}
          y={y-25}
          width={20}
          height={20}
          opacity={1}
          viewBox="0 0 512 512"
        >
          <path
            fill="#E92641"
            d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"
          />
        </svg>
      );
    }

    return (
      <svg
        x={x}
        y={y}
        width={20}
        height={20}
        opacity={0}
        viewBox="0 0 512 512"
      >
        <path
          fill="#72CB46"
          d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
        />
      </svg>
    );
  }
}

export default LabelCustomizedAuditing;