import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  isSameDay,
  subDays
} from 'date-fns';
import { getServerDate } from '../../../../utils';

const defineds = {
  startOfWeek: startOfWeek(getServerDate()),
  endOfWeek: endOfWeek(getServerDate()),
  startOfLastWeek: startOfWeek(addDays(getServerDate(), -7)),
  endOfLastWeek: endOfWeek(addDays(getServerDate(), -7)),
  startOfToday: startOfDay(getServerDate()),
  endOfToday: endOfDay(getServerDate()),
  startOfYesterday: startOfDay(addDays(getServerDate(), -1)),
  endOfYesterday: endOfDay(addDays(getServerDate(), -1)),
  startOfMonth: startOfMonth(getServerDate()),
  endOfMonth: endOfMonth(getServerDate()),
  startOfLastMonth: startOfMonth(addMonths(getServerDate(), -1)),
  endOfLastMonth: endOfMonth(addMonths(getServerDate(), -1)),
  yesterday: subDays(getServerDate(), 1)
};

const staticRangeHandler = {
  range: {},
  isSelected(range) {
    const definedRange = this.range();
    return (
      isSameDay(range.startDate, definedRange.startDate) &&
      isSameDay(range.endDate, definedRange.endDate)
    );
  },
};

export function createStaticRanges(ranges) {
  return ranges.map(range => ({ ...staticRangeHandler, ...range }));
}

export const defaultStaticRanges = createStaticRanges([
  {
    label: 'Último 7 dias',
    range: () => ({
      startDate: addDays(defineds.yesterday, - 6),
      endDate: defineds.yesterday,
    }),
  },
  {
    label: 'Último 15 dias',
    range: () => ({
      startDate: addDays(defineds.yesterday, - 14),
      endDate: defineds.yesterday,
    }),
  },
  {
    label: 'Últimos 30 dias',
    range: () => ({
      startDate: addDays(defineds.yesterday, - 29),
      endDate: defineds.yesterday,
    }),
  },
  {
    label: 'Última semana',
    range: () => ({
      startDate: defineds.startOfLastWeek,
      endDate: defineds.endOfLastWeek,
    }),
  },
  {
    label: 'Último mês',
    range: () => ({
      startDate: defineds.startOfLastMonth,
      endDate: defineds.endOfLastMonth,
    }),
  }
]);
export const noneStaticRanges = createStaticRanges([]);
export const defaultInputRanges = [];