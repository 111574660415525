import React, { Component, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';

import { get, post, del, useInput } from '../../../../../utils';
import Columns, { Column } from '../../../../../components/Columns';
import * as Icons from '../../../../../components/icons';

import notification from '../../../../../utils/notification';
import DvrsImages from './DvrsImages';
import MarcacaoLatas from './MarcacaoLatas';

const getVenueTypeDescription = drawType => drawType.isVisitor ? 'área de Visitantes'
  : drawType.isPassersBy ? 'Área de Passantes'
  : drawType.isVenue ? 'Área Interna da loja'
  : drawType.isShelf ? 'Prateleira'
  : drawType.isCheckoutQueue ? 'Área de Fila' : '';


const AreaSelectionBox = props => {
  const { savedVerticesWithVenueData, drawType, cleanDraw, venues, isLoadingVenues } = props;
  const alreadyUsedVenues = savedVerticesWithVenueData.map(s => s.venueId);

  const venuesFiltered = venues
    .filter(venue => {
      const isDrawType = Object.keys(venue)
        .filter(key => drawType[key] === venue[key] && drawType[key])
        .length !== 0;

      if (!drawType.isShelf)
        return isDrawType;

      return isDrawType && !alreadyUsedVenues.includes(venue.id)
    });

  const [menuSelected, setMenuSelected] = useState('new');
  const [ savingNewArea, setSavingNewArea ] = useState(false);
  const [ savingDrawArea, setSavingDrawArea ] = useState(false);
  const [ selectedVenue, setSelectedVenue ] = useState(0);
  const descriptionInput = useInput();

  useEffect(() => {
    const selectedVenue = (venuesFiltered[0] || { id: 0}).id;
    setSelectedVenue(selectedVenue)

    // eslint-disable-next-line
  }, [ JSON.stringify(drawType) ])

  const setMenuNew = () => () => {
    setMenuSelected('new')
  }
  const setMenuExists = () => () => {
    setMenuSelected('exists')
  }

  const drawTypeLabel = getVenueTypeDescription(drawType)

  const saveDrawForVenue = () => {
    if(selectedVenue === 0 || savingDrawArea) {
      return;
    }

    setSavingDrawArea(true)
    props.saveDraw(selectedVenue)
      .then(() => {
        notification.success('Dados salvos com sucesso!')
      })
      .catch(err => {
        setSavingDrawArea(false)
        notification.error('Não foi possivel salvar a area.')
      })
  }

  const addShelfAndDraw = () => {
    if (descriptionInput.value === "" || !descriptionInput.value) {
      notification.error('Preencha o campo corretamente para salvar.')
      return;
    }

    if(savingNewArea) {
      return;
    }

    setSavingNewArea(true);
    props.newArea(descriptionInput.value)
      .then(venueInserted => {
        return props.saveDraw(venueInserted.id)
      })
      .then(() => {
        notification.success('Dados salvos com sucesso!')
      })
      .catch(err => {
        setSavingNewArea(false)
        notification.error('Não foi possivel salvar a area.')
      })
  }
  

  return <div style={{ borderRadius: 5, padding: 10, zIndex: 9999, width: 350, height: 200, backgroundColor: '#ffffff'}}>
    <div className="tabs is-small" style={{ marginBottom: 10 }}>
      <ul>
        {/* eslint-disable-next-line */}
        <li className={`${menuSelected === 'new' ? "is-active": ""}`} onClick={setMenuNew()}><a>Novo</a></li>
        {/* eslint-disable-next-line */}
        <li className={`${menuSelected !== 'new' ? "is-active": ""}`} onClick={setMenuExists()}><a>Existente</a></li>
      </ul>
    </div>

    {menuSelected === 'exists' && <div>
      <label className='label'>
        Selecione {drawTypeLabel}
      </label>
      <div className="field is-small is-grouped">
        <div className="control is-expanded">
          <div className={`select is-primary is-fullwidth ${isLoadingVenues ? 'is-loading': ''}`}>
            <select onChange={e => setSelectedVenue(e.target.value)}>
              {venuesFiltered
              .map(venue => <option key={`select_venue_to_drawn_${venue.id}`} value={venue.id}>{venue.description}</option>)}
            </select>
          </div>
        </div>
      </div>
      <div className="buttons">
        <span
          className={`button is-primary ${savingDrawArea ? 'is-loading': ''}`}
          onClick={saveDrawForVenue}
        >
          Salvar
        </span>
        <span className="button" onClick={cleanDraw}>
          Cancelar
        </span>
      </div>
    </div>}

    {menuSelected === 'new' && <div>
      <label className='label'>
        Nova {drawTypeLabel}
      </label>
      <div className="field is-grouped is-small">
        <span className="control has-icons-left has-icons-right is-expanded">
          <input
            name="description"
            className="input"
            type="text"
            placeholder="Descrição Prateleira"
            required
            {...descriptionInput}
          />
          <Icons.MapMarkedIcon className="is-small is-left"/>
        </span>
      </div>
      <div className="buttons">
        <span className={`button is-primary ${savingNewArea ? 'is-loading': ''}`} onClick={addShelfAndDraw}>
          Salvar
        </span>
        <span className="button" onClick={cleanDraw}>
          Cancelar
        </span>
      </div>
    </div>}
  </div>
}



class CameraAreaV2 extends Component {

  constructor(props) {
    super(props);

    const originalWidth = props.camera.width;
    const originalHeight = props.camera.height;

    const {
      showWidth,
      showHeigth,
      heigthFator,
      widthFator
    } = this._calculateCameraProportion(props.camera.width, props.camera.height);

    this.state = {
      isRemovingDraw: false,
      isAddinngDraw: false,
      isLoadingVenues: false,
      isLoadingSavedVertices: false,
      selectedVenue: 0,
      savedVertices: [],
      vertices: [],
      venues: [],
      subMenuSelected: 'draw',
      showWidth,
      showHeigth,
      heigthFator,
      widthFator,
      originalWidth,
      originalHeight,
      removing: {},
      marcacaoLatas: false,
      drawType: {
        isVisitor: true,
        isPassersBy: false,
        isVenue: false,
        isShelf: false,
        isCheckoutQueue: false
      }
    }
  }

  componentDidMount() {
    this._loadVenues();
    this._loadVertices();

    const node = ReactDOM.findDOMNode(this.refs.canvas);
    const rect = node.getBoundingClientRect();
    const { left, top } = rect;
    this.setState({ top, left });
  }

  _calculateCameraProportion = (originalWidth, originalHeight) => {
    const showWidth = originalWidth < 1280 ? originalWidth : 1280;
    const showHeigth = originalHeight < 720 ? originalHeight : 720;
    const heigthFator = originalHeight / showHeigth;
    const widthFator = originalWidth / showWidth;

    return { showWidth, showHeigth, heigthFator, widthFator }
  }

  _setIsRemoving = (verticeId, removing) => this.setState({
    removing: {
      ...this.state.removing,
      [verticeId]: removing
    }
  });

  _selectDrawType = e => {
    const { value } = e.target;
    const { drawType } = this.state;

    drawType['isVisitor'] = false
    drawType['isPassersBy'] = false
    drawType['isVenue'] = false
    drawType['isShelf'] = false
    drawType['isCheckoutQueue'] = false

    drawType[value] = true;

    this.setState({ drawType })
  }

  _newArea = (description) => {
    const { site } = this.props;
    const {
      isVisitor,
      isPassersBy,
      isVenue,
      isShelf,
      isCheckoutQueue
    } = this.state.drawType;

    return post(`admin/videos/sites/${site.id}/venues`, {
      description,
      isVisitor,
      isPassersBy,
      isVenue,
      isShelf,
      isCheckoutQueue
    })
    .then(resp => {
      this.setState({ venues: [...this.state.venues, resp.data.data] });
      return resp.data.data;
    });
  }

  _setSubMenuAsDraw = () => {
    this.setState({ subMenuSelected: 'draw' });
  }

  _setSubMenuAsDvrImages = () => {
    this.setState({ subMenuSelected: 'dvr_images' });
  }

  _setSubMenuAsSnippets = () => {
    this.setState({ subMenuSelected: 'snippets' });
  }

  _isDrawClosed = () => {
    const { vertices } = this.state;
    const endVertice = vertices.find(vertice => vertice.end);
    return !!endVertice;
  }

  _saveDraw = (selectedVenue) => {
    const { site, camera } = this.props;
    const { isAddinngDraw, isRemovingDraw, vertices, savedVertices } = this.state;

    if(isAddinngDraw || isRemovingDraw)
      return;

    const points = vertices.map(vertice => `${parseInt(vertice.fator.w * vertice.x)},${parseInt(vertice.fator.h * vertice.y)}`).join(' ');

    return post(`admin/videos/sites/${site.id}/cameras/${camera.id}/vertices`, {
      venueId: selectedVenue,
      vertices: points
    })
    .then(resp => {
      const savedVertice = resp.data.data;
      const { id, points } = savedVertice;
      savedVertices.push({ ...savedVertice, id: id, venueId: parseInt(selectedVenue), points: this._resizePoints(points)});
      this.setState({ isAddinngDraw: false, savedVertices, vertices: [] });
    });
  }

  handleMouseDown = (evt, fator) => {
    evt.persist();

    const e = evt.target;
    if(e.localName !== 'svg' && e.localName !== 'image')
      return;

    const dim = e.getBoundingClientRect();
    const x = parseInt(evt.clientX - dim.left, 10);
    const y = parseInt(evt.clientY - dim.top, 10);
    const { vertices } = this.state;

    if(this._isDrawClosed())
      return;

    vertices.push({ x, y, fator });
    this.setState({ vertices });
  };

  _undoLastVertice = () => {
    const { vertices } = this.state;
    vertices.pop();
    this.setState({ vertices });
  }

  endDraw = (x, y) => (e) => {
    const { vertices } = this.state;

    const firstPoint = _.first(vertices);
    if(firstPoint.x !== x || firstPoint.y !== y) {
      return;
    }

    vertices.push({ ...firstPoint, x, y, end: true });
    this.setState({ vertices })
  }

  _removeDrawFromVenue = (verticeId) => {
    return () => {
      const { site, camera } = this.props;
      const { isAddinngDraw, removing, savedVertices } = this.state;
  
      if(isAddinngDraw || removing[verticeId])
        return;
  
      this.setState({ removing: { ...removing, [verticeId]: true } }, () => {
        del(`admin/videos/sites/${site.id}/cameras/${camera.id}/vertices/${verticeId}`)
          .then(resp => {
            notification.success('Removido com sucesso!')
            this.setState({
              removing: { ...removing, [verticeId]: false },
              isRemovingDraw: false,
              savedVertices: savedVertices.filter(v => parseInt(v.id) !== parseInt(verticeId))
            });
          });
      })
    }
  }

  _resizePoints = (points) => points
    .split(' ')
    .map(pointStr => pointStr.split(','))
    .map(point => [parseInt(point[0]), parseInt(point[1])])
    .map(point => [parseInt(point[0] / this.state.widthFator), parseInt(point[1] / this.state.heigthFator)])
    .map(point => `${point[0]},${point[1]}`)
    .join(" ")

  _loadVertices = () => {
    const { site } = this.props;
    const { camera } = this.props;

    this.setState({ isLoadingSavedVertices: true }, () => {
      get(`admin/videos/sites/${site.id}/cameras/${camera.id}/vertices`)
        .then(resp => resp.data.data)
        .then(savedVertices => {
          const savedVerticesReajustToResolution = savedVertices.map(vertice => {
            const { points } = vertice;
            const newPoints = this._resizePoints(points)

            return {
              ...vertice,
              points: newPoints
            }
          })
          this.setState({ savedVertices: savedVerticesReajustToResolution, isLoadingSavedVertices: false });
        });
    });
  }

  _loadVenues = () => {
    const { id } = this.props.site;
    this.setState({ isLoadingVenues: true }, () => {
      get(`admin/videos/sites/${id}/venues`)
        .then(resp => {
          const venues = resp.data.data;
          this.setState({ venues, isLoadingVenues: false });
        });
    });
  }

  _switchCameraSrc = (image) => {
    const { camera } = this.props;
    post(`admin/videos/sites/${this.props.site.id}/cameras/${camera.id}/dvrimage/${image.id}`)
      .then(() => {
        this._setSubMenuAsDraw();
        this.props.changePictureSrc(camera.id, image.src);

        const {
          showWidth,
          showHeigth,
          heigthFator,
          widthFator
        } = this._calculateCameraProportion(image.width, image.height);
        
        this.setState({
          showWidth,
          showHeigth,
          heigthFator,
          widthFator,
          originalHeight: image.height,
          originalWidth: image.width
        })
      })
  }

  _onOverTable = cameraVenueId => () => this.setState({ overVenueDrawn: cameraVenueId });

  render() {
    const { site, camera } = this.props;
    const {
      showHeigth,
      showWidth,
      widthFator,
      heigthFator,
      subMenuSelected,
      venues,
      vertices,
      savedVertices
    } = this.state;
    const endVertice = vertices.find(vertice => vertice.end);
    const color = (endVertice) ? '#00d1b2' : '#222222';

    const savedVerticesWithVenueData = savedVertices.map(savedPolygon => {
        const { id, venueId, cameraVenueId, points } = savedPolygon;
        const venue = venues.find(venue => venue.id === venueId);
        return { ...venue, id, venueId, cameraVenueId, points };
      });
   
    return (<div className="notification card" >
      <p className="title is-4 has-text-centered">Configuração de prateleiras</p>
      <p className="title is-5">{camera.id} URL: {camera.url}</p>
      <p className="subtitle is-5">Descrição: {camera.description}</p>
      <Columns isMultiline>
        <Column isSize={12}>
          <div className="tabs">
            <ul>
              <li className={subMenuSelected === "draw" ? "is-active": ""}>
                {/* eslint-disable-next-line */}
                <a onClick={this._setSubMenuAsDraw}>Marcação de areas</a>
              </li>
              <li className={subMenuSelected === "snippets" ? "is-active": ""}>
                {/* eslint-disable-next-line */}
                <a onClick={this._setSubMenuAsSnippets}>Marcação de "Snippets" de áreas</a>
              </li>
              <li className={subMenuSelected === "dvr_images" ? "is-active": ""}>
                {/* eslint-disable-next-line */}
                <a onClick={this._setSubMenuAsDvrImages}>Associar nova imagem</a>
              </li>
            </ul>
          </div>
        </Column>
        {subMenuSelected === 'snippets' && <Column isSize={12}>
          <MarcacaoLatas
            closeMarcacao={() => this.setState({ marcacaoLatas: false })}
            site={site}
            camera={camera}
            showHeigth={showHeigth}
            showWidth={showWidth}
            widthFator={widthFator}
            heigthFator={heigthFator}
          />
        </Column>}
        {subMenuSelected === 'dvr_images' && <Column isSize={12}>
          <DvrsImages site={this.props.site} camera={camera} changeCameraImage={this._switchCameraSrc}/>
        </Column>}
        {subMenuSelected === 'draw' && <Column isSize={12}>
          <label className="label">
            Tipo do desenho (pode marcar antes de salvar)
          </label>
          <div className="control" onChange={this._selectDrawType}>
            <label className="radio">
              <input readOnly type="radio" name="areaType" value="isVisitor" checked={this.state.drawType.isVisitor}/>
              Visitantes
            </label>
            <label className="radio">
              <input readOnly type="radio" name="areaType" value="isPassersBy" checked={this.state.drawType.isPassersBy}/>
              Passantes
            </label>
            <label className="radio">
              <input readOnly type="radio" name="areaType" value="isVenue" checked={this.state.drawType.isVenue}/>
              Area
            </label>
            <label className="radio">
              <input readOnly type="radio" name="areaType" value="isCheckoutQueue" checked={this.state.drawType.isCheckoutQueue}/>
              Fila
            </label>
            <label className="radio">
              <input readOnly type="radio" name="areaType" value="isShelf" checked={this.state.drawType.isShelf}/>
              Prateleira
            </label>
          </div>
        </Column>}
        {subMenuSelected === 'draw' && <Column style={{ overflow: 'auto'}}>
          <svg
            name="svgDraw"
            style={{
              border: '1px solid #222222',
              borderRadius: 5,
              cursor: 'crosshair'
            }}
            width={showWidth}
            height={showHeigth}
            ref="canvas"
            >
              <image
                href={camera.imageSrc}
                x="0"
                y="0"    
                onMouseDown={e => this.handleMouseDown(e, {w: widthFator, h: heigthFator})}
                width={showWidth}
                height={showHeigth}
              />
              {savedVerticesWithVenueData.map(venueVertices => <polyline
                key={`vertice-${venueVertices.venueId}-${venueVertices.points}`}
                points={venueVertices.points}
                fill="#72CB46"
                fillOpacity={0.2}
                stroke='#222222'
                onMouseOver={this._onOverTable(venueVertices.id)}
                onMouseLeave={() => this.setState({ overVenueDrawn: null })}
                strokeWidth={venueVertices.id === this.state.overVenueDrawn ? 4 : 1}
              >
              </polyline>)}
              {vertices.length > 1 && <polyline points={vertices.map(vertice => `${vertice.x},${vertice.y}`).join(' ')} fill="none" stroke="black" />}
              {vertices.map((vertice, i) => {
                if(vertices.length === i + 1) {
                  return <g key={`venue-vertice-${vertice.x}-${vertice.y}-${vertice.end}`}>
                    <circle
                      onMouseDown={this._undoLastVertice}
                      cx={vertice.x}
                      cy={vertice.y}
                      fill={'red'}
                      r="14"
                    />
                    {vertice.end && <foreignObject
                      x={vertice.x + 350 > showWidth ? vertice.x - ((vertice.x + 350) - showWidth) : vertice.x}
                      y={vertice.y + 200 > showHeigth ? vertice.y - ((vertice.y + 200) - showHeigth) : vertice.y}
                      width="350"
                      height="200">
                      <AreaSelectionBox
                        savedVerticesWithVenueData={savedVerticesWithVenueData}
                        cleanDraw={() => this.setState({ vertices: [] })}
                        drawType={this.state.drawType}
                        newArea={this._newArea}
                        saveDraw={this._saveDraw}
                        venues={this.state.venues}
                        isLoadingVenues={this.state.isLoadingVenues}
                      />
                    </foreignObject>}
                  </g>
                }

                return <circle
                  onMouseDown={this.endDraw(vertice.x, vertice.y)}
                  key={`venue-vertice-${vertice.x}-${vertice.y}-${vertice.end}`}
                  cx={vertice.x}
                  cy={vertice.y}
                  fill={color}
                  r="14"
                />
              })}
          </svg>
        </Column>}
        {subMenuSelected === 'draw' && <Column isSize={4} style={{ height: showHeigth, overflow: 'auto'}}>
          <p className='subtitle'>Desenhos</p>
          <table className='table is-fullwidth'>
            <thead>
              <tr>
                <th>ID</th>
                <th>AREA</th>
                <th>TIPO</th>
                <th>AÇÃO</th>
              </tr>
            </thead>
            <tbody>
              {savedVerticesWithVenueData.map(vertice => <tr
                key={`table_row_draw_${vertice.id}`}
                onMouseOver={this._onOverTable(vertice.id)}
                onMouseLeave={() => this.setState({ overVenueDrawn: null })}
                style={{ fontWeight: vertice.id === this.state.overVenueDrawn ? 'bold': 'normal' }}
              >
                <td>{vertice.venueId}</td>
                <td>{vertice.description}</td>
                <td>{getVenueTypeDescription(vertice)}</td>
                <td>
                  {this.state.removing[vertice.id]
                    ? <span className={`button is-danger is-loading`}></span>
                    : <span
                      className="button is-danger"
                      onClick={this._removeDrawFromVenue(vertice.id)}>
                      <Icons.TrashIcon />
                    </span>}
                </td>
              </tr>)}
            </tbody>
          </table>
        </Column>}
      </Columns>
    </div>);
  }
}

export default (CameraAreaV2);