
import React, { Component } from 'react';
import _ from 'lodash';
import {format} from 'date-fns';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ReferenceLine, Legend
} from 'recharts';


export default class TemperatureChart extends Component {
  render() {
    const {data=[], formatDate='HH:mm:ss', venue={gps:false}} = this.props;
    const min = _.minBy(data, (arr) => arr.temperature)|| {temperature: 0};
    const max = _.maxBy(data, (arr) => arr.temperature) || {temperature: 0};
    const minH = _.minBy(data, (arr) => arr.humidity)|| {humidity: 0};
    const maxH = _.maxBy(data, (arr) => arr.humidity) || {humidity: 0};
    const minHumidity = minH.humidity < 2 ? 0 : minH.humidity -2  

    return (
      <ResponsiveContainer 
        width={'100%'}
        height={200}
      >
        
        <LineChart
          data={data}
          margin={{
            top: 0, right: 30, left: 20, bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="collectTime" tickFormatter={date => format(date, formatDate) } />
          <YAxis orientation="left"  yAxisId="1" domain={[min.temperature -2, max.temperature +2]} type="number"  stroke="#343434" tickFormatter={(val) => `${Number(val).toFixed(1)}˚C`}/>
          {venue.hasHumi && <YAxis orientation="right"  yAxisId="2" domain={[minHumidity, maxH.humidity +2]} type="number"  stroke="#343434" tickFormatter={(val) => `${Number(val).toFixed(1)}%`} />}
          <Legend />
          <ReferenceLine  yAxisId="1" label="Maxima Permitida" y={venue.maxTemp} />
          <ReferenceLine  yAxisId="1" label="Minima Permitida" y={venue.minTemp} />
          <Tooltip labelFormatter={date => format(date, formatDate) }/>
          <Line connectNulls={true} dot={false} unit="˚C" name={ `Temperatura ${venue.multi_thermo_part ? '1': ''}`} yAxisId="1" dataKey="temperature" stroke="#0043ff" />
          {venue.multi_thermo_part && <Line connectNulls={true} dot={false} unit="˚C" name="Temperatura 2"  yAxisId="1" dataKey="temperature_2" stroke="#00d1b2"  /> }
          {venue.hasHumi && <Line connectNulls={true} dot={false} unit="%" name="Umidade"  yAxisId="2" dataKey="humidity" stroke="hsl(281.3, 100%, 41%)"  /> }
        </LineChart>
      </ResponsiveContainer>
      );
  }
}
