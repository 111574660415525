import React, { Component } from 'react';

import _ from 'lodash';

import {
  format,
  addDays,
  differenceInDays,
  startOfDay,
  endOfDay
} from 'date-fns';

import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  Legend,
  CartesianGrid,
  Tooltip,
  LabelList
} from 'recharts';

import * as Icons from '../../../../../components/icons';

import Columns, { Column } from '../../../../../components/Columns';
import DateFilterTwoRanges from './DateFilterTwoRanges';
import LabelCustomizedAuditing from './LabelCustomizedAuditing';
import { getServerDate } from '../../../../../utils';


class PeoplecountChart extends Component {

  constructor(props) {
    super(props);
    
    const CURRDATE = addDays(getServerDate(), -1);

    this.state = {
      selectedIndice: 'passersByCount',
      isLoading: false,
      typeChart: 'DAILY',
      filterRanges: {
        startDate: addDays(CURRDATE, -6),
        endDate: CURRDATE,
        key: 'selectionRange1'
      },
      filterRanges2: {
        startDate: addDays(CURRDATE, -13),
        endDate: addDays(CURRDATE, -7),
        key: 'selectionRange2'
      },
      dash: {
        header: {},
        data: []
      }
    }
  }

  componentDidMount() {
    this.loadData();
  }

  renderChartLegend = (props) => {
    const { payload } = props;
    
    if(!payload) {
      return <div></div>;
    }

    const first = payload[0];
    const indice = _.last(first.dataKey.split('.'));
    const indiceLabel = indice === "meanTime" ? "Tempo médio"
      : indice === "conversionRate" ? "Taxa de visitantes"
      : indice === "passersByCount" ? "Passantes"
      : indice === "visitorsCount" ? "Visitantes"
      : "";

    const payloads = payload.map((entry, index) => {
      const { color } = entry;
      const indice = _.first(entry.dataKey.split('.'));
      const { filterRanges, filterRanges2 } = this.state;
      if(indice === "periodo2") {
        return { indice, name: <span><b>{format(filterRanges2.startDate, "DD/MM/YY")}</b> <i>até</i> <b>{format(filterRanges2.endDate, "DD/MM/YY")}</b></span>, color }
      } else if(indice === "periodo1") {
        return { indice, name: <span><b>{format(filterRanges.startDate, "DD/MM/YY")}</b> <i>até</i> <b>{format(filterRanges.endDate, "DD/MM/YY")}</b></span>, color }
      } else {
        return { indice, name: "Taxa de variação entre os períodos", color }
      }
    });

    const periodo1 = payloads.find(p => p.indice === 'periodo1');
    const periodo2 = payloads.find(p => p.indice === 'periodo2');

    return (
      <div>
        <p className="help">Indicador de <b>{indiceLabel}</b> com a comparação entre dois períodos.</p>
        <ul>
          <li>
            <b style={{ fontSize: '12px', color: periodo2.color }}>{indiceLabel} em </b>
            {periodo2.name}
          </li>
          <li>
            <b style={{ fontSize: '12px', color: periodo1.color }}>{indiceLabel} em </b>
            {periodo1.name}
          </li>
        </ul>
          
      </div>
    );
  }

  _changeTypeChart = (e) => {
    let { filterRanges, filterRanges2 } = this.state;

    const CURRDATE = addDays(getServerDate(), -1);

    if(e.target.value === 'HOURLY') {
      filterRanges = {
        startDate: startOfDay(CURRDATE),
        endDate: endOfDay(CURRDATE),
        key: 'selectionRange1'
      };

      filterRanges2 = {
        startDate: startOfDay(addDays(CURRDATE, -1)),
        endDate: endOfDay(addDays(CURRDATE, -1)),
        key: 'selectionRange2'
      }
    }

    this.setState({ typeChart: e.target.value, filterRanges, filterRanges2 }, this.loadData);
  }
  
  _changeDateFilter = () => (ranges, ranges2) => {
    this.setState({ filterRanges: ranges, filterRanges2: ranges2 }, () => {
      this.loadData();
    });
  }

  loadData = () => {
    this.setState({ isLoading: true }, () => {
      const { typeChart, filterRanges, filterRanges2 } = this.state;
      this.props.loadPeopleCount(typeChart, filterRanges.startDate, filterRanges.endDate, filterRanges2.startDate, filterRanges2.endDate)
        .then(result => {
          const {
            periodo1={}
          } = result.header;

          const whichMenu = (periodo1.passersByCount > 0 || (periodo1.passersByCount === 0 && periodo1.visitorsCount === 0)) ?
            'passersByCount' : 'visitorsCount';

          this.setState({
            isLoading: false,
            dash: result,
            selectedIndice: whichMenu
          });
        });
    });
  }

  selectIndice = (indice) => {
    this.setState({ selectedIndice: indice });
  }

  render() {
    const {
      isLoading,
      dash,
      selectedIndice,
      filterRanges,
      filterRanges2
    } = this.state;

    const {
      periodo1={},
      periodo2={},
      variation={}
    } = dash.header;

    return <div className="tile notification is-white is-paddingless">
      <div className="card tile is-vertical is-12 is-paddingless" style={{ width: '100%' }}>
        <div className="card-content tile is-paddingless"> 
          <div className="tile is-ancestor is-paddingless">
            <div className="tile is-12 is-vertical ">
              <div className="tile is-vertical is-parent has-text-centered">
                <Columns isMultiline isGapless style={{ width: '100%' }}>
                  <Column isSize={12}>
                    <Columns isGapless>
                      <Column>
                        <div
                          style={{ borderTop: `4px solid none` }}
                          className={`content isScore-indice  ${selectedIndice === 'passersByCount' ? 'indice-active' : ''}`}
                          onClick={(e) => this.selectIndice('passersByCount', e)}>
                            <div className="has-text-centered">
                              <span className="heading" style={{ marginBottom: '0px'}}>
                                <span>Passantes</span>
                                <Icons.FootsIcon />
                              </span>
                              <span className="title is-1">
                                {isLoading || !periodo1 
                                  ? <span className="button hv-no-background is-large is-loading is-bordless"/> 
                                  : <span>{periodo1.passersByCount || 0}</span>}
                              </span>
                              {!isLoading && <div>
                                <span title={`Variação em relação à ${differenceInDays(periodo1.info_date, periodo2.info_date)} dias atrás`}>
                                  {variation.passersBy > 0 
                                    ? <span style={{ color: 'green' }}>
                                        <Icons.CaretUpIcon style={{ marginRight: '-5px' }}/>
                                        {variation.passersBy || 0}%
                                      </span>
                                    : <span style={{ color: 'red' }}>
                                        <Icons.CaretDownIcon  style={{ marginRight: '-5px' }}/>
                                        {variation.passersBy || 0}%
                                      </span>}
                                </span>
                              </div>}
                            </div>
                        </div>
                      </Column>
                      <Column>
                        <div
                          style={{ borderTop: `4px solid none` }}
                          className={`content isScore-indice  ${selectedIndice === 'visitorsCount' ? 'indice-active' : ''}`}
                          onClick={(e) => this.selectIndice('visitorsCount', e)}>
                            <div className="has-text-centered">
                              <span className="heading" style={{ marginBottom: '0px'}}>
                                <span>Visitantes</span>
                                <Icons.WalkingIcon />
                              </span>
                              <span className="title is-1">
                                {isLoading 
                                  ? <span className="button hv-no-background is-large is-loading is-bordless"/> 
                                  : <span>{periodo1.visitorsCount || 0}</span>}
                              </span>
                              {!isLoading && <div>
                                <span title={`Variação em relação à ${differenceInDays(periodo1.info_date, periodo2.info_date)} dias atrás`}>
                                  {variation.visitors > 0 
                                    ? <span style={{ color: 'green' }}>
                                        <Icons.CaretUpIcon style={{ marginRight: '-5px' }}/>
                                        {variation.visitors || 0}%
                                      </span>
                                    : <span style={{ color: 'red' }}>
                                        <Icons.CaretDownIcon  style={{ marginRight: '-5px' }}/>
                                        {variation.visitors || 0}%
                                      </span>}
                                </span>
                              </div>}
                            </div>
                        </div>
                      </Column>
                      <Column>
                        <div
                          style={{
                            borderTop: '4px solid none'
                          }}
                          className={`content isScore-indice  ${selectedIndice === 'conversionRate' ? 'indice-active' : ''}`}
                          onClick={(e) => this.selectIndice('conversionRate', e)}>
                            <div className="has-text-centered">
                              <span className="heading" style={{ marginBottom: '0px'}}>
                                <span>Taxa de visitantes</span>
                                <Icons.DoorOpenIcon />
                              </span>
                              <span className="title is-1">
                                <span>
                                  {isLoading
                                    ? <span className="button hv-no-background is-large is-loading is-bordless"/> 
                                    : <span>{periodo1.conversionRate}</span>}
                                </span>
                                <span style={{ fontSize: '20px', marginLeft: '4px' }}> %</span>
                              </span>
                              {!isLoading && <div>
                                <span title={`Variação em relação à ${differenceInDays(periodo1.info_date, periodo2.info_date)} dias atrás`}>
                                  {variation.conversionRate > 0 
                                    ? <span style={{ color: 'green' }}>
                                        <Icons.CaretUpIcon style={{ marginRight: '-5px' }}/>
                                        {variation.conversionRate}%
                                      </span>
                                    : <span style={{ color: 'red' }}>
                                        <Icons.CaretDownIcon  style={{ marginRight: '-5px' }}/>
                                        {variation.conversionRate}%
                                      </span>}
                                </span>
                              </div>}
                            </div>
                        </div>
                      </Column>
                      <Column>
                        <div
                          style={{
                            borderTop: '4px solid none'
                          }}
                          className={`content isScore-indice  ${selectedIndice === 'meanTime' ? 'indice-active' : ''}`}
                          onClick={(e) => this.selectIndice('meanTime', e)}>
                            <div className="has-text-centered">
                              <span className="heading" style={{ marginBottom: '0px'}}>
                                <span>Tempo médio</span>
                                <Icons.ClockIcon />
                              </span>
                              <span className="title is-1">
                                <span>
                                  {isLoading 
                                    ? <span className="button hv-no-background is-large is-loading is-bordless"/> 
                                    : <span>{periodo1.meanTime && Math.round(periodo1.meanTime / 60)}</span>}
                                </span>
                                <span style={{ fontSize: '10px', marginLeft: '4px' }}>MIN</span>
                              </span>
                              {!isLoading && <div>
                                <span title={`Variação em relação à ${differenceInDays(periodo1.info_date, periodo2.info_date)} dias atrás`}>
                                  {variation.meanTime > 0 
                                    ? <span style={{ color: 'green' }}>
                                        <Icons.CaretUpIcon style={{ marginRight: '-5px' }}/>
                                        {variation.meanTime}%
                                      </span>
                                    : <span style={{ color: 'red' }}>
                                        <Icons.CaretDownIcon  style={{ marginRight: '-5px' }}/>
                                        {variation.meanTime}%
                                      </span>}
                                </span>
                              </div>}
                            </div>
                        </div>
                      </Column>
                    </Columns>
                  </Column>
                  <Column isSize={12}>
                    <p className="help has-text-centered" style={{ marginTop: '15px' }}>
                      Os números no cabeçalho pertencem ao dia <span className="has-text-weight-bold">{periodo1.info_date ? format(periodo1.info_date, 'DD/MM/YY') : '...'}</span>
                    </p>
                  </Column>
                  <Column isSize={12}>
                    {isLoading 
                    ? <div className="has-text-centered" style={{ height:300, paddingTop: '70px' }}>
                        <span>
                          <span className="button is-loading is-large is-bordless" />
                          <p className="subtitle is-5">Aguarde, carregando...</p>
                        </span>
                      </div>
                    : <ResponsiveContainer width="100%" height={350}>
                      <ComposedChart data={dash.data}
                          margin={{top: 20, right: 50, left: 10, bottom: 20}}>
                        <CartesianGrid strokeDasharray="3 10" />
                        <XAxis dataKey="label" tickFormatter={(val) => `${this.state.typeChart === 'DAILY' ? formatVs(val) : format(val, 'HH:mm')}`}/>
                        <YAxis yAxisId="left" orientation="left" stroke="#343434"/>
                        <YAxis yAxisId="right" orientation="right" stroke="#343434" tickFormatter={(val) => `${val}%`} domain={['dataMin-100', 'dataMax+5']}/>
                        <Legend content={this.renderChartLegend} />

                        <Tooltip
                          content={<CustomToolTip selectedIndice={selectedIndice} />}
                          isAnimationActive={false}/>

                        <Bar key={selectedIndice}
                          yAxisId="left"
                          name='Período 2'
                          dataKey={`periodo2.${selectedIndice}`}
                         maxBarSize={15}
                          fill="#00d1b2"
                          isAnimationActive={false}
                          minPointSize={3}
                          connectNulls={true}
                        >
                          <LabelList
                            dataKey="periodo2.auditingOk"
                            position="top"
                            content={<LabelCustomizedAuditing />}
                          />
                        </Bar>
                        <Bar key={`${selectedIndice}2`}
                          yAxisId="left"
                          name='Período 1'
                         maxBarSize={15}
                          dataKey={`periodo1.${selectedIndice}`}
                          fill="#0043ff"
                          isAnimationActive={false}
                          minPointSize={3}
                          connectNulls={true}
                          >
                            <LabelList
                              dataKey="periodo1.auditingOk"
                              position="top"
                              content={<LabelCustomizedAuditing />}
                            />
                          </Bar>
                        <Line key={`${selectedIndice}3`}
                          yAxisId="right"
                          name={`Variação`}
                          dataKey={`variation.${selectedIndice === 'passersByCount' ? 'passersBy' : selectedIndice === 'visitorsCount' ? 'visitors' : selectedIndice }`}
                          strokeDasharray="3 5"
                          fill="#000"
                          stroke="#000"
                          isAnimationActive={false}
                          connectNulls={true}
                        >
                          <LabelList
                            dataKey={`variation.${selectedIndice === 'passersByCount' ? 'passersBy' : selectedIndice === 'visitorsCount' ? 'visitors' : selectedIndice }`}
                            position="top"
                            fontSize={12}
                            formatter={(val) => `${val}%`}
                          />
                        </Line> 
                      </ComposedChart>
                    </ResponsiveContainer>} 
                  </Column>
                  
                </Columns>
              </div>
            </div>
          </div>
        </div> 
        <footer className="card-footer tile is-paddingless" style={{ maxHeight: '37px' }}>
          <span className="button has-text-left is-white card-footer-item" style={{ borderRight: 'none' }}>
            <DateFilterTwoRanges changeDates={this._changeDateFilter()} actualRanges={{ ranges: filterRanges, ranges2: filterRanges2 }}/>
          </span>
          <span className="card-footer-item"  style={{ borderRight: 'none' }}>
          </span>
          <span className="card-footer-item" >
            
          </span>
        </footer>
      </div>
    </div>;
  }
}


class CustomToolTip extends Component {

  description = (indice) => indice === 'passersByCount' ? 'Passantes' : indice === 'visitorsCount' ? 'Visitantes' : indice === 'meanTime' ? 'Tempo médio' : 'Taxa de visitantes';

  render() {
    const { active, selectedIndice } = this.props;
    if(active) {
      const { payload=[] } = this.props;
      const data = payload[0] || { payload: {} };

      return (<div className="card">
        <div  style={{ padding: '0px 5px 0px 5px' }}>
          <p className="heading has-text-centered">
            {format(data.payload.periodo2.info_date, 'DD/MM')} vs {format(data.payload.periodo1.info_date, 'DD/MM')}
          </p>

          <span className="heading has-text-centered" style={{ fontWeight: 'bold', marginBottom: '-8px' }}>
            <span>{this.description(selectedIndice)}</span>
            {data.payload.variation[selectedIndice === 'passersByCount' ? 'passersBy' : selectedIndice === 'visitorsCount' ? 'visitors' : selectedIndice] > 0 
              ? <span style={{ color: 'green' }}>
                  <Icons.CaretUpIcon style={{ marginRight: '-5px' }}/>
                  {data.payload.variation[selectedIndice === 'passersByCount' ? 'passersBy' : selectedIndice === 'visitorsCount' ? 'visitors' : selectedIndice]}%
                </span>
              : <span style={{ color: 'red' }}>
                  <Icons.CaretDownIcon  style={{ marginRight: '-5px' }}/>
                  {data.payload.variation[selectedIndice === 'passersByCount' ? 'passersBy' : selectedIndice === 'visitorsCount' ? 'visitors' : selectedIndice]}%
                </span>}
          </span>
          <span className="heading has-text-centered">
            <span>{data.payload.periodo2[selectedIndice]} - {data.payload.periodo1[selectedIndice]}</span>
          </span>

          {!data.payload.periodo1.auditingOk && <span
            className="help is-danger">
            <Icons.ExclamationCircleIcon />
            <span>
              {data.payload.periodo1.info_date} - dados não confiáveis.
            </span>
          </span>}

          {!data.payload.periodo2.auditingOk && <span
            className="help is-danger">
            <Icons.ExclamationCircleIcon />
            <span>
              {data.payload.periodo2.info_date} - dados não confiáveis.
            </span>
          </span>}

        </div>
      </div>)
    }
    return null;
  }
}

const formatVs = (val) =>{
  const valArr = val.split(' ');
  return format(valArr[2], 'DD/MM') + ' vs \n' + format(valArr[0], 'DD/MM')
}

export default PeoplecountChart;