import React, { Component } from 'react';

import PictureFrame from './PictureFrame';
import { IdBadgeIcon } from '../../../../../components/icons';
import { format } from 'date-fns';
import {pt} from 'date-fns/locale';
import Columns, { Column } from '../../../../../components/Columns';
import { get,post, del } from '../../../../../utils';


class ModalAnalysis extends Component {

  constructor(props) {
    super(props);

    this.state = {
      currentSecondPosition: 0,
      camerasToCount: props.audit.cameras,
      totalFotos: 0,
      secondsToCount: [],
      selectedBoxes: [],
      isloading: false,
      isloadingUpload: false,
      isloadingFrames: false,
      framesCounted: [],
      removingIds: {},
      vertices: {},
      tags: [],
      tagsSugestion: []
    }
  }

  componentDidMount() {
    const { site, audit } = this.props;

    this.setState({ isloading: true }, () => {
      get(`dashboard/video/sites/${site.id}/audit/${audit.id}/images`)
      .then(resp => {
        this.setState({
          totalFotos: resp.data.data.totalImages,
          secondsToCount: resp.data.data.timestampSeconds,
          currentSecondPosition: resp.data.data.currentPosition
        }, () => {
          get(`dashboard/video/sites/${site.id}/audit/${audit.id}/tags`)
            .then((resp) => this.setState({ isloading: false, tags: resp.data.data }));

          get(`dashboard/video/sites/${site.id}/audit/${audit.id}/tagssugestion`)
            .then((resp) => this.setState({ tagsSugestion: resp.data.data }));
        });
      });
    });

    this._loadFrames()
  }

  _loadFrames = () =>{
    const { site, audit } = this.props;
    this.setState({ isloadingFrames: true }, () => {
      get(`dashboard/video/sites/${site.id}/audit/${audit.id}/person-frames`)
        .then(resp => {
          this.setState({
            isloadingFrames: false,
            framesCounted: resp.data.data
          });
        });
    })
  }

  _loadNext = (times) => () => {
    const { currentSecondPosition=0 } = this.state;
    const currentSecondPositionNext = currentSecondPosition + times;
    this.setState({ currentSecondPosition: currentSecondPositionNext, selectedBoxes: [], createdBoxes: [] });
  }
  
  _loadPrevious = (times) => () => {
    const { currentSecondPosition=0 } = this.state;
    const currentSecondPositionNext = currentSecondPosition - times;
    this.setState({ currentSecondPosition: currentSecondPositionNext, selectedBoxes: [], createdBoxes: [] });
  }

  _uploadSelected = () => {
    const { site, audit } = this.props;
    const { selectedBoxes, camerasToCount, secondsToCount, currentSecondPosition, framesCounted } = this.state;

    const currentSecond = secondsToCount[currentSecondPosition];
    const images = camerasToCount.map(camera => {
      const imagesInSecond = currentSecond.images.filter(image => image.camera_id === camera.id);
      return imagesInSecond[0]
    });

    const persons = selectedBoxes.map(sel => sel.box);
    const payload = images.filter(img => img !== undefined).map(img => {
      const {camera_id, image, imageUrl, url} = img;
      const selectedPerson = persons.filter(p => p.image_url === url)
      return {image_url: url, 
              snap_time: currentSecond.snap_time, 
              storage_url: imageUrl, 
              camera_read: image, camera_id, 
              persons: selectedPerson}
    });

    this.setState({isloadingUpload: true}, () => {
      const postPromise = payload.map(async pay => post(`dashboard/video/sites/${site.id}/audit/${audit.id}/images`, pay))
      Promise.all(postPromise)
      .then((res) => {
        res.forEach(r => r.data.data.forEach(d => framesCounted.push(d)))
        this.setState({isloadingUpload: false, framesCounted})
        this._loadNext(2)()
        return get(`dashboard/video/sites/${site.id}/audit/${audit.id}/tagssugestion`)
      })
      .then((resp) => this.setState({ tagsSugestion: resp.data.data }))
      .catch(err => console.log(err))
    })
  }

  _handleCancelButton = () => {
    this.props.toogleModal();
  }

  _pushBox = (centroid, box) => {
    const { selectedBoxes, createdBoxes } = this.state;

    const alreadySelected = selectedBoxes.map(box => box.centroid).includes(centroid);
    if(alreadySelected) {
      this.setState({
        selectedBoxes: selectedBoxes.filter(box => box.centroid !== centroid),
        createdBoxes: createdBoxes.filter(box => box.centroid !== centroid)
      });
      return;
    }

    this.setState({
      selectedBoxes: [
        ...selectedBoxes,
        {
          centroid,
          box
        }
      ]
    });
  }

  _removePersonFromAudit = (personId) => () => {
    const {
      site,
      audit
    } = this.props;

    const { removingIds } = this.state;
    if(removingIds[personId])
      return;

    removingIds[personId] = true;

    this.setState({ removingIds }, () => {
      del(`dashboard/video/sites/${site.id}/audit/${audit.id}/person-frames/${personId}`)
        .then(resp => {
          const {
            framesCounted,
            removingIds
          } = this.state;

          delete removingIds[personId];
          this.setState({
            removingIds,
            framesCounted: framesCounted.filter(frame => frame.id !== personId)
          });
        });
    });

  }

  _createBox = (centroid, box, cb) => {
    const {
      createdBoxes=[]
    } = this.state;

    const index = createdBoxes.findIndex(box => box.centroid === centroid);
    if (box.tags && index !== -1) {
      createdBoxes[index] = {
        ...createdBoxes[index],
        box: {
          ...createdBoxes[index].box,
          tags: box.tags
        }
      }

      this.setState({ createdBoxes }, cb);
      return;
    }

    this.setState({
      createdBoxes: [
        ...createdBoxes,
        {
          centroid,
          box
        }
      ]
    }, () => {
      this._pushBox(centroid, box);
      cb();
    });
  }

  _handleRefreshTags = (tags) => {
    this.setState({ tags });
  }

  render() {
    const {
      day,
      hour,
      audit,
      site
    } = this.props;

    const {
      isloading=false,
      totalFotos,
      currentSecondPosition,
      secondsToCount=[],
      camerasToCount,
      selectedBoxes=[],
      isloadingUpload = false,
      isloadingFrames=false,
      framesCounted=[],
      createdBoxes=[],
      removingIds={},
      vertices={},
      tags,
      tagsSugestion
    } = this.state;

    const currentSecond = secondsToCount[currentSecondPosition];
    const acabouContagem = totalFotos - 1 === currentSecondPosition;

    
    
    return <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card" style={{ backgroundColor: 'white', width: '95%', height: '90%', borderRadius: 5  }}>
        <div className="modal-card-body">
          <p className="title is-4 has-text-left">
            <IdBadgeIcon />
            <span>
              Análise eventos
            </span>
          </p>
          <p className="subtitle is-5 has-text-left">
            <b>#{audit.id}</b> "{audit.description}" {format(day.snap_date, 'DD MMMM', { locale: pt })} {hour.hour} até {hour.hour.replace('00:00', '59:59')}
          </p>
          <Columns isMultiline style={{
            height: '80%'
          }}>
            <Column isSize={12} style={{
              display: 'flex',
              alignItems: 'center',
              verticalAlign: 'middle'
            }}>
              {isloading && <p className="subtitle is-5 has-text-centered" style={{ width: '100%' }}>
                Aguarde sincronizando fotos para auditar...
              </p>}
              {!isloading && !currentSecond && <p className="subtitle is-5">Não há fotos disponíveis para auditoria.</p>}
              {!isloading && currentSecond && <div style={{ display: 'flex', flexDirection: 'row', maxWidth: '100%', overflow: 'auto'}}>
              {camerasToCount.map(camera => {
                const cameraVertices = vertices[camera.id];
                const imagesInSecond = currentSecond.images.filter(image => image.camera_id === camera.id);
                return <div key={`cameras_images_counter_id_${camera.id}`} style={{ marginRight: 10, display: 'flex', flexDirection: 'column' }}>
                  <p className="help heading"><b>#{camera.id}</b>{camera.description}</p>
                  {imagesInSecond.length === 0 && <p className="subtitle is-5" style={{width: 640, height: 360}}>
                    Não há fotos
                  </p>}
                  {imagesInSecond.length >= 1 && [imagesInSecond[0]].map(frame => <PictureFrame
                    isAnalysis={true}
                    tags={tags}
                    tagsSugestion={tagsSugestion}
                    siteId={site.id}
                    auditId={audit.id}
                    handleRefreshTags={this._handleRefreshTags}
                    key={`frame_url_${frame.url}`}
                    imageUrl={frame.url}
                    cameraId={camera.id}
                    cameraVertices={cameraVertices}
                    boxes={[
                      ...frame.boxes,
                      ...createdBoxes
                        .filter(person => person.box.camera_id === camera.id)
                        .map(person => person.box)
                    ]}
                    newBox={this._createBox}
                    selectedBoxes={selectedBoxes}
                    pushBox={this._pushBox}
                  />)}
                </div>})}
              </div>}
            </Column>
            <Column isSize={12} style={{
              display: 'flex',
              alignItems: 'center',
              verticalAlign: 'middle'
            }}>
              {isloadingFrames && <p className="subtitle is-5">Aguarde, localizando pessoas já contadas...</p>}
              {!isloading && !isloadingFrames && <div style={{ height: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                {framesCounted.map(frame => {
                  return <div key={`person_counted_${frame.id}`} style={{
                    width: 64,
                    margin: 5,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                  }}>
                    <img alt="personimage" src={frame.image_url} style={{
                      width: 64,
                      height: 128,
                      marginBottom: 5
                    }}/>
                    <span className={`button is-danger is-outlined is-small ${removingIds[frame.id] ? 'is-loading': ''}`} onClick={removingIds[frame.id] ? () => {} : this._removePersonFromAudit(frame.id)}>remover</span>
                  </div>
                })}
              </div>}
            </Column>
          </Columns>
        </div>
        <footer className="modal-card-foot">
          <Columns style={{ width: '100%', justifyContent: 'space-between' }}>
            <Column className="is-narrow">
            </Column>
            {currentSecond && acabouContagem && <Column className="is-narrow">
              <p className="subtitle is-4 has-text-centered">Concluído!</p>
            </Column>}
            {currentSecond && !acabouContagem && <Column className="is-narrow">
              {currentSecond && <p className="subtitle is-5 has-text-centered" style={{marginBottom: 5}}>Imagem {currentSecondPosition + 1} de {totalFotos} </p>}
              {currentSecond && <div className="buttons is-centered" style={{marginBottom: 5}}>
                <span className="button" onClick={this._loadPrevious(2)}>Voltar</span>
                <span className={`button is-primary ${isloadingUpload ? 'is-loading': ''}`} onClick={() => this._uploadSelected()}>Confirmar e avançar</span>
                <span className="button" onClick={this._loadNext(2)}>Avançar</span>
              </div>}
              {currentSecond && <progress
                className={`progress ${true ? 'is-primary':'is-success'}`}
                value={currentSecondPosition < 20 ? 20 : currentSecondPosition}
                max={totalFotos}>
              </progress>}
            </Column>}
            <Column className="is-narrow">
            </Column>
          </Columns>
        </footer>
      </div>
      <button className="modal-close is-large" onClick={this._handleCancelButton} aria-label="close"></button>
    </div>
  }
}

export default ModalAnalysis