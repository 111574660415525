import React, { Component } from 'react';
import Columns, { Column } from '../../../../components/Columns';
import {
  CalendarAltIcon,
  MicroChipIcon,
  MinusIcon,
  PlusCircleIcon,
  SalesIcon,
  SyncAltIcon
} from '../../../../components/icons';

import _ from 'lodash';

import {format} from 'date-fns'
import {pt} from 'date-fns/locale'

import { del, get, post, put } from '../../../../utils';
import notification from '../../../../utils/notification';

import { STATUS_AUDITORIA } from './enums';
import SalesChartPreview from './AuditsSalesChartPreview';
import { ResponsiveContainer, Scatter, ScatterChart, XAxis, YAxis, ReferenceLine, Tooltip } from 'recharts';
import CartesianGrid from 'recharts/lib/cartesian/CartesianGrid';


export default class AuditDashboard extends Component {

  constructor(props) {
    super(props);

    this.state = {
      days: [],
      closeDesconsiderados: true,
      closeEmAndamento: true,
      closeFinalizado: true,
      attemptStatus: {},
      isLoadingAudit: false,
      audits: [],
      attempts: [],
      dashboardData: [],
      considerarLoading: {},
      desconsiderarLoading: {},
      naoIniciadoLoading: {},
      descriptionSelected: [],
      attemptsToHide: [],
      toogleNewAttempt: false,
      submissionDynamicFields: {
        dbSize: 300,
        reid: [],
        detectionsInHour: [],
        sales: [],
        minThreshold: 3,
        maxThreshold: 7
      },
      submissionFields: {
        dbSize: 300,
        reidThreshold: 4.5,
        snapDate: null
      },
      tryDrop: {},
      deleting: {},
      showAllAttemptsInScatter: true,
      useSalesForRegression: 0
    };
  }

  componentDidMount() {
    this._loadAuditAndAttempts();
    this._loadAttemptsStatus();
  }

  _loadAuditAndAttempts = () => {
    const { site } = this.props;
    this.setState({ isLoadingAudit: true }, () => {
      const promise1 = get(`dashboard/video/sites/${site.id}/allaudit?auditType=visitors`)
        .then(response => response.data.data)
        .catch(err => []);
      const promise2 = get(`dashboard/video/sites/${site.id}/attempts`)
        .then(response => response.data.data)
        .catch(err => []);

      Promise.all([promise1, promise2])
        .then(async ([promise1, promise2]) => {
          const daysReduced = promise1.reduce((prev, curr) => {
            prev[curr.snapDate] = true;
            return prev;
          }, {});

          const days = Object.keys(daysReduced);
          const daysSales = await get(`dashboard/foottraffic/sites/${site.id}/salesindays`, {
            days: days.join(',')
          })
          .then(response => response.data.data)
          .catch(err => []);


          this.setState({
            isLoadingAudit: false,
            audits: promise1,
            attempts: promise2,
            dashboardData: daysSales
          })
        });
    })
  }

  _setStatus = (auditId, status) => {
    const { site } = this.props;
    const desconsiderarLoading = {...this.state.desconsiderarLoading};
    const considerarLoading = { ...this.state.considerarLoading };
    const naoIniciadoLoading = { ...this.state.naoIniciadoLoading };

    if(desconsiderarLoading[auditId] || considerarLoading[auditId] || naoIniciadoLoading[auditId]) {
      return;
    }

    if(status === STATUS_AUDITORIA.NAO_INICIADO) {
      naoIniciadoLoading[auditId] = true;
    }
    if(status === STATUS_AUDITORIA.FINALIZADO) {
      considerarLoading[auditId] = true;
    }
    if(status === STATUS_AUDITORIA.DESCONSIDERAR) {
      desconsiderarLoading[auditId] = true;
    }

    this.setState({ naoIniciadoLoading, considerarLoading, desconsiderarLoading }, () => {
      put(`dashboard/video/sites/${site.id}/audit/${auditId}/status`, {
        status
      }).then(resp => {
        delete naoIniciadoLoading[auditId];
        delete considerarLoading[auditId];
        delete desconsiderarLoading[auditId];

        const audits = this.state.audits.map(audit => {
          if(audit.id === auditId) {
            return {...audit, status }
          }
          return audit;
        })
        
        this.setState({ naoIniciadoLoading, considerarLoading, desconsiderarLoading, audits })
      })
      .catch(err => {
        delete naoIniciadoLoading[auditId];
        delete considerarLoading[auditId];
        delete desconsiderarLoading[auditId]
        this.setState({ naoIniciadoLoading, considerarLoading, desconsiderarLoading })
      })
    });
  }

  _selectAttempt = (description) => {
    const { descriptionSelected, attemptsToHide } = this.state;
    if(descriptionSelected.includes(description)) {
      this.setState({
        attemptsToHide: [ ...attemptsToHide.filter(at => at.description !== description) ],
        descriptionSelected: descriptionSelected.filter(sel => sel !== description)
      });
      return;
    }


    this.setState({ descriptionSelected: [...descriptionSelected, description] });
  }

  _hideAttempt = (description, snapDate, snapTime) => {
    const { attemptsToHide=[] } = this.state;
    this.setState({ attemptsToHide: [ ...attemptsToHide, { description, snapDate, snapTime }] })
  }

  _toogleIncludeNewAttempt = () => {
    const { toogleNewAttempt } = this.state;
    this.setState({ toogleNewAttempt: !toogleNewAttempt });
  }

  _changeSubmissionFields = (e) => {
    const { name, value } = e.target;

    const { submissionFields } = this.state;
    submissionFields[name] = value;
    this.setState({ submissionFields });
  }

  _dropAttempt = (description) => {
    const { site } = this.props;
    const { tryDrop, deleting } = this.state;

    if(tryDrop[description]) {

      if(deleting[description]) {
        return;
      }

      const endDelete = () => {
        const { deleting } = this.state;
          this.setState({
            deleting: {
              ...deleting,
              [description]: false
            }
          }, () => this._undoTryDrop(description));
      }

      this.setState({
        deleting: {
          ...deleting,
          [description]: true
        }
      }, () => {
        del(`dashboard/video/sites/${site.id}/attempts/${description}`)
        .then(resp => {

          const {
            attemptStatus,
            attempts
          } = this.state;

          delete attemptStatus[description];

          this.setState({
            attempts: attempts
              .filter(att => att.description !== description),
            attemptStatus: attemptStatus
          }, () => {
            notification.success('Removido com sucesso.');
            endDelete();
          });
        })
        .catch(err => {
          notification.error(`Não foi possível deletar. ${err.toString()}`)
          endDelete();
        });
      });
      return;
    }

    this.setState({
      tryDrop: {
        ...tryDrop,
        [description]: true
      }
    });
  }

  _undoTryDrop = (description) => {
    const { tryDrop } = this.state;

    this.setState({
      tryDrop: {
        ...tryDrop,
        [description]: false
      }
    });
  }
  
  _submitAttempt = (day, auditsReducedDay) => () => {
    const { site } = this.props;
    const { submissionFields, isSubmiting=false, isCoralFixed=0 } = this.state;
    const { dbSize, reidThreshold } = submissionFields;

    if(isSubmiting) {
      return;
    }

    if (day === 'todos') {
      const requests = auditsReducedDay.map(d => post(`dashboard/video/sites/${site.id}/attempts`, {
        dbSize,
        reidThreshold,
        snapDate: d,
        isCoral: parseInt(isCoralFixed),
        doVisitors: true
      }));

      Promise.all(requests)
        .then(resp => {
          notification.success('Submeteu com sucesso!');
          this.setState({ isSubmiting: false }, this._toogleIncludeNewAttempt);
        });
      
      return;
    }

    this.setState({ isSubmiting: true }, () => {
      post(`dashboard/video/sites/${site.id}/attempts`, {
        dbSize,
        reidThreshold,
        snapDate: day,
        isCoral: parseInt(isCoralFixed),
        doVisitors: true
      }).then(resp => {
        notification.success('Submeteu com sucesso!');
        this.setState({ isSubmiting: false }, this._toogleIncludeNewAttempt);
      });
    })

  }
  
  _submitAttemptDynamic = (days) => () => {
    const { site } = this.props;
    const { submissionDynamicFields, isSubmitingDynamic=false, isCoralDinamic=0, useSalesForRegression=0 } = this.state;
    const { dbSize, reid, detectionsInHour, minThreshold, maxThreshold, sales } = submissionDynamicFields;

    if(isSubmitingDynamic) {
      return;
    }

    this.setState({ isSubmitingDynamic: true }, () => {
      post(`dashboard/video/sites/${site.id}/attempts`, {
        dbSize,
        teach: {
          days,
          detectionsInHour,
          sales,
          reid,
          minThreshold,
          maxThreshold,
          doSales: parseInt(useSalesForRegression)
        },
        isCoral: parseInt(isCoralDinamic),
        doVisitors: true
      }).then(resp => {
        notification.success('Submeteu com sucesso!');
        this.setState({ isSubmitingDynamic: false });
      });
    })

  }
  
  _changeFieldsToDynamic = (attemptsToDisplay) => () => {
    const { submissionDynamicFields } = this.state;
    this.setState({
      submissionDynamicFields: {
        ...submissionDynamicFields,
        reid: attemptsToDisplay.map(attempt => attempt.reidThreshold),
        sales: attemptsToDisplay.map(attempt => attempt.totalSales),
        detectionsInHour: attemptsToDisplay.map(attempt => attempt.detectionsInHour),
      }
    })
  }

  _onChangeFieldsDynamicSubmission = (e) => {
    const { name, value } = e.target;
    const { submissionDynamicFields } = this.state;

    submissionDynamicFields[name] = value;
    this.setState({ submissionDynamicFields })
  }

  _loadAttemptsStatus = () => {
    const { site } = this.props;

    this.setState({ isLoadingStatus: true }, () => {
      get(`dashboard/video/sites/${site.id}/attemptsstatus`)
        .then(resp => {
          this.setState({ isLoadingStatus: false, attemptStatus: resp.data.data });
        });
    })

  }

  _selectAttemptPreview = (description) => {
    this.setState({
      showPreviewCharts: description
    });
  }

  render() {
    const {
      attempts,
      attemptsToHide=[],
      audits,
      dashboardData,
      desconsiderarLoading,
      considerarLoading,
      naoIniciadoLoading,
      descriptionSelected=[],
      toogleNewAttempt,
      submissionFields,
      isSubmiting=false,
      submissionDynamicFields,
      attemptStatus,
      isSubmitingDynamic,
      showPreviewCharts=null,
      tryDrop={},
      deleting={},
      isLoadingAudit
    } = this.state;

    const auditsReducedDay = Object.keys(audits
    .filter(audit => audit.status === STATUS_AUDITORIA.FINALIZADO)
    .reduce((prev, curr) => {
      prev[`${curr.snapDate}`] = true;
      return prev;
    }, {}));

    const auditsMerged = audits
      .map(auditoria => {
      const dashboardDataFromTime = dashboardData
        .find(dashdata => dashdata.info_date === `${auditoria.snapDate} ${auditoria.snapTime}`);
        
      const { visitors, total_sales, conversionRate } = dashboardDataFromTime;
      const auditoriaSaleRate = auditoria.visitors === 0 ? 0 : Math.round((total_sales / auditoria.visitors) * 100);
      const acuracia = Math.round((visitors / auditoria.visitors) * 100)

      const backgroundColorAcuracy = acuracia >= 75 && acuracia <= 125 ? '#34a853'
        : acuracia < 70 || acuracia > 130 ? '#d76359'
        : '#ffd666';

      return {
        id: auditoria.id,
        auditoriaDescription: auditoria.description,
        auditoriaSnapDate: auditoria.snapDate,
        auditoriaSnapTime: auditoria.snapTime,
        auditoriaVisitors: auditoria.visitors,
        dashVisitors: visitors,
        backgroundColorAcuracy,
        acuracia,
        totalSales: total_sales,
        auditoriaSaleRate,
        conversionRate,
        status: auditoria.status,
        desconsiderarLoading: desconsiderarLoading[auditoria.id],
        considerarLoading: considerarLoading[auditoria.id],
        naoIniciadoLoading: naoIniciadoLoading[auditoria.id]
      }
    });

    const auditsSummary = {
      audits: auditsMerged
        .filter(auditoria => auditoria.status === STATUS_AUDITORIA.FINALIZADO),
      auditsLowAccuracy: auditsMerged
        .filter(auditoria => auditoria.status === STATUS_AUDITORIA.FINALIZADO)
        .filter(audit => audit.acuracia < 70 || audit.acuracia > 130),
      auditsMedAccuracy: auditsMerged
        .filter(auditoria => auditoria.status === STATUS_AUDITORIA.FINALIZADO)
        .filter(audit => (audit.acuracia >= 70 && audit.acuracia < 75) || (audit.acuracia <= 130 && audit.acuracia > 125)),
      auditsGoodAccuracy: auditsMerged
        .filter(auditoria => auditoria.status === STATUS_AUDITORIA.FINALIZADO)
        .filter(audit => (audit.acuracia >= 75 && audit.acuracia <= 125))
    }

    const attemptsMerged = attempts.map(attempt => {
      const auditDataFromDay = auditsSummary.audits
        .find(auditoria => `${attempt.snapDate} ${attempt.snapTime}` === `${auditoria.auditoriaSnapDate} ${auditoria.auditoriaSnapTime}`) || {
          auditoriaVisitors: 0
        };

      const dashDataFromDay = dashboardData
        .find(dash => dash.info_date === `${attempt.snapDate} ${attempt.snapTime}`) || {};

      const totalSales = dashDataFromDay.total_sales ? dashDataFromDay.total_sales : 0;

      const auditoriaSaleRate = auditDataFromDay.auditoriaVisitors >= 0 ? Math.round((totalSales / auditDataFromDay.auditoriaVisitors) * 100) : 0;
      const attemptSaleRate = attempt.visitorsNew > 0 ? Math.round((totalSales / attempt.visitorsNew) * 100) : 0;
      
      const acuracia = auditDataFromDay.auditoriaVisitors > 0 ? Math.round((attempt.visitorsNew / auditDataFromDay.auditoriaVisitors) * 100) : 0;

      const backgroundColorAcuracy = acuracia >= 75 && acuracia <= 125 ? '#34a853'
        : acuracia < 70 || acuracia > 130 ? '#d76359'
        : '#ffd666';

      return {
        id: attempt.id,
        auditId: auditDataFromDay.id,
        description: attempt.description,
        snapDate: attempt.snapDate,
        snapTime: attempt.snapTime,
        visitorsAudit: auditDataFromDay.auditoriaVisitors,
        visitorsNew: attempt.visitorsNew,
        visitorsBefore: attempt.visitorsBefore,
        backgroundColorAcuracy,
        acuracia,
        totalSales,
        auditoriaSaleRate,
        attemptSaleRate,
        dbSize: attempt.dbSize,
        reidThreshold: attempt.reidThreshold,
        detectionsInHour: attempt.detectionsInHour,
        status: auditDataFromDay.status
      }
    });

    const attemptsReduced = attemptsMerged
      .filter(attempt => attempt.status === STATUS_AUDITORIA.FINALIZADO)
      .reduce((prev, curr) => {
      const descriptionAttempts = prev[curr.description] || [];
      descriptionAttempts.push(curr);
      prev[curr.description] = descriptionAttempts;
      return prev;
    }, {});

    const attemptsSelectedToShow = attemptsMerged
      .filter(attempt => descriptionSelected.includes(attempt.description));

    const attemptsToDisplay = attemptsSelectedToShow
      .filter(attempt => attempt.status === STATUS_AUDITORIA.FINALIZADO)
      .filter(attempt => {
        const attemptShouldBeHide = attemptsToHide
          .find(at => at.description === attempt.description
            && at.snapDate === attempt.snapDate
            && at.snapTime === attempt.snapTime);
        return !attemptShouldBeHide
      })
      .sort((a, b) => {
        return parseInt(format(`${a.snapDate} ${a.snapTime}`, 'x')) - parseInt(format(`${b.snapDate} ${b.snapTime}`, 'x'))
      });
    

    const attemptsPerformance = Object
      .keys(attemptsReduced)
      .map(description => {
        const attempts = attemptsReduced[description];

        const accuracies = attempts.map(attempt => attempt.acuracia);
        const auditoriaSaleRates = attempts.map(attempt => attempt.auditoriaSaleRate);
        const attemptSaleRates = attempts.map(attempt => attempt.attemptSaleRate);
        const meanAccuracy = Math.round(_.mean(accuracies));

        const backgroundColorAcuracy = meanAccuracy >= 75 && meanAccuracy <= 125 ? '#34a853'
          : meanAccuracy < 70 || meanAccuracy > 130 ? '#d76359'
          : '#ffd666';

        const auditsLowAccuracy = attempts
          .filter(audit => audit.acuracia < 70 || audit.acuracia > 130);

        const auditsMedAccuracy = attempts
          .filter(audit => (audit.acuracia >= 70 && audit.acuracia < 75) || (audit.acuracia <= 130 && audit.acuracia > 125));

        const auditsGoodAccuracy = attempts
          .filter(audit => (audit.acuracia >= 75 && audit.acuracia <= 125));

        return {
          description,
          attempts,
          backgroundColorAcuracy,
          auditsLowAccuracy,
          auditsMedAccuracy,
          auditsGoodAccuracy,
          meanAccuracy: meanAccuracy,
          meanAuditoriaSaleRates: Math.round(_.mean(auditoriaSaleRates)),
          meanAttemptSaleRates: Math.round(_.mean(attemptSaleRates))
        }
      });

    const attemptsPreviewChart = attempts.filter(attempt => attempt.description === showPreviewCharts);
    return <Columns isMultiline>
        {showPreviewCharts && <SalesChartPreview
          closeModal={() => this._selectAttemptPreview(null)}
          attempts={attemptsPreviewChart}
          dashboardData={dashboardData}
          audits={audits.filter(audit => audit.status === STATUS_AUDITORIA.FINALIZADO)} />}

        {/* Acuracia final dos dados no dash */}
        <Column isSize={12}>
          <div className="notification card" style={{ width: '100%' }}>
            <h1 className="subtitle is-4 cursor-pointer" onClick={() => this.setState({
              closeFinalizado: !this.state.closeFinalizado
            })}>Acurácia dados finalizados</h1>
            {!this.state.closeFinalizado && <table className="table is-hoverable">
              <thead>
                <tr>
                  <th>ID Auditoria</th>
                  <th>Dia Semana</th>
                  <th>Descrição auditoria</th>
                  <th>Dia</th>
                  <th>Hora</th>
                  <th>Visitantes Auditados</th>
                  <th>Visitantes Dashboard</th>
                  <th>Acurácia</th>
                  <th>Vendas</th>
                  <th>Taxa Vendas Auditado</th>
                  <th>Taxa Vendas Dashboard</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {auditsMerged
                  .filter(auditoria => auditoria.status === STATUS_AUDITORIA.FINALIZADO)
                  .map(auditoria => <tr>
                    <td>{auditoria.id}</td>
                  <td>{format(auditoria.auditoriaSnapDate, 'dddd', { locale: pt })}</td>
                  <td>{auditoria.auditoriaDescription}</td>
                  <td>{auditoria.auditoriaSnapDate}</td>
                  <td>{auditoria.auditoriaSnapTime}</td>
                  <td>{auditoria.auditoriaVisitors}</td>
                  <td>{auditoria.dashVisitors}</td>
                  <td style={{ backgroundColor: auditoria.backgroundColorAcuracy }}>{auditoria.acuracia}%</td>
                  <td>{auditoria.totalSales}</td>
                  <td>{auditoria.auditoriaSaleRate}%</td>
                  <td>{auditoria.conversionRate}%</td>
                  <td>
                    <span className={`button ${auditoria.desconsiderarLoading ? 'is-loading':''}`} onClick={() => this._setStatus(auditoria.id, STATUS_AUDITORIA.DESCONSIDERAR)}>Desconsiderar</span>
                  </td>
                  <td>
                    <span className={`button ${auditoria.naoIniciadoLoading ? 'is-loading':''}`} onClick={() => this._setStatus(auditoria.id, STATUS_AUDITORIA.NAO_INICIADO)}>Não iniciado</span>
                  </td>
                </tr>)}
              </tbody>
              <tfoot>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>{Math.round(_.mean(auditsMerged
                              .filter(auditoria => auditoria.status === STATUS_AUDITORIA.FINALIZADO)
                              .map(auditoria => auditoria.acuracia)))}%</td>
                  <td></td>
                  <td>{Math.round(_.mean(auditsMerged
                              .filter(auditoria => auditoria.status === STATUS_AUDITORIA.FINALIZADO)
                              .map(auditoria => auditoria.auditoriaSaleRate)))}%</td>
                  <td>{Math.round(_.mean(auditsMerged
                              .filter(auditoria => auditoria.status === STATUS_AUDITORIA.FINALIZADO)
                              .map(auditoria => auditoria.conversionRate)))}%</td>
                  <td></td>
                  <td></td>
                </tr>
              </tfoot>
            </table>}
          </div>
        </Column>

        <Column>
          <div className="notification card" style={{ width: '100%' }}>
            <Columns>
              <Column isSize='is-narrow' style={{ backgroundColor: '#d76359', borderRadius: '5px', margin: '5px' }}>
                Ruim: {Math.round((auditsSummary.auditsLowAccuracy.length / auditsSummary.audits.length) * 100)}%
              </Column>
              <Column isSize='is-narrow' style={{ backgroundColor: '#ffd666', borderRadius: '5px', margin: '5px' }}>
                Médio: {Math.round((auditsSummary.auditsMedAccuracy.length / auditsSummary.audits.length) * 100)}%
              </Column>
              <Column isSize='is-narrow' style={{ backgroundColor: '#34a853', borderRadius: '5px', margin: '5px' }}>
                Bom: {Math.round((auditsSummary.auditsGoodAccuracy.length / auditsSummary.audits.length) * 100)}%
              </Column>
            </Columns>
          </div>
        </Column>

        {/* Auditorias em andamento */}
        <Column isSize={12}>
          <div className="notification card" style={{ width: '100%' }}>
            <h1 className="subtitle is-4 cursor-pointer" onClick={() => this.setState({
              closeEmAndamento: !this.state.closeEmAndamento
            })}>Auditorias em andamento</h1>
            {!this.state.closeEmAndamento && <table className="table is-hoverable">
              <thead>
                <tr>
                  <th>ID Auditoria</th>
                  <th>Dia Semana</th>
                  <th>Descrição auditoria</th>
                  <th>Dia</th>
                  <th>Hora</th>
                  <th>Visitantes Auditados</th>
                  <th>Visitantes Dashboard</th>
                  <th>Acurácia</th>
                  <th>Vendas</th>
                  <th>Taxa Vendas Auditado</th>
                  <th>Taxa Vendas Dashboard</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {auditsMerged
                  .filter(auditoria => auditoria.status === STATUS_AUDITORIA.NAO_INICIADO)
                  .map(auditoria => <tr>
                    <td>{auditoria.id}</td>
                  <td>{format(auditoria.auditoriaSnapDate, 'dddd', { locale: pt })}</td>
                  <td>{auditoria.auditoriaDescription}</td>
                  <td>{auditoria.auditoriaSnapDate}</td>
                  <td>{auditoria.auditoriaSnapTime}</td>
                  <td>{auditoria.auditoriaVisitors}</td>
                  <td>{auditoria.dashVisitors}</td>
                  <td style={{ backgroundColor: auditoria.backgroundColorAcuracy }}>{auditoria.acuracia}%</td>
                  <td>{auditoria.totalSales}</td>
                  <td>{auditoria.auditoriaSaleRate}%</td>
                  <td>{auditoria.conversionRate}%</td>
                  <td>
                    <span className={`button ${auditoria.desconsiderarLoading ? 'is-loading':''}`} onClick={() => this._setStatus(auditoria.id, STATUS_AUDITORIA.DESCONSIDERAR)}>Desconsiderar</span>
                  </td>
                  <td>
                    <span className={`button ${auditoria.considerarLoading ? 'is-loading':''}`} onClick={() => this._setStatus(auditoria.id, STATUS_AUDITORIA.FINALIZADO)}>Considerar Finalizado</span>
                  </td>
                </tr>)}
              </tbody>
            </table>}
          </div>
        </Column>

        {/* Auditorias desconsideradas (muitas vezes desconsiderada temporariamente apenas para clarear alguma analise) */}
        <Column isSize={12}>
          <div className="notification card" style={{ width: '100%' }}>
            <h1 className="subtitle is-4 cursor-pointer" onClick={() => this.setState({
              closeDesconsiderados: !this.state.closeDesconsiderados
            })}>Auditorias desconsideradas</h1>
            {!this.state.closeDesconsiderados && <table className="table is-hoverable">
              <thead>
                <tr>
                  <th>ID Auditoria</th>
                  <th>Dia Semana</th>
                  <th>Descrição auditoria</th>
                  <th>Dia</th>
                  <th>Hora</th>
                  <th>Visitantes Auditados</th>
                  <th>Visitantes Dashboard</th>
                  <th>Acurácia</th>
                  <th>Vendas</th>
                  <th>Taxa Vendas Auditado</th>
                  <th>Taxa Vendas Dashboard</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {auditsMerged
                  .filter(auditoria => auditoria.status === STATUS_AUDITORIA.DESCONSIDERAR)
                  .map(auditoria => <tr>
                  <td>{auditoria.id}</td>
                  <td>{format(auditoria.auditoriaSnapDate, 'dddd', { locale: pt })}</td>
                  <td>{auditoria.auditoriaDescription}</td>
                  <td>{auditoria.auditoriaSnapDate}</td>
                  <td>{auditoria.auditoriaSnapTime}</td>
                  <td>{auditoria.auditoriaVisitors}</td>
                  <td>{auditoria.dashVisitors}</td>
                  <td style={{ backgroundColor: auditoria.backgroundColorAcuracy }}>{auditoria.acuracia}%</td>
                  <td>{auditoria.totalSales}</td>
                  <td>{auditoria.auditoriaSaleRate}%</td>
                  <td>{auditoria.conversionRate}%</td>
                  <td>
                    <span className={`button ${auditoria.naoIniciadoLoading ? 'is-loading':''}`} onClick={() => this._setStatus(auditoria.id, STATUS_AUDITORIA.NAO_INICIADO)}>Não iniciado</span>
                  </td>
                  <td>
                    <span className={`button ${auditoria.considerarLoading ? 'is-loading':''}`} onClick={() => this._setStatus(auditoria.id, STATUS_AUDITORIA.FINALIZADO)}>Considerar Finalizado</span>
                  </td>
                </tr>)}
              </tbody>
            </table>}
          </div>
        </Column>

        {/* Gerar tentativa de dado com parametros fixos */}
        <Column>
          <div className="notification card" style={{ width: '100%' }}>
            <h1 className="subtitle is-4">
              Tentativas {toogleNewAttempt ? <MinusIcon
                onClick={this._toogleIncludeNewAttempt}
                className="cursor-pointer"
                style={{
                  color: '#00d1b2',
                  marginLeft: 15
                }}
              /> : <PlusCircleIcon
              onClick={this._toogleIncludeNewAttempt}
              className="cursor-pointer"
              style={{
                color: '#00d1b2',
                marginLeft: 15
              }}
            />}
            </h1>
            {toogleNewAttempt && <div style={{ border: '1px solid #ccc', padding: 10 }}>
              <Columns>
                <Column isSize={2}>
                  <div className="field">
                    <label className="label">Dia</label>
                    <p className="control has-icons-left">
                      <span className="select">
                        <select name="snapDate" onChange={this._changeSubmissionFields} value={submissionFields.snapDate ? submissionFields.snapDate : auditsReducedDay[0]}>
                          {auditsReducedDay.map(day => <option key={'key_submission_snapDate'} value={day}>
                            {format(day, 'DD/MM/YY ddd', { locale: pt})}
                          </option>)}
                          <option value="todos">Todos os dias</option>
                        </select>
                      </span>
                      <CalendarAltIcon className="is-left" />
                    </p>
                  </div>
                </Column>
                <Column isSize={2}>
                  <div className="field">
                    <label className="label">Reid Threshold</label>
                    <div className="control">
                      <input
                        className="input"
                        type="number"
                        placeholder="ReID Threshold"
                        value={submissionFields.reidThreshold}
                        name="reidThreshold"
                        onChange={this._changeSubmissionFields}
                      />
                    </div>
                  </div>
                </Column>
                <Column isSize={2}>
                  <div className="field">
                    <label className="label">DB Size</label>
                    <div className="control">
                      <input
                        className="input"
                        type="number"
                        placeholder="DB Size"
                        value={submissionFields.dbSize}
                        name="dbSize"
                        onChange={this._changeSubmissionFields}
                      />
                    </div>
                  </div>
                </Column>
                <Column isSize={2}>
                  <div className="field">
                    <label className="label">Coral</label>
                    <p className="control has-icons-left">
                      <span className="select">
                        <select name="snapDate" onChange={(e) => this.setState({ isCoralFixed: e.target.value })} value={this.state.isCoralFixed}>
                          <option value={0}>Não</option>
                          <option value={1}>Sim</option>
                        </select>
                      </span>
                      <MicroChipIcon className="is-left" />
                    </p>
                  </div>
                </Column>
                <Column isSize={2}>
                  <label className="label">Você pode:</label>
                  <div className="field is-grouped">
                    <div className="control">
                      <span className={`button is-primary ${isSubmiting ? 'is-loading': ''}`} onClick={this._submitAttempt(submissionFields.snapDate ? submissionFields.snapDate : auditsReducedDay[0], auditsReducedDay)}>
                        Submeter
                      </span>
                    </div>
                    <div className="control">
                      <span className="button is-light" onClick={this._toogleIncludeNewAttempt}>
                        Cancel
                      </span>
                    </div>
                  </div>
                </Column>
              </Columns>
            </div>}
            <table className="table is-hoverable">
              <thead>
                <tr>
                  <th>Descrição tentativa</th>
                  <th>Executando</th>
                  <th>Com Erro</th>
                  <th>Finalizado</th>
                  <th>
                    {this.state.isLoadingStatus
                      ? <span className="button is-loading is-small is-bordless"></span>
                      : <SyncAltIcon onClick={this._loadAttemptsStatus} className="cursor-pointer"/>}
                  </th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(attemptStatus).map((description) => <tr>
                  <td>{description}</td>
                  <td>
                    <span
                      style={{
                        backgroundColor: '#4285f4',
                        color: '#fff',
                        display: 'flex',
                        justifyContent: 'center',
                        minWidth: 20,
                        borderRadius: 20
                      }}
                    >
                      {attemptStatus[description].filter(status => status.status === 'running').length}
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: '#d76359',
                        color: '#fff',
                        display: 'flex',
                        justifyContent: 'center',
                        minWidth: 20,
                        borderRadius: 20
                      }}
                    >
                      {attemptStatus[description].filter(status => status.status === 'warning').length}
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        backgroundColor: '#34a853',
                        color: '#fff',
                        display: 'flex',
                        justifyContent: 'center',
                        minWidth: 20,
                        borderRadius: 20
                      }}
                    >
                      {attemptStatus[description].filter(status => status.status === 'done').length}
                    </span>
                  </td>
                  <th>
                    {deleting[description] ? <span
                        className="button is-danger is-loading"
                        onClick={() => this._dropAttempt(description)}>
                          Excluir Tentativa
                      </span>
                      : tryDrop[description]
                        ? <div className="buttons">
                          <span className="button is-danger" onClick={() => this._dropAttempt(description)}>Deletar</span>
                          <span className="button is-danger is-outlined" onClick={() => this._undoTryDrop(description)}>Cancelar</span> 
                        </div>
                        : <span
                            className="button is-danger is-outlined"
                            onClick={() => this._dropAttempt(description)}>
                              Excluir Tentativa
                          </span>}
                  </th>
                </tr>)}
              </tbody>
            </table>
          </div>
        </Column>

        {/* Ver historico de tentativas */}
        <Column isSize={12}>
          <div className="notification card" style={{ width: '100%' }}>
            <h1 className="subtitle is-4">
              Histórico Tentativas
            </h1>
            <table className="table is-hoverable is-fullwidth">
              <thead>
                <tr>
                  <th>Descrição tentativa</th>
                  <th>Média Acuracia</th>
                  <th>Média Taxa de Vendas Auditoria</th>
                  <th>Média Taxa de Vendas Tentativa</th>
                  <th>Frequencia Acurácias </th>
                  <th></th>
                  <th>
                    {isLoadingAudit ? <span className="button is-small is-loading is-bordless"></span>
                      : <SyncAltIcon onClick={this._loadAuditAndAttempts} className="cursor-pointer"/>}
                  </th>
                </tr>
              </thead>
              <tbody>
                {attemptsPerformance
                  .map(attempt => <tr>
                  <td>
                    <div>{attempt.description}</div>
                  </td>
                  <td style={{ backgroundColor: attempt.backgroundColorAcuracy }}>
                    {attempt.meanAccuracy}%
                  </td>
                  <td >{attempt.meanAuditoriaSaleRates}%</td>
                  <td >{attempt.meanAttemptSaleRates}%</td>
                  <td style={{ padding: 20 }}>
                    <Columns>
                      <Column isSize={'is-narrow'} style={{ padding: 5, fontSize: '0.8em', backgroundColor: '#d76359', borderRadius: '5px', margin: '1px' }}>
                        Ruim: {Math.round((attempt.auditsLowAccuracy.length / attempt.attempts.length) * 100)}%
                      </Column>
                      <Column isSize={'is-narrow'} style={{ padding: 5, fontSize: '0.8em', backgroundColor: '#ffd666', borderRadius: '5px', margin: '1px' }}>
                        Médio: {Math.round((attempt.auditsMedAccuracy.length / attempt.attempts.length) * 100)}%
                      </Column>
                      <Column isSize={'is-narrow'} style={{ padding: 5, fontSize: '0.8em', backgroundColor: '#34a853', borderRadius: '5px', margin: '1px' }}>
                        Bom: {Math.round((attempt.auditsGoodAccuracy.length / attempt.attempts.length) * 100)}%
                      </Column>
                    </Columns>
                  </td>
                  <td>
                    {!descriptionSelected.includes(attempt.description) && <span className="button is-small is-primary is-outlined" onClick={() => this._selectAttempt(attempt.description)}>Selecionar tentativa</span>}
                    {descriptionSelected.includes(attempt.description) && <span className="button is-small  is-outlined" onClick={() => this._selectAttempt(attempt.description)}>Deselecionar</span>}
                  </td>
                  <td>
                    <span className="button is-small is-link is-outlined" onClick={() => this._selectAttemptPreview(attempt.description)}>Preview</span>
                  </td>
                </tr>)}
              </tbody>
            </table>
            
          </div>
        </Column>
        <Column isSize={12}>
          <div className="notification card" style={{ width: '100%' }}>
            <h1 className="subtitle is-4">
              Tentativas de parametros
            </h1>
            <table className="table is-hoverable">
              <thead>
                <tr>
                  <th>Dia Semana</th>
                  <th>Descrição auditoria</th>
                  <th>Dia</th>
                  <th>Hora</th>
                  <th>Visitantes Auditados</th>
                  <th>Visitantes Dashboard</th>
                  <th>Acurácia</th>
                  <th>Vendas</th>
                  <th>Taxa Vendas Auditado</th>
                  <th>Taxa Vendas Dashboard</th>

                  <th>DB SIZE</th>
                  <th>Reid Threshold</th>
                  <th>Detections In Hour</th>

                  <th></th>

                </tr>
              </thead>
              <tbody>
                {attemptsToDisplay
                  .map(attempt => <tr>
                  <td>{format(attempt.snapDate, 'dddd', { locale: pt })}</td>
                  <td>{attempt.description}</td>
                  <td>{attempt.snapDate}</td>
                  <td>{attempt.snapTime}</td>
                  <td>{attempt.visitorsAudit}</td>
                  <td>{attempt.visitorsNew}</td>
                  <td style={{ backgroundColor: attempt.backgroundColorAcuracy }}>{attempt.acuracia}%</td>
                  <td>{attempt.totalSales}</td>
                  <td>{attempt.auditoriaSaleRate}%</td>
                  <td>{attempt.attemptSaleRate}%</td>

                  <td>{attempt.dbSize}</td>
                  <td>{attempt.reidThreshold}</td>
                  <td>{attempt.detectionsInHour}</td>

                  <td
                    className="cursor-pointer"
                    onClick={() => this._hideAttempt(attempt.description, attempt.snapDate,attempt.snapTime)}
                  >
                    <MinusIcon style={{color: '#d76359'}} />
                  </td>
                </tr>)}
              </tbody>
            </table>
            

            <label className="checkbox" style={{ marginLeft: 10 }}>
              <input checked={this.state.showAllAttemptsInScatter} onChange={e => this.setState({showAllAttemptsInScatter: e.target.checked})} type="checkbox"/>
              Mostrar todos os horários da tentativa até mesmo os que não tem auditoria
            </label>

            {this.state.showAllAttemptsInScatter && descriptionSelected.length > 1 && <p className="help is-danger">
                Existe mais de uma tentativa selecionadas no momento. Isso pode deixar o gráfico confuso.
            </p>}
            
            <Columns style={{ marginTop: 50, height: 400 }}>
              <Column isSize={4}>
                <p className="subtitle is-4 has-text-centered">Vendas vs Detections</p>
                <ResponsiveContainer width="100%" height="100%">
                  <ScatterChart
                    height={250}
                    width={250}
                    margin={{
                      top: 20,
                      right: 20,
                      bottom: 20,
                      left: 20,
                    }}
                  >
                    <CartesianGrid />
                    <Tooltip />
                    <XAxis
                      type="number"
                      dataKey="detectionsInHour"
                      name="Detections"
                      label={{
                        value: 'Detections',
                        position: 'bottom',
                        offset: 0
                      }}
                    />
                    <YAxis type="number" dataKey="totalSales" name="Vendas" 
                      label={{
                        value: 'Vendas',
                        angle: -90,
                        position: 'insideLeft'
                      }}  />
                    <Scatter name="Dispersão entre Vendas e Detecções" data={this.state.showAllAttemptsInScatter ? attemptsSelectedToShow : attemptsToDisplay} fill="#00d1b2" />
                  </ScatterChart>
                </ResponsiveContainer>
              </Column>
              <Column isSize={4}>
                <p className="subtitle is-4 has-text-centered">Visitantes Algoritmo vs Detections</p>
                <ResponsiveContainer width="100%" height="100%">
                  <ScatterChart
                    height={250}
                    width={250}
                    margin={{
                      top: 20,
                      right: 20,
                      bottom: 20,
                      left: 20,
                    }}
                  >
                    <CartesianGrid />
                    <Tooltip />
                    <XAxis
                      type="number"
                      dataKey="detectionsInHour"
                      name="Detections"
                      label={{
                        value: 'Detections',
                        position: 'bottom',
                        offset: 0
                      }}
                    />
                    <YAxis
                      type="number"
                      dataKey="visitorsNew"
                      name="Visitors New" 
                      label={{
                        value: 'Visitantes Novos',
                        angle: -90,
                        position: 'insideLeft'
                      }}
                    />
                    <Scatter name="Dispersão entre Visitantes algoritmo e Detecções" data={this.state.showAllAttemptsInScatter ? attemptsSelectedToShow : attemptsToDisplay} fill="#00d1b2" />
                  </ScatterChart>
                </ResponsiveContainer>
              </Column>
              <Column isSize={4}>
                <p className="subtitle is-4 has-text-centered">Visitantes Auditados vs Detections</p>
                <ResponsiveContainer width="100%" height="100%">
                  <ScatterChart
                    height={250}
                    width={250}
                    margin={{
                      top: 20,
                      right: 20,
                      bottom: 20,
                      left: 20,
                    }}
                  >
                    <CartesianGrid />
                    <Tooltip />
                    <XAxis
                      label={{
                        value: 'Detections',
                        position: 'bottom',
                        offset: 0
                      }}
                      type="number"
                      dataKey="detectionsInHour"
                      name="Detections"/>
                    <YAxis 
                      label={{
                        value: 'Visitantes Auditados',
                        angle: -90,
                        position: 'insideLeft'
                      }}
                      type="number"
                      dataKey="visitorsAudit"
                      name="Visitantes Auditados" />
                    <Scatter name="Dispersão entre Visitantes Auditados e Detecções" data={this.state.showAllAttemptsInScatter ? attemptsSelectedToShow.filter(a => a.visitorsAudit > 0) : attemptsToDisplay} fill="#00d1b2" />
                  </ScatterChart>
                </ResponsiveContainer>
              </Column>
            </Columns>
            <Columns style={{ marginBottom: 50, marginTop: 50, height: 400 }}>
              <Column isSize={4}>
                <p className="subtitle is-4 has-text-centered">Acurácia vs Detections</p>
                <ResponsiveContainer width="100%" height="100%">
                  <ScatterChart
                    height={250}
                    width={250}
                    margin={{
                      top: 20,
                      right: 20,
                      bottom: 20,
                      left: 20,
                    }}
                  >
                    <CartesianGrid />
                    <ReferenceLine y={100} label="100%" stroke="green" />
                    <ReferenceLine y={125} label="125%" stroke="red" />
                    <ReferenceLine y={75} label="75%" stroke="red" />
                    <Tooltip />
                    <XAxis
                      label={{
                        value: 'Detections',
                        position: 'bottom',
                        offset: 0
                      }}
                      type="number"
                      dataKey="detectionsInHour"
                      name="Detections"/>
                    <YAxis 
                      label={{
                        value: 'Acurácia',
                        angle: -90,
                        position: 'insideLeft'
                      }}
                      type="number"
                      dataKey="acuracia"
                      name="Acuracia"
                      domain={[0, 200]} />
                    <Scatter name="Dispersão entre Acuracia e Detecções" data={this.state.showAllAttemptsInScatter ? attemptsSelectedToShow.filter(a => a.acuracia > 0) : attemptsToDisplay} fill="#00d1b2" />
                  </ScatterChart>
                </ResponsiveContainer>
              </Column>
              <Column isSize={4}>
                <p className="subtitle is-4 has-text-centered">Acurácia vs Reid Threshold</p>
                <ResponsiveContainer width="100%" height="100%">
                  <ScatterChart
                    height={250}
                    width={250}
                    margin={{
                      top: 20,
                      right: 20,
                      bottom: 20,
                      left: 20,
                    }}
                  >
                    <CartesianGrid />
                    <Tooltip />
                    <ReferenceLine y={100} label="100%" stroke="green" />
                    <ReferenceLine y={125} label="125%" stroke="red" />
                    <ReferenceLine y={75} label="75%" stroke="red" />
                    <XAxis
                      type="number"
                      dataKey="reidThreshold"
                      name="reidThreshold"
                      label={{
                        value: 'Reid',
                        position: 'bottom',
                        offset: 0
                      }}
                      domain={[3,7]}/>
                    <YAxis
                      label={{
                        value: 'Acurácia',
                        angle: -90,
                        position: 'insideLeft'
                      }}
                      type="number"
                      dataKey="acuracia"
                      name="acuracia"
                      domain={[0, 200]} />
                    <Scatter name="Dispersão entre Acuracia e Detecções" data={this.state.showAllAttemptsInScatter ? attemptsSelectedToShow.filter(a => a.acuracia > 0) : attemptsToDisplay} fill="#00d1b2" />
                  </ScatterChart>
                </ResponsiveContainer>
              </Column>
              <Column isSize={4}>
                <p className="subtitle is-4 has-text-centered">Acurácia vs Conversão Tentativa</p>
                <ResponsiveContainer width="100%" height="100%">
                  <ScatterChart
                    height={250}
                    width={250}
                    margin={{
                      top: 20,
                      right: 20,
                      bottom: 20,
                      left: 20,
                    }}
                  >
                    <CartesianGrid />
                    <ReferenceLine y={100} label="100%" stroke="green" />
                    <ReferenceLine y={125} label="125%" stroke="red" />
                    <ReferenceLine y={75} label="75%" stroke="red" />
                    <XAxis
                      type="number"
                      dataKey="attemptSaleRate"
                      name="attemptSaleRate"
                      label={{
                        value: 'Conversão vendas tentativa',
                        position: 'bottom',
                        offset: 0
                      }}
                    />
                    <YAxis
                      type="number"
                      dataKey="acuracia"
                      label={{
                        value: 'Acurácia',
                        angle: -90,
                        position: 'insideLeft'
                      }}
                      name="acuracia"
                      domain={[0, 200]}
                    />
                    <Scatter name="Dispersão entre Acuracia e Conversão de vendas da tentativa" data={this.state.showAllAttemptsInScatter ? attemptsSelectedToShow.filter(a => a.acuracia > 0) : attemptsToDisplay} fill="#00d1b2" />
                  </ScatterChart>
                </ResponsiveContainer>
              </Column>
            </Columns>
            <span className="button is-primary is-outlined" onClick={this._changeFieldsToDynamic(attemptsToDisplay)}>Aplicar para geração de métricas</span>
          </div>
        </Column>
        <Column>
          <div className="notification card" style={{ width: '100%' }}>
            <h1 className="subtitle is-4" style={{ marginTop: 20 }}>
              Submeter tentativa dinâmica
            </h1>
            <div className="field">
              <label className="label">DB Size</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  style={{ width: "100%" }}
                  value={submissionDynamicFields.dbSize}
                  name="dbSize"
                  onChange={this._onChangeFieldsDynamicSubmission}
                />
              </div>
            </div>
            <div className="field">
              <label className="label">List Reid Thresholds</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  style={{ width: "100%" }}
                  value={submissionDynamicFields.reid}
                  disabled
                />
              </div>
            </div>
            <div className="field">
              <label className="label">List Detections in Hour</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  style={{ width: "100%" }}
                  value={submissionDynamicFields.detectionsInHour}
                  disabled
                />
              </div>
            </div>
            <div className="field">
              <label className="label">List Vendas por hora</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  style={{ width: "100%" }}
                  value={submissionDynamicFields.sales}
                  disabled
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Min Threshold</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  style={{ width: "100%" }}
                  value={submissionDynamicFields.minThreshold}
                  name="minThreshold"
                  onChange={this._onChangeFieldsDynamicSubmission}
                />
              </div>
            </div>

            <div className="field">
              <label className="label">Max Threshold</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  style={{ width: "100%" }}
                  value={submissionDynamicFields.maxThreshold}
                  name="maxThreshold"
                  onChange={this._onChangeFieldsDynamicSubmission}
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Coral</label>
              <p className="control has-icons-left">
                <span className="select">
                  <select name="snapDate" onChange={(e) => this.setState({ isCoralDinamic: e.target.value })} value={this.state.isCoralDinamic}>
                    <option value={0}>Não</option>
                    <option value={1}>Sim</option>
                  </select>
                </span>
                <MicroChipIcon className="is-left" />
              </p>
            </div>
            <div className="field">
              <label className="label">Usar vendas como medida para a regressão</label>
              <p className="control has-icons-left">
                <span className="select">
                  <select name="useSalesForRegression" onChange={(e) => this.setState({ useSalesForRegression: e.target.value })} value={this.state.useSalesForRegression}>
                    <option value={0}>Não</option>
                    <option value={1}>Sim</option>
                  </select>
                </span>
                <SalesIcon className="is-left" />
              </p>
            </div>
            <span className={`button is-primary ${isSubmitingDynamic ? 'is-loading': ''}`} onClick={this._submitAttemptDynamic(auditsReducedDay)}>
              Métricas Dinâmicas
            </span>
          </div>
        </Column>
      </Columns>
  }
}
