import React, { Component } from 'react';

import {
  DragSource,
  DropTarget
} from 'react-dnd';

import PerformanceTable from './PerformanceTable';
import ItemsType from '../../../../../dragndrop/constants';

const PerformanceTableDraggable = DragSource(
  ItemsType.PERFORMANCE_TABLE_TO_COMPARE,
  {
    beginDrag: (props, monitor, component) => {
      return { id: props.tableNumber };
    },
    endDrag: (props, monitor, component) => {
      if(!monitor.didDrop()) {
        return;
      }
      const item = monitor.getItem();
      const itemToCompare = monitor.getDropResult();
      props.handleDnd(item, itemToCompare);
    }
  },
  (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
    item: monitor.getItem(),
    isDrag: true
  })
)(PerformanceTable)

const PerformanceTableDropTarget = DropTarget(
  ItemsType.PERFORMANCE_TABLE_TO_COMPARE,
  {
    drop: (props) => {
      return { id: props.tableNumber };
    }
  },
  (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
  })
)(PerformanceTableDraggable)

class TableSitesPerformance extends Component {

  _removeIndexFromList = (tableNumber) => () => {
    this.props.onClickToRemove(tableNumber);
  }

  _handleDnd = (item, itemToCompare) => {
    this.props.handleDnd(item.id, itemToCompare.id);
  }

  _handleDragWithNoDrop = () => {
    this.props.handleDragWithNoDrop()
  }

  render() {
    const {
      table,
      tableNumber,
      showLoyalty,
      showPassersBy
    } = this.props;

    return <PerformanceTableDropTarget
      table={table}
      tableNumber={tableNumber}
      showLoyalty={showLoyalty}
      showPassersBy={showPassersBy}
      _removeIndexFromList={this._removeIndexFromList(tableNumber)}
      handleDnd={this._handleDnd}
      handleDragNoDrop={this._handleDragWithNoDrop}
    />
  }
}

export default TableSitesPerformance;
