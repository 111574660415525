import React, { useEffect, useState } from 'react'; 

import Columns, { Column } from '../../../../components/Columns';
import { ChartLineIcon } from '../../../../components/icons';
import Page, { PageTitle } from '../../../../components/Page';
import IsLoading from '../../../../components/IsLoading';
import { get } from '../../../../utils';

import { STATUS_AUDITORIA, TIPOS_AUDITORIA } from './enums';
import ModalNewAudit from './ModalNewAudit';
import ModalShowMoreAudit from './ModalShowMoreAudit';

const getStatusDescription = (status) => {
  if(status === STATUS_AUDITORIA.FINALIZADO) {
    return "Finalizado";
  }

  if(status === STATUS_AUDITORIA.DESCONSIDERAR) {
    return "Desconsiderado";
  }

  if(status === STATUS_AUDITORIA.NAO_INICIADO) {
    return "Não iniciado";
  }

  if(status === STATUS_AUDITORIA.EM_ANDAMENTO) {
    return "Em Andamento";
  }
}

const getTipoDescription = (auditType) => {
  if(auditType === TIPOS_AUDITORIA.VISITORS) {
    return "Visitante"; 
  }
  if(auditType === TIPOS_AUDITORIA.PASSERSBY) {
    return "Passante"; 
  }
  if(auditType === TIPOS_AUDITORIA.VENUE) {
    return "Area"; 
  }
  if(auditType === TIPOS_AUDITORIA.PRATELEIRA) {
    return "Prateleiras"; 
  }
}


const useAudits = (siteId, reloadAudit) => {
  const [audits, setAudits] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    get(`dashboard/video/sites/${siteId}/allaudit`)
      .then(resp => {
        setAudits(resp.data.data);
        setIsLoading(false);
      })
  }, [ siteId, reloadAudit ]);

  const modifyAudit = (auditId, user) => {
    const newAudits = audits.map(audit => {
      if (auditId === audit.id) {
        return {
          ...audit,
          user
        }
      }

      return audit;
    });

    setAudits(newAudits);
  }


  const changeAuditStatus = (auditId, status) => {
    const newAudits = audits.map(audit => {
      if (auditId === audit.id) {
        return {
          ...audit,
          status
        }
      }

      return audit;
    });

    setAudits(newAudits);
  }

  return [ isLoading, audits, modifyAudit, changeAuditStatus ];
}


const AuditDashboardMain = ({ site }) => {
  const [ reloadAudit, setReloadAudit ] = useState(false);
  const [ isLoadingAudits, audits, modifyAudit, changeAuditStatus ] = useAudits(site.id, reloadAudit);
  const [ sumirDesconiderado, setSumirDesconsiderado ] = useState(true);
  const [ showMoreAuditModal, setShowMoreAuditModal ] = useState(false);
  const [ auditModal, setAuditModal ] = useState(null);

  const [ showNewAuditModal, setShowNewAuditModal ] = useState(false);
  const toogleModalNewAudit = (doReload=false) => {
    if(doReload) {
      setReloadAudit(!reloadAudit);
    }

    setShowNewAuditModal(!showNewAuditModal);
  }

  const toogleModalShowMoreAuditModal = (audit, doReload=false) => {
    if(doReload) {
      setReloadAudit(!reloadAudit);
    }

    setShowMoreAuditModal(!showMoreAuditModal);
    setAuditModal(audit)
  }

  const desconsiderada = (auditId) => {
    changeAuditStatus(auditId, "2")
    toogleModalShowMoreAuditModal()
  }

  const handleSaveUser = (audit) => {
    modifyAudit(audit.id, audit.user);
    setAuditModal(audit);
  }

  return <Page>
  <PageTitle
    title={<span>
      <span style={{ marginRight: '10px' }}>
        {`${(site.name) || ''} `}
      </span>
      <p className="subtitle">Dashboard Auditoria <ChartLineIcon /></p>
    </span>}
  />

  {showNewAuditModal && <ModalNewAudit
    site={site}
    toogleModal={toogleModalNewAudit}
  />}

  {showMoreAuditModal && <ModalShowMoreAudit
    site={site}
    audit={auditModal}
    handleSave={handleSaveUser}
    toogleModal={toogleModalShowMoreAuditModal}
    desconsiderada={desconsiderada}
  />}
  <Columns isMultiline>
    <Column isSize={12}>
      <div className="card notification">
        <p className="subtitle">Auditorias</p>
        <div className="field">
          <div className="control">
            <label className="checkbox">
            <input type='checkbox' checked={sumirDesconiderado} onChange={e => setSumirDesconsiderado(e.target.checked)}/>
              Sumir auditorias desconsideradas
            </label>
          </div>
        </div>

        {isLoadingAudits && <div style={{ width: '100%', display: 'flex', justifyContent: 'center'}}>
          <IsLoading fontSize={60} isBorderless={true}/>
        </div>}
        {!isLoadingAudits && <span className="button is-primary" onClick={() => toogleModalNewAudit()}>Adicionar auditoria</span>}
        {!isLoadingAudits && <table style={{ width: '100%'}} className="table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Descricao</th>
              <th>Dia</th>
              <th>Hora</th>
              <th>Status</th>
              <th>Tipo</th>
              <th>Auditor</th>
              <th>Visitantes Contados</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {audits
              .filter(audit => (audit.status !== STATUS_AUDITORIA.DESCONSIDERAR && sumirDesconiderado) || !sumirDesconiderado)
              .map(audit => <tr key={`all_auditorias_${audit.id}`}>
              <td>#{audit.id}</td>
              <td>{audit.description}</td>
              <td>{audit.snapDate}</td>
              <td>{audit.snapTime}</td>
              <td>
                {audit.status === STATUS_AUDITORIA.DESCONSIDERAR ? <span className="tag is-warning is-light">
                  {getStatusDescription(audit.status)}
                </span>
                : audit.status === STATUS_AUDITORIA.NAO_INICIADO ? <span className="tag is-dark">
                {getStatusDescription(audit.status)}
                </span> : audit.status === STATUS_AUDITORIA.EM_ANDAMENTO ? <span className="tag is-link">
                {getStatusDescription(audit.status)}
                </span> : audit.status === STATUS_AUDITORIA.FINALIZADO ? <span className="tag is-success is-light">
                  {getStatusDescription(audit.status)}
                </span> : <span></span>}
              </td>
              <td>
                {audit.flowAnalysis ? 'Eventos' : getTipoDescription(audit.auditType)}
              </td>
              <td>{audit.user.email}</td>
              <td>{audit.visitors}</td>
              <td>
                <span className="button is-default" onClick={() => toogleModalShowMoreAuditModal(audit, false)}>
                  Ver mais
                </span>
              </td>
            </tr>)}
          </tbody>
        </table>}
      </div>
    </Column>
  </Columns>
  </Page>
}

export default AuditDashboardMain;