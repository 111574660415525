import React, { Component } from 'react';
import AnalysisTags from './AnalysisTags';


const DEFAULT_POINTS = {
  startX: null,
  startY: null,
  endX: null,
  endY: null
}

const DEFAULT_RECTANGLES = {
  x: null,
  y: null,
  width: null,
  height: null
}


class BoxPerson extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showTags: false
    }
  }

  render() {
    const { selectedBoxes, cameraId, imageUrl, box } = this.props;

    const centrox = box.centroid_x;
    const centroy = box.centroid_y;
    const tags = box.tags;
    const centroidStr = `${cameraId}_${centrox}x${centroy}`;
    const isSelected = selectedBoxes
      .map(box => box.centroid)
      .includes(centroidStr);

    const { box_end_x, box_end_y, box_start_x, box_start_y } = box;

    return <g
      key={`svg_box_cam_${centroidStr}`}
    >
      <rect
        x={box_start_x}
        y={box_start_y}
        width={box_end_x - box_start_x}
        height={box_end_y - box_start_y}
        fill="none"
        stroke="#222"
        strokeWidth="2"
        opacity={0.5}
      />
      <circle
        cx={centrox}
        cy={centroy}
        stroke="white"
        r="5"
        fill="#222"
        opacity={0.5}
      ></circle>
      {!tags && <circle cx={centrox} cy={centroy} stroke="white" r="5" fill="#222"></circle>}
      {!tags && <rect x={centrox} y={centroy} width="30" height="15" stroke="white" fill={isSelected ? '#00d1b2' : '#222222'} rx="5"></rect>}
      {tags && <g>
        <rect
          cursor={'pointer'}
          x={box_start_x + 5}
          y={box_end_y-50}
          width={60}
          height="20"
          stroke="#ffffff"
          fill={'#00d1b2'}
          onClick={() => this.props.handleClickShowTags(centroidStr, tags)}
          rx="5"/>
        <text
          cursor={'pointer'}
          x={box_start_x + 10}
          y={box_end_y-36}
          fontSize="14"
          onClick={() => this.props.handleClickShowTags(centroidStr, tags)}
          fill="#ffffff">
          ver tags
        </text>
        <rect
          cursor={'pointer'}
          x={box_start_x + 5}
          y={box_end_y-25}
          width={60}
          height="20"
          stroke="red"
          fill={'none'}
          onClick={this.props.pushToSelected(centroidStr, {...box, camera_id: cameraId, image_url: imageUrl})}
          rx="5"/>
        <text
          cursor={'pointer'}
          x={box_start_x + 10}
          y={box_end_y-11}
          fontSize="14"
          fill="red"
          onClick={this.props.pushToSelected(centroidStr, {...box, camera_id: cameraId, image_url: imageUrl})}
        >
          remover
        </text>
      </g>}
    </g>
  }
}

class PictureFrame extends Component {

  constructor(props) {
    super(props);
    
    this.state = {
      mouse: {
        x: 50,
        y: 50
      },
      centroIdSelected: null,
      boxTags:[],
      canDraw: false,
      drawing: false,
      points: DEFAULT_POINTS,
      rectangle: DEFAULT_RECTANGLES
    };
  }

  _renderMousePosition = (event) => {
    event.persist()
    const { drawing, points } = this.state;
    if(!drawing)
      return;

    const { offsetX, offsetY } = event.nativeEvent;
    const { startX, startY } = points;

    let x = startX;
    let y = startY;
    let width = offsetX - startX;
    let height = offsetY - startY;

    if(startX > offsetX) {
      x = offsetX;
      width = startX - offsetX;
    }

    if(startY > offsetY) {
      y = offsetY;
      height = startY - offsetY;
    }

    this.setState({
      rectangle: { x, y, width, height }
    });
  }

  _cleanUpRectangleDraw = () => {
    this.setState({
      drawing: false,
      canDraw: false,
      points: DEFAULT_POINTS,
      rectangle: DEFAULT_RECTANGLES,
      centroIdSelected: null,
      boxTags: []
    });
  }

  _createPerson = (tags) => {
    const {
      points
    } = this.state;

    const {
      imageUrl,
      cameraId
    } = this.props;

    const {
      startX,
      startY,
      endX,
      endY
    } = points;

    const centroid_x = parseInt(startX + (endX - startX) / 2);
    const centroid_y = parseInt(startY + (endY - startY) / 2);
    const centroid = `${cameraId}_${centroid_x}x${centroid_y}`;

    const box = {
      tags: tags,
      box_end_x: endX,
      box_end_y: endY,
      box_start_x: startX,
      box_start_y: startY,
      camera_id: cameraId,
      centroid_x: centroid_x,
      centroid_y: centroid_y,
      image_url: imageUrl
    }

    this.props.newBox(centroid, box, this._cleanUpRectangleDraw);
  }

  _handleEditTags = (tags) => {
    const { centroIdSelected } = this.state;
    this.props.newBox(centroIdSelected, { tags }, () => {
      this.setState({centroIdSelected: null, boxTags: []});
    });
  }

  _createRectangle = (event) => {
    event.persist();
    const { drawing, points, canDraw } = this.state;
    const { offsetX, offsetY } = event.nativeEvent;

    if(!canDraw){
      this.setState({ canDraw: true});
      return;
    }

    if(points.startX && points.endX)
      return;

    if (!drawing) {
      this.setState({
        drawing: true,
        points: {
          startX: offsetX,
          startY: offsetY
        },
        rectangle: {
          x: offsetX,
          y: offsetY,
          width: 1,
          height: 1
        }
      });
      return;
    }

    this.setState({
      drawing: false,
      points: {
        startX: points.startX > offsetX ? offsetX : points.startX,
        startY: points.startY > offsetY ? offsetY : points.startY,
        endX: points.startX < offsetX ? offsetX : points.startX,
        endY: points.startY < offsetY ? offsetY : points.startY
      }
    })
  }

  _pushToSelected = (centroid, box) => () => {
    this.props.pushBox(centroid, box);
  }

  _handleRefreshTags = (tags) => {
    this.props.handleRefreshTags(tags);
  }

  _handleClickShowTags = (centroid, tags) => {
    this.setState({ centroIdSelected: centroid, boxTags: tags });
  }

  render() {
    const {
      cameraId,
      imageUrl,
      boxes=[],
      selectedBoxes,
      tags,
      siteId,
      auditId,
      isAnalysis=false,
      tagsSugestion=[]
    } = this.props;

    const {
      rectangle,
      canDraw,
      points,
      centroIdSelected,
      boxTags
    } = this.state;

    return <div className="audit-imagebox">
      {canDraw ? <span className="button is-small is-danger" onClick={this._cleanUpRectangleDraw}>
        Cancelar desenho
      </span> : <span className="button is-small is-primary is-outlined" onClick={() => this.setState({ canDraw: true })}>
        Detectar manualmente
      </span>}
      <svg width="704" height="480" style={{ marginTop: 10, borderRadius: 4, cursor: canDraw ? 'crosshair': 'default' }}>
        <image
          xlinkHref={imageUrl}
          x="0"
          y="0"
          width="100%"
          height="100%"
          onMouseMove={this._renderMousePosition}
          onClick={this._createRectangle}
        />
        
        {boxes.map(box => <BoxPerson
          key={`box_person_centro_id_${box.cameraId}_${box.centroid_x}_${box.centroid_y}`}
          handleClickShowTags={this._handleClickShowTags}
          pushToSelected={this._pushToSelected}
          imageUrl={imageUrl}
          selectedBoxes={selectedBoxes}
          cameraId={cameraId}
          box={box}
        />)}
        {isAnalysis && <g>
          {points.startX && <rect
            x={rectangle.x}
            y={rectangle.y}
            width={rectangle.width}
            height={rectangle.height}
            fill="none"
            stroke="#222"
            strokeWidth="2"
            onClick={this._createRectangle}
          />}
          {points.startX && <circle
            cx={points.startX}
            cy={points.startY}
            stroke="white"
            r="5"
            fill="#222"
          ></circle>}
          {points.endX && <circle
            cx={points.endX}
            cy={points.endY}
            stroke="white"
            r="5"
            fill="#222"
          ></circle>}
          {((points.startX && points.endX) || centroIdSelected) && <foreignObject className="node" x="22" y="22" width="660" height="430">
            <AnalysisTags
              siteId={siteId}
              auditId={auditId}
              tags={tags}
              tagsSugestion={tagsSugestion}
              selectedBox={centroIdSelected}
              boxTags={boxTags}
              handleApplyEditTags={this._handleEditTags}
              handleApply={this._createPerson}
              handleRefreshTags={this._handleRefreshTags}
              handleCancel={this._cleanUpRectangleDraw}
            />
          </foreignObject>}
        </g>}
        {!isAnalysis && <g>
          {points.startX && <rect
            x={rectangle.x}
            y={rectangle.y}
            width={rectangle.width}
            height={rectangle.height}
            fill="none"
            stroke="#222"
            strokeWidth="2"
            onClick={this._createRectangle}
          />}
          {points.startX && <circle
            cx={points.startX}
            cy={points.startY}
            stroke="white"
            r="5"
            fill="#222"
          ></circle>}
          {points.endX && <circle
            cx={points.endX}
            cy={points.endY}
            stroke="white"
            r="5"
            fill="#222"
          ></circle>}
          {points.startX && points.endY && <rect
            cursor="pointer"
            x={points.startX}
            y={points.endY > 300 ? points.startY- 24: points.endY + 4}
            width="40"
            height="20"
            fill="#00d1b2"
            fillOpacity={0.6}
            stroke="#00d1b2"
            onClick={() => this._createPerson()}
            strokeWidth="2"
            rx="4"
          ></rect>}
          {points.startX && points.endY && <text
            x={points.startX + 5}
            y={points.endY > 300 ? points.startY- 10: points.endY + 18}
            fontFamily="Titillium Web"
            fontSize="14"
            fill="#222"
            cursor="pointer"
            onClick={() => this._createPerson()}
            stroke="#222">
              Criar
          </text>}
          {points.startX && points.endY && <rect
            cursor="pointer"
            x={points.startX + 45}
            y={points.endY > 300 ? points.startY- 24: points.endY + 4}
            width="60"
            height="20"
            fill="#E92641"
            fillOpacity={0.6}
            stroke="#E92641"
            onClick={this._cleanUpRectangleDraw}
            strokeWidth="2"
            rx="4"
          ></rect>}
          {points.startX && points.endY && <text
            x={points.startX + 50}
            y={points.endY > 300 ? points.startY- 10: points.endY + 18}
            fontFamily="Titillium Web"
            fontSize="14"
            fill="#222"
            cursor="pointer"
            onClick={this._cleanUpRectangleDraw}
            stroke="#222">
              Cancelar
          </text>}
        </g>}
      </svg>
    </div>
  }
}

export default PictureFrame