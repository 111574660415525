import React, { Component } from 'react';

import withRouter from "react-router-dom/withRouter";
import { connect } from 'react-redux';

import {format, addDays, toDate } from 'date-fns';
import { db } from '../../../../firebase/index';

import Columns, { Column } from '../../../../components/Columns';
import TemperatureChart from './TemperatureChart';
import JohannMinMaxCard from './JohannMinMaxCard';
import JohannRealtimeCard from './JohannRealtimeCard';
import DateFilterOneRange from '../foottraffic/analytics/DateFilterOneRange';

import { get, getServerDate } from '../../../../utils';
import { ChartLineIcon, ChartBarIcon } from '../../../../components/icons';
import MinMaxPerDayChart from './MinMaxPerDayChart';
import MinMaxPerDayWithGPS from './MinMaxPerDayWithGPS';

class SensorJohannDash extends Component {

  constructor(props){
    super(props);

    const CURRDATE = getServerDate();

    const filterRanges = {
      startDate: addDays(CURRDATE, 0),
      endDate: addDays(CURRDATE, 0),
      key: 'selectionRange1'
    }

    this.state = {
      isloading: false,
      data:[],
      detail: [],
      dailyData: [],
      dailyDatasGps: [],
      total: {
        minTemperature: 0,
        minDateTemp: '',
        minDateHumid: '',
        maxDateHumid: '',
        maxTemperature: 0,
        maxHumidity: 0,
        minHumidity: 0,
        maxDateTemp:''
      },
      realtimeValues: {},
      filterRanges
    }
  }

  componentWillUnmount() {
    const { venue, site } = this.props;
    db.ref(`/johann/${site.id}/sensors/${venue.sensorid}/realtime/values`).off('value');
  }

  componentDidMount() {
    this._loadRealTime();
    this.getSensorData(this.state.filterRanges);
  }

  _realtimeTemperatureAndHumidity = (sensorid) => (data) => {
    if(data === null || data.toJSON() === null)
      return;

    this.setState({ realtimeValues: data.toJSON() });
  }

  getSensorData = (filterRanges)=>{
    const { venue, site } = this.props;
    const begin = format(filterRanges.startDate, 'YYYY-MM-DD');
    const end = format(filterRanges.endDate, 'YYYY-MM-DD');

    this.setState({hasLoaded: false} , () => {

      const dailyDatas = get(`dashboard/johann/sites/${site.id}/sensor/${venue.sensorid}/day`, { begin, end })
        .then(resp => resp.data.data);
      const dailyDatasGps = get(`dashboard/johann/sites/${site.id}/sensor/${venue.sensorid}/gps`, { begin, end })
        .then(resp => resp.data.data);
      const datas = get(`dashboard/johann/sites/${site.id}/sensor/${venue.sensorid}/data`, { begin, end })
        .then(resp => resp.data);
      
      Promise.all([dailyDatas, dailyDatasGps, datas]).then(res => {
        this.setState({hasLoaded: true, dailyData: res[0], dailyDatasGps: res[1], total: res[2].data, detail: res[2].detail, filterRanges})
      });
    });
  }

  _changeDateFilter = () => (filterRanges) => {  
    this.getSensorData(filterRanges);
  }
  
  _loadRealTime() {
    const { venue, site } = this.props;
    this.setState({ isloading: true }, () => {
      db.ref(`/johann/${site.id}/sensors/${venue.sensorid}/realtime/values`).on('value', this._realtimeTemperatureAndHumidity(venue.sensorid))
      get(`dashboard/johann/sites/${site.id}/sensor/${venue.sensorid}/lastHour`)
        .then(resp => resp.data.data)
        .then(data => this.setState({data, isloading: false}));
    });
  }

  render() {
    const { venue={} } = this.props;
    const {
      realtimeValues={},
      data=[],
      detail=[],
      dailyData=[], 
      dailyDatasGps=[],
      filterRanges={},
      total={
        minTemperature: 0,
        minDateTemp: '',
        minDateHumid: '',
        maxDateHumid: '',
        maxTemperature: 0,
        maxHumidity: 0,
        minHumidity: 0,
        maxDateTemp:'',
      },
      hasLoaded,
      isloading
    } = this.state;

    const {
      minTemperature,
      minDateTemp,
      minDateHumid,
      maxDateHumid,
      maxTemperature,
      maxHumidity,
      minHumidity,
      maxDateTemp,
    } = total;
    
    const { collectTime=0, temperature=0, humidity=0, temperature_2 = 0 } = realtimeValues;
    const realTemperature = venue.multi_thermo_part ? (temperature + temperature_2) / 2 :  temperature;

    const hasWarn = hasLoaded && (
      temperature > venue.maxTemp ||
      temperature < venue.minTemp ||
      humidity > venue.maxHumi ||
      humidity < venue.minHumi
    );

    return (
        <div className="tile is-child is-12">
          <div className="tile is-child is-12">
            <Columns isSize={12} className="has-text-centered" style={{justifyContent: 'center'}}>
              <Column isSize={3}>
                <JohannRealtimeCard
                  hasWarn={hasWarn}
                  isLoading={isloading}

                  icon={(
                    <span className="icon" style={{marginRight: 1}}>
                      <i className="fas fa-clock"></i>
                    </span>
                  )}
                  label="Última atualização"
                  value={format(collectTime * 1000, 'HH:mm:ss')}
                  subvalue={format(collectTime * 1000, 'DD/MM/YYYY')}
                />
              </Column>
              <Column isSize={3}>
                <JohannRealtimeCard
                  hasWarn={hasWarn}
                  isLoading={isloading}
                  icon={(
                    <span className="icon " style={{marginRight: 1}}>
                      <i className="fas fa-temperature-high "></i>
                    </span>
                  )}
                  label="Temperatura"
                  value={`${Number(realTemperature).toFixed(1)}˚C` }
                  subvalue={venue.multi_thermo_part ? `TEMP 1: ${Number(temperature).toFixed(1)}˚C   TEMP 2: ${Number(temperature_2).toFixed(1)}˚C` : `MIN PERMITIDA: ${venue.minTemp}˚C   MAX PERMITIDA: ${venue.maxTemp}˚C`}
                />
              </Column>
              {venue.hasHumi && <Column isSize={3}>
                <JohannRealtimeCard
                  hasWarn={hasWarn}
                  isLoading={isloading}
                  icon={(
                    <span className="icon " style={{marginRight: 1}}>
                      <i className="fas fa-temperature-high "></i>
                    </span>
                  )}
                  label="Umidade"
                  value={`${Number(humidity).toFixed(1)}%`}
                  subvalue={ `MIN PERMITIDA: ${venue.minHumi}%   MAX PERMITIDA: ${venue.maxHumi}%`}
                />
              </Column>}
            </Columns>
          </div>
          <div className="tile is-parent is-12">
            <span className="subtitle is-5 " style={{ marginTop: 30 }}>
              <ChartLineIcon /> Última hora 
            </span>
          </div>
          <div className="tile is-parent is-12">
            <Columns style={{ width: '100%', height: '100%', justifyContent: 'space-between' }}>
              <Column isSize={12}>
                {isloading && <div className="card notification"  
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 200,
                    width: '100%'
                  }}
                >
                  <span className="button is-loading is-white" style={{ fontSize: 60 }}></span>
                </div>}
                {!isloading && <div className="card notification"  
                  style={{
                    width: '100%'
                  }}
                >
                  <p className="subtitle is-5 has-text-centered">Temperatura</p>
                  <TemperatureChart data={data} venue={venue}/>
                </div>}
              </Column>
            </Columns>
          </div>
          <div className="tile is-parent is-12">
            {hasLoaded && <span className="subtitle is-5" style={{ marginTop: 30 }}>
            <ChartBarIcon /> Analise do periodo 
              <DateFilterOneRange
                className="johann-tooltip-calendar"
                changeDates={this._changeDateFilter()}
                minDate={toDate('2019-11-03')}
                maxDate={getServerDate()}
                actualRanges={{ ranges: filterRanges }}/>
            </span>}
          </div>
          <div className="tile is-parent is-12">
            <div className="tile is-parent is-2" style={{ paddingLeft: 0 }}>
              <Columns isMultiline>
                <Column isSize={12} className="has-text-centered">
                  <JohannMinMaxCard hasLoaded={hasLoaded} label="Temperatura mínima" value={`${minTemperature}˚C`} subvalue={format(minDateTemp, 'DD/MM/YYYY HH:mm:ss' )}/>
                </Column>
                <Column isSize={12} className="has-text-centered">
                  <JohannMinMaxCard hasLoaded={hasLoaded} label="Temperatura máxima" value={`${maxTemperature}˚C`} subvalue={format(maxDateTemp, 'DD/MM/YYYY HH:mm:ss' )}/>
                </Column>
                {venue.hasHumi && <Column isSize={12} className="has-text-centered">
                  <JohannMinMaxCard hasLoaded={hasLoaded} label="Umidade mínima" value={`${minHumidity}%`} subvalue={format(minDateHumid, 'DD/MM/YYYY HH:mm:ss' )}/>
                </Column>}
                {venue.hasHumi && <Column isSize={12} className="has-text-centered">
                  <JohannMinMaxCard hasLoaded={hasLoaded} label="Umidade máxima" value={`${maxHumidity}%`} subvalue={format(maxDateHumid, 'DD/MM/YYYY HH:mm:ss' )}/>
                </Column>}
              </Columns>
            </div>
            <div className="tile is-parent is-10" style={{ paddingRight: 0 }}>
              <Columns isMultiline style={{ width: '100%', height: '100%', justifyContent: 'space-between' }}>
                <Column isSize={12}>
                  {!hasLoaded && <div className="card notification"  
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: 200,
                      width: '100%'
                    }}
                  >
                    <span className="button is-loading is-white" style={{ fontSize: 60 }}></span>
                  </div>}
                  {hasLoaded && venue.hasHumi && 
                    <div className="card notification"  
                      style={{
                        width: '100%'
                      }}
                      >
                      <p className="subtitle is-5 has-text-centered">Resumo Diário</p>
                      <MinMaxPerDayChart venue={venue} detail={dailyData}/>
                      <MinMaxPerDayChart venue={venue} detail={dailyData} isHumi={true}/>
                    </div>
                  }
                  {hasLoaded && !venue.hasHumi && 
                    <div className="card notification"  
                      style={{
                        width: '100%'
                      }}
                      >
                      <p className="subtitle is-5 has-text-centered">Resumo Diário</p>
                      <MinMaxPerDayChart venue={venue} detail={dailyData}/>
                    </div>
                  }
                  {hasLoaded && venue.gps && <div className="card notification"  
                    style={{
                      width: '100%'
                    }}
                  >
                    <p className="subtitle is-5 has-text-centered">Resumo Diário a cada 20 minutos</p>
                    <MinMaxPerDayWithGPS venue={venue} detail={dailyDatasGps}/>
                  </div>}
                </Column>
                <Column isSize={12}>
                  {!hasLoaded && <div className="card notification"  
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: 200,
                        width: '100%'
                      }}
                    >
                    <span className="button is-loading is-white" style={{ fontSize: 60 }}></span>
                  </div>}
                  {hasLoaded && <div className="card notification"  
                      style={{
                        width: '100%'
                      }}
                    >
                      <p className="subtitle is-5 has-text-centered">Temperatura</p>
                      <TemperatureChart data={detail} formatDate='DD/MM HH:mm' venue={venue}/>
                    </div>}
                </Column>
              </Columns>
            </div>
          </div>
        </div>
    );
  }
}

function mapStateToProps(state, props) {
  return { site: props.site }
}

export default withRouter(connect(mapStateToProps)(SensorJohannDash));
