import React, {
  useEffect,
  useState
} from 'react';
import Columns, { Column } from '../../../../components/Columns';

import { IdBadgeIcon } from '../../../../components/icons';
import IsLoading from '../../../../components/IsLoading';
import Page, { PageTitle } from '../../../../components/Page';
import { get } from '../../../../utils';
import { STATUS_AUDITORIA } from './enums';
import ModalCounter from './ModalCounter';
import ModalShelf from './ModalShelf';
import ModalShelfSetup from './ModalShelfSetup';


const useAudits = (siteId, userId, reloadList) => {
  const [ isLoading, setIsLoading ] = useState(false);
  const [ audits, setAudits ] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    get(`dashboard/video/sites/${siteId}/useraudit`)
      .then(resp => {
        setAudits(resp.data.data);
        setIsLoading(false);
      });
  }, [ siteId, userId, reloadList ]);

  return [ isLoading, audits ];
}


const getStatusDescription = (status) => {
  if(status === STATUS_AUDITORIA.FINALIZADO) {
    return "Finalizado";
  }

  if(status === STATUS_AUDITORIA.DESCONSIDERAR) {
    return "Desconsiderado";
  }

  if(status === STATUS_AUDITORIA.NAO_INICIADO) {
    return "Não iniciado";
  }

  if(status === STATUS_AUDITORIA.EM_ANDAMENTO) {
    return "Em Andamento";
  }
}


const ManualCount = (props) => {
  const { site, user } = props;

  const [ checkShowDone, setCheckShowDone ] = useState(false);
  const [ reloadList, setReloadList ] = useState(false);
  const [ selectedAudit, setSelectedAudit ] = useState(null);
  const [ toogleModalCounter, setToogleModalCounter ] = useState(false);
  const [ toogleModalShelf, setToogleModalShelf ] = useState(false);
  const [ toogleModalShelfSetup, setToogleModalShelfSetup ] = useState(false);
  const [ isLoadingAudits, audits ] = useAudits(site.id, user.id, reloadList);

  const _toogleModalCounter = (doReload=false) => {
    setToogleModalCounter(!toogleModalCounter);

    if(doReload){
      setReloadList(!reloadList);
    }

    if (toogleModalCounter) {
      setSelectedAudit(null);
    }
  }

  const __toogleModalShelf = (doReload=false) => {
    setToogleModalShelf(!toogleModalShelf);
    if(doReload){
      setReloadList(!reloadList);
    }

    if (toogleModalShelf) {
      setSelectedAudit(null);
    }
  }
  

  const __toogleModalShelfSetup = (doReload=false) => {
    setToogleModalShelfSetup(!toogleModalShelfSetup);
    if(doReload){
      setReloadList(!reloadList);
    }

    if (toogleModalShelfSetup) {
      setSelectedAudit(null);
    }
  }
  
  const _clickConter = (audit) => {
    setSelectedAudit(audit);

    if (audit.auditType === 'shelf') {
      __toogleModalShelf();
      return;
    }
    
    if (audit.auditType === 'shelf_setup') {
      __toogleModalShelfSetup();
      return;
    }

    _toogleModalCounter();
  }

  return <Page>
    <PageTitle
      title={<span>
        <span style={{ marginRight: '10px' }}>
          {`${(site.name) || ''} `}
        </span>
        <p className="subtitle">Auditoria <IdBadgeIcon/></p>
      </span>}
    />

    {toogleModalCounter && selectedAudit && <ModalCounter
      site={site}
      audit={selectedAudit}
      toogleModal={_toogleModalCounter}
    />}

    {toogleModalShelf && selectedAudit && <ModalShelf
      site={site}
      audit={selectedAudit}
      toogleModal={__toogleModalShelf}
    />}

    {toogleModalShelfSetup && selectedAudit && <ModalShelfSetup
      site={site}
      audit={selectedAudit}
      toogleModal={__toogleModalShelfSetup}
    />}

    <Columns isMultiline>
      <Column isSize={12}>
        <div className="card notification">
          <p className="subtitle is-2 has-text-centered">Contagens</p>
          <label className="checkbox">
            <input type="checkbox" checked={checkShowDone} onChange={(e) => setCheckShowDone(e.target.checked)} />
            <span>Mostrar finalizados</span>
          </label>
          {isLoadingAudits && <div className="chart_justifycenter">
            <IsLoading fontSize={60} isBorderless={true}/>
          </div>}
          {!isLoadingAudits && <table style={{ width: '100%'}} className="table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Descricao</th>
                <th>Dia</th>
                <th>Hora</th>
                <th>Status</th>
                <th>Visitantes Contados</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {audits
                .filter(audit => ["0", "3", checkShowDone ? "1" : "0"].includes(audit.status) )
                .map(audit => <tr key={`all_auditorias_${audit.id}`}>
                <td>#{audit.id}</td>
                <td>{audit.description}</td>
                <td>{audit.snapDate}</td>
                <td>{audit.snapTime}</td>
                <td>
                  {audit.status === STATUS_AUDITORIA.DESCONSIDERAR ? <span className="tag is-warning is-light">
                    {getStatusDescription(audit.status)}
                  </span>
                  : audit.status === STATUS_AUDITORIA.NAO_INICIADO ? <span className="tag is-default">
                  {getStatusDescription(audit.status)}
                  </span> : audit.status === STATUS_AUDITORIA.EM_ANDAMENTO ? <span className="tag is-link">
                  {getStatusDescription(audit.status)}
                  </span> : audit.status === STATUS_AUDITORIA.FINALIZADO ? <span className="tag is-success is-light">
                    {getStatusDescription(audit.status)}
                  </span> : <span></span>}  
                </td>
                <td>{audit.visitors}</td>
                <td>
                  {audit.status !== "1" ? <span className="button is-primary" onClick={() => _clickConter(audit)}>
                    {audit.auditType === 'shelf' ? "Auditar Prateleira" : audit.auditType === 'shelf_setup' ? "Setup de planograma" : "Contagem"}
                  </span> : <span className="button is-primary is-outlined" onClick={() => _clickConter(audit)}>
                    Rever
                  </span>} 
                </td>
              </tr>)}
            </tbody>
          </table>}
        </div>
      </Column>
    </Columns>
  </Page>
}

export default ManualCount;