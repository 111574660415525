import React, {Component} from 'react';

class JohannMinMaxCard extends Component {

  _handleSearchTerm = (e) => {
    this.setState({ searchTerm: e.target.value });
  }

  render() {
    const { isLoading, hasWarn, icon, label, value, subvalue } = this.props;

    if(isLoading)
      return (<div className="card notification is-white" style={{ padding: 8, backgroundColor: '#eee' }}>
        <span className="tag has-text-weight-bold" style={{width: '100%', marginBottom: 15, padding: 10, backgroundColor: '#d5d5d5' }}>
          {icon} {label}
        </span>
        <p style={{marginBottom: 0}} className={"title is-4"+ (hasWarn ? " has-text-danger ": " ")}>
          {value}
        </p>
        <p className="help">
          {subvalue}
        </p>
      </div>);

    return <div className="card notification is-white" style={{ padding: 8 }}>
      <span className={`tag ${hasWarn ? "is-danger": "is-primary"} is-primary has-text-weight-bold`} style={{width: '100%', marginBottom: 15, padding: 10}}>
        {icon} {label}
      </span>
      <p style={{marginBottom: 0}} className={`title is-4 ${hasWarn ? " has-text-danger ": " "}`}>
        {value}
      </p>
      <p className="help">
        {subvalue}
      </p>
    </div>
  }
}

export default JohannMinMaxCard