import React, { useEffect, useState } from 'react';
import Columns, { Column } from '../../../../components/Columns';
import { ClipboardCheckIcon } from '../../../../components/icons';

import Page, { PageTitle } from '../../../../components/Page';
import AuditVisitorsDashboard from './AuditVisitorsDashboard';
import AuditPassersDashboard from './AuditPassersDashboard';
import { get } from '../../../../utils';
import IsLoading from '../../../../components/IsLoading';
import AuditVenueDashboard from './AuditVenueDashboard';

const useVenues = (siteId) => {
  const [ venues, setVenues ] = useState([]);
  const [ isLoading, setIsLoading ] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    get(`admin/videos/sites/${siteId}/venues`)
      .then(resp =>  {
        setIsLoading(false);
        setVenues(resp.data.data.filter(venue => !venue.isVisitor && !venue.isPassersBy && !venue.isShelf));
      });
  }, [ siteId ])

  return [ isLoading, venues ];
}


const SiteSetup = (props) => {
  const { site } = props;

  const [ selectedVenue, setSelectedVenue ] = useState(null);
  const [ selectedMenu, setSelectedMenu ] = useState('visitors');
  const [ isLoadingVenues, venues ] = useVenues(site.id);

  const selectVenue = (venue) => () => {
    setSelectedVenue(venue);
  }

  const selectMenu = (menu) => (e) => {
    e.preventDefault();

    if(menu !== 'venues') {
      setSelectedVenue(null);
    }

    setSelectedMenu(menu);
  }

  return <Page>
    <PageTitle
      title={<span>
        <span style={{ marginRight: '10px' }}>
          {`${(site.name) || ''} `}
        </span>
        <p className="subtitle">Setup de loja <ClipboardCheckIcon/></p>
      </span>}
    />
    <Columns isGapless={true} isMultiline>
      <Column isSize={12}>
        <div className="tabs is-centered is-large" >
          <ul style={{ borderBottom: 0 }}>
            <li className={`${selectedMenu === 'visitors' ? 'is-active' : ''}`}>
              <a href="#visitors" onClick={selectMenu('visitors')}>Visitantes</a>
            </li>
            <li className={`${selectedMenu === 'passersby' ? 'is-active' : ''}`}>
              <a href="#passersby" onClick={selectMenu('passersby')}>Passantes</a>
            </li>
            <li className={`${selectedMenu === 'venues' ? 'is-active' : ''}`}>
              <a href="#venues" onClick={selectMenu('venues')}>Áreas</a>
            </li>
          </ul>
        </div>
      </Column>
      {selectedMenu === 'visitors' && <Column>
        <AuditVisitorsDashboard site={site} />
      </Column>}
      {selectedMenu === 'passersby' && <Column>
        <AuditPassersDashboard site={site} />
      </Column>}
      {selectedMenu === 'venues' && <Column>
        <div className="card notification">
          {isLoadingVenues && <IsLoading />}
          {selectedVenue === null && venues.map(venue => <span
            key={`venue_id_inside_site_setup_${venue.id}`}
            className={`tag is-large ${venue.id === selectedVenue ? 'is-link': 'cursor-pointer'}`}
            style={{ marginRight: 10 }}
            onClick={selectVenue(venue.id)}
          >
            {venue.description}
          </span>)}
          {selectedVenue !== null && venues
            .filter(v => v.id === selectedVenue)
            .map(venue => <span
              key={`venue_id_inside_site_setup_${venue.id}`}
              className={`tag is-large is-link cursor-pointer`}
              style={{ marginRight: 10 }}
              onClick={selectVenue(null)}
            >
              {venue.description}
              <button className="delete"></button>
            </span>)}
        </div>
        {selectedVenue !== null && <AuditVenueDashboard site={site} venueId={selectedVenue} />}
      </Column>}
    </Columns>
  </Page>
}

export default SiteSetup;