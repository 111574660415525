import React, { Component } from 'react';
import withRouter from "react-router-dom/withRouter";
import { connect } from 'react-redux';


import { get } from '../../../../utils';
import { format } from 'date-fns';
import Columns, { Column } from '../../../../components/Columns';
import * as Icons from '../../../../components/icons';
import Page, { PageTitle } from '../../../../components/Page';
import VisitorsAndPassersBy from './VisitorsAndPassersBy';
import VisitorsAndPassersByHourly from './VisitorsAndPassersByHourly';

class DashboardSalesVideo extends Component {
  constructor(props){
    super(props);
    this.state = {
      loading: false,
      toogleModal: false,
      modalContent: null,
      peopleCountErr: false,
    }
  }
  loadPeopleCount = (type, startDate, endDate, startDate2, endDate2) => {
    const siteId = this.props.site.id;
    const begin = format(startDate, 'YYYY-MM-DD HH:mm:SS');
    const end = format(endDate, 'YYYY-MM-DD HH:mm:SS');

    const timezonebegin = format(startDate, 'Z');
    const timezoneend = format(endDate, 'Z');

    if(startDate2 && endDate2) {
      const begin2 = format(startDate2, 'YYYY-MM-DD HH:mm:SS');
      const end2 = format(endDate2, 'YYYY-MM-DD HH:mm:SS');

      const timezonebegin2 = format(startDate2, 'Z');
      const timezoneend2 = format(endDate2, 'Z');

      return get(`dashboard/video/sites/${siteId}/peoplecount`, { type, begin, end, timezonebegin, timezoneend, begin2, end2, timezonebegin2, timezoneend2 })
        .catch(err => {
          this.setState({ peopleCountErr: true });
          return { data: { header: {}, data: [] } };
        })
        .then(resp => resp.data);
    }
    
    return get(`dashboard/video/sites/${siteId}/peoplecount`, { type, begin, end, timezonebegin, timezoneend })
      .catch(err => {
        this.setState({ peopleCountErr: true });
        return { data: { header: {}, data: [] } };
      })
      .then(resp => resp.data);
  }
  render() {

    const { site, group } = this.props;
    return (
        <Page>
          <PageTitle
            title={<span>
              <span style={{ marginRight: '10px' }}>
                {`${(group && group.name)} - ${(site && site.name) || ''} `}
              </span>
              <p className="subtitle">Vendas vs Tráfego <Icons.SalesIcon /></p>
            </span>}/>

            <Columns isMultiline>
              <Column isSize={12}>
                <VisitorsAndPassersBy site={site} loadPeopleCount={this.loadPeopleCount} dashVideo={true}/>
              </Column>
              <Column isSize={12}>
                <VisitorsAndPassersByHourly site={site} loadPeopleCount={this.loadPeopleCount} dashVideo={true} />
              </Column>
            </Columns>
        </Page>
      )
  }
}


function mapStateToProps(state, props) {
  return {
    tooqAdmin: true,
    site: props.site,
    group: props.group
  }
}

export default withRouter(connect(mapStateToProps)(DashboardSalesVideo));