import React, { useEffect, useState } from 'react'; 

import Columns, { Column } from '../../../../components/Columns';
import { CircleIcon } from '../../../../components/icons';
import IsLoading from '../../../../components/IsLoading';
import { formatAsPtBr, get, getServerDate, post } from '../../../../utils';
import Card from '../../../../components/Card';
import { format } from 'date-fns';

import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ComposedChart,
  LabelList,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import notification from '../../../../utils/notification';

const MESSAGE = "Feito manual pelo dashboard";


const useDashVisitorsAndSales = (siteId, begin, end) => {
  const [ isLoading, setIsLoading ] = useState(true);
  const [ data, setData ] = useState([]);
  
  useEffect(() => {
    setIsLoading(true);
    get(`dashboard/foottraffic/sites/${siteId}/salesrate`, { begin, end })
    .then(resp => {
      setData(resp.data.data);
      setIsLoading(false);
    });
  }, [ siteId, begin, end ]);

  return {
    isLoading: isLoading,
    data: data
  }
}

const markAsOk = (siteId, snapDate) => {
  return post(`dashboard/footanomaly/sites/${siteId}/daily/${snapDate}/setok`, { message: MESSAGE });
}

const markAsAnomaly = (siteId, snapDate, doZero=false) => {
  return post(`dashboard/footanomaly/sites/${siteId}/daily/${snapDate}/setasanomaly`, { doZero, message: MESSAGE });
}

const useDashVisitorsAnomalyHist = (siteId, begin, end, setSelectedDay) => {
  const [ isLoading, setIsLoading ] = useState(true);
  const [ data, setData ] = useState([]);

  const editDay = (day, newVal) => {
    const newDataset = data.map(d => {
      if (d.snapDate === day) {
        return newVal;
      }

      return d;
    });
    setData(newDataset);
  }

  useEffect(() => {
    setIsLoading(true);
    get(`dashboard/footanomaly/sites/${siteId}/daily`, { begin, end })
      .then(resp => {
        setData(resp.data.data.map(d => ({ ...d, number: 1 })));
        setSelectedDay(resp.data.data[resp.data.data.length-1])
        setIsLoading(false);
      });
      // eslint-disable-next-line
  }, [ siteId, begin, end ]);


  return {
    isLoading: isLoading,
    data: data,
    editDay: editDay
  }
}


const getAnomalyStatusColor = (anomalyStatus) => {
  const color = anomalyStatus === "is_ok" 
  ? "#72CB46" : 
  anomalyStatus === "anomaly"
    ? "#E92641" 
    : anomalyStatus === "human_should_review"
      ? "#F5CF07" : "#EEEEEE";

  const status = anomalyStatus === "is_ok" 
  ? "Dentro da normalidade." : 
  anomalyStatus === "anomaly"
    ? "Fora da normalidade." 
    : anomalyStatus === "human_should_review"
      ? "Não foi possível definir pela inteligencia." : "Detector não estava ativo.";

  return {color, status};
}

const CORES = [ "#00d1b2", "#72CB46", "#0043ff", "#7571ff", "#F5CF07", "#E92641" ]

const AnomalyHistPage = ({ site }) => {
  const [ selectedDay, setSelDay ] = useState(null);
  const [ selecting, setSelecting ] = useState(false);

  const setSelectedDay = (val) => {

    if (selecting) 
      return;

    setSelecting(true);


    const {status, color} = getAnomalyStatusColor(val.anomaly);
    const {
      nDays={},
      similarDays={}
    } = val;

    const nDays2 = {
      array_visitantes: [],
      anomaly: "not_registered",
      ...nDays
    }

    const similarDays2 = {
      array_visitantes: [],
      anomaly: "not_registered",
      ...similarDays
    }

    const req1 = get(`dashboard/foottraffic/sites/${site.siteId}/salesindays`, {
      days: val.snapDate
    })
      .then(resp => resp.data.data)
      .then(data => data.map(d => ({
        snapDate: d.info_date,
        sales: d.total_sales,
        visitors: d.visitors,
        conversionRate: d.conversionRate })))

    const req2 = get(`dashboard/footanomaly/sites/${site.siteId}/images/visitors`, {
      snapDate: val.snapDate
    })

    Promise.all([req1, req2])
      .then(([resp1, resp2]) => {
        setSelecting(false);
        setSelDay({
          ...val,
          nDays: nDays2,
          similarDays: similarDays2,
          status,
          color,
          imagesFromDay: resp2.data.data,
          hourlyVisitors: resp1 });
      })
  }

  const [ begin, setBegin ] = useState(format(getServerDate(40), 'YYYY-MM-DD'))
  const [ end, setEnd ] = useState(format(getServerDate(1), 'YYYY-MM-DD'))

  const [ doZero, setDoZero ] = useState(false);

  const salesData = useDashVisitorsAndSales(site.siteId, begin, end);
  const anomalyHist = useDashVisitorsAnomalyHist(site.siteId, begin, end, setSelectedDay);

  const [ isLoadingSelectedDay, setIsLoadingSelectedDay ] = useState(false);

  const markDayAsAnomaly = (siteId, snapDate) => {
    if(isLoadingSelectedDay)
      return;

    setIsLoadingSelectedDay(true);

    const newVal = {
      ...selectedDay,
      anomaly: "anomaly",
      message: MESSAGE
    }

    markAsAnomaly(siteId, snapDate, doZero)
      .then(resp => {
        anomalyHist.editDay(snapDate, newVal);
        setIsLoadingSelectedDay(false);
        setSelectedDay(newVal);
        notification.success('Marcado como anomalia com sucesso.')
      })
  }

  const markDayAsGood = (siteId, snapDate) => {
    if(isLoadingSelectedDay)
      return;

    setIsLoadingSelectedDay(true);

    const newVal = {
      ...selectedDay,
      anomaly: 'is_ok',
      message: MESSAGE
    }

    markAsOk(siteId, snapDate)
      .then(resp => {
        anomalyHist.editDay(snapDate, newVal);
        setSelectedDay(newVal);
        setIsLoadingSelectedDay(false);
        notification.success('Marcado como ok com sucesso.')
      })
  }

  return <Card>
    {/* <p>Dados {begin} até {end}</p> */}
    <Columns>
      <Column isSize={2}>
        Inicio:
        <input className="input" type="date" value={begin} onChange={e => setBegin(e.target.value)} />
      </Column>
      <Column isSize={2}>
        Fim:
        <input className="input" type="date" value={end} onChange={e => setEnd(e.target.value)}/>
      </Column>
    </Columns>

    <Columns isMultiline>
      <Column isSize={12}>
        <p className="subtitle is-4 has-text-centered">Historico visitantes (dashboard)</p>
        {salesData.isLoading && <div className="has-text-centered">
          <IsLoading fontSize={60} isBorderless={true} />
        </div>}
        {!salesData.isLoading && <ResponsiveContainer width="100%" height={300}>
          <ComposedChart syncId="visitorsAndAnomalyHist" cursor={false && this.props.permission === 0 ? "pointer": ""} data={salesData.data}
              margin={{top: 20, right: 0, left: 0, bottom: 20}}>
            <CartesianGrid strokeDasharray="3 10" />
            <XAxis dataKey="info_date" tickFormatter={(val) => `${formatAsPtBr(val, 'ddd - DD/MM')}`} />
            <YAxis yAxisId="left" orientation="left" stroke="#343434" />
            <YAxis yAxisId="right" orientation="right" stroke="#343434" tickFormatter={(val) => `${val}%`} domain={[0, 120]}/>
            <Legend />
            <Tooltip
              isAnimationActive={false}/>
            <Bar
              key="visitors"
              yAxisId="left"
              name="Visitantes"
              dataKey="visitors"
              maxBarSize={15}
              fill="#0043ff"
              isAnimationActive={false}
              minPointSize={3}
              connectNulls={true}
            />
            <Bar
              key="total_sales"
              yAxisId="left"
              name="Vendas"
              dataKey="total_sales"
              maxBarSize={15}
              fill="#00d1b2"
              isAnimationActive={false}
              minPointSize={3}
              connectNulls={true}
            />
            <Line key="conversionRate"
              yAxisId="right"
              name="Taxa de conversão"
              dataKey="conversionRate"
              strokeDasharray="3 5"
              fill="#000"
              stroke="#000"
              isAnimationActive={false}
              connectNulls={true}
            >
              <LabelList dataKey="conversionRate" position="top" formatter={(val) => `${val}%`} fontSize={22} />
            </Line>
          </ComposedChart>
        </ResponsiveContainer>}
      </Column>
      <Column isSize={12}>
        <p className="subtitle is-4 has-text-centered">Historico da detecção de anomalia</p>
        {anomalyHist.isLoading && <div className="has-text-centered">
          <IsLoading fontSize={60} isBorderless={true} />
        </div>}
        {!anomalyHist.isLoading && <ResponsiveContainer width="100%" height={150}>
          <BarChart syncId="visitorsAndAnomalyHist" data={anomalyHist.data}
              margin={{top: 20, right: 0, left: 0, bottom: 20}}>
            <CartesianGrid strokeDasharray="3 10" />
            <XAxis dataKey="snapDate" tickFormatter={(val) => `${formatAsPtBr(val, 'ddd - DD/MM')}`} />
            <YAxis yAxisId="left" orientation="left" stroke="#343434" domain={[0, 1]} />
            <YAxis yAxisId="right" orientation="right" stroke="#343434" domain={[0, 1]} />
            <Legend />
            <Tooltip />
            <Bar
              key="number"
              yAxisId="left"
              name="Anomalia?"
              dataKey="number"
              onClick={props => setSelectedDay(props)}
              cursor="pointer"
              maxBarSize={30}
              background={{ fill: '#eee' }}
            >
              {anomalyHist.data.map((entry, index) => {
                return <Cell
                  key={`cellbar-${index}`}
                  opacity={(selectedDay && entry.snapDate === selectedDay.snapDate) ? 1 : 0.6}
                  fill={getAnomalyStatusColor(entry.anomaly).color}
                />
              })}
            </Bar>
          </BarChart>
        </ResponsiveContainer>}
        <p className="help has-text-centered">
          Clique nas barras para interagir
        </p>
      </Column>
      {selecting && <div className="has-text-centered" style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <IsLoading fontSize={60} isBorderless={true} /> 
      </div>}
      {!selecting && !selectedDay && <Column isSize={12} style={{ minHeight: 250, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <p className="subtitle is-4 has-text-centered">Escolha um dia para analisar</p>
      </Column>}
      {!selecting && selectedDay && <Column isSize={12} style={{ minHeight: 250, border: '1px solid #ccc', padding: 20 }}>
        <p className="title is-4 has-text-centered" style={{ textTransform: 'capitalize' }}>{formatAsPtBr(selectedDay.snapDate, 'DD/MM/YYYY')}</p>
        <p className="subtitle is-4 has-text-centered" style={{ textTransform: 'capitalize' }}>{formatAsPtBr(selectedDay.snapDate, 'dddd')}</p>
        
        <p><b>Status: </b><CircleIcon color={selectedDay.color} /> {selectedDay.status}</p>
        <p><b>Mensagem: </b> {selectedDay.message}</p>

        <br/>
        <Columns isMultiline>
          <Column isSize={4}>
            <p><b>Parametros ultimos dias: </b></p>
            <p style={{ marginLeft: 15 }}><b>Range:</b> {parseInt(selectedDay.nDays.min_geral)} até {parseInt(selectedDay.nDays.max_geral)} </p>
            <p style={{ marginLeft: 15 }}><b>Média:</b> {parseInt(selectedDay.nDays.media_geral)}</p>
            <p style={{ marginLeft: 15 }}><b>Desv. Padrão:</b> {parseInt(selectedDay.nDays.std_geral)}</p>
            <p style={{ marginLeft: 15 }}><b>Fator Desv. Padrão usado:</b> {selectedDay.nDays.std_fator_used}</p>
            <p style={{ marginLeft: 15 }}><b>Dataset usado:</b> [ {selectedDay.nDays.array_visitantes.join(', ')} ] </p>
            <p style={{ marginLeft: 15 }}><b>Status analise:</b> <CircleIcon color={getAnomalyStatusColor(selectedDay.nDays.anomaly).color} /> {getAnomalyStatusColor(selectedDay.nDays.anomaly).status}</p>
          </Column>
          <Column isSize={4}>
            <p><b>Parametros dias similares (apenas {formatAsPtBr(selectedDay.snapDate, 'dddd')}): </b></p>
            <p style={{ marginLeft: 15 }}><b>Range:</b> {parseInt(selectedDay.similarDays.min_similar)} até {parseInt(selectedDay.similarDays.max_similar)} </p>
            <p style={{ marginLeft: 15 }}><b>Média:</b> {parseInt(selectedDay.similarDays.media_similar)}</p>
            <p style={{ marginLeft: 15 }}><b>Desv. Padrão:</b> {parseInt(selectedDay.similarDays.std_similar)}</p>
            <p style={{ marginLeft: 15 }}><b>Fator Desv. Padrão usado:</b> {selectedDay.similarDays.std_fator_used}</p>
            <p style={{ marginLeft: 15 }}><b>Dataset usado:</b> [ {selectedDay.similarDays.array_visitantes.join(', ')} ]</p>
            <p style={{ marginLeft: 15 }}><b>Status analise:</b> <CircleIcon color={getAnomalyStatusColor(selectedDay.similarDays.anomaly).color} /> {getAnomalyStatusColor(selectedDay.similarDays.anomaly).status}</p>
          </Column>
        
          <Column isSize={12}>
            <p className="subtitle is-4 has-text-centered">
              Fluxo e vendas no dia
            </p>
            <ResponsiveContainer width="100%" height={200}>
              <ComposedChart data={selectedDay.hourlyVisitors}
                  margin={{top: 20, right: 0, left: 0, bottom: 20}}>

                <XAxis dataKey="snapDate" interval={0} tickFormatter={(val) => `${format(val, 'HH:mm')}`} />
                <YAxis yAxisId="left" orientation="left" stroke="#343434"/>
                <YAxis yAxisId="right" orientation="right" stroke="#343434" tickFormatter={(val) => `${val}%`} domain={[0, 120]}/>
                <Bar
                  key="visitors"
                  yAxisId="left"
                  name="Visitantes"
                  dataKey="visitors"
                  maxBarSize={15}
                  fill="#0043ff"
                  isAnimationActive={false}
                  minPointSize={3}
                  connectNulls={true}
                >
                </Bar>
                <Bar
                  key="total_sales"
                  yAxisId="left"
                  name="Vendas"
                  dataKey="sales"
                  maxBarSize={15}
                  fill="#00d1b2"
                  isAnimationActive={false}
                  minPointSize={3}
                  connectNulls={true}
                />
                <Line key="conversionRate"
                  yAxisId="right"
                  name="Taxa de conversão"
                  dataKey="conversionRate"
                  strokeDasharray="3 5"
                  fill="#000"
                  stroke="#000"
                  isAnimationActive={false}
                  connectNulls={true}
                >
                  <LabelList dataKey="conversionRate" position="top" formatter={(val) => `${val}%`} fontSize={22} />
                </Line>
                <Legend />
                <Tooltip />
              </ComposedChart>
            </ResponsiveContainer>
          </Column>


          {selectedDay.imagesFromDay.cameras === 0 && <Column isSize={12}> 
            <p className="subtitle is-4">
              Não há fotos para esse dia.  
            </p>
          </Column>}

          {selectedDay.imagesFromDay.cameras !== 0 && <Column isSize={12}>
            <p className="subtitle is-4 has-text-centered">
              Fotos do dia para as cameras de <b>fluxo</b>
            </p>
            <ResponsiveContainer width="100%" height={250}>
              <BarChart syncId="visitorsAndAnomalyHist" data={selectedDay.imagesFromDay.images}
                  margin={{top: 20, right: 0, left: 0, bottom: 20}}>
                <CartesianGrid strokeDasharray="3 10" />
                <XAxis dataKey="hora" tickFormatter={(hora) => `${hora}`} />
                <YAxis yAxisId="left" orientation="left" stroke="#343434" domain={[0, selectedDay.imagesFromDay.cameras.length * 3600]} />
                <YAxis yAxisId="right" orientation="right" stroke="#343434" domain={[0, selectedDay.imagesFromDay.cameras.length * 3600]} />
                <Legend />
                <Tooltip />
                {selectedDay.imagesFromDay.cameras.map((camera, i) => <Bar
                  stackId="cameras"
                  key={`cameras.${camera}`}
                  yAxisId="left"
                  name={`Qtde Fotos CAM${camera}`}
                  dataKey={`cameras.${camera}`}
                  maxBarSize={20}
                  fill={CORES[i]}
                >
                </Bar>)}
              </BarChart>
            </ResponsiveContainer>
          </Column>}
        </Columns>
        {selectedDay.anomaly === 'is_ok' && <div>
          <input
            type="checkbox"
            onChange={(e) => setDoZero(e.target.checked)}
            checked={doZero} />
            zerar o numero
        </div>}
        {isLoadingSelectedDay && <IsLoading className={selectedDay.anomaly === 'is_ok' ? 'is-danger' : 'is-success'}/>}
        {!isLoadingSelectedDay && selectedDay.anomaly === 'is_ok' && <span className="button is-danger" onClick={() => markDayAsAnomaly(site.siteId, selectedDay.snapDate)}>Marcar como anomalia</span>}
        {!isLoadingSelectedDay && selectedDay.anomaly !== 'is_ok' && <span className="button is-success" onClick={() => markDayAsGood(site.siteId, selectedDay.snapDate)}>Marcar como OK</span>}
      </Column>}
    </Columns>
  </Card>
}

export default AnomalyHistPage;