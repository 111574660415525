import  React, { Component } from 'react';

class FunnelChart extends Component {
  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
  }

  render() {
    return (
      <div> 
        <p style={{marginBottom: 10}} className="subtitle has-text-centered">Taxa de Perda por Etapa</p>
        <ProgressBarTime
          isLoading={false}
          isBig={false}
          description="Entrada"
          rate={100}
          value={"55%"}
          progressColor={'rgba(213, 62, 79, 0.9)'}
          />
        
        <ProgressBarTime
          isLoading={false}
          isBig={false}
          description="Calçados"
          rate={80}
          value={"25%"}
          progressColor={'rgba(244, 109, 67, 0.9)'}
          />

        <ProgressBarTime
          isLoading={false}
          isBig={false}
          description="Atendidos"
          rate={70}
          value={"24%"}
          progressColor={'rgba(253, 174, 97, 0.9)'}
          />

        <ProgressBarTime
          isLoading={false}
          isBig={false}
          description="Banco"
          rate={60}
          value={"29%"}
          progressColor={'rgba(255, 207, 73, 0.9)'}
          />

        <ProgressBarTime
          isLoading={false}
          isBig={false}
          description="Caixa"
          rate={40}
          value={"3%"}
          progressColor={'rgba(194, 202, 74, 0.9)'}
          />
        
      </div>
    );
  }
  
}
const ProgressBarTime = ({ description, rate, progressColor, isBig=false, value }) => {
  
  return <div style={{
    width: '100%'
  }}>
    <div style={{
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      borderRadius: 5,
      height: isBig ? 50 : 40,
      marginBottom: 10
    }}>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: `${rate > 100 ? 100: rate}%`,
        minWidth: '1%',
        borderRadius: 5,
        height: isBig ? 50 : 40,
        backgroundImage: `linear-gradient(to right, ${progressColor} 35% , rgba(174, 174, 174, 0.9) 85%)`,
      }}>
        <span style={{
          fontWeight: isBig ? 'bold' : '',
          color: 'white',
          whiteSpace: 'nowrap',
          fontSize: isBig ? '1.5rem' : '1.2rem',
          marginLeft: 10
        }}>
          {description}
        </span>
        <div style={{
          margin: 5,
          padding: 5,
          fontSize: isBig ? '1rem' : '0.8rem',
          textDecoration: 'weight',
          backgroundColor: '#ffffff',
          justifyContent: 'center',
          display: 'flex',
          alignItems: 'center',
          borderRadius: 15,
          minWidth: 40
        }}>
          <b>{value}</b>
        </div>
      </div>
    </div>
  </div>
}
export default FunnelChart;
