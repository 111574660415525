
import React, { useEffect } from 'react';
import Card from '../../../../components/Card';
import {IsLoadingCentered} from '../../../../components/IsLoading';
import Modal from '../../../../components/Modal';
import { TrashIcon } from '../../../../components/icons';
import { useCrud } from '../../../../hooks/useCrud';
import { useField } from '../../../../hooks/useField';
import { get, post, del} from '../../../../utils';
import notification from '../../../../utils/notification';


const IaDatasetModal = props => {
  const dataset = useCrud();

  const descriptionField = useField();
  const pathField = useField();

  const newDataset = (e) => {
    e.preventDefault();

    return dataset.add(
      () => post(`mlops/ia-dataset`, {
        description: descriptionField.value,
        path: pathField.value,
        groupId: 0,
        siteId: props.site.id
      })
    )
    .then(() => {
      descriptionField.setValue('')
      pathField.setValue('')
    })
    .catch(err => {
      notification.error('Erro ao salvar o modelo');
    })
  }

  const removeDataset = datasetId => {
    dataset.remove(
      datasetId,
      () => del(`mlops/ia-dataset/${datasetId}`)
    )
    .catch(err => notification.error(err));
  }

  useEffect(() => {
    dataset.list(
      () => get(`mlops/ia-dataset?siteId=${props.site.id}`)
    )
    //eslint-disable-next-line
  }, []);

  return <Modal
      handleToogleModal={props.closeModal}
      style={{ width: "35%", height: '50%' }}
      component={<Card style={{ height: '95%', overflow: 'auto' }}>
      <p className="title is-4">IA Dataset</p>
      <form className="field is-grouped" onSubmit={newDataset}>
        <div className="control is-expanded">
          <input className='input' {...descriptionField} placeholder="Descrição dataset"  required/>
        </div>
        <div className="control is-expanded">
          <input className='input' {...pathField} placeholder="Path S3" required/>
        </div>
        <div className="control">
          <button type='submit' className={`button is-primary ${dataset.isAddingRow ? 'is-loading': ''}`}>
            Salvar
          </button>
        </div>
      </form>

      <table className='table is-fullwidth'>
        <thead>
          <tr>
            <th>ID</th>
            <th>Descrição</th>
            <th>Path S3</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {dataset.data.map(row => <tr>
            <td>{row.id}</td>
            <td>{row.description}</td>
            <td>{row.path}</td>
            <td>
              <span className={`button is-danger ${dataset.isRemovingRow[row.id] ? 'is-loading' : ''}`} onClick={() => removeDataset(row.id)}>
                <TrashIcon />
              </span>
            </td>
          </tr>)}
        </tbody>
      </table>
      {dataset.isLoadingList && <IsLoadingCentered fontSize={40} isBorderless={true} />}
    </Card>}
  />
}

export default IaDatasetModal;