import React, { Component } from 'react';

import tinycolor from 'tinycolor2';
import * as Icons from '../../../../../components/icons';
import { put, post } from '../../../../../utils';

import './AnalysisTags.css';

class AnalysisTags extends Component {

  constructor(props) {
    super(props);

    this.state = {
      toogleEditTag: false,
      toogleNewTag: false,
      toogleCopyTag: false,
      tagToEdit: null,
      tagToEditOriginal: null,
      selectedTag: null,
      tagCopyAuditId: 0,
      tagsToApply: [
        ...props.boxTags
      ],
      tagToAdd: {
        label: "",
        color: "#FFFFCC"
      },
      tags: props.tags
    }
  }

  _toogleCopyTag = () => {
    this.setState({
      tagCopyAuditId: 0,
      toogleCopyTag: !this.state.toogleCopyTag
    });
  }

  _reloadColor = () => {
    const { tagToAdd } = this.state;

    this.setState({
      tagToAdd: {
        ...tagToAdd,
        color: `#${Math.floor(Math.random()*16777215).toString(16)}`
      }
    });
  }

  _toogleNewTag = () => {
    const { toogleCopyTag, toogleNewTag } = this.state;
    if (toogleCopyTag) {
      this._toogleCopyTag();
      return;
    }
    const newState = !toogleNewTag;
    if(newState) {
      this._reloadColor()
    }
    this.setState({ toogleNewTag: !toogleNewTag });
  }

  _toogleEditTag = () => {
    this.setState({
      toogleEditTag: false,
      tagToEdit: null
    })
  }

  _editTag = (tag) => () => {
    this.setState({
      toogleNewTag: false,
      toogleCopyTag: false,
      toogleEditTag: true,
      tagToEditOriginal: {...tag},
      tagToEdit: {...tag}
    });
  }

  _editFormCanSave = () => {
    const { tagToEdit, tagToEditOriginal } = this.state;
    return (tagToEdit.label.length > 0 && tagToEdit.label !== tagToEditOriginal.label)
      || tagToEdit.color !== tagToEditOriginal.color 
  }

  _changeTagToCopy = (e) => {
    const { value } = e.target;

    this.setState({
      tagCopyAuditId: value
    });
  }

  __selectTag = (tag) => () => {
    const { tagsToApply } = this.state;

    if(this._isTagSelected(tag.id)) {
      this.setState({
        tagsToApply: tagsToApply.filter(tagToApply => tagToApply.id !== tag.id)
      });
      return;
    }

    this.setState({ 
      tagsToApply: [...tagsToApply, tag]
    });
  }

  _isTagSelected = (tagId) => {
    const { tagsToApply } = this.state;
    return tagsToApply.findIndex(tag => tag.id === tagId) !== -1;
  }

  _onChangeEdit = (e) => {
    const { value, name } = e.target;
    const { tagToEdit } = this.state;

    tagToEdit[name] = value;
    this.setState({ tagToEdit: tagToEdit });
  }

  _onChangeNewTag = (e) => {
    const { value, name } = e.target;
    const { tagToAdd } = this.state;

    tagToAdd[name] = value;
    this.setState({ tagToAdd: tagToAdd });
  }

  _copyTagsFromAudit = () => {
    const {
      siteId,
      auditId
    } = this.props;

    const { tagCopyAuditId, isCopying } = this.state;
    if (tagCopyAuditId === 0 || isCopying || auditId === tagCopyAuditId) {
      return;
    }

    this.setState({ isCopying: true }, () => {

      post(`dashboard/video/sites/${siteId}/audit/${auditId}/tags/copy`, {
        fromAuditId: tagCopyAuditId
      })
      .then(resp => {
        this.setState({
          tags: resp.data.data,
          isCopying: false,
          toogleCopyTag: false,
          tagCopyAuditId: 0,
          toogleNewTag: false
        }, () => {
          this.props.handleRefreshTags(this.state.tags);
        });
      });
    });
  }

  _saveEdit = () => {
    const {
      tagToEdit,
      isEditing
    } = this.state;

    if(!this._editFormCanSave() || isEditing)
      return;

    this.setState({ isEditing: true }, () => {
      const {
        siteId,
        auditId
      } = this.props;
      put(`dashboard/video/sites/${siteId}/audit/${auditId}/tags/${tagToEdit.id}`, {
        label: tagToEdit.label,
        color: tagToEdit.color
      })
      .then(resp => {
        const { tags } = this.state;
        const tagsNew = tags.map(tag => {
          if(tag.id === resp.data.data.id)
            return resp.data.data;

          return tag;
        });

        this.setState({
          isEditing: false,
          tags: tagsNew,
          tagToEdit: null,
          tagToEditOriginal: null,
          toogleEditTag: false,
          toogleNewTag: false,
          toogleCopyTag: false
        }, () => {
          this.props.handleRefreshTags(this.state.tags);
        });

        return;
      });
    });
  }

  _includeTag = () => {
    const {
      tags,
      tagToAdd,
      isSavingNewTag
    } = this.state;

    if(tagToAdd.label.length === 0 || isSavingNewTag)
      return

    this.setState({ isSavingNewTag: true }, () => {
      const {
        siteId,
        auditId
      } = this.props;
  
      post(`dashboard/video/sites/${siteId}/audit/${auditId}/tags`, {
        label: tagToAdd.label,
        color: tagToAdd.color
      })
      .then(resp => {
        this.setState({
          tags: [ ...tags, resp.data.data ],
          tagsToApply: [ ...this.state.tagsToApply, resp.data.data],
          isSavingNewTag: false,
          toogleNewTag: false,
          tagToAdd: {
            label: "",
            color: "#FFFFCC"
          }
        }, () => {
          this.props.handleRefreshTags(this.state.tags);
        });
      });
    });
  }

  _handleApplyTag = () => {
    const { tagsToApply } = this.state;
    const { selectedBox } = this.props;

    if(tagsToApply.length === 0)
      return;

    if(selectedBox) {
      this.props.handleApplyEditTags(tagsToApply);
      return;
    }

    this.props.handleApply(tagsToApply);
  }

  render() {
    const {
      tagsSugestion
    } = this.props;

    const {
      tagsToApply,
      isEditing,
      isSavingNewTag,
      isCopying,
      tags,
      tagCopyAuditId,
      toogleNewTag,
      toogleEditTag,
      toogleCopyTag,
      tagToEdit,
      tagToEditOriginal,
      tagToAdd
    } = this.state;

    const tagSugestion = tagsSugestion
    .filter(sugestion => {
      return tagsToApply
        .map(tagToApply => `${tagToApply.id}`)
        .map(tagToApply => sugestion.includes(tagToApply))
        .findIndex(s => !s) === -1 && tagsToApply.length > 0; // nao podem haver false, assim todos os tags selecionados estao na sugestao.
    })
    .map(sugestion => {
      return sugestion.map(sugestionTag => tags.find(tag => tag.id === parseInt(sugestionTag)))
    });

    return <div className="card box-analysis-tags" style={{ cursor: 'default' }}>
      {toogleNewTag && !toogleCopyTag && !toogleEditTag && <div className="box-tags">
        <div className="field">
          <span className="label">Descrição tag</span>
          <div className="control">
            <input
              className={`input `}
              type="text"
              placeholder="Descrição"
              name="label"
              value={tagToAdd.label}
              onChange={this._onChangeNewTag}
            />
          </div>
        </div>

        <label className="label">Selecione a cor</label>
        <div className="field is-grouped">
          <div className="control">
            <input
              type="color"
              name="color"
              value={tagToAdd.color}
              onChange={this._onChangeNewTag}
            />
          </div>
          <div className="control">
            <Icons.SyncAltIcon className="cursor-pointer" onClick={this._reloadColor} />
          </div>
        </div>
        
        <div className="buttons">
          <span className={`button is-primary ${isSavingNewTag ? 'is-loading': ''}`} onClick={this._includeTag}>Criar</span>
          <span className="button" onClick={this._toogleNewTag}>Cancelar</span>
        </div>
      </div>}

      {toogleCopyTag && !toogleEditTag && <div className="box-tags">
        <div className="field">
          <span className="label">ID. Auditoria</span>
          <div className="control">
            <input
              className="input"
              type="number"
              placeholder="ID. Auditoria"
              value={tagCopyAuditId}
              onChange={this._changeTagToCopy}
            />
          </div>
        </div>
        
        <div className="buttons">
          <span className={`button is-primary ${isCopying ? 'is-loading': ''}`} onClick={this._copyTagsFromAudit}>Copiar</span>
          <span className="button" onClick={this._toogleCopyTag}>Cancelar</span>
        </div>
      </div>}

      {toogleEditTag && tagToEdit && <div className="box-tags">
        <div className="field">
          <span className="label">Descrição tag</span>
          <div className="control">
            <input 
              onChange={this._onChangeEdit}
              name="label"
              className="input"
              type="text"
              placeholder="Descrição"
              value={tagToEdit.label}
              maxLength={33}
            />
          </div>
          {tagToEdit.label !== tagToEditOriginal.label && <p className="help">Mudou de <b>{tagToEditOriginal.label}</b> para <b>{tagToEdit.label}</b></p>}
        </div>

        <div className="field">
          <label className="label">Selecione a cor</label>
          <div className="control">
            <input
              name="color"
              type="color"
              onChange={this._onChangeEdit}
              value={tagToEdit.color}
            />
          </div>
          {tagToEdit.color !== tagToEditOriginal.color && <p
            className="help"
            >
            Mudou de <b style={{ color: tagToEditOriginal.color }}>{tagToEditOriginal.color}</b> para <b  style={{ color: tagToEdit.color }}>{tagToEdit.color}</b>
          </p>}
        </div>
        
        <div className="buttons">
          <span
            className={`button is-primary ${isEditing ? 'is-loading':''}`}
            disabled={!this._editFormCanSave()}
            onClick={this._saveEdit}
          >
            Salvar
          </span>
          <span className="button" onClick={this._toogleEditTag}>Cancelar</span>
        </div>
      </div>}
      {!toogleNewTag && !toogleCopyTag && !toogleEditTag && <div style={{ maxHeight: '85%' }}>
        <ul className="tags-analysis">
          {tags.map(tag => <li
            key={`analysis_flow_tag_${tag.id}`}
            className="tag-analysis"
          >
            <div
              className={`tag ${this._isTagSelected(tag.id) ? 'tag-selected' : ''}`}
              onClick={this.__selectTag(tag)}
              style={{
                backgroundColor: tag.color,
                color: tinycolor(tag.color).isDark() ? '#FFFFFF':'#222222'
              }}>
                {tag.label}
            </div>
            <Icons.EditIcon className="noshow-button" onClick={this._editTag(tag)}/>
          </li>)}
          <li>
            <div
              className="tag"
              onClick={this._toogleNewTag}
            >
              Nova Tag
            </div>
          </li>
        </ul>
        <div className="subtitle is-4" style={{ marginLeft: 5, marginBottom: 0 }}>Sugestões:</div>
        <ul className="box-tags-audit-sugestions">
          {tagSugestion.map(sugestionTag => <li className="box-tags-audit-sugestion">
            <div>
              {sugestionTag.map(tag => <div
              className={`box-tags-audit-sugestion-tag tag ${this._isTagSelected(tag.id) ? 'tag-selected' : ''}`}
              style={{
                backgroundColor: tag.color,
                color: tinycolor(tag.color).isDark() ? '#FFFFFF':'#222222'
              }}>{tag.label}</div>)}
            </div>
            <div>
              <div
                style={{ marginLeft: 25 }}
                className="box-tags-audit-sugestion-tag tag cursor-pointer is-primary"
                onClick={() => {
                  this.setState({ 
                    tagsToApply: [...sugestionTag]
                  }, this._handleApplyTag);
                }}
              >
                Aplicar tags
              </div>
              <div
                className="box-tags-audit-sugestion-tag tag cursor-pointer"
                onClick={() => 
                  this.setState({ 
                    tagsToApply: [...sugestionTag]
                  })}
              >
                Selecionar tudo
              </div>
            
            </div>
          </li>)}
        </ul>
      </div>}
      {!toogleNewTag && !toogleCopyTag && !toogleEditTag && <div className="tags-analysis-buttons">
        <div>
          <div
            onClick={this._handleApplyTag}
            className="button is-primary"
            disabled={tagsToApply.length === 0}
          >
            Aplicar tags
          </div>
          <div
            style={{ marginLeft: '5px' }}
            onClick={this.props.handleCancel}
            className="button"
          >
            Cancelar
          </div>
        </div>
        <div className="button is-small" onClick={this._toogleCopyTag}>Copiar tags de outra auditoria</div>
      </div>}
    </div>
  }
}

export default AnalysisTags