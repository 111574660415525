import React, { Component } from 'react';

import { connect } from 'react-redux';

import {
  Link,
  Route,
  withRouter
} from "react-router-dom";

import * as Icons from './icons';

class MainAsideMenuFoottraffic extends Component {

  render() {
    const { match, site, user } = this.props;
    const { url } = match;

    const { modules = {}, userPermissions } = site;

    if (userPermissions.permission === 3) {
      return <aside className={`menu aside-menu is-aside-toogle`}>
        <div className="menu-aside-header" style={{ minHeight: 100 }}>
          <p className="subtitle is-5 child-to-show">
            {site.name}
          </p>
        </div>
        <div className="menu-label">
          Administrativo
        </div>
        <ul className="menu-list" style={{ position: 'static' }}>
          <Route
            path={`${url}/home`}
            children={({ match }) => <li className={`${match ? 'is-aside-selected' : ''}`}>
              <Link to={`${url}/home`}>
                <Icons.IdBadgeIcon className="is-mini is-gray" />
                <span className="child-to-show">Contagem manual</span>
              </Link>
            </li>}
          />
        </ul>
      </aside>
    }

    return (<aside className={`menu aside-menu is-aside-toogle`}>
      <div className="menu-aside-header" style={{ minHeight: 100 }}>
        <p className="subtitle is-5 child-to-show">
          {site.name}
        </p>
      </div>
      <p className="menu-label">
        Dashboard
      </p>
      <ul className="menu-list" style={{ position: 'static' }}>
        <Route
          path={`${url}/home`}
          children={({ match }) => <li className={`${match ? 'is-aside-selected' : ''}`}>
            <Link to={`${url}/home`}>
              <Icons.HomeIcon className="is-mini is-gray" />
              <span className="child-to-show">Página Inicial</span>
            </Link>
          </li>}
        />
        {modules.isSuvinil && <Route
          path={`${url}/gondolas`}
          children={({ match }) => <li className={`${match ? 'is-aside-selected' : ''}`}>
            <Link to={`${url}/gondolas`}>
              <Icons.FillIcon className="is-mini is-gray" />
              <span className="child-to-show">Ánalise de Gôndolas</span>
            </Link>
          </li>}
        />}
        {modules.shelf && <Route
          path={`${url}/visitors`}
          children={({ match }) => <li className={`${match ? 'is-aside-selected' : ''}`}>
            <Link to={`${url}/visitors`}>
              <Icons.WalkingIcon className="is-mini is-gray" />
              <span className="child-to-show">Visitantes</span>
            </Link>
          </li>}
        />}
        {!modules.isSuvinil && <Route
          path={`${url}/sales`}
          children={({ match }) => <li className={`${match ? 'is-aside-selected' : ''}`}>
            <Link to={`${url}/sales`}>
              <Icons.SalesIcon className="is-mini is-gray" />
              <span className="child-to-show">Vendas</span>
            </Link>
          </li>}
        />}
        {site.isOnline && <Route
          path={`${url}/realtime`}
          children={({ match }) => <li className={`${match ? 'is-aside-selected' : ''}`}>
            <Link to={`${url}/realtime`}>
              <Icons.PlayCircle className="is-mini is-gray" />
              <span className="child-to-show">Tempo Real</span>
            </Link>
          </li>}
        />}

        {modules.queues && <Route
          path={`${url}/queues`}
          children={({ match }) => <li className={`${match ? 'is-aside-selected' : ''}`}>
            <Link to={`${url}/queues`}>
              <Icons.MaleIcon />
              <span className="child-to-show">Gestão de Filas</span>
            </Link>
          </li>}
        />}

        {modules.foottraffic && <Route
          path={`${url}/relatorios`}
          children={({ match }) => <li className={`${match ? 'is-aside-selected' : ''}`}>
            <Link to={`${url}/relatorios`}>
              <Icons.FilePDFIcon />
              <span className="child-to-show">Relatórios</span>
            </Link>
          </li>}
        />}

        {(site.userPermissions.permission === 0 || site.userPermissions.permission === 3) && <Route
          path={`${url}/dedoduro`}
          children={({ match }) => <li className={`${match ? 'is-aside-selected' : ''}`}>
            <Link to={`${url}/dedoduro`}>
              <Icons.HandPointRightIcon className="is-mini is-gray" />
              <span className="child-to-show">Trilha de acessos</span>
            </Link>
          </li>}
        />}

        {(user.tooqAdmin || userPermissions.permission === 0) && <Route
          path={`${url}/manualcount`}
          children={({ match }) => <li className={`${match ? 'is-aside-selected' : ''}`}>
            <Link to={`${url}/manualcount`}>
              <Icons.IdBadgeIcon className="is-mini is-gray" />
              <span className="child-to-show">Auditorias</span>
            </Link>
          </li>}
        />}
      </ul>
      {(user.tooqAdmin || userPermissions.permission === 0) && <div className="menu-label">
        Administrativo
      </div>}
      {(user.tooqAdmin || userPermissions.permission === 0) && <ul className="menu-list" style={{ position: 'static' }}>
        <Route
          path={`${url}/settings`}
          children={({ match }) => <li className={`${match ? 'is-aside-selected' : ''}`}>
            <Link to={`${url}/settings`}>
              <Icons.CogIcon className="is-mini is-gray" />
              <span className="child-to-show">Configurações</span>
            </Link>
          </li>}
        />
      </ul>}

      {(user.tooqAdmin) && <div className="menu-label">
        Genius Store
      </div>}
      {(user.tooqAdmin) && <ul className="menu-list" style={{ position: 'static' }}>
        <Route
          path={`${url}/audits`}
          children={({ match }) => <li className={`${match ? 'is-aside-selected' : ''}`}>
            <Link to={`${url}/audits`}>
              <Icons.ClipboardListIcon className="is-mini is-gray" />
              <span className="child-to-show">Auditorias</span>
            </Link>
          </li>}
        />
        <Route
          path={`${url}/sitehealth`}
          children={({ match }) => <li className={`${match ? 'is-aside-selected' : ''}`}>
            <Link to={`${url}/sitehealth`}>
              <Icons.StethoscopeIcon className="is-mini is-gray" />
              <span className="child-to-show">Saúde da Loja</span>
            </Link>
          </li>}
        />
        <Route
          path={`${url}/setup`}
          children={({ match }) => <li className={`${match ? 'is-aside-selected' : ''}`}>
            <Link to={`${url}/setup`}>
              <Icons.ClipboardCheckIcon className="is-mini is-gray" />
              <span className="child-to-show">Setup da loja</span>
            </Link>
          </li>}
        />
      </ul>}
    </aside>);
  }
}

function mapStateToProps(state, props) {
  const { match } = props;
  const site = state.sites.all[match.params.siteId] || {};
  return { site, user: state.app.user };
}

export default withRouter(connect(mapStateToProps)(MainAsideMenuFoottraffic));
