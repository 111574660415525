import React, { Component } from 'react';

import {
  addDays, format
} from 'date-fns';

import { BranchHistoryIcon } from '../../../../../components/icons';
import SalesAndVisitors from './SalesAndVisitors';
import { getServerDate } from '../../../../../utils';

class SalesRateChart extends Component {

  constructor(props) {
    super(props);
    
    const CURRDATE = addDays(getServerDate(), -1);
    
    this.state = {
      isLoading: false,
      toogleFilter: false,
      filterRanges: {
        startDate: addDays(CURRDATE, -6),
        endDate: CURRDATE,
        key: 'selectionRange1'
      },
      dash: {
        header: {},
        data: []
      }
    }
  }

  _toogleFilter = () => {
    this.setState({ toogleFilter: !this.state.toogleFilter });
  }

  _handleFilterRangeDates = () => (ranges1, ranges2) => {
    this.setState({ filterRanges: ranges1, filterRanges2: ranges2 }, () => {
      this.loadData();
    });

    this._toogleFilter();
  }

  loadData = () => {
    this.setState({ isLoading: true }, () => {
      const { filterRanges } = this.state;
      this.props.loadSalesRate(filterRanges.startDate, filterRanges.endDate)
        .then(result => {
          this.setState({ isLoading: false, dash: result });
        });
    });
  }

  _showMore = () => {
    const { filterRanges, dash, isLoading } = this.state;
    if(isLoading) {
      return;
    }
    const {dashVideo} = this.props

    dash.data = this.mapVideoData(dash.data)

    this.props.showMore(<SalesAndVisitors
        shopId={this.props.shopId}
        filterRanges={filterRanges}
        site={this.props.site}
        dash={dash}
        dashVideo={dashVideo}
        mirrorFunction={(dash) => this.setState({ dash: dash })}
        loadSalesRate={this.props.loadSalesRate}
      />);
  }

  mapVideoData = (data) => {
    return data.map(({info_date, visitorsCount, visitors, totalSales, total_sales, conversionRate, salesRate, ticket}) => {
     return {
       info_date,
       auditingOk: true,
       visitors: visitors || visitorsCount,
       total_sales: total_sales || totalSales,
       conversionRate: conversionRate || salesRate,
     }
   })
  }
  componentDidMount() {
    this.loadData();
  }

  render() {
    const { isLoading, dash } = this.state;
    const { header } = dash;
    return<div>
      <div className="card notification is-paddingless is-link">
        <div className="card-content"> 
          <div className="tile is-ancestor">
            <div className="tile is-6 is-vertical is-parent">
              <div className="subtitle is-6">Taxa de  vendas {header.info_date ? format(header.info_date, 'DD/MM/YY') : '...'}</div>
              <div className="tile is-vertical is-parent has-text-centered">
                <span className="title" style={{ fontSize: '50px' }}>
                </span>
                {isLoading 
                  ? <span className="button is-large is-loading is-link is-bordless"/> 
                  : (<div className="title" style={{ fontSize: '100px' }}>
                      {header.conversionRate || header.salesRate || 0}
                      <span style={{ fontSize: '15px' }}>%</span>
                    </div>)}
              </div>
            </div>
            <div className="tile is-6 is-vertical is-parent has-text-centered">
              <div className="tile" style={{ justifyContent: 'center', alignItems: 'center' }}>
                <div className="has-text-centered">
                  <div className="heading has-text-wieght-bold">
                    Vendas vs Visitantes
                  </div>
                  <div className="title">
                    {isLoading
                      ? <progress style={{ height: '30px' }} className="progress" max="100"></progress>
                      : <progress style={{ height: '30px' }} className="progress" value={header.total_sales || header.totalSales } max={header.visitors || header.visitorsCount || 100}>90%</progress>}
                  </div>
                  <div className="subtitle">
                    {header.total_sales || header.totalSales || 0} de {header.visitors || header.visitorsCount || 0}
                  </div>
                </div>
              </div>
            </div>
          </div>  
        </div>
        <footer className="card-footer">
          <span className="button is-link card-footer-item is-bordless" style={{ borderRight: 'none' }} onClick={this._showMore}>
            <BranchHistoryIcon />
            <span>
              Ver histórico completo
            </span>
          </span>
          <span className="card-footer-item is-bordless" style={{ borderRight: 'none' }}>
            <span>
            </span>
          </span>
        </footer>
      </div>
    </div>
  }
}

export default (SalesRateChart);