import React, { useState, useEffect } from 'react';
import { TrashIcon } from '../../../../components/icons';
import IsLoading from '../../../../components/IsLoading';
import { useCrud } from '../../../../hooks/useCrud';
import { del, get } from '../../../../utils';
// import notification from '../../../../utils/notification';
import AddTrainingModal from './AddTrainingModal';
import IaDatasetModal from './IaDatasetModal';
import IaModelModal from './IaModelModal';
import IaTransformsModal from './IaTransformsModal';
// import { useCrud } from '../../../../hooks/useCrud';

const getStatusLabel = (status) => {
  const r = {
    0: [ 'is-dark', 'Criado'],
    1: [ 'is-success', 'Finalizado'],
    2: [ 'is-link', 'Rodando'],
    3: [ 'is-link is-light', 'Pronto para rodar'],
    9: [ 'is-danger', 'Erro'],
  }

  return r[status];
}


const IaTraining = (props) => {
  const { site } = props;
  const trainings = useCrud();

  const [modalAddTraining, setModalAddTraining] = useState(false);
  const [ iaModelModal, setIaModelModal ] = useState(false);
  const [ iaDatasetModal, setIaDatasetModal ] = useState(false);
  const [ iaTransformsModal, setIaTransformsModal ] = useState(false);

  const removeTrain = (trainId) => {
    trainings.remove(
      trainId,
      () => del(`mlops/training/${trainId}`)
    )
  }

  const addTrain = () => {
    trainings.list(() => get(`mlops/training`, {siteId: site.id}));
  }

  useEffect(() => {
    trainings.list(() => get(`mlops/training`, {siteId: site.id}));
    //eslint-disable-next-line
  }, [site.id]);

  return <div>
    <div className='columns is-centered' style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div className='column is-narrow'>
        <div className='buttons'>
          <button
            className="button is-default"
            onClick={() => setIaDatasetModal(!modalAddTraining)}>
            Datasets
          </button>
          <button
            className="button is-default"
            onClick={() => setIaModelModal(!modalAddTraining)}>
            Modelos
          </button>
          <button
            className="button is-default"
            onClick={() => setIaTransformsModal(!modalAddTraining)}>
            Transforms
          </button>
        </div>
      </div>
      <div className='column is-narrow'>
        <button
          className="button is-primary"
          onClick={() => setModalAddTraining(!modalAddTraining)}>
          Adicionar treinamento
        </button>
      </div>
    </div>
    {iaTransformsModal && <IaTransformsModal
      closeModal={() => setIaTransformsModal(false)}
      site={site} />}
    {iaDatasetModal && <IaDatasetModal
      closeModal={() => setIaDatasetModal(false)}
      site={site} />}
    {iaModelModal && <IaModelModal
      closeModal={() => setIaModelModal(false)}
      site={site} />}
    {modalAddTraining && <AddTrainingModal
      handleAdd={addTrain}
      closeModal={() => setModalAddTraining(false)}
      siteId={site.id} />}
    <table className='table is-fullwidth is-hoverable'>
      <thead>
        <tr>
          <th>ID</th>
          <th>Descrição</th>
          <th>Status</th>
          <th>Data cadastro</th>
          <th>Batchsize</th>
          <th>Epochs</th>
          <th>Seed</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {trainings.data.map(row => {
          const [tag, description] = getStatusLabel(row.status)
          return <tr>
            <td className='is-vcentered'>{row.id}</td>
            <td className='is-vcentered'>{row.description}</td>
            <td className='is-vcentered'>
              <span className={`tag ${tag}`}>{description}</span>
            </td>
            <td className='is-vcentered'>{row.insert_date}</td>
            <td className='is-vcentered'>{row.batchsize}</td>
            <td className='is-vcentered'>{row.epoch}</td>
            <td className='is-vcentered'>{row.seed}</td>
            <td className='is-vcentered'>
              {row.status === 0 && <span
                className={`button is-danger ${trainings.isRemovingRow[row.id] ? 'is-loading':''}`}
                onClick={() => removeTrain(row.id)}>
                <TrashIcon />
              </span>}
            </td>
          </tr>
        })}
      </tbody>
    </table>

    {trainings.isLoadingList && <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}><IsLoading isBorderless={true} fontSize={60} /></div>}
  </div>
};

export default IaTraining;