import React, { Component } from 'react';
import { IdBadgeIcon } from '../../../../../components/icons';
import { format } from 'date-fns';
import {pt} from 'date-fns/locale';
import { post } from '../../../../../utils';

const CAMERA_DEFAULT = { id: 0, description: "Selecione"};


class ModalNewAudit extends Component {

  constructor(props) {
    super(props);
    this.state = {
      camera: CAMERA_DEFAULT,
      description: '',
      cameras: [],
      isSaving: false,
      flowAnalysis: false
    }
  }
  
  _handleCancelButton = () => {
    this.props.toogleModal();
  }

  _checkFlowAnalysis = (e) => {
    const { checked } = e.target;
    this.setState({ flowAnalysis: checked });
  }

  _changeCamera = (e) => {
    const { hour } = this.props;
    const { value } = e.target;
    const camera = hour.cameras.find(cam => cam.id === parseInt(value, 10)) || CAMERA_DEFAULT

    this.setState({ camera });
  }

  _changeDescription = (e) => {
    const { value } = e.target;
    this.setState({ description: value });
  }

  _includeCamera = () => {
    const { camera, cameras } = this.state;
    if (camera.id === 0 || cameras.find(cam => cam.id === camera.id)) {
      return;
    }

    this.setState({
      cameras: [
        camera,
        ...cameras
      ]
    });
  }

  _submitForm = (e) => {
    e.preventDefault();
    const { site, day, hour } = this.props;
    const { cameras, description, flowAnalysis=false } = this.state;
    if (cameras.length === 0) {
      return;
    }

    if(this.state.isSaving){
      return;
    }
    
    this.setState({ isSaving: true }, () => {
      post(`dashboard/video/sites/${site.id}/audit`, {
        description,
        cameras: cameras.map(camera => camera.id),
        day: day.snap_date,
        hour: hour.hour,
        flowAnalysis: flowAnalysis
      })
      .then(resp => {
        this.props.updateAudits(resp.data.data);
      });
    })
  }

  render() {
    const { day, hour } = this.props;
    const { camera, description, cameras, flowAnalysis=false, isSaving } = this.state;

    return <div className="modal is-active">
    <div className="modal-background" onClick={this._handleCancelButton}></div>
    <div className="modal-content is-paddingless " style={{ width: 400 }}>
      <div className="card notification calendarNotDisplay-left-bar">
        <p className="subtitle is-4 has-text-centered">
          <IdBadgeIcon />
          <span>
            Iniciar nova auditoria
          </span>
        </p>
        <p className="subtitle is-5 has-text-left">
          {format(day.snap_date, 'DD MMMM', { locale: pt })} às {hour.hour}
        </p>
        <form onSubmit={this._submitForm}>
          <label className="label" htmlFor="input-cameras-list">Cameras:</label>
          <div className="field has-addons">
            <div className="control is-expanded">
              <div className="select is-fullwidth">
                <select value={camera.id} onChange={this._changeCamera} id="input-cameras-list" name="camera">
                  <option value="0">Selecione...</option>
                  {hour.cameras.map(camera => <option key={camera.id} value={camera.id}>
                    {camera.description}
                  </option>)}
                </select>
              </div>
            </div>
            <div className="control">
              <button type="button" className="button is-primary" onClick={this._includeCamera}>Adicionar</button>
            </div>
          </div>
         
          {cameras.length === 0 && <p className="subtitle is-5">Sem cameras incluídas</p>}
          {cameras.length !== 0 && <table className="table is-striped is-hoverable" style={{ width: '100%' }}>
            <thead>
              <tr>
                <th>ID</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              {cameras.map(camera => <tr key={`camera_id_list_added_${camera.id}`}>
                <td>
                  <div className="audit-table-detail-cel-text">
                    {camera.id}
                  </div>
                  </td>
                <td>
                  <span className="audit-table-detail-cel-number">
                    {camera.description}
                  </span>
                </td>
              </tr>)}
            </tbody>
          </table>}
          <label className="label" htmlFor="input-audit-observation">Observação</label>
          <div className="field">
            <div className="control">
              <input
                id="input-audit-observation"
                className="input"
                type="text"
                placeholder="Ex: contarei só blusas branca"
                value={description}
                onChange={this._changeDescription}
              />
            </div>
          </div>
          <div className="field">
            <div className="control">
              <label className="checkbox">
                <input type="checkbox" checked={flowAnalysis} onChange={this._checkFlowAnalysis}/>
                <span> Auditoria de eventos</span>
              </label>
            </div>
          </div>
          <div className="buttons">
            <button
              type="submit"
              className={`button is-primary ${isSaving ? 'is-loading': ''}`}
            >
              Salvar
            </button>
            <span
              className={`button`}
              onClick={this._handleCancelButton}
            >
              Cancelar
            </span>
            
          </div>
        </form>
      </div>
    </div>
    <button className="modal-close is-large" onClick={this._handleCancelButton} aria-label="close"></button>
  </div>
  }
}

export default ModalNewAudit
