import React, { Component } from 'react';

import { withRouter } from "react-router-dom";

import {format} from 'date-fns';

import { get } from '../../../../../utils';
import CalendarRangePicker from '../../calendar/CalendarRangePicker';

class PerformanceSites extends Component {

  constructor(props) {
    super(props);

    this.state = {
      including: false,
      descriptionField: ''
    };
  }

  _handleCancelButton = () => {
    const { including } = this.state;
    if(!including)
      this.props.toogleModal();
  }

  _handleSelectDate = (params) => {
    this.setState({ including: true }, () => {
      const {group} = this.props
      const { startDate, endDate } = params.range;
      const currentDate = format(endDate, 'YYYY-MM-DD');
      const threeMonthsBefore = format(startDate, 'YYYY-MM-DD');
      get(`dashboard/foottraffic/groups/${group.id}/performance?type=PERSONALIZED&begin=${threeMonthsBefore}&end=${currentDate}`)
        .then(resp => {
          this.setState({ including: false }, () => {
            this.props.includeTable({...resp.data.data, isPersonalized: true });
            this._handleCancelButton()
          });
        });
    })
  }

  _handleChangeFieldDescription = (e) => {
    const { value } = e.target;
    this.setState({ descriptionField: value });
  }
  
  render() {
    const { filterRanges } = this.props;
    const { including } = this.state;

    return (<div className="modal is-active">
      <div className="modal-background" onClick={this._handleCancelButton}></div>
      <CalendarRangePicker
        range={filterRanges}
        close={this._handleCancelButton}
        onChange={this._handleSelectDate}
        loadingButton={including}
      />
      <button className="modal-close is-large" onClick={this._handleCancelButton} aria-label="close"></button>
    </div>)
  }
}

export default withRouter(PerformanceSites);