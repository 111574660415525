import { format, subDays } from 'date-fns';
import React, { Component } from 'react';
import { Bar, BarChart, CartesianGrid, LabelList, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { get, getServerDate } from '../../../../../utils';
import DateFilterOneRange from './DateFilterOneRange';

class AvailableSensorChart extends Component {
    constructor(props) {
      super(props);
      this.state = {hasLoaded: false, filterRanges: props.filterRanges, data: []}
    }
    componentDidMount(){
      this.getAvailableSensors( this.state.filterRanges);
    }
    
    _changeDateFilter = () => (filterRanges) => {     
      this.getAvailableSensors(filterRanges);
    }

    getAvailableSensors = (filterRanges) => {     
      const { shopId } = this.props;
      const begin = format(filterRanges.startDate, 'YYYY-MM-DD');
      const end = format(filterRanges.endDate, 'YYYY-MM-DD');
      
      this.setState({hasLoaded: false} , () => {
        get(`dash/foottraffic/${shopId}/available`, { begin, end })
        .then(resp => this.setState({hasLoaded: true, data: resp.data.data, filterRanges}));  
      });
    }

    formatRate = (rate) => rate + '%';

    render(){
      const { filterRanges, data, hasLoaded } = this.state;
      return (
        <div className="card notification is-white is-paddingless" style={{width: '100%'}}>
          <header className="card-header" style={{ padding: '20px'}}>
              Disponibilidade dos Sensores
          </header>
          <div className="card-content">
            { hasLoaded ?
              <ResponsiveContainer 
                  width="100%" 
                  height={300}>
                <BarChart data={data} 

                  margin={{ top: 15, right: 30, left: 20, bottom: 5 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis domain={[0, 110]} />  />
                  <Tooltip formatter={this.formatRate}/>
                  <Legend />
                  <Bar dataKey="rate" 
                       name="Percentual Online"
                       maxBarSize={15} 
                       fill="#0043ff">
                        <LabelList dataKey="rate" formatter={this.formatRate} position="top" />
                    </Bar>
                </BarChart>
              </ResponsiveContainer> 
              :
              <div className="has-text-centered" style={{ height:400, paddingTop: '100px' }}>
                <span>
                  <span className="button is-loading is-large is-bordless" />
                  <p className="subtitle is-5">Aguarde, carregando...</p>
                </span>
              </div>
            }
            
          </div>
          <footer className="card-footer" style={{ padding: '20px'}}>
            <span className="button has-text-left is-white card-footer-item is-radiusless is-shadowless is-bordless" style={{ borderRight: 'none' }}>
              <DateFilterOneRange maxDate={subDays(getServerDate(), 1)} changeDates={this._changeDateFilter()} actualRanges={{ ranges: filterRanges }}/>
            </span>
            <span className="card-footer-item" style={{ borderRight: 'none' }}>
            </span>
            <span className="card-footer-item" style={{ borderRight: 'none' }}>
            </span>
            <span className="card-footer-item" style={{ borderRight: 'none' }}>
            </span>
          </footer>
        </div>
      )
    }

}
 
export default AvailableSensorChart;