import React, { Component } from 'react';

import {
  addDays,
  format
} from 'date-fns';

import {
  CalendarAltIcon, ExclamationCircleIcon
} from '../../../../../components/icons';

import DatePickerFilter from './DatePickerFilter';

import image692 from '../../../../../img/plantas/loja69/planta2.png';
import image691 from '../../../../../img/plantas/loja69/planta1.png';
import image401 from '../../../../../img/plantas/loja40/planta1.png';
import image402 from '../../../../../img/plantas/loja40/planta2.png';
import image421 from '../../../../../img/plantas/loja42/planta1.png';
import image241 from '../../../../../img/plantas/loja24/planta1.png';
import image211 from '../../../../../img/plantas/loja21/planta1.png';
import image531 from '../../../../../img/plantas/loja53/planta1.png';

import { dateToDDMMYYYY, getServerDate } from '../../../../../utils';

class HeatMapVenues extends Component {

  constructor(props) {
    super(props);

    const CURRDATE = addDays(getServerDate(), -1);

    this.state = {
      date: format(CURRDATE,'YYYY-MM-DD'),
      isLoading: true,
      hasHeatmap: false,
      heatmaps: []
    }
  }

  componentDidMount() {
    this.loaddata();
  }

  _changeDateFilter = () => (date) => {
    this.setState({ date }, this.loaddata);
  }

  loaddata = () => {
    this.setState({ isLoading: true }, () => {
      const { date } = this.state;
      this.props.loadHeatmaps(date)
      .then(result => {
          const { date, hasHeatmap, heatmaps } = result;
          this.setState({ isLoading: false, date, hasHeatmap, heatmaps });
        });
    });
  }

  _renderDatePickerLabel = () => {
    const { date } = this.state;
    return <div style={{ display: 'flex', justifyContent: 'center' }}>
      <span
        className="button is-large has-text-centered is-white card-footer-item is-radiusless is-shadowless is-bordless"
        style={{
          borderRight: 'none',
          maxWidth: '100%',
          justifyContent: 'center'
        }}
      >
        <CalendarAltIcon />
        <span>Heatmap em <b>{dateToDDMMYYYY(date)}</b></span>
      </span>
    </div>
  }

  render() {
    const { siteId } = this.props;

    const {
      isLoading,
      date,
      hasHeatmap=false,
      heatmaps
    } = this.state;

    if(!isLoading && !hasHeatmap) {
      return <div></div>
    }

    return <div style={{ width: '100%' }} className="card">
      <header className="card-header" style={{ borderBottom: '1px solid #eee', padding: '10px', justifyContent: 'space-between' }}> 
        <div className="title is-5 is-marginless">
          Heatmap da loja
        </div>
      </header>
      
      <div className="card-content">
        <div className="tile is-ancestor">
          <div className="tile is-12 is-vertical is-parent">
            <div className="tile is-vertical is-parent has-text-centered">
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                {date 
                  && <DatePickerFilter 
                    renderLabel={this._renderDatePickerLabel}
                    className={'top-to-down-right'}
                    changeDates={this._changeDateFilter()}
                    currDate={date}
                  />}
              </div>
              {isLoading
                ? <div className="has-text-centered" style={{ height:300, paddingTop: '70px' }}>
                    <span>
                      <span className="button is-loading is-large is-bordless" />
                      <p className="subtitle is-5">Aguarde, carregando...</p>
                    </span>
                  </div>
                : <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                  {heatmaps.map(heatmap => {
                    let image = null;
                    if(siteId === 1) {
                      if(heatmap.id === 12) {
                        image = image691;
                      } else if(heatmap.id === 10) {
                        image = image692;
                      }
                    } else if(siteId === 2) {
                      image = image421;
                    } else if(siteId === 3) {
                      image = image531;
                    } else if(siteId === 4) {
                      image = image241;
                    } else if(siteId === 6) {
                      image = image211;
                    } else if(siteId === 5) {
                      if(heatmap.id === 13) {
                        image = image401;
                      } else if(heatmap.id === 15) {
                        image = image402;
                      }
                    }

                    const paths = heatmap.pathPoints.map(paths => {
                      const venue = paths.venue;

                      let path = '';
                      let points = paths.points.slice(0);
                      if (points.length > 0) {
                        path = `M ${points[0].x} ${points[0].y}`;
                        var p1, p2, end;
                        for (var i = 1; i < points.length - 2; i += 2) {
                          p1 = points[i];
                          p2 = points[i + 1];
                          end = points[i + 2];
                          path += ` C ${p1.x} ${p1.y}, ${p2.x} ${p2.y}, ${end.x} ${end.y}`;
                        }
                      }
                      return { venue, path };
                    }).filter(p => p !== '');

                    return <div key={`planta-macro-${heatmap.id}`} style={{ justifyContent: 'center', padding: '30px' }}>
                      <p className="subtitle is-4">{heatmap.description}</p>
                      <svg
                        style={{ border: '1px solid black' }}
                        width={800}
                        height={600}
                        >
                        <defs>
                          <radialGradient id="RadialGradient1">
                            <stop offset="0%" stopColor="red" stopOpacity={1}/>
                            <stop offset="25%" stopColor="red" stopOpacity={1}/>
                            <stop offset="85%" stopColor="red" stopOpacity={0}/>
                            <stop offset="100%" stopColor="red" stopOpacity={0}/>
                          </radialGradient>
                      </defs>
                        <image x={0} y={0} xlinkHref={image} width={800} height={600} />
                          {
                            paths.map(({venue, path}) => {
                              return (<path
                                key={`path-${path}`}
                                d={path}
                                fillOpacity={venue.opacity}
                                fill="url(#RadialGradient1)"
                              />);
                            })
                          }
                      </svg>
                      <br/>
                      {!heatmap.auditingOk && <p className="tag is-danger">
                        <ExclamationCircleIcon />
                        <span>{heatmap.info_date.split(' ')[0]} dados não confiáveis.</span>
                      </p>}
                    </div>
                  })}
                </div>}
            </div>
          </div> 
        </div>
      </div>
      <footer className="card-footer">
        <span className="card-footer-item" style={{ borderRight: 'none' }}>
        </span>
        <span className="card-footer-item" style={{ borderRight: 'none' }}>
        </span>
        <span className="card-footer-item" style={{ borderRight: 'none' }}>
        </span>
      </footer>
    </div>
  }
}

export default (HeatMapVenues);