import React, { Component } from 'react';
import Columns, { Column } from '../../../../../components/Columns';
import { db } from '../../../../../firebase';
import { Link, withRouter} from "react-router-dom";

import {format, differenceInMinutes} from 'date-fns';
import {connect} from 'react-redux';
import { CheckIcon, CircleIcon, DoorClosedIcon, DoorOpenIcon, ExclamationTriangleIcon } from '../../../../../components/icons';
import { getServerDate, put } from '../../../../../utils';

import '../../../../../styles/components/SensorCard.scss';

class SensorCard extends Component {
  constructor(props){
    super(props);

    const { lastData } = props.venue;
    
    const last = {...lastData, collectTime: format(lastData.collectTime, 'X')}
    this.state = {
      serverDate: getServerDate(),
      isloading: false,
      realtimeValues: last
    }
  }

  componentDidMount(){
    this._loadRealTime();
  }

  componentWillUnmount(){
    const { venue, siteId } = this.props;
    db.ref(`/johann/${siteId}/sensors/${venue.sensorid}/realtime/values`).off('value')
  }

  _loadRealTime() {
    const { venue, siteId } = this.props;
    db.ref(`/johann/${siteId}/sensors/${venue.sensorid}/realtime/values`).on('value', this._realtimeTemperatureAndHumidity)
  }

  _realtimeTemperatureAndHumidity = async (data) => {
    if(data === null || !data.exists())
      return;

    const serverDate = getServerDate();
    // this.setState({ realtimeValues: data.toJSON(), isloading: false, serverDate });
    this.props.onChangeRealtimeValue(this.props.areaId, this.props.venue.id, serverDate, data.toJSON());
  }

  _handleInactive = (venue) => {
    const { siteId, areaId } = this.props;
    
    put(`admin/johann/sites/${siteId}/venues/${venue.id}/inactive`, {
      isInactive: !venue.isInactive
    }).then(res => {venue.makeInactive(areaId, venue.id, !venue.isInactive)})
  };

  render() {
    let diff = 0
    const { venue, siteId } = this.props;
    const { realtimeValues={}, serverDate } = venue;

    const getTemperature = () => {
      if (!realtimeValues)
        return 0;

      let temperature = realtimeValues.temperature;
  
      if(venue.multi_thermo_part){
        temperature = (realtimeValues.temperature + realtimeValues.temperature_2) / 2
      };

      return temperature;
    }

    const temperature = getTemperature();
    
    const hasWarn = realtimeValues.temperature && (
      temperature > venue.maxTemp ||
      temperature < venue.minTemp
    );

    if(realtimeValues.collectTime){
      diff = differenceInMinutes(serverDate, new Date(realtimeValues.collectTime *1000))
    }

    const isOffline = diff > 30;

    return (<div className="notification is-white sensor-card-smile" style={{ width: '100%'}}>
        <p className={`tag ${isOffline || venue.isInactive ? 'is-default' : hasWarn ? 'is-danger' : 'is-white'} sensor-card-title-content has-text-left`}>
          {`${venue.placa} #${venue.name}`}
        </p>
        {isOffline && <p style={{ marginBottom: 0}} className={"help is-danger sensorcardHora"}>
          <CircleIcon /> OFFLINE <b>{format(realtimeValues.collectTime * 1000, 'DD/MM HH:mm:ss')}</b>
        </p>}
        {venue.isInactive && <p style={{ marginBottom: 0}} className={"help is-danger sensorcardHora"}>
          <CircleIcon /> INATIVO </p>}
        {!isOffline && !venue.isInactive && <p style={{ marginBottom: 0}} className={`help sensorcardHora ${hasWarn ? "" : "is-success"}`}>
          Atualizado às <b className='sensorcardHora'>{format(realtimeValues.collectTime * 1000, 'DD/MM HH:mm:ss')}</b>
        </p>}

        <Columns style={{ marginTop: 5, marginBottom: 5 }} isMultiline isGapless>
          <Column isSize={4}>
            <p style={{ width: '100%', marginBottom: 0 }} className={`sensorcardTemperatura-title heading ${isOffline || venue.isInactive ? '' : hasWarn ? "has-text-danger" : ""}`}>
              {venue.multi_thermo_part ? 'Média' : 'Temperatura'}
            </p>
            <p className={`sensorcardTemperatura-value tag ${isOffline || venue.isInactive? 'is-default' : hasWarn ? 'is-danger': 'is-success'} has-text-weight-bold`}>
              {Number(Math.round(temperature  * 100) / 100).toFixed(1)}˚C
            </p>
          </Column>
          {venue.multi_thermo_part && <Column isSize={4}>
            <p style={{ width: '100%', marginBottom: 0 }} className={`sensorcardTemperatura-title heading ${isOffline || venue.isInactive ? '' :hasWarn ? "has-text-danger" : ""}`}>Temp.1</p>
            <p className={`sensorcardTemperatura-value tag ${isOffline || venue.isInactive ? 'is-default' : hasWarn ? 'is-danger': 'is-success'} has-text-weight-bold`}>
              {Number(Math.round(realtimeValues.temperature  * 100) / 100).toFixed(1)}˚C
            </p>
          </Column>}
          {venue.multi_thermo_part && <Column isSize={4}>
            <p style={{ width: '100%', marginBottom: 0 }} className={`sensorcardTemperatura-title heading ${isOffline || venue.isInactive ? '' :hasWarn ? "has-text-danger" : ""}`}>Temp.2</p>
            <p className={`sensorcardTemperatura-value tag ${isOffline || venue.isInactive ? 'is-default' : hasWarn ? 'is-danger': 'is-success'} has-text-weight-bold`}>
              {Number(Math.round(realtimeValues.temperature_2  * 100) / 100).toFixed(1)}˚C
            </p>
          </Column>}
          <Column isSize={12}>
            <p style={{ paddingBottom: 5, paddingTop: 5 }} className={`help ${isOffline || venue.isInactive ? '': hasWarn ? 'is-danger' : ''}`}>
              {hasWarn ? <ExclamationTriangleIcon style={{fontSize: 10, color: isOffline || venue.isInactive ? '#ccc' : undefined}}/> : <CheckIcon  style={{fontSize: 10, color: isOffline || venue.isInactive ? '#ccc' : undefined}}/>} MIN: {venue.minTemp}˚C   MAX: {venue.maxTemp}˚C
            </p>
          </Column>
        </Columns>

        <Columns isGapless isMultiline>
          <Column isSize={6} style={{ minHeight: 45}}>
            {venue.hasHumi && <p style={{ width: '100%', marginBottom: 0 }} className={`sensorcardTemperatura-title heading ${isOffline || venue.isInactive ? '' :hasWarn ? "has-text-danger" : ""}`}>
              Nível Umidade
            </p>}
            {venue.hasHumi && <p className={`sensorcardTemperatura-value tag ${isOffline || venue.isInactive ? 'is-default' : hasWarn ? 'is-danger': 'is-success'} is-light has-text-weight-bold`}>
              <b>{realtimeValues.humidity}%</b>
            </p>}
          </Column>
          <Column isSize={6} style={{ minHeight: 25}}>
            {venue.ldrval > 0 && <p style={{ width: '100%', marginBottom: 0 }} className={`sensorcardTemperatura-title heading ${isOffline || venue.isInactive ? '' : hasWarn ? "has-text-danger" : ""}`}>
              Situação Porta
            </p>}
            {venue.ldrval > 0 && 
            <p title={`Situação porta: ${realtimeValues.open_lvl}`} className={`sensorcardTemperatura-value tag ${isOffline || venue.isInactive ? 'is-default' : hasWarn ? 'is-danger': 'is-success'} is-light has-text-weight-bold`}>
              {realtimeValues.open_lvl >= venue.ldrval ? <span><span><b>{siteId === 16 ? parseInt(realtimeValues.open_lvl) : 'Aberta'}</b></span> <DoorOpenIcon /> </span> : <span><span><b>{siteId === 16 ? parseInt(realtimeValues.open_lvl) : "FECHADA" }</b></span> <DoorClosedIcon /></span>}
            </p>}
          </Column>
        </Columns>
        
      
        <Link style={{ width: '100%' }} to={`/board/sites/${siteId}/dashboard/johann/venue/${venue.id}`} className="has-text-link">
          <p className="help heading has-text-centered"><b>Detalhes</b></p>
        </Link>

        {<div className="field" style={{marginTop: 10}}>
          <input id={`venue_key_${venue.id}`} type="checkbox" className="switch is-rounded" checked={venue.isInactive} onChange={() => this._handleInactive(venue)} />
          <label for={`venue_key_${venue.id}`} ClassName="label" >Inativar Sensor</label>
        </div>}

      </div>
    )
  }
}

function mapStateToProps(state, props) {
  const site = state.sites.all[props.match.params.siteId] || {};
  return { site }
}

export default withRouter(connect(mapStateToProps)(SensorCard));
