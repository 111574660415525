import React, { useEffect, useState } from 'react'; 

import Columns, { Column } from '../../../../components/Columns';
import { BugIcon, CaretDownIcon, CaretRightIcon, CircleIcon, SalesIcon } from '../../../../components/icons';
import Page, { PageTitle } from '../../../../components/Page';
import IsLoading from '../../../../components/IsLoading';
import AnomalySiteHistPage from './AnomalySiteHistPage';
import { get, getServerDate } from '../../../../utils';
import Card from '../../../../components/Card';
import { format } from 'date-fns';

import _ from 'lodash';


const SiteView = ({site}) => {
  const [ showMore, setShowMore ] = useState(false);
  const { salesRate, name, anomalyHist=[] } = site;

  const lastDay = _.last(anomalyHist);
  const lastDayColor = getAnomalyStatusColor(lastDay.anomaly).color;

  return <div style={{ marginBottom: 30 }}>
    <p className="subtitle is-4" style={{ marginBottom: 5 }}>
      <CircleIcon color={lastDayColor} /> {name} 
    </p>
    <p className="help">status dia de ontem</p>
    <p><SalesIcon /> <span style={{fontSize: 30}}>{salesRate}%</span></p>
    <p className="has-text-link heading">
      <span className="cursor-pointer" onClick={() => setShowMore(!showMore)}>
        {showMore ? 'Esconder' : "ver mais"}
        {showMore ? <CaretRightIcon /> : <CaretDownIcon />}
      </span>
    </p>
    {showMore && <AnomalySiteHistPage site={site} />}
  </div>
}


const useDashVisitorsAnomalyHist = (groupId, begin, end) => {
  const [ isLoading, setIsLoading ] = useState(true);
  const [ sites, setSites ] = useState([]);

  useEffect(() => {
    get(`dashboard/footanomaly/groups/${groupId}/daily`, { begin, end })
      .then(resp => {
        setSites(resp.data.data);
        setIsLoading(false);
      });
  }, [ groupId, begin, end ]);

  return {
    isLoading: isLoading,
    sites: sites
  }
}


const getAnomalyStatusColor = (anomalyStatus) => {
  const color = anomalyStatus === "is_ok" 
  ? "#72CB46" : 
  anomalyStatus === "anomaly"
    ? "#E92641" 
    : anomalyStatus === "human_should_review"
      ? "#F5CF07" : "#EEEEEE";

  const status = anomalyStatus === "is_ok" 
  ? "Dentro da normalidade." : 
  anomalyStatus === "anomaly"
    ? "Fora da normalidade." 
    : anomalyStatus === "human_should_review"
      ? "Não foi possível definir pela inteligencia." : "Detector não estava ativo.";

  return {color, status};
}


const AnomalyHistPage = ({ group }) => {
  const anomalyHist = useDashVisitorsAnomalyHist(
    group.id,
    format(getServerDate(5), 'YYYY-MM-DD'),
    format(getServerDate(1), 'YYYY-MM-DD'));

  return <Page>
    <PageTitle
      title={<span>
        <span style={{ marginRight: '10px' }}>
          {`${(group.name) || ''} `}
        </span>
        <p className="subtitle">Histórico de anomalias <BugIcon /></p>
      </span>}
    />

    <Card>
      <Columns isMultiline>
        <Column isSize={12}>
          <p className="subtitle is-4 has-text-centered">Status anomalia lojas</p>
          {anomalyHist.isLoading && <div className="has-text-centered">
            <IsLoading fontSize={60} isBorderless={true} />
          </div>}
          {!anomalyHist.isLoading && anomalyHist.sites.map(site => <SiteView site={site}/>)}
        </Column>
      </Columns>
    </Card>
  </Page>
}

export default AnomalyHistPage;