import React, { useState } from 'react';
import { post } from '../../../utils';

const AddSite = (props) => {
  const { selectedGroup } = props;
  const { modalAddClickedSite } = props;
  const { loadSites } = props;
  const [ siteName, setName ] = useState("");
  const [ siteNickName, setSiteNickName ] = useState("");
  const [ foottraffic, setFoottraffic ] = useState();
  const [ johann, setJohann ] = useState();
  const [ loyalty, setLoyalty ] = useState();
  const [ queues, setQueues ] = useState();
  const [ sales, setSales ] = useState();
  const [ shelf, setShelf ] = useState();
  const [ showcases, setShowcases ] = useState();
  const [ venues, setVenues] = useState();
  const [ video, setVideo] = useState();
  const [ isSuvinil, setIsSuvinil] = useState();
  const [ isAmbev, setIsAmbev] = useState();
  const [ buttonloading, setButtonLoading] = useState(false);

  const cancelAddSite = () => {
    modalAddClickedSite(null);
  };

  const addSiteSave = () => {

    if(buttonloading){
      return;
    }
    
    setButtonLoading(true);

    if(selectedGroup.id){
      post(`admin/tooq/sites/`,
      { 
        name: siteName,
        nickName: siteNickName,
        groupId: selectedGroup.id,
        modules: {
          foottraffic: foottraffic,
          johann: johann,
          video: video,
          sales: sales,
          queues: queues,
          showcases: showcases,
          facebook: false,
          instagram: false,
          shelf: shelf,
          loyalty: loyalty,
          venues: venues,
          isSuvinil: isSuvinil,
          isAmbev: isAmbev
        }
      })
      .then(() => modalAddClickedSite(null))
      .then(() => setButtonLoading(false))
      .then(() => loadSites())

    }else {
      return alert('Precisa selecionar uma loja!')
    }
  };

  return <div className="card" style={{width: '100%'}}>
    <div className="card-header">
      <p className="card-header-title">Criando Loja</p>
    </div>
    <div className="card-content">
      <div className="content">
        <div className="field">         
          <label className="label">Nome da loja:</label>
          <div className="control">
            <input 
              className="input is-primary" 
              type="text" 
              value={siteName} 
              onChange={(e) => setName(e.target.value)} 
            />
          </div>
        </div>
        <div className="field">
          <label className="label">NickName da loja</label>
          <div className="control">
            <input 
              className="input is-primary" 
              type="text" 
              value={siteNickName} 
              onChange={(e) => setSiteNickName(e.target.value)} 
            />
          </div>            
        </div>
        <div>
          <label className="checkbox">
            <input type="checkbox" onChange={(e) => setFoottraffic(e.target.checked)} checked={foottraffic}/>
              foottraffic
          </label>
        </div>
        <div>
          <label className="checkbox">
            <input type="checkbox" onChange={(e) => setJohann(e.target.checked)} checked={johann}/>
              johann
          </label>
        </div>
        <div>
          <label className="checkbox">
            <input type="checkbox" onChange={(e) => setLoyalty(e.target.checked)} checked={loyalty}/>
              loyalty
          </label>
        </div>
        <div>
          <label className="checkbox">
            <input type="checkbox" onChange={(e) => setQueues(e.target.checked)} checked={queues}/>
              queues
          </label>
        </div>
        <div>
          <label className="checkbox">
            <input type="checkbox" onChange={(e) => setSales(e.target.checked)} checked={sales}/>
              sales
          </label>
        </div>
        <div>
          <label className="checkbox">
            <input type="checkbox" onChange={(e) => setShelf(e.target.checked)} checked={shelf}/>
              shelf
          </label>
        </div>
        <div>
          <label className="checkbox">
            <input type="checkbox" onChange={(e) => setShowcases(e.target.checked)} checked={showcases}/>
              showcases
          </label>
        </div>
        <div>
          <label className="checkbox">
            <input type="checkbox" onChange={(e) => setVenues(e.target.checked)} checked={venues}/>
              venues
          </label>
        </div>
        <div>
          <label className="checkbox">
            <input type="checkbox" onChange={(e) => setVideo(e.target.checked)} checked={video}/>
              video
          </label>
        </div>
        <div>
          <label className="checkbox">
            <input type="checkbox" onChange={(e) => setIsSuvinil(e.target.checked)} checked={isSuvinil}/>
              isSuvinil
          </label>
        </div>
        <div>
          <label className="checkbox">
            <input type="checkbox" onChange={(e) => setIsAmbev(e.target.checked)} checked={isAmbev}/>
              isAmbev
          </label>
          <div>
            <div className="buttons" style={{ marginTop: 15 }}>
              <button 
                onClick={() => addSiteSave()} 
                className={`button is-primary ${buttonloading ? 'is-loading' : ''}`} >
                Salvar
              </button>
              <button 
                onClick={() => cancelAddSite()} 
                className="button is-default">
                cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
};

export default AddSite;