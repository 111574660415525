
import React, { useEffect } from 'react';
import Card from '../../../../components/Card';
import {IsLoadingCentered} from '../../../../components/IsLoading';
import Modal from '../../../../components/Modal';
import { useCrud } from '../../../../hooks/useCrud';
import { useField } from '../../../../hooks/useField';
import { get, post } from '../../../../utils';
import notification from '../../../../utils/notification';


const IaModelModal = props => {
  const models = useCrud();

  const nameField = useField();

  const newModel = (e) => {
    e.preventDefault();

    return models.add(
      () => post(`mlops/ia-model`, {
        name: nameField.value
      })
    )
    .then(() => {
      nameField.setValue('');
    })
    .catch(err => {
      notification.error('Erro ao salvar o modelo');
    })
  }

  useEffect(() => {
    models.list(
      () => get(`mlops/ia-model`)
    )

    //eslint-disable-next-line
  }, []);

  return <Modal
      handleToogleModal={props.closeModal}
      style={{ width: "35%", height: '50%' }}
      component={<Card style={{ height: '95%', overflow: 'auto' }}>
      <p className="title is-4">IA Models</p>
      <form className="field is-grouped" onSubmit={newModel}>
        <div className="control is-expanded">
          <input className='input' {...nameField} placeholder="Descrição modelo"  required/>
        </div>
        <div className="control">
          <button type='submit' className={`button is-primary ${models.isAddingRow ? 'is-loading': ''}`}>
            Salvar
          </button>
        </div>
      </form>

      <table className='table is-fullwidth'>
        <thead>
          <tr>
            <th>ID</th>
            <th>Descrição</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {models.data.map(row => <tr>
            <td>{row.id}</td>
            <td>{row.name}</td>
            <td></td>
          </tr>)}
        </tbody>
      </table>
      {models.isLoadingList && <IsLoadingCentered fontSize={40} isBorderless={true} />}
    </Card>}
  />
}

export default IaModelModal;