// import { format, subDays } from 'date-fns';
import React from 'react';
import Columns, { Column } from '../../../../components/Columns';
import { StoreIcon } from '../../../../components/icons';
import Page, { PageTitle } from '../../../../components/Page';
// import { get } from '../../../../utils';
// import { ReactComponent as BottleSvg } from '../../../../img/bottles/bottle.svg';
import SkusNielsen from './SkusNielsen';


// const SHARE_FILL = (type) => type === "FUTUREBEV_AMBEV" ? '#008615ff' : 
//   type === "RUPTURA_FUTUREBEV_AMBEV" ? '#00861580' :
//   type === "FUTUREBEV_CONCORRENTE" ? '#d58945ff' : 
//   type === 'NOT_FOUND' ? '#CCCCCC' : '#d5894580'


// const searchHours = (siteId, snapDate, setLoadingHours, setHours, firstLoad) => {
//   setLoadingHours(true);
//   get(`dashboard/sharegondola/${siteId}/hours-with-data`, {
//     snapDate: snapDate
//   })
//   .then(resp => {
//     setHours(resp.data.data, firstLoad);
//     setLoadingHours(false);
//   });
// }

// const searchHoursDebounce = _.debounce(searchHours, 600);


// const ShelfShare = ({ columnId, columnsCount, share }) => {
//   const shareWithColumnSize = share
//     .map(share => {
//       //100 da gondola / 12 colunas = 8.33 entao 1 share deve ocupar no minimo 8.33
//       const columnPercentEquivalent = 8.33;
//       const columnSize = parseInt(share.share / columnPercentEquivalent);
//       return {
//         ...share,
//         columnSize: columnSize === 0 ? 1 : columnSize
//       };
//     })

//   let sumOfColumns = _.sum(shareWithColumnSize.map(s => s.columnSize));
//   const columnsGondolas = shareWithColumnSize
//   .map(share => {
//       if(sumOfColumns === 12)
//         return share
      
//       if(sumOfColumns < 12) {
//         const missingColumns = (12 - sumOfColumns)
//         share.columnSize += missingColumns
//         sumOfColumns += missingColumns
//         return share
//       }

//       const extraColumns = (sumOfColumns - 12)
//       if(share.columnSize === 1) {
//         share.columnSize -= 1;
//         sumOfColumns -= 1;
//         return share;
//       }

//       share.columnSize -= extraColumns
//       sumOfColumns -= extraColumns
//       return share
//     })
//     .filter(share => share.columnSize > 0)
    
//   return <Column isSize={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', borderBottom: `6px solid #cccccc` }}>
//     <Columns isGapless style={{ width: '100%' }}>
//       {columnsGondolas
//       .map(share => <Column isSize={share.columnSize} style={{
//         display: "flex",
//         flexDirection: 'row',
//         // border: '1px solid #222'
//       }}>
//         {_.range(0, share.columnSize).map(i => <div style={{
//           display: "flex",
//           flexDirection: 'column',
//           width: "100%",
//           height: "100%"
//         }}>
//           <BottleSvg width={"100%"} height={'50%'} viewBox="9 0 16 32" fill={SHARE_FILL(share.shareType)} />
//           <BottleSvg width={"100%"} height={'50%'} viewBox="9 0 16 32" fill={SHARE_FILL(share.shareType)} />
//         </div>) }
//       </Column>)}
//     </Columns>
//   </Column>
// }


// const GondolaColumn = ({columnId, columnsCount, venues}) => <Column isSize={2}>
//   {/* Sou uma coluna de bebidas e tenho {venues.length} prateleiras */}
//   <Columns isMultiline isGapless>
//     {venues.map(venue => <ShelfShare columnId={columnId} columnsCount={columnsCount} share={venue.share} />)}
//   </Columns>
// </Column>


const ShareGondolaDashBoard = (props) => {
  const { site } = props;

  // const [ hours, setHoursList ] = useState([]);
  // const [ loadingHours, setLoadingHours ] = useState(true);
  // const [ selectedHour, setSelectedHour ] = useState(null);

  // const [ columnsToDisplay, setColumnsToDisplay ] = useState([]);
  // const [ futureBevsKPI, setFutureBevsKPI ] = useState([]);

  // const getKpiByKey = (key) => {
  //   const kpi = futureBevsKPI.find(f => f.key === key)
  //   if(kpi)
  //     return kpi.indicator;

  //   return "0.00";
  // }

  // const [ loadingDashboard, setLoadingDashboard ] = useState(true);
  // const [ snapDate, setSnapDate ] =  useState(format(subDays(new Date(), 1), 'YYYY-MM-DD')); //  useState("2022-02-09") //  

  // const [ searchedDate, setSearchedDate ] = useState(snapDate);


  // const searchHourData = (siteId, snapDate, hour) => {
  //   setLoadingDashboard(true);
  //   get(`dashboard/sharegondola/${siteId}/shelfs`, { snapDate, hour })
  //     .then(resp => {
  //       setFutureBevsKPI(resp.data.kpiFutureBevs);
  //       setColumnsToDisplay(resp.data.data);
  //       setSearchedDate(snapDate);
  //       setLoadingDashboard(false);
  //     })
  // }

  // const onClickSearchHourData = (siteId, snapDate, hour) => {
  //   if(loadingDashboard)
  //     return;

  //   searchHourData(siteId, snapDate, hour)
  // }

  // const setHours = (hours, firstLoad) => {
  //   setHoursList(hours);

  //   if(hours.length === 0) {
  //     setSelectedHour(null)
  //     setLoadingDashboard(false);
  //     return;
  //   }

  //   setSelectedHour(hours[0])
  //   if (firstLoad) {
  //     searchHourData(site.id, snapDate, hours[0])
  //   }
  // }

  
  // const changeSnapDate = (e) => {
  //   const { value } = e.target;
  //   setSnapDate(value);
  //   searchHoursDebounce(site.id, value, setLoadingHours, setHours)
  // }

  // useEffect(() => {
  //   searchHours(site.id, snapDate, setLoadingHours, setHours, true)
  //   // eslint-disable-next-line
  // }, []);

  // const noData = !loadingHours && hours.length === 0;
  return <Page>
    <PageTitle
      title={<span>
        <span style={{ marginRight: '10px' }}>
          {`${(site && site.name) || ''} `}
        </span>
        <p className="subtitle">Share de Gondola <StoreIcon /></p>
      </span>}
    />

    {/* <Columns className="is-justify-content-right">
      <Column isSize={'is-narrow'}>
        <div className="field">
          <label className="label">DIA</label>
          <div className="control">
            <input
              className="input"
              type="date"
              value={snapDate}
              onChange={changeSnapDate}
            />
          </div>
          <p className="help">Selecione um dia e hora e depois clique em buscar.</p>
        </div>
      </Column>
      <Column isSize={'is-narrow'}>
        <div className="field">
          <label className="label">HORA</label>
          <div className="control">
            <div className="select">
              <select onChange={(e) => setSelectedHour(e.target.value)} value={selectedHour} style={{ width: 100, marginLeft: 5}} disabled={noData}>
                {noData && <option value={null}>Não há horários</option>}
                {!loadingHours && hours.length !== 0 && <option value={null}>Selecione um horário</option>}
                {loadingHours && <option value={null}>...</option>}
                {hours.map(hour => <option value={hour}>{hour}</option>)}
              </select>
            </div>
          </div>
        </div>
      </Column>
      <Column isSize={'is-narrow'}>
        <button
          style={{ marginTop: '2rem' }}
          className={`button is-primary ${loadingDashboard ? 'is-loading' : ''}`}
          disabled={noData || !selectedHour}
          onClick={() => onClickSearchHourData(site.id, snapDate, selectedHour)}
        >
          <SearchIcon /> <span style={{marginLeft: 2}}>Buscar</span>
        </button>
      </Column>
    </Columns> */}

    {/* <Columns>
      {loadingDashboard && <Column isSize={12}>
        <div className="card notification has-text-centered">
          <IsLoading fontSize={60} isBorderless={true} />
          <p className="subtitle is-4 has-text-centered">Aguarde carregando...</p>
        </div>
      </Column>}

      {!loadingDashboard && columnsToDisplay.length === 0 && <Column isSize={12}>
        <div className="card notification">
          <p className='has-text-centered' style={{ fontSize: 120 }}>
            <ShopSlashIcon />
          </p>
          <p className='subtitle is-5 has-text-centered'>
            Não há dados para o dia <b>{format(searchedDate, 'DD/MM/YY')}</b>, <br/> tente outra busca.
          </p>
        </div>
      </Column>}
      {!loadingDashboard && <Column isSize={12}>
        <div className="card notification">
          <p className="subtitle is-4 has-text-centered">Indicadores dia <b>{format(searchedDate, 'DD/MM/YY')}</b></p>
          <p className="help">A representação visual das prateleiras é uma aproximação da realidade. Proporções e quantidade de produtos são meramente ilustrativos.</p>
          <Columns style={{ overflow: 'auto' }}>
            {columnsToDisplay.map(column => <GondolaColumn columnId={column.column} columnsCount={columnsToDisplay.length} venues={column.venues}/>)}
          </Columns>
        </div>
      </Column>}
    </Columns> */}
    <Columns>
      {/* <Column isSize={3} >
        <div className="card" style={{marginTop: 20}}>
          <div className="card-content">
              <p className="card-header-title has-text-centered" style={{display: 'inherit'}}>
                FUTURE BEV'S X CONCORRENTES
              </p>
            <div style={{width: '100%', display: 'flex'}}> 
              <div style={{width: '50%', display: 'flex', flexFlow: 'column', alignItems: 'end', padding: '0 10px'}}> 
                <div className="has-text-centered has-text-weight-bold"> 
                  <div className="has-text-white is-size-4" style={{width: 75, height: 75, backgroundColor: '#008615ff', display: 'flex', justifyContent: 'center', alignItems: 'center'}}> 
                    {loadingDashboard ? <IsLoading isBorderless={true} fontSize={10} backgroundColor={'#008615ff'} />: getKpiByKey("FUTUREBEV_AMBEV")}% 
                  </div>
                  <p> (AMBEV) </p>
                </div>
                <div className="has-text-centered has-text-weight-bold" style={{marginTop: 10}}> 
                  <div className="is-size-4" style={{width: 75, height: 75, backgroundColor: '#00861580', display: 'flex', justifyContent: 'center', alignItems: 'center'}}> 
                    {loadingDashboard ? <IsLoading isBorderless={true} fontSize={10} backgroundColor={'#00861580'} />: getKpiByKey("RUPTURA_FUTUREBEV_AMBEV")}%
                  </div>
                  <p> RUPTURA </p>
                </div>
              </div>

              <div style={{width: '50%', display: 'flex', flexFlow: 'column', alignItems: 'baseline', padding: '0 10px'}}> 
                <div className="has-text-centered has-text-weight-bold"> 
                  <div className="has-text-white is-size-4" style={{width: 75, height: 75, backgroundColor: '#d58945ff', display: 'flex', justifyContent: 'center', alignItems: 'center'}}> 
                    {loadingDashboard ? <IsLoading isBorderless={true} fontSize={10} backgroundColor={'#d58945ff'} />: getKpiByKey("FUTUREBEV_CONCORRENTE")}%
                  </div>
                  <p> (CONC) </p>
                </div>

                <div className="has-text-centered has-text-weight-bold" style={{marginTop: 10}}> 
                  <div className="is-size-4" style={{width: 75, height: 75, backgroundColor: '#d5894580', display: 'flex', justifyContent: 'center', alignItems: 'center'}}> 
                    {loadingDashboard ? <IsLoading isBorderless={true} fontSize={10} backgroundColor={'#d5894580'} />: getKpiByKey("RUPTURA_FUTUREBEV_CONCORRENTE")}%
                  </div>
                  <p> RUPTURA </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Column> */}
      <Column isSize={12}>
        <SkusNielsen siteId={site.id} />
      </Column>
    </Columns>
  </Page>
}

export default ShareGondolaDashBoard;
