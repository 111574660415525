import React, { Component } from 'react';
import { post, get, del } from '../../../../utils';

import * as Icons from '../../../../components/icons';
import Columns, { Column } from '../../../../components/Columns';
import Modal from '../../../../components/Modal';
import notification from '../../../../utils/notification';

class JohannMoreOptions extends Component {

  constructor(props) {
    super(props);
    this.state = {
      allJohannSites: [],
      isDeleting: false,
      loading: false,
      selectedMenu: 'email',
      siteToMove: 0,
      forms: {
        email: '',
        phone: '',
        intervalTimeNotify: '',
        maxOutLimitNotify: ''
      },
      emails: [],
      phones: [],
      telegrams:[],
      savingPhone: false,
      savingEmail: false,
      savingNotifications: false,
      savingTimes: false,
      originalTimes: {
        intervalTimeNotify: '',
        maxOutLimitNotify:''
      },
      originalNotifications: {
        whatsapp: false,
        email: false,
        telegram: false
      },
      notifications: {
        whatsapp: false,
        email: false,
        telegram: false
      }
    }
  }

  componentDidMount() {
    this.setState({ loading: true }, () => {
      const { venue, siteId } = this.props;
      get(`admin/johann/sites/${siteId}/venues/${venue.id}/notifications`)
        .then(resp => resp.data.data)
        .then(notificationSettings => {
          const { types, times, emails, phones, telegrams } = notificationSettings;
          const forms = {...this.state.forms};

          forms['intervalTimeNotify'] = times.intervalTimeNotify;
          forms['maxOutLimitNotify']= times.maxOutLimitNotify

          this.setState({
            forms,
            loading: false,
            originalTimes: { ...times },
            originalNotifications: {...types},
            phones, emails, telegrams,
            notifications: {...types}
          });
        })
        .catch(err => {
          console.log(err);
          this.setState({ loading: false });
        })
    });

    this._listAllSitesJohann()
  }

  _listAllSitesJohann = () => {
    get(`admin/johann/sites`)
      .then(resp => resp.data.data)
      .then(sites => this.setState({ allJohannSites: sites }))
      .catch(err => console.log(err))
  }

  _onChangeChecked = (e) => {
    const { notifications } = this.state;
    const { checked, name } = e.target;
    notifications[name] = checked;
    this.setState({ notifications });
  }

  _onChangeValue = (e) => {
    const { forms } = this.state;
    const { value, name, type } = e.target;

    forms[name] = value;

    if(type === 'number')
      forms[name] = parseInt(forms[name], 10);

    if(name === 'phone') {
      forms[name] = value.replace(/[^0-9]/g, '');
    }

    this.setState({ forms });
  }

  _deleteVenue = (venueid) => () => {
    if(this.state.isDeleting)
      return;

    this.setState({ isDeleting: true }, () => {
      const { siteId } = this.props;

      del(`admin/johann/sites/${siteId}/venues/${venueid}`)
        .then(resp => {
          notification.success('Removido com sucesso!')
          this.props.handleRemoveVenue(venueid)
        })
        .catch(err => this.setState({ isDeleting: false }));
    });
  }

  _saveNotifications = () => { 
    const { savingNotifications, notifications } = this.state;
    const { whatsapp, email, telegram } = notifications;

    if(savingNotifications)
      return;

    this.setState({ savingNotifications: true }, () => {
      const { siteId, venue } = this.props;
      post(
        `admin/johann/sites/${siteId}/venues/${venue.id}/notifications`, {
        types:{
          whatsapp,
          email,
          telegram
        }
      })
      .then(resp => resp.data.data)
      .then(data => {
        notification.success('Dados salvos com sucesso!')
        const { types } = data;
        this.setState({
          savingNotifications: false,
          originalNotifications: {...types},
          notifications: {...types}
        });
      })
      .catch(err => 
        this.setState({savingNotifications: false}))
    });
  }

  _removeEmail = (id) => () => {
    const { siteId, venue } = this.props;
    del(`admin/johann/sites/${siteId}/venues/${venue.id}/notifications/emails/${id}`)
    .then(resp => {
      notification.success('Removido com sucesso!')
      const { emails } = this.state;
      const newEmailList = emails.filter(email => email.id !== id)
      this.setState({ emails: newEmailList });
    })
    .catch(err => console.log(err))
  }
  
  _removePhone = (id) => () => {
    const { siteId, venue } = this.props;
    del(`admin/johann/sites/${siteId}/venues/${venue.id}/notifications/phones/${id}`)
    .then(resp => {
      notification.success('Removido com sucesso!')
      const { phones } = this.state;
      const phoneList = phones.filter(phone => phone.id !== id)
      this.setState({ phones: phoneList });
    })
    .catch(err => console.log(err))
  }

  _saveEmail = () => {
    const { siteId, venue } = this.props;
    const { email, savingEmail } = this.state.forms;

    if(email.length === 0)
      return;

    if(email.indexOf('@') === -1)
      return;

    if(savingEmail)
      return;

    this.setState({ savingEmail: true }, () => {
      post(`admin/johann/sites/${siteId}/venues/${venue.id}/notifications/emails`, {
        email
      })
      .then(resp => {
        notification.success('Dados salvos com sucesso!')
        const email = resp.data.data;
        const { emails } = this.state;
        const emailsList = [ email, ...emails ];
        const { forms } = this.state;
        forms.email = '';
        this.setState({ savingEmail: false, emails: emailsList, forms });
      })
      .catch(err => {
        console.log(err)
        this.setState({ savingEmail: false });
      });
    });
  }


  _savePhone = () => {
    const { siteId, venue } = this.props;
    const { phone, savingPhone } = this.state.forms;

    if(phone.length === 0)
      return;

    if(savingPhone)
      return;

    this.setState({ savingPhone: true }, () => {
      post(`admin/johann/sites/${siteId}/venues/${venue.id}/notifications/phones`, {
        phone
      })
      .then(resp => {
        notification.success('Dados salvos com sucesso!')
        const phone = resp.data.data;
        const { phones } = this.state;
        const phonesList = [ phone, ...phones ];
        const { forms } = this.state;
        forms.phone = '';
        this.setState({ savingPhone: false, phones: phonesList, forms });
      })
      .catch(err => {
        console.log(err)
        this.setState({ savingPhone: false });
      });
    });
  }

  _moveToSite = e => {
    if(parseInt(this.state.siteToMove) === 0) {
      notification.error('Não selecionou um ambiente');
      return;
    }

    post(`admin/johann/sensor/${this.props.venue.id}/move-to-site/${this.state.siteToMove}`)
      .then(resp => {
        notification.success("Moveu com sucesso");
        return;
      })
      .catch(err => {
        notification.error("Não foi possivel mover")
      })
  }

  _saveTimes = (e) => {
    e.preventDefault();

    const { originalTimes, savingTimes, forms } = this.state;
    const { intervalTimeNotify, maxOutLimitNotify } = forms;

    const timeHasChanges = (originalTimes.intervalTimeNotify !== intervalTimeNotify ||  originalTimes.maxOutLimitNotify !== maxOutLimitNotify)

    if(!timeHasChanges)
      return;

    if(savingTimes)
      return;

    this.setState({ savingTimes: true }, () => {
      const { siteId, venue } = this.props;
      post(
        `admin/johann/sites/${siteId}/venues/${venue.id}/notifications`, {
        times: {
          intervalTimeNotify: parseInt(intervalTimeNotify, 10),
          maxOutLimitNotify: parseInt(maxOutLimitNotify, 10)
        }
      })
      .then(resp => resp.data.data)
      .then(data => {
        notification.success('Dados salvos com sucesso!')
        this.setState({ savingTimes: false, originalTimes: {...data.times} });
      })
      .catch(err =>this.setState({ savingTimes: true }))
    });
  }

  render() {
    const { venue={}, handleToogleModal } = this.props;
    const {
      loading,
      isDeleting,
      emails=[],
      phones=[],
      telegrams=[],
      notifications,
      originalNotifications,
      savingNotifications,
      savingTimes,
      savingPhone,
      savingEmail,
      originalTimes,
      forms
    } = this.state;

    const notificationsHasChanges = (originalNotifications.whatsapp !== notifications.whatsapp
      || originalNotifications.telegram !== notifications.telegram
      || originalNotifications.email !== notifications.email);

    const timeHasChanges = (originalTimes.intervalTimeNotify !== forms.intervalTimeNotify || originalTimes.maxOutLimitNotify !== forms.maxOutLimitNotify) 

    return <Modal
      handleToogleModal={isDeleting ? () => {} : handleToogleModal}
      isCard={true}
      buttonsLeft={[
        {
          label: 'Fechar',
          type: 'is-default',
          disabled: isDeleting,
          onClick: handleToogleModal
        }
      ]}
      buttonsRight={[
        {
          label: 'Excluir',
          type: `is-danger ${isDeleting && 'is-loading'}`,
          disabled: false,
          onClick: this._deleteVenue(venue.id)
        }
      ]}
      cardStyle={{ maxWidth: '40%' }}
      title={`${venue.name}`}
      component={(<Columns isMultiline>
        <Column isSize={12} style={{ borderBottom: '2px solid whitesmoke' }} >
          <form onSubmit={this._saveTimes}>
            <p className="subtitle is-5">
              <span>Configuração para alertas</span>
            </p>
            <span className="heading">
              Tempo limite que a temperatura/umidade pode ficar fora dos valores aceitáveis: (minutos)
            </span>
            <div className="field is-small">
              <div className="control has-icons-right">
                <input type="number" name="maxOutLimitNotify" onChange={this._onChangeValue} className="input" value={this.state.forms.maxOutLimitNotify} placeholder="minutos" />
                <span className="icon is-right has-text-weight-bold">
                  <Icons.ClockIcon />
                </span>
              </div>
            </div>
            <span className="heading">
              Intervalo de tempo entre notificações: (minutos)
            </span>
            <div className="field is-small">
              <div className="control has-icons-right">
                <input type="number" name="intervalTimeNotify" onChange={this._onChangeValue} className="input" value={this.state.forms.intervalTimeNotify} placeholder="minutos" />
                <span className="icon is-right has-text-weight-bold">
                  <Icons.ClockIcon />
                </span>
              </div>
            </div>
            {timeHasChanges
              ? <div className="field is-small">
                  <div className="control">
                    <button
                      type="submit"
                      className={`button is-primary is-outlined ${savingTimes ? 'is-loading' : ''}`}
                    >
                      Salvar
                    </button>
                  </div>
                </div>
              : <div className="field is-small">
                  <div className="control">
                    <span className="button is-static">Salvo</span>
                  </div>
                </div>}
          </form>
        </Column>
        <Column isSize={12} style={{ borderBottom: '2px solid whitesmoke' }}>
          <p className="subtitle is-5">
            Notificar via:
          </p>
          {loading && <div style={{width: '100%'}} className="button is-loading is-bordless has-text-centered"></div>}
          {!loading && <Columns>
            <Column>
              <div className="field is-small">
                <div className="field">
                  <div className="control">
                    <label className="checkbox">
                      <input name="email" onChange={this._onChangeChecked} type="checkbox" checked={notifications.email} />
                      <span> <Icons.EnvelopeIcon /> Email</span>
                    </label>
                  </div>
                </div>
              </div>
            </Column>
            <Column>
              <div className="field is-small">
                <div className="field">
                  <div className="control">
                    <label className="checkbox">
                      <input name="whatsapp" onChange={this._onChangeChecked} type="checkbox" checked={notifications.whatsapp} />
                      <span> <Icons.WhatsAppIcon /> WhatsApp</span>
                    </label>
                  </div>
                </div>
              </div>
            </Column>
            <Column>
              <div className="field is-small">
                <div className="field">
                  <div className="control">
                    <label className="checkbox">
                      <input name="telegram" onChange={this._onChangeChecked} type="checkbox" checked={notifications.telegram} />
                      <span> <Icons.TelegramPlaneIcon /> Telegram</span>
                    </label>
                  </div>
                </div>
              </div>
            </Column>
            {/* <Column>
              <div className="field is-small">
                <div className="field">
                  <div className="control">
                    <label className="checkbox">
                      <input name="sms" onChange={this._onChangeChecked} type="checkbox" checked={notifications.sms} />
                      <span> <Icons.SMSIcon /> SMS</span>
                    </label>
                  </div>
                </div>
              </div>
            </Column> */}
          </Columns>}
          {notificationsHasChanges 
            ? <div className="field is-small">
                <div className="control">
                  <span
                    className={`button is-primary is-outlined ${savingNotifications ? 'is-loading' : ''}`}
                    onClick={this._saveNotifications}
                  >
                    Salvar
                  </span>
                </div>
              </div>
            : <div className="field is-small">
                <div className="control">
                  <span className="button is-static">Salvo</span>
                </div>
              </div>}
        </Column>
        <Column isSize={12}>
          <p className="subtitle is-5">Quem será notificado:</p>
          <div className="tabs is-small is-left">
            <ul>
              <li className={this.state.selectedMenu === 'email' ? "is-active" : ""}>
                <a href onClick={() => this.setState({ selectedMenu: 'email' })}>
                  <Icons.EnvelopeIcon />
                  <span>Emails</span>
                </a>
              </li>
              <li className={this.state.selectedMenu === 'phone' ? "is-active" : ""}>
                <a href onClick={() => this.setState({ selectedMenu: 'phone' })}>
                  <Icons.WhatsAppIcon />
                  <span>Números de whatsapp</span>
                </a>
              </li> 
              <li className={this.state.selectedMenu === 'telegram' ? "is-active" : ""}>
                <a href onClick={() => this.setState({ selectedMenu: 'telegram' })}>
                  <Icons.TelegramPlaneIcon />
                  <span>Usuários de Telegram</span>
                </a>
              </li>
            </ul>
          </div>
          {loading && <div style={{width: '100%'}} className="button is-loading is-bordless has-text-centered"></div>}
          {!loading && this.state.selectedMenu === 'email' && <div>
            <div className="field is-small is-grouped">
              <div className="control has-icons-left is-expanded">
                <input
                  type="email"
                  name="email"
                  className="input"
                  placeholder="Ex: email@email.com"
                  onChange={this._onChangeValue}
                  value={this.state.forms.email}
                  onKeyUp={e => {
                    const ENTER = 13;
                    if(e.keyCode === ENTER)
                      this._saveEmail();
                  }}
                />
                <span className="icon is-left has-text-weight-bold">
                  <Icons.EnvelopeIcon />
                </span>
              </div>
              <div className="control">
                <span className={`button is-primary ${savingEmail ? 'is-loading' : ''}`} onClick={this._saveEmail}>
                  <span>Adicionar</span>
                  <Icons.PlusIcon />
                </span>
              </div>
            </div>
            <div style={{width:'100%', height: 100, overflowY: 'scroll'}}>
              <table className="table is-striped is-hoverable is-fullwidth">
                <tbody>
                  {!emails.length  && <tr>
                    <td>Não há emails</td>
                  </tr>}
                  {emails
                    // .filter(email => email.email.indexOf(this.state.forms.email) !== -1 || this.state.forms.email.length === 0)
                    .map(email => <tr key={email.id} className="hasInvisibleChild">
                    <td>{email.email}</td>
                    <td>
                      <span className="has-text-danger cursor-pointer showWhenHover" onClick={this._removeEmail(email.id)}>
                        <Icons.TrashIcon />
                      </span>
                    </td>
                  </tr>)}
                </tbody>
              </table>
            </div>
          </div>}
          {!loading && this.state.selectedMenu === 'phone'  && <div>
            <div className="field is-small is-grouped">
              <div className="control has-icons-left is-expanded">
                <input
                  type="text"
                  name="phone"
                  className="input"
                  placeholder="Ex: 11999999999"
                  onChange={this._onChangeValue}
                  value={this.state.forms.phone}
                  onKeyUp={(e) => {
                    e.persist();
                    const ENTER = 13;
                    if(e.keyCode === ENTER)
                      this._savePhone(); 
                  }}
                />
                <span className="icon is-left has-text-weight-bold">
                  <Icons.PhoneIcon />
                </span>
              </div>
              <div className="control">
                <span className={`button is-primary ${savingPhone ? 'is-loading' : ''}`} onClick={this._savePhone}>
                  <span>Adicionar</span>
                  <Icons.PlusIcon />
                </span>
              </div>
            </div>
            <div style={{width:'100%', height: 100, overflowY: 'scroll'}}>
              <table className="table is-striped is-hoverable is-fullwidth">
                <tbody>
                  {!phones.length && <tr>
                    <td>Não há telefones</td>
                  </tr>}
                  {phones
                    // .filter(phone => phone.phone.indexOf(this.state.forms.phone) !== -1 || this.state.forms.phone.length === 0)
                    .map(phone => <tr key={phone.id} className="hasInvisibleChild">
                    <td>{phone.phone}</td>
                    <td>
                      <span className="has-text-danger cursor-pointer showWhenHover" onClick={this._removePhone(phone.id)}>
                        <Icons.TrashIcon />
                      </span>
                    </td>
                  </tr>)}
                </tbody>
              </table>
            </div>
          </div>}
          {!loading && this.state.selectedMenu === 'telegram'  && <div>
            <div style={{width:'100%', height: 100, overflowY: 'scroll'}}>
              <table className="table is-striped is-hoverable is-fullwidth">
                <tbody>
                  <tr>
                    <td colSpan="2">Para cadastrar um usuário às notificações do telegram, utilize o RvSmileBot no telegram.</td>
                  </tr>
                  {!telegrams.length  && <tr>
                    <td>Não há telegram cadastrado</td>
                  </tr>}
                  {telegrams
                    // .filter(email => email.email.indexOf(this.state.forms.email) !== -1 || this.state.forms.email.length === 0)
                    .map(telegram => <tr key={telegram.id} className="hasInvisibleChild">
                    <td>{telegram.mail}</td>
                    <td>{telegram.name}</td>
                  </tr>)}
                </tbody>
              </table>
            </div>
          </div>}
        </Column>
        {this.props.tooqadmin && <Column isSize={12} style={{ borderBottom: '2px solid whitesmoke' }}>
          <form onSubmit={this._saveTimes}>
            <p className="subtitle is-5">
              <span>Mover sensor para outro ambiente</span>
            </p>

            <div className="field has-addons has-addons-centered">
              <div className="control">
                <span className="select">
                  <select value={this.state.siteToMove} onChange={(e) => this.setState({ siteToMove: e.target.value })}>
                    <option value={0}>Selecione uma opção</option>
                    {this.state.allJohannSites.map(site => <option value={site.siteId}>{site.siteName}</option>)}
                  </select>
                </span>
              </div>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  placeholder="escreva: mover"
                  value={this.state.secureMove}
                  onChange={e => this.setState({ secureMove: e.target.value })}
                />
              </div>
              <div className="control">
                <button
                  className="button is-primary"
                  disabled={this.state.secureMove !== 'mover'}
                  onClick={() => this._moveToSite()}
                >
                  Mover
                </button>
              </div>
            </div>
          </form>
        </Column>}
      </Columns>)}
    />
  }
}

export default JohannMoreOptions;