
import React, { useRef, useState } from 'react';

import { del, post } from '../../../../utils';


const Gondola = (props) => {
  const {
    site,
    gondola,
    onSelectShelf,
    venuesShelves,
    venuesShelvesIsLoading
  } = props;

  const canvas = useRef()
  const [ shelves, setShelves ] = useState(gondola.shelfs); 
  const [currentBox, setCurrentBox ] = useState({ drawing: false, venueId: 0 });

  const clearCurrentBox = () => setCurrentBox({ drawing: false, venueId: 0, description: '' });

  const clickDraw = (e) => {
    e.persist();

    if (currentBox.drawing){
      setCurrentBox({ ...currentBox, drawing: false });
      return;
    }

    const { offsetX, offsetY } = e.nativeEvent;
    setCurrentBox({
      pointsStart: {
        x: offsetX,
        y: offsetY
      },
      rectangle: {
        x: offsetX,
        y: offsetY,
        width: 1,
        height: 1,
      },
      drawing: true
    });
  }

  const renderMousePosition = (event) => {
    event.persist()
    const { drawing } = currentBox;
    if(!drawing)
      return;

    const { offsetX, offsetY } = event.nativeEvent;
    const { pointsStart } = currentBox;
    let x = pointsStart.x;
    let y = pointsStart.y;

    let width = offsetX - x;
    let height = offsetY - y;

    if(x > offsetX) {
      x = offsetX;
      width = pointsStart.x - offsetX;
    }

    if(y > offsetY) {
      y = offsetY;
      height = pointsStart.y - offsetY;
    }

    setCurrentBox({
      ...currentBox,
      rectangle: {
        x, y, width, height, drawing: true
      }
    });
  }

  const saveShelf = () => {
    const { rectangle, saving, venueId } = currentBox;
    const { x, y, width, height } = rectangle;
    const points = `${x},${y},${width},${height}`;

    if(saving || venueId === 0)
      return;

    setCurrentBox({ ...currentBox, saving: true });

    post(`admin/videos/sites/${site.id}/gondolas/${gondola.id}/draw`, { venueId, points })
      .then((resp) => {
        const { id } = resp.data.data;
        const description = venuesShelves.find(v => v.id === venueId).description;
        setShelves([
          ...shelves,
          {
            id,
            gondolaVenueId: id,
            description,
            venueId,
            points
          }
        ]);
        onSelectShelf({});
        clearCurrentBox()
      });

  }

  const removeShelf = () => {
    const { id, removing } = currentBox;

    if(removing)
      return

    setCurrentBox({ ...currentBox, removing: true });

    del(`admin/videos/sites/${site.id}/gondolas/${gondola.id}/draw/${id}`)
      .then((resp) => {
        setShelves(shelves.filter(shelve => shelve.gondolaVenueId !== id));
        clearCurrentBox()
        onSelectShelf({});
      });
  }

  const onClickSelectShelf = (shelf) => () => {
    const { id, description, x, y, w, h, venueId } = shelf;

    onSelectShelf({
      gondolaVenueId: id,
      venueId,
      gondola
    });

    setCurrentBox({
      id,
      description,
      venueId,
      rectangle: {
        x: parseInt(x),
        y: parseInt(y),
        width: parseInt(w),
        height: parseInt(h)
      },
      pointsStart: {
        x: parseInt(x),
        y: parseInt(y)
      }
    });
  }

  const onChangeVenueId = (e) => {
    const { value } = e.target;
    const description = venuesShelves.find(s => s.id === parseInt(value)).description;
    setCurrentBox({
      ...currentBox,
      venueId: parseInt(value),
      description
    })
  }

  return <svg
    ref={canvas}
    style={{
      border: '1px solid #363636',
      borderRadius: 5
    }}
    width={gondola.screenWidth}
    height={gondola.screenHeight}
  >
    <image
      href={gondola.src}
      x="0"
      y="0"
      width={gondola.screenWidth}
      height={gondola.screenHeight}
      onClick={clickDraw}
      onMouseMove={renderMousePosition}
    />
    
    {shelves.map(shelf => {
      const { gondolaVenueId, description, points, venueId } = shelf;
      const [x, y, w, h] = points.split(',');
      return <rect
          key={`shelf-${gondolaVenueId}-${points.replace(' ', '_').replace(',', '')}`}
          points={points}
          fill="#72CB46"
          fillOpacity={0.2}
          stroke='#222222'
          cursor="pointer"
          strokeWidth={2}
          width={w}
          height={h}
          x={x}
          y={y}
          onClick={onClickSelectShelf({ id: gondolaVenueId, description, x, y, w, h, venueId })}
        > 
        </rect>
      })}

    {currentBox.rectangle && <g>
      <rect
        y={currentBox.rectangle.y}
        x={currentBox.rectangle.x}
        width={currentBox.rectangle.width}
        height={currentBox.rectangle.height}
        fillOpacity={0.1}
        stroke={"#FFFFFF"}
        strokeWidth={2}
        onClick={clickDraw}
        onMouseMove={renderMousePosition}
      />
      {!currentBox.drawing && <foreignObject
        y={currentBox.rectangle.y+currentBox.rectangle.height+5 + 85 > gondola.screenHeight ? gondola.screenHeight - 85 : currentBox.rectangle.y+currentBox.rectangle.height+5}
        x={currentBox.rectangle.x + 250 > gondola.screenWidth ? gondola.screenWidth - 250 : currentBox.rectangle.x}
        width={250}
        height={80}>
          {currentBox.id && <div
            style={{
              width: '100%',
              backgroundColor: '#FFFFFF',
              borderRadius: 5,
              padding: 5
            }}
          >
              <div className="field">
                <div className="control is-expanded has-icons-left">
                  <label className='label'>
                    #{currentBox.id} {currentBox.description}
                  </label>
                </div>
              </div>
              <div className="buttons">
                <button
                  type='button'
                  className={`button is-danger is-small ${currentBox.removing ? 'is-loading': ''}`}
                  onClick={removeShelf}>
                  Remover
                </button>
                <button type='button' className="button is-default is-small" onClick={() => clearCurrentBox()}>
                  Cancelar
                </button>
              </div>
          </div>}
          {!currentBox.id && <div
            style={{
              width: '100%',
              backgroundColor: '#FFFFFF',
              borderRadius: 5,
              padding: 5
            }}
          >
            <div className="field">
              <div className="control is-fullwidth">
                <div className="select is-small">
                  <select onChange={onChangeVenueId} value={currentBox.venueId}>
                    {venuesShelvesIsLoading && <option value={0}>Aguarde...</option>}
                    {!venuesShelvesIsLoading && <option value={0}>Selecione uma prateleira</option>}
                    {venuesShelves
                      .filter(venue => !shelves.map(s => s.venueId).includes(venue.id) || venue.id === currentBox.venueId)
                      .map(option => <option value={option.id}>{option.description}</option>)}
                  </select>
                </div>
              </div>
            </div>
            <div className="buttons">
              <button
                type='button'
                className={`button is-primary is-small ${currentBox.saving ? 'is-loading': ''}`}
                onClick={saveShelf}>
                Salvar
              </button>
              <button type='button' className="button is-default is-small" onClick={() => clearCurrentBox()}>
                Cancelar
              </button>
            </div>
          </div>}
      </foreignObject>}
    </g>}
  </svg>
}

export default Gondola;