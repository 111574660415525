import React, { useEffect, useState } from 'react';
import Columns, { Column } from '../../../../components/Columns';
import HealthSiteModal from './HealthSiteModal';
import _ from 'lodash';

import { StethoscopeIcon } from '../../../../components/icons';
import IsLoading from '../../../../components/IsLoading';
import Page, { PageTitle } from '../../../../components/Page';
import { formatAsPtBr, get } from '../../../../utils';


const SLA_GOOD = (val) => val >= 75 && val <= 125;
const SLA_MED = (val) => (val < 75 && val >= 70) || (val <= 130 && val > 125);
const SLA_BAD = (val) => val < 70 || val > 130;

const useVenues = (siteId) => {
  const [ venues, setVenues ] = useState([]);

  useEffect(() => {
    get(`admin/videos/sites/${siteId}/venues`)
      .then(resp => setVenues(resp.data.data));

  }, [ siteId ])

  return [ venues ];
}

const useAuditorias = (siteId) => {
  const [ audits, setAudits ] = useState([]);
  const [ dash, setDash ] = useState([]);
  const [ dashVenue, setDashVenue ] = useState([]);

  const [ isLoading, setIsLoading ] = useState(false);
  const [ isLoadingVisitors, setIsLoadingVisitors ] = useState(false);
  const [ isLoadingVenueVisitors, setIsLoadingVenueVisitors ] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    get(`dashboard/video/sites/${siteId}/allaudit`)
      .then(response => response.data.data.filter(audit => audit.status === "1"))
      .then(setAudits)
      .then(() => setIsLoading(false))
      .catch(err => setAudits([]));
  }, [ siteId ]);

  const daysReduced = Object.keys(audits.reduce((prev, curr) => {
    prev[curr.snapDate] = true;
    return prev;
  }, {}));

  const daysToSearch = daysReduced.join(',');

  useEffect(() => {
    if(daysToSearch.length === 0)
      return;

    setIsLoadingVisitors(true);
    get(`dashboard/foottraffic/sites/${siteId}/salesindays`, {
      days: daysToSearch
    })
    .then((resp) => {
      setIsLoadingVisitors(false);
      setDash(resp.data.data);
    }); 

    setIsLoadingVenueVisitors(true);
    get(`dashboard/foottraffic/sites/${siteId}/areasindays`, { days: daysToSearch })
      .then((resp) => setDashVenue(resp.data.data))
      .then(() => setIsLoadingVenueVisitors(false));
  }, [ siteId, daysToSearch ]);

  const visitorsAuditAccuracy = audits
    .filter(audit => audit.auditType === "visitors")
    .map(audit => {
      const dayAndHourDash = dash.find(dashData => dashData.info_date === `${audit.snapDate} ${audit.snapTime}`) || {
        passersby: 0,
        visitors: 0,
        total_sales: 0
      };

      const salesRateAudit = (audit.visitors === 0 || dayAndHourDash.total_sales === 0)
        ? 0
        : Math.round((dayAndHourDash.total_sales / audit.visitors) * 100);

      const accuracy = (dayAndHourDash.visitors === 0 || audit.visitors === 0)
        ? 0
        : Math.round((dayAndHourDash.visitors / audit.visitors) * 100);

      return {
        ...audit,
        visitorsDashboard: dayAndHourDash.visitors,
        sales: dayAndHourDash.total_sales,
        salesRateDash: dayAndHourDash.conversionRate,
        salesRateAudit,
        accuracy
      }
    })

  const visitors = Object.values(visitorsAuditAccuracy
    .reduce((prev, curr) => {
      const dayOfWeek = formatAsPtBr(curr.snapDate, 'ddd');
      const dayOfWeekNumber = formatAsPtBr(curr.snapDate, 'd');

      const currentData = prev[dayOfWeek] || {
        dayOfWeek: dayOfWeek,
        dayOfWeekNumber: dayOfWeekNumber,
        count: 0,
        accuracy: [],
        sales: [],
        salesRateDash: [],
        salesRateAudit: [],
        audits: []
      }

      currentData['count'] += 1;
      currentData['accuracy'].push(curr.accuracy)
      currentData['sales'].push(curr.sales)
      currentData['salesRateDash'].push(curr.salesRateDash)
      currentData['salesRateAudit'].push(curr.salesRateAudit)
      currentData['audits'].push(curr)

      prev[dayOfWeek] = currentData;
      return prev;
    }, {}))
    .map(item => {
      const accuracyMean = _.mean(item.accuracy);
      const distances = _.sum(item.accuracy.map(ac => ac - accuracyMean).map(distance => Math.pow(distance, 2)));
      const desvpad = Math.sqrt((distances / (item.count-1))).toFixed(2);

      const goodSLA = parseInt(((item.accuracy.filter(SLA_GOOD).length / item.count) * 100).toFixed(0));
      const medSLA = parseInt(((item.accuracy.filter(SLA_MED).length / item.count) * 100).toFixed(0));
      const badSLA = parseInt(((item.accuracy.filter(SLA_BAD).length / item.count) * 100).toFixed(0));
      return {
        dayOfWeek: item.dayOfWeek,
        dayOfWeekNumber: item.dayOfWeekNumber,
        count: item.count,
        desvpad,
        goodSLA,
        medSLA,
        badSLA,
        accuracyMean: accuracyMean.toFixed(1),
        sales: (_.mean(item.salesRateAudit) - _.mean(item.salesRateDash)).toFixed(2)
      }
    })
    .sort((a, b) => {
      return a.dayOfWeekNumber - b.dayOfWeekNumber
    })

  const passersbyAuditAccuracy = audits
    .filter(audit => audit.auditType === "passersby")
    .map(audit => {

      const dayAndHourDash = dash.find(dashData => dashData.info_date === `${audit.snapDate} ${audit.snapTime}`) || {
        passersby: 0,
        visitors: 0,
        total_sales: 0
      };

      const accuracy = (dayAndHourDash.passersby === 0 || audit.visitors === 0)
        ? 0
        : Math.round((dayAndHourDash.passersby / audit.visitors) * 100);

      return {
        ...audit,
        visitorsDashboard: dayAndHourDash.passersby,
        accuracy
      }
    });

  const passersby = Object.values(passersbyAuditAccuracy
    .reduce((prev, curr) => {
      const dayOfWeek = formatAsPtBr(curr.snapDate, 'ddd');
      const dayOfWeekNumber = formatAsPtBr(curr.snapDate, 'd');
      const currentData = prev[dayOfWeek] || {
        dayOfWeek: dayOfWeek,
        dayOfWeekNumber,
        count: 0,
        accuracy: [],
        audits: []
      }

      currentData['count'] += 1;
      currentData['accuracy'].push(curr.accuracy)
      currentData['audits'].push(curr)

      prev[dayOfWeek] = currentData;
      return prev;
    }, {}))
    .map(item => {
      const accuracyMean = _.mean(item.accuracy);
      const distances = _.sum(item.accuracy.map(ac => ac - accuracyMean).map(distance => Math.pow(distance, 2)));
      const desvpad = Math.sqrt((distances / (item.count-1))).toFixed(2);

      const goodSLA = parseInt(((item.accuracy.filter(SLA_GOOD).length / item.count) * 100).toFixed(0));
      const medSLA = parseInt(((item.accuracy.filter(SLA_MED).length / item.count) * 100).toFixed(0));
      const badSLA = parseInt(((item.accuracy.filter(SLA_BAD).length / item.count) * 100).toFixed(0));

      return {
        dayOfWeek: item.dayOfWeek,
        dayOfWeekNumber: item.dayOfWeekNumber,
        count: item.count,
        desvpad,
        goodSLA,
        medSLA,
        badSLA,
        accuracyMean: accuracyMean.toFixed(1)
      }
    })
    .sort((a, b) => {
      return a.dayOfWeekNumber - b.dayOfWeekNumber
    });

  
  const [ venuesInfo ] = useVenues(siteId);
  const venues = Object.values(audits.filter(audit => audit.auditType === "venue")
    .map(audit => {
      const dayAndHourDash = dashVenue.find(dashData => dashData.snapDate === audit.snapDate && dashData.snapTime === audit.snapTime && dashData.venueId === audit.venueId) || {
        visitors: 0
      };

      const accuracy = (dayAndHourDash.visitors === 0 || audit.visitors === 0)
        ? 0
        : Math.round((dayAndHourDash.visitors / audit.visitors) * 100);

      return {
        ...audit,
        visitorsDashboard: dayAndHourDash.visitors,
        accuracy
      }
    })
    .reduce((prev, curr) => {
      const data = prev[curr.venueId] || {
        id: curr.venueId,
        venue: venuesInfo.find(v => v.id === curr.venueId) || { description: '-' },
        audits: []
      }
      data.audits = [ ...data.audits, curr ];
      prev[curr.venueId] = data;
      return prev;
    }, {}));

  const visitorsGeneral = visitorsAuditAccuracy.reduce((prev, curr) => {
    const goodSLA = SLA_GOOD(curr.accuracy) ? 1 : 0;
    const medSLA = SLA_MED(curr.accuracy) ? 1 : 0;
    const badSLA = SLA_BAD(curr.accuracy) ? 1 : 0;
    prev.goodSLA += goodSLA;
    prev.medSLA += medSLA;
    prev.badSLA += badSLA;
    prev.totalSLA += 1;
    prev.accuracy = [ ...prev.accuracy, curr.accuracy ]
    return prev;
  }, {
    totalSLA: 0,
    goodSLA: 0,
    medSLA: 0,
    badSLA: 0,
    accuracy: []
  });

  const passersbyGeneral = passersbyAuditAccuracy.reduce((prev, curr) => {
    const goodSLA = SLA_GOOD(curr.accuracy) ? 1 : 0;
    const medSLA = SLA_MED(curr.accuracy) ? 1 : 0;
    const badSLA = SLA_BAD(curr.accuracy) ? 1 : 0;
    prev.goodSLA += goodSLA;
    prev.medSLA += medSLA;
    prev.badSLA += badSLA;
    prev.totalSLA += 1;
    prev.accuracy = [ ...prev.accuracy, curr.accuracy ]
    return prev;
  }, {
    totalSLA: 0,
    goodSLA: 0,
    medSLA: 0,
    badSLA: 0,
    accuracy: []
  })

  return [
    (isLoading || isLoadingVenueVisitors || isLoadingVisitors),
    visitors,
    passersby,
    venues,
    visitorsGeneral,
    passersbyGeneral,
    visitorsAuditAccuracy,
    passersbyAuditAccuracy
  ];
}

const HealthSiteDash = (props) => {
  const { site } = props;
  const [ toogleModalVisitors, setToogleModalVisitors ] = useState(false);
  const [ toogleModalPassers, setToogleModalPassers ] = useState(false);
  const [ toogleModalVenue, setToogleModalVenue ] = useState(false);

  const closeModal = () => {
    setToogleModalVisitors(false);
    setToogleModalPassers(false);
    setToogleModalVenue(false);
    setVenueSelected(null);
  }

  const [ venueSelected, setVenueSelected ] = useState(null);
  const _exploreVenue = (auditVenue) => {
    setVenueSelected(auditVenue);
    setToogleModalVenue(true);
  }

  const [
    isLoadingAudits,
    auditsVisitors,
    auditsPassersby,
    auditsVenues,
    visitorsGeneral,
    passersbyGeneral,
    visitorsAuditAccuracy,
    passersbyAuditAccuracy
  ] = useAuditorias(site.id);

  return <Page>
    <PageTitle
      title={<span>
        <span style={{ marginRight: '10px' }}>
          {`${(site.name) || ''} `}
        </span>
        <p className="subtitle">Saúde da loja <StethoscopeIcon /></p>
      </span>}
    />

    {toogleModalVisitors && <HealthSiteModal
      closeModal={closeModal}
      site={site}
      isVisitor={true}
      isVenue={false}
      isPassers={false}
      audits={visitorsAuditAccuracy}
    />}

    {toogleModalPassers && <HealthSiteModal
      closeModal={closeModal}
      site={site}
      isVisitor={false}
      isPassers={true}
      isVenue={false}
      audits={passersbyAuditAccuracy}
    />}
    
    {toogleModalVenue && <HealthSiteModal
      closeModal={closeModal}
      site={site}
      isPassers={false}
      isVenue={true}
      venue={venueSelected}
      audits={venueSelected.audits}
      isVisitor={false}
    />}

    {isLoadingAudits && <Columns isMultiline>
      <Column isSize={6}>
        <div className="card notification" style={{ justifyContent: 'center', display: 'flex', flexDirection: 'column'}}>
          <p className="subtitle is-4 has-text-centered">Saúde Visitantes</p>
          <IsLoading fontSize={60} isBorderless={true} />
        </div>
      </Column>
      <Column isSize={6}>
        <div className="card notification" style={{ justifyContent: 'center', display: 'flex', flexDirection: 'column'}}>
          <p className="subtitle is-4 has-text-centered">Saúde Passantes</p>
          <IsLoading fontSize={60} isBorderless={true} />
        </div>
      </Column>
      <Column isSize={12}>
        <p className="title is-4 has-text-centered">Saúde Áreas</p>
      </Column>
      <Column isSize={12}>
        <div className="card notification" style={{ justifyContent: 'center', display: 'flex', flexDirection: 'column'}}>
          <IsLoading fontSize={60} isBorderless={true} />
        </div>
      </Column>
    </Columns>}
    {!isLoadingAudits && <Columns isMultiline>
      <Column isSize={6}>
        <div className="card notification">
          <p className="title is-4 has-text-centered">Saúde Visitantes</p>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
              <div className="has-background-grey-lighter" style={{ fontSize: 17, margin: 15, borderRadius: 5, padding: 15 }}>
                Acurácia: {_.mean(visitorsGeneral.accuracy).toFixed(1)}%
              </div>
              <div style={{ fontSize: 17, margin: 15, borderRadius: 5, padding: 15, backgroundColor: '#34a853' }} className="has-text-centered">
                Bom: {((visitorsGeneral.goodSLA / visitorsGeneral.totalSLA) * 100).toFixed(0)}%
              </div>
              <div style={{ fontSize: 17, margin: 15, borderRadius: 5, padding: 15, backgroundColor: '#ffd666' }} className="has-text-centered">
                Médio: {((visitorsGeneral.medSLA / visitorsGeneral.totalSLA) * 100).toFixed(0)}%
              </div>
              <div style={{ fontSize: 17, margin: 15, borderRadius: 5, padding: 15, backgroundColor: '#d76359' }} className="has-text-centered">
                Ruim: {((visitorsGeneral.badSLA / visitorsGeneral.totalSLA) * 100).toFixed(0)}%
              </div>
            </div>
          </div>
          <table className="table" style={{ width: '100%' }}>
            <thead>
              <tr>
                <th>Dia Semana</th>
                <th>Qtde. Auditorias</th>
                <th>Média Acurácia</th>
                <th>Desv. Padrão</th>
                <th>Freq. Acerto</th>
                <th>Distorção Tx.Vendas</th>
              </tr>
            </thead>
            <tbody>
              {auditsVisitors.map(summary => <tr key={`summary_visitors_${summary.dayOfWeek}`}>
                <td>{summary.dayOfWeek}</td>
                <td>{summary.count}</td>
                <td>{summary.accuracyMean}%</td>
                <td>{summary.desvpad}%</td>
                <td style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', width: 160 }}>
                  <div style={{ padding: 2, marginRight: '1%', color: '#363636', backgroundColor: '#34a853', borderRadius: 3, width: `30%` }}>{summary.goodSLA}%</div>
                  <div style={{ padding: 2, marginRight: '1%', color: '#363636', backgroundColor: '#ffd666', borderRadius: 3, width: `30%` }}>{summary.medSLA}%</div>
                  <div style={{ padding: 2, color: '#363636', backgroundColor: '#d76359', borderRadius: 3, width: `30%` }}>{summary.badSLA}%</div>
                </td>
                <td>{summary.sales}%</td>
              </tr>)}
            </tbody>
          </table>
          <div className="button is-default" onClick={() => setToogleModalVisitors(true)}>Explorar</div>
        </div>
      </Column>
      <Column isSize={6}>
        <div className="card notification">
          <p className="title is-4 has-text-centered">Saúde Passantes</p>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
              <div className="has-background-grey-lighter" style={{ fontSize: 17, margin: 15, borderRadius: 5, padding: 15 }}>
                Acurácia: {_.mean(passersbyGeneral.accuracy).toFixed(1)}%
              </div>
              <div style={{ fontSize: 17, margin: 15, borderRadius: 5, padding: 15, backgroundColor: '#34a853' }} className="has-text-centered">
                Bom: {((passersbyGeneral.goodSLA / passersbyGeneral.totalSLA) * 100).toFixed(0)}%
              </div>
              <div style={{ fontSize: 17, margin: 15, borderRadius: 5, padding: 15, backgroundColor: '#ffd666' }} className="has-text-centered">
                Médio: {((passersbyGeneral.medSLA / passersbyGeneral.totalSLA) * 100).toFixed(0)}%
              </div>
              <div style={{ fontSize: 17, margin: 15, borderRadius: 5, padding: 15, backgroundColor: '#d76359' }} className="has-text-centered">
                Ruim: {((passersbyGeneral.badSLA / passersbyGeneral.totalSLA) * 100).toFixed(0)}%
              </div>
            </div>
          </div>
          <table className="table" style={{ width: '100%' }}>
            <thead>
              <tr>
                <th>Dia Semana</th>
                <th>Qtde. Auditorias</th>
                <th>Média Acurácia</th>
                <th>Desv. Padrão</th>
                <th>Freq. Acerto</th>
              </tr>
            </thead>
            <tbody>
              {auditsPassersby.map(summary => <tr key={`summary_passersby_${summary.dayOfWeek}`}>
                <td>{summary.dayOfWeek}</td>
                <td>{summary.count}</td>
                <td>{summary.accuracyMean}%</td>
                <td>{summary.desvpad}%</td>
                <td style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', width: 160 }}>
                  <div style={{ padding: 2, marginRight: '1%', color: '#363636', backgroundColor: '#34a853', borderRadius: 3, width: `30%` }}>{summary.goodSLA}%</div>
                  <div style={{ padding: 2, marginRight: '1%', color: '#363636', backgroundColor: '#ffd666', borderRadius: 3, width: `30%` }}>{summary.medSLA}%</div>
                  <div style={{ padding: 2, color: '#363636', backgroundColor: '#d76359', borderRadius: 3, width: `30%` }}>{summary.badSLA}%</div>
                </td>
              </tr>)}
            </tbody>
          </table>
          <div className="button is-default" onClick={() => setToogleModalPassers(true)}>Explorar</div>
        </div>
      </Column>
      <Column isSize={12}>
        <p className="title is-4 has-text-centered">Saúde Áreas</p>
      </Column>
      {auditsVenues.map(auditVenue => {
        const general = auditVenue.audits.reduce((prev, curr) => {
          const goodSLA = SLA_GOOD(curr.accuracy) ? 1 : 0;
          const medSLA = SLA_MED(curr.accuracy) ? 1 : 0;
          const badSLA = SLA_BAD(curr.accuracy) ? 1 : 0;
          prev.goodSLA += goodSLA;
          prev.medSLA += medSLA;
          prev.badSLA += badSLA;
          prev.totalSLA += 1;
          prev.accuracy = [ ...prev.accuracy, curr.accuracy ]
          return prev;
        }, {
          totalSLA: 0,
          goodSLA: 0,
          medSLA: 0,
          badSLA: 0,
          accuracy: []
        })

        const summary = Object.values(auditVenue.audits.reduce((prev, curr) => {
            const dayOfWeek = formatAsPtBr(curr.snapDate, 'ddd');
            const currentData = prev[dayOfWeek] || {
              dayOfWeek: dayOfWeek,
              count: 0,
              accuracy: [],
              audits: []
            }

            currentData['count'] += 1;
            currentData['accuracy'].push(curr.accuracy)
            currentData['audits'].push(curr)

            prev[dayOfWeek] = currentData;
            return prev;
          }, {}))
          .map(item => {
            const accuracyMean = _.mean(item.accuracy);
            const distances = _.sum(item.accuracy.map(ac => ac - accuracyMean).map(distance => Math.pow(distance, 2)));
            const desvpad = Math.sqrt((distances / (item.count-1))).toFixed(2);

            const goodSLA = parseInt(((item.accuracy.filter(SLA_GOOD).length / item.count) * 100).toFixed(0));
            const medSLA = parseInt(((item.accuracy.filter(SLA_MED).length / item.count) * 100).toFixed(0));
            const badSLA = parseInt(((item.accuracy.filter(SLA_BAD).length / item.count) * 100).toFixed(0));

            return {
              dayOfWeek: item.dayOfWeek,
              count: item.count,
              desvpad,
              goodSLA,
              medSLA,
              badSLA,
              accuracyMean: accuracyMean.toFixed(1)
            }
          })
          .sort((a, b) => {
            if (a.dayOfWeek > b.dayOfWeek) {
              return 1;
            }
            if (a.dayOfWeek < b.dayOfWeek) {
              return -1;
            }

            return 0;
          })

        return <Column key={`venue_health_${auditVenue.id}`} isSize={6}>
          <div className="notification card">
            <p className="subtitle is-4"><b>#{auditVenue.id}</b> {auditVenue.venue.description}</p>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
              <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
                <div className="has-background-grey-lighter" style={{ fontSize: 17, margin: 15, borderRadius: 5, padding: 15 }}>
                  Acurácia: {_.mean(general.accuracy).toFixed(1)}%
                </div>
                <div style={{ fontSize: 17, margin: 15, borderRadius: 5, padding: 15, backgroundColor: '#34a853' }} className="has-text-centered">
                  Bom: {((general.goodSLA / general.totalSLA) * 100).toFixed(0)}%
                </div>
                <div style={{ fontSize: 17, margin: 15, borderRadius: 5, padding: 15, backgroundColor: '#ffd666' }} className="has-text-centered">
                  Médio: {((general.medSLA / general.totalSLA) * 100).toFixed(0)}%
                </div>
                <div style={{ fontSize: 17, margin: 15, borderRadius: 5, padding: 15, backgroundColor: '#d76359' }} className="has-text-centered">
                  Ruim: {((general.badSLA / general.totalSLA) * 100).toFixed(0)}%
                </div>
              </div>
            </div>
            <table className="table" style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th>Dia Semana</th>
                  <th>Qtde. Auditorias</th>
                  <th>Média Acurácia</th>
                  <th>Desv. Padrão</th>
                  <th>Freq. Acerto</th>
                </tr>
              </thead>
              <tbody>
                {summary.map(summary => <tr key={`summary_passersby_${summary.dayOfWeek}`}>
                  <td>{summary.dayOfWeek}</td>
                  <td>{summary.count}</td>
                  <td>{summary.accuracyMean}%</td>
                  <td>{summary.desvpad}%</td>
                  <td style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', width: 160 }}>
                    <div style={{ padding: 2, marginRight: '1%', color: '#363636', backgroundColor: '#34a853', borderRadius: 3, width: `30%` }}>{summary.goodSLA}%</div>
                    <div style={{ padding: 2, marginRight: '1%', color: '#363636', backgroundColor: '#ffd666', borderRadius: 3, width: `30%` }}>{summary.medSLA}%</div>
                    <div style={{ padding: 2, color: '#363636', backgroundColor: '#d76359', borderRadius: 3, width: `30%` }}>{summary.badSLA}%</div>
                  </td>
                </tr>)}
              </tbody>
            </table>
            <div className="button is-default" onClick={() => _exploreVenue(auditVenue)}>Explorar</div>
          </div>
        </Column>
      })}
    </Columns>}
  </Page>
}


export default HealthSiteDash;