import React, { Component } from 'react';
import {
  ResponsiveContainer,
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  Line,
  Tooltip,
  Legend,
} from 'recharts';
class ConversaoChart extends Component {
  _secondsToLabel = (val) => {
    if(val === 0)
      return `0 min 0 seg`;

    const [ minutes, seconds='0' ] = `${val/60}`.split('.');
    const secondsLabel = `${60 * parseInt(seconds.slice(0,2)) / 10}`.slice(0, 2);

    if (seconds === '0' || parseInt(secondsLabel) >= 60)
      return `${minutes} min`;
      

    return `${minutes} min ${secondsLabel} seg`;
  }
  render(){
    const {data} = this.props;
    return (<div>
      <div style={{marginTop: 0}}>
        <p style={{margin:0}} className="heading has-text-centered is-size-6">Vendas </p>
        <ResponsiveContainer width="100%" height={250}>
          <ComposedChart data={data}
            syncId="CaixaChart"
            margin={{ top: 0, right: 30, left: 0, bottom: 0 }}>
            <XAxis dataKey="row" minTickGap={1} />
            <YAxis yAxisId="left" />
            <YAxis yAxisId="right" orientation="right" domain={['dataMin', 'dataMax + 5']} />
            <Tooltip />
            <Legend />
            <Bar name="Visitantes" yAxisId="left" dataKey="visitantes" barSize={20} fill="#00d1b2" />
            <Bar name="Quantidade de Tickets" yAxisId="left" dataKey="qtdCupons" barSize={20} fill="#2C45B4" />
            <Line name="Conversão" label={{position: 'top', offset: 5, formatter:(val => `${val}%`)}} type="monotone" yAxisId="right" dataKey="conversionRate" fill="#363636" unit="%" stroke="#363636" strokeWidth={2} />
          </ComposedChart>
        </ResponsiveContainer>
      </div>

      <div style={{marginTop: 20}}>
        <p style={{margin:0}} className="heading has-text-centered is-size-6">Fila </p>
        <ResponsiveContainer width="100%" height={250}>
          <ComposedChart data={data}
            syncId="CaixaChart"
            margin={{ top: 0, right: 30, left: 0, bottom: 0 }}>
            <XAxis dataKey="row" minTickGap={1} />
            <YAxis yAxisId="left" />
            <YAxis yAxisId="right" orientation="right" tickFormatter={val => this._secondsToLabel(val)} />
            <Tooltip content={<CustomToolTip />} />
            <Legend />
            <Bar name="Pessoas Na fila" yAxisId="left" dataKey="qtdFila" barSize={20} fill="#00d1b2" />
            <Line name="Tempo Médio na Fila" type="monotone" yAxisId="right" dataKey="tempoMedioFila" tickFormatter={val => this._secondsToLabel(val)} fill="#363636" stroke="#363636" strokeWidth={2} />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>)
  }
}

class CustomToolTip extends Component {
  _secondsToLabel = (val) => {
    if(val === 0)
      return `0 min 0 seg`;

    const [ minutes, seconds='0' ] = `${val/60}`.split('.');
    const secondsLabel = `${60 * parseInt(seconds.slice(0,2)) / 10}`.slice(0, 2);

    if (seconds === '0' || parseInt(secondsLabel) >= 60)
      return `${minutes} min`;
      

    return `${minutes} min ${secondsLabel} seg`;
  }
  render() {
    const { active } = this.props;
    if(active) {
      const { payload=[] } = this.props;
      const data = payload[0] || { payload: {} };
      return (<div className="card" style={{ padding: 10 }}>
          <p style={{ marginBottom: 10}}>
            {data.payload.row}
          </p>
          <p style={{color: '#00d1b2', marginBottom: 10}}>
            Pessoas Na fila : {data.payload.qtdFila}
          </p>
          <p style={{color: '#363636', marginBottom: 5}}>
            Tempo Médio na Fila : {this._secondsToLabel(data.payload.tempoMedioFila)}
          </p>

          
      </div>)
    }
    return null;
  }
}



export default ConversaoChart;